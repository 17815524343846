var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isOnline
    ? _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "", column: "" } },
        [
          _c(
            "v-icon",
            {
              staticClass: "grey--text text--lighten-2 mb-4",
              attrs: { size: _vm.iconSize }
            },
            [_vm._v("wifi_off")]
          ),
          _c("h4", { staticClass: "title mb-4" }, [
            _vm._v("You are offline. Connect to the internet.")
          ]),
          _vm.showButton
            ? _c(
                "v-btn",
                {
                  staticClass: "text-uppercase",
                  attrs: { color: "primary", flat: "", depressed: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("reload", true)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Reload")))]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }