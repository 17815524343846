import Vue from 'vue'
import Vuetify, {
  VSnackbar,
  VBtn,
  VIcon,
  VLayout,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VToolbar,
  VToolbarTitle,
  VAlert,
  VForm,
  VFlex,
  VSlideYTransition,
  VTextField,
  VDatePicker,
  VMenu,
  VTooltip,
  VSelect,
  VListTileTitle,
  VListTileContent,
  VExpansionPanel,
  VExpansionPanelContent,
} from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VLayout,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VAlert,
    VForm,
    VFlex,
    VSlideYTransition,
    VTextField,
    VDatePicker,
    VMenu,
    VTooltip,
    VSelect,
    VListTileTitle,
    VListTileContent,
    VExpansionPanel,
    VExpansionPanelContent,
  },
  iconfont: 'md',
  theme: {
    primary: '#2196F3',
  },
})
