<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar />
    <tev-subnavbar :back="'/'  + currentUser.accountType + '/transactions/' + propertyId" title="BANK ACCOUNT INFORMATION" />

    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8>
            <div class="text-xs-center" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>

            <v-flex v-if="!isLoading && bank" xs12 sm12 mb-4>
              <v-card class="z-card mb-4">
                <v-card-text class="pa-0">
                  <v-list two-line class="transparent pa-0">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          <strong>{{ bank.alias }}</strong>
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Bank Account Alias</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon flat small color="info">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="property">{{ property.name }}</v-list-tile-title>
                        <v-list-tile-sub-title>Property</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn
                          icon
                          flat
                          small
                          class="info"
                          @click="$router.push('/' + currentUser.accountType + '/properties/'+propertyId)"
                        >
                          <v-icon color="white">chevron_right</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ bank.routingNumber }}</v-list-tile-title>
                        <v-list-tile-sub-title>Routing Number</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ bank.last4 }}</v-list-tile-title>
                        <v-list-tile-sub-title>4 last digits</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>

            <z-table-transactions v-if="!isLoading && items" :items="items"></z-table-transactions>

          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>

  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import ZTableTransactions from '../../components/ZTableTransactions.vue'

export default {
  title: 'Bank Details',
  data() {
    return {
      isLoading: false,
      propertyId: null,
      bankId: null,
      managers: [],
      bank: null,
      property: null,
      items:[],
 
    }
  },
  mixins: [Alerts],
  components: {
    'z-table-transactions': ZTableTransactions
  },

  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),

  },

  methods: {
    goBack() {
      this.$router.push('/' + this.currentUser.accountType + '/properties/' + this.propertyId)
    },

    loadBank() {
      this.isLoading = true      
      this.$http
        .get('/manager/properties/' + this.propertyId + '/banks')
        .then(response => {
          this.bank = response.data.banks.find(el => el.id === this.bankId)
          this.loadTransactions()
        })
        .catch(err => {
          this.isLoading = false
          this.$log.error(err)
        })
    },

    loadTransactions() {
      this.$http
        .get(
          '/manager/properties/' +
            this.propertyId +
            '/transactions/' +
            this.bank.id
        )
        .then(response => {
          this.items = response.data.items
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })          
    },

    loadProperty() {
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
        })
        .catch(err => {
          this.$log.error(err)
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    this.bankId = this.$route.params.bankId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadBank()
    this.loadProperty()
  },
}
</script>

<style lang="scss">
.primary--border {
  border-color: #2896f3;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@media (min-width: 601px) {
  .tableForm {
    border: 2px solid #ededed !important;
  }
}
@media (max-width: 600px) {
  .tableForm {
    .v-input__control {
      border: 2px solid #ededed !important;
    }
    .v-input {
      margin: 8px auto;
    }
  }
}
.trxTitle {
  .v-list__tile {
    padding: 0;
  }
}
.tableForm {
  .v-list__tile {
    padding: 0;
  }
  border-left: 0px !important;
  .v-input__control {
    border-radius: 0px !important;
    &:last-child {
      border-left: 2px solid #ededed;
    }
    label {
      font-size: 14px; 
    }
  }
}
.removeRadius {
  border-radius: 0px !important;
}

</style>
