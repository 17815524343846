var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-invite" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("members") } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md9: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.inviteMember($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "align-start": "",
                                "justify-space-between": "",
                                "fill-height": "",
                                "mb-5": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: { icon: "", to: "/admin" }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "text-xs-center pt-4" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/icon-user-green.svg"),
                                      height: "60"
                                    }
                                  }),
                                  _c(
                                    "h3",
                                    { staticClass: "font-weight-regular" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("invite_people_to_zinc"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("z-spacer", { attrs: { width: 52 } })
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("first_name"),
                                      type: "text",
                                      box: "",
                                      autocomplete: "off",
                                      autofocus: _vm.$isDesktop()
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.firstName,
                                      callback: function($$v) {
                                        _vm.firstName = $$v
                                      },
                                      expression: "firstName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("last_name"),
                                      type: "text",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.lastName,
                                      callback: function($$v) {
                                        _vm.lastName = $$v
                                      },
                                      expression: "lastName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "z-callingcode-field",
                                    attrs: {
                                      label: _vm.$t("cell_phone"),
                                      type: "number",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function() {
                                          return [
                                            _c("z-callingcode-field", {
                                              attrs: {
                                                enabledCountryCode: true,
                                                preferredCountries: [],
                                                label: _vm.$t("dialcode")
                                              },
                                              model: {
                                                value: _vm.callingCode,
                                                callback: function($$v) {
                                                  _vm.callingCode = $$v
                                                },
                                                expression: "callingCode"
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.cellPhone,
                                      callback: function($$v) {
                                        _vm.cellPhone = $$v
                                      },
                                      expression: "cellPhone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("email"),
                                      type: "email",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      loading: _vm.isLoading,
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.dropdownOrganization,
                                      "search-input": _vm.search,
                                      flat: "",
                                      clearable: "",
                                      label: _vm.$t("organization_name"),
                                      autocomplete: "off",
                                      box: ""
                                    },
                                    on: {
                                      change: _vm.organizationChanged,
                                      "update:searchInput": function($event) {
                                        _vm.search = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.search = $event
                                      }
                                    },
                                    model: {
                                      value: _vm.organizationId,
                                      callback: function($$v) {
                                        _vm.organizationId = $$v
                                      },
                                      expression: "organizationId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        loading: _vm.isLoading,
                                        color: "light-green",
                                        dark: "",
                                        depressed: "",
                                        block: "",
                                        large: ""
                                      },
                                      on: { click: _vm.inviteMember }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("invite")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }