export const MUTATION_TYPES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  UPDATE_USER_NOTIFICATIONS: 'updateUserNotifications',
  RESET_USER_NOTIFICATIONS: 'resetUserNotifications',
}

export const ACTION_TYPES = MUTATION_TYPES

export const API_URL = (() => {
  switch (process.env.VUE_APP_MODE) {
    case 'production':
      return 'https://api.zinc.tech/v1'
    case 'development':
      return 'https://api-dev.zinc.tech/v1'
    case 'sandbox':
      return 'https://api-sandbox.zinc.tech/v1'
    default:
      return 'http://localhost:8443/v1'
  }
})()

export const LOGROCKET_TOKEN = 'wdf7zm/test'

// Plaid
export const PLAID_PUBLIC_KEY = 'e130ba098c3108d01e7b34b98fbeaf'

export const PLAID_ENV = (() => {
  switch (process.env.VUE_APP_MODE) {
    case 'production':
      return 'production'
    case 'development':
      return 'development'
    default:
      return 'sandbox'
  }
})()

export const PLAID_PRODUCTS = 'transactions'

export const PLAID_COUNTRY_CODES = 'US'

export const ACCOUNT_TYPES = [
  'admin',
  'representative',
  'support',
  'accountant',
  'manager',
  'assistant',
  'property',
  'applicant',
  'tenantev',
]

export const ZERO_UUID = '00000000-0000-0000-0000-000000000000'

export const RECAPTCHA_KEY = '6Ldag-IUAAAAANOZwxptOSxu4TIRrDCGdhmkmA5a'

export const MIN_PASSWORD_LENGTH = 12
