var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: "Change phone" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { x12: "", sm8: "", md6: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-start": "",
                        "justify-start": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "text-xs-left": "", "grey--text": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "light-green--text",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.back()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    {
                      staticClass: "transparent py-0",
                      attrs: { "two-line": "" }
                    },
                    [
                      _c(
                        "v-list-tile",
                        { attrs: { avatar: "" } },
                        [
                          _c("v-list-tile-avatar", { attrs: { size: "16" } }, [
                            _c("img", {
                              attrs: { src: require("@/assets/ico-phone.svg") }
                            })
                          ]),
                          _c(
                            "v-list-tile-content",
                            [
                              _vm.isLoading
                                ? _c(
                                    "v-list-tile-title",
                                    [
                                      _c(
                                        "skeleton-loading",
                                        [
                                          _c("square-skeleton", {
                                            attrs: {
                                              boxProperties: {
                                                width: "120px",
                                                height: "14px"
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.profile && !_vm.isLoading
                                ? _c("v-list-tile-title", [
                                    _vm._v(
                                      "+" +
                                        _vm._s(_vm.profile.dialCode) +
                                        " " +
                                        _vm._s(_vm.profile.cellPhone)
                                    )
                                  ])
                                : _vm._e(),
                              _c("v-list-tile-sub-title", [
                                _vm._v("Current phone number")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "form-sm",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.resetPhone($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "z-callingcode-field",
                                    attrs: {
                                      label: "New phone number",
                                      type: "number",
                                      box: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function() {
                                          return [
                                            _c("z-callingcode-field", {
                                              attrs: {
                                                enabledCountryCode: true,
                                                preferredCountries: [],
                                                label: _vm.$t("dialcode")
                                              },
                                              model: {
                                                value: _vm.callingCode,
                                                callback: function($$v) {
                                                  _vm.callingCode = $$v
                                                },
                                                expression: "callingCode"
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.cellPhone,
                                      callback: function($$v) {
                                        _vm.cellPhone = $$v
                                      },
                                      expression: "cellPhone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", "mb-5": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "light-green",
                                        dark: "",
                                        large: "",
                                        block: "",
                                        depressed: ""
                                      },
                                      on: { click: _vm.resetPhone }
                                    },
                                    [_vm._v("Submit")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }