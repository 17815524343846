<template>
  <v-app>
    <tev-navbar />
    <v-content>
      <v-layout row wrap>
        <v-card class="transparent elevation-0 px-3 mt-5 mb-4" width="600" max-width="100%">
          <h4 class="primary--text font-weight-bold mb-3 text-uppercase">{{ $t('my_properties') }}</h4>
          <!--
          <v-layout row wrap>
            <v-flex xs12 sm12>
              <v-text-field
                class="box-border"
                append-icon="search"
                @click:append="newSearch"
                v-model="queryName"
                :label="$t('search')"
                type="text"
                autocomplete="off"
                box
                :autofocus="$isDesktop()"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-chip class="white font-weight-bold">All</v-chip>
          <v-chip class="white font-weight-bold grey--text z-filter-chip">
            <v-avatar class="light-green">
              <v-icon class="white--text">done</v-icon>
            </v-avatar>Current
          </v-chip>
          <v-chip class="white font-weight-bold grey--text z-filter-chip">
            <v-avatar class="pink accent-3">
              <v-icon class="white--text">expand_less</v-icon>
            </v-avatar>Past
          </v-chip>
          <v-chip class="white font-weight-bold grey--text z-filter-chip">
            <v-avatar class="grey">
              <v-icon class="white--text">date_range</v-icon>
            </v-avatar>Date
          </v-chip>

          -->

          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>

          <template v-if="!isLoading && totalResults">
            <ZProperty
              :key="item.id"
              v-for="item in properties"
              :property="item"
              :to="'/applicant/properties/' + item.id"
            ></ZProperty>
          </template>

          <!-- property-list -->
          <template v-if="!totalResults && !isLoading">
            <h3 class="mt-2 mb-5 title">No matching properties found.</h3>
          </template>

          <template v-if="totalResults && !isLoading">
            <div class="mt-3 grey--text text-xs-center">
              Results {{ currentPage * perPage - perPage + 1 }} -
              {{ Math.min(currentPage * perPage, totalResults) }} of
              {{ totalResults }} properties
            </div>

            <v-pagination
              v-model="currentPage"
              @input="changePage"
              :length="totalPages"
              :total-visible="6"
              circle
              v-if="totalPages > 1"
            ></v-pagination>
          </template>
        </v-card>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import ZProperty from '@/components/ZProperty'

export default {
  title: 'Properties',
  data() {
    return {
      queryName: null,
      checkbox: false,
      isLoading: true,
      currentPage: 1,
      perPage: 25,
      totalPages: null,
      totalResults: null,
      properties: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  components: { ZProperty },
  methods: {
    stopPropagation(e) {
      e.stopPropagation()
    },
    changePage(page) {
      this.$scrollTo('#app')
      this.currentPage = page
      this.loadProperties()
    },
    newSearch() {
      this.currentPage = 1
      this.loadProperties()
    },
    loadProperties() {
      this.isLoading = true
      this.$http
        .get('/applicant/properties', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            status: 'all',
          },
        })
        .then(response => {
          if (response && response.data) {
            this.properties = response.data.properties
            this.totalResults = response.data.metadata.total
            this.totalPages = Math.ceil(this.totalResults / this.perPage)
            if (!this.totalResults) {
              this.$router.push('/applicant/properties/')
            } else {
              this.$scrollTo('#app')
            }
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.$toast(err.response.data.detail, {
              color: 'red',
              timeout: 10000,
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadProperties()
  },
}
</script>
