<template>
  <div>
    <template v-if="step > titleStep">
      {{ title }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="ml-2" @click="$emit('edit')">
            <img src="@/assets/edit.svg" />
          </v-btn>
        </template>
        <span>{{ $t('edit') }}</span>
      </v-tooltip>
    </template>
    <h2 class="title indigo--text text--darken-4" v-else-if="step == titleStep">{{ title }}</h2>
    <span v-else>{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: {
    titleStep: Number,
    title: String,
    step: Number,
  },
}
</script>