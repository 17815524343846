var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: "" } }),
      _c("tev-subnavbar", {
        attrs: { back: "/accountant/properties", title: "Bank Account Setup" }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-4": "" } }, [
                    _c("div", { staticClass: "font-weight-bold heading" }, [
                      _vm._v("Icon Brickell Condo No1")
                    ]),
                    _c("div", { staticClass: "grey--text" }, [
                      _vm._v(_vm._s(_vm.$t("property_name")))
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs12: "", "mb-4": "" } }, [
                    _vm._v(
                      "How do you want to connect your property bank account"
                    )
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mb-4": "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "align-space-between": "",
                            "justify-space-between": "",
                            row: "",
                            "fill-height": "",
                            white: ""
                          }
                        },
                        [
                          _c("v-flex", { attrs: { "pa-3": "" } }, [
                            _c(
                              "h3",
                              {
                                staticClass: "primary--text font-weight-regular"
                              },
                              [_vm._v("Using online banking credentials")]
                            ),
                            _c("div", { staticClass: "font-weight-bold" }, [
                              _vm._v("Immediate bank verification")
                            ]),
                            _c("div", [
                              _vm._v(
                                "Make sure you have the online user and password of the online banking of the property bank account."
                              )
                            ])
                          ]),
                          _c(
                            "v-layout",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: {
                                "align-center": "",
                                "fill-height": "",
                                column: "",
                                white: "",
                                "pa-3": "",
                                grey: "",
                                "lighten-3": "",
                                "light-green--text": ""
                              }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "checkbox-green",
                                attrs: { value: false, color: "light-green" }
                              }),
                              _c("div", [_vm._v(_vm._s(_vm.$t("select")))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mb-4": "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "align-space-between": "",
                            "justify-space-between": "",
                            row: "",
                            "fill-height": "",
                            white: ""
                          }
                        },
                        [
                          _c("v-flex", { attrs: { "pa-3": "" } }, [
                            _c(
                              "h3",
                              {
                                staticClass: "primary--text font-weight-regular"
                              },
                              [_vm._v("Bank account manual set up")]
                            ),
                            _c("div", [
                              _vm._v(
                                "Manually add the routing and account number of the property bank account."
                              )
                            ]),
                            _c("div", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "Bank verification may take up to 3 business days"
                              )
                            ])
                          ]),
                          _c(
                            "v-layout",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: {
                                "align-center": "",
                                "fill-height": "",
                                column: "",
                                white: "",
                                "pa-3": "",
                                grey: "",
                                "lighten-3": "",
                                "light-green--text": ""
                              }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "checkbox-green",
                                attrs: { value: false, color: "light-green" }
                              }),
                              _c("div", [_vm._v(_vm._s(_vm.$t("select")))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }