<template>
  <v-avatar class="letter-icon" :class="{'medium': size == 'medium'}">{{ letters }}</v-avatar>
</template>
<script>
export default {
  props: ["name", "size"],
  data() {
    return {};
  },
  computed: {
    letters() {
      let parts = String(this.name).split(" ");
      let text = "";
      if (parts[0]) {
        text = parts[0].substring(0, 1);
      }
      if (parts[1]) {
        text += parts[1].substring(0, 1);
      }
      return text.toUpperCase();
    }
  }
};
</script>
<style>
.letter-icon {
  background-color: #cfd8dc !important;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.letter-icon.medium {
  width: 64px !important;
  height: 64px !important;
  font-size: 20px;
}
</style>
