import {
    ACTION_TYPES,
    MUTATION_TYPES
} from '../libs/constants'

export default {
    [ACTION_TYPES.LOGIN]({
        commit
    }) {
        commit(MUTATION_TYPES.LOGIN)
    },

    [ACTION_TYPES.LOGOUT]({
        commit
    }) {
        commit(MUTATION_TYPES.LOGOUT)
    },

    [ACTION_TYPES.UPDATE_USER_NOTIFICATIONS]({
        commit
    }, payload) {
        commit(MUTATION_TYPES.UPDATE_USER_NOTIFICATIONS, payload)
    },

    [ACTION_TYPES.RESET_USER_NOTIFICATIONS]({
        commit
    }) {
        commit(MUTATION_TYPES.RESET_USER_NOTIFICATIONS)
    },
}