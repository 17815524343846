var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-myprofile page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("my_profile") } }),
      _c("tev-subnavbar", { attrs: { title: _vm.$t("my_digital_id") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm6: "" } },
                    [
                      _c("z-digital-id-card"),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "transparent",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "v-list-tile",
                                { attrs: { avatar: "" } },
                                [
                                  _c(
                                    "v-list-tile-avatar",
                                    { attrs: { size: "16" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/ico-user.svg")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v("Michelle Hendley")
                                      ]),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Name")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                { attrs: { avatar: "" } },
                                [
                                  _c(
                                    "v-list-tile-avatar",
                                    { attrs: { size: "16" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/ico-phone.svg")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v("305.000.0000")
                                      ]),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Phone number")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                { attrs: { avatar: "" } },
                                [
                                  _c(
                                    "v-list-tile-avatar",
                                    { attrs: { size: "16" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/ico-email.svg")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v("michelle@mail.com")
                                      ]),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v(_vm._s(_vm.$t("email")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                { attrs: { avatar: "" } },
                                [
                                  _c(
                                    "v-list-tile-avatar",
                                    { attrs: { size: "16" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/ico-org.svg")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v("Tenant Evaluation")
                                      ]),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v(_vm._s(_vm.$t("organization")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                color: "light-green",
                                dark: "",
                                depressed: "",
                                block: "",
                                large: ""
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("share_id")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }