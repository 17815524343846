var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("transactions") } }),
      _c("tev-subnavbar", {
        attrs: { title: _vm.$t("current_transactions_list") }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "mb-5 text-xs-center",
                      attrs: { xs12: "", sm12: "" }
                    },
                    [
                      _vm._l(_vm.transactions, function(item) {
                        return [
                          _c(
                            "v-card",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple",
                                  value: { class: "light-green--text" },
                                  expression: "{ class: 'light-green--text' }"
                                }
                              ],
                              key: item.id,
                              staticClass: "card-tx"
                            },
                            [
                              _c("v-card-title", [
                                _vm._v(_vm._s(item.fullName))
                              ]),
                              _c("v-divider"),
                              _c("v-card-actions", [
                                _vm._v(
                                  _vm._s(_vm.$t(item.status.toLowerCase()))
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }