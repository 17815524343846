<template>
  <v-app>
    <tev-navbar title="Transactions" />

    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8>
            <div class="text-xs-center" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>

            <v-flex v-if="!isLoading && balance" xs12 sm12 mb-4>
              <v-card class="z-card mb-4">
                <v-card-text class="pa-0">
                  <v-list two-line class="transparent pa-0">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          <strong>{{ balance.name }}</strong>
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Account Name</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title><strong>{{ balance.balance.value | currency }}</strong></v-list-tile-title>
                        <v-list-tile-sub-title>Total</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                   <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ balance.dateFmt }}</v-list-tile-title>
                        <v-list-tile-sub-title>Balance Last Update</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>                        
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ balance.id }}</v-list-tile-title>
                        <v-list-tile-sub-title>Balance ID</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ balance.type }}</v-list-tile-title>
                        <v-list-tile-sub-title>Balance Type</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>                                                       
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>

            <z-table-transactions v-if="!isLoading && items" :items="items"></z-table-transactions>

          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import ZTableTransactions from '../../components/ZTableTransactions.vue'

export default {
  title: 'Transactions',
  data() {
    return {
      isLoading: false,
      balance: null,
      propertyId: null,
      bankId: null,
      managers: [],
      bank: null,
      property: null,
      items:[],

    }
  },
  mixins: [Alerts],
  components: {
    'z-table-transactions': ZTableTransactions
  },

  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },

  methods: {

    loadBalance() {
      this.isLoading = true
      this.$http
        .get('/admin/balance')
        .then(res => {
          this.balance = res.data;
          this.balance.dateFmt = new Date(this.balance.lastUpdated).toLocaleString('en-US')
          this.loadTransactions()
        })
        .catch(err => {
          this.isLoading = false;          
          this.$log.error(err)
        })
    },

    loadTransactions() {
      this.$http
        .get('/admin/transactions')
        .then(response => {
          this.items = response.data.items
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })        
    },

   },
  mounted() {

    this.loadBalance()
    //this.loadBank()

    //this.loadStatus()
    //this.loadBalance()
  },
}
</script>

<style lang="scss">
.primary--border {
  border-color: #2896f3;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
</style>
