<template>
  <v-app>
    <tev-navbar :title="$t('profile')" />

    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8 md6>
            <v-layout row wrap align-start justify-start>
              <v-btn icon class="light-green--text" @click="$router.back()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                &nbsp;
                <template v-if="!isLoading">ID: {{ currentUser.userId }}</template>
              </v-flex>
            </v-layout>
            <z-digital-id-card cardName="Digital ID" />

            <v-tabs grow color="transparent" slider-color="primary">
              <v-tab ripple>My Personal Info</v-tab>
              <v-tab ripple></v-tab>
              <v-tab-item>
                <v-divider></v-divider>
                <v-flex xs12 sm12 mb-4>
                  <v-list two-line class="transparent py-0">
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-user.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '140px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">{{ currentUser.fullName }}</v-list-tile-title>
                        <v-list-tile-sub-title>Name</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-phone.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '120px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title
                          v-if="profile && !isLoading"
                        >+{{ profile.dialCode }} {{ profile.cellPhone }}</v-list-tile-title>
                        <v-list-tile-sub-title>Phone number</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon flat small color="info"
                        to="/account/phone"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-tile-action>                          
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-email.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '160px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">{{ currentUser.email }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('email') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-password.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '160px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">****************</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('password') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon flat small color="info"
                        to="/account/password"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-tile-action>                    
                    </v-list-tile>
                    <v-divider></v-divider>                  
                  </v-list>
                </v-flex>
              </v-tab-item>
            </v-tabs>

          </v-flex>
        </v-layout>

        <v-layout v-if="profile && !isLoading" row wrap pt-4>
          <v-flex x12 sm8 md6>
            <h3 class="text-uppercase grey--text text--lighten-1 mb-3">
              Email Notifications
            </h3>

            <v-card class="elevation-0 rounded notify_card">
              <v-list subheader two-line>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Transactions</v-list-tile-title>
                    <v-list-tile-sub-title>Notifications about your bank transactions</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-switch 
                      v-model="profile.notifyTransactions" 
                      v-on:change="changeNotification('notifyTransactions')">
                    </v-switch>
                  </v-list-tile-action>
                </v-list-tile>

                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Account</v-list-tile-title>
                    <v-list-tile-sub-title>Notifications about your Zinc account</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-switch 
                      v-model="profile.notifyAccount" 
                      v-on:change="changeNotification('notifyAccount')">
                    </v-switch>
                  </v-list-tile-action>
                </v-list-tile>

                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Marketing</v-list-tile-title>
                    <v-list-tile-sub-title>Zinc announcements</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-switch 
                      v-model="profile.notifyMarketing" 
                      v-on:change="changeNotification('notifyMarketing')">
                    </v-switch>
                  </v-list-tile-action>
                </v-list-tile>

              </v-list>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap pt-4>
          <v-flex xs12 sm12>
            <v-container>
              <v-layout row wrap>
                <!--
                <v-flex xs12 class="text-xs-center">
                  <z-avatar class="mb-3" @cropView="updateView" />
                </v-flex>
                -->
                <v-flex xs12 sm6 class="text-xs-center" v-if="!cropView">
                  <!--
                  <v-btn
                    outline
                    class="mb-3"
                    color="primary"
                    large
                    block
                    depressed
                    to="/account/notifications"
                  >{{ $t('notifications') }}</v-btn>
                  <v-btn
                    outline
                    class="mb-3"
                    color="primary"
                    large
                    block
                    depressed
                    to="/account/preferences"
                  >{{ $t('preferences') }}</v-btn>
                  -->

                  <v-btn outline color="primary" large block depressed to="/logout">{{ $t('logout') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      <z-footer :dark="false"></z-footer>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DigitalIdCard from '@/components/DigitalIdCard'
import Alerts from '@/mixin/Alerts'
//import ZAvatar from '@/components/ZAvatar'

export default {
  title: 'Profile',
  data() {
    return {
      isLoading: false,
      cropView: false,
      profile: null,
      temp: true,
    }
  },
  mixins: [Alerts],  
  components: {
    'z-digital-id-card': DigitalIdCard,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    updateView(isCropView) {
      this.cropView = isCropView
    },
    loadAccount() {
      this.isLoading = true
      this.$http
        .get('/account/profile')
        .then(res => {
          this.profile = res.data
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/')
        })
        .finally(() => {
          this.isLoading = false
        })
    },   
    
    changeNotification(notificationName) {

      if (this.isLoading) {
        return
      }
      this.isLoading = true
 
      if (
        !notificationName
      ) {
        return this.toastError('Notification not found.')
      }

      this.$http
        .post('/account/notifications/change', {
          notificationName: notificationName,
          notificationValue: this.profile[notificationName],
        })
        .then(() => {
          this.toastSuccess('Your change has been saved successfully.')
        })
        .catch(err => {
          this.isLoading = false          
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },

  mounted() {
    this.loadAccount()
  }
}
</script>

<style lang="scss">
.addressIdenticon {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  background-color: #212121;
  margin: 0 auto;
}

label.addressIdenticon {
  margin-bottom: -0.9rem;
}

.addressIdenticon.big {
  width: 5rem;
  height: 5rem;
  padding: 0;
}

.addressIdenticon.med {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
}

.addressIdenticon.small {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.addressIdenticon.inline {
  display: inline-block;
}

.addressIdenticon.float {
  float: left;
}

.notify_card {
  padding-left: 20px;

  .accent--text {
    color: #8bc53f!important;
  }
}

@media (max-width: 600px) {
  .notify_card {
    padding-left: 10px;
  }
}
</style>
