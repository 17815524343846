var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    {
      staticClass: "z-status-chip v-chip--disabled text-lowercase",
      attrs: { color: "grey lighten-3", "text-color": "grey" }
    },
    [
      _c(
        "v-avatar",
        [
          _c(
            "v-icon",
            { attrs: { color: _vm.statusColor[_vm.status], size: "10" } },
            [_vm._v("lens")]
          )
        ],
        1
      ),
      _vm._v(" " + _vm._s(_vm.label) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }