var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "z-digital-id", attrs: { xs12: "", sm12: "", column: "" } },
    [
      _c("div", { staticClass: "z-digital-id__head" }, [
        _c("span", [_vm._v(_vm._s(_vm.cardName))])
      ]),
      _c("div", { staticClass: "z-digital-id__body" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }