var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("member_details") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "", md6: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-start": "",
                            "justify-start": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "light-green--text",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.back()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                "py-3": "",
                                "text-xs-right": "",
                                "grey--text": ""
                              }
                            },
                            [
                              _vm._v(" "),
                              !_vm.isLoading
                                ? [_vm._v("ID: " + _vm._s(_vm.member.id))]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c("z-digital-id-card", {
                        attrs: { cardName: "Digital ID" }
                      }),
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            grow: "",
                            color: "transparent",
                            "slider-color": "primary"
                          }
                        },
                        [
                          _c("v-tab", { attrs: { ripple: "" } }, [
                            _vm._v("Personal Info")
                          ]),
                          _c("v-tab", { attrs: { ripple: "" } }),
                          _c(
                            "v-tab-item",
                            [
                              _c("v-divider"),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent py-0",
                                      attrs: { "two-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-tile",
                                        { attrs: { avatar: "" } },
                                        [
                                          _c(
                                            "v-list-tile-avatar",
                                            { attrs: { size: "16" } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/ico-user.svg")
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _vm.isLoading
                                                ? _c(
                                                    "v-list-tile-title",
                                                    [
                                                      _c(
                                                        "skeleton-loading",
                                                        [
                                                          _c(
                                                            "square-skeleton",
                                                            {
                                                              attrs: {
                                                                boxProperties: {
                                                                  width:
                                                                    "140px",
                                                                  height: "14px"
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.isLoading
                                                ? _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.member.fullName
                                                      )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Name")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        { attrs: { avatar: "" } },
                                        [
                                          _c(
                                            "v-list-tile-avatar",
                                            { attrs: { size: "16" } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/ico-phone.svg")
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _vm.isLoading
                                                ? _c(
                                                    "v-list-tile-title",
                                                    [
                                                      _c(
                                                        "skeleton-loading",
                                                        [
                                                          _c(
                                                            "square-skeleton",
                                                            {
                                                              attrs: {
                                                                boxProperties: {
                                                                  width:
                                                                    "120px",
                                                                  height: "14px"
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.isLoading
                                                ? _c("v-list-tile-title", [
                                                    _vm._v(
                                                      "+" +
                                                        _vm._s(
                                                          _vm.member.dialCode
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.member.cellPhone
                                                        )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Phone number")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        { attrs: { avatar: "" } },
                                        [
                                          _c(
                                            "v-list-tile-avatar",
                                            { attrs: { size: "16" } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/ico-email.svg")
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _vm.isLoading
                                                ? _c(
                                                    "v-list-tile-title",
                                                    [
                                                      _c(
                                                        "skeleton-loading",
                                                        [
                                                          _c(
                                                            "square-skeleton",
                                                            {
                                                              attrs: {
                                                                boxProperties: {
                                                                  width:
                                                                    "160px",
                                                                  height: "14px"
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.isLoading
                                                ? _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(_vm.member.email)
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(_vm._s(_vm.$t("email")))
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }