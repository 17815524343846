var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "auto-signature" }, [
        _c("div", { staticClass: "result-section" }, [
          _c(
            "div",
            {
              staticClass: "result",
              style: { "font-family": _vm.selectedFont }
            },
            [_vm._v(_vm._s(_vm.signatureTextProp))]
          ),
          _c("canvas", {
            ref: "textCanvas",
            staticStyle: { display: "none" },
            attrs: { height: "130", width: "455" }
          })
        ])
      ]),
      _vm.signatureTextProp
        ? _c(
            "v-btn",
            {
              attrs: { color: "primary", small: "", dark: "", depressed: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.signatureSelectVisible = true
                }
              }
            },
            [_vm._v(" Select signature style ")]
          )
        : _vm._e(),
      _vm.signatureSelectVisible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.outSideClicked,
                  expression: "outSideClicked"
                }
              ],
              ref: "selectSection",
              staticClass: "select-section"
            },
            [
              _c(
                "div",
                { staticClass: "styles" },
                _vm._l(_vm.fonts, function(font, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.selectFont(font, index)
                          }
                        }
                      },
                      [
                        _c(
                          "fieldset",
                          { class: { active: font.selected, fieldset: true } },
                          [
                            _c("legend", [
                              _vm._v("Style " + _vm._s(index + 1))
                            ]),
                            _c("div", { style: { "font-family": font.name } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.signatureTextProp || "Test Signature"
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }