var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: "Email Templates" } }),
      _c(
        "v-content",
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "text-xs-center pa-5 mb-5" },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-start": "",
                        "fill-height": "",
                        "mb-3": ""
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "light-green--text",
                          attrs: { icon: "", to: "/admin/email-templates" }
                        },
                        [_c("v-icon", [_vm._v("arrow_back")])],
                        1
                      ),
                      _c(
                        "h3",
                        { staticClass: "heading pt-2 ml-3" },
                        [
                          _vm._v(" Template: "),
                          _vm.emailTemplate.name
                            ? [_vm._v(_vm._s(_vm.emailTemplate.name))]
                            : [_vm._v("Untitled")]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "pa-1": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("name"),
                                      type: "text",
                                      box: "",
                                      autocomplete: "off",
                                      autofocus: _vm.$isDesktop()
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.emailTemplate.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.emailTemplate, "name", $$v)
                                      },
                                      expression: "emailTemplate.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "pa-1": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "slug",
                                      type: "text",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.emailTemplate.slug,
                                      callback: function($$v) {
                                        _vm.$set(_vm.emailTemplate, "slug", $$v)
                                      },
                                      expression: "emailTemplate.slug"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "pa-1": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "language",
                                      type: "text",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.emailTemplate.lang,
                                      callback: function($$v) {
                                        _vm.$set(_vm.emailTemplate, "lang", $$v)
                                      },
                                      expression: "emailTemplate.lang"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "pa-1": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("subject"),
                                      type: "text",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.emailTemplate.subject,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.emailTemplate,
                                          "subject",
                                          $$v
                                        )
                                      },
                                      expression: "emailTemplate.subject"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pa-1": "" } },
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: { "fixed-tabs": "" },
                                      model: {
                                        value: _vm.tabEditor,
                                        callback: function($$v) {
                                          _vm.tabEditor = $$v
                                        },
                                        expression: "tabEditor"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#tab-html" } },
                                        [_vm._v("HTML")]
                                      ),
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#tab-plain" } },
                                        [_vm._v("Plain Text")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.tabEditor == "tab-html"
                                    ? _c(
                                        "div",
                                        [
                                          _c("prism-editor", {
                                            attrs: {
                                              language: "html",
                                              lineNumbers: false
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.emailTemplate.messageHtml,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emailTemplate,
                                                  "messageHtml",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emailTemplate.messageHtml"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tabEditor == "tab-plain"
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-textarea", {
                                            attrs: { box: "", rows: "16" },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.emailTemplate.messagePlain,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emailTemplate,
                                                  "messagePlain",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emailTemplate.messagePlain"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        loading: _vm.isSaving,
                                        color: "primary",
                                        dark: "",
                                        depressed: "",
                                        large: ""
                                      },
                                      on: { click: _vm.saveEmailTemplate }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("save")))]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        color: "primary",
                                        flat: "",
                                        depressed: "",
                                        large: "",
                                        to: "/admin/email-templates"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "px-2": "" } },
                        [
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.emailTemplate.messageHtml !== null
                              ? _c("iframe", {
                                  ref: "previewTpl",
                                  staticClass: "preview-tpl elevation-1",
                                  attrs: { src: "about:blank" }
                                })
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }