var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fullSchema &&
    _vm.fullSchema.const === undefined &&
    _vm.fullSchema["x-display"] !== "hidden"
    ? _c(
        "v-flex",
        { class: _vm.propertyClass, style: _vm.fullSchema["x-style"] || "" },
        [
          _vm._t("prepend-" + _vm.slotName, null, {
            fullKey: _vm.fullKey,
            fullSchema: _vm.fullSchema,
            modelWrapper: _vm.modelWrapper,
            modelKey: _vm.modelKey,
            model: _vm.modelWrapper[_vm.modelKey],
            required: _vm.required,
            disabled: _vm.disabled,
            rule: _vm.rules,
            htmlDescription: _vm.htmlDescription
          }),
          _vm._t(
            _vm.slotName,
            [
              _vm.fullSchema.type === "string" &&
              ["date", "date-time"].includes(_vm.fullSchema.format)
                ? _c(
                    "v-menu",
                    {
                      ref: "menu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        "return-value": _vm.modelWrapper[_vm.modelKey],
                        disabled: _vm.disabled,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px"
                      },
                      on: {
                        "update:returnValue": function($event) {
                          return _vm.$set(
                            _vm.modelWrapper,
                            _vm.modelKey,
                            $event
                          )
                        },
                        "update:return-value": function($event) {
                          return _vm.$set(
                            _vm.modelWrapper,
                            _vm.modelKey,
                            $event
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      class: _vm.fullSchema["x-field-class"],
                                      attrs: {
                                        label: _vm.label,
                                        name: _vm.fullKey,
                                        required: _vm.required,
                                        rules: _vm.rules,
                                        clearable: !_vm.required,
                                        "append-icon":
                                          _vm.options.icons.calendar,
                                        color:
                                          _vm.fullSchema["x-color"] ||
                                          _vm.color,
                                        "hide-details": !!_vm.fullSchema[
                                          "x-hide-details"
                                        ],
                                        hint: _vm.fullSchema["x-hint"],
                                        placeholder:
                                          _vm.fullSchema["x-placeholder"],
                                        autofocus:
                                          _vm.fullSchema["x-autofocus"],
                                        readonly: _vm.fullSchema["x-readonly"],
                                        dark: !!_vm.fullSchema["x-dark"],
                                        dense: _vm.fullSchema["x-dense"],
                                        flat: _vm.fullSchema["x-flat"],
                                        solo: _vm.fullSchema["x-solo"],
                                        "solo-inverted":
                                          _vm.fullSchema["x-solo-inverted"],
                                        shaped: _vm.fullSchema["x-shaped"],
                                        rounded: _vm.fullSchema["x-rounded"],
                                        filled: _vm.fullSchema["x-filled"],
                                        outlined: _vm.fullSchema["x-outlined"],
                                        box: !!_vm.fullSchema["x-box"],
                                        "background-color":
                                          _vm.fullSchema["x-background-color"]
                                      },
                                      model: {
                                        value: _vm.modelWrapper[_vm.modelKey],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.modelWrapper,
                                            _vm.modelKey,
                                            $$v
                                          )
                                        },
                                        expression: "modelWrapper[modelKey]"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.htmlDescription
                                      ? _c("tooltip", {
                                          attrs: {
                                            slot: "append-outer",
                                            options: _vm.options,
                                            "html-description":
                                              _vm.htmlDescription
                                          },
                                          slot: "append-outer"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3701318851
                      ),
                      model: {
                        value: _vm.menu,
                        callback: function($$v) {
                          _vm.menu = $$v
                        },
                        expression: "menu"
                      }
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            scrollable: "",
                            landscape: _vm.fullSchema["x-landscape"],
                            locale: _vm.options.locale
                          },
                          model: {
                            value: _vm.modelWrapper[_vm.modelKey],
                            callback: function($$v) {
                              _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                            },
                            expression: "modelWrapper[modelKey]"
                          }
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "v-btn--flat",
                              style: _vm.oldFlat,
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  _vm.menu = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "v-btn--flat primary--text",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  _vm.$refs.menu.save(
                                    _vm.modelWrapper[_vm.modelKey]
                                  )
                                  _vm.change()
                                  _vm.input()
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.fullSchema.type === "string" &&
                  _vm.fullSchema.format === "time"
                ? _c(
                    "v-menu",
                    {
                      ref: "menu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        "return-value": _vm.modelWrapper[_vm.modelKey],
                        disabled: _vm.disabled,
                        transition: "scale-transition",
                        "offset-y": "",
                        "full-width": "",
                        "min-width": "290px"
                      },
                      on: {
                        "update:returnValue": function($event) {
                          return _vm.$set(
                            _vm.modelWrapper,
                            _vm.modelKey,
                            $event
                          )
                        },
                        "update:return-value": function($event) {
                          return _vm.$set(
                            _vm.modelWrapper,
                            _vm.modelKey,
                            $event
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      class: _vm.fullSchema["x-field-class"],
                                      attrs: {
                                        label: _vm.label,
                                        name: _vm.fullKey,
                                        required: _vm.required,
                                        rules: _vm.rules,
                                        clearable: !_vm.required,
                                        "prepend-icon": _vm.options.icons.clock,
                                        readonly: "",
                                        color:
                                          _vm.fullSchema["x-color"] ||
                                          _vm.color,
                                        "hide-details": !!_vm.fullSchema[
                                          "x-hide-details"
                                        ],
                                        hint: _vm.fullSchema["x-hint"],
                                        placeholder:
                                          _vm.fullSchema["x-placeholder"],
                                        autofocus:
                                          _vm.fullSchema["x-autofocus"],
                                        dark: !!_vm.fullSchema["x-dark"],
                                        dense: _vm.fullSchema["x-dense"],
                                        flat: _vm.fullSchema["x-flat"],
                                        solo: _vm.fullSchema["x-solo"],
                                        "solo-inverted":
                                          _vm.fullSchema["x-solo-inverted"],
                                        shaped: _vm.fullSchema["x-shaped"],
                                        rounded: _vm.fullSchema["x-rounded"],
                                        filled: _vm.fullSchema["x-filled"],
                                        outlined: _vm.fullSchema["x-outlined"],
                                        box: !!_vm.fullSchema["x-box"],
                                        "background-color":
                                          _vm.fullSchema["x-background-color"]
                                      },
                                      model: {
                                        value: _vm.modelWrapper[_vm.modelKey],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.modelWrapper,
                                            _vm.modelKey,
                                            $$v
                                          )
                                        },
                                        expression: "modelWrapper[modelKey]"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.htmlDescription
                                      ? _c("tooltip", {
                                          attrs: {
                                            slot: "append-outer",
                                            options: _vm.options,
                                            "html-description":
                                              _vm.htmlDescription
                                          },
                                          slot: "append-outer"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2436424344
                      ),
                      model: {
                        value: _vm.menu,
                        callback: function($$v) {
                          _vm.menu = $$v
                        },
                        expression: "menu"
                      }
                    },
                    [
                      _c(
                        "v-time-picker",
                        {
                          model: {
                            value: _vm.modelWrapper[_vm.modelKey],
                            callback: function($$v) {
                              _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                            },
                            expression: "modelWrapper[modelKey]"
                          }
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "v-btn--flat",
                              style: _vm.oldFlat,
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  _vm.menu = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "v-btn--flat primary--text",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  _vm.$refs.menu.save(
                                    _vm.modelWrapper[_vm.modelKey]
                                  )
                                  _vm.change()
                                  _vm.input()
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.fullSchema.format === "hexcolor"
                ? [
                    _vm.fullSchema["x-display"] === "color-picker"
                      ? [
                          _c(
                            "v-input",
                            {
                              attrs: {
                                name: _vm.fullKey,
                                label: _vm.label,
                                required: _vm.required,
                                rules: _vm.rules,
                                disabled: _vm.disabled
                              }
                            },
                            [
                              _vm.htmlDescription
                                ? _c("tooltip", {
                                    attrs: {
                                      slot: "append",
                                      options: _vm.options,
                                      "html-description": _vm.htmlDescription
                                    },
                                    slot: "append"
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "close-on-click": true,
                                    direction: "bottom",
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "color-picker-trigger",
                                                  class: {
                                                    "color-picker-trigger-empty": !_vm
                                                      .modelWrapper[
                                                      _vm.modelKey
                                                    ],
                                                    "color-picker-trigger-dense":
                                                      _vm.fullSchema["x-dense"]
                                                  },
                                                  style:
                                                    "background-color: " +
                                                    _vm.modelWrapper[
                                                      _vm.modelKey
                                                    ] +
                                                    ";"
                                                },
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    914859716
                                  )
                                },
                                [
                                  _c("v-color-picker", {
                                    attrs: {
                                      value: _vm.modelWrapper[_vm.modelKey],
                                      "preset-colors":
                                        _vm.options.colors.swatches
                                    },
                                    on: {
                                      input: function(val) {
                                        _vm.modelWrapper[_vm.modelKey] = val.hex
                                        _vm.input()
                                        _vm.change()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _c(
                          "v-input",
                          {
                            attrs: {
                              name: _vm.fullKey,
                              label: _vm.label,
                              required: _vm.required,
                              rules: _vm.rules,
                              disabled: _vm.disabled
                            }
                          },
                          [
                            _vm.htmlDescription
                              ? _c("tooltip", {
                                  attrs: {
                                    slot: "append",
                                    options: _vm.options,
                                    "html-description": _vm.htmlDescription
                                  },
                                  slot: "append"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("swatches", {
                              attrs: {
                                disabled: _vm.disabled,
                                colors: _vm.options.colors,
                                "trigger-style": {
                                  width: "36px",
                                  height: "36px"
                                },
                                shapes: "circles"
                              },
                              on: {
                                input: function($event) {
                                  _vm.input()
                                  _vm.change()
                                }
                              },
                              model: {
                                value: _vm.modelWrapper[_vm.modelKey],
                                callback: function($$v) {
                                  _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                                },
                                expression: "modelWrapper[modelKey]"
                              }
                            })
                          ],
                          1
                        )
                  ]
                : (_vm.fullSchema.type === "array" &&
                    _vm.fullSchema.items.enum &&
                    _vm.fullSchema.items.type === "object") ||
                  (_vm.fullSchema.enum && _vm.fullSchema.type === "object")
                ? [
                    _c(
                      "v-select",
                      {
                        class: _vm.fullSchema["x-field-class"],
                        attrs: {
                          items: _vm.selectItems,
                          name: _vm.fullKey,
                          label: _vm.label,
                          required: _vm.required,
                          rules: _vm.rules,
                          disabled: _vm.disabled,
                          clearable: !_vm.required,
                          multiple: _vm.fullSchema.type === "array",
                          "item-text": _vm.itemTitle,
                          "item-value": _vm.itemKey,
                          "return-object": true,
                          chips: !!_vm.fullSchema["x-chips"],
                          "deletable-chips": !!_vm.fullSchema[
                            "x-deletable-chips"
                          ],
                          "small-chips": !!_vm.fullSchema["x-small-chips"],
                          "hide-selected": !!_vm.fullSchema["x-hide-selected"],
                          placeholder: _vm.fullSchema["x-placeholder"],
                          color: _vm.fullSchema["x-color"] || _vm.color,
                          "hide-details": !!_vm.fullSchema["x-hide-details"],
                          hint: _vm.fullSchema["x-hint"],
                          autofocus: _vm.fullSchema["x-autofocus"],
                          readonly: _vm.fullSchema["x-readonly"],
                          dark: !!_vm.fullSchema["x-dark"],
                          dense: _vm.fullSchema["x-dense"],
                          flat: _vm.fullSchema["x-flat"],
                          solo: _vm.fullSchema["x-solo"],
                          "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                          shaped: _vm.fullSchema["x-shaped"],
                          rounded: _vm.fullSchema["x-rounded"],
                          filled: _vm.fullSchema["x-filled"],
                          outlined: _vm.fullSchema["x-outlined"],
                          box: !!_vm.fullSchema["x-box"],
                          "background-color":
                            _vm.fullSchema["x-background-color"]
                        },
                        on: { change: _vm.change, input: _vm.input },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-select__selection v-select__selection--comma"
                                    },
                                    [
                                      _vm.itemIcon
                                        ? _c("select-icon", {
                                            attrs: {
                                              value: data.item[_vm.itemIcon]
                                            }
                                          })
                                        : _vm._e(),
                                      ![null, undefined].includes(
                                        data.item[_vm.itemTitle]
                                      )
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.item[_vm.itemTitle] +
                                                    (_vm.fullSchema.type ===
                                                      "array" &&
                                                    data.index !==
                                                      _vm.modelWrapper[
                                                        _vm.modelKey
                                                      ].length -
                                                        1
                                                      ? ", "
                                                      : "")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _vm.itemIcon
                                    ? _c("select-icon", {
                                        attrs: {
                                          value: data.item[_vm.itemIcon]
                                        }
                                      })
                                    : _vm._e(),
                                  _c("select-item", {
                                    attrs: {
                                      title: data.item[_vm.itemTitle],
                                      options: _vm.options
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3103966844
                        ),
                        model: {
                          value: _vm.modelWrapper[_vm.modelKey],
                          callback: function($$v) {
                            _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                          },
                          expression: "modelWrapper[modelKey]"
                        }
                      },
                      [
                        _vm.htmlDescription
                          ? _c("tooltip", {
                              attrs: {
                                slot: "append-outer",
                                options: _vm.options,
                                "html-description": _vm.htmlDescription
                              },
                              slot: "append-outer"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : (_vm.fullSchema.type === "array" &&
                    _vm.fullSchema.items.enum) ||
                  _vm.fullSchema.enum
                ? [
                    _c(
                      "v-select",
                      {
                        class: _vm.fullSchema["x-field-class"],
                        attrs: {
                          items: _vm.selectItems,
                          name: _vm.fullKey,
                          label: _vm.label,
                          required: _vm.required,
                          rules: _vm.rules,
                          disabled: _vm.disabled,
                          clearable: !_vm.required,
                          multiple: _vm.fullSchema.type === "array",
                          chips: !!_vm.fullSchema["x-chips"],
                          "deletable-chips": !!_vm.fullSchema[
                            "x-deletable-chips"
                          ],
                          "small-chips": !!_vm.fullSchema["x-small-chips"],
                          "hide-selected": !!_vm.fullSchema["x-hide-selected"],
                          placeholder: _vm.fullSchema["x-placeholder"],
                          color: _vm.fullSchema["x-color"] || _vm.color,
                          "hide-details": !!_vm.fullSchema["x-hide-details"],
                          hint: _vm.fullSchema["x-hint"],
                          autofocus: _vm.fullSchema["x-autofocus"],
                          readonly: _vm.fullSchema["x-readonly"],
                          dark: !!_vm.fullSchema["x-dark"],
                          dense: _vm.fullSchema["x-dense"],
                          flat: _vm.fullSchema["x-flat"],
                          solo: _vm.fullSchema["x-solo"],
                          "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                          shaped: _vm.fullSchema["x-shaped"],
                          rounded: _vm.fullSchema["x-rounded"],
                          filled: _vm.fullSchema["x-filled"],
                          outlined: _vm.fullSchema["x-outlined"],
                          box: !!_vm.fullSchema["x-box"],
                          "background-color":
                            _vm.fullSchema["x-background-color"]
                        },
                        on: { change: _vm.change, input: _vm.input },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-select__selection v-select__selection--comma"
                                    },
                                    [
                                      _vm.itemIcon
                                        ? _c("select-icon", {
                                            attrs: { value: data.item }
                                          })
                                        : _vm._e(),
                                      ![null, undefined].includes(data.item)
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.item +
                                                    (_vm.fullSchema.type ===
                                                      "array" &&
                                                    data.index !==
                                                      _vm.modelWrapper[
                                                        _vm.modelKey
                                                      ].length -
                                                        1
                                                      ? ", "
                                                      : "")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _vm.itemIcon
                                    ? _c("select-icon", {
                                        attrs: { value: data.item }
                                      })
                                    : _vm._e(),
                                  _c("select-item", {
                                    attrs: {
                                      title: data.item,
                                      options: _vm.options
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2069927823
                        ),
                        model: {
                          value: _vm.modelWrapper[_vm.modelKey],
                          callback: function($$v) {
                            _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                          },
                          expression: "modelWrapper[modelKey]"
                        }
                      },
                      [
                        _vm.htmlDescription
                          ? _c("tooltip", {
                              attrs: {
                                slot: "append-outer",
                                options: _vm.options,
                                "html-description": _vm.htmlDescription
                              },
                              slot: "append-outer"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm.oneOfSelect
                ? [
                    _c(
                      "v-select",
                      {
                        class: _vm.fullSchema["x-field-class"],
                        attrs: {
                          items: _vm.selectItems,
                          name: _vm.fullKey,
                          label: _vm.label,
                          required: _vm.required,
                          disabled: _vm.disabled,
                          rules: _vm.rules,
                          clearable: !_vm.required,
                          multiple: _vm.fullSchema.type === "array",
                          "item-text": _vm.itemTitle,
                          "item-value": _vm.itemKey,
                          chips: !!_vm.fullSchema["x-chips"],
                          "deletable-chips": !!_vm.fullSchema[
                            "x-deletable-chips"
                          ],
                          "small-chips": !!_vm.fullSchema["x-small-chips"],
                          "hide-selected": !!_vm.fullSchema["x-hide-selected"],
                          placeholder: _vm.fullSchema["x-placeholder"],
                          color: _vm.fullSchema["x-color"] || _vm.color,
                          "hide-details": !!_vm.fullSchema["x-hide-details"],
                          hint: _vm.fullSchema["x-hint"],
                          autofocus: _vm.fullSchema["x-autofocus"],
                          readonly: _vm.fullSchema["x-readonly"],
                          dark: !!_vm.fullSchema["x-dark"],
                          dense: _vm.fullSchema["x-dense"],
                          flat: _vm.fullSchema["x-flat"],
                          solo: _vm.fullSchema["x-solo"],
                          "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                          shaped: _vm.fullSchema["x-shaped"],
                          rounded: _vm.fullSchema["x-rounded"],
                          filled: _vm.fullSchema["x-filled"],
                          outlined: _vm.fullSchema["x-outlined"],
                          box: !!_vm.fullSchema["x-box"],
                          "background-color":
                            _vm.fullSchema["x-background-color"]
                        },
                        on: { change: _vm.change, input: _vm.input },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-select__selection v-select__selection--comma"
                                    },
                                    [
                                      _vm.itemIcon
                                        ? _c("select-icon", {
                                            attrs: {
                                              value: data.item[_vm.itemIcon]
                                            }
                                          })
                                        : _vm._e(),
                                      ![null, undefined].includes(
                                        data.item[_vm.itemTitle]
                                      )
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.item[_vm.itemTitle] +
                                                    (_vm.fullSchema.type ===
                                                      "array" &&
                                                    data.index !==
                                                      _vm.modelWrapper[
                                                        _vm.modelKey
                                                      ].length -
                                                        1
                                                      ? ", "
                                                      : "")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _vm.itemIcon
                                    ? _c("select-icon", {
                                        attrs: {
                                          value: data.item[_vm.itemIcon]
                                        }
                                      })
                                    : _vm._e(),
                                  _c("select-item", {
                                    attrs: {
                                      title: data.item[_vm.itemTitle],
                                      options: _vm.options
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3103966844
                        ),
                        model: {
                          value: _vm.modelWrapper[_vm.modelKey],
                          callback: function($$v) {
                            _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                          },
                          expression: "modelWrapper[modelKey]"
                        }
                      },
                      [
                        _vm.htmlDescription
                          ? _c("tooltip", {
                              attrs: {
                                slot: "append-outer",
                                options: _vm.options,
                                "html-description": _vm.htmlDescription
                              },
                              slot: "append-outer"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm.fullSchema["x-display"] !== "list" &&
                  (_vm.fromUrl || _vm.fullSchema["x-fromData"])
                ? _c(
                    "v-select",
                    {
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        items: _vm.selectItems,
                        name: _vm.fullKey,
                        label: _vm.label,
                        "no-data-text": _vm.options.noDataMessage,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        "item-text": _vm.itemTitle,
                        "item-value": _vm.itemKey,
                        "return-object":
                          (_vm.fullSchema.type === "array" &&
                            _vm.fullSchema.items.type === "object") ||
                          _vm.fullSchema.type === "object",
                        clearable: !_vm.required,
                        loading: _vm.loading,
                        multiple: _vm.fullSchema.type === "array",
                        chips: !!_vm.fullSchema["x-chips"],
                        "deletable-chips": !!_vm.fullSchema[
                          "x-deletable-chips"
                        ],
                        "small-chips": !!_vm.fullSchema["x-small-chips"],
                        "hide-selected": !!_vm.fullSchema["x-hide-selected"],
                        color: _vm.fullSchema["x-color"] || _vm.color,
                        "hide-details": !!_vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        placeholder: _vm.fullSchema["x-placeholder"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        box: !!_vm.fullSchema["x-box"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-select__selection v-select__selection--comma"
                                  },
                                  [
                                    _vm.itemIcon
                                      ? _c("select-icon", {
                                          attrs: {
                                            value: data.item[_vm.itemIcon]
                                          }
                                        })
                                      : _vm._e(),
                                    ![null, undefined].includes(
                                      data.item[_vm.itemTitle]
                                    )
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.item[_vm.itemTitle] +
                                                  (_vm.fullSchema.type ===
                                                    "array" &&
                                                  data.index !==
                                                    _vm.modelWrapper[
                                                      _vm.modelKey
                                                    ].length -
                                                      1
                                                    ? ", "
                                                    : "")
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _vm.itemIcon
                                  ? _c("select-icon", {
                                      attrs: { value: data.item[_vm.itemIcon] }
                                    })
                                  : _vm._e(),
                                _c("select-item", {
                                  attrs: {
                                    title: data.item[_vm.itemTitle],
                                    options: _vm.options
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3103966844
                      ),
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append-outer",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append-outer"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fromUrlWithQuery
                ? _c(
                    "v-autocomplete",
                    {
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        items: _vm.selectItems,
                        "search-input": _vm.q,
                        name: _vm.fullKey,
                        label: _vm.label,
                        "no-data-text": _vm.options.noDataMessage,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        "item-text": _vm.itemTitle,
                        "item-value": _vm.itemKey,
                        "return-object":
                          (_vm.fullSchema.type === "array" &&
                            _vm.fullSchema.items.type === "object") ||
                          _vm.fullSchema.type === "object",
                        clearable: !_vm.required,
                        filter: function() {
                          return true
                        },
                        loading: _vm.loading,
                        multiple: _vm.fullSchema.type === "array",
                        autocomplete: "off",
                        "auto-select-first": "",
                        "cache-items": !!_vm.fullSchema["x-cache-items"],
                        chips: !!_vm.fullSchema["x-chips"],
                        "deletable-chips": !!_vm.fullSchema[
                          "x-deletable-chips"
                        ],
                        "small-chips": !!_vm.fullSchema["x-small-chips"],
                        "hide-selected": !!_vm.fullSchema["x-hide-selected"],
                        placeholder: _vm.fullSchema["x-placeholder"],
                        color: _vm.fullSchema["x-color"] || _vm.color,
                        "hide-details": !!_vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        box: !!_vm.fullSchema["x-box"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: {
                        "update:searchInput": function($event) {
                          _vm.q = $event
                        },
                        "update:search-input": function($event) {
                          _vm.q = $event
                        },
                        change: _vm.change,
                        input: _vm.input
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                !_vm.fullSchema["x-chips"] &&
                                !_vm.fullSchema["x-small-chips"]
                                  ? [
                                      _vm.itemIcon
                                        ? _c("select-icon", {
                                            attrs: {
                                              value: data.item[_vm.itemIcon]
                                            }
                                          })
                                        : _vm._e(),
                                      ![null, undefined].includes(
                                        data.item[_vm.itemTitle]
                                      )
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.item[_vm.itemTitle] +
                                                    (_vm.fullSchema.type ===
                                                      "array" &&
                                                    data.index !==
                                                      _vm.modelWrapper[
                                                        _vm.modelKey
                                                      ].length -
                                                        1
                                                      ? ", "
                                                      : "")
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  : [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: !!_vm.fullSchema[
                                              "x-small-chips"
                                            ]
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item[_vm.itemTitle]) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _vm.itemIcon
                                  ? _c("select-icon", {
                                      attrs: { value: data.item[_vm.itemIcon] }
                                    })
                                  : _vm._e(),
                                _c("select-item", {
                                  attrs: {
                                    title: data.item[_vm.itemTitle],
                                    options: _vm.options
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2097467125
                      ),
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append-outer",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append-outer"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema.type === "string" &&
                  _vm.fullSchema.maxLength &&
                  _vm.fullSchema.maxLength > 1000 &&
                  _vm.fullSchema["x-display"] !== "single-line"
                ? _c(
                    "v-textarea",
                    {
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        name: _vm.fullKey,
                        label: _vm.label,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        placeholder: _vm.fullSchema["x-placeholder"],
                        color: _vm.fullSchema["x-color"] || _vm.color,
                        "auto-grow": !!_vm.fullSchema["x-auto-grow"],
                        "no-resize": !!_vm.fullSchema["x-no-resize"],
                        "hide-details": !!_vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        box: !!_vm.fullSchema["x-box"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append-outer",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append-outer"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema.type === "string" &&
                  _vm.fullSchema["x-display"] === "fileinput"
                ? [
                    _c("file-upload", {
                      class: {
                        "error--text":
                          _vm.$refs[_vm.fullKey + "-field"] &&
                          _vm.$refs[_vm.fullKey + "-field"].validationState ==
                            "error"
                      },
                      attrs: {
                        label: _vm.label,
                        "allow-multiple": !!_vm.fullSchema["x-multiple"],
                        "accepted-file-types": _vm.fullSchema["x-accept"],
                        allowFileEncode: true
                      },
                      on: {
                        input: function($event) {
                          _vm.modelWrapper[_vm.modelKey] = arguments[0]
                        }
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "v-text-field__details" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "v-messages theme--light error--text"
                          },
                          [
                            _c("div", { staticClass: "v-messages__wrapper" }, [
                              _c(
                                "div",
                                { staticClass: "v-messages__message" },
                                [
                                  _vm.$refs[_vm.fullKey + "-field"] &&
                                  _vm.$refs[_vm.fullKey + "-field"]
                                    .validationState == "error"
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$refs[_vm.fullKey + "-field"]
                                              .validations[0]
                                          )
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          ]
                        ),
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          ref: _vm.fullKey + "-field",
                          attrs: {
                            value: _vm.modelWrapper[_vm.modelKey],
                            name: _vm.fullKey,
                            label: _vm.label,
                            disabled: _vm.disabled,
                            required: _vm.required,
                            rules: _vm.rules
                          },
                          on: { change: _vm.input }
                        })
                      ],
                      1
                    )
                  ]
                : _vm.fullSchema.type === "string" &&
                  _vm.fullSchema["x-display"] === "password"
                ? _c(
                    "v-text-field",
                    {
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        name: _vm.fullKey,
                        label: _vm.label,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        type: "password",
                        placeholder: _vm.fullSchema["x-placeholder"],
                        color: _vm.fullSchema["x-color"] || _vm.color,
                        "hide-details": !!_vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        box: !!_vm.fullSchema["x-box"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append-outer",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append-outer"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema.type === "string" && _vm.fullSchema["x-mask"]
                ? _c(
                    "v-text-field",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: _vm.fullSchema["x-mask"],
                          expression: "fullSchema['x-mask']"
                        }
                      ],
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        name: _vm.fullKey,
                        label: _vm.label,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        color: _vm.fullSchema["x-color"] || _vm.color,
                        "hide-details": !!_vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        placeholder: _vm.fullSchema["x-placeholder"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        box: !!_vm.fullSchema["x-box"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _c("tooltip", {
                        attrs: {
                          slot: "append-outer",
                          options: _vm.options,
                          "html-description": _vm.htmlDescription
                        },
                        slot: "append-outer"
                      })
                    ],
                    1
                  )
                : _vm.fullSchema.type === "string"
                ? _c(
                    "v-text-field",
                    {
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        name: _vm.fullKey,
                        label: _vm.label,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        color: _vm.fullSchema["x-color"] || _vm.color,
                        "hide-details": !!_vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        placeholder: _vm.fullSchema["x-placeholder"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        box: !!_vm.fullSchema["x-box"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append-outer",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append-outer"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema["x-display"] === "rating" &&
                  (_vm.fullSchema.type === "number" ||
                    _vm.fullSchema.type === "integer")
                ? [
                    _c("v-rating", {
                      attrs: {
                        color:
                          _vm.$refs[_vm.fullKey + "-field"] &&
                          _vm.$refs[_vm.fullKey + "-field"].validationState ==
                            "error"
                            ? "red"
                            : _vm.fullSchema["x-color"] || "orange",
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    }),
                    _c("div", { staticClass: "v-text-field__details" }, [
                      _c(
                        "div",
                        { staticClass: "v-messages theme--light error--text" },
                        [
                          _c("div", { staticClass: "v-messages__wrapper" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "v-messages__message text-center text-xs-center"
                              },
                              [
                                _vm.$refs[_vm.fullKey + "-field"] &&
                                _vm.$refs[_vm.fullKey + "-field"]
                                  .validationState == "error"
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$refs[_vm.fullKey + "-field"]
                                            .validations[0]
                                        )
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    ]),
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      ref: _vm.fullKey + "-field",
                      attrs: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        name: _vm.fullKey,
                        label: _vm.label,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules
                      },
                      on: { change: _vm.input }
                    })
                  ]
                : _vm.fullSchema["x-display"] === "slider" &&
                  (_vm.fullSchema.type === "number" ||
                    _vm.fullSchema.type === "integer")
                ? _c(
                    "v-slider",
                    {
                      attrs: {
                        name: _vm.fullKey,
                        label: _vm.label,
                        min: _vm.fullSchema.minimum,
                        max: _vm.fullSchema.maximum,
                        step:
                          _vm.fullSchema["x-step"] ||
                          (_vm.fullSchema.type === "integer" ? 1 : 0.01),
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        "thumb-label": ""
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, _vm._n($$v))
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema.type === "number" ||
                  _vm.fullSchema.type === "integer"
                ? _c(
                    "v-text-field",
                    {
                      attrs: {
                        name: _vm.fullKey,
                        label: _vm.label,
                        min: _vm.fullSchema.minimum,
                        max: _vm.fullSchema.maximum,
                        step:
                          _vm.fullSchema["x-step"] ||
                          (_vm.fullSchema.type === "integer" ? 1 : 0.01),
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        type: "number",
                        "hide-details": _vm.fullSchema["x-hide-details"],
                        hint: _vm.fullSchema["x-hint"],
                        placeholder: _vm.fullSchema["x-placeholder"],
                        autofocus: _vm.fullSchema["x-autofocus"],
                        readonly: _vm.fullSchema["x-readonly"],
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        flat: _vm.fullSchema["x-flat"],
                        solo: _vm.fullSchema["x-solo"],
                        "solo-inverted": _vm.fullSchema["x-solo-inverted"],
                        shaped: _vm.fullSchema["x-shaped"],
                        rounded: _vm.fullSchema["x-rounded"],
                        filled: _vm.fullSchema["x-filled"],
                        outlined: _vm.fullSchema["x-outlined"],
                        "background-color": _vm.fullSchema["x-background-color"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, _vm._n($$v))
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append-outer",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append-outer"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema.type === "boolean"
                ? _c(
                    "v-checkbox",
                    {
                      class: _vm.fullSchema["x-field-class"],
                      attrs: {
                        label: _vm.label,
                        name: _vm.fullKey,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        rules: _vm.rules,
                        color: _vm.fullSchema["x-color"] || "primary",
                        dark: !!_vm.fullSchema["x-dark"],
                        dense: _vm.fullSchema["x-dense"],
                        light: _vm.fullSchema["x-light"],
                        indeterminate: _vm.fullSchema["x-indeterminate"]
                      },
                      on: { change: _vm.change, input: _vm.input },
                      model: {
                        value: _vm.modelWrapper[_vm.modelKey],
                        callback: function($$v) {
                          _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                        },
                        expression: "modelWrapper[modelKey]"
                      }
                    },
                    [
                      _vm.htmlDescription
                        ? _c("tooltip", {
                            attrs: {
                              slot: "append",
                              options: _vm.options,
                              "html-description": _vm.htmlDescription
                            },
                            slot: "append"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.fullSchema.type === "array" &&
                  _vm.fullSchema.items.type === "string"
                ? [
                    _c(
                      "v-combobox",
                      {
                        attrs: {
                          name: _vm.fullKey,
                          label: _vm.label,
                          required: _vm.required,
                          rules: _vm.rules,
                          disabled: _vm.disabled,
                          chips: "",
                          multiple: "",
                          "append-icon": ""
                        },
                        on: { change: _vm.change, input: _vm.input },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { close: "" },
                                      on: {
                                        input: function($event) {
                                          _vm.modelWrapper[_vm.modelKey].splice(
                                            data.index,
                                            1
                                          )
                                          _vm.change()
                                          _vm.input()
                                        },
                                        "click:close": function($event) {
                                          _vm.modelWrapper[_vm.modelKey].splice(
                                            data.index,
                                            1
                                          )
                                          _vm.change()
                                          _vm.input()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(data.item))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          739428362
                        ),
                        model: {
                          value: _vm.modelWrapper[_vm.modelKey],
                          callback: function($$v) {
                            _vm.$set(_vm.modelWrapper, _vm.modelKey, $$v)
                          },
                          expression: "modelWrapper[modelKey]"
                        }
                      },
                      [
                        _vm.htmlDescription
                          ? _c("tooltip", {
                              attrs: {
                                slot: "append-outer",
                                options: _vm.options,
                                "html-description": _vm.htmlDescription
                              },
                              slot: "append-outer"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm.fullSchema.type === "object"
                ? [
                    _vm.fullSchema.title
                      ? _c(
                          "v-subheader",
                          {
                            staticClass: "mt-2",
                            style: _vm.foldable ? "cursor:pointer;" : "",
                            on: {
                              click: function($event) {
                                _vm.folded = !_vm.folded
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.fullSchema.title) + " "),
                            _vm.foldable && _vm.folded
                              ? _c("v-icon", [
                                  _vm._v(_vm._s(_vm.options.icons.dropdown))
                                ])
                              : _vm._e(),
                            _vm.foldable && !_vm.folded
                              ? _c("v-icon", [
                                  _vm._v(_vm._s(_vm.options.icons.dropup))
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-slide-y-transition",
                      [
                        _c(
                          "v-layout",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.foldable || !_vm.folded,
                                expression: "!foldable || !folded"
                              }
                            ],
                            staticClass: "ma-0",
                            attrs: { row: "", wrap: "" }
                          },
                          [
                            _vm.fullSchema.description
                              ? _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.htmlDescription)
                                  }
                                })
                              : _vm._e(),
                            _vm._l(_vm.fullSchema.properties, function(
                              childProp
                            ) {
                              return _c(
                                "property",
                                {
                                  key: childProp.key,
                                  attrs: {
                                    schema: childProp,
                                    "model-wrapper":
                                      _vm.modelWrapper[_vm.modelKey],
                                    "model-root": _vm.modelRoot,
                                    "model-key": childProp.key,
                                    "parent-key": _vm.fullKey + ".",
                                    required: !!(
                                      _vm.fullSchema.required &&
                                      _vm.fullSchema.required.includes(
                                        childProp.key
                                      )
                                    ),
                                    options: _vm.options
                                  },
                                  on: {
                                    error: function(e) {
                                      return _vm.$emit("error", e)
                                    },
                                    change: function(e) {
                                      return _vm.$emit("change", e)
                                    },
                                    input: function(e) {
                                      return _vm.$emit("input", e)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(_vm.$scopedSlots, function(
                                        _,
                                        name
                                      ) {
                                        return {
                                          key: name,
                                          fn: function(slotData) {
                                            return [
                                              _vm._t(name, null, null, slotData)
                                            ]
                                          }
                                        }
                                      })
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._l(_vm.$slots, function(_, name) {
                                    return _vm._t(name, null, { slot: name })
                                  })
                                ],
                                2
                              )
                            }),
                            _vm.fullSchema.allOf && _vm.fullSchema.allOf.length
                              ? [
                                  !_vm.parentKey &&
                                  _vm.fullSchema.allOf[0].title
                                    ? [
                                        _vm.options.allOfTabs
                                          ? _c(
                                              "v-tabs",
                                              {
                                                attrs: {
                                                  "show-arrows": "",
                                                  grow: _vm.options.tabsMode.includes(
                                                    "grow"
                                                  ),
                                                  centered: _vm.options.tabsMode.includes(
                                                    "centered"
                                                  ),
                                                  vertical: _vm.options.tabsMode.includes(
                                                    "vertical"
                                                  )
                                                }
                                              },
                                              [
                                                _c("v-tabs-slider"),
                                                _vm._l(
                                                  _vm.fullSchema.allOf,
                                                  function(currentAllOf, i) {
                                                    return _c(
                                                      "v-tab",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          href: "#tab-" + i
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            currentAllOf.title
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._l(
                                                  _vm.fullSchema.allOf,
                                                  function(currentAllOf, i) {
                                                    return _c(
                                                      "v-tab-item",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: "tab-" + i
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "property",
                                                          {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              schema: Object.assign(
                                                                {},
                                                                currentAllOf,
                                                                {
                                                                  type:
                                                                    "object",
                                                                  title: null
                                                                }
                                                              ),
                                                              "model-wrapper":
                                                                _vm.subModels,
                                                              "model-root":
                                                                _vm.modelRoot,
                                                              "model-key":
                                                                "allOf-" + i,
                                                              "parent-key":
                                                                _vm.parentKey,
                                                              options:
                                                                _vm.options,
                                                              color: _vm.color
                                                            },
                                                            on: {
                                                              error: function(
                                                                e
                                                              ) {
                                                                return _vm.$emit(
                                                                  "error",
                                                                  e
                                                                )
                                                              },
                                                              change: function(
                                                                e
                                                              ) {
                                                                return _vm.$emit(
                                                                  "change",
                                                                  e
                                                                )
                                                              },
                                                              input: function(
                                                                e
                                                              ) {
                                                                return _vm.$emit(
                                                                  "input",
                                                                  e
                                                                )
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                _vm._l(
                                                                  _vm.$scopedSlots,
                                                                  function(
                                                                    _,
                                                                    name
                                                                  ) {
                                                                    return {
                                                                      key: name,
                                                                      fn: function(
                                                                        slotData
                                                                      ) {
                                                                        return [
                                                                          _vm._t(
                                                                            name,
                                                                            null,
                                                                            null,
                                                                            slotData
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._l(
                                                              _vm.$slots,
                                                              function(
                                                                _,
                                                                name
                                                              ) {
                                                                return _vm._t(
                                                                  name,
                                                                  null,
                                                                  { slot: name }
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm.options.vuetifyVersion === 2
                                          ? _c(
                                              "v-expansion-panels",
                                              {
                                                attrs: {
                                                  inset:
                                                    _vm.options
                                                      .accordionMode ===
                                                    "inset",
                                                  popout:
                                                    _vm.options
                                                      .accordionMode ===
                                                    "popout",
                                                  focusable: ""
                                                }
                                              },
                                              _vm._l(
                                                _vm.fullSchema.allOf,
                                                function(currentAllOf, i) {
                                                  return _c(
                                                    "v-expansion-panel",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "v-expansion-panel-header",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              currentAllOf.title
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-expansion-panel-content",
                                                        { staticClass: "pt-2" },
                                                        [
                                                          _c(
                                                            "property",
                                                            {
                                                              attrs: {
                                                                schema: Object.assign(
                                                                  {},
                                                                  currentAllOf,
                                                                  {
                                                                    type:
                                                                      "object",
                                                                    title: null
                                                                  }
                                                                ),
                                                                "model-wrapper":
                                                                  _vm.subModels,
                                                                "model-root":
                                                                  _vm.modelRoot,
                                                                "model-key":
                                                                  "allOf-" + i,
                                                                "parent-key":
                                                                  _vm.parentKey,
                                                                options:
                                                                  _vm.options,
                                                                color: _vm.color
                                                              },
                                                              on: {
                                                                error: function(
                                                                  e
                                                                ) {
                                                                  return _vm.$emit(
                                                                    "error",
                                                                    e
                                                                  )
                                                                },
                                                                change: function(
                                                                  e
                                                                ) {
                                                                  return _vm.$emit(
                                                                    "change",
                                                                    e
                                                                  )
                                                                },
                                                                input: function(
                                                                  e
                                                                ) {
                                                                  return _vm.$emit(
                                                                    "input",
                                                                    e
                                                                  )
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  _vm._l(
                                                                    _vm.$scopedSlots,
                                                                    function(
                                                                      _,
                                                                      name
                                                                    ) {
                                                                      return {
                                                                        key: name,
                                                                        fn: function(
                                                                          slotData
                                                                        ) {
                                                                          return [
                                                                            _vm._t(
                                                                              name,
                                                                              null,
                                                                              null,
                                                                              slotData
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._l(
                                                                _vm.$slots,
                                                                function(
                                                                  _,
                                                                  name
                                                                ) {
                                                                  return _vm._t(
                                                                    name,
                                                                    null,
                                                                    {
                                                                      slot: name
                                                                    }
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c(
                                              "v-expansion-panel",
                                              {
                                                attrs: {
                                                  inset:
                                                    _vm.options
                                                      .accordionMode ===
                                                    "inset",
                                                  popout:
                                                    _vm.options
                                                      .accordionMode ===
                                                    "popout",
                                                  focusable: ""
                                                }
                                              },
                                              _vm._l(
                                                _vm.fullSchema.allOf,
                                                function(currentAllOf, i) {
                                                  return _c(
                                                    "v-expansion-panel-content",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold"
                                                          },
                                                          attrs: {
                                                            slot: "header"
                                                          },
                                                          slot: "header"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                currentAllOf.title
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-card",
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _c(
                                                                "property",
                                                                {
                                                                  attrs: {
                                                                    schema: Object.assign(
                                                                      {},
                                                                      currentAllOf,
                                                                      {
                                                                        type:
                                                                          "object",
                                                                        title: null
                                                                      }
                                                                    ),
                                                                    "model-wrapper":
                                                                      _vm.subModels,
                                                                    "model-root":
                                                                      _vm.modelRoot,
                                                                    "model-key":
                                                                      "allOf-" +
                                                                      i,
                                                                    "parent-key":
                                                                      _vm.parentKey,
                                                                    options:
                                                                      _vm.options
                                                                  },
                                                                  on: {
                                                                    error: function(
                                                                      e
                                                                    ) {
                                                                      return _vm.$emit(
                                                                        "error",
                                                                        e
                                                                      )
                                                                    },
                                                                    change: function(
                                                                      e
                                                                    ) {
                                                                      return _vm.$emit(
                                                                        "change",
                                                                        e
                                                                      )
                                                                    },
                                                                    input: function(
                                                                      e
                                                                    ) {
                                                                      return _vm.$emit(
                                                                        "input",
                                                                        e
                                                                      )
                                                                    }
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      _vm._l(
                                                                        _vm.$scopedSlots,
                                                                        function(
                                                                          _,
                                                                          name
                                                                        ) {
                                                                          return {
                                                                            key: name,
                                                                            fn: function(
                                                                              slotData
                                                                            ) {
                                                                              return [
                                                                                _vm._t(
                                                                                  name,
                                                                                  null,
                                                                                  null,
                                                                                  slotData
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    _vm.$slots,
                                                                    function(
                                                                      _,
                                                                      name
                                                                    ) {
                                                                      return _vm._t(
                                                                        name,
                                                                        null,
                                                                        {
                                                                          slot: name
                                                                        }
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                      ]
                                    : _vm._l(
                                        _vm.fullSchema.allOf || [],
                                        function(currentAllOf, i) {
                                          return _c(
                                            "property",
                                            {
                                              key: i,
                                              attrs: {
                                                schema: Object.assign(
                                                  {},
                                                  currentAllOf,
                                                  { type: "object" }
                                                ),
                                                "model-wrapper": _vm.subModels,
                                                "model-root": _vm.modelRoot,
                                                "model-key": "allOf-" + i,
                                                "parent-key": _vm.parentKey,
                                                options: _vm.options
                                              },
                                              on: {
                                                error: function(e) {
                                                  return _vm.$emit("error", e)
                                                },
                                                change: function(e) {
                                                  return _vm.$emit("change", e)
                                                },
                                                input: function(e) {
                                                  return _vm.$emit("input", e)
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  _vm._l(
                                                    _vm.$scopedSlots,
                                                    function(_, name) {
                                                      return {
                                                        key: name,
                                                        fn: function(slotData) {
                                                          return [
                                                            _vm._t(
                                                              name,
                                                              null,
                                                              null,
                                                              slotData
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    }
                                                  )
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._l(_vm.$slots, function(
                                                _,
                                                name
                                              ) {
                                                return _vm._t(name, null, {
                                                  slot: name
                                                })
                                              })
                                            ],
                                            2
                                          )
                                        }
                                      )
                                ]
                              : _vm._e(),
                            _vm.fullSchema.oneOf
                              ? [
                                  _c(
                                    "v-select",
                                    {
                                      class: _vm.fullSchema["x-field-class"],
                                      attrs: {
                                        items: _vm.fullSchema.oneOf,
                                        disabled: _vm.disabled,
                                        "item-value": function(item) {
                                          return _vm.oneOfConstProp
                                            ? item.properties[
                                                _vm.oneOfConstProp.key
                                              ].const
                                            : item.title
                                        },
                                        label: _vm.oneOfConstProp
                                          ? _vm.oneOfConstProp.title ||
                                            _vm.oneOfConstProp.key
                                          : "Type",
                                        required: _vm.oneOfRequired,
                                        clearable: !_vm.oneOfRequired,
                                        rules: _vm.oneOfRules,
                                        "item-text": "title",
                                        "return-object": "",
                                        chips: !!_vm.fullSchema["x-chips"],
                                        "deletable-chips": !!_vm.fullSchema[
                                          "x-deletable-chips"
                                        ],
                                        "small-chips": !!_vm.fullSchema[
                                          "x-small-chips"
                                        ],
                                        "hide-selected": !!_vm.fullSchema[
                                          "x-hide-selected"
                                        ],
                                        placeholder:
                                          _vm.fullSchema["x-placeholder"],
                                        color:
                                          _vm.fullSchema["x-color"] ||
                                          _vm.color,
                                        "hide-details": !!_vm.fullSchema[
                                          "x-hide-details"
                                        ],
                                        hint: _vm.fullSchema["x-hint"],
                                        autofocus:
                                          _vm.fullSchema["x-autofocus"],
                                        readonly: _vm.fullSchema["x-readonly"],
                                        dark: !!_vm.fullSchema["x-dark"],
                                        dense: _vm.fullSchema["x-dense"],
                                        flat: _vm.fullSchema["x-flat"],
                                        solo: _vm.fullSchema["x-solo"],
                                        "solo-inverted":
                                          _vm.fullSchema["x-solo-inverted"],
                                        shaped: _vm.fullSchema["x-shaped"],
                                        rounded: _vm.fullSchema["x-rounded"],
                                        filled: _vm.fullSchema["x-filled"],
                                        outlined: _vm.fullSchema["x-outlined"],
                                        "background-color":
                                          _vm.fullSchema["x-background-color"]
                                      },
                                      on: {
                                        change: _vm.change,
                                        input: _vm.input
                                      },
                                      model: {
                                        value: _vm.currentOneOf,
                                        callback: function($$v) {
                                          _vm.currentOneOf = $$v
                                        },
                                        expression: "currentOneOf"
                                      }
                                    },
                                    [
                                      _vm.htmlDescription
                                        ? _c("tooltip", {
                                            attrs: {
                                              slot: "append-outer",
                                              options: _vm.options,
                                              "html-description":
                                                _vm.oneOfConstProp &&
                                                _vm.oneOfConstProp
                                                  .htmlDescription
                                            },
                                            slot: "append-outer"
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.currentOneOf && _vm.showCurrentOneOf
                                    ? [
                                        _c(
                                          "property",
                                          {
                                            attrs: {
                                              schema: Object.assign(
                                                {},
                                                _vm.currentOneOf,
                                                {
                                                  title: null,
                                                  type: "object"
                                                }
                                              ),
                                              "model-wrapper": _vm.subModels,
                                              "model-root": _vm.modelRoot,
                                              "parent-key": _vm.parentKey,
                                              options: _vm.options,
                                              "model-key": "currentOneOf"
                                            },
                                            on: {
                                              error: function(e) {
                                                return _vm.$emit("error", e)
                                              },
                                              change: function(e) {
                                                return _vm.$emit("change", e)
                                              },
                                              input: function(e) {
                                                return _vm.$emit("input", e)
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                _vm._l(
                                                  _vm.$scopedSlots,
                                                  function(_, name) {
                                                    return {
                                                      key: name,
                                                      fn: function(slotData) {
                                                        return [
                                                          _vm._t(
                                                            name,
                                                            null,
                                                            null,
                                                            slotData
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  }
                                                )
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._l(_vm.$slots, function(
                                              _,
                                              name
                                            ) {
                                              return _vm._t(name, null, {
                                                slot: name
                                              })
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                : _vm.fullSchema.type === "array" &&
                  Array.isArray(_vm.fullSchema.items)
                ? [
                    _vm.fullSchema.title
                      ? _c(
                          "v-subheader",
                          {
                            staticClass: "mt-2",
                            style: _vm.foldable ? "cursor:pointer;" : "",
                            on: {
                              click: function($event) {
                                _vm.folded = !_vm.folded
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.fullSchema.title) + " "),
                            _vm.foldable && _vm.folded
                              ? _c("v-icon", [
                                  _vm._v(_vm._s(_vm.options.icons.dropdown))
                                ])
                              : _vm._e(),
                            _vm.foldable && !_vm.folded
                              ? _c("v-icon", [
                                  _vm._v(_vm._s(_vm.options.icons.dropup))
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-slide-y-transition", [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.foldable || !_vm.folded,
                              expression: "!foldable || !folded"
                            }
                          ]
                        },
                        [
                          _vm.fullSchema.description
                            ? _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.htmlDescription)
                                }
                              })
                            : _vm._e(),
                          _vm._l(_vm.fullSchema.items, function(child, i) {
                            return _c(
                              "property",
                              {
                                key: i,
                                attrs: {
                                  schema: child,
                                  "model-wrapper":
                                    _vm.modelWrapper[_vm.modelKey],
                                  "model-root": _vm.modelRoot,
                                  "model-key": i,
                                  "parent-key": _vm.fullKey + ".",
                                  options: _vm.options
                                },
                                on: {
                                  error: function(e) {
                                    return _vm.$emit("error", e)
                                  },
                                  change: function(e) {
                                    return _vm.$emit("change", e)
                                  },
                                  input: function(e) {
                                    return _vm.$emit("input", e)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm._l(_vm.$scopedSlots, function(_, name) {
                                      return {
                                        key: name,
                                        fn: function(slotData) {
                                          return [
                                            _vm._t(name, null, null, slotData)
                                          ]
                                        }
                                      }
                                    })
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._l(_vm.$slots, function(_, name) {
                                  return _vm._t(name, null, { slot: name })
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]
                : _vm.fullSchema.type === "array"
                ? [
                    _c(
                      "v-layout",
                      { staticClass: "mt-2 mb-1 pr-1", attrs: { row: "" } },
                      [
                        _c("v-subheader", [_vm._v(_vm._s(_vm.label))]),
                        !_vm.disabled &&
                        !(_vm.fromUrl || _vm.fullSchema.fromData)
                          ? _c(
                              "v-btn",
                              {
                                attrs: { fab: "", small: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.modelWrapper[_vm.modelKey].push(
                                      _vm.fullSchema.items.default ||
                                        _vm.defaultValue(_vm.fullSchema.items)
                                    )
                                    _vm.change()
                                    _vm.input()
                                  }
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(_vm._s(_vm.options.icons.add))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-spacer"),
                        _vm.htmlDescription
                          ? _c("tooltip", {
                              attrs: {
                                options: _vm.options,
                                "html-description": _vm.htmlDescription
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.modelWrapper[_vm.modelKey] &&
                    _vm.modelWrapper[_vm.modelKey].length
                      ? _c(
                          "v-container",
                          {
                            staticClass: "pt-0 px-2",
                            attrs: { "grid-list-md": "" }
                          },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "ma-0",
                                attrs: { row: "", wrap: "" }
                              },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { handle: ".handle" },
                                    model: {
                                      value: _vm.modelWrapper[_vm.modelKey],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.modelWrapper,
                                          _vm.modelKey,
                                          $$v
                                        )
                                      },
                                      expression: "modelWrapper[modelKey]"
                                    }
                                  },
                                  _vm._l(
                                    _vm.modelWrapper[_vm.modelKey],
                                    function(itemModel, i) {
                                      return _c(
                                        "v-flex",
                                        { key: i, attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "array-card" },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  !_vm.disabled &&
                                                  _vm.fullSchema[
                                                    "x-sortable"
                                                  ] !== false
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "handle",
                                                          attrs: { icon: "" }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.options
                                                                  .icons.reorder
                                                              )
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.itemTitle &&
                                                  _vm.modelWrapper[
                                                    _vm.modelKey
                                                  ][i]
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.modelWrapper[
                                                                _vm.modelKey
                                                              ][i][
                                                                _vm.itemTitle
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _c("v-spacer"),
                                                  !_vm.disabled &&
                                                  !(
                                                    _vm.fromUrl ||
                                                    _vm.fullSchema.fromData
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            color: "warning"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.modelWrapper[
                                                                _vm.modelKey
                                                              ].splice(i, 1)
                                                              _vm.change()
                                                              _vm.input()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.options
                                                                  .icons.delete
                                                              )
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "property",
                                                    {
                                                      attrs: {
                                                        schema:
                                                          _vm.fullSchema.items,
                                                        "model-wrapper":
                                                          _vm.modelWrapper[
                                                            _vm.modelKey
                                                          ],
                                                        "model-root":
                                                          _vm.modelRoot,
                                                        "model-key": i,
                                                        "parent-key":
                                                          _vm.fullKey + ".",
                                                        options: _vm.options
                                                      },
                                                      on: {
                                                        error: function(e) {
                                                          return _vm.$emit(
                                                            "error",
                                                            e
                                                          )
                                                        },
                                                        change: function(e) {
                                                          return _vm.$emit(
                                                            "change",
                                                            e
                                                          )
                                                        },
                                                        input: function(e) {
                                                          return _vm.$emit(
                                                            "input",
                                                            e
                                                          )
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          _vm._l(
                                                            _vm.$scopedSlots,
                                                            function(_, name) {
                                                              return {
                                                                key: name,
                                                                fn: function(
                                                                  slotData
                                                                ) {
                                                                  return [
                                                                    _vm._t(
                                                                      name,
                                                                      null,
                                                                      null,
                                                                      slotData
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.$slots,
                                                        function(_, name) {
                                                          return _vm._t(
                                                            name,
                                                            null,
                                                            { slot: name }
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm.options.debug
                ? _c("p", [
                    _vm._v(
                      ' Unsupported type "' +
                        _vm._s(_vm.fullSchema.type) +
                        '" - ' +
                        _vm._s(_vm.fullSchema) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            {
              fullKey: _vm.fullKey,
              fullSchema: _vm.fullSchema,
              modelWrapper: _vm.modelWrapper,
              modelKey: _vm.modelKey,
              model: _vm.modelWrapper[_vm.modelKey],
              required: _vm.required,
              disabled: _vm.disabled,
              rule: _vm.rules,
              htmlDescription: _vm.htmlDescription
            }
          ),
          _vm._t("append-" + _vm.slotName, null, {
            fullKey: _vm.fullKey,
            fullSchema: _vm.fullSchema,
            modelWrapper: _vm.modelWrapper,
            modelKey: _vm.modelKey,
            model: _vm.modelWrapper[_vm.modelKey],
            required: _vm.required,
            disabled: _vm.disabled,
            rule: _vm.rules,
            htmlDescription: _vm.htmlDescription
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }