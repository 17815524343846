var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar"),
      _c("tev-subnavbar", {
        attrs: { back: "/manager/applications", title: "Application Detail" }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "transparent elevation-0",
                      attrs: { width: "600", "max-width": "100%" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "mb-3",
                          attrs: { "align-center": "", "justify-center": "" }
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "text-uppercase grey--text text--lighten-1"
                            },
                            [_vm._v(_vm._s(_vm.$t("applicant_info")))]
                          ),
                          _c("v-spacer"),
                          _c("z-status-chip", {
                            attrs: {
                              status: _vm.application.status,
                              label: _vm.$t(_vm.application.status)
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "z-card mb-4" },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "transparent pa-0",
                                  attrs: { "two-line": "" }
                                },
                                [
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.application.firstName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.application.lastName
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("applicant")) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.application.unitNumber
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Unit Number")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.application
                                                    .applicationType
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Type of Application")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.application.moveInDate,
                                                    "dddd, MMMM Do YYYY, h:mm:ss a"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Move In Date")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.application.moveOutDate
                                    ? [
                                        _c("v-divider"),
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          _vm.application
                                                            .moveOutDate,
                                                          "dddd, MMMM Do YYYY, h:mm:ss a"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("v-list-tile-sub-title", [
                                                  _vm._v("Move Out Date")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _c("v-divider"),
                                  _vm.application.monthlyRent
                                    ? _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.application
                                                          .monthlyRent
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Monthly Rent")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.application.pet) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Pet")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.application.cellPhone
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Cell Phone")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.application.email) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Email Address")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.application.createdAt,
                                                    "dddd, MMMM Do YYYY, h:mm:ss a"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Created")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-uppercase grey--text text--lighten-1 mb-3"
                        },
                        [_vm._v(_vm._s(_vm.$t("transactions")))]
                      ),
                      _vm._l(_vm.application.transactions, function(
                        transaction
                      ) {
                        return _c(
                          "v-card",
                          {
                            key: transaction.id,
                            staticClass: "z-card mb-4 pa-0"
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pa-0" },
                              [
                                _c(
                                  "v-list",
                                  {
                                    staticClass: "transparent pa-0",
                                    attrs: { "two-line": "" }
                                  },
                                  [
                                    _c(
                                      "v-list-tile",
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(transaction.feeName)
                                                )
                                              ])
                                            ]),
                                            _c("v-list-tile-sub-title", [
                                              _vm._v("Fee Description")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-list-tile",
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c(
                                              "v-list-tile-title",
                                              [
                                                transaction.contractType ==
                                                "deposit"
                                                  ? [_vm._v("Refundable")]
                                                  : [_vm._v("Non-Refundable")]
                                              ],
                                              2
                                            ),
                                            _c("v-list-tile-sub-title", [
                                              _vm._v("Type of Fee")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-list-tile",
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    transaction.bankAlias
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c("v-list-tile-sub-title", [
                                              _vm._v("Bank Account")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    transaction.submittedAt
                                      ? [
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            transaction.submittedAt,
                                                            "MM/DD/YYYY"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Date Submitted")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-tile-action")
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    transaction.collectedAt
                                      ? [
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            transaction.collectedAt,
                                                            "MM/DD/YYYY"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Date Collected")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-list-tile-action")
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    transaction.contractType == "deposit" &&
                                    transaction.refundedAt
                                      ? [
                                          _c("v-divider"),
                                          transaction.contractType == "deposit"
                                            ? _c(
                                                "v-list-tile",
                                                [
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c("v-list-tile-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                transaction.refundedAt,
                                                                "MM/DD/YYYY"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-list-tile-sub-title",
                                                        [_vm._v("Refund Date")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-action",
                                                    [
                                                      _c("countdown", {
                                                        attrs: {
                                                          time:
                                                            new Date(
                                                              transaction.refundedAt
                                                            ).getTime() -
                                                            new Date().getTime()
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                props
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "v-layout",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props.days
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-1 light-green--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "days"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props.hours
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-1 light-green--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "hours"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props.minutes
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-1 light-green--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "minutes"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src: require("@/assets/schedule.svg"),
                                                                            height:
                                                                              "22"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                [
                                  _c("v-divider"),
                                  transaction.contractType == "deposit"
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass: "py-3 text-xs-center",
                                          attrs: {
                                            row: "",
                                            "align-center": "",
                                            "justify-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "", sm6: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "text-uppercase body-2",
                                                  attrs: {
                                                    round: "",
                                                    depressed: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Extend Smart Contract"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "", sm6: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "text-uppercase body-2",
                                                  attrs: {
                                                    round: "",
                                                    depressed: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.newIncident(
                                                        transaction
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Document An Incident")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "light-green white--text pa-3",
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-uppercase body-1" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(transaction.status))
                                        ])
                                      ]
                                    ),
                                    _c("v-spacer"),
                                    transaction.feeRateType != "variable" &&
                                    transaction.amount != 0
                                      ? _c("strong", { staticClass: "title" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                transaction.amount
                                              )
                                            )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v("Need To Calculate")
                                        ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", "max-width": "580" },
          model: {
            value: _vm.dialogIncident,
            callback: function($$v) {
              _vm.dialogIncident = $$v
            },
            expression: "dialogIncident"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-toolbar",
                { staticClass: "transparent", attrs: { card: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "text-uppercase subheading primary--text" },
                    [_vm._v("Document An Incident")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogIncident = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "grey--text" },
                [
                  _c("p", [_vm._v("Describe the incident")]),
                  _c(
                    "div",
                    { staticClass: "pa-2 white mb-3" },
                    [
                      _c("v-textarea", {
                        staticClass: "pt-0",
                        attrs: {
                          "background-color": "white",
                          placeholder: "Add text here",
                          "hide-details": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "mb-3", attrs: { "align-start": "" } },
                    [
                      _vm._l(_vm.imagesToUpload, function(image, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "file-upload-thumb",
                            on: {
                              click: function($event) {
                                return _vm.imagesToUpload.splice(index, 1)
                              }
                            }
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                src: image,
                                width: "100",
                                height: "100",
                                "aspect-ratio": "1"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "file-remove" },
                              [
                                _c("v-icon", [_vm._v("remove_circle")]),
                                _vm._v("Remove ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm.imagesToUpload.length < 5
                        ? _c("div", { staticClass: "file-upload" }, [
                            _c(
                              "div",
                              {
                                staticClass: "input-wrapper",
                                staticStyle: { opacity: "1" }
                              },
                              [
                                _c("input", {
                                  staticClass:
                                    "input-with-value file-upload-input",
                                  attrs: {
                                    id: "file-upload-input",
                                    type: "file",
                                    name: "file",
                                    accept: ".png, .jpg"
                                  },
                                  on: { change: _vm.onFileChange }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "file-upload-label",
                                    attrs: { for: "file-upload-input" }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("add_circle")]),
                                    _vm._v("Add Image ")
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _c("p", [
                    _vm._v("Add the amount to be deducted from deposit")
                  ]),
                  _c(
                    "div",
                    { staticClass: "pa-2 white mb-4" },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: {
                          placeholder: "Add amount",
                          "background-color": "white",
                          "single-line": "",
                          "hide-details": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-layout", { staticClass: "py-2" }, [
                    _c("span", { staticClass: "black--text" }, [
                      _vm._v("Current Balance")
                    ]),
                    _c("span")
                  ]),
                  _c("v-divider", { staticClass: "mb-4" }),
                  _c("p", [
                    _vm._v("Client will get an email with all the details.")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-0", staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        color: "light-green",
                        large: "",
                        block: "",
                        dark: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.dialogConfirm = true
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "320" },
          model: {
            value: _vm.dialogConfirm,
            callback: function($$v) {
              _vm.dialogConfirm = $$v
            },
            expression: "dialogConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { staticClass: "transparent", attrs: { card: "" } },
                [
                  _c("v-toolbar-title", { staticClass: "black--text" }, [
                    _vm._v("Confirm Incident")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogConfirm = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _c("p", [_vm._v("This action cannot be undone.")])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogConfirm = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", color: "primary" },
                      on: { click: _vm.submitIncident }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }