<template>
  <v-card class="z-card pa-3 text-xs-left mb-3" :to="to" v-ripple="{class: 'primary--text'}">
    <v-layout row mb-2>
      <v-flex xs8>
        <div class="font-weight-bold">{{ property.name }}</div>
        <div class="grey--text body-1">Property</div>
      </v-flex>
      <v-flex></v-flex>
    </v-layout>
    <v-divider class="mb-2"></v-divider>
    <v-layout row>
      <v-flex xs8>
        <div>{{ property.moveInAddress}} - Unit {{ property.unitNumber}}</div>
        <div class="grey--text body-1">Address</div>
      </v-flex>
      <v-flex xs4>
        <v-icon color="grey" style="margin:10px 2px;float:right">chevron_right</v-icon>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: { to: String, property: Object },
  data() {
    return {}
  },
  computed: {
    address() {
      return this.property.address.postalCode
    },
  },
  methods: {
    statusClass(status) {
      let str = 'red--text'
      if (status == 'submitted') {
        str = 'primary--text'
      } else if (status == 'processed') {
        str = 'green--text'
      }

      return str
    },
  },
}
</script>