var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-content",
        { staticClass: "login-background" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "pt-5": "",
                "pb-5": ""
              }
            },
            [
              _c("z-logo", {
                staticClass: "logo-zinc",
                attrs: { color: "#ffffff", slogan: "" }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "px-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto", attrs: { "max-width": "500px" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "form-sm",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.sendPassword($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "login-container pa-0" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  !_vm.emailSent
                                    ? _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "headline indigo--text text--darken-2 font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("forgotyourpassword"))
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "transition",
                                        { attrs: { name: "fade" } },
                                        [
                                          _vm.emailSent
                                            ? [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: { row: "", wrap: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          sm12: "",
                                                          "text-xs-center": ""
                                                        }
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "email-sent-circle"
                                                        }),
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "email-sent-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Email Sent!"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass: "mb-4"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Check your email for a link to reset your password."
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-muted mb-5"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "If it doesn’t appear within a few minutes, check your spam folder."
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              depressed: "",
                                                              large: "",
                                                              color: "primary",
                                                              to: "/login"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Return to Sign In"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      !_vm.emailSent
                                        ? [
                                            _c("p", [
                                              _vm._v(
                                                "Enter your email address and we will send you a link to reset your password."
                                              )
                                            ]),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "", wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      sm12: ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        xs12: "",
                                                        type: "text",
                                                        rules: _vm.rules.email,
                                                        label: _vm.$t("email"),
                                                        "background-color":
                                                          "#F1F5F7",
                                                        required: "",
                                                        box: "",
                                                        autofocus: _vm.$isDesktop()
                                                      },
                                                      model: {
                                                        value: _vm.email,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.email = $$v
                                                        },
                                                        expression: "email"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      sm12: "",
                                                      "text-xs-center": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "primary mb-3",
                                                        class: {
                                                          "blue--text":
                                                            _vm.isLoading
                                                        },
                                                        attrs: {
                                                          type: "submit",
                                                          block: "",
                                                          large: "",
                                                          depressed: "",
                                                          loading:
                                                            _vm.isLoading,
                                                          disabled:
                                                            _vm.isLoading
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.sendPassword
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("send"))
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          block: "",
                                                          flat: "",
                                                          color: "primary",
                                                          to: "/login"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("cancel")
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("z-footer", { attrs: { dark: true } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }