<template>
  <div>
    <router-view></router-view>
    <!--div class="corner-ribbon right" v-if="VUE_APP_MODE != 'production'">
      {{ VUE_APP_MODE }} <span>v{{ version }}</span>
    </div-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ACTION_TYPES } from './libs/constants'
import { identify as logRocketIdentify } from './libs/logrocket'
import { version } from '../package.json'

export default {
  name: 'app',
  data: () => ({
    version: version,
  }),
  sockets: {
    connect() {
      this.$log.debug('socket connected!')

      // subscribe
      this.$socket.emit('subscribe', 'notifications')
    },
    notifications(msg) {
      this.$log.debug('New Notification!', msg)

      // Show notification

      /*
      this.$toast(msg, {
        color: 'green',
        timeout: 6000,
        position: 'top',
      })
      */
    },
    unauthorized(e) {
      this.$log.debug(e)
    },
  },
  watch: {
    currentUser(val) {
      logRocketIdentify(val)
    },
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
    VUE_APP_MODE() {
      return process.env.VUE_APP_MODE
    },
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_USER_NOTIFICATIONS]),
    loadNotifications() {
      this.$http
        .get('/account/notifications', {
          params: {},
        })
        .then(res => {
          this.$log.debug(res)

          // TODO: Añadir notificaciones
          if ('data' in res && 'notifications' in res.data) {
            this[ACTION_TYPES.UPDATE_USER_NOTIFICATIONS](res.data.notifications)
          }
        })
        .catch(err => {
          // eslint-disable-next-line
          this.$log.error(err)
        })
    },
  },
  beforeDestroy() {
    this.$socket.emit('unsubscribe', 'notifications')
  },
  mounted() {
    if (this.currentUser.isLoggedIn) {
      logRocketIdentify(this.currentUser)
    }

    this.$on('offline', () => {
      this.$toast('You are offline!', {
        //icon: 'wifi_offline',
        color: 'red',
        showClose: true,
        timeout: 0,
        position: 'top',
      })
    })

    this.$on('online', () => {
      this.$toast('Welcome back! You are online', {
        //icon: 'wifi',
        color: 'green',
        timeout: 6000,
        position: 'top',
      })
    })

    // Test
    //TODO: añadir notificaciones
    if (this.currentUser.isLoggedIn) {
      //this.loadNotifications()
    }
  },
}
</script>
