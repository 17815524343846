import store from 'store';
import locales from '../locales';

export default {
    set(key, value, expires = 86400) {
        store.set(key, {
            value,
            expired_at: +new Date() + expires * 1000
        });
    },
    get(key, defaultValue) {
        let temp = store.get(key);
        try {
            let value = temp.value;
            if (value === undefined || temp.expired_at < +new Date()) {
                return defaultValue;
            }
            return value;
        } catch (e) {
            return defaultValue;
        }
    },
    getLan() {
        /*let locale = this.get('lan');
        if (locale === undefined) {
            navigator.languages.forEach(language => {
                if (locale === undefined && locales.locales.indexOf(language) !== -1) {
                    locale = language;
                }
            });
            if (locale === undefined) {
                locale = 'en';
            }
        }
        return locale;*/
        return 'en';
    },
    setLan(language) {
        if (locales.locales.indexOf(language) !== -1) {
            let locale = language;
            localStorage.setItem('lang', locale);
            store.set('lang', {
                locale
            });
        }
    }
};