var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentUser.isLoggedIn
    ? _c(
        "div",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", app: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.showDrawer = !_vm.showDrawer
                    }
                  }
                },
                [
                  !_vm.showDrawer ? _c("v-icon", [_vm._v("menu")]) : _vm._e(),
                  _vm.showDrawer
                    ? _c("v-icon", [_vm._v("menu_open")])
                    : _vm._e()
                ],
                1
              ),
              !_vm.showDrawer
                ? _c(
                    "v-btn",
                    {
                      staticClass: "u-opacity-60",
                      attrs: { icon: "", to: "/" }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("../assets/z.svg"), height: "20" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.showDrawer
                ? _c("z-spacer", { attrs: { width: 48 } })
                : _vm._e(),
              _vm.backTo
                ? _c(
                    "v-btn",
                    { attrs: { icon: "", to: _vm.backTo } },
                    [_c("v-icon", [_vm._v("arrow_back")])],
                    1
                  )
                : _vm._e(),
              _vm.back
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("back")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("arrow_back")])],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-toolbar-title",
                { staticClass: "title", attrs: { flat: "" } },
                [_c("span", [_vm._v(_vm._s(_vm.title))])]
              ),
              _c("v-spacer"),
              _vm.$router.currentRoute.path == "/account/notifications"
                ? _c("z-spacer", { attrs: { width: 48 } })
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ attrs: { icon: "" } }, on),
                              [_c("v-icon", [_vm._v("help_outline")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3464568996
                  )
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("help")))])]
              )
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", floating: "", persistent: "", dark: "" },
              model: {
                value: _vm.showDrawer,
                callback: function($$v) {
                  _vm.showDrawer = $$v
                },
                expression: "showDrawer"
              }
            },
            [
              !_vm.$isDesktop()
                ? _c(
                    "v-toolbar",
                    { attrs: { dark: "", flat: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.showDrawer = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_left")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "pl-4 pb-5",
                  class: { "pt-5": _vm.$isDesktop() }
                },
                [
                  _c(
                    "router-link",
                    { staticClass: "ml-3", attrs: { to: "/" } },
                    [
                      _c("z-logo", {
                        staticClass: "logo-zinc",
                        attrs: { color: "#ffffff", slogan: "" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "pl-4 pb-5" }),
              _c(
                "v-list",
                { staticClass: "ml-4" },
                [
                  _vm._l(_vm.items[_vm.currentUser.accountType], function(
                    item
                  ) {
                    return [
                      item.dividerTop
                        ? _c("v-divider", {
                            key: "_" + item.to,
                            staticClass: "my-2"
                          })
                        : _vm._e(),
                      _c(
                        "v-list-tile",
                        {
                          key: item.title,
                          attrs: {
                            "active-class": "primary--text text--lighten-1",
                            to: item.to
                          },
                          on: {
                            click: function($event) {
                              _vm.showDrawer = _vm.$vuetify.breakpoint.lgAndUp
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(_vm.$t(item.title)))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              ),
              _c(
                "v-layout",
                { staticClass: "btn-logout", attrs: { row: "", "px-4": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        depressed: "",
                        block: "",
                        large: "",
                        color: "light-green",
                        to: "/logout"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("logout")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }