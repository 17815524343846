<template>
  <v-app>
    <tev-navbar :title="$t('reports')" />
    <v-content>
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-container>
          <v-layout row wrap mt-4>
            <v-flex xs12 sm12>
              <v-text-field
                :label="$t('property_name') +' / ' + $t('applicant_name')"
                type="text"
                box
                :autofocus="$isDesktop()"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="startDate" :label="$t('from_date')" readonly v-on="on" box>
                    <template v-slot:append>
                      <img width="16" height="16" src="../../assets/calendar.svg" alt />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @input="startDateMenu = false"
                  color="light-green"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6>
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="endDate" :label="$t('to_date')" readonly v-on="on" box>
                    <template v-slot:append>
                      <img width="16" height="16" src="../../assets/calendar.svg" alt />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  @input="endDateMenu = false"
                  color="light-green"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm12>
              <v-text-field :label="$t('property_id')" type="text" box></v-text-field>
            </v-flex>
            <v-flex xs12 sm12>
              <v-select :items="statusTypes" append-icon="expand_more" :label="$t('status')" box></v-select>
            </v-flex>
            <v-flex xs12 sm12>
              <v-select
                :items="transactionTypes"
                append-icon="expand_more"
                :label="$t('transaction_type')"
                box
              ></v-select>
            </v-flex>
            <!--v-flex xs12 sm12>
            <v-select :items="formatTypes" append-icon="expand_more" item-text="name" item-value="type" label="Format" box></v-select>
            </v-flex-->
          </v-layout>
          <v-btn
            color="light-green"
            dark
            depressed
            block
            large
            class="text-uppercase"
          >{{ $t('generate_reports') }}</v-btn>
        </v-container>
      </v-form>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      statusTypes: [],
      transactionTypes: [],
      formatTypes: [
        {
          type: 'csv',
          name: 'CSV',
        },
        {
          type: 'excel',
          name: 'Excel',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
}
</script>
<style>
</style>