var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", attrs: { "lazy-validation": "" } },
    [
      _c("p", { staticClass: "mb-4 grey--text" }, [
        _vm._v(
          " Please start the onboarding process by confirming your personal information. "
        )
      ]),
      _vm.formSchema
        ? _c("SchemaForm", {
            attrs: {
              schema: _vm.formSchema,
              model: _vm.formData,
              options: null
            },
            on: { error: _vm.showError }
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { round: "", depressed: "", color: "primary" },
          on: { click: _vm.submit }
        },
        [_vm._v("OK, Continue")]
      ),
      _c(
        "v-btn",
        {
          attrs: { round: "", flat: "" },
          on: {
            click: function($event) {
              return _vm.$emit("back")
            }
          }
        },
        [_vm._v("Back")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }