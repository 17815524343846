var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: "" } }),
      _c("tev-subnavbar", {
        attrs: { back: "/applicant/payments", title: "Payment Detail" }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c("img", {
                staticClass: "z-seal",
                attrs: { src: require("@/assets/verified.svg") }
              }),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _vm.isLoading
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "mb-5 text-xs-center",
                          attrs: { xs12: "", sm8: "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-xs-center pa-5 mb-5" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.payment && !_vm.isLoading
                    ? _c(
                        "v-card",
                        {
                          staticClass: "elevation-0 transparent",
                          attrs: { "max-width": "100%", width: "600" }
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "text-uppercase grey--text text--lighten-1 mb-3"
                            },
                            [_vm._v(_vm._s(_vm.$t("property_info")))]
                          ),
                          _c(
                            "v-card",
                            { staticClass: "z-card mb-4" },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent pa-0",
                                      attrs: { "two-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.payment.propertyName
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("property_name")
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment.address.line1
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("address")) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment.address.city
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("city")) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment.address.state
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("state")) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-tile-action")
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment.address
                                                        .postalCode
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("zip_code")) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment
                                                        .organizationName
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  "Property Management Company"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "h3",
                            {
                              staticClass:
                                "text-uppercase grey--text text--lighten-1 mb-3"
                            },
                            [_vm._v("Payment Details")]
                          ),
                          _c(
                            "v-card",
                            { staticClass: "z-card mb-4 pa-0" },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent pa-0",
                                      attrs: { "two-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.description)
                                                  )
                                                ])
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Fee Description")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c(
                                                "v-list-tile-title",
                                                [
                                                  _vm.payment.contractType ==
                                                  "deposit"
                                                    ? [_vm._v("Refundable")]
                                                    : [_vm._v("Non-Refundable")]
                                                ],
                                                2
                                              ),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Type of Fee")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.payment.submittedAt,
                                                      "MM/DD/YYYY"
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Date Collected")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-list-tile-action")
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _vm.payment.contractType == "deposit"
                                        ? _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          _vm.payment
                                                            .refundedAt,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Refund Date")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm.payment.status ==
                                                "completed" ||
                                              _vm.payment.status == "pending"
                                                ? _c(
                                                    "v-list-tile-action",
                                                    [
                                                      _c("countdown", {
                                                        attrs: {
                                                          time:
                                                            new Date(
                                                              _vm.payment.refundedAt
                                                            ).getTime() -
                                                            new Date().getTime()
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                props
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "v-layout",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props.days
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-1 light-green--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "days"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props.hours
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-1 light-green--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "hours"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props.minutes
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-1 light-green--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "minutes"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src: require("@/assets/schedule.svg"),
                                                                            height:
                                                                              "22"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          2882806273
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "light-green white--text pa-3",
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-uppercase body-1"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.payment.status))
                                          ])
                                        ]
                                      ),
                                      _c("v-spacer"),
                                      _c("strong", { staticClass: "title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.payment.amount
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mb-5 text-xs-center primary--text"
                            },
                            [_vm._v("Payment ID: " + _vm._s(_vm.payment.id))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }