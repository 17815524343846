var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("my_property") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "transparent elevation-0",
                      attrs: { width: "600", "max-width": "100%" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-start": "",
                            "justify-start": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "light-green--text",
                              attrs: {
                                icon: "",
                                "active-class": "none",
                                to:
                                  "/" +
                                  _vm.currentUser.accountType +
                                  "/properties"
                              }
                            },
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                "py-3": "",
                                "text-xs-right": "",
                                "grey--text": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm._v(" "),
                                  _vm.property
                                    ? [
                                        _vm._v(
                                          _vm._s(_vm.$t("property_id")) +
                                            ": " +
                                            _vm._s(_vm.property.tevPropertyId)
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c("z-status-chip", {
                                attrs: {
                                  status: _vm.property.status,
                                  label: _vm.$t(_vm.property.status)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("h3", [
                                _vm._v(_vm._s(_vm.$t("property_info")))
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.property.name))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("property_name")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.property.address.line1))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("address")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.property.address.city))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("city")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.property.address.state))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("state")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(
                                    _vm._s(_vm.property.address.postalCode)
                                  )
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("zip_code")))
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-center": "",
                            "justify-center": "",
                            "pb-3": "",
                            "text-xs-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm4: "",
                                "pl-1": "",
                                "pr-1": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "button-action white--text",
                                  attrs: {
                                    to:
                                      "/" +
                                      _vm.currentUser.accountType +
                                      "/transactions/" +
                                      _vm.propertyId,
                                    depressed: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        pt3: "",
                                        pb3: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/transactions.svg"),
                                              height: "24"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "button-text" },
                                            [_vm._v("Transactions")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm4: "",
                                "pl-1": "",
                                "pr-1": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "button-action white--text",
                                  attrs: {
                                    to:
                                      "/" +
                                      _vm.currentUser.accountType +
                                      "/properties/" +
                                      _vm.propertyId +
                                      "/contracts",
                                    depressed: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        pt3: "",
                                        pb3: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/contracts.svg"),
                                              height: "24"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "button-text" },
                                            [_vm._v("Smart Contracts")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm4: "",
                                "pl-1": "",
                                "pr-1": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "button-action  white--text",
                                  attrs: {
                                    to:
                                      "/" +
                                      _vm.currentUser.accountType +
                                      "/properties/" +
                                      _vm.propertyId +
                                      "/applications",
                                    depressed: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        pt3: "",
                                        pb3: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/applicants.svg"),
                                              height: "24"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "button-text" },
                                            [_vm._v("Applicants")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.activeMembers.length
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-uppercase grey--text text--lighten-1 mb-3"
                                },
                                [_vm._v("Active Members")]
                              ),
                              _c(
                                "v-card",
                                { staticClass: "z-card" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-2 py-1" },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "transparent",
                                          attrs: { "two-line": "" }
                                        },
                                        [
                                          _vm._l(_vm.activeMembers, function(
                                            item,
                                            index
                                          ) {
                                            return [
                                              index > 0
                                                ? _c("v-divider", {
                                                    key: "d" + index
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-list-tile",
                                                {
                                                  key: "t" + index,
                                                  attrs: {
                                                    avatar: "",
                                                    to:
                                                      "/" +
                                                      _vm.currentUser
                                                        .accountType +
                                                      "/members/" +
                                                      item.id
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-tile-avatar",
                                                    [
                                                      _c("Lettericon", {
                                                        attrs: {
                                                          name: item.fullName,
                                                          size: "2"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c("v-list-tile-title", [
                                                        _vm._v(
                                                          _vm._s(item.fullName)
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-list-tile-sub-title",
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.membersName[
                                                                item.accountType
                                                              ]
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-action",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            icon: "",
                                                            ripple: "",
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "white--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "chevron_right"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pendingMembers.length
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "" } },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "zinc-box" },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "zinc-box__title",
                                      attrs: { xs12: "" }
                                    },
                                    [_c("h3", [_vm._v("Pending Members")])]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "zinc-box__body px-2",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "transparent mb-4",
                                          attrs: { "two-line": "" }
                                        },
                                        _vm._l(_vm.pendingMembers, function(
                                          item
                                        ) {
                                          return _c(
                                            "v-list-tile",
                                            {
                                              key: item.title,
                                              attrs: {
                                                avatar: "",
                                                to:
                                                  "/" +
                                                  _vm.currentUser.accountType +
                                                  "/members/" +
                                                  item.id
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-tile-avatar",
                                                [
                                                  _c("Lettericon", {
                                                    attrs: {
                                                      name: item.fullName,
                                                      size: "2"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(item.fullName)
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.membersName[
                                                          item.accountType
                                                        ]
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-tile-action",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        icon: "",
                                                        ripple: "",
                                                        color: "primary"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "white--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "chevron_right"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.assistantMembers.length == 0
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm12: "",
                                "pb-1": "",
                                "pt-3": "",
                                "text-xs-center": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-uppercase mb-3 white--text",
                                  attrs: {
                                    to:
                                      "/" +
                                      _vm.currentUser.accountType +
                                      "/properties/" +
                                      _vm.propertyId +
                                      "/invite",
                                    block: "",
                                    depressed: "",
                                    large: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v("Add Assitant")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }