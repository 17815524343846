<template>
  <div>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div>
        <v-toolbar flat :color="toolbarClass">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :class="canUndo ? 'grey--text' : ''"
                icon
                @click="undo(commands)"
              >
                <v-icon>undo</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('undo') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :class="canRedo ? 'grey--text' : ''"
                icon
                @click="redo(commands)"
              >
                <v-icon>redo</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('redo') }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.bold() ? activeClass : ''"
                @click="commands.bold"
              >
                <v-icon>format_bold</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('bold') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.italic() ? activeClass : ''"
                @click="commands.italic"
              >
                <v-icon>format_italic</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('italic') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.strike() ? activeClass : ''"
                @click="commands.strike"
              >
                <v-icon>strikethrough_s</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('strike') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.underline() ? activeClass : ''"
                @click="commands.underline"
              >
                <v-icon>format_underlined</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('underline') }}</span>
          </v-tooltip>
          <!--
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.paragraph()?activeClass:''"
                @click="commands.paragraph"
              >
                <v-icon>paragraph</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('paragraph') }}</span>
          </v-tooltip>
          -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.heading({ level: 1 }) ? activeClass : ''"
                @click="commands.heading({ level: 1 })"
                >H1</v-btn
              >
            </template>
            <span>Heading 1</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.heading({ level: 2 }) ? activeClass : ''"
                @click="commands.heading({ level: 2 })"
                >H2</v-btn
              >
            </template>
            <span>Heading 2</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.heading({ level: 3 }) ? activeClass : ''"
                @click="commands.heading({ level: 3 })"
                >H3</v-btn
              >
            </template>
            <span>Heading 3</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.bullet_list() ? activeClass : ''"
                @click="commands.bullet_list"
              >
                <v-icon>format_list_bulleted</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('bullet_list') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.ordered_list() ? activeClass : ''"
                @click="commands.ordered_list"
              >
                <v-icon>format_list_numbered</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ordered_list') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :class="isActive.blockquote() ? activeClass : ''"
                @click="commands.blockquote"
              >
                <v-icon>format_quote</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('blockquote') }}</span>
          </v-tooltip>

          <!--
        <v-btn icon :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block">
          <v-icon>code</v-icon>
        </v-btn>
          -->
          <!--
          <v-btn
            icon
            @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
          >
            <v-icon>table</v-icon>
          </v-btn>
          -->
        </v-toolbar>
        <v-toolbar flat :color="toolbarClass" v-if="isActive.table()">
          <v-btn icon class="menubar__button" @click="commands.deleteTable">
            <icon name="delete_table" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.addColumnBefore">
            <icon name="add_col_before" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.addColumnAfter">
            <icon name="add_col_after" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.deleteColumn">
            <icon name="delete_col" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.addRowBefore">
            <icon name="add_row_before" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.addRowAfter">
            <icon name="add_row_after" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.deleteRow">
            <icon name="delete_row" />
          </v-btn>
          <v-btn icon class="menubar__button" @click="commands.toggleCellMerge">
            <icon name="combine_cells" />
          </v-btn>
        </v-toolbar>
      </div>
    </editor-menu-bar>
    <editor-content :editor="editor" />
  </div>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from 'tiptap-extensions'

export default {
  props: {
    toolbarClass: {
      type: String,
      default: 'grey lighten-3',
    },
    activeClass: {
      type: String,
      default: 'grey lighten-1',
    },
  },
  data() {
    return {
      canUndo: 0,
      canRedo: 0,
      historyAction: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
        ],
        onInit: () => {
          this.$emit('init', true)
        },
        onUpdate: ({ getHTML }) => {
          const newContent = getHTML()
          if (!this.historyAction) {
            this.history += 1
            this.historyAction = false
          }
          this.$emit('change', newContent)
        },
      }),
    }
  },
  components: {
    EditorContent,
    EditorMenuBar,
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    redo(cmd) {
      this.historyAction = true
      cmd.redo()
    },
    undo(cmd) {
      this.historyAction = true
      cmd.undo()
    },
  },
}
</script>
<style>
.ProseMirror [contenteditable='false'] {
  white-space: normal;
}

.ProseMirror [contenteditable='true'] {
  white-space: pre-wrap;
}

.ProseMirror {
  background: #ffffff;
  min-height: 200px;
  padding: 10px;
}

.ProseMirror-focused {
  outline: 2px solid #2896f3;
  outline: 0 !important;
}
</style>
