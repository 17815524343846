var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "px-3 mt-5 mb-4 text-xs-center",
                  attrs: { xs12: "", sm8: "", md6: "" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "bounce", mode: "out-in" } },
                    [
                      _vm.showPreapproved
                        ? _c("div", { staticClass: "box-preapproved" }, [
                            _c(
                              "h2",
                              { staticClass: "blue--text text--darken-4 mb-2" },
                              [_vm._v("Congratulations!")]
                            ),
                            _c("div", { staticClass: "mb-3" }, [
                              _vm._v(
                                " You have been pre-approved for a personal loan of "
                              ),
                              _c(
                                "strong",
                                { staticClass: "blue--text text--darken-4" },
                                [_vm._v("up to $8000")]
                              )
                            ]),
                            _c("div", { staticClass: "mb-3" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/figure-congrats.svg")
                                }
                              })
                            ]),
                            _c(
                              "svg",
                              {
                                attrs: {
                                  height: "34",
                                  viewBox: "0 0 64 16.323"
                                }
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform: "translate(236 -18079.141)"
                                    }
                                  },
                                  [
                                    _c(
                                      "text",
                                      {
                                        staticClass: "a",
                                        attrs: {
                                          transform: "translate(-236 18084.141)"
                                        }
                                      },
                                      [
                                        _c(
                                          "tspan",
                                          { attrs: { x: "0", y: "0" } },
                                          [_vm._v("Powered by")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          transform:
                                            "translate(-223.042 18079.879)"
                                        }
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              transform: "translate(0 5.595)"
                                            }
                                          },
                                          [
                                            _c(
                                              "g",
                                              {
                                                attrs: {
                                                  transform: "translate(0 0)"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M394.865,85.712a3.328,3.328,0,0,0-.654-2.206,2.411,2.411,0,0,0-1.936-.775,2.523,2.523,0,0,0-1.988.8,3.386,3.386,0,0,0-.7,2.3,3.218,3.218,0,0,0,.379,1.628,2.389,2.389,0,0,0,1.024.974,3.2,3.2,0,0,0,1.421.312,3.909,3.909,0,0,0,1.1-.141,3.01,3.01,0,0,0,.9-.438.285.285,0,0,0,.126-.227.43.43,0,0,0-.039-.168l-.231-.482c-.055-.145-.149-.167-.2-.167a.3.3,0,0,0-.163.062,2.355,2.355,0,0,1-1.331.393,1.714,1.714,0,0,1-1.183-.379,1.344,1.344,0,0,1-.414-.982h3.481C394.607,86.21,394.865,86.145,394.865,85.712Zm-3.894-.544a1.445,1.445,0,0,1,.339-.927,1.245,1.245,0,0,1,.975-.377,1.161,1.161,0,0,1,.934.363,1.511,1.511,0,0,1,.311.941Z",
                                                    transform:
                                                      "translate(-343.824 -80.527)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M346.031,82.73a2.523,2.523,0,0,0-1.988.8,3.387,3.387,0,0,0-.7,2.3,3.219,3.219,0,0,0,.38,1.628,2.391,2.391,0,0,0,1.024.974,3.2,3.2,0,0,0,1.421.312,3.909,3.909,0,0,0,1.1-.141,3.013,3.013,0,0,0,.9-.438.285.285,0,0,0,.127-.227.427.427,0,0,0-.039-.168l-.231-.482c-.055-.145-.149-.167-.2-.167a.3.3,0,0,0-.163.062,2.356,2.356,0,0,1-1.331.393,1.715,1.715,0,0,1-1.183-.379,1.344,1.344,0,0,1-.414-.982h3.482c.154,0,.412-.065.412-.5a3.329,3.329,0,0,0-.654-2.206A2.41,2.41,0,0,0,346.031,82.73Zm1.255,2.438h-2.56a1.445,1.445,0,0,1,.339-.927,1.245,1.245,0,0,1,.975-.377,1.16,1.16,0,0,1,.934.363A1.51,1.51,0,0,1,347.287,85.168Z",
                                                    transform:
                                                      "translate(-303.33 -80.527)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M318.247,83.665a.316.316,0,0,0,0-.174.292.292,0,0,0-.109-.149,2.447,2.447,0,0,0-1.018-.191,5.214,5.214,0,0,0-1.46.277.237.237,0,0,0-.215-.12h-.65a.326.326,0,0,0-.286.11.512.512,0,0,0-.067.3v4.708a.558.558,0,0,0,.112.373l.014.014a.458.458,0,0,0,.344.123h.508a.445.445,0,0,0,.365-.139.577.577,0,0,0,.105-.37v-3.85a4.035,4.035,0,0,1,1.089-.189c.05,0,.1,0,.144.007a1.021,1.021,0,0,1,.422.113l.095.028.021,0a.2.2,0,0,0,.2-.171l.376-.683Z",
                                                    transform:
                                                      "translate(-278.028 -80.895)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M289.754,70.713a.4.4,0,0,0-.339-.125h-.994V69.142a.568.568,0,0,0-.093-.351.388.388,0,0,0-.329-.136h-.605a.387.387,0,0,0-.328.136.566.566,0,0,0-.093.351v5.167c0,1.684,1.041,2.035,1.585,2.1a.286.286,0,0,0,.323-.284v-.769a.286.286,0,0,0-.154-.253.537.537,0,0,1-.166-.14,1.2,1.2,0,0,1-.139-.7V71.857h.994a.407.407,0,0,0,.337-.117.578.578,0,0,0,.085-.358v-.307A.61.61,0,0,0,289.754,70.713Z",
                                                    transform:
                                                      "translate(-253.97 -68.202)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M243.14,83.03a4,4,0,0,0-1.277-.21,2.865,2.865,0,0,0-2.176.8,3.2,3.2,0,0,0-.754,2.273,2.98,2.98,0,0,0,.635,2.025,2.538,2.538,0,0,0,2.7.555,1.76,1.76,0,0,0,.491-.333,1.579,1.579,0,0,1-.271.9,1.087,1.087,0,0,1-.88.328,3.345,3.345,0,0,1-1.064-.173.2.2,0,0,0-.265.18l-.024.833a.2.2,0,0,0,.148.2,5.734,5.734,0,0,0,1.313.187,2.193,2.193,0,0,0,1.887-.827,3.709,3.709,0,0,0,.6-2.223V83.5A10.037,10.037,0,0,0,243.14,83.03Zm-.371,1.215v1.967a1.152,1.152,0,0,1-.312.857,1.122,1.122,0,0,1-.819.307A1.173,1.173,0,0,1,240.68,87a1.869,1.869,0,0,1-.319-1.2c0-1.167.472-1.734,1.443-1.734a2.939,2.939,0,0,1,.576.055A1.677,1.677,0,0,1,242.769,84.245Z",
                                                    transform:
                                                      "translate(-211.905 -80.606)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M198,82.818a5.3,5.3,0,0,0-1.572.227l-.265.068a.236.236,0,0,0-.218-.124h-.65a.326.326,0,0,0-.286.11.512.512,0,0,0-.068.3v4.75a.559.559,0,0,0,.112.372.449.449,0,0,0,.359.137h.508a.446.446,0,0,0,.366-.14.578.578,0,0,0,.105-.37V84.257a4.9,4.9,0,0,1,1.189-.192.847.847,0,0,1,.635.2,1.061,1.061,0,0,1,.193.723v3.155a.558.558,0,0,0,.112.372.449.449,0,0,0,.359.137h.517a.433.433,0,0,0,.356-.14.578.578,0,0,0,.105-.37V84.9a2.153,2.153,0,0,0-.512-1.556A1.8,1.8,0,0,0,198,82.818Z",
                                                    transform:
                                                      "translate(-173.383 -80.604)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M178.61,84.192H178.1a.449.449,0,0,0-.359.137.577.577,0,0,0-.112.384v4.636a.558.558,0,0,0,.112.372.449.449,0,0,0,.359.137h.508a.446.446,0,0,0,.366-.14.578.578,0,0,0,.105-.37V84.713a.578.578,0,0,0-.112-.384A.449.449,0,0,0,178.61,84.192Z",
                                                    transform:
                                                      "translate(-158.23 -81.807)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M134.78,65.02c-.421.188-1.3.914-1.3,1.1v1.5a2.147,2.147,0,0,0-1.384-.4,2.274,2.274,0,0,0-1.311.382,2.42,2.42,0,0,0-.85,1.062,3.888,3.888,0,0,0-.291,1.549c0,2,.966,3.016,2.873,3.016a4.157,4.157,0,0,0,1.33-.216,9.506,9.506,0,0,0,1.064-.479V65.157a.139.139,0,0,0-.031-.092C134.855,65.036,134.816,65,134.78,65.02Zm-1.3,4.474v2.271a1.709,1.709,0,0,1-.935.226,1.425,1.425,0,0,1-1.107-.4,2,2,0,0,1-.365-1.346,2.282,2.282,0,0,1,.333-1.321,1.124,1.124,0,0,1,.983-.459,1.184,1.184,0,0,1,.794.261A.96.96,0,0,1,133.48,69.495Z",
                                                    transform:
                                                      "translate(-116.21 -65.016)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M88.747,82.818a5.3,5.3,0,0,0-1.572.227l-.032.008-.234.06a.234.234,0,0,0-.217-.124h-.649a.326.326,0,0,0-.286.11.511.511,0,0,0-.068.3v4.75a.559.559,0,0,0,.112.372.449.449,0,0,0,.359.137h.508a.445.445,0,0,0,.366-.14.578.578,0,0,0,.105-.37V84.257a4.9,4.9,0,0,1,1.189-.192.847.847,0,0,1,.635.2,1.06,1.06,0,0,1,.193.723v3.155a.558.558,0,0,0,.112.372.449.449,0,0,0,.359.137h.517a.432.432,0,0,0,.356-.14.578.578,0,0,0,.105-.37V84.9a2.154,2.154,0,0,0-.512-1.556A1.8,1.8,0,0,0,88.747,82.818Z",
                                                    transform:
                                                      "translate(-77.722 -80.604)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M41.489,82.73a2.523,2.523,0,0,0-1.988.8,3.386,3.386,0,0,0-.7,2.3,3.218,3.218,0,0,0,.379,1.628,2.39,2.39,0,0,0,1.024.974,3.2,3.2,0,0,0,1.421.312,3.906,3.906,0,0,0,1.1-.141,3.014,3.014,0,0,0,.9-.438.285.285,0,0,0,.126-.227.43.43,0,0,0-.039-.168l-.231-.482c-.055-.145-.149-.167-.2-.167a.3.3,0,0,0-.163.062,2.356,2.356,0,0,1-1.331.393,1.714,1.714,0,0,1-1.183-.379,1.344,1.344,0,0,1-.414-.982h3.481c.154,0,.412-.065.412-.5a3.329,3.329,0,0,0-.653-2.206A2.41,2.41,0,0,0,41.489,82.73Zm1.256,2.438h-2.56a1.444,1.444,0,0,1,.339-.927,1.245,1.245,0,0,1,.975-.377,1.16,1.16,0,0,1,.934.363A1.511,1.511,0,0,1,42.745,85.168Z",
                                                    transform:
                                                      "translate(-36.667 -80.527)"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "b",
                                                  attrs: {
                                                    d:
                                                      "M23.227,72.913a1.2,1.2,0,0,1-.139-.7V66.8a.566.566,0,0,0-.093-.351.387.387,0,0,0-.328-.136h-.605a.387.387,0,0,0-.329.136.566.566,0,0,0-.093.351v5.459c0,1.684,1.041,2.035,1.585,2.1a.286.286,0,0,0,.323-.284v-.769a.286.286,0,0,0-.154-.253A.538.538,0,0,1,23.227,72.913Z",
                                                    transform:
                                                      "translate(-21.639 -66.15)"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("path", {
                                          staticClass: "b",
                                          attrs: {
                                            d:
                                              "M184.767,20.03a45.222,45.222,0,0,0-4.615.766,3.233,3.233,0,0,0-2.518,3.625v1.01s0,1.8,0,1.848.033.045.054.017a26.035,26.035,0,0,1,3.624-3.911s.009-.01-.007-.006a11.734,11.734,0,0,0-2.2.947c0-.205,0-.34,0-.364-.01-1.457.848-1.851,1.872-2.135a17.53,17.53,0,0,1,2.39-.365s-.127.908-.292,2.757c-.12,1.351-1.113,1.653-2.5,1.653h-.782a3.832,3.832,0,0,0-.706,1.436h1.528c2.907,0,3.557-1.281,3.695-3.177a31.033,31.033,0,0,1,.49-4.069A.027.027,0,0,0,184.767,20.03Z",
                                            transform:
                                              "translate(-158.23 -20.029)"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _vm.showPreapproved
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "title black--text mb-4 font-weight-regular"
                              },
                              [
                                _vm._v(
                                  "Do you want to pay all your fees with this loan?"
                                )
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-uppercase mb-4 white--text",
                                    attrs: {
                                      depressed: "",
                                      round: "",
                                      large: "",
                                      color: "primary"
                                    }
                                  },
                                  [_vm._v("Yes")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-uppercase mb-4 white--text",
                                    attrs: {
                                      depressed: "",
                                      round: "",
                                      large: "",
                                      color: "grey"
                                    }
                                  },
                                  [_vm._v("No")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "body-2 font-weight-regular" },
                              [
                                _vm._v(
                                  " The money will usually be deposited in your bank account within 24 hours after approved. "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }