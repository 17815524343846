<template>
  <div v-if="currentUser.isLoggedIn">
    <v-toolbar dark flat app>
      <v-btn icon @click="showDrawer = !showDrawer">
        <v-icon v-if="!showDrawer">menu</v-icon>
        <v-icon v-if="showDrawer">menu_open</v-icon>
      </v-btn>
      <v-btn icon to="/" class="u-opacity-60" v-if="!showDrawer">
        <img src="../assets/z.svg" height="20" />
      </v-btn>
      <z-spacer :width="48" v-if="showDrawer" />
      <v-btn icon v-if="backTo" :to="backTo">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-btn icon v-if="back" @click="$emit('back')">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title flat class="title">
        <span>{{ title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <z-spacer :width="48" v-if="$router.currentRoute.path == '/account/notifications'" />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>help_outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('help') }}</span>
      </v-tooltip>

      <!--
      <v-menu
        transition="scale-transition"
        origin="top right"
        v-model="showNotifications"
        offset-y
        v-if="$router.currentRoute.path != '/account/notifications'"
      >
        <v-tooltip bottom slot="activator">
          <v-btn icon slot="activator">
            <v-badge right overlap color="red" v-if="unreadNotifications">
              <template v-slot:badge>
                <span>{{ unreadNotifications }}</span>
              </template>
              <v-icon>notifications</v-icon>
            </v-badge>
            <v-icon v-if="!unreadNotifications">notifications</v-icon>
          </v-btn>
          <span>{{ $t('your_notifications') }}</span>
        </v-tooltip>
        <v-card class="notifications pa-2 grey lighten-3">
          <v-layout class="align-center justify-space-between row">
            <h3 class="primary--text subheading text-uppercase px-2">
              {{ $t('your_notifications') }}
            </h3>
            <v-spacer />
            <v-btn icon @click="showNotifications === false">
              <v-icon class="grey--text">close</v-icon>
            </v-btn>
          </v-layout>
          <template v-if="currentNotifications.length">
            <div class="notifications-container">
              <template v-for="notification in currentNotifications">
                <z-notification
                  :notification="notification"
                  :key="notification.id"
                  v-if="notification.status == 'unread'"
                ></z-notification>
              </template>
              <v-list avatar two-line subheader class="transparent">
              <v-list-tile
                v-for="(notification, index) in currentNotifications"
                :key="notification.id"
                @click="clickNotification(notification)"
              >
                <v-list-tile-avatar>
                  <v-avatar size="36px">
                    <img
                      src="https://avatars0.githubusercontent.com/u/1472352?s=460&v=4"
                      alt="Avatar"
                    />
                  </v-avatar>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title>{{ notification.title }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ notification.message }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              </v-list>
            </div>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn color="primary" flat to="/account/notifications">{{
                $t('view_all_notifications')
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </template>

          <template v-if="!currentNotifications.length">
            <div class="pa-2 grey--text">You have no new notifications.</div>
          </template>
        </v-card>
      </v-menu>
      -->
    </v-toolbar>
    <v-navigation-drawer v-model="showDrawer" app floating persistent dark>
      <v-toolbar dark flat v-if="!$isDesktop()">
        <v-spacer></v-spacer>
        <v-btn icon @click="showDrawer = false">
          <v-icon>chevron_left</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="pl-4 pb-5" :class="{ 'pt-5': $isDesktop() }">
        <router-link to="/" class="ml-3">
          <z-logo color="#ffffff" slogan class="logo-zinc" />
        </router-link>
      </div>

      <!--
      <v-card color="transparent" class="mx-4" to="/account">
        <v-list>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <img src="https://avatars0.githubusercontent.com/u/1472352?s=460&v=4" />
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ currentUser.fullName }}</v-list-tile-title>
              <v-list-tile-sub-title class="grey--text">{{ currentUser.email }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
      -->

      <div class="pl-4 pb-5"></div>
      <v-list class="ml-4">
        <template v-for="item in items[currentUser.accountType]">
          <v-divider class="my-2" :key="'_' + item.to" v-if="item.dividerTop" />
          <v-list-tile
            active-class="primary--text text--lighten-1"
            :key="item.title"
            :to="item.to"
            @click="showDrawer = $vuetify.breakpoint.lgAndUp"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ $t(item.title) }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
      <v-layout row px-4 class="btn-logout">
        <v-btn
          depressed
          block
          large
          color="light-green"
          class="text-uppercase"
          to="/logout"
        >{{ $t('logout') }}</v-btn>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import menu from '../router/menu'
import config from '../libs/config'
//import ZNotification from './ZNotification.vue'

export default {
  props: {
    title: String,
    back: Boolean,
    backTo: String,
  },
  components: {
    //'z-notification': ZNotification,
  },
  data() {
    return {
      showDrawer: config.get('showDrawer', this.$vuetify.breakpoint.lgAndUp),
      showNotifications: false,
      items: menu.items,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentNotifications: 'currentNotifications', // TODO: Añadir popup de notificaciones y señal de no leido
    }),
    unreadNotifications() {
      //TODO: mostrar contador correcto de notificaciones no leidas
      return this.currentNotifications.length
    },
  },
  watch: {
    showDrawer(val) {
      config.set('showDrawer', val)
    },
  },
  methods: {
    //TODO: Reportar al backend las notificaciones leidas
    clickNotification(notification) {
      this.$log.debug('notification ' + JSON.stringify(notification))
      this.$router.push('/account/notifications')
    },

    viewMoreNotifications() {
      this.$router.push('/account/notifications')
    },
  },
}
</script>
<style>
.logo-zinc {
  width: 120px;
}

.btn-logout {
  bottom: 20px;
  position: absolute;
  width: 100%;
}
.notifications-container {
  max-height: 400px;
  overflow: scroll;
}
.notifications {
  max-width: 330px;
}
@media only screen and (max-width: 576px) {
  .notifications {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.notifications-header {
  background-color: #ffffff;
  height: 84px;
  width: 100%;
}

.notifications-header__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 24px;
}

.notifications-header__content > :first-child.v-btn--icon {
  margin-right: 6px !important;
  background: transparent !important;
}

.notifications-header h3 {
  color: #2196f3;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 10px;
  display: inline;
  line-height: 84px;
  font-size: 16px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@media only screen and (max-width: 959px) {
  .notifications-header {
    height: 74px;
  }

  .notifications-header h3 {
    line-height: 74px;
  }

  .notifications-header:before {
    height: 54px;
  }
}
</style>
