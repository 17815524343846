var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: "" } }),
      _c("tev-subnavbar", {
        attrs: {
          back: "/accountant/properties/" + _vm.propertyId,
          title: "Bank Account Setup"
        }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "", md6: "" } },
                    [
                      _vm.selectedAccount
                        ? [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "black--text mb-4 font-weight-regular"
                              },
                              [
                                _vm._v(
                                  "Make sure new bank information provided is ok before continue"
                                )
                              ]
                            ),
                            _c("div", { staticClass: "grey--text body-1" }, [
                              _vm._v("Bank Account:")
                            ]),
                            _c("div", { staticClass: "mb-3" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.institutionName))
                              ])
                            ]),
                            _c("div", { staticClass: "grey--text body-1" }, [
                              _vm._v("Account Number:")
                            ]),
                            _c("div", { staticClass: "mb-3" }, [
                              _c("strong", [
                                _vm._v(
                                  "ending in " +
                                    _vm._s(_vm.selectedAccount.mask)
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "grey--text body-1" }, [
                              _vm._v("Property to be assigned:")
                            ]),
                            _c("div", { staticClass: "mb-3" }, [
                              _c("strong", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.property.name)
                                }
                              })
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", "mb-3": "" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "grey--text body-1 mb-1" },
                                  [_vm._v("Bank Account Alias:")]
                                ),
                                _c("v-text-field", {
                                  attrs: {
                                    "single-line": "",
                                    autofocus: _vm.$isDesktop(),
                                    label: "Bank Account Alias",
                                    placeholder: "Ex. Maintenance Account",
                                    required: "",
                                    box: ""
                                  },
                                  model: {
                                    value: _vm.alias,
                                    callback: function($$v) {
                                      _vm.alias = $$v
                                    },
                                    expression: "alias"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("p", [
                              _vm._v(
                                " I hereby authorize Zinc LLC, hereinafter called Zinc to initiate debit entries or such adjusting entries, either credit or debit which are necessary for corrections, to my bank account indicated before and the financial institution named below to debit (or credit) the same to such account. "
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                " This authority is to remain in full force and effect until Zinc LLC has received written notification from me of its termination in such time and in such manner as to afford Zinc LLC a reasonable opportunity to act on it. "
                              )
                            ]),
                            _c("z-sign", {
                              staticClass: "mb-3",
                              on: { change: _vm.signChange }
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-uppercase mb-5 white--text",
                                attrs: {
                                  loading: _vm.isSaving,
                                  block: "",
                                  depressed: "",
                                  large: "",
                                  color: "light-green"
                                },
                                on: { click: _vm.saveBankAccount }
                              },
                              [_vm._v(_vm._s(_vm.$t("save")))]
                            )
                          ]
                        : _vm._e(),
                      !_vm.selectedAccount
                        ? [
                            _c("div", [
                              _c("strong", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.property.name)
                                }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "grey--text mb-4 body-1" },
                              [_vm._v("Property Name")]
                            ),
                            _vm.isSaving
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: {
                                          size: 45,
                                          width: 1,
                                          color: "light-green",
                                          indeterminate: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "black--text mb-4 font-weight-regular"
                                    },
                                    [_vm._v("One moment please")]
                                  )
                                ]
                              : _vm._e(),
                            !_vm.isSaving && _vm.plaidAccounts.length
                              ? [
                                  _c("img", {
                                    staticClass: "d-block mt-3 mb-2",
                                    attrs: { src: require("@/assets/bank.svg") }
                                  }),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "black--text mb-4 font-weight-regular"
                                    },
                                    [_vm._v("Please select your bank account")]
                                  ),
                                  _vm._l(_vm.plaidAccounts, function(item) {
                                    return _c(
                                      "v-card",
                                      {
                                        key: item.id,
                                        staticClass: "z-card mb-4 pa-3 pointer",
                                        attrs: {
                                          ripple: {
                                            class:
                                              "primary--text text--lighten-5"
                                          }
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.selectedAccount = item
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "align-center": "",
                                              "justify-space-between": "",
                                              row: ""
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "primary--text subheading"
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "body-1" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "ending in " +
                                                        _vm._s(item.mask)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "body-1 grey--text"
                                                },
                                                [_vm._v(_vm._s(item.subtype))]
                                              )
                                            ]),
                                            _c("v-spacer"),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/checkbox-green.svg"),
                                                height: "24"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mb-3 pt-2 text-xs-center" },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " You are connected to your " +
                                            _vm._s(_vm.institutionName) +
                                            " account. "
                                        ),
                                        _c(
                                          "a",
                                          { on: { click: _vm.openPlaid } },
                                          [_vm._v("Change Bank")]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            !_vm.isSaving && !_vm.plaidAccounts.length
                              ? [
                                  _c("img", {
                                    staticClass: "d-block mt-3 mb-2",
                                    attrs: { src: require("@/assets/bank.svg") }
                                  }),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "black--text mb-4 font-weight-regular"
                                    },
                                    [
                                      _vm._v(
                                        "How do you want to connect your bank account"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "z-card mb-4 pa-3 pointer",
                                      attrs: {
                                        ripple: {
                                          class: "primary--text text--lighten-5"
                                        }
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleSelected("online")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "justify-space-between": "",
                                            row: ""
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "primary--text subheading"
                                              },
                                              [
                                                _vm._v(
                                                  "Using online banking credentials"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "body-1" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    "Immediate bank verification"
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "body-1" },
                                              [
                                                _vm._v(
                                                  " Make sure you have the online user and password of the online banking of the property bank account. "
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-spacer"),
                                          _vm.setupSelected == "online" ||
                                          !_vm.allowManualSetup
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/checkbox-green-checked.svg"),
                                                  height: "24"
                                                }
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: require("@/assets/checkbox-green.svg"),
                                                  height: "24"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.setupSelected == "online" ||
                                  !_vm.allowManualSetup
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mb-3 pt-2 text-xs-center"
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "text-uppercase mb-4 white--text",
                                                attrs: {
                                                  round: "",
                                                  depressed: "",
                                                  large: "",
                                                  color: "primary"
                                                },
                                                on: { click: _vm.openPlaid }
                                              },
                                              [_vm._v("Sign into your bank")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm.allowManualSetup
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "z-card mb-4 pa-3 pointer",
                                          attrs: {
                                            ripple: {
                                              class:
                                                "primary--text text--lighten-5"
                                            }
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleSelected(
                                                "manual"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                "align-center": "",
                                                "justify-space-between": "",
                                                row: ""
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "primary--text subheading"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Bank account manual set up"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "body-1" },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        " Bank verification may take up to 3 business days "
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "body-1" },
                                                  [
                                                    _vm._v(
                                                      " Manually add the routing and account number of the property bank account. "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c("v-spacer"),
                                              _vm.setupSelected == "manual"
                                                ? _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/checkbox-green-checked.svg"),
                                                      height: "24"
                                                    }
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/checkbox-green.svg"),
                                                      height: "24"
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.setupSelected == "manual"
                                    ? [
                                        _c(
                                          "h3",
                                          { staticClass: "black--text" },
                                          [_vm._v("Connect a checking account")]
                                        ),
                                        _c("div", { staticClass: "mb-4" }, [
                                          _vm._v(
                                            "Type the routing and account number"
                                          )
                                        ]),
                                        _c(
                                          "v-card",
                                          { staticClass: "mb-5 elevation-0" },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _vm._v(
                                                          "Bank Account Country"
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "single-line": "",
                                                        label: "Add here",
                                                        box: "",
                                                        "background-color":
                                                          "#F1F5F7",
                                                        flat: "",
                                                        autocomplete: "off",
                                                        required: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _vm._v(
                                                          "Financial institution"
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "single-line": "",
                                                        label: "Add here",
                                                        box: "",
                                                        "background-color":
                                                          "#F1F5F7",
                                                        flat: "",
                                                        autocomplete: "off",
                                                        required: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-2" },
                                                      [_vm._v("Routing Number")]
                                                    ),
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "single-line": "",
                                                        label: "Add here",
                                                        box: "",
                                                        "background-color":
                                                          "#F1F5F7",
                                                        flat: "",
                                                        autocomplete: "off",
                                                        required: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-2" },
                                                      [_vm._v("Account Number")]
                                                    ),
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "single-line": "",
                                                        label: "Add here",
                                                        box: "",
                                                        "background-color":
                                                          "#F1F5F7",
                                                        flat: "",
                                                        autocomplete: "off",
                                                        required: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _vm._v(
                                                          "Confirm Account Number"
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "single-line": "",
                                                        label: "Add here",
                                                        box: "",
                                                        "background-color":
                                                          "#F1F5F7",
                                                        flat: "",
                                                        autocomplete: "off",
                                                        required: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "text-uppercase",
                                            attrs: {
                                              loading: _vm.isSaving,
                                              color: "light-green",
                                              dark: "",
                                              depressed: "",
                                              block: "",
                                              large: ""
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("verify")))]
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }