<template>
  <v-form ref="form">
    <p class="mb-4 grey--text">Please enter the property company information.</p>
    <SchemaForm v-if="formSchema" :schema="formSchema" :model="formData" @error="showError">
      <template v-slot:prepend-einFile="{  }">
        <v-flex sm12>
          <div
            class="mb-3 grey--text"
          >The document(s) will then be reviewed; this review may take up to 1-2 business days to approve or reject.</div>
        </v-flex>
      </template>
    </SchemaForm>
    <v-btn round depressed color="primary" @click="submit">Continue</v-btn>
    <v-btn round flat @click="$emit('back')">Back</v-btn>
  </v-form>
</template>
<script>
import SchemaForm from '@/components/schemaForm/Schema.vue'
import Alerts from '@/mixin/Alerts'

export default {
  props: {
    propertyId: String,
    property: Object,
  },
  data() {
    return {
      formData: {},
      formSchema: {
        title: '',
        type: 'object',
        required: [
          'businessName',
          'businessType',
          'ein',
          'einFile',
          'articlesFile',
        ],
        properties: {
          businessName: {
            type: 'string',
            title: 'Registered business name',
            'x-autofocus': true,
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          doingBusinessAs: {
            type: 'string',
            title: 'Preferred business name',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          businessType: {
            type: 'object',
            title: 'Business Type',
            enum: [
              { value: 'corporation', title: 'Corporation' },
              { value: 'llc', title: 'LLC' },
              { value: 'partnership', title: 'Partnership' },
            ],
            'x-item-title': 'title',
            'x-itemKey': 'value',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          ein: {
            type: 'string',
            title: 'Employer Identification Number',
            'x-mask': '##-#######',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          einFile: {
            type: 'string',
            title: 'Upload EIN Letter',
            format: 'base64',
            'x-accept': 'image/jpeg, image/png',
            'x-display': 'fileinput',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          articlesFile: {
            type: 'string',
            title: 'Upload Articles of Incorporation',
            format: 'base64',
            'x-accept': 'image/jpeg, image/png',
            'x-display': 'fileinput',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
        },
      },
    }
  },
  components: {
    SchemaForm,
  },
  mixins: [Alerts],
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return this.toastError(this.$t('complete_required'))
      }
      this.$emit('next', this.formData)
    },
    showError(err) {
      this.$log.error(err)
    },
  },
  created() {
    this.formData['doingBusinessAs'] = this.property.name
  },
}
</script>
