var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        color: "transparent",
        "max-width": "600",
        width: "100%"
      }
    },
    [
      !_vm.success
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "", sm12: "" } },
              [
                _c("div", { staticClass: "text-xs-center mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "display-1 black--text font-weight-bold" },
                    [_vm._v(_vm._s(_vm._f("currency")(_vm.totalAmount)))]
                  ),
                  _c(
                    "div",
                    { staticClass: "black--text font-weight-bold mb-2" },
                    [
                      _vm.contractType == "deposit"
                        ? [_vm._v("Total Deposits")]
                        : _vm._e(),
                      _vm.contractType == "fee"
                        ? [_vm._v("Total Fees")]
                        : _vm._e()
                    ],
                    2
                  )
                ]),
                _c(
                  "v-card",
                  { staticClass: "z-card py-3" },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-tile",
                          { attrs: { avatar: "" } },
                          [
                            _c("v-list-tile-avatar", [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/ach-withdraw.svg")
                                }
                              })
                            ]),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-title", [
                                  _vm._v("Credit Card Pre-authorization")
                                ]),
                                _c(
                                  "v-list-tile-sub-title",
                                  { staticClass: "body-1" },
                                  [
                                    _vm._v(
                                      "Funds will be hold for no more than 30 days."
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "px-3 mb-4" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "z-card indigo darken-4 white--text",
                        staticStyle: {
                          "border-top-left-radius": "0",
                          "border-top-right-radius": "0"
                        }
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "align-center": "",
                                  "align-content-center": ""
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    src: require("@/assets/attention.svg"),
                                    height: "26"
                                  }
                                }),
                                _c("v-flex", [
                                  _vm._v(
                                    " Credit card pre-authorization is only available for short term deposits. Funds will be hold for no more than 30 days. "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "z-card mb-5" },
                  [
                    _vm._l(_vm.application.transactions, function(item, index) {
                      return [
                        _vm.isCCAvailable(item)
                          ? [
                              _c(
                                "v-layout",
                                {
                                  key: item.id,
                                  attrs: { row: "", "ma-3": "" }
                                },
                                [
                                  _c("v-flex", [
                                    _c("div", [_vm._v(_vm._s(item.feeName))]),
                                    _c(
                                      "div",
                                      { staticClass: "grey--text body-1 mb-2" },
                                      [_vm._v("Description")]
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { "text-xs-right": "" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "indigo--text text--darken-4"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.feeRateType == "variable"
                                                    ? _vm.variableSetup[item.id]
                                                        .amount
                                                    : item.amount
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text body-1" },
                                        [_vm._v("Amount to hold")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider", { key: index })
                            ]
                          : _vm._e()
                      ]
                    }),
                    _c(
                      "v-layout",
                      { attrs: { row: "", "ma-3": "" } },
                      [
                        _c("v-flex", [
                          _c("div", [_c("strong", [_vm._v("Total")])])
                        ]),
                        _c("v-flex", { attrs: { "text-xs-right": "" } }, [
                          _c("div", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm._f("currency")(_vm.totalAmount))
                              )
                            ])
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "v-form",
              {
                ref: "form",
                attrs: { id: "form-card" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.makePayment($event)
                  }
                }
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "Card Number",
                        mask: "####  ####  ####  ####",
                        box: "",
                        rules: _vm.rules.cardNumber,
                        autofocus: _vm.$isDesktop()
                      },
                      on: { input: _vm.detectCardType },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("img", {
                                  attrs: {
                                    height: "22",
                                    src: _vm.imgCardType,
                                    alt: ""
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1671611586
                      ),
                      model: {
                        value: _vm.form.cardNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "cardNumber", $$v)
                        },
                        expression: "form.cardNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "Card First Name",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.cardFirstName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "cardFirstName", $$v)
                        },
                        expression: "form.cardFirstName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "Card Last Name",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.cardLastName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "cardLastName", $$v)
                        },
                        expression: "form.cardLastName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs6: "", sm6: "", md4: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            outline: "",
                            placeholder: "MM / YY",
                            label: "Expiry Date",
                            mask: "## / ##",
                            name: "expirydate",
                            rules: _vm.rules.cardExpiryDate,
                            box: ""
                          },
                          model: {
                            value: _vm.form.cardExpiryDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "cardExpiryDate", $$v)
                            },
                            expression: "form.cardExpiryDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs6: "", sm6: "", md3: "", "ml-2": "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            outline: "",
                            label: "CVV",
                            name: "cvv",
                            type: "number",
                            box: "",
                            rules: _vm.rules.cardCVV,
                            maxlength: "3"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-width": 200,
                                          "offset-x": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "v-btn--very-small",
                                                        attrs: {
                                                          color: "primary",
                                                          icon: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "14" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "priority_high"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1443879531
                                        ),
                                        model: {
                                          value: _vm.popoverCVV,
                                          callback: function($$v) {
                                            _vm.popoverCVV = $$v
                                          },
                                          expression: "popoverCVV"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              "max-width": "290",
                                              color:
                                                "white body-1 grey--text text--darken-2"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pb-0" },
                                              [
                                                _vm._v(
                                                  " The CVV/CVC code (Card Verification Value/Code) is located on the back of your credit/debit card on the right side of the white signature strip; it is always the last 3 digits in case of VISA and MasterCard. "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    width: "260",
                                                    src: require("@/assets/cvv-location.png")
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      flat: "",
                                                      depressed: "",
                                                      color: "primary",
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.popoverCVV = false
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(_vm.$t("ok")))]
                                                ),
                                                _c("v-spacer")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            987656989
                          ),
                          model: {
                            value: _vm.form.cardCVV,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "cardCVV", $$v)
                            },
                            expression: "form.cardCVV"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "h3",
                  {
                    staticClass:
                      "primary--text subheading font-weight-regular mb-3"
                  },
                  [_vm._v("Billing Address")]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        "single-line": "",
                        label: "Street address",
                        name: "address",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.addressLine1,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "addressLine1", $$v)
                        },
                        expression: "form.addressLine1"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        "single-line": "",
                        label: "Apartment, suite. etc (optional)",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.addressLine2,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "addressLine2", $$v)
                        },
                        expression: "form.addressLine2"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "City",
                        name: "city",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.city,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "city", $$v)
                        },
                        expression: "form.city"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "State",
                        name: "state",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.state,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "Zip code",
                        name: "zip",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.postalCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "postalCode", $$v)
                        },
                        expression: "form.postalCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm12: "" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outline: "",
                        label: "Country",
                        name: "country",
                        box: "",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: _vm.form.country,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "country", $$v)
                        },
                        expression: "form.country"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-flex",
              {
                attrs: {
                  "body-1": "",
                  "mb-4": "",
                  "grey--text": "",
                  "text--darken-1": ""
                }
              },
              [
                _vm._v(
                  "I hereby authorize Zinc LLC (“Zinc”), its parent, affiliates and their respective agents and their assignees to initiate, depending on the payment method I select, a single or recurring electronic debit entry/entries to my designated checking, savings account or credit card (“Account”) at my designated financial institution or credit issuer (“Financial Institution”) for which I am an authorized user, as well as any Account or Financial Institution I later designate, for the single or recurring transactions I authorize. I acknowledge that the origination of electronic debits to my Account must be permitted by my Financial Institution, which must be located in the United States. I understand that my authorization will remain in full force and effect until Zinc has received written notification from me at least 3 business days prior to my scheduled transfer to terminate this authorization. If I am using a credit card to conduct a transaction, an authorization hold will be secured on the credit card provided, to cover the deposits or charges and any fees that may be incurred. These funds will not be available for your use. Additional authorizations (holds) will be obtained if I authorize subsequent transactions using a credit card. We use Dwolla for payment processing and do not store any credit card information. Dwolla is a trusted, Level 1 PCI Service Provider."
                )
              ]
            ),
            _c(
              "v-flex",
              { attrs: { xs12: "", "mb-4": "" } },
              [
                _c("z-sign", {
                  ref: "sign",
                  attrs: { id: "form-sign" },
                  on: { change: _vm.signChange }
                })
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "text-uppercase mb-5",
                attrs: {
                  color: "primary",
                  dark: "",
                  depressed: "",
                  block: "",
                  large: ""
                },
                on: { click: _vm.makePayment }
              },
              [_vm._v(_vm._s(_vm.$t("pay")))]
            )
          ]
        : _vm._e(),
      _vm.success
        ? _c("z-success-pay", {
            attrs: {
              amount: _vm.totalAmount,
              description: _vm.payDescription,
              title: "Your payment has been successfully processed.",
              subtitle:
                "Soon you will get and email with all the transaction details."
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }