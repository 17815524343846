import User from '../models/User'
import { MUTATION_TYPES } from '../libs/constants'

export default {
  [MUTATION_TYPES.LOGIN](state) {
    state.user = User.from(localStorage.token)
  },
  [MUTATION_TYPES.LOGOUT](state) {
    delete localStorage.token
    state.user = {
      loggedIn: false,
      userId: null,
      accountType: null,
      email: null,
      fullName: null,
      expireIn: null,
    }
  },
  [MUTATION_TYPES.UPDATE_USER_NOTIFICATIONS](state, payload) {
    state.notifications = payload
  },
  [MUTATION_TYPES.RESET_USER_NOTIFICATIONS](state) {
    state.notifications = null
  },
}
