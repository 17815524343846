<template>
  <div>
    <v-card class="z-card pt-3 pb-0" max-width="600" width="100%">
      <template v-for="item in application.payments">
        <div :key="item.id" v-if="item.contractType == contractType || contractType=='all'">
          <v-layout row ma-3>
            <v-flex>
              <div>
                <strong>{{ item.feeName }}</strong>
                <v-menu
                  v-model="popover"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                  v-if="item.feeRateType == 'variable'"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn class="v-btn--very-small" color="primary" icon v-on="on">
                      <v-icon size="14">priority_high</v-icon>
                    </v-btn>
                  </template>
                  <v-card max-width="290" color="grey lighten-5 body-1 grey--text text--darken-2">
                    <v-card-text class="pb-0">
                      A security deposit is a deposit of money to the landlord to
                      ensure that rent will be paid and other responsibilities of
                      the lease performed.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        flat
                        depressed
                        color="primary"
                        text
                        @click="popover = false"
                      >{{ $t('ok') }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
              <div class="grey--text body-1 mb-2">
                <template
                  v-if="item.contractType == 'deposit'"
                >Refund in {{ item.refundCounter }} {{ item.refundStepType }}</template>
                <template v-else>Description</template>
              </div>
            </v-flex>
            <v-flex text-xs-right v-if="item.feeRateType == 'flat'">
              <div>
                <strong>{{ item.amount | currency }}</strong>
              </div>
              <div class="grey--text body-1">
                <template v-if="contractType == 'deposit'">Amount to deposit</template>
                <template v-else>Amount to pay</template>
              </div>
            </v-flex>
            <v-flex
              text-xs-right
              font-weight-bold
              v-if="item.feeRateType == 'variable' && !setup[item.id]"
            >Pending</v-flex>
            <v-flex text-xs-right v-if="item.feeRateType == 'variable' && setup[item.id]">
              <div>
                <strong>{{ setup[item.id].amount | currency }}</strong>
              </div>
              <div class="grey--text body-1">
                <template v-if="contractType == 'deposit'">Amount to deposit</template>
                <template v-else>Amount to pay</template>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row ma-3 mt-4 v-if="item.feeRateType == 'variable'">
            <v-flex text-xs-center v-if="!calculate[item.id]">
              <div
                class="grey--text body-1"
              >{{ item.feeName }} must be calculated to get final amount.</div>
              <v-btn
                depressed
                round
                color="primary"
                class="mb-4 white--text"
                @click="calculateRate(item.id)"
              >Calculate Rate</v-btn>
            </v-flex>
            <v-flex v-if="calculate[item.id]">
              <template v-if="item.setupByType == 'sqft'">
                <div>How many Sqft does your unit have?</div>
                <v-select
                  v-model="setup[item.id]"
                  single-line
                  :items="item.units"
                  box
                  background-color="#F1F5F7"
                  flat
                  label="Select one"
                  append-icon="keyboard_arrow_down"
                  required
                  @change="showAttention(item.id)"
                >
                  <template
                    v-slot:selection="{ item }"
                  >From {{ item.sqftFrom }} to {{ item.sqftTo }}</template>
                  <template v-slot:item="{ item }">From {{ item.sqftFrom }} to {{ item.sqftTo }}</template>
                </v-select>
              </template>
              <template v-if="item.setupByType == 'rooms'">
                <div>How many rooms does your unit have?</div>
                <v-select
                  single-line
                  v-model="setup[item.id]"
                  :items="item.units"
                  box
                  background-color="#F1F5F7"
                  flat
                  label="Select one"
                  append-icon="keyboard_arrow_down"
                  required
                  @change="showAttention(item.id)"
                >
                  <template v-slot:selection="{ item }">{{ item.rooms }} rooms</template>
                  <template v-slot:item="{ item }">{{ item.rooms }} rooms</template>
                </v-select>
              </template>
              <template v-if="item.setupByType == 'name'">
                <div>What is the name of your unit?</div>
                <v-select
                  single-line
                  v-model="setup[item.id]"
                  :items="item.units"
                  box
                  background-color="#F1F5F7"
                  flat
                  label="Select one"
                  append-icon="keyboard_arrow_down"
                  required
                  @change="showAttention(item.id)"
                >
                  <template v-slot:selection="{ item }">{{ item.name }}</template>
                  <template v-slot:item="{ item }">{{ item.name }}</template>
                </v-select>
              </template>
              <template v-if="item.setupByType == 'rent'">
                <div>What is the monthly rent of your unit?</div>
                <v-select
                  single-line
                  v-model="setup[item.id]"
                  :items="item.units"
                  box
                  background-color="#F1F5F7"
                  flat
                  label="Select one"
                  append-icon="keyboard_arrow_down"
                  required
                  @change="showAttention(item.id)"
                >
                  <template v-slot:selection="{ item }">Montly rent: {{ item.rent | currency }}</template>
                  <template v-slot:item="{ item }">Montly rent: {{ item.rent | currency }}</template>
                </v-select>
              </template>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </div>
      </template>
      <v-layout row ma-3 pb-4>
        <v-flex>
          <div class="font-weight-bold">Total</div>
          <div class="grey--text body-1">Due date Immediate</div>
        </v-flex>
        <v-flex text-xs-right>
          <div class="font-weight-bold">{{ totalAmount | currency }}</div>
          <div class="grey--text body-1 text-lowercase">{{ variableDeposits }}</div>
        </v-flex>
      </v-layout>
      <div class="ma-3 pb-5">
        <div class="body-1 mb-3">
          <a @click="dialogTerms = true">Review Terms</a>
        </div>

        <div class="grey--text body-1 mb-4">
          Make sure to read and agree with all the smart contracts belonging to
          this property before payment.
        </div>
        <v-layout align-center justify-start fill-height class="body-1 mb-4">
          <toggle-button
            v-model="agree"
            class="mr-3"
            color="#8bc34a"
            :width="68"
            :font-size="14"
            :height="30"
            :sync="true"
            :labels="{ checked: 'YES', unchecked: '' }"
          ></toggle-button>
          <a @click="agree = !agree" class="black--text">I Agree with all the terms and conditions</a>
        </v-layout>
        <v-btn
          round
          block
          depressed
          large
          color="primary"
          class="text-uppercase mb-0 white--text"
          @click="makePayment"
        >Make Payment</v-btn>
      </div>
      <!-- Checkout Fee Terms -->
      <v-dialog v-model="dialogTerms" persistent max-width="500">
        <v-card class="pa-0">
          <v-card-text class="pa-4">
            <img src="@/assets/z-seal.svg" style="float:right" />
            <img src="@/assets/ico-smartcontract.svg" class="mb-2" />
            <h3
              class="mb-3 subheading grey--text font-weight-bold text-uppercase"
            >Terms And Conditions</h3>
            <div class="grey--text text--darken-1 body-1">
              <vue-markdown>{{ termsTxt }}</vue-markdown>
            </div>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn
              color="light-green"
              class="text-uppercase white--text rounded-0"
              large
              block
              depressed
              @click="dialogTerms = false"
            >{{ $t('continue') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Checkout Fee Terms -->
      <v-dialog v-model="dialogAttention" persistent max-width="290">
        <v-card class="pa-3">
          <v-card-text class="text-xs-center">
            <img src="@/assets/attention.svg" class="mb-2" />
            <h3 class="mb-3 title">Attention</h3>
            <div class="grey--text text--darken-1">
              Failure to disclose the right Sqft area may incur additional charges
              or a penalty.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              class="text-uppercase"
              block
              depressed
              @click="dialogAttention = false"
            >{{ $t('continue') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import Alerts from '@/mixin/Alerts'
import VueMarkdown from 'vue-markdown'
import TermsTxt from 'raw-loader!@/raw/terms.txt'

export default {
  props: { application: Object, contractType: String },
  data() {
    return {
      agree: false,
      popover: false,
      dialogAttention: false,
      dialogTerms: false,
      dialogTitle: null,
      dialogBody: null,
      alerted: {},
      calculate: {},
      setup: {},
      termsTxt: TermsTxt,
    }
  },
  mixins: [Alerts],
  components: {
    'vue-markdown': VueMarkdown,
  },
  computed: {
    totalAmount() {
      let total = 0
      if (this.application) {
        let items = this.application.payments.filter(
          item => (item.contractType == this.contractType || this.contractType=='all')
        )
        for (let i = 0; i < items.length; i++) {
          if (items[i].feeRateType == 'variable') {
            if (this.setup[items[i].id]) {
              total += parseFloat(this.setup[items[i].id].amount)
            }
          } else {
            total += parseFloat(items[i].amount)
          }
        }
      }
      return total
    },
    variableDeposits() {
      const _this = this
      let str = ''
      if (this.application && this.contractType == 'deposit') {
        let arr = []
        let items = this.application.payments.filter(
          item => item.feeRateType == 'variable'
        )
        items.forEach(item => {
          if (!_this.setup[item.id]) {
            arr.push(item.feeName)
          }
        })
        str = arr.length ? '+ ' + arr.join(', ') : ''
      }
      return str
    },
  },
  methods: {
    isCCAvailable(item) {
      if (
        item.contractType == 'deposit' &&
        item.refundStepType == 'days' &&
        item.refundCounter < 30
      ) {
        return true
      }
      return false
    },
    makePayment() {
      const _this = this

      // Check variable deposits
      if (this.contractType == 'deposit') {
        let items = this.application.payments.filter(
          item => item.feeRateType == 'variable'
        )
        for (let i = 0; i < items.length; i++) {
          if (!_this.setup[items[i].id]) {
            // items[i].feeName
            return this.toastError(
              'Please calculate the rate to get the final amount.'
            )
          }
        }
      }

      // Check Agree
      if (!this.agree) {
        return this.toastError('Please agree with the terms to continue.')
      }

      this.$emit('change', {
        setup: this.setup,
        total: this.totalAmount,
        application: this.application,
      })
    },
    showAttention(id) {
      let tmp = this.alerted
      if (!tmp[id]) {
        this.dialogAttention = true
        tmp[id] = true
      }
      this.alerted = null
      this.alerted = tmp
    },
    calculateRate(id) {
      let tmp = this.calculate
      tmp[id] = true
      this.calculate = null
      this.calculate = tmp
    },
  },
}
</script>
