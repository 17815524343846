<template>
  <v-app>
    <tev-navbar :title="$t('my_property')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-card class="transparent elevation-0" width="600" max-width="100%">
            <v-layout row wrap align-start justify-start>
              <v-btn
                icon
                active-class="none"
                class="light-green--text"
                :to="'/'  + currentUser.accountType + '/properties'"
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                <div>
                  &nbsp;
                  <template
                    v-if="property"
                  >{{ $t('property_id') }}: {{ property.tevPropertyId }}</template>
                </div>
                <z-status-chip :status="property.status" :label="$t(property.status)"></z-status-chip>
              </v-flex>
            </v-layout>
            <!-- property_info -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>{{ $t('property_info') }}</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body">
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ property.name }}</div>
                  <div class="zinc-box__label">{{ $t('property_name') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ property.address.line1 }}</div>
                  <div class="zinc-box__label">{{ $t('address') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ property.address.city }}</div>
                  <div class="zinc-box__label">{{ $t('city') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ property.address.state }}</div>
                  <div class="zinc-box__label">{{ $t('state') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ property.address.postalCode }}</div>
                  <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                </div>
              </v-flex>
            </v-flex>
            <!-- / property_info -->

            <v-layout row wrap align-center justify-center pb-3 text-xs-center>

              <v-flex xs12 sm4 pl-1 pr-1>
                <v-btn 
                  :to="'/'  + currentUser.accountType + '/transactions/'+propertyId"
                  depressed
                  color="primary"
                  class="button-action white--text"
                >
                  <v-layout row wrap pt3 pb3>  
                    <v-flex xs12 sm12>                                                
                      <img src="@/assets/transactions.svg" height="24" />
                    </v-flex>
                    <v-flex xs12 sm12>                                                
                      <div class="button-text">Transactions</div>
                    </v-flex>
                  </v-layout>
                </v-btn>
               
              </v-flex>

              <v-flex xs12 sm4 pl-1 pr-1>
                <v-btn 
                  :to="'/'  + currentUser.accountType + '/properties/'+propertyId+'/contracts'"
                  depressed
                  color="primary"
                  class="button-action white--text"
                >
                  <v-layout row wrap pt3 pb3>  
                    <v-flex xs12 sm12>                                                
                      <img src="@/assets/contracts.svg" height="24" />
                    </v-flex>
                    <v-flex xs12 sm12>                                                
                      <div class="button-text">Smart Contracts</div>
                    </v-flex>
                  </v-layout>
                </v-btn>
               
              </v-flex>

              <v-flex xs12 sm4 pl-1 pr-1>
                <v-btn 
                  :to="'/'  + currentUser.accountType + '/properties/'+propertyId+'/applications'"
                  depressed
                  color="primary"
                  class="button-action  white--text"
                >
                  <v-layout row wrap pt3 pb3>  
                    <v-flex xs12 sm12>                                                
                      <img src="@/assets/applicants.svg" height="24" />
                    </v-flex>
                    <v-flex xs12 sm12>                                                
                      <div class="button-text">Applicants</div>
                    </v-flex>
                  </v-layout>
                </v-btn>
               
              </v-flex>
            </v-layout>

 

            <v-flex xs12 sm12 mb-4 v-if="activeMembers.length">
              <h3 class="text-uppercase grey--text text--lighten-1 mb-3">Active Members</h3>
              <v-card class="z-card">
                <v-card-text class="px-2 py-1">
                  <v-list two-line class="transparent">
                    <template v-for="(item, index) in activeMembers">
                      <v-divider :key="'d'+index" v-if="index > 0"></v-divider>
                      <v-list-tile
                        :key="'t'+index"
                        avatar
                        :to="'/'  + currentUser.accountType + '/members/'+item.id"
                      >
                        <v-list-tile-avatar>
                          <Lettericon :name="item.fullName" size="2"></Lettericon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ item.fullName }}</v-list-tile-title>
                          <v-list-tile-sub-title>{{ membersName[item.accountType] }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn small icon ripple color="primary">
                            <v-icon class="white--text">chevron_right</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm12 v-if="pendingMembers.length">
              <!-- active_members -->
              <v-flex class="zinc-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>Pending Members</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body px-2">
                  <v-list two-line class="transparent mb-4">
                    <v-list-tile
                      v-for="item in pendingMembers"
                      :key="item.title"
                      avatar
                      :to="'/'  + currentUser.accountType + '/members/'+item.id"
                    >
                      <v-list-tile-avatar>
                        <Lettericon :name="item.fullName" size="2"></Lettericon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item.fullName }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ membersName[item.accountType] }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn small icon ripple color="primary">
                          <v-icon class="white--text">chevron_right</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                </v-flex>
              </v-flex>
              <!-- / active_members -->
            </v-flex>

            <v-flex v-if="assistantMembers.length==0" xs12 sm12 pb-1 pt-3 text-xs-center>
              <v-btn
                :to="'/'  + currentUser.accountType + '/properties/'+propertyId+'/invite'"
                block
                depressed
                large
                color="primary"
                class="text-uppercase mb-3 white--text"
              >Add Assitant</v-btn>
            </v-flex>
          </v-card>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '../../components/Lettericon'

import Alerts from '@/mixin/Alerts'

export default {
  title: 'Property Details',
  data() {
    return {
      error: false,
      isLoadingMembers: true,
      propertyId: null,
      members: [],
      membersName: {
        manager: 'Property Manager',
        accountant: 'Property Accountant',
        property: 'Property Account',
        assistant: 'Manager assistant',
      },

      property: {
        name: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
        },
        status: null,
        organizationId: null,
        organizationName: null,
        organizationStatus: null,
      },
    }
  },
  mixins: [Alerts],
  components: { Lettericon },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
    activeMembers() {
      return this.members.filter(item => item.status == 'active')
    },
    pendingMembers() {
      return this.members.filter(item => item.status == 'pending')
    },
    assistantMembers() {
      return this.members.filter(item => item.accountType == 'assistant')
    },
  },
  methods: {
    loadProperty() {
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
          this.loadMembers()
        })
        .catch(err => {
          this.$log.error(err)
          //this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
    },

    loadMembers() {
      this.isLoadingMembers = true
      this.$http
        .get('/manager/properties/' + this.propertyId + '/members')
        .then(res => {
          this.members = res.data.members
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoadingMembers = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadProperty()
  },
}
</script>
<style>
.v-list__tile__title {
  height: auto;
}

.button-action {
  padding: 40px 10px;
  min-height: 120px;
  width: 100%;
}

.button-text {
  display: block;
}
</style>