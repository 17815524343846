<template>
  <v-app>
    <v-content class="login-background">
      <v-layout row align-center justify-center pt-5 pb-5>
        <z-logo color="#ffffff" slogan class="logo-zinc" />
      </v-layout>
      <v-layout row wrap px-4>
        <v-flex xs12>
          <v-card max-width="500px" class="mx-auto">
            <v-container class="login-container">
              <v-card flat>
                <v-card-title
                  class="justify-center headline indigo--text text--darken-2 font-weight-bold"
                >Logout successful</v-card-title>
                <v-card-text>
                  <div class="text-xs-center mb-0">
                    <p class="mb-4 grey--text text--darken-1">You have successfully logged out.</p>
                    <v-btn
                      class="mb-2"
                      color="primary"
                      large
                      depressed
                      to="/login"
                    >{{ $t('signin') }}</v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
      <z-footer :dark="true"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
export default {
  name: 'Logout',
  data() {
    return {}
  },
  created() {
    this.logOut()
  },
  updated() {
    this.logOut()
  },
  methods: {
    logOut() {
      this.$http
        .post('/public/revoke', {
          refreshToken: localStorage.refresh_token,
        })
        .then(res => {
          this.$log.debug(res)
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.$store.dispatch('logout')
        })
    },
  },
}
</script>