<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <v-list-tile class="trxTitle px-0 mt-2">
          <v-list-tile-content class="pl-0">
            <v-list-tile-title style="color:rgb(193,193,193);" class="text-uppercase">Applicants</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn
              @click="generateCsv"
              :small="isMobile"
              flat
              class="text-capitalize"
              color="info"
            >create report</v-btn>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile class="px-0 tableForm">
          <v-layout class="mx-0" row wrap>
            <v-flex xs12 sm5 class="pa-0">
              <v-text-field
                flat
                solo
                hide-details
                v-model="search"
                label="Name, email or application ID"
              >
                <template v-slot:append>
                  <v-icon size="20">search</v-icon>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm5 class="pa-0">
              <v-text-field
                :value="dateRange"
                flat
                @click="datePickerModal = !datePickerModal"
                readonly
                solo
                hide-details
                label="Date Range"
              >
                <template v-slot:append>
                  <v-icon size="20">today</v-icon>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm2 class="pa-0">
              <v-select
                :items="statusFilter"
                flat
                solo
                v-model="status"
                hide-details
                label="Filter"
              >
                <template v-slot:append>
                  <v-icon size="20">filter_list</v-icon>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
        </v-list-tile>
      </v-flex>
    </v-layout>
    <v-dialog v-model="datePickerModal" max-width="290" content-class="removeRadius">
      <v-card tile>
        <v-date-picker class="elevation-0" multiple v-model="range"></v-date-picker>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="range = []" flat>clear</v-btn>
          <v-btn color="info" @click="datePickerModal = false" flat>done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout pt-5>
      <v-flex xs12>
        <v-data-table
          class="accInfoTable elevation-2"
          :headers="headers"
          disable-initial-sort
          :hide-default-header="isMobile"
          :hide-headers="isMobile"
          :items="filteredItems"
          :custom-sort="customSort"
          :pagination.sync="pagination"
        >
          <template v-slot:items="{ item }">
            <v-list-tile class="table-mobile" v-if="isMobile" @click="handleClick(item)">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.applicantName }}</v-list-tile-title>
                <v-list-tile-sub-title class="caption">
                  {{ item.date }}
                  <br />
                  <span class="success--text">{{ item.fees }}</span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-content class="text-xs-right">
                <v-list-tile-title class="text-xs-right">
                  <span
                    :class="[item.amount > 0 ? 'success--text' : '']"
                    class="font-weight-bold"
                  >{{ item.amount | currency }}</span>
                </v-list-tile-title>
                <v-list-tile-sub-title class="caption">
                  <z-status-chip :status="item.status" :label="item.status"></z-status-chip>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider v-if="isMobile" />
            <tr v-if="!isMobile" @click="handleClick(item)">
              <td>{{ item.applicantName }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.fees }}</td>
              <td>
                <z-status-chip :status="item.status" :label="item.status"></z-status-chip>
              </td>
              <td>
                <span
                  :class="[item.amount > 0 ? 'success--text' : '']"
                  class="font-weight-bold body-1"
                >{{ item.amount | currency }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogDetails" ref="dialogDetails" scrollable :max-width="(isMobile)?'100%':'70%'" class="app-details-dialog" content-class="removeRadius">
      <v-card class="grey lighten-5">
        <v-card-title>
          <v-toolbar class="transparent" card>
            <v-toolbar-title class="text-uppercase subheading primary--text">Application Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon class="grey--text text--darken-2" @click="dialogDetails = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text id="card-details" class="app-card-dialog">
          <z-application-detail ref="appDetail" :application="application" :propertyId="propertyId"></z-application-detail>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Alerts from '../mixin/Alerts'
import ZApplicationDetail from './ZApplicationDetail'

export default {
  props: {
    items: Array,
    propertyId: String,
  },
  data() {
    return {
      isLoading: false,

      datePickerModal: false,
      dialogDetails: false,

      application: {},
      payment: {},      
      search: '',
      range: [],
      status: '',
      pagination: {
        rowsPerPage: 10,
      },
      headers: [
        { text: 'Applicant', value: 'applicantName' },
        { text: 'Created', value: 'date' },
        { text: 'Fees', value: 'fees' },
        { text: 'Status', value: 'status' },
        { text: 'Amount', value: 'amount' },
      ],
    }
  },
  components: {
    'z-application-detail': ZApplicationDetail
  },
  watch: {
    range(val) {
      if (val.length > 2) {
        val.pop()
      }
    },
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
   
    isMobile: function() {
      return this.$vuetify.breakpoint.smAndDown
    },
    filteredItems() {
      let searched = this.items.filter(item => {
        const applicantName = item.applicantName.toLowerCase()
        const id = item.id.toLowerCase()
        const txt = this.search.toLowerCase()
        return applicantName.includes(txt) || id.includes(txt)
      })

      const range = this.sortDate().obj

      if (range) {
        searched = searched.filter(item => {
          const [m, d, y] = item.date.split('/')
          const date = new Date(y, m - 1, d)
          if (
            date.getTime() > range.from.getTime() &&
            date < range.to.getTime()
          ) {
            this.$log.info({ item: item.date, date, range })
          }

          return (
            date.getTime() > range.from.getTime() && date < range.to.getTime()
          )
        })
      }
      if (!!this.status && this.status !== 'All')
        searched = searched.filter(item => item.status === this.status)
      return searched
    },
    dateRange: function() {
      const range = this.sortDate().str
      if (range) {
        return `${this.formatDate(range.from)} - ${this.formatDate(range.to)}`
      }
      return ''
    },
    statusFilter: function() {
      const set = new Set()
      set.add('All')
      set.add('pending')
      set.add('completed')
      //this.items.forEach(item => set.add(item.status))
      return Array.from(set)
    },
  },

  methods: {      
    statusClass(status) {
      let str = 'orange'
      if (status == 'submitted') {
        str = 'primary'
      } 
      else if (status == 'processed' || status == 'completed') {
        str = 'light-green'
      }
      else if (status == 'refund sent' || status == 'refunded'){
        str = 'grey'
      }
      else if (status == 'failed' || status == 'cancelled') {
        str = 'red'
      }
      return str
    },

    handleFilePondInit: function() {
      // FilePond instance methods are available on `this.$refs.pond`
      //this.$log.info('handleFilePondInit')
      this.extendDocument = null
      this.incidentImages = []
    },

    formatDate: function(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    sortDate: function() {
      const [first, second] = this.range
      if (first && second) {
        const d1 = new Date(first)
        const d2 = new Date(second)
        if (d1.getTime() < d2.getTime()) {
          return {
            str: { from: first, to: second },
            obj: { from: d1, to: d2 },
          }
        }
        return {
          str: { from: second, to: first },
          obj: { from: d2, to: d1 },
        }
      }
      return false
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'date') {
          const [m1, d1, y1] = a[index].split('/')
          const [m2, d2, y2] = b[index].split('/')
          const t1 = new Date(y1, m1 - 1, d1)
          const t2 = new Date(y2, m2 - 1, d2)
          if (!isDesc) {
            return t2 - t1
          } else {
            return t1 - t2
          }
        } else if (index === 'amount') {
          const t1 = Number(a[index])
          const t2 = Number(b[index])
          if (!isDesc) {
            return t2 - t1
          } else {
            return t1 - t2
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase())
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },
    handleClick: function(item) {
      this.application = item
      this.dialogDetails = true
      setTimeout(() => {
        document.getElementById('card-details').scrollTop = 0
      }, 100)
      setTimeout(() => {
        this.$refs.appDetail.loadEvents()
      }, 300)
    },

    printReport: function() {
      this.$htmlToPaper('card-details');
    },

    generateCsv: function() {
      let csvContent = 'data:text/csv;charset=utf-8,'
      const keys = []
      const columns = []
      const model = [
        {
          value: 'id',
          text: 'Application ID',
        },
        {
          value: 'status',
          text: 'Status',
        },        
        {
          value: 'applicantName',
          text: 'Applicant',
        },
        {
          value: 'propertyName',
          text: 'Property',
        },        
        {
          value: 'createdAt',
          text: 'Created At',
        },
        {
          value: 'moveInDate',
          text: 'Move In Date',
        },     
        {
          value: 'applicationType',
          text: 'Application Type',
        },           
        {
          value: 'fees',
          text: 'Fees',
        },  
        {
          value: 'amount',
          text: 'Amount',
        },                 
      ]
      model.forEach(item => {
        keys.push(item.value)
        columns.push(item.text)
      })
      csvContent += columns.join('|') + '\r\n'

      this.filteredItems.forEach(item => {
        const row = []
        keys.forEach(key => {
          row.push(item[key])
        })
        csvContent += row.join('|') + '\r\n'
      })
      const uri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('display', 'none')
      link.setAttribute('href', uri)
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
    },
  },
}
</script>


<style lang="scss">

@media (max-width: 600px) {
  .tableForm {
    padding-top: 60px!important;

    .v-input__control {
      border: 2px solid #ededed !important;
    }
    .v-input {
      margin: 8px auto;
    }
  }
}

.table-mobile .v-list__tile {
  height: 90px !important;
}
.v-list__tile {
  height: 70px !important;
}
.trxTitle {
  .v-list__tile {
    padding: 0;  
    margin-top: -20px!important;
  }
}

.tableForm {

  border-left: 0px !important;
  .v-input__control {
    border-radius: 0px !important;
    &:last-child {
      border-left: 2px solid #ededed;
    }
    label {
      font-size: 14px;
    }
  }
}
.removeRadius {
  border-radius: 0px !important;
}
.accInfoTable {
  border-radius: 0px;
  tr {
    cursor: pointer;
  }  
  thead {
    tr {
      height: 70px;   
      th {
        margin: 20px auto;
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }
    }
  }
}

@media (max-width: 600px) {
  .accInfoTable {
    padding-top: 60px;
  }
}

.zinc-box {
  background-color: transparent !important;
  margin-bottom: 20px;
}

.zinc-box__title {
  background-color: #c1c1c1;
  color: #fff;
  padding: 6px 20px;
}

.zinc-box__title h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.zinc-box__body {
  padding: 0 20px;
}

.zinc-box__footer {
  background-color: #efefef;
  padding: 20px;
  font-weight: 400;
  font-size: 18px;
}

.zinc-box__item {
  border-bottom: 1px solid #e4e4e4;
  padding: 18px 0;
}

.zinc-box__item:last-child {
  border-bottom: 0;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.zinc-box__value {
  color: #373041;
  font-size: 18px;
  font-weight: 400;
}

.zinc-box-total {
  background-color: #2B388F;
  min-height: 112px;
  .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 959px) {
  .zinc-box__body {
    padding: 0 18px;
  }
  .zinc-box__item {
    padding: 14px 0;
  }
  .zinc-box__value {
    font-size: 16px;
  }
  .zinc-box__footer {
    padding: 18px;
    font-size: 16px;
  }
}

.app-card-dialog {
  opacity: 1;
  background: #f8f8f8;
}

</style>
