<template>
  <v-app>
    <tev-navbar :title="$t('preferences')" />
    <v-content>
      <v-layout row>
        <v-flex xs12 sm6 mt-5>
          <v-card class="elevation-0 rounded">
            <v-list subheader two-line>
              <v-subheader>Notifications</v-subheader>
              <v-list-tile>
                <v-list-tile-action>
                  <v-checkbox v-model="notifications"></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content @click="notifications = !notifications">
                  <v-list-tile-title>Notifications</v-list-tile-title>
                  <v-list-tile-sub-title>Allow notifications</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  title: window.vm.$t('preferences'),
  data() {
    return {
      notifications: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
}
</script>