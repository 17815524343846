var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "load-signature" },
    [
      _c("div", { staticClass: "preview" }, [
        _c("img", {
          ref: "preview",
          style: { "max-height": _vm.imageHeight },
          attrs: { src: "", alt: "" }
        })
      ]),
      _c("input", {
        ref: "signatureFileInput",
        staticClass: "signature-file-input",
        attrs: { type: "file", accept: "image/x-png,image/gif,image/jpeg" },
        on: { change: _vm.onSignatureFileSelected }
      }),
      _c(
        "v-btn",
        {
          attrs: { color: "primary", small: "", dark: "", depressed: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$refs.signatureFileInput.click()
            }
          }
        },
        [_vm._v(" Select signature image ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }