<template>
  <v-card flat color="transparent text-xs-center mb-2" max-width="500" width="100%">
    <transition name="fade" mode="out-in">
      <div key="1" v-if="!hideIntro">
        <h2 class="indigo--text text--darken-4 mb-4">Welcome to Zinc!</h2>
        <div class="mb-4">
          <img src="../../assets/figure-welcome.svg" height="220" />
        </div>
        <div class="lead mb-4">
          Zinc is a Smart Contract based platform, that allow the performance of
          credible transactions without third parties. These transactions are
          trackable and irreversible.
        </div>
      </div>
      <div v-if="hideIntro">
        <v-flex xs12 pa-4 text-xs-center>
          <img src="../../assets/figure-topay.svg" />
          <div class="title font-weight-light mb-2">
            There are some fees that have to be paid before you can move
            into:
          </div>
          <div class="title mb-3">{{ application.propertyName }} Unit {{ application.unitNumber }}</div>
          <div class="grey--text body-1">
            If you have any questions about these fees please contact the
            property directly.
          </div>
        </v-flex>
        <v-flex xs12 class="pa-4 text-xs-left">
          <v-card class="z-card mb-4">
            <div class="pa-3">
              <h3 class="text-uppercase grey--text text--lighten-1">Total Moving Fees &amp; Deposits</h3>
              <h3 class="primary--text">{{ totalAmount | currency }}</h3>
              <div
                class="primary--text text-lowercase body-1"
                :key="item.description"
                v-for="(item) in variableFees"
              >+ {{ item.feeName }}</div>
            </div>
            <v-divider></v-divider>
            <template v-for="item in application.payments">
              <v-layout row ma-3 :key="item.id">
                <v-flex>
                  <div>
                    <strong>{{ item.feeName }}</strong>
                  </div>
                  <div
                    class="primary--text body-1"
                    v-if="isCCAvailable(item)"
                  >Credit card pre-authorization payment available</div>
                  <div
                    v-if="!isCCAvailable(item)"
                    class="primary--text body-1"
                  >Conveniently pay with ACH</div>
                  <div
                    class="grey--text body-1"
                    v-if="item.contractType == 'deposit'"
                  >Refund in {{ item.refundCounter }} {{ item.refundStepType }}</div>
                </v-flex>
                <v-flex text-xs-right font-weight-bold>
                  <template v-if="item.feeRateType=='flat'">{{ item.amount | currency }}</template>
                  <template v-else>Calculate</template>
                </v-flex>
              </v-layout>
              <v-divider :key="'d'+item.id"></v-divider>
            </template>
            <v-layout row ma-3 pb-4>
              <v-flex font-weight-bold>Total</v-flex>
              <v-flex text-xs-right>
                <div class="font-weight-bold">{{ totalAmount | currency }}</div>
                <div
                  class="grey--text text-lowercase body-1"
                  :key="'v'+item.id"
                  v-for="(item) in variableFees"
                >+ {{ item.feeName }}</div>
              </v-flex>
            </v-layout>
          </v-card>
          <v-btn
            depressed
            block
            round
            large
            color="primary"
            class="text-uppercase mb-4 white--text pulse"
            @click="start()"
          >Let's Start</v-btn>
        </v-flex>
        <z-footer :dark="true"></z-footer>
      </div>
    </transition>
  </v-card>
</template>
<script>
export default {
  props: {
    application: Object,
    totalFees: Number,
    totalDeposits: Number,
    depositsLength: Number,
    feesLength: Number,
    variableDeposits: String,
  },
  data() {
    return {
      isLoading: false,
      hideIntro: false,
      started: false,
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.application.payments.forEach(item => {
        total += parseFloat(item.amount)
      })
      return total
    },
    variableFees() {
      return this.application.payments.filter(
        item => item.feeRateType == 'variable'
      )
    },
  },
  methods: {
    isCCAvailable(item) {
      if (
        item.contractType == 'deposit' &&
        item.refundStepType == 'days' &&
        item.refundCounter < 30
      ) {
        return false
      }
      return false
    },
    start() {
      this.$scrollTo('#app')
      this.$emit('start', true)
    },
  },
  mounted() {
    const _this = this
    setTimeout(function() {
      _this.hideIntro = true
      _this.$emit('step', true)
    }, 3000)
  },
}
</script>
<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 6s ease;
}
.slide-fade-leave-active {
  transition: all 6s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>