var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.step > _vm.titleStep
        ? [
            _vm._v(" " + _vm._s(_vm.title) + " "),
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "ml-2",
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("edit")
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("img", {
                                attrs: { src: require("@/assets/edit.svg") }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1847033223
                )
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("edit")))])]
            )
          ]
        : _vm.step == _vm.titleStep
        ? _c("h2", { staticClass: "title indigo--text text--darken-4" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _c("span", [_vm._v(_vm._s(_vm.title))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }