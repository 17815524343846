<template lang="html">
  <div class="vjsf-form grid-list-md">
    <property
      v-if="!!resolvedSchema"
      :schema="resolvedSchema"
      :model-root="modelWrapper.root"
      :model-wrapper="modelWrapper"
      :options="fullOptions"
      :color="color"
      model-key="root"
      parent-key=""
      :rules="rules"
      @error="e => $emit('error', e)"
      @change="e => $emit('change', e)"
      @input="e => $emit('input', e)"
    >
      <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </property>
  </div>
</template>

<script>
import jrefs from './utils/json-refs'
import colors from './utils/colors'
import Property from './Property.vue'
export default {
  name: 'SchemaForm',
  components: { Property },
  props: {
    schema: {
      type: Object,
    },
    model: { type: Object },
    options: { type: Object },
    rules: { type: Object },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return { modelWrapper: { root: this.model } }
  },
  computed: {
    resolvedSchema() {
      return jrefs.resolve(this.schema)
    },
    fullOptions() {
      const httpLib = this.axios || this.$http || this.$axios
      const vuetifyVersion = !this.$vuetify.theme.themes ? 1 : 2
      const defaultOptions = {
        debug: false,
        httpLib,
        disableAll: false,
        colors,
        autoFoldObjects: false,
        allOfTabs: false,
        requiredMessage: 'This information is required',
        noDataMessage: 'No matching value found',
        searchMessage: 'Search...',
        vuetifyVersion,
        tabsMode: 'grow',
        locale: 'en',
      }
      const fullOptions = Object.assign(
        {},
        defaultOptions,
        this.resolvedSchema['x-options'] || {},
        this.options || {}
      )
      fullOptions.icons = Object.assign(
        this.getIcons(vuetifyVersion),
        fullOptions.icons || {}
      )
      return fullOptions
    },
  },
  methods: {
    getIcons(vuetifyVersion) {
      const supportedIcons = {
        md: {
          calendar: 'event',
          clock: 'clock',
          info: 'info',
          dropdown: 'arrow_drop_down',
          dropup: 'arrow_drop_up',
          add: 'add',
          reorder: 'reorder',
          delete: 'delete',
        },
        mdi: {
          calendar: 'mdi-calendar',
          clock: 'mdi-clock',
          info: 'mdi-information',
          dropdown: 'mdi-menu-down',
          dropup: 'mdi-menu-up',
          add: 'mdi-plus',
          reorder: 'mdi-reorder-horizontal',
          delete: 'mdi-delete',
        },
        fa: {
          calendar: 'fa-calendar-alt',
          clock: 'fa-clock',
          info: 'fa-info-circle',
          dropdown: 'fa-caret-down',
          dropup: 'fa-caret-up',
          add: 'fa-plus',
          reorder: 'fa-ellipsis-v-alt',
          delete: 'fa-trash-alt',
        },
      }
      if (vuetifyVersion === 1) {
        return supportedIcons.md
      } else if (
        this.$vuetify.icons &&
        this.$vuetify.icons.iconfont &&
        supportedIcons[this.$vuetify.icons.iconfont]
      ) {
        return supportedIcons[this.$vuetify.icons.iconfont]
      } else return supportedIcons.mdi
    },
  },
}
</script>
<style>
.vjsf-form.grid-list-md .layout > .flex {
  padding: 4px;
}
</style>
