<template>
  <v-app>
    <v-content class="topay-bg">
      <v-layout row align-center justify-center pt-5 pb-5>
        <z-logo color="#ffffff" slogan class="logo-zinc" />
      </v-layout>
      <div class="text-xs-center pa-5 mb-5 white--text" v-if="isLoading">
        <v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
      </div>
      <transition name="fade">
        <v-layout row wrap px-4 v-if="!isLoading">
          <v-flex xs12>
            <v-card max-width="600px" class="mx-auto">
              <v-flex xs12 pa-4 text-xs-center>
                <img src="@/assets/figure-topay.svg" class="figure-topay" />
                <div class="title font-weight-light mb-2">
                  There are some fees that have to be paid before you can move
                  into:
                </div>
                <div
                  class="title mb-3"
                >{{ application.propertyName }} Unit {{ application.unitNumber }} {{ application.address.city }}, {{ application.address.state }} {{ application.address.postalCode }}</div>
                <div class="grey--text body-1">
                  If you have any questions about these fees please contact the
                  property directly.
                </div>
              </v-flex>
              <v-flex xs12 class="grey lighten-4 pa-4">
                <v-card class="z-card mb-4">
                  <div class="pa-3">
                    <h3 class="text-uppercase grey--text text--lighten-1">Total Moving Fees</h3>
                    <h3 class="primary--text">{{ totalAmount | currency }}</h3>
                    <div
                      class="primary--text text-lowercase"
                      :key="item.description"
                      v-for="(item) in variableFees"
                    >+ {{ item.description }}</div>
                  </div>
                  <v-divider></v-divider>
                  <template v-for="item in application.payments">
                    <v-layout row ma-3 :key="item.id">
                      <v-flex>
                        <div>{{ item.description }}</div>
                        <div class="grey--text" v-if="item.contractType == 'deposit'">Refundable</div>
                      </v-flex>
                      <v-flex text-xs-right font-weight-bold>
                        <template v-if="item.feeRateType=='flat'">{{ item.amount | currency }}</template>
                        <template v-else>{{ $t('pending') }}</template>
                      </v-flex>
                    </v-layout>
                    <v-divider :key="'d'+item.id"></v-divider>
                  </template>
                  <v-layout row ma-3 pb-4>
                    <v-flex font-weight-bold>Total</v-flex>
                    <v-flex text-xs-right>
                      <div class="font-weight-bold">{{ totalAmount | currency }}</div>
                      <div
                        class="grey--text text-lowercase"
                        :key="'v'+item.id"
                        v-for="(item) in variableFees"
                      >+ {{ item.description }}</div>
                    </v-flex>
                  </v-layout>
                </v-card>

                <div class="text-xs-center">
                  <v-btn
                    @click="payApplication"
                    block
                    depressed
                    large
                    color="primary"
                    class="text-uppercase mb-4 white--text"
                  >Pay Now</v-btn>

                  <!--img class="truste-logo" src="@/assets/truste-logo.svg" alt="Truste Logo" /-->
                </div>
              </v-flex>
            </v-card>

            <z-footer :dark="true"></z-footer>
          </v-flex>
        </v-layout>
      </transition>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      applicationCode: null,
      decoded: null,
      application: {
        payments: [],
        address: {},
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    totalAmount() {
      let total = 0
      this.application.payments.forEach(item => {
        total += parseFloat(item.amount)
      })
      return total
    },
    variableFees() {
      return this.application.payments.filter(
        item => item.feeRateType == 'variable'
      )
    },
  },
  methods: {
    payApplication() {
      if (this.decoded[2]) {
        if (
          this.application.applicantStatus == 'active' ||
          (this.currentUser.isLoggedIn &&
            this.currentUser.id == this.application.applicantId)
        ) {
          this.$router.push('/applicant/payment/' + this.decoded[0])
        } else {
          this.$router.push(
            '/register?code=' +
              btoa(this.application.applicantId + '.' + this.decoded[2])
          )
        }
      } else {
        this.$router.push('/applicant/payment/' + this.decoded[0])
      }
    },
    loadApplication() {
      this.isLoading = true
      this.$http
        .get('/public/applicant/applications/' + this.applicationCode)
        .then(response => {
          this.application = response.data
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.applicationCode = this.$route.params.applicationCode
    if (!this.applicationCode) {
      this.$router.push('/')
    }
    this.decoded = atob(this.applicationCode).split('.')

    this.loadApplication()
  },
}
</script>
<style>
.tab-text-color {
  color: #2b388f !important;
  font-size: 30px !important;
  line-height: 35px !important;
  font-weight: bold !important;
  width: 200px;
}
.figure-topay {
  margin-top: -40px;
  margin-bottom: 24px;
}
.topay-bg {
  background: #110867;
  background: linear-gradient(0deg, #110867 0%, #8240a7 100%);
}
.truste-logo {
  height: 40px;
}
</style>
