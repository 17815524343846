export default {
  logout: 'Salir',
  search: 'Buscar',
  settings: 'Ajustes',
  select: 'Seleccionar',
  save: 'Guardar',
  add: 'Añadir',
  users: 'Usuarios',
  members: 'Miembros',
  invite_new_members: 'Invitar a nuevos miembros',
  invite_new_members_property:
    'Invita a nuevos miembros a administrar esta propiedad',
  invite_people_to_zinc: 'Invita a la gente a Zinc',
  invite: 'Invitar',
  password: 'Contraseña',
  admin_invitation_sent_explain:
    'La invitación ha sido enviada. Los nuevos miembros de la organización recibirán un correo electrónico que les dará acceso a Zinc.',
  close: 'Cerrar',
  send: 'Enviar',
  cancel: 'Cancelar',
  active: 'Activo',
  no: 'No',
  yes: 'Sí',
  ok: 'OK',
  import: 'Importar',

  session_expired: 'Sesión expirada',
  session_expired_explain: 'Tu sesión ha caducado. Inicia sesión de nuevo.',

  complete_required: 'Debes completar los campos obligatorios.',

  name: 'Nombre',
  first_name: 'Nombre',
  last_name: 'Apellidos',
  my_organizations: 'Mis organizaciones',
  organization_id: 'Organización ID',
  organization_name: 'Nombre de la organización',
  organization_info: 'Información del organización',
  organization: 'Organización',
  representative: 'Representante',
  phone: 'Teléfono',
  dialcode: 'Prefijo telefónico',
  add_commission_value: 'Agregar valor comisión',

  transactions: 'Transacciones',
  current_transactions_list: 'Lista de transacciones actuales',
  transactions_details: 'Detalles de la transacción',

  status: 'Estado',
  pending: 'Pendiente',
  completed: 'Completado',
  waiting: 'Esperando',

  application_id: 'ID de aplicación',
  property_id: 'ID de propiedad',
  id_number: 'Número de identificación',
  applications: 'Aplicaciones',
  applicant_info: 'Información del aplicante',
  applicant_name: 'Nombre del aplicante',
  mobile_phone: 'Teléfono móvil',
  email: 'Correo electrónico',
  date_of_birth: 'Fecha de nacimiento',
  us_social_security: 'Seguridad social de EE. UU.',
  property_info: 'Información de propiedad',
  property_name: 'Nombre de la propiedad',
  address: 'Dirección',
  city: 'Ciudad',
  state: 'Estado',
  zip_code: 'Código postal',
  move_in_date: 'Fecha de mudanza',
  monthly_rent: 'Renta mensual',
  type_of_application: 'Tipo de aplicacion',
  lease: 'Arrendamiento',
  transaction_status: 'Estado de la transacción',
  documents_signed: 'Documentos firmados',
  detail_of_collected_fees: 'Detalle de tarifas cobradas',
  move_in_fee: 'Tarifa de mudanza',
  fee: 'Tarifa',
  non_refundable: 'No reembolsable',
  type_of_fee: 'Tipo de tarifa',
  due_date: 'Fecha de vencimiento',
  rate: 'Tarifa',
  elevator_deposit_fee: 'Cargo por depósito de ascensor',
  total_to_be_collected: 'Total a recoger',

  import_properties: 'Importar Propiedades',
  properties: 'Propiedades',
  property: 'Propiedad',
  my_property: 'Mi propiedad',
  property_management_company: 'Empresa de gestión de la propiedad',
  active_members: 'Miembros activos',

  transfer_property: 'Transferir propiedad',
  new_property_management_company: 'Nueva empresa de gestión de propiedades',

  manager_first_name: 'Nombre del manager',
  manager_last_name: 'Apellido del manager',
  cell_phone: 'Teléfono móvil',
  role: 'Rol',

  profile: 'Perfil',  
  my_profile: 'Mi perfil',
  my_digital_id: 'Mi digital ID',
  share_id: 'Compartir ID',

  reports: 'Reportes',
  filter_your_search: 'Filtra tu búsqueda',
  from_date: 'Desde la fecha',
  to_date: 'Hasta la fecha',
  transaction_type: 'Tipo de transacción',
  generate_reports: 'Generar informes',

  my_properties: 'Mis propiedades',
  properties_list: 'Lista de propiedades',
  import_explain:
    'Selecciona las propiedades en las que deseas que Zinc colecte los pagos.',

  payment_setup: 'Configuración de pago',
  payment_setup_explain:
    '¿Cómo quieres hacer tus pagos? Puede agregar varias cuentas y editarlas en cualquier momento.',

  checking_account: 'Cuenta de cheques',
  manual_setup: 'Configuración manual',
  debit_or_credit_card: 'Tarjeta de débito o crédito',

  payments_takes_days:
    'Los pagos tardan aproximadamente {days} días hábiles en completarse.',
  amount_transaction_fee: 'Tarifa de transacción de {amount}',

  my_smart_services: 'Mis Smart Services',
  select_smartservices_explain:
    'Selecciona los contratos inteligentes que deseas asignar a esta propiedad',

  one_time_fee: 'Tarifa única',
  recurring_fee: 'Cobro recurrente',
  special_assessments_fee: 'Cuota de tasa especial',
  miscellaneous: 'Misceláneo',

  change_password: 'Cambiar contraseña',
  current_password: 'Contraseña actual',
  new_password: 'Nueva contraseña',
  confirm_password: 'Confirmar contraseña',
  continue: 'Continuar',

  signincredentials: 'Inicia sesión con tus credenciales',
  signin: 'Acceder',
  forgotyourpassword: '¿Olvidaste tu contraseña?',

  create_your_account: 'Crea tu cuenta',
  enter_your_code: 'Introduce tu código',
  enter_access_code: 'Ingresa el código de acceso',
  access_code: 'Código de acceso',
  security_code: 'Código de seguridad',
  validate: 'Validar',
  create_account: 'Crear cuenta',
  justsentsms_explain:
    'Acabamos de enviarte un mensaje de texto con un código para que lo introduzcas aquí.',
  phoneno: 'Teléfono Nº',
  sendmenewcode: 'Envíame un nuevo código',
  verify: 'Verificar',

  your_account: 'Tu cuenta',
  invitations: 'Invitaciones',
  organizations: 'Organizaciones',

  your_notifications: 'Tus notificaciones',
  view_more: 'Ver más',
  view_all_notifications: 'Ver todas las notificaciones',

  login_history: 'Historial de sesión',
  platform: 'Platforma',
  browser: 'Navegador',
  notifications: 'Notificaciones',

  preferences: 'Preferencias',
  select_all: 'Selecionar todo',
  unselect_all: 'Deseleccionar todo',

  org_total_importproperties:
    '¡Tu organización tiene {total} propiedades disponibles para importar!',

  property_activity: 'Actividad de la propiedad',
  no_activity_explain: 'No hay actividad disponible para esta propiedad.',

  member_type: 'Tipo de miembro',
  member_details: 'Detalles del miembro',

  select_image_area: 'Seleccionar área de la imagen',

  home: 'Inicio',
  payments: 'Pagos',
  get_loan: 'Obtener préstamo',

  two_types: '2 tipos',
  the_are: 'Existen',
  of_fees: 'de fees',

  my_payments: 'Mis pagos',
}
