<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar />
    <tev-subnavbar :back="'/manager/applications'" title="Application Detail" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-card class="transparent elevation-0" width="600" max-width="100%">
            <!-- applicant_info -->
            <v-layout align-center justify-center class="mb-3">
              <h3 class="text-uppercase grey--text text--lighten-1">{{ $t('applicant_info') }}</h3>
              <v-spacer></v-spacer>
              <z-status-chip :status="application.status" :label="$t(application.status)"></z-status-chip>
            </v-layout>

            <v-card class="z-card mb-4">
              <v-card-text class="pa-0">
                <v-list two-line class="transparent pa-0">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>
                          {{ application.firstName }}
                          {{ application.lastName }}
                        </strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('applicant')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.unitNumber
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Unit Number</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.applicationType
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Type of Application</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.moveInDate
                        | moment('dddd, MMMM Do YYYY, h:mm:ss a')
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Move In Date</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <template v-if="application.moveOutDate">
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{
                          application.moveOutDate
                          | moment('dddd, MMMM Do YYYY, h:mm:ss a')
                          }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Move Out Date</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <v-divider></v-divider>
                  <v-list-tile v-if="application.monthlyRent">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.monthlyRent | currency
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Monthly Rent</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.pet
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Pet</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.cellPhone
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Cell Phone</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.email
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Email Address</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        application.createdAt
                        | moment('dddd, MMMM Do YYYY, h:mm:ss a')
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Created</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card-text>
            </v-card>

            <h3 class="text-uppercase grey--text text--lighten-1 mb-3">{{ $t('transactions') }}</h3>

            <v-card
              class="z-card mb-4 pa-0"
              :key="transaction.id"
              v-for="transaction in application.transactions"
            >
              <v-card-text class="pa-0">
                <v-list two-line class="transparent pa-0">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ transaction.feeName }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Fee Description</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <template v-if="transaction.contractType == 'deposit'">Refundable</template>
                        <template v-else>Non-Refundable</template>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Type of Fee</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        transaction.bankAlias
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Bank Account</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <template v-if="transaction.submittedAt">
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{
                          transaction.submittedAt | moment('MM/DD/YYYY')
                          }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Date Submitted</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action></v-list-tile-action>
                    </v-list-tile>
                  </template>
                  <template v-if="transaction.collectedAt">
                    <v-divider></v-divider>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{
                          transaction.collectedAt | moment('MM/DD/YYYY')
                          }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Date Collected</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action></v-list-tile-action>
                    </v-list-tile>
                  </template>
                  <template
                    v-if="
                      transaction.contractType == 'deposit' &&
                        transaction.refundedAt
                    "
                  >
                    <v-divider></v-divider>
                    <v-list-tile v-if="transaction.contractType == 'deposit'">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{
                          transaction.refundedAt | moment('MM/DD/YYYY')
                          }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Refund Date</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <countdown :time="new Date(transaction.refundedAt).getTime()  - new Date().getTime()">
                          <template slot-scope="props">
                            <v-layout>
                              <div class="mr-2">
                                {{ props.days }}
                                <div class="body-1 light-green--text">days</div>
                              </div>
                              <div class="mr-2">
                                {{ props.hours }}
                                <div class="body-1 light-green--text">hours</div>
                              </div>
                              <div class="mr-2">
                                {{ props.minutes }}
                                <div class="body-1 light-green--text">minutes</div>
                              </div>
                              <img src="@/assets/schedule.svg" height="22" />
                            </v-layout>
                          </template>
                        </countdown>
                      </v-list-tile-action>
                    </v-list-tile>
                  </template>
                </v-list>
                <template>
                  <v-divider></v-divider>
                  <v-layout
                    row
                    align-center
                    justify-center
                    v-if="transaction.contractType == 'deposit'"
                    class="py-3 text-xs-center"
                  >
                    <v-flex xs12 sm6>
                      <v-btn
                        round
                        depressed
                        color="primary"
                        class="text-uppercase body-2"
                      >Extend Smart Contract</v-btn>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-btn
                        round
                        depressed
                        color="primary"
                        class="text-uppercase body-2"
                        @click="newIncident(transaction)"
                      >Document An Incident</v-btn>
                    </v-flex>
                  </v-layout>
                </template>

                <v-layout align-center justify-center class="light-green white--text pa-3">
                  <span class="text-uppercase body-1">
                    <strong>{{ transaction.status }}</strong>
                  </span>
                  <v-spacer></v-spacer>
                  <strong
                    class="title"
                    v-if="
                      transaction.feeRateType != 'variable' &&
                        transaction.amount != 0
                    "
                  >{{ transaction.amount | currency }}</strong>
                  <span v-else>Need To Calculate</span>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-card>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
    <v-dialog v-model="dialogIncident" persistent scrollable max-width="580">
      <v-card class="grey lighten-5">
        <v-toolbar class="transparent" card>
          <v-toolbar-title class="text-uppercase subheading primary--text">Document An Incident</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogIncident = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="grey--text">
          <p>Describe the incident</p>
          <div class="pa-2 white mb-3">
            <v-textarea
              class="pt-0"
              background-color="white"
              placeholder="Add text here"
              hide-details
            ></v-textarea>
          </div>

          <v-layout align-start class="mb-3">
            <div
              class="file-upload-thumb"
              @click="imagesToUpload.splice(index, 1)"
              :key="index"
              v-for="(image,index) in imagesToUpload"
            >
              <v-img :src="image" width="100" height="100" aspect-ratio="1" />
              <div class="file-remove">
                <v-icon>remove_circle</v-icon>Remove
              </div>
            </div>

            <div class="file-upload" v-if="imagesToUpload.length<5">
              <div class="input-wrapper" style="opacity: 1;">
                <input
                  id="file-upload-input"
                  type="file"
                  name="file"
                  accept=".png, .jpg"
                  class="input-with-value file-upload-input"
                  @change="onFileChange"
                />
                <label for="file-upload-input" class="file-upload-label">
                  <v-icon>add_circle</v-icon>Add Image
                </label>
              </div>
            </div>
          </v-layout>

          <p>Add the amount to be deducted from deposit</p>
          <div class="pa-2 white mb-4">
            <v-text-field
              class="pt-0"
              placeholder="Add amount"
              background-color="white"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <v-layout class="py-2">
            <span class="black--text">Current Balance</span>
            <span></span>
          </v-layout>
          <v-divider class="mb-4"></v-divider>
          <p>Client will get an email with all the details.</p>
        </v-card-text>
        <v-card-actions class="pa-0" style="width: 100%;">
          <v-btn
            color="light-green"
            class="text-uppercase"
            large
            block
            dark
            depressed
            @click="dialogConfirm=true"
          >Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="320">
      <v-card>
        <v-toolbar class="transparent" card>
          <v-toolbar-title class="black--text">Confirm Incident</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogConfirm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <p>This action cannot be undone.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed flat @click="dialogConfirm = false">Cancel</v-btn>
          <v-btn depressed color="primary" @click="submitIncident">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  title: 'Application Details',
  data() {
    return {
      error: false,
      applicationId: null,
      application: {},
      dialogIncident: false,
      dialogConfirm: false,
      imagesToUpload: [],
      minImageWidth: 400,
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  methods: {
    newIncident() {
      this.dialogIncident = true
    },
    onFileChange(event) {
      // Handle files like:
      this.validateImage(event)
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          var image = new Image()
          image.src = reader.result
          image.onload = function() {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 1000,
              width = image.width,
              height = image.height

            if (width > height) {
              if (width > max_size) {
                height *= max_size / width
                width = max_size
              }
            } else {
              if (height > max_size) {
                width *= max_size / height
                height = max_size
              }
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            var dataUrl = canvas.toDataURL('image/jpeg')

            resolve({
              width: width,
              height: height,
              imageData: dataUrl,
            })
          }
        }
        reader.onerror = error => reject(error)
      })
    },
    validateImage(event) {
      let fileList = event.target.files
      let file = fileList[0]
      let ext = file.name.match(/(?<=\.)\w+$/g)[0].toLowerCase()

      if (ext !== 'jpg' && ext !== 'jpeg') {
        event.target.value = ''
        this.$log.error('Wrong file extension!')
      }

      this.getBase64(file)
        .then(data => {
          this.$log.info(data)
          this.imagesToUpload.push(data.imageData)
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {})
    },
    load() {
      this.$http
        .get('/manager/applications/' + this.applicationId)
        .then(res => {
          this.application = res.data
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/applications')
        })
    },
    submitIncident() {
      this.$http
        .post('/manager/applications/' + this.applicationId + '/incident', {
          images: this.imagesToUpload,
        })
        .then(res => {
          this.$log.info(res.data)
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.dialogIncident = false
        })
    },
  },
  mounted() {
    this.applicationId = this.$route.params.applicationId
    if (!this.applicationId) {
      this.$router.push('/' + this.currentUser.accountType + '/applications')
    }
    this.load()
  },
}
</script>
<style lang="stylus">
.file-upload-thumb {
  width: 100px;
  height: 100px;
  margin-right: 8px;
  border: 1px solid #999;
  background: #999;
  overflow: hidden;
  position: relative;

  .file-remove {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0.8em;
    color: #2196f3;
    display: inline-block;
    padding: 10px;
    position: absolute;
    z-index: 2;
    line-height: 1.4em;
    display: none;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;

    .v-icon {
      display: block;
      font-size: 50px;
      margin: 8px;
      color: #2196f3;
      pointer-events: none;
    }
  }

  &:hover {
    .file-remove {
      display: block;
      cursor: pointer;
    }
  }
}

.file-upload {
  .input-wrapper {
    text-align: center;
    position: relative;
    background-color: transparent;
    border: 1px solid #2196f3;
    height: 100px;
    width: 100px;

    &:hover {
      background-color: #efefef;
    }

    .file-upload-input {
      width: 100px;
      height: 100px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      display: block;
      z-index: -1;
    }

    .file-upload-label {
      width: 100%;
      height: 100%;
      font-size: 0.8em;
      color: #2196f3;
      display: inline-block;
      padding: 10px;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      line-height: 1.4em;

      .v-icon {
        display: block;
        font-size: 50px;
        margin: 8px;
        color: #2196f3;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>