var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-list-tile",
                { staticClass: "trxTitle px-0 mt-5" },
                [
                  _c(
                    "v-list-tile-content",
                    { staticClass: "pl-0" },
                    [
                      _c(
                        "v-list-tile-title",
                        {
                          staticClass: "text-uppercase",
                          staticStyle: { color: "rgb(193,193,193)" }
                        },
                        [_vm._v("transactions")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-capitalize",
                          attrs: {
                            small: _vm.isMobile,
                            flat: "",
                            color: "info"
                          },
                          on: { click: _vm.generateCsv }
                        },
                        [_vm._v("create report")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-tile",
                { staticClass: "px-0 tableForm" },
                [
                  _c(
                    "v-layout",
                    { staticClass: "mx-0", attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pa-0", attrs: { xs12: "", sm5: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              flat: "",
                              solo: "",
                              "hide-details": "",
                              label: "Description or transaction ID"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c("v-icon", { attrs: { size: "20" } }, [
                                      _vm._v("search")
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-0", attrs: { xs12: "", sm5: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.dateRange,
                              flat: "",
                              readonly: "",
                              solo: "",
                              "hide-details": "",
                              label: "Date Range"
                            },
                            on: {
                              click: function($event) {
                                _vm.datePickerModal = !_vm.datePickerModal
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c("v-icon", { attrs: { size: "20" } }, [
                                      _vm._v("today")
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-0", attrs: { xs12: "", sm2: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.statusFilter,
                              flat: "",
                              solo: "",
                              "hide-details": "",
                              label: "Filter"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c("v-icon", { attrs: { size: "20" } }, [
                                      _vm._v("filter_list")
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.status,
                              callback: function($$v) {
                                _vm.status = $$v
                              },
                              expression: "status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290", "content-class": "removeRadius" },
          model: {
            value: _vm.datePickerModal,
            callback: function($$v) {
              _vm.datePickerModal = $$v
            },
            expression: "datePickerModal"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-date-picker", {
                staticClass: "elevation-0",
                attrs: { multiple: "" },
                model: {
                  value: _vm.range,
                  callback: function($$v) {
                    _vm.range = $$v
                  },
                  expression: "range"
                }
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.range = []
                        }
                      }
                    },
                    [_vm._v("clear")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "info", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.datePickerModal = false
                        }
                      }
                    },
                    [_vm._v("done")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "pt-5": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                staticClass: "accInfoTable elevation-2",
                attrs: {
                  headers: _vm.headers,
                  "disable-initial-sort": "",
                  "hide-default-header": _vm.isMobile,
                  "hide-headers": _vm.isMobile,
                  items: _vm.filteredItems,
                  "custom-sort": _vm.customSort,
                  pagination: _vm.pagination
                },
                on: {
                  "update:pagination": function($event) {
                    _vm.pagination = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.isMobile
                          ? _c(
                              "v-list-tile",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleClick(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c("v-list-tile-title", [
                                      _vm._v(_vm._s(item.note))
                                    ]),
                                    _c(
                                      "v-list-tile-sub-title",
                                      { staticClass: "caption" },
                                      [_vm._v(" " + _vm._s(item.date) + " ")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-tile-content",
                                  { staticClass: "text-xs-right" },
                                  [
                                    _c(
                                      "v-list-tile-title",
                                      { staticClass: "text-xs-right" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-weight-bold",
                                            class: [
                                              item.amount > 0
                                                ? "success--text"
                                                : ""
                                            ]
                                          },
                                          [
                                            item.amount > 0
                                              ? _c("span", [_vm._v("+")])
                                              : _vm._e(),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(item.amount)
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-list-tile-sub-title",
                                      { staticClass: "caption" },
                                      [_vm._v(_vm._s(item.status))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isMobile ? _c("v-divider") : _vm._e(),
                        !_vm.isMobile
                          ? _c(
                              "tr",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleClick(item)
                                  }
                                }
                              },
                              [
                                _c("td", [_vm._v(_vm._s(item.note))]),
                                _c("td", [_vm._v(_vm._s(item.date))]),
                                _c("td", [_vm._v(_vm._s(item.fromto))]),
                                _c("td", [_vm._v(_vm._s(item.status))]),
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-bold body-1",
                                      class: [
                                        item.amount > 0 ? "success--text" : ""
                                      ]
                                    },
                                    [
                                      item.amount > 0
                                        ? _c("span", [_vm._v("+")])
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(_vm._f("currency")(item.amount))
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            id: "app-details",
            "max-width": "400px",
            "content-class": "removeRadius"
          },
          model: {
            value: _vm.details,
            callback: function($$v) {
              _vm.details = $$v
            },
            expression: "details"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-toolbar",
                { staticClass: "transparent", attrs: { card: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "text-uppercase subheading primary--text" },
                    [_vm._v("Transaction Details")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.details = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider")
            ],
            1
          ),
          _c(
            "v-list",
            { staticClass: "removeRadius pb-0" },
            [
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c(
                        "v-list-tile-title",
                        { staticClass: "font-weight-bold" },
                        [_vm._v(" " + _vm._s(_vm.onDialog.note) + " ")]
                      ),
                      _c("v-list-tile-sub-title", [_vm._v("Description")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v(_vm._s(_vm.onDialog.date))
                      ]),
                      _c("v-list-tile-sub-title", [_vm._v("Date")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v(_vm._s(_vm.onDialog.sourceName))
                      ]),
                      _c("v-list-tile-sub-title", [_vm._v("From")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v(_vm._s(_vm.onDialog.destinationName))
                      ]),
                      _c("v-list-tile-sub-title", [_vm._v("To")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v(_vm._s(_vm.onDialog.id))
                      ]),
                      _c("v-list-tile-sub-title", [_vm._v("Transaction ID")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-tile",
                {
                  staticStyle: { background: "#8BC53F" },
                  on: {
                    click: function($event) {
                      _vm.details = false
                    }
                  }
                },
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c(
                        "v-list-tile-title",
                        {
                          staticClass:
                            "white--text text-uppercase font-weight-bold"
                        },
                        [_vm._v(_vm._s(_vm.onDialog.status))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-list-tile-title",
                        { staticClass: "white--text font-weight-bold" },
                        [
                          _vm.onDialog.amount > 0
                            ? _c("span", [_vm._v("+")])
                            : _vm._e(),
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.onDialog.amount))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }