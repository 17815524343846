var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-layout",
        { staticClass: "text-xs-left", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-checkbox", {
                attrs: {
                  color: "primary",
                  label: _vm.agreeText,
                  rules: _vm.rules.required
                },
                on: { change: _vm.emitChange },
                model: {
                  value: _vm.signerAgree,
                  callback: function($$v) {
                    _vm.signerAgree = $$v
                  },
                  expression: "signerAgree"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.signerNameText
                ? _c("v-text-field", {
                    attrs: {
                      value: _vm.signerNameText,
                      label: "Printed Name",
                      rules: _vm.rules.required,
                      readonly: true,
                      required: "",
                      box: "",
                      "background-color": _vm.backgroundColor
                    }
                  })
                : _c("v-text-field", {
                    attrs: {
                      label: "Printed Name",
                      rules: _vm.rules.required,
                      required: "",
                      box: "",
                      "background-color": _vm.backgroundColor
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.signerName,
                      callback: function($$v) {
                        _vm.signerName = $$v
                      },
                      expression: "signerName"
                    }
                  })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Date",
                  readonly: "",
                  box: "",
                  "background-color": _vm.backgroundColor
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c("img", {
                          attrs: {
                            width: "16",
                            height: "16",
                            src: require("@/assets/calendar.svg")
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.signingDate,
                  callback: function($$v) {
                    _vm.signingDate = $$v
                  },
                  expression: "signingDate"
                }
              })
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c(
              "div",
              {
                staticClass: "pa-3",
                style: "background-color:" + _vm.backgroundColor
              },
              [
                _c("TeSignaturePad", {
                  ref: "TeSignaturePad",
                  attrs: {
                    signatureText: _vm.signerNameText
                      ? _vm.signerNameText
                      : _vm.signerName
                  },
                  on: { onSignature: _vm.onSignatureChange }
                }),
                _vm.signError
                  ? _c("div", { staticClass: "v-text-field__details white" }, [
                      _c(
                        "div",
                        { staticClass: "v-messages theme--light error--text" },
                        [
                          _c("div", { staticClass: "v-messages__wrapper" }, [
                            _c("div", { staticClass: "v-messages__message" }, [
                              _vm._v("Signature is required.")
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }