<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar :title="$t('transactions')" />
    <tev-subnavbar :title="$t('transactions_details')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm12>
            <v-flex py-2 text-xs-right grey--text>{{ $t('id_number') }}: {{ applicationId }}</v-flex>
            <!-- applicant_info -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>{{ $t('applicant_info') }}</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body">
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ applicantName }}</div>
                  <div class="zinc-box__label">{{ $t('applicant_name') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ mobilePhone }}</div>
                  <div class="zinc-box__label">{{ $t('mobile_phone') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ email }}</div>
                  <div class="zinc-box__label">{{ $t('email') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ dateBirth }}</div>
                  <div class="zinc-box__label">{{ $t('date_of_birth') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ ssNumber }}</div>
                  <div class="zinc-box__label">{{ $t('us_social_security') }}</div>
                </div>
              </v-flex>
            </v-flex>
            <!-- / applicant_info -->
          </v-flex>
          <v-flex xs12 sm12>
            <!-- property_info -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>{{ $t('property_info') }}</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body">
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ propertyName }}</div>
                  <div class="zinc-box__label">{{ $t('property_name') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ address }}</div>
                  <div class="zinc-box__label">{{ $t('address') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ city }}</div>
                  <div class="zinc-box__label">{{ $t('city') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ state }}</div>
                  <div class="zinc-box__label">{{ $t('state') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ zipCode }}</div>
                  <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ moveInDate }}</div>
                  <div class="zinc-box__label">{{ $t('move_in_date') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ monthlyRent }}</div>
                  <div class="zinc-box__label">{{ $t('monthly_rent') }}</div>
                </div>
              </v-flex>
              <v-flex x12 class="zinc-box__footer">{{ $t('type_of_application') }}</v-flex>
            </v-flex>
            <!-- / property_info -->
          </v-flex>
          <v-flex xs12 sm12>
            <!-- tx-progress -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>{{ $t('transaction_status') }}</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body" pb-4 text-xs-center>
                <z-progress-tx :step="3"></z-progress-tx>
                <h4 class="subheading font-weight-medium">03.26.2019</h4>
                <div class="grey--text body-2">Documents signed</div>
              </v-flex>
            </v-flex>
            <!-- / tx-progress -->
          </v-flex>

          <v-flex xs12 sm12>
            <!-- detail_of_collected_fees -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>{{ $t('detail_of_collected_fees') }}</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body">
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ $t('move_in_fee') }}</div>
                  <div class="zinc-box__label">{{ $t('fee') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ $t(status.toLowerCase()) }}</div>
                  <div class="zinc-box__label">{{ $t('status') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ typeFee }}</div>
                  <div class="zinc-box__label">{{ $t('type_of_fee') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ dueDate }}</div>
                  <div class="zinc-box__label">{{ $t('due_date') }}</div>
                </div>
                <div class="zinc-box__item">
                  <div class="zinc-box__value">{{ rate }}</div>
                  <div class="zinc-box__label">{{ $t('rate') }}</div>
                </div>
              </v-flex>
            </v-flex>
            <!-- / detail_of_collected_fees -->
          </v-flex>
          <v-flex xs12 sm12>
            <!-- detail_of_collected_fees -->
            <v-flex class="zinc-box-total primary white--text" pa-4 mb-5 text-xs-center>
              <div>{{ $t('total_to_be_collected') }}</div>
              <div class="headline font-weight-bold">$150.00</div>
            </v-flex>
            <!-- / detail_of_collected_fees -->
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import ZProgressTx from '../../components/ProgressTx'
export default {
  data() {
    return {
      applicationId: 8684,
      applicantName: 'Marco Thompson',
      mobilePhone: '305.000.0000',
      email: 'marco@mail.com',
      dateBirth: 'xx / xx / 1982',
      ssNumber: 'xxx - xx - 0644',
      propertyName: 'Icon Brickell Condo No2',
      address: 'Address: 475 Brickell Ave',
      city: 'Miami',
      state: 'Florida',
      zipCode: '33131',
      moveInDate: '05 / 06 / 2019',
      monthlyRent: '$ 1.600',
      moveInFee: 'Move in fee',
      status: 'Waiting',
      typeFee: 'Non-refundable',
      dueDate: 'NA',
      rate: '$150.00',
    }
  },
  components: {
    'z-progress-tx': ZProgressTx,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (!this.currentUser || !localStorage.token) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
  },
  mounted() {},
}
</script>
<style>
.zinc-box {
  background-color: #fff;
  margin-bottom: 20px;
}

.zinc-box__title {
  background-color: #c1c1c1;
  color: #fff;
  padding: 6px 20px;
}

.zinc-box__title h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.zinc-box__body {
  padding: 0 20px;
}

.zinc-box__footer {
  background-color: #efefef;
  padding: 20px;
  font-weight: 400;
  font-size: 18px;
}

.zinc-box__item {
  border-bottom: 1px solid #e4e4e4;
  padding: 18px 0;
}

.zinc-box__item:last-child {
  border-bottom: 0;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.zinc-box__value {
  color: #373041;
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (max-width: 959px) {
  .zinc-box__body {
    padding: 0 18px;
  }
  .zinc-box__item {
    padding: 14px 0;
  }
  .zinc-box__value {
    font-size: 16px;
  }
  .zinc-box__footer {
    padding: 18px;
    font-size: 16px;
  }
}
</style>