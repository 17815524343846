var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("transactions") } }),
      _c("tev-subnavbar", {
        attrs: { title: _vm.$t("current_transactions_list") }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "mb-5 text-xs-center",
                  attrs: { xs12: "", sm12: "" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "transparent mb-4",
                      attrs: { "two-line": "" }
                    },
                    [
                      _vm._l(_vm.transactions, function(item) {
                        return [
                          _c(
                            "v-list-tile",
                            { key: item.id, attrs: { avatar: "" } },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(item.fullName))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(_vm._s(item.property_name))
                                  ])
                                ],
                                1
                              ),
                              _c("v-list-tile-action", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tx-status",
                                    class: {
                                      "tx-status-pending":
                                        item.status == "Pending"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(item.status.toLowerCase())
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-divider", { key: item.id + "_divider" })
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }