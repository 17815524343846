<template>
  <v-app>
    <tev-navbar :title="(property.name)?'Property: ' + property.name:''" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8>
            <v-flex x12 sm12>
              <v-layout row wrap align-start justify-start>
                <v-btn
                  icon
                  class="light-green--text"
                  @click="goBack()"
                >
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-flex py-3 text-xs-right grey--text>
                  <div>
                    &nbsp;
                    <template v-if="propertyId"
                      >{{ $t('property_id') }}: {{ property.tevPropertyId }}</template
                    >
                  </div>
                  <z-status-chip
                    :status="property.status"
                    :label="$t(property.status)"
                  ></z-status-chip>
                </v-flex>
              </v-layout>
            </v-flex>
              
            <template v-if="paymentMethod == 'ach'">

              <BankSetup
                :variableSetup="variableSetup"
                :application="application"
                :totalAmount="totalAmount"
                :contractType="currentPayStep"
                :property="property"
                v-if="paymentMethod == 'ach' && !bankSetup"
                v-on:success="bankSetupSuccess"
              ></BankSetup>

              <PayAch
                :variableSetup="variableSetup"
                :application="application"
                :totalAmount="totalAmount"
                :contractType="currentPayStep"
                :bankSetup="bankSetup"
                :property="property"
                v-if="paymentMethod == 'ach' && bankSetup"
                v-on:success="paySuccess"
              ></PayAch>
            </template>

            <template v-else>
              <!-- property_info -->
              <v-flex xs12 sm12 mb-4>
                <v-card class="z-card">
                  <v-card-text class="px-2 py-1 pl-3 pr-3">                  
                    <div class="zinc-box__item">
                      <div class="zinc-box__value property-name">{{ property.name }}</div>
                      <div class="zinc-box__label">{{ $t('property_name') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">
                        {{ property.address.line1 }}
                      </div>
                      <div class="zinc-box__label">{{ $t('address') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">
                        {{ property.address.city }}
                      </div>
                      <div class="zinc-box__label">{{ $t('city') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">
                        {{ property.address.state }}
                      </div>
                      <div class="zinc-box__label">{{ $t('state') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">
                        {{ property.address.postalCode }}
                      </div>
                      <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!-- / property_info -->

              <v-flex xs12 sm12 mb-4 v-if="managers.length">
                <h3 class="text-uppercase grey--text text--lighten-1 mb-3">
                  Members
                </h3>
                <v-card class="z-card">
                  <v-card-text class="px-2 py-1">
                    <v-list two-line class="transparent">
                      <template v-for="(item, index) in managers">
                        <v-divider
                          :key="'d' + index"
                          v-if="index > 0"
                        ></v-divider>
                        <v-list-tile
                          :key="'t' + index"
                          avatar
                          :to="'/accountant/members/' + item.id"
                        >
                          <v-list-tile-avatar>
                            <Lettericon
                              :name="item.fullName"
                              size="2"
                            ></Lettericon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              {{ item.fullName }}
                            </v-list-tile-title>
                            <v-list-tile-sub-title
                              >Property Manager</v-list-tile-sub-title
                            >
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-btn small icon ripple color="primary">
                              <v-icon class="white--text">chevron_right</v-icon>
                            </v-btn>
                          </v-list-tile-action>
                        </v-list-tile>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12 sm12 mb-4 v-if="isLoading">
                <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>
              </v-flex>

              <v-flex xs12 sm12 mb-4 v-if="!isLoading">
                <v-card
                  class="z-card primary--border mb-4"
                  v-if="dwStatus=='unverified'"
                >
                  <v-card-text class="pa-3">
                    <v-layout align-center justify-start row>
                      <img
                        src="@/assets/icon-onboarding.svg"
                        width="50"
                        height="50"
                        class="mr-4"
                      />
                      <div
                        class="primary--text font-weight-bold mb-1 text-uppercase text-xs-left"
                      >
                        Property Onboarding
                      </div>
                      <v-spacer></v-spacer>
                    </v-layout>
                    <template v-if="!startOnboarding">
                      <div class="grey--text body-1 mt-4 mb-1">
                        <div class="mb-3">
                          In order to start collecting fees and deposits, we need to
                          verify some information related with your property. Before
                          you continue, here is a check list of the information
                          required in order to onboard your property:
                        </div>
                        <div class="mb-2">
                          <v-icon color="light-green" class="mr-2">done</v-icon
                          >Registration and personal Information
                        </div>
                        <div class="mb-2">
                          <v-icon color="light-green" class="mr-2">done</v-icon
                          >Controller Information
                        </div>

                        <div class="mb-2">
                          <v-icon color="light-green" class="mr-2">done</v-icon
                          >Company Information
                        </div>
                        <div class="mb-2">
                          <v-icon color="light-green" class="mr-2">done</v-icon
                          >Company Details
                        </div>
                        <div class="mb-2">
                          <v-icon color="light-green" class="mr-2">done</v-icon>Add
                          bank accounts and setup fee
                        </div>
                      </div>
                      <div class="text-xs-right">
                        <v-btn
                          large
                          round
                          color="primary"
                          depressed
                          class="text-uppercase"
                          @click="startOnboarding = true"
                          >Start Property Onboarding</v-btn
                        >
                      </div>
                    </template>
                  </v-card-text>
                  <transition name="fade">
                    <PropertyOnboarding
                      :propertyId="propertyId"
                      :property="property"
                      v-on:success="loadStatus"
                      v-if="startOnboarding"
                      class="ml-1"
                    ></PropertyOnboarding>
                  </transition>
                </v-card>

                <v-card class="z-card primary--border mb-4" v-if="dwStatus && dwStatus!='unverified'">
                  <v-card-text class="pa-3">
                    <v-layout align-center justify-start row class="mb-4">
                      <img
                        src="@/assets/icon-onboarding.svg"
                        width="50"
                        height="50"
                        class="mr-4"
                      />
                      <div
                        class="primary--text font-weight-bold mb-1 text-uppercase text-xs-left"
                      >
                        Property Onboarding
                      </div>
                      <v-spacer></v-spacer>
                    </v-layout>
                    <div v-if="dwStatus=='verified'">
                      <h4 class="green--text font-weight-bold">Verified</h4>
                      <div class="grey--text body-1 mb-1">
                        The identifying information submitted was sufficient in verifying the account.
                      </div>                     
                    </div>  
                    <div v-else-if="dwStatus=='certify'">
                      <h4 class="grey--text font-weight-bold">Certifying Beneficial Ownership</h4>
                      <div class="pt-1 grey--text body-1 mb-1">
                        In order to complete property onboarding must certify beneficial ownership as imposed by the United States Federal customer due diligence rule and Zinc.
                      </div>
                      <div class="pt-1 grey--text body-1 mb-1">                      
                        I, <b>{{ currentUser.fullName }}</b> (as Accountant Administrator of property <b>{{ property.name }}</b>), hereby certify, to the best of my knowledge, that the information provided for this property is complete and correct.
                      </div>

                      <div class="text-xs-right">
                        <v-btn round color="primary" @click="submitCertify()"
                          >Submit</v-btn
                        >
                      </div>                    
                    </div>   

                    <div v-else-if="dwStatus=='suspended'">
                      <h4 class="red--text font-weight-bold">Suspended</h4>
                      <div class="grey--text body-1 mb-1">
                        This account has been suspended, please contact us.
                      </div>
                    </div>      
                    <div v-else-if="dwStatus=='retry' || dwStatus=='document'">
                      <h4 class="red--text font-weight-bold">Verfication failed</h4>
                      <div class="grey--text body-1 mb-1">
                        The initial identity verification attempt failed because the information provided did not satisfy verification check.
                      </div>
                      <div class="grey--text body-1 mb-1">
                        Please review it and submit valid identity documentation
                        <v-btn round color="primary" outline
                          @click="dwStatus = 'unverified'"
                          >Submit</v-btn
                        >
                      </div>
                    </div>     

                    

                    <div v-else>
                      <h4 class="orange--text font-weight-bold">Pending</h4>
                      <div class="grey--text body-1 mb-1">
                        The documents you submitted are waiting to be approved.
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <v-flex xs12 mb-4 v-if="dwStatus=='verified' && !property.setupPayed">
                  <v-card class="z-card primary--border">
                    <v-card-text class="pa-3">
                      <div class="primary--text mb-1">{{ totalAmount | currency }} Property Setup Fee</div>
                      <div class="grey--text body-1 mb-1">
                        Accountant admin need to make payment in order to give
                        property access to bank accounts setup.
                      </div>
                      <div class="text-xs-right">
                        <v-btn round color="primary" @click="paymentMethod = 'ach'"
                          >Pay Setup Fee Now</v-btn
                        >
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 sm12 mb-4 v-if="dwStatus=='verified' && banks.length">
                  <v-layout row>
                    <v-flex py-3>
                      <h3 class="text-uppercase grey--text text--lighten-1 mb-3">
                        Bank Accounts 
                      </h3>
                    </v-flex>
                    <v-flex py-3 text-xs-right grey--text>
                      {{ banks.length }} / {{ property.maxBanks }}
                    </v-flex>
                  </v-layout>
                  <v-card class="z-card">
                    <v-card-text class="px-2 py-1">
                      <v-list two-line class="transparent">
                        <template v-for="(item, index) in banks">
                          <v-divider
                            :key="'d' + item.id"
                            v-if="index > 0"
                          ></v-divider>
                          <v-list-tile :key="item.id" @click="$router.push('/accountant/properties/' + propertyId + '/banks/'+item.id)">
                            <v-list-tile-content>
                              <v-list-tile-title class="font-weight-bold">
                                {{ item.alias }}
                              </v-list-tile-title>
                              <v-list-tile-sub-title
                                >Bank Account Alias</v-list-tile-sub-title
                              >
                            </v-list-tile-content>
                            <v-list-tile-content>
                              <v-list-tile-title class="font-weight-bold">
                                {{ item.last4 }}
                              </v-list-tile-title>
                              <v-list-tile-sub-title
                                >4 last digits</v-list-tile-sub-title
                              >
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <v-btn no-ripple small icon :ripple="false" disabled>
                                <v-icon size="32" class="grey--text"
                                  >chevron_right</v-icon
                                >
                              </v-btn>
                            </v-list-tile-action>
                          </v-list-tile>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-flex>
              
              <v-layout
                align-center
                justify-center
                pb-3
                text-xs-center
                v-if="dwStatus=='verified' && property.setupPayed && banks.length < property.maxBanks && property.status!='suspended'"
              >
                <v-spacer></v-spacer>

                <div>
                  <v-btn
                    :to="'/accountant/properties/' + propertyId + '/bank'"
                    depressed
                    large
                    color="primary"
                    class="text-uppercase rounded mb-2 white--text"
                  >
                    <img src="@/assets/add-bank.svg" height="64"/>
                  </v-btn>
                  <div class="body-1">Add Bank Account</div>
                </div>
                <v-spacer></v-spacer>
                <!--div>
                  <v-btn
                    :to="'/accountant/properties/' + propertyId + '/activity'"
                    depressed
                    large
                    color="primary"
                    class="text-uppercase rounded mb-2 white--text"
                  >
                    <img src="@/assets/email-receipt.svg" />
                  </v-btn>
                  <div class="body-1">Recent Activity</div>
                </div>
                <v-spacer></v-spacer-->
              </v-layout>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '@/components/Lettericon'
import Alerts from '@/mixin/Alerts'
import PropertyOnboarding from '@/components/onboarding/PropertyOnboarding'
import BankSetup from '@/components/checkout/BankSetup'
import PayAch from '@/components/checkout/PayAch'

export default {
  title: 'Property Details',
  data() {
    return {
      dwStatus: null,
      startOnboarding: false,
      isLoading: true,
      isMembersLoading: false,
      propertyId: null,
      managers: [],
      banks: [],
      property: {
        name: null,
        tevPropertyId: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
        },
        status: null,
        organizationId: null,
        organizationName: null,
        organizationStatus: null,
      },

      application: null,
      paySteps: ['fee'],
      currentPayStep: 'fee',
      totalAmount: null,
      paymentMethod: null,
      variableSetup: null,
      bankSetup: false,      
    }
  },
  mixins: [Alerts],
  components: {
    Lettericon,
    PropertyOnboarding,
    BankSetup,
    PayAch,    
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  methods: {
    goBack() {
      if (this.paymentMethod == 'ach' && this.bankSetup) {
        this.bankSetup = null
      } else if (this.paymentMethod) {
        this.paymentMethod = null
      } else {
        this.$router.push('/accountant/properties')        
      }
    },

    assignManager() {
      this.$router.push('/accountant/properties/' + this.propertyId + '/invite')
    },
    loadBalance() {
      this.$http
        .get('/accountant/properties/' + this.propertyId + '/balance')
        .then(res => {
          this.$log.info(res)
        })
        .catch(err => {
          this.$log.error(err)
        })
    },
    loadStatus() {
      this.$http
        .get('/accountant/properties/' + this.propertyId + '/dwstatus')
        .then(res => {
          if (res.data.certify){
            this.dwStatus = 'certify'
          }
          else {
            this.dwStatus = res.data.status
          }
          this.isLoading = false
        })
        .catch(err => {
          this.$log.error(err)
        })
    },    
    loadProperty() {
      this.$http
        .get('/accountant/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
          this.totalAmount = Number(this.property.setupPrice)

        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/accountant/properties')
        })
    },
    loadManagers() {
      this.$http
        .get('/accountant/properties/' + this.propertyId + '/managers')
        .then(response => {
          this.managers = response.data.managers      
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
    },
    loadBanks() {
      this.$http
        .get('/accountant/properties/' + this.propertyId + '/banks')
        .then(response => {
          this.banks = response.data.banks
        })
        .catch(err => {
          this.$log.error(err)
        })
    },
    submitCertify(){
      this.$http
        .get('/accountant/properties/' + this.propertyId + '/certify')
        .then(res => {
          if (res.data.status==='certified'){
            this.dwStatus = 'verified'
          }
        })
        .catch(err => {
          this.$log.error(err)
        })
    },
    bankSetupSuccess(data) {
      this.bankSetup = data
    },
    paySuccess() {
      const _this = this
      this.success = true
      setTimeout(function() {
        if (_this.paySteps.length) {
          _this.paying = false
          _this.paymentMethod = null
          //_this.currentPayStep = _this.paySteps.shift()
          _this.success = false
        } else {
          _this.$router.push('/applicant/payments')
        }
      }, 7000)
    },    
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/accountant/properties')
    }
    this.loadProperty()
    this.loadManagers()
    this.loadBanks()
    this.loadStatus()
    //this.loadBalance()
  },
}
</script>

<style scoped>
.primary--border {
  border-color: #2896f3;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

</style>
