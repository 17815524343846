<template>
  <div>
    <div class="grey--text text--darken-1">{{ label }}</div>
    <file-pond
      :name="uniqueId"
      :ref="uniqueId"
      class="v-filepond__selections"
      :allowFileSizeValidation="true"
      :label-idle="labelIdle"
      :maxFileSize="maxFileSize"
      :imagePreviewMaxFileSize="imagePreviewMaxFileSize"
      :imagePreviewHeight="imagePreviewHeight"
      :accepted-file-types="acceptedFileTypes"
      :allow-multiple="allowMultiple"
      :allowImageTransform="allowImageTransform"
      :allowFileEncode="allowFileEncode"
      @addfile="updateFiles"
      @removefile="eventRemove"
      @error="eventError"
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize
)

export default {
  name: 'FileUpload',
  props: {
    value: Array,
    label: String,
    labelIdle: {
      type: String,
      default:
        'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
    },
    maxFileSize: {
      type: String,
      default: '2MB',
    },
    imagePreviewMaxFileSize: {
      type: String,
      default: '1MB',
    },
    imagePreviewHeight: {
      type: Number,
      default: null,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    allowImageTransform: {
      type: Boolean,
      default: false,
    },
    acceptedFileTypes: {
      type: [String, Array],
      default: () => ['image/png', 'image/jpeg', 'application/pdf'],
    },
    allowFileEncode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uniqueId: '',
    }
  },
  components: {
    FilePond,
  },
  created() {
    this.uniqueId = 'file-upload-' + this.generateId()
  },
  methods: {
    generateId() {
      return Math.random()
        .toString(36)
        .substr(2, 8)
    },
    eventError(err) {
      this.$log.error(err)
    },
    eventRemove() {
      this.$emit('input', null)
    },
    updateFiles(err) {
      if (!err) {
        let files = this.$refs[this.uniqueId].getFiles()
        let base64 = files[0].getFileEncodeBase64String()
        this.$emit('input', base64)
      }
    },
  },
}
</script>
<style>
.v-filepond.v-filepond--enclosed {
  margin: 0;
  padding: 0;
}

.v-filepond__slot .v-label {
  -webkit-transform: translateY(-16px) scale(0.75);
  transform: translateY(-16px) scale(0.75);
}

.v-filepond__slot {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  width: 100%;
  padding: 0 12px;
}

.v-application--is-ltr .v-filepond .v-label {
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.v-filepond__slot .filepond--panel-root {
  background-color: transparent;
}

.v-filepond__selections {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
}

.filepond--root {
  width: 100%;
}
</style>
