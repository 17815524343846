<template>
  <v-app class="page-members">
    <tev-navbar title="Email Templates" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <v-container>
            <v-flex xs12 mb-3>
              <v-btn
                color="light-green"
                dark
                depressed
                large
                class="text-uppercase"
                to="/admin/email-templates/new"
              >New Email Template</v-btn>
            </v-flex>

            <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
            <v-list two-line subheader class="transparent mb-4" v-if="!isLoading">
              <v-divider></v-divider>

              <v-list-tile
                v-for="item in emailTemplates"
                :key="item.title"
                :to="'/admin/email-templates/'+item.id"
              >
                <v-list-tile-action>
                  <v-chip>{{ item.lang | uppercase }}</v-chip>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                  <v-list-tile-sub-title></v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-chip>{{ item.slug }}</v-chip>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-btn small icon ripple>
                    <v-icon class="grey--text">chevron_right</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
            <div class="text-xs-center mb-5">
              <div
                v-if="totalResults"
              >Results {{ currentPage * perPage - perPage + 1 }} - {{ Math.min(currentPage * perPage, totalResults) }} of {{ totalResults }}</div>
              <v-pagination
                v-model="currentPage"
                @input="changePage"
                :length="totalPages"
                :total-visible="6"
                circle
                v-if="totalPages>1"
              ></v-pagination>
            </div>
          </v-container>
        </v-flex>
      </v-layout>
      <v-fab-transition>
        <v-btn
          color="light-green"
          dark
          fixed
          bottom
          right
          depressed
          fab
          to="/admin/email-templates/new"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Email Templates',
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 1000,
      totalPages: null,
      totalResults: null,
      emailTemplates: [],
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    skeletonRows() {
      return !this.members.length ? this.perPage : this.members.length
    },
  },
  watch: {
    currentPage() {
      //this.loadMembers()
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page
      this.loadMembers()
    },
    newSearch() {
      this.currentPage = 1
      this.loadMembers()
    },
    loadData() {
      // offline
      if (!this.isOnline) {
        return this.toastError('You are offline!')
      }

      this.isLoading = true
      this.$http
        .get('/admin/email-templates', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            name: this.name,
          },
        })
        .then(response => {
          if (response && response.data) {
            this.emailTemplates = response.data.emailTemplates
            this.totalResults = response.data.metadata.total
            this.totalPages = Math.ceil(this.totalResults / this.perPage)
            this.$scrollTo('#app')
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
<style>
.page-members .v-content .v-list--two-line .v-list__tile {
  border-bottom: 1px solid #cfd8dc;
}

.split .theme--light.v-list .v-list__tile--link:hover {
  background: rgba(144, 202, 249, 0.1);
}
</style>
