var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("my_property") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "transparent elevation-0",
                      attrs: { width: "600", "max-width": "100%" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-start": "",
                            "justify-start": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "light-green--text",
                              attrs: {
                                icon: "",
                                "active-class": "none",
                                to: "/applicant/properties"
                              }
                            },
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                "py-3": "",
                                "text-xs-right": "",
                                "grey--text": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm._v(" "),
                                  _vm.propertyId
                                    ? [
                                        _vm._v(
                                          _vm._s(_vm.$t("property_id")) +
                                            ": " +
                                            _vm._s(_vm.property.tevPropertyId)
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c("z-status-chip", {
                                attrs: {
                                  status: _vm.property.status,
                                  label: _vm.$t(_vm.property.status)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-uppercase grey--text text--lighten-1 mb-3"
                        },
                        [_vm._v(_vm._s(_vm.$t("property_info")))]
                      ),
                      _c(
                        "v-card",
                        { staticClass: "z-card mb-4" },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "transparent pa-0",
                                  attrs: { "two-line": "" }
                                },
                                [
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _c("strong", [
                                              _vm._v(_vm._s(_vm.property.name))
                                            ])
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("property_name")
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.property.address.line1
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("address")) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.property.address.city
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("city")) + " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.property.address.state
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("state")) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-tile-action")
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.property.address
                                                    .postalCode
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("zip_code")) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-uppercase grey--text text--lighten-1 mb-3"
                        },
                        [_vm._v("Move Ins")]
                      ),
                      _vm._l(_vm.property.applications, function(item) {
                        return _c("ZApplication", {
                          key: item.id,
                          attrs: {
                            application: item,
                            to: "/applicant/applications/" + item.id
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }