<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar :title="$t('transactions')" />
    <tev-subnavbar :title="$t('current_transactions_list')" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm12 class="mb-5 text-xs-center">
          <v-list two-line class="transparent mb-4">
            <template v-for="item in transactions">
              <v-list-tile :key="item.id" avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.fullName }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{
                    item.property_name
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <div
                    class="tx-status"
                    :class="{ 'tx-status-pending': item.status == 'Pending' }"
                  >
                    {{ $t(item.status.toLowerCase()) }}
                  </div>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="item.id + '_divider'"></v-divider>
            </template>
          </v-list>
          <!--ul class="transaction-list">
                  <li :class="'a-transaction d-flex justify-content-between tx-'+transaction.status" v-for="transaction in transactions">
                    <div class="transaction-info">
                      <div class="transaction-indicator"></div>
                      <ul>
                        <li class="transaction-user-name">{{ transaction.fullName }}</li>
                        <li class="user-phone">Tel: {{ transaction.cellPhone }}</li>
                        <li class="user-email">Email : {{ transaction.email }}</li>
                        <li class="application-number d-block d-lg-none">Application No: {{ transaction.application_id }}</li>
                      </ul>
                    </div>
                    <div class="transaction-info">
                      <ul>
                        <li class="transaction-property"><strong>Property:</strong> {{ transaction.property_name }}</li>
                      </ul>
                    </div>
                    <div class="transaction-link d-flex flex-column">
                      <div class="more-wrapper d-flex justify-content-end flex-column flex-lg-row align-items-end align-items-lg-center">
                        <span class="badge mb-4 mb-lg-0 mr-0 mr-lg-auto" v-if="transaction.status=='Out'">Out</span>
                        <span class="badge mb-4 mb-lg-0 mr-0 mr-lg-auto red" v-else>In</span>
                        <b-link :to="'/manager/transactions/'+transaction.id" class="btn-more" v-b-tooltip.hover title="Read More"><span class="sr-only">More</span></b-link>
                      </div>
                      <span class="application-number d-none d-lg-block mt-auto">Application No: {{ transaction.application_id }}</span>
                    </div>
                  </li>
          </ul-->
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      showLoading: false,
      currentPage: 1,
      perPage: 10,
      totalResults: null,
      onlyRed: false,
      onlyGreen: false,
      searchProperty: null,
      dropdownProperties: [],
      searchStartDate: null,
      searchEndDate: null,
      applicationId: null,
      dropdownSmartFormTypes: [
        {
          text: 'One Time Direct Debit Form',
          value: 'onetime',
        },
        {
          text: 'Recurring Direct Debit Form',
          value: 'recurring',
        },
        {
          text: 'Special Assessments Direct Debit Form',
          value: 'special',
        },
        {
          text: 'Miscellaneus Direct Debit Form',
          value: 'miscellaneus',
        },
      ],
      dropdownFormat: [
        {
          text: 'CSV',
          value: 'csv',
        },
        {
          text: 'Excel',
          value: 'xls',
        },
      ],
      exportProperties: null,
      exportSmartFormType: null,
      exportFormat: null,

      transactions: [
        {
          id: 1,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Completed',
        },
        {
          id: 2,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
        {
          id: 3,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Completed',
        },
        {
          id: 4,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
        {
          id: 5,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
        {
          id: 6,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Completed',
        },
        {
          id: 7,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (!this.currentUser || !localStorage.token) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
    onSearchProperties(search, loading) {
      const _this = this
      if (search.length < 3) {
        //return
      }
      loading(true)
      this.$http
        .post('/manager/typeProperties', {
          name: search,
        })
        .then(response => {
          loading(false)
          if (response.data.length) {
            _this.dropdownProperties = response.data
          }
        })
        .catch(e => {
          loading(false)
          this.$log.error(e)
        })
    },
    changeExportProperties(data, text) {
      this.exportProperties = text
    },
    alertLoading() {
      /*
        this.$refs.simplert.openSimplert({
          message: `<div class="text-center">
                <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
              </div>`+ message,
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-submit red',
          type: 'error'
        })
        */
    },
    alertError() {
      /*
        this.$refs.simplert.openSimplert({
          message: message,
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-submit red',
          type: 'error'
        })
        */
    },
    searchPropertiesSubmit() {
      let startDate = null
      let endDate = null

      if (this.searchStartDate) {
        startDate = this.searchStartDate.toISOString().split('T')[0]
      }

      if (this.searchEndDate) {
        endDate = this.searchEndDate.toISOString().split('T')[0]
      }

      if (startDate && endDate) {
        if (this.searchEndDate <= this.searchStartDate) {
          return this.alertError(
            'The End date must be greater than the Start date.'
          )
        }
      }

      this.newSearch()
    },
    exportData() {
      this.$refs.modalExport.hide()
      //this.alertLoading('Exporting...')
    },
    showExportModal() {
      this.$refs.modalExport.show()
    },
    toggleRed() {
      this.onlyRed = !this.onlyRed
      this.onlyGreen = false
      this.newSearch()
    },
    toggleGreen() {
      this.onlyGreen = !this.onlyGreen
      this.onlyRed = false
      this.newSearch()
    },
    changePage(page) {
      this.$scrollTo('#main')
      this.currentPage = page
      this.loadTransactions()
    },
    newSearch() {
      this.currentPage = 1
      this.loadTransactions()
    },
    loadTransactions() {
      const _this = this
      _this.showLoading = true
      this.$http
        .post('/manager/listTransactions', {
          property_id: this.searchProperty ? this.searchProperty.id : null,
          start_date: this.searchStartDate,
          end_date: this.searchEndDate,
          application_id: this.applicationId,
          status: this.onlyRed ? 'red' : this.onlyGreen ? 'green' : 'all',
        })
        .then(response => {
          _this.showLoading = false
          _this.transactions = response.data.results
          //this.currentPage = response.data.page
          _this.perPage = response.data.per_page
          _this.totalResults = response.data.total_results
        })
        .catch(e => {
          this.$log.error(e)
          _this.showLoading = false
        })
    },
  },
  mounted() {
    //this.loadTransactions()
  },
}
</script>
<style></style>
