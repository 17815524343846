<template>
  <v-app class="page-member">
    <tev-navbar :title="$t('members')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm12 mb-4>
            <v-layout align-start justify-space-between row fill-height>
              <v-btn icon @click="isInvite=false" class="light-green--text">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-layout xs12 align-center justify-center row wrap fill-height pt-4>
                <v-btn outline icon large color="primary">
                  <v-avatar :size="16">
                    <img src="../../assets/edit.svg" />
                  </v-avatar>
                </v-btn>
                <Lettericon name="Michelle Hendley" size="medium" class="ma-3"></Lettericon>
                <v-btn outline icon large color="primary">
                  <v-avatar :size="16">
                    <img src="../../assets/delete.svg" />
                  </v-avatar>
                </v-btn>
              </v-layout>
              <z-spacer :width="52" />
            </v-layout>
          </v-flex>
          <v-layout xs12 align-center justify-center text-xs-center row wrap mb-4>
            <v-flex xs12 title font-weight-regular>{{ fullName }}</v-flex>
            <v-flex xs12 body-1>Manager Assistant</v-flex>
          </v-layout>
          <v-flex xs12 sm12 mx-4>
            <v-list two-line class="transparent">
              <v-list-tile avatar>
                <v-list-tile-avatar size="16">
                  <img src="../../assets/ico-user.svg" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ fullName }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('name') }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar size="16">
                  <img src="../../assets/ico-phone.svg" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ phone }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('phone') }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar size="16">
                  <img src="../../assets/ico-email.svg" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ email }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('email') }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile avatar>
                <v-list-tile-avatar size="16">
                  <img src="../../assets/ico-org.svg" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ organization }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('organization') }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>

              <!--v-list-tile avatar>
                <v-list-tile-avatar size="16">
                  <img src="../assets/ico-home.svg">
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ organization }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('Properties') }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider-->

              <v-list-tile avatar>
                <v-list-tile-avatar size="16">
                  <img src="../../assets/ico-lock.svg" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>***********</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('password') }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn icon ripple color="primary" outline class="border-0">
                    <img src="../../assets/edit.svg" />
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '../../components/Lettericon'

export default {
  data() {
    return {
      fullName: 'Michelle Hendley',
      phone: '305.000.0000',
      email: 'michelle@mail.com',
      organization: 'Tenant Evaluation',
    }
  },
  components: {
    Lettericon,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (!this.currentUser || !localStorage.token) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
  },
  mounted() {},
}
</script>
<style>
.page-member .v-list__tile__avatar {
  min-width: 44px;
}

.page-member .v-avatar img {
  border-radius: 0;
}
</style>