var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-organizations" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("organizations") } }),
      _c(
        "v-content",
        [
          _vm.organization
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "pt-4": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "", md9: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.inviteRepresentative($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "align-start": "",
                                    "justify-space-between": "",
                                    "fill-height": "",
                                    "mb-5": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "light-green--text",
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.organization = null
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("arrow_back")])],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-xs-center pt-4" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/icon-user-green.svg"),
                                          height: "60"
                                        }
                                      }),
                                      _c("h3", { staticClass: "black--text" }, [
                                        _vm._v(_vm._s(_vm.organization.name))
                                      ])
                                    ]
                                  ),
                                  _c("z-spacer", { attrs: { width: 52 } })
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "text-xs-center": "",
                                        "mb-4": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _vm._v(
                                            "Invite Property Account Manager"
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.representatives,
                                          "item-value": "id",
                                          "item-text": "fullName",
                                          box: "",
                                          label: "Select Account Manager"
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.hasChanges = true
                                          }
                                        },
                                        model: {
                                          value: _vm.repId,
                                          callback: function($$v) {
                                            _vm.repId = $$v
                                          },
                                          expression: "repId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _vm.repId
                                        ? _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "add_commission_value"
                                              ),
                                              type: "number",
                                              box: "",
                                              autocomplete: "off",
                                              autofocus: true
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            model: {
                                              value: _vm.repCommission,
                                              callback: function($$v) {
                                                _vm.repCommission = $$v
                                              },
                                              expression: "repCommission"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.repId
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-uppercase",
                                              attrs: {
                                                loading: _vm.isLoading,
                                                color: "light-green",
                                                dark: "",
                                                depressed: "",
                                                block: "",
                                                large: ""
                                              },
                                              on: {
                                                click: _vm.inviteRepresentative
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("invite")))]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.organization
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "pt-4": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.newSearch($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "box-border",
                                        attrs: {
                                          "append-icon": "search",
                                          label: _vm.$t("search"),
                                          type: "text",
                                          autocomplete: "off",
                                          box: "",
                                          autofocus: _vm.$isDesktop()
                                        },
                                        on: { "click:append": _vm.newSearch },
                                        model: {
                                          value: _vm.name,
                                          callback: function($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.isLoading
                                ? _c(
                                    "skeleton-loading",
                                    { staticClass: "mb-4" },
                                    _vm._l(_vm.skeletonRows, function(i) {
                                      return _c(
                                        "div",
                                        { key: i },
                                        [
                                          _c(
                                            "row",
                                            {
                                              attrs: {
                                                gutter: {
                                                  top: "14px",
                                                  bottom: "12px"
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "column",
                                                {
                                                  attrs: { span: 20, gutter: 6 }
                                                },
                                                [
                                                  _c("square-skeleton", {
                                                    attrs: {
                                                      count: 1,
                                                      boxProperties: {
                                                        top: "6px",
                                                        bottom: "4px",
                                                        width: "200px",
                                                        height: "14px"
                                                      }
                                                    }
                                                  }),
                                                  _c("square-skeleton", {
                                                    attrs: {
                                                      count: 1,
                                                      boxProperties: {
                                                        bottom: "6px",
                                                        width: "120px",
                                                        height: "12px"
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("square-skeleton", {
                                            attrs: {
                                              count: 1,
                                              boxProperties: {
                                                bottom: "1px",
                                                width: "100%",
                                                height: "1px"
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _c("v-divider"),
                              !_vm.isLoading
                                ? _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent mb-4",
                                      attrs: { "two-line": "", subheader: "" }
                                    },
                                    _vm._l(_vm.organizations, function(item) {
                                      return _c(
                                        "v-list-tile",
                                        {
                                          key: item.title,
                                          attrs: { avatar: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toImport(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(_vm._s(item.name))
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.addressLine1) +
                                                    " " +
                                                    _vm._s(item.addressLine2) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    ripple: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [_vm._v("add")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "text-xs-center mb-5" },
                                [
                                  _vm.totalResults
                                    ? _c("div", [
                                        _vm._v(
                                          " Results " +
                                            _vm._s(
                                              _vm.currentPage * _vm.perPage -
                                                _vm.perPage +
                                                1
                                            ) +
                                            " - " +
                                            _vm._s(
                                              Math.min(
                                                _vm.totalResults,
                                                _vm.currentPage * _vm.perPage
                                              )
                                            ) +
                                            " of " +
                                            _vm._s(_vm.totalResults) +
                                            " organizations "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.totalPages > 1
                                    ? _c("v-pagination", {
                                        attrs: {
                                          length: _vm.totalPages,
                                          "total-visible": 6,
                                          circle: ""
                                        },
                                        on: { input: _vm.changePage },
                                        model: {
                                          value: _vm.currentPage,
                                          callback: function($$v) {
                                            _vm.currentPage = $$v
                                          },
                                          expression: "currentPage"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }