var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.property
        ? _c("tev-navbar", {
            attrs: {
              title: _vm.property.name ? "Applicants: " + _vm.property.name : ""
            }
          })
        : _vm._e(),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { x12: "", sm11: "" } }, [
                    _vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "text-xs-center" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 50,
                                color: "primary",
                                indeterminate: ""
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm11: "" } },
                    [
                      _vm.property && !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "align-start": "",
                                "justify-start": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goBack()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "py-3": "",
                                    "text-xs-right": "",
                                    "grey--text": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" "),
                                      _vm.property
                                        ? [
                                            _vm._v(
                                              _vm._s(_vm.$t("property_id")) +
                                                ": " +
                                                _vm._s(
                                                  _vm.property.tevPropertyId
                                                )
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("z-status-chip", {
                                    attrs: {
                                      status: _vm.property.status,
                                      label: _vm.$t(_vm.property.status)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  !_vm.isLoading && !_vm.applications.length
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "pink--text accent-3",
                          attrs: { x12: "", sm11: "" }
                        },
                        [
                          _vm._v(
                            "The property has no applications in Zinc yet."
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm11: "" } },
                    [
                      !_vm.isLoading && _vm.applications
                        ? _c("z-table-applications", {
                            attrs: {
                              items: _vm.applications,
                              propertyId: _vm.propertyId
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }