<template>
  <v-app class="page-invite">
    <tev-navbar />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm12 md9>
          <v-form ref="form" lazy-validation @submit.prevent="inviteMember">
            <v-container>
              <v-layout align-start justify-space-between fill-height mb-5>
                <v-btn icon class="light-green--text" :to="'/accountant/properties/'+propertyId">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <div class="text-xs-center pt-4">
                  <img src="@/assets/icon-user-green.svg" height="60" />

                  <template v-if="!invitationSent">
                    <h3 class="black--text">{{ property.name }}</h3>
                    <p class="font-weight-regular mb-4">{{ $t('property_name') }}</p>
                    <h2 class="font-weight-regular">Invite Property Manager to Zinc</h2>
                  </template>
                </div>
                <z-spacer :width="52" />
              </v-layout>

              <v-layout row wrap v-if="invitationSent">
                <v-flex xs12 sm8 text-xs-center subheading mb-5>
                  <h3
                    class="font-weight-regular"
                  >The invitation has been sent. New members will get an email that gives them access to Zinc.</h3>
                </v-flex>
                <v-flex xs12 sm8 text-xs-center>
                  <v-btn
                    color="light-green"
                    dark
                    depressed
                    large
                    class="text-uppercase mb-4"
                    :to="'/accountant/properties/'+propertyId"
                  >{{ $t('continue') }}</v-btn>
                </v-flex>
              </v-layout>

              <v-layout row wrap v-if="!invitationSent">
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="firstName"
                    :label="$t('first_name')"
                    type="text"
                    box
                    :autofocus="$isDesktop()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field v-model="lastName" :label="$t('last_name')" type="text" box></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="cellPhone"
                    :label="$t('cell_phone')"
                    type="number"
                    class="z-callingcode-field"
                    box
                  >
                    <template v-slot:prepend>
                      <z-callingcode-field
                        :enabledCountryCode="true"
                        :preferredCountries="[]"
                        v-model="callingCode"
                        :label="$t('dialcode')"
                      ></z-callingcode-field>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field v-model="email" :label="$t('email')" type="email" box></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-btn
                    :loading="isLoading"
                    color="light-green"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                    @click="inviteMember"
                  >{{ $t('invite') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import ZCallingCodeField from '@/components/ZCallingCodeField'
import Alerts from '@/mixin/Alerts'

export default {
  title: window.vm.$t('invite_new_members'),
  data() {
    return {
      invitationSent: false,
      isLoading: false,
      firstName: null,
      lastName: null,
      email: null,
      callingCode: null,
      cellPhone: null,
      propertyId: null,
      property: {
        name: null,
      },
    }
  },
  mixins: [Alerts],
  components: {
    'z-callingcode-field': ZCallingCodeField,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    inviteMember() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.callingCode.dialCode ||
        !this.cellPhone
      ) {
        return this.toastError(this.$t('complete_required'))
      }

      this.isLoading = true
      this.$http
        .post('/accountant/properties/' + this.propertyId + '/managers', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          dialCode: this.callingCode.dialCode,
          cellPhone: this.cellPhone,
        })
        .then(() => {
          this.hasChanges = false
          this.invitationSent = true
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/accountant/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/accountant/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/accountant/properties')
    }
    this.loadProperty()
  },
}
</script>
