import { render, staticRenderFns } from "./NotificationsView.vue?vue&type=template&id=2a4a34d7&"
import script from "./NotificationsView.vue?vue&type=script&lang=js&"
export * from "./NotificationsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VApp,VContent,VFlex,VLayout,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src266546640/src/zinc-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a4a34d7')) {
      api.createRecord('2a4a34d7', component.options)
    } else {
      api.reload('2a4a34d7', component.options)
    }
    module.hot.accept("./NotificationsView.vue?vue&type=template&id=2a4a34d7&", function () {
      api.rerender('2a4a34d7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/account/NotificationsView.vue"
export default component.exports