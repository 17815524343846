import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import { ACCOUNT_TYPES } from '../libs/constants'
import { locales } from '../locales'

// SetLang
import SetLang from '../mixin/SetLang'
Vue.mixin(SetLang)

const lPath = '/:lang(' + locales.join('|') + ')?'

function route(path, view, beforeEnter) {
  return {
    path: lPath + path,
    name: path,
    component: resolve => import(`../pages/${view}View.vue`).then(resolve),
    beforeEnter: beforeEnter,
  }
}

const roles = {}
ACCOUNT_TYPES.forEach(type => {
  const fn = 'is' + type[0].toUpperCase() + type.slice(1)
  roles[fn] = (to, from, next) => {
    if (!store.getters.currentUser[fn]) {
      next('/login?redirect=' + to.path)
      return
    }
    next()
  }
})

const isLoggedIn = (to, from, next) => {
  if (!store.getters.currentUser.isLoggedIn) {
    next('/login?redirect=' + to.path)
    return
  }
  next()
}

Vue.use(Router)

// UUID Regex
const uuidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: [
    route('/', 'Home'),
    route('/login', 'Login'),
    route('/logout', 'Logout'),
    route('/forgot', 'Forgot'),
    route('/reset', 'Reset'),
    route('/activate/:key', 'Reset'),
    route('/change-password/:key', 'Reset'),
    route('/register', 'Register'),

    //
    // Account
    //
    route('/account', 'account/Account', isLoggedIn),
    route('/account/preferences', 'account/Preferences', isLoggedIn),
    route('/account/notifications', 'account/Notifications', isLoggedIn),
    route('/account/login-history', 'account/LoginHistory', isLoggedIn),
    route('/account/password', 'account/Password', isLoggedIn),
    route('/account/phone', 'account/Phone', isLoggedIn),    

    //
    // Admin
    //
    route('/admin', 'admin/Organizations', roles['isAdmin']),
    route('/admin/organizations', 'admin/Organizations', roles['isAdmin']),
    route(
      '/admin/organizations/:organizationId(' + uuidRegex + ')',
      'admin/Organization',
      roles['isAdmin']
    ),
    route(
      '/admin/organizations/import',
      'admin/ImportOrganizations',
      roles['isAdmin']
    ),

    route('/admin/members', 'admin/Members', roles['isAdmin']),
    route(
      '/admin/members/:memberId(' + uuidRegex + ')',
      'admin/Member',
      roles['isAdmin']
    ),
    route('/admin/invite', 'admin/Invite', roles['isAdmin']),

    route('/admin/transactions', 'admin/Transactions', roles['isAdmin']),

    route('/admin/login-history', 'admin/LoginHistory', roles['isAdmin']),
    route('/admin/reports', 'admin/Reports', roles['isAdmin']),
    route('/admin/email-templates', 'admin/EmailTemplates', roles['isAdmin']),
    route(
      '/admin/email-templates/new',
      'admin/EmailTemplate',
      roles['isAdmin']
    ),
    route(
      '/admin/email-templates/:tplId',
      'admin/EmailTemplate',
      roles['isAdmin']
    ),
    route('/admin/mailing', 'admin/Mailing', roles['isAdmin']),

    route(
      '/admin/properties/:propertyId(' + uuidRegex + ')',
      'admin/Property',
      roles['isAdmin']
    ),

    //
    // Representative
    //
    route(
      '/representative',
      'representative/Organizations',
      roles['isRepresentative']
    ),
    route(
      '/representative/organizations',
      'representative/Organizations',
      roles['isRepresentative']
    ),
    route(
      '/representative/organizations/:organizationId(' + uuidRegex + ')',
      'representative/Organization',
      roles['isRepresentative']
    ),
    route(
      '/representative/organizations/:organizationId(' +
        uuidRegex +
        ')/properties',
      'representative/Properties',
      roles['isRepresentative']
    ),
    route(
      '/representative/organizations/:organizationId(' +
        uuidRegex +
        ')/properties/import',
      'representative/Import',
      roles['isRepresentative']
    ),

    route(
      '/representative/register',
      'representative/Register',
      roles['isRepresentative']
    ),
    route(
      '/representative/invite',
      'representative/Invite',
      roles['isRepresentative']
    ),
    route(
      '/representative/members',
      'representative/Members',
      roles['isRepresentative']
    ),
    route(
      '/representative/members/:memberId(' + uuidRegex + ')',
      'representative/Member',
      roles['isRepresentative']
    ),
    route(
      '/representative/properties/:propertyId(' + uuidRegex + ')',
      'representative/Property',
      roles['isRepresentative']
    ),
    route(
      '/representative/properties/:propertyId(' + uuidRegex + ')/activity',
      'representative/PropertyActivity',
      roles['isRepresentative']
    ),
    route(
      '/representative/properties/:propertyId(' + uuidRegex + ')/invite',
      'representative/PropertyInvite',
      roles['isRepresentative']
    ),
    route(
      '/representative/reports',
      'representative/Reports',
      roles['isRepresentative']
    ),

    route(
      '/representative/members/:memberId(' + uuidRegex + ')/invite',
      'representative/Profile',
      roles['isRepresentative']
    ),

    //
    // Accountant
    //
    route('/accountant', 'accountant/Properties', roles['isAccountant']),
    route(
      '/accountant/properties',
      'accountant/Properties',
      roles['isAccountant']
    ),
    route(
      '/accountant/properties/:propertyId(' + uuidRegex + ')',
      'accountant/Property',
      roles['isAccountant']
    ),
    route(
      '/accountant/properties/:propertyId(' + uuidRegex + ')/invite',
      'accountant/PropertyInvite',
      roles['isAccountant']
    ),
    route(
      '/accountant/properties/:propertyId(' + uuidRegex + ')/bank',
      'accountant/BankSetup',
      roles['isAccountant']
    ),
    route(
      '/accountant/properties/:propertyId(' + uuidRegex + ')/banks/:bankId(' + uuidRegex + ')',
      'accountant/Bank',
      roles['isAccountant']
    ),    
    route(
      '/accountant/properties/:propertyId(' + uuidRegex + ')/activity',
      'accountant/PropertyActivity',
      roles['isAccountant']
    ),
    route(
      '/accountant/members/:memberId(' + uuidRegex + ')',
      'accountant/Member',
      roles['isAccountant']
    ),
    route('/accountant/reports', 'accountant/Reports', roles['isAccountant']),

    //
    // Manager
    //
    route('/manager', 'manager/Properties', roles['isManager']),
    route('/manager/properties', 'manager/Properties', roles['isManager']),
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')',
      'manager/Property',
      roles['isManager']
    ),
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')/applications',
      'manager/Applications',
      roles['isManager']
    ),
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')/activity',
      'manager/PropertyActivity',
      roles['isManager']
    ),
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')/invite',
      'manager/PropertyInvite',
      roles['isManager']
    ),
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')/contracts',
      'manager/Contracts',
      roles['isManager']
    ),     
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')/contracts/new',
      'manager/NewContract',
      roles['isManager']
    ),
    route(
      '/manager/properties/:propertyId(' +
        uuidRegex +
        ')/contracts/:contractId(' +
        uuidRegex +
        ')/disable',
      'manager/DisableContract',
      roles['isManager']
    ),
    route(
      '/manager/members/:memberId(' + uuidRegex + ')',
      'manager/Member',
      roles['isManager']
    ),

    route('/manager/applications', 'manager/Applications', roles['isManager']),
    route(
      '/manager/applications/:applicationId(' + uuidRegex + ')',
      'manager/Application',
      roles['isManager']
    ),

    route(
      '/manager/transactions/:propertyId(' + uuidRegex + ')',
      'manager/Transactions',
      roles['isManager']
    ),
    route(
      '/manager/properties/:propertyId(' + uuidRegex + ')/banks/:bankId(' + uuidRegex + ')',
      'manager/Bank',
      roles['isManager']
    ),    

    route('/manager/register2', 'manager/Register2', roles['isManager']),
    route('/manager/reports', 'manager/Reports', roles['isManager']),

    //
    // Assistant
    //
    route('/assistant', 'manager/Properties', roles['isAssistant']),
    route('/assistant/properties', 'manager/Properties', roles['isAssistant']),
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')',
      'manager/Property',
      roles['isAssistant']
    ),
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')/applications',
      'manager/Applications',
      roles['isAssistant']
    ),
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')/activity',
      'manager/PropertyActivity',
      roles['isAssistant']
    ),
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')/invite',
      'manager/PropertyInvite',
      roles['isAssistant']
    ),
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')/contracts',
      'manager/Contracts',
      roles['isAssistant']
    ),    
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')/contracts/new',
      'manager/NewContract',
      roles['isAssistant']
    ),
    route(
      '/assistant/properties/:propertyId(' +
        uuidRegex +
        ')/contracts/:contractId(' +
        uuidRegex +
        ')/disable',
      'manager/DisableContract',
      roles['isAssistant']
    ),
    route(
      '/assistant/members/:memberId(' + uuidRegex + ')',
      'manager/Member',
      roles['isAssistant']
    ),

    route('/assistant/applications', 'manager/Applications', roles['isAssistant']),
    route(
      '/assistant/applications/:applicationId(' + uuidRegex + ')',
      'manager/Application',
      roles['isAssistant']
    ),

    route(
      '/assistant/transactions/:propertyId(' + uuidRegex + ')',
      'manager/Transactions',
      roles['isAssistant']
    ),
    route(
      '/assistant/properties/:propertyId(' + uuidRegex + ')/banks/:bankId(' + uuidRegex + ')',
      'manager/Bank',
      roles['isAssistant']
    ),    

    route('/assistant/register2', 'manager/Register2', roles['isAssistant']),
    route('/assistant/reports', 'manager/Reports', roles['isAssistant']),


    // Property
    route('/property', 'property/Property', roles['isProperty']),
    route('/property/members', 'property/Member', roles['isProperty']),

    route(
      '/property/applications',
      'property/Applications',
      roles['isProperty']
    ),
    route('/property/banks', 'property/Banks', roles['isProperty']),
    route('/property/reports', 'property/Reports', roles['isProperty']),

    // TenantEv
    route('/tenantev', 'tenantev/Applications', roles['isTenantev']),
    route(
      '/tenantev/applications',
      'tenantev/Applications',
      roles['isTenantev']
    ),
    route('/tenantev/reports', 'tenantev/Reports', roles['isTenantev']),

    // Applicant
    route('/applicant', 'applicant/Home', roles['isApplicant']),
    route('/applicant/home', 'applicant/Home', roles['isApplicant']),
    route('/applicant/payments', 'applicant/Payments', roles['isApplicant']),
    route(
      '/applicant/payments/:paymentId(' + uuidRegex + ')',
      'applicant/Payment',
      roles['isApplicant']
    ),
    //route('/applicant/get-loan', 'applicant/GetLoan'),
    route(
      '/applicant/properties',
      'applicant/Properties',
      roles['isApplicant']
    ),
    route(
      '/applicant/properties/:propertyId(' + uuidRegex + ')',
      'applicant/Property',
      roles['isApplicant']
    ),
    route(
      '/applicant/applications/:applicationId(' + uuidRegex + ')',
      'applicant/Application',
      roles['isApplicant']
    ),

    route('/applicant/reports', 'applicant/Reports', roles['isApplicant']),

    route('/application/:applicationCode([0-9a-fA-Z]+)', 'applicant/ToPay'),

    // Support
    //route('/support', 'support/Properties'),

    //
    // CSS
    //
    route('/tmp/transaction', 'tmp/Transaction'),
    route('/tmp/transfer', 'tmp/Transfer'),
    route('/tmp/profile', 'tmp/Profile'),

    route('/tmp/member', 'tmp/Member'),
    route('/tmp/transactions2', 'tmp/Member'),

    // Global redirect for 404
    {
      path: '*',
      redirect: '/',
    },
  ],
})
