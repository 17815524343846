var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "z-card pt-3 pb-0",
          attrs: { "max-width": "600", width: "100%" }
        },
        [
          _vm._l(_vm.application.payments, function(item) {
            return [
              item.contractType == _vm.contractType || _vm.contractType == "all"
                ? _c(
                    "div",
                    { key: item.id },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "ma-3": "" } },
                        [
                          _c("v-flex", [
                            _c(
                              "div",
                              [
                                _c("strong", [_vm._v(_vm._s(item.feeName))]),
                                item.feeRateType == "variable"
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-width": 200,
                                          "offset-x": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "v-btn--very-small",
                                                        attrs: {
                                                          color: "primary",
                                                          icon: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "14" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "priority_high"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.popover,
                                          callback: function($$v) {
                                            _vm.popover = $$v
                                          },
                                          expression: "popover"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            attrs: {
                                              "max-width": "290",
                                              color:
                                                "grey lighten-5 body-1 grey--text text--darken-2"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pb-0" },
                                              [
                                                _vm._v(
                                                  " A security deposit is a deposit of money to the landlord to ensure that rent will be paid and other responsibilities of the lease performed. "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      flat: "",
                                                      depressed: "",
                                                      color: "primary",
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.popover = false
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(_vm.$t("ok")))]
                                                ),
                                                _c("v-spacer")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "grey--text body-1 mb-2" },
                              [
                                item.contractType == "deposit"
                                  ? [
                                      _vm._v(
                                        "Refund in " +
                                          _vm._s(item.refundCounter) +
                                          " " +
                                          _vm._s(item.refundStepType)
                                      )
                                    ]
                                  : [_vm._v("Description")]
                              ],
                              2
                            )
                          ]),
                          item.feeRateType == "flat"
                            ? _c("v-flex", { attrs: { "text-xs-right": "" } }, [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm._f("currency")(item.amount))
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "grey--text body-1" },
                                  [
                                    _vm.contractType == "deposit"
                                      ? [_vm._v("Amount to deposit")]
                                      : [_vm._v("Amount to pay")]
                                  ],
                                  2
                                )
                              ])
                            : _vm._e(),
                          item.feeRateType == "variable" && !_vm.setup[item.id]
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "text-xs-right": "",
                                    "font-weight-bold": ""
                                  }
                                },
                                [_vm._v("Pending")]
                              )
                            : _vm._e(),
                          item.feeRateType == "variable" && _vm.setup[item.id]
                            ? _c("v-flex", { attrs: { "text-xs-right": "" } }, [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.setup[item.id].amount
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "grey--text body-1" },
                                  [
                                    _vm.contractType == "deposit"
                                      ? [_vm._v("Amount to deposit")]
                                      : [_vm._v("Amount to pay")]
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      item.feeRateType == "variable"
                        ? _c(
                            "v-layout",
                            { attrs: { row: "", "ma-3": "", "mt-4": "" } },
                            [
                              !_vm.calculate[item.id]
                                ? _c(
                                    "v-flex",
                                    { attrs: { "text-xs-center": "" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "grey--text body-1" },
                                        [
                                          _vm._v(
                                            _vm._s(item.feeName) +
                                              " must be calculated to get final amount."
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mb-4 white--text",
                                          attrs: {
                                            depressed: "",
                                            round: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.calculateRate(item.id)
                                            }
                                          }
                                        },
                                        [_vm._v("Calculate Rate")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.calculate[item.id]
                                ? _c(
                                    "v-flex",
                                    [
                                      item.setupByType == "sqft"
                                        ? [
                                            _c("div", [
                                              _vm._v(
                                                "How many Sqft does your unit have?"
                                              )
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                "single-line": "",
                                                items: item.units,
                                                box: "",
                                                "background-color": "#F1F5F7",
                                                flat: "",
                                                label: "Select one",
                                                "append-icon":
                                                  "keyboard_arrow_down",
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.showAttention(
                                                    item.id
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          "From " +
                                                            _vm._s(
                                                              item.sqftFrom
                                                            ) +
                                                            " to " +
                                                            _vm._s(item.sqftTo)
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          "From " +
                                                            _vm._s(
                                                              item.sqftFrom
                                                            ) +
                                                            " to " +
                                                            _vm._s(item.sqftTo)
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.setup[item.id],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.setup,
                                                    item.id,
                                                    $$v
                                                  )
                                                },
                                                expression: "setup[item.id]"
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      item.setupByType == "rooms"
                                        ? [
                                            _c("div", [
                                              _vm._v(
                                                "How many rooms does your unit have?"
                                              )
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                "single-line": "",
                                                items: item.units,
                                                box: "",
                                                "background-color": "#F1F5F7",
                                                flat: "",
                                                label: "Select one",
                                                "append-icon":
                                                  "keyboard_arrow_down",
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.showAttention(
                                                    item.id
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          _vm._s(item.rooms) +
                                                            " rooms"
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          _vm._s(item.rooms) +
                                                            " rooms"
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.setup[item.id],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.setup,
                                                    item.id,
                                                    $$v
                                                  )
                                                },
                                                expression: "setup[item.id]"
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      item.setupByType == "name"
                                        ? [
                                            _c("div", [
                                              _vm._v(
                                                "What is the name of your unit?"
                                              )
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                "single-line": "",
                                                items: item.units,
                                                box: "",
                                                "background-color": "#F1F5F7",
                                                flat: "",
                                                label: "Select one",
                                                "append-icon":
                                                  "keyboard_arrow_down",
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.showAttention(
                                                    item.id
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.setup[item.id],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.setup,
                                                    item.id,
                                                    $$v
                                                  )
                                                },
                                                expression: "setup[item.id]"
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      item.setupByType == "rent"
                                        ? [
                                            _c("div", [
                                              _vm._v(
                                                "What is the monthly rent of your unit?"
                                              )
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                "single-line": "",
                                                items: item.units,
                                                box: "",
                                                "background-color": "#F1F5F7",
                                                flat: "",
                                                label: "Select one",
                                                "append-icon":
                                                  "keyboard_arrow_down",
                                                required: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.showAttention(
                                                    item.id
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          "Montly rent: " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(item.rent)
                                                            )
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          "Montly rent: " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(item.rent)
                                                            )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.setup[item.id],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.setup,
                                                    item.id,
                                                    $$v
                                                  )
                                                },
                                                expression: "setup[item.id]"
                                              }
                                            })
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider")
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _c(
            "v-layout",
            { attrs: { row: "", "ma-3": "", "pb-4": "" } },
            [
              _c("v-flex", [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v("Total")
                ]),
                _c("div", { staticClass: "grey--text body-1" }, [
                  _vm._v("Due date Immediate")
                ])
              ]),
              _c("v-flex", { attrs: { "text-xs-right": "" } }, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.totalAmount)))
                ]),
                _c("div", { staticClass: "grey--text body-1 text-lowercase" }, [
                  _vm._v(_vm._s(_vm.variableDeposits))
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ma-3 pb-5" },
            [
              _c("div", { staticClass: "body-1 mb-3" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.dialogTerms = true
                      }
                    }
                  },
                  [_vm._v("Review Terms")]
                )
              ]),
              _c("div", { staticClass: "grey--text body-1 mb-4" }, [
                _vm._v(
                  " Make sure to read and agree with all the smart contracts belonging to this property before payment. "
                )
              ]),
              _c(
                "v-layout",
                {
                  staticClass: "body-1 mb-4",
                  attrs: {
                    "align-center": "",
                    "justify-start": "",
                    "fill-height": ""
                  }
                },
                [
                  _c("toggle-button", {
                    staticClass: "mr-3",
                    attrs: {
                      color: "#8bc34a",
                      width: 68,
                      "font-size": 14,
                      height: 30,
                      sync: true,
                      labels: { checked: "YES", unchecked: "" }
                    },
                    model: {
                      value: _vm.agree,
                      callback: function($$v) {
                        _vm.agree = $$v
                      },
                      expression: "agree"
                    }
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "black--text",
                      on: {
                        click: function($event) {
                          _vm.agree = !_vm.agree
                        }
                      }
                    },
                    [_vm._v("I Agree with all the terms and conditions")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-uppercase mb-0 white--text",
                  attrs: {
                    round: "",
                    block: "",
                    depressed: "",
                    large: "",
                    color: "primary"
                  },
                  on: { click: _vm.makePayment }
                },
                [_vm._v("Make Payment")]
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "500" },
              model: {
                value: _vm.dialogTerms,
                callback: function($$v) {
                  _vm.dialogTerms = $$v
                },
                expression: "dialogTerms"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-0" },
                [
                  _c("v-card-text", { staticClass: "pa-4" }, [
                    _c("img", {
                      staticStyle: { float: "right" },
                      attrs: { src: require("@/assets/z-seal.svg") }
                    }),
                    _c("img", {
                      staticClass: "mb-2",
                      attrs: { src: require("@/assets/ico-smartcontract.svg") }
                    }),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "mb-3 subheading grey--text font-weight-bold text-uppercase"
                      },
                      [_vm._v("Terms And Conditions")]
                    ),
                    _c(
                      "div",
                      { staticClass: "grey--text text--darken-1 body-1" },
                      [_c("vue-markdown", [_vm._v(_vm._s(_vm.termsTxt))])],
                      1
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase white--text rounded-0",
                          attrs: {
                            color: "light-green",
                            large: "",
                            block: "",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.dialogTerms = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("continue")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "290" },
              model: {
                value: _vm.dialogAttention,
                callback: function($$v) {
                  _vm.dialogAttention = $$v
                },
                expression: "dialogAttention"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-3" },
                [
                  _c("v-card-text", { staticClass: "text-xs-center" }, [
                    _c("img", {
                      staticClass: "mb-2",
                      attrs: { src: require("@/assets/attention.svg") }
                    }),
                    _c("h3", { staticClass: "mb-3 title" }, [
                      _vm._v("Attention")
                    ]),
                    _c("div", { staticClass: "grey--text text--darken-1" }, [
                      _vm._v(
                        " Failure to disclose the right Sqft area may incur additional charges or a penalty. "
                      )
                    ])
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: { color: "primary", block: "", depressed: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogAttention = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("continue")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }