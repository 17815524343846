<template>
  <v-app>
    <tev-navbar :title="$t('your_notifications')" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm6 class="mb-5 pt-4">
          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <z-notification
            :notification="notification"
            v-for="(notification) in notifications"
            :key="notification.id"
          ></z-notification>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
// TODO: Añadir html para resultados
// TODO: Añadir paginacion y scroll
import { mapGetters } from 'vuex'
import ZNotification from '../../components/ZNotification.vue'

export default {
  title: 'Your Notifications',
  data() {
    return {
      isLoading: false,
      offset: 0,
      limit: 25,
      notifications: [],
    }
  },
  components: {
    'z-notification': ZNotification,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loadNotifications() {
      this.isLoading = true
      this.$http
        .get('/account/notifications', {
          params: {
            limit: this.limit,
            offset: this.offset,
            sort: 'createdAt',
            order: 'desc',
          },
        })
        .then(res => {
          if ('data' in res && 'notifications' in res.data) {
            this.notifications = res.data.notifications
          }
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadNotifications()
  },
}
</script>

