<template>
  <v-app>
    <tev-navbar />
    <v-content
      :class="{
        'bg-bottom': showIntroStep && !paymentMethod,
        'grey lighten-3': started,
      }"
    >
      <!-- subnavbar -->
      <v-layout
        pa-2
        align-center
        justify-center
        class="grey lighten-5"
        v-if="started && !success"
      >
        <v-btn icon class="light-green--text bg-white ma-3" @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-flex class="my-3 text-xs-center" v-if="currentPayStep == 'all'">
          <div class="grey--text">Please pay fees and deposits</div>
          <h3 class="headline font-weight-bold indigo--text text--darken-4">
            Fees & Deposits
          </h3>
          <div class="body-1">{{ applicationAddress }}</div>
        </v-flex>

        <v-flex class="my-3 text-xs-center" v-if="currentPayStep == 'deposit'">
          <div class="grey--text">Let’s start by paying first the</div>
          <h3 class="headline font-weight-bold indigo--text text--darken-4">
            Deposits
          </h3>
          <div class="body-1">{{ applicationAddress }}</div>
        </v-flex>
        <v-flex class="my-3 text-xs-center" v-if="currentPayStep == 'fee'">
          <div class="grey--text">Let’s start by paying first the</div>
          <h3 class="headline font-weight-bold indigo--text text--darken-4">
            Fees
          </h3>
          <div class="body-1">{{ applicationAddress }}</div>
        </v-flex>
        <div style="width:36px" class="ma-3"></div>
      </v-layout>

      <StepperCheckout v-if="started"></StepperCheckout>

      <v-container>
        <v-layout row align-center justify-center>
          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <template v-if="!isLoading">
            <!-- Multiple applications -->
            <template v-if="!application && applications.length > 1">
              <template v-if="applications.length > 1"></template>
              <div style="max-width:600px">
                <div class="text-xs-center pt-5 mb-5 grey--text">
                  <h2 class="headline black--text">
                    You have several pending payments.
                  </h2>
                  <div>Please select the payment you wish to complete.</div>
                </div>
                <v-card
                  class="z-card mb-4 pointer"
                  @click="selectApplication(item)"
                  v-ripple="{ class: 'light-green--text' }"
                  :key="item.id"
                  v-for="item in applications"
                >
                  <v-card-text class="pl-0">
                    <v-layout
                      align-center
                      justify-space-between
                      row
                      fill-height
                    >
                      <img
                        src="../../assets/icon-property-green.svg"
                        class="ma-4"
                      />
                      <v-layout row wrap>  
                        <v-flex xs12 sm12>                                                
                          <h3 class="title">{{ item.propertyName}}</h3>
                        </v-flex>
                        <v-flex xs12 sm12>                                                
                          <h4 >{{ item.moveInAddress}} - Unit {{ item.unitNumber}}</h4>
                        </v-flex>
                      </v-layout>

                      <v-spacer></v-spacer>
                      <v-btn small icon ripple color="primary">
                        <v-icon class="white--text">navigate_next</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </div>
            </template>
            <!-- No applications -->
            <template v-if="!application && !applications.length">
              <div class="text-xs-center pt-5 mb-5 grey--text">
                <h2 class="headline black--text">
                  You have no pending payments.
                </h2>
              </div>
            </template>
            <template v-if="application">
              <!-- IntroCheckout -->
              <IntroCheckout
                :application="application"
                :contractType="currentPayStep"
                :totalFees="totalFees"
                :totalDeposits="totalDeposits"
                :depositsLength="depositsLength"
                :feesLength="feesLength"
                :variableDeposits="variableDeposits"
                v-on:step="showIntroStep++"
                v-on:start="started = true"
                v-if="!started"
              ></IntroCheckout>
              <!-- Checkout -->
              <CheckoutReview
                :application="application"
                :contractType="currentPayStep"
                v-on:change="checkoutChanged"
                v-if="started && !paying"
              ></CheckoutReview>
              <!-- My Wallet -->
              <MyWallet
                :variableSetup="variableSetup"
                :application="application"
                :totalAmount="totalAmount"
                :contractType="currentPayStep"
                @change="paymentMethodSelected"
                v-if="paying && !paymentMethod"
              ></MyWallet>
              <!-- PayCreditCard -->
              <PayCreditCard
                :variableSetup="variableSetup"
                :application="application"
                :totalAmount="totalAmount"
                :contractType="currentPayStep"
                v-if="paymentMethod == 'preauth'"
                v-on:success="paySuccess"
              ></PayCreditCard>
              <!-- BankSetup -->
              <BankSetup
                :variableSetup="variableSetup"
                :application="application"
                :totalAmount="totalAmount"
                :contractType="currentPayStep"
                v-if="paymentMethod == 'ach' && !bankSetup"
                v-on:success="bankSetupSuccess"
              ></BankSetup>
              <!-- PayAch -->
              <PayAch
                :variableSetup="variableSetup"
                :application="application"
                :totalAmount="totalAmount"
                :contractType="currentPayStep"
                :bankSetup="bankSetup"             
                v-if="paymentMethod == 'ach' && bankSetup"
                v-on:success="paySuccess"
              ></PayAch>
            </template>
          </template>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import IntroCheckout from '@/components/checkout/IntroCheckout'
import CheckoutReview from '@/components/checkout/CheckoutReview'
import MyWallet from '@/components/checkout/MyWallet'
import PayCreditCard from '@/components/checkout/PayCreditCard'
import BankSetup from '@/components/checkout/BankSetup'
import PayAch from '@/components/checkout/PayAch'
import StepperCheckout from '@/components/checkout/StepperCheckout'

export default {
  data() {
    return {
      menu: false,
      success: false,
      started: false,
      paying: false,
      toggle: false,
      popover: false,
      isLoading: false,
      applications: [],
      application: null,
      showIntroStep: 0,
      //paySteps: ['fee', 'deposit'],
      paySteps: ['all'],
      currentPayStep: null,
      totalAmount: null,
      paymentMethod: null,
      variableSetup: null,
      bankSetup: false,
    }
  },
  components: {
    IntroCheckout,
    CheckoutReview,
    MyWallet,
    PayCreditCard,
    BankSetup,
    PayAch,
    StepperCheckout,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    applicationAddress() {
      return this.application.moveInAddress + ' ' + this.application.unitNumber
    },
    feesLength() {
      if (this.application) {
        let items = this.application.payments.filter(
          item => item.contractType == 'fee'
        )
        return items.length
      }
      return 0
    },
    depositsLength() {
      if (this.application) {
        let items = this.application.payments.filter(
          item => item.contractType == 'deposit'
        )
        return items.length
      }
      return 0
    },
    totalDeposits() {
      let total = 0
      if (this.application) {
        let items = this.application.payments.filter(
          item => item.contractType == 'deposit'
        )
        for (let i = 0; i < items.length; i++) {
          total += parseFloat(items[i].amount)
        }
      }
      return total
    },
    totalFees() {
      let total = 0
      if (this.application) {
        let items = this.application.payments.filter(
          item => item.contractType == 'fee'
        )
        for (let i = 0; i < items.length; i++) {
          total += parseFloat(items[i].amount)
        }
      }
      return total
    },
    variableDeposits() {
      let str = ''
      if (this.application) {
        let arr = []
        let items = this.application.payments.filter(
          item => item.feeRateType == 'variable'
        )
        items.forEach(item => {
          arr.push(item.feeName)
        })
        str = arr.length ? '+ ' + arr.join(', ') : ''
      }
      return str
    },
  },
  methods: {
    goBack() {
      if (this.paymentMethod == 'ach' && this.bankSetup) {
        this.bankSetup = null
      } else if (this.paymentMethod) {
        this.paymentMethod = null
      } else if (this.paying) {
        this.paying = false
      } else if (this.started) {
        this.started = false
      }
    },
    checkoutChanged(data) {
      this.variableSetup = data.setup
      this.totalAmount = data.total
      this.paying = true
    },
    bankSetupSuccess(data) {
      this.bankSetup = data
    },
    paySuccess() {
      const _this = this
      this.success = true
      setTimeout(function() {
        if (_this.paySteps.length) {
          _this.paying = false
          _this.paymentMethod = null
          _this.currentPayStep = _this.paySteps.shift()
          _this.success = false
        } else {
          _this.$router.push('/applicant/payments')
        }
      }, 7000)
    },
    paymentMethodSelected(selected) {
      this.paymentMethod = selected
    },
    selectApplication(app) {
      this.currentPayStep = this.paySteps.shift()
      this.application = app
      if (!this.totalFees) {
        this.currentPayStep = this.paySteps.shift()
      }
    },
    loadApplications() {
      this.isLoading = true
      this.$http
        .get('/applicant/applications/pending')
        .then(response => {
          this.applications = response.data.applications
          if (!this.applications.length) {
            this.$router.push('/applicant/payments')
          } else if (this.applications.length == 1) {
            this.selectApplication(this.applications[0])
          }
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadApplications()
  },
}
</script>
<style>
.wallet {
  padding-top: 40px;
  transition: margin 0.5s ease;
}
.wallet .v-card {
  padding: 10px;
  margin-top: -40px;
  min-height: 120px;
}
.wallet .selected {
  padding-top: 20px;
  margin-bottom: 500px;
  min-height: 80px;
}
.lead {
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.msg-explain {
  position: relative;
  width: 300px;
  margin: 0 auto;
}
.msg-explain > div {
  position: absolute;
  top: 120px;
  right: 40px;
  text-align: right;
  color: #1a237e;
  line-height: 20px;
}
.msg-the-are {
  font-size: 18px;
  margin-right: 40px;
}
.msg-two-types {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin-right: 30px;
}
.msg-of-fees {
  font-size: 24px;
  margin-left: 60px;
}
</style>
