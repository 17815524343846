<template>
  <div>
    <!--
    <popover name="myname"> </popover>
    <div
      class="transparent elevation-0 stepper-lg v-stepper v-stepper--alt-labels theme--light"
      style="width:600px;max-width:100%;margin:0 auto"
    >
      <div class="v-stepper__header">
        <div class="v-stepper__step v-stepper__step--complete" v-popover:myname>
          <span class="v-stepper__step__step primary">1</span>
          <div
            class="body-1 "
            :class="{
              'ligth-green--text': true,
              'primary--text': true,
              'grey--text': false,
            }"
          >
            <template v-if="step == 'fee'">
              Fees paid
            </template>
            <template v-else>
              Pay your fees
            </template>
          </div>
        </div>
        <hr class="v-divider theme--light" />
        <div class="v-stepper__step v-stepper__step--inactive">
          <span class="v-stepper__step__step">2</span>
          <div
            class="body-1 "
            :class="{
              'ligth-green--text': true,
              'primary--text': false,
              'grey--text': false,
            }"
          >
            <template v-if="step == 'deposit'">
              Deposits paid
            </template>
            <template v-else>
              Pay your deposits
            </template>
          </div>
        </div>
        <hr class="v-divider theme--light" />
        <div class="v-stepper__step v-stepper__step--inactive">
          <span class="v-stepper__step__step">3</span>
          <div class="v-stepper__label">Get confirmation</div>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
export default {
  props: {
    step: String,
  },
}
</script>
