var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vjsf-form grid-list-md" },
    [
      !!_vm.resolvedSchema
        ? _c(
            "property",
            {
              attrs: {
                schema: _vm.resolvedSchema,
                "model-root": _vm.modelWrapper.root,
                "model-wrapper": _vm.modelWrapper,
                options: _vm.fullOptions,
                color: _vm.color,
                "model-key": "root",
                "parent-key": "",
                rules: _vm.rules
              },
              on: {
                error: function(e) {
                  return _vm.$emit("error", e)
                },
                change: function(e) {
                  return _vm.$emit("change", e)
                },
                input: function(e) {
                  return _vm.$emit("input", e)
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function(_, name) {
                    return {
                      key: name,
                      fn: function(slotData) {
                        return [_vm._t(name, null, null, slotData)]
                      }
                    }
                  })
                ],
                null,
                true
              )
            },
            [
              _vm._l(_vm.$slots, function(_, name) {
                return _vm._t(name, null, { slot: name })
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }