<template>
  <v-card class="z-card pa-3 text-xs-left mb-3" :to="to" v-ripple="{class: 'primary--text'}">
    <v-layout row mb-2>
      <v-flex xs8>
        <div class="font-weight-bold">{{ payment.feeName }}</div>
        <div class="grey--text body-1">Fee Description</div>
      </v-flex>
      <v-flex xs4>
        <v-icon color="primary" style="margin:10px 2px;float:right">chevron_right</v-icon>
        <div class="font-weight-bold">
          <template
            v-if="payment.contractType=='deposit'&&payment.amount==0"
          >Need to Calculate</template>
          <template v-else>{{ payment.amount | currency }}</template>
        </div>
        <div
          class="font-weight-bold text-uppercase body-1"
          :class="statusClass(payment.status)"
        >{{ payment.status }}</div>
      </v-flex>
    </v-layout>
    <v-divider class="mb-2"></v-divider>
    <v-layout row>
      <v-flex xs8>
        <img
          src="@/assets/schedule.svg"
          height="20"
          style="margin:10px;float:right"
          v-if="payment.contractType == 'deposit' && payment.status!='pending'"
        />
        <div>{{ payment.propertyName}} - Unit {{ payment.unitNumber}}</div>
        <div class="grey--text body-1">Property</div>
      </v-flex>
      <v-flex xs4>
        <template v-if="payment.contractType=='deposit' && payment.status!='pending'">
          <div>{{ payment.refundedAt | moment("MM/DD/YYYY") }}</div>
          <div class="grey--text body-1">Refund Date</div>
        </template>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: { to: String, payment: Object },
  data() {
    return {}
  },
  methods: {
    statusClass(status) {
      let str = 'orange--text'
      if (status == 'submitted' || status == 'refund sent') {
        str = 'primary--text'
      } else if (status == 'processed' || status == 'completed' || status == 'refunded') {
        str = 'green--text'
      }
      else if (status == 'failed' || status == 'cancelled') {
        str = 'red--text'
      }
      return str
    },
  },
}
</script>