<template>
  <v-app>
    <tev-navbar title="Email Templates" />
    <v-content>
      <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </div>
      <v-container v-if="!isLoading">
        <v-layout align-start fill-height mb-3>
          <v-btn icon class="light-green--text" to="/admin/email-templates">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <h3 class="heading pt-2 ml-3">
            Template:
            <template v-if="emailTemplate.name">{{ emailTemplate.name }}</template>
            <template v-else>Untitled</template>
          </h3>
        </v-layout>

        <v-layout wrap>
          <v-flex xs12 sm6>
            <v-layout row wrap>
              <v-flex xs12 sm6 pa-1>
                <v-text-field
                  v-model="emailTemplate.name"
                  :label="$t('name')"
                  type="text"
                  box
                  autocomplete="off"
                  :autofocus="$isDesktop()"
                  @change="hasChanges=true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 pa-1>
                <v-text-field
                  v-model="emailTemplate.slug"
                  label="slug"
                  type="text"
                  box
                  autocomplete="off"
                  @change="hasChanges=true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 pa-1>
                <v-text-field
                  v-model="emailTemplate.lang"
                  label="language"
                  type="text"
                  box
                  autocomplete="off"
                  @change="hasChanges=true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 pa-1>
                <v-text-field
                  v-model="emailTemplate.subject"
                  :label="$t('subject')"
                  type="text"
                  box
                  autocomplete="off"
                  @change="hasChanges=true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pa-1>
                <v-tabs fixed-tabs v-model="tabEditor">
                  <v-tab href="#tab-html">HTML</v-tab>
                  <v-tab href="#tab-plain">Plain Text</v-tab>
                </v-tabs>
                <div v-if="tabEditor=='tab-html'">
                  <prism-editor
                    v-model="emailTemplate.messageHtml"
                    language="html"
                    :lineNumbers="false"
                    @change="hasChanges=true"
                  ></prism-editor>
                </div>
                <div v-if="tabEditor=='tab-plain'">
                  <v-textarea
                    v-model="emailTemplate.messagePlain"
                    box
                    rows="16"
                    @change="hasChanges=true"
                  ></v-textarea>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  :loading="isSaving"
                  color="primary"
                  dark
                  depressed
                  large
                  class="text-uppercase"
                  @click="saveEmailTemplate"
                >{{ $t('save') }}</v-btn>
                <v-btn
                  color="primary"
                  flat
                  depressed
                  large
                  class="text-uppercase"
                  to="/admin/email-templates"
                >{{ $t('cancel') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 px-2>
            <transition name="fade">
              <iframe
                class="preview-tpl elevation-1"
                ref="previewTpl"
                src="about:blank"
                v-if="emailTemplate.messageHtml!==null"
              ></iframe>
            </transition>
          </v-flex>
        </v-layout>
      </v-container>

      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DisableAutocomplete from '@/mixin/DisableAutocomplete'
import UnsavedChanges from '@/mixin/UnsavedChanges'
import Alerts from '@/mixin/Alerts'
import 'prismjs'
import 'prismjs/themes/prism.css'
import PrismEditor from 'vue-prism-editor'
import 'vue-prism-editor/dist/VuePrismEditor.css' // import the styles

export default {
  title: 'Email Templates',
  data() {
    return {
      isSaving: false,
      isLoading: false,
      tabEditor: 'tab-html',
      tplId: null,
      emailTemplate: {
        name: null,
        slug: null,
        lang: null,
        messagePlain: null,
        messageHtml: null,
      },
    }
  },
  components: { PrismEditor },
  mixins: [DisableAutocomplete, UnsavedChanges, Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  watch: {
    'emailTemplate.messageHtml'(newHtml) {
      const _this = this
      setTimeout(() => {
        _this.previewHtml(newHtml)
      }, 100)
    },
  },
  methods: {
    htmlChange(code) {
      this.emailTemplate.messageHtml = code
    },
    previewHtml(newHtml) {
      this.$refs.previewTpl.contentWindow.document.open()
      this.$refs.previewTpl.contentWindow.document.write(newHtml)
      this.$refs.previewTpl.contentWindow.document.close()
    },
    saveEmailTemplate() {
      if (
        !this.emailTemplate.name ||
        !this.emailTemplate.slug ||
        !this.emailTemplate.subject ||
        !this.emailTemplate.messagePlain ||
        !this.emailTemplate.messageHtml
      ) {
        return this.toastError(this.$t('complete_required'))
      }

      this.isSaving = true
      this.$http
        .post('/admin/email-templates' + (this.tplId ? '/' + this.tplId : ''), {
          name: this.emailTemplate.name,
          slug: this.emailTemplate.slug,
          lang: this.emailTemplate.lang,
          subject: this.emailTemplate.subject,
          messagePlain: this.emailTemplate.messagePlain,
          messageHtml: this.emailTemplate.messageHtml,
        })
        .then(() => {
          this.toastSuccess('Email Template Saved!')
          this.hasChanges = false
          this.$router.push('/admin/email-templates')
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    loadTemplate() {
      this.isLoading = true
      this.$http
        .get('/admin/email-templates/' + this.tplId)
        .then(response => {
          this.emailTemplate = response.data
          //this.previewHtml()
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
          this.$router.push('/admin/email-templates')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.tplId = this.$route.params.tplId
    if (this.tplId && this.tplId !== 'new') {
      this.loadTemplate()
    }
  },
}
</script>
<style>
.prism-editor-wrapper {
  max-height: 440px;
}
.prism-editor-wrapper code[class*='language-'],
.prism-editor-wrapper pre[class*='language-'] {
  background: #f1f1f1 !important;
  min-width: 100%;
  min-height: 440px;
}
.preview-tpl {
  background: #e1e1e1;
  width: 100%;
  height: 670px;
  border: 0;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 4px;
}
</style>