<template>
  <v-app>
    <tev-navbar :title="$t('member_details')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm6>
            <v-layout row wrap align-start justify-start>
              <v-btn icon class="light-green--text" @click="$router.back()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                &nbsp;
                <template v-if="!isLoading">ID: {{ member.id }}</template>
              </v-flex>
            </v-layout>

            <z-digital-id-card cardName="Digital ID" />
            <!--v-layout xs12 align-start justify-space-between row fill-height>
            <h4>Personal Information</h4>
            <v-btn outline icon large color="primary">
              <v-avatar :size="16">
                <img src="../../assets/edit.svg" />
              </v-avatar>
            </v-btn>
            </v-layout-->

            <v-tabs grow color="transparent" slider-color="primary">
              <v-tab ripple>Personal Info</v-tab>
              <v-tab ripple>Properties</v-tab>
              <v-tab-item>
                <v-divider></v-divider>
                <v-flex xs12 sm12 mb-4>
                  <v-list two-line class="transparent py-0">
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-user.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '140px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">{{ member.fullName }}</v-list-tile-title>
                        <v-list-tile-sub-title>Name</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-phone.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '120px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title
                          v-if="!isLoading"
                        >+{{ member.dialCode }} {{ member.cellPhone }}</v-list-tile-title>
                        <v-list-tile-sub-title>Phone number</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-email.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '160px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">{{ member.email }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('email') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <!--
                <v-list-tile avatar>
                  <v-list-tile-avatar size="16">
                    <img src="../../assets/ico-org.svg" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title v-if="isLoading">
                      <skeleton-loading>
                        <square-skeleton :boxProperties="{width: '140px',height: '14px'}"></square-skeleton>
                      </skeleton-loading>
                    </v-list-tile-title>
                    <v-list-tile-title v-if="!isLoading">{{ member.organization }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{ $t('organization') }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                    -->
                  </v-list>
                </v-flex>
              </v-tab-item>
              <v-tab-item>
                <v-divider></v-divider>
                <v-list two-line class="transparent mb-4 py-0">
                  <template v-for="(item) in member.properties">
                    <v-list-tile
                      :key="item.id"
                      @click="$router.push('/admin/properties/'+item.id)"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title class="heading">{{ item.name }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('address') }}: {{ item.address.line1 }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn small icon ripple color="primary">
                          <v-icon class="white--text">chevron_right</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider :key="item.id+'_divider'"></v-divider>
                  </template>
                </v-list>
              </v-tab-item>
            </v-tabs>

            <!--v-flex xs12 sm12>
              <v-btn
                color="light-green"
                dark
                depressed
                block
                large
                class="text-uppercase"
              >{{ $t('share_id') }}</v-btn>
            </v-flex-->
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DigitalIdCard from '../../components/DigitalIdCard'

export default {
  title: 'Member Details',
  data() {
    return {
      isLoading: false,
      memberId: null,
      member: {
        id: null,
        fullName: null,
        dialCode: null,
        cellPhone: null,
        email: null,
        orgStatus: null,
        organization: null,
        status: null,
      },
    }
  },
  components: {
    'z-digital-id-card': DigitalIdCard,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loadMember() {
      this.isLoading = true
      this.$http
        .get('/admin/members/' + this.memberId)
        .then(response => {
          this.$log.info(response.data)
          this.member = response.data
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/admin/members')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.memberId = this.$route.params.memberId
    if (!this.memberId) {
      this.$router.push('/admin/members')
    }
    this.loadMember()
  },
}
</script>
<style>
.page-member .v-list__tile__avatar {
  min-width: 44px;
}
.page-member .v-avatar img {
  border-radius: 0;
}
</style>
