<template>
  <v-app class="page-organization">
    <tev-navbar :title="$t('organization')" />
    <v-content>
      <v-container>
        <v-flex x12 sm8 offset-sm2>
          <v-layout row wrap align-start justify-start>
            <v-btn icon class="light-green--text" to="/admin/organizations">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-flex py-3 text-xs-right grey--text>
              <div>
                &nbsp;
                <template
                  v-if="organizationId"
                >{{ $t('organization_id') }}: {{ organizationId }}</template>
              </div>
              <z-status-chip :status="organization.status" :label="$t(organization.status)"></z-status-chip>
            </v-flex>
          </v-layout>
          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <transition name="fade" mode="out-in">
            <v-layout row wrap v-if="!isLoading">
              <v-flex x12 sm12>
                <!-- organization_info -->
                <v-flex class="zinc-box">
                  <v-flex xs12 class="zinc-box__title">
                    <h3>{{ $t('organization_info') }}</h3>
                  </v-flex>
                  <v-flex xs12 class="zinc-box__body">
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">{{ organization.name }}</div>
                      <div class="zinc-box__label">{{ $t('organization_name') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div
                        class="zinc-box__value"
                      >{{ organization.address.line1 }}, {{ organization.address.line2 }}</div>
                      <div class="zinc-box__label">{{ $t('address') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">{{ organization.address.city }}</div>
                      <div class="zinc-box__label">{{ $t('city') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">{{ organization.address.state }}</div>
                      <div class="zinc-box__label">{{ $t('state') }}</div>
                    </div>
                    <div class="zinc-box__item">
                      <div class="zinc-box__value">{{ organization.address.postalCode }}</div>
                      <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                    </div>
                  </v-flex>
                </v-flex>
                <!-- / organization_info -->
              </v-flex>

              <!-- active_representative -->
              <v-flex xs12 sm12>
                <v-flex class="zinc-box">
                  <v-flex xs12 class="zinc-box__title">
                    <h3>{{ $t('representative') }}</h3>
                  </v-flex>
                  <v-flex xs12 class="zinc-box__body px-2">
                    <v-list single-line class="transparent mb-4">
                      <v-list-tile avatar>
                        <v-list-tile-avatar>
                          <Lettericon :name="organization.representativeName" size="2"></Lettericon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>{{ organization.representativeName }}</v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn small icon ripple color="primary"
                            :to="'/admin/members/' + organization.repId">
                            <v-icon class="white--text">keyboard_arrow_right</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                </v-flex>
              </v-flex>
              <!-- / active_representative -->

              <template v-if="organization.status != 'active'">
                <v-flex xs12 mb-3>
                  <v-btn
                    :loading="isSending"
                    :disabled="isSending"
                    color="primary"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                    @click="resendInvite"
                  >Re-Send Invitation</v-btn>
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    :loading="isCanceling"
                    :disabled="isCanceling"
                    color="red"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                    @click="cancelInvite"
                  >Cancel Invitation</v-btn>
                </v-flex>
              </template>
            </v-layout>
          </transition>
        </v-flex>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '@/components/Lettericon'
import Alerts from '@/mixin/Alerts'

export default {
  data() {
    return {
      isLoading: true,
      isSending: false,
      isCanceling: false,
      organizationId: null,
      organization: {
        name: null,
        tevId: null,
        repId: null,
        representativeName: null,
        repCommission: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
          country: null,
        },
        status: null,
        createdAt: null,
        updatedAt: null,
      },
    }
  },
  mixins: [Alerts],
  components: {
    Lettericon,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    resendInvite() {
      this.isSending = true
      this.$http
        .post('/admin/organizations/' + this.organizationId + '/resend')
        .then(() => {
          this.toastSuccess('Invitation Sent!')
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSending = false
        })
    },
    async cancelInvite() {
      const answer = await this.$dialog.confirm({
        text: 'Are you sure you want to cancel this invitation?',
        title: 'Attention',
        actions: {
          false: 'Cancel',
          true: {
            text: 'Yes, Cancel Invitation',
            color: 'primary',
          },
        },
      })

      if (!answer) {
        return
      }

      this.isCanceling = true
      this.$http
        .post('/admin/organizations/' + this.organizationId + '/cancel')
        .then(() => {
          this.$router.push('/admin/organizations/')
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isCanceling = false
        })
    },
    loadOrganization() {
      this.isLoading = true
      this.$http
        .get('/admin/organizations/' + this.organizationId)
        .then(response => {
          this.organization = response.data
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
          this.$router.push('/admin/organizations/')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.organizationId = this.$route.params.organizationId
    this.loadOrganization()
  },
}
</script>
<style>
.zinc-box {
  background-color: #fff;
  margin-bottom: 20px;
}

.zinc-box__title {
  background-color: #c1c1c1;
  color: #fff;
  padding: 6px 20px;
}

.zinc-box__title h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.zinc-box__body {
  padding: 0 20px;
  min-height: 40px;
}

.zinc-box__footer {
  background-color: #efefef;
  padding: 20px;
  font-weight: 400;
  font-size: 18px;
}

.zinc-box__item {
  border-bottom: 1px solid #e4e4e4;
  padding: 18px 0;
}

.zinc-box__item:last-child {
  border-bottom: 0;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.zinc-box__value {
  color: #373041;
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (max-width: 959px) {
  .zinc-box__body {
    padding: 0 18px;
  }
  .zinc-box__item {
    padding: 14px 0;
  }
  .zinc-box__value {
    font-size: 16px;
  }
  .zinc-box__footer {
    padding: 18px;
    font-size: 16px;
  }
}

.zinc-smartservices-box .zinc-box__title {
  background-color: #373041;
}

.page-property .v-list__tile__avatar .v-icon {
  font-family: roboto;
  font-size: 14px;
  font-weight: 700;
}

.grey.zinc {
  background-color: #cfd8dc !important;
}
</style>
