<template>
  <v-app>
    <tev-navbar />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm8 class="px-3 mt-5 mb-4">
          <v-form ref="form" lazy-validation @submit.prevent="newSearch">
            <v-container>
              <h4 class="primary--text font-weight-bold mb-3 text-uppercase">{{ $t('my_properties') }}</h4>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field
                    class="box-border"
                    append-icon="search"
                    @click:append="newSearch"                    
                    v-model="queryName"
                    :label="$t('search')"
                    type="text"
                    autocomplete="off"
                    box
                    :autofocus="$isDesktop()"
                  ></v-text-field>
                  
                  <v-chip class="white font-weight-bold" @click="filterStatus('all')">All</v-chip>
                  <v-chip class="white font-weight-bold grey--text z-filter-chip" @click="filterStatus('pending')">
                    <v-avatar class="orange accent-3">
                      <v-icon class="white--text">chevron_right</v-icon>
                    </v-avatar>Pending
                  </v-chip>
                  <v-chip class="white font-weight-bold grey--text z-filter-chip" @click="filterStatus('waiting')">
                    <v-avatar class="primary">
                      <v-icon class="white--text">autorenew</v-icon>
                    </v-avatar>Waiting
                  </v-chip>
                  <v-chip class="white font-weight-bold grey--text z-filter-chip" @click="filterStatus('active')">
                    <v-avatar class="light-green">
                      <v-icon class="white--text">expand_less</v-icon>
                    </v-avatar>Active
                  </v-chip>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm8 class="mb-5 text-xs-center">
          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <v-list two-line class="transparent mb-4" v-if="!isLoading&&totalResults">
            <v-divider></v-divider>
            <template v-for="(item) in properties">
              <v-list-tile :key="item.id" @click="$router.push('/accountant/properties/'+item.id)">
                <!--
                <v-list-tile-action
                  @click.stop.prevent="stopPropagation"
                  v-if="(item.status === 'setup'||item.status === 'pending')"
                >
                  <v-checkbox v-model="checkbox" color="light-green" class="checkbox-green"></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-action v-if="(item.status !== 'setup'&&item.status !== 'pending')"></v-list-tile-action>
                -->
                <v-list-tile-content>
                  <v-list-tile-title class="heading">{{ item.name }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('address') }}: {{ item.address.line1 }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <div class="tx-status" :class="statusClass(item.status)">{{ item.status }}</div>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="item.id+'_divider'"></v-divider>
            </template>
          </v-list>
          <!-- property-list -->
          <template v-if="!totalResults&&!isLoading">
            <h3 class="mt-2 mb-5 title">No matching properties found.</h3>
          </template>
          <template v-if="totalResults&&!isLoading">
            <div
              class="mt-3"
            >Results {{ currentPage * perPage - perPage + 1 }} - {{ Math.min(currentPage * perPage, totalResults) }} of {{ totalResults }} properties</div>
            <v-pagination
              v-model="currentPage"
              @input="changePage"
              :length="totalPages"
              :total-visible="6"
              circle
              v-if="totalPages>1"
            ></v-pagination>
          </template>

          <!--
          <v-flex xs12 sm12>
            <v-btn
              color="light-green"
              dark
              depressed
              block
              large
              class="text-uppercase"
              :to="'/accountant/organizations/'+ this.organizationId +'/properties/import'"
            >{{ $t('import_properties') }}</v-btn>
          </v-flex>
          -->
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Properties',
  data() {
    return {
      queryName: null,
      queryStatus: 'all',       
      checkbox: false,
      isLoading: true,
      currentPage: 1,
      perPage: 25,
      totalPages: null,
      totalResults: null,
      properties: [],
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    statusClass(status) {
      let str = 'orange'
      if (status == 'active') {
        str = 'light-green'
      }
      else if (status == 'suspended') {
        str = 'grey'
      }
      return str
    },    
    stopPropagation(e) {
      e.stopPropagation()
    },
    changePage(page) {
      this.$scrollTo('#app')
      this.currentPage = page
      this.loadProperties()
    },
    newSearch() {
      this.currentPage = 1
      this.loadProperties()
    },
    filterStatus(status) {
      this.queryStatus = status
      this.loadProperties()
    },    
    loadProperties() {
      this.isLoading = true
      this.$http
        .get('/accountant/properties', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            name: this.queryName,            
            status: this.queryStatus,
          },
        })
        .then(res => {
          if (res && res.data) {
            this.properties = res.data.properties
            this.totalResults = res.data.metadata.total
            this.totalPages = Math.ceil(this.totalResults / this.perPage)
            if (!this.totalResults) {
              this.$router.push('/accountant/properties/')
            } else {
              this.$scrollTo('#app')
            }
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadProperties()
  },
}
</script>
