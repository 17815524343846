var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("login_history") } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "pt-5 mb-5 text-xs-center",
                  attrs: { xs12: "", sm8: "" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "white py-0 mb-4",
                      attrs: { "two-line": "" },
                      on: { scroll: _vm.onScroll }
                    },
                    [
                      _vm._l(_vm.loginHistory, function(item, index) {
                        return [
                          _c(
                            "v-list-tile",
                            { key: item.createdAt, attrs: { avatar: "" } },
                            [
                              _c("v-list-tile-action", [
                                _c("div", {
                                  staticClass: "iti-flag",
                                  class: String(item.country).toLowerCase(),
                                  attrs: { title: item.country }
                                })
                              ]),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(" " + _vm._s(item.ip) + " "),
                                    _c(
                                      "em",
                                      { staticClass: "ml-4 grey--text body-1" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.createdAt,
                                              "from"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("platform")) +
                                        ": " +
                                        _vm._s(item.platform) +
                                        " - " +
                                        _vm._s(_vm.$t("browser")) +
                                        ": " +
                                        _vm._s(item.browser)
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("v-list-tile-action", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tx-status",
                                    class: {
                                      "tx-status-pending":
                                        item.status == "failed"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.status))]
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-divider", { key: index })
                        ]
                      })
                    ],
                    2
                  ),
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5 mb-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "scroll",
                          rawName: "v-scroll",
                          value: _vm.onScroll,
                          expression: "onScroll"
                        },
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fab,
                          expression: "fab"
                        }
                      ],
                      staticClass: "elevation-0",
                      attrs: {
                        fab: "",
                        dark: "",
                        fixed: "",
                        bottom: "",
                        right: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$scrollTo("#app")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }