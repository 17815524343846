var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        color: "transparent",
        "max-width": "600",
        width: "100%"
      }
    },
    [
      _c("div", { staticClass: "text-xs-center mb-4" }, [
        _c("div", { staticClass: "display-1 black--text font-weight-bold" }, [
          _vm._v(_vm._s(_vm._f("currency")(_vm.totalAmount)))
        ]),
        _c(
          "div",
          { staticClass: "black--text font-weight-bold mb-2" },
          [
            !_vm.property && _vm.contractType == "deposit"
              ? [_vm._v("Total Deposits")]
              : _vm._e(),
            !_vm.property && _vm.contractType == "fee"
              ? [_vm._v("Total Fees")]
              : _vm._e(),
            _vm.property ? [_vm._v("Property Setup Fee")] : _vm._e()
          ],
          2
        )
      ]),
      _vm.isSaving
        ? [
            _c(
              "div",
              { staticClass: "mt-3 mb-2" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    size: 45,
                    width: 1,
                    color: "light-green",
                    indeterminate: ""
                  }
                })
              ],
              1
            ),
            _c("h3", { staticClass: "black--text mb-4 font-weight-regular" }, [
              _vm._v("One moment please")
            ])
          ]
        : _vm._e(),
      !_vm.isSaving && _vm.plaidAccounts.length
        ? [
            _c("h3", { staticClass: "black--text mb-4 font-weight-regular" }, [
              _vm._v("Please select your bank account")
            ]),
            _vm._l(_vm.plaidAccounts, function(item) {
              return _c(
                "v-card",
                {
                  key: item.id,
                  staticClass: "z-card mb-4 pa-3 pointer",
                  attrs: { ripple: { class: "primary--text text--lighten-5" } },
                  on: {
                    click: function($event) {
                      return _vm.selectBankAccount(item)
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-space-between": "",
                        row: ""
                      }
                    },
                    [
                      _c("div", [
                        _c("h3", { staticClass: "primary--text subheading" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _c("div", { staticClass: "body-1" }, [
                          _c("strong", [
                            _vm._v("ending in " + _vm._s(item.mask))
                          ])
                        ]),
                        _c("div", { staticClass: "body-1 grey--text" }, [
                          _vm._v(_vm._s(item.subtype))
                        ])
                      ]),
                      _c("v-spacer"),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/checkbox-green.svg"),
                          height: "24"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c("div", { staticClass: "mb-3 pt-2 text-xs-center" }, [
              _c("p", [
                _vm._v(
                  " You are connected to your " +
                    _vm._s(_vm.institutionName) +
                    " account. "
                ),
                _c("a", { on: { click: _vm.openPlaid } }, [
                  _vm._v("Change Bank")
                ])
              ])
            ])
          ]
        : _vm._e(),
      !_vm.isSaving && !_vm.plaidAccounts.length
        ? [
            _c(
              "v-card",
              { staticClass: "z-card py-3 mb-4 primary" },
              [
                _c(
                  "v-list",
                  { staticClass: "transparent white--text" },
                  [
                    _c(
                      "v-list-tile",
                      { attrs: { avatar: "" } },
                      [
                        _c("v-list-tile-avatar", [
                          _c("img", {
                            attrs: { src: require("@/assets/wallet-ach.svg") }
                          })
                        ]),
                        _c(
                          "v-list-tile-content",
                          [
                            _c(
                              "v-list-tile-title",
                              { staticClass: "white--text" },
                              [_vm._v("Bank ACH Payment")]
                            ),
                            _c(
                              "v-list-tile-sub-title",
                              { staticClass: "body-1 white--text" },
                              [
                                _vm._v(
                                  "Payment could take up to 3 business days"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "text-xs-center" },
                  [
                    _c("v-progress-circular", {
                      attrs: { size: 50, color: "primary", indeterminate: "" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isLoading
              ? [
                  _c(
                    "h3",
                    { staticClass: "black--text mb-4 font-weight-regular" },
                    [_vm._v("How do you want to connect your bank account")]
                  ),
                  _vm._l(_vm.bankAccounts, function(item) {
                    return _c(
                      "v-card",
                      {
                        key: item.id,
                        staticClass: "z-card mb-4 pa-3 pointer",
                        attrs: {
                          ripple: { class: "primary--text text--lighten-5" }
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("success", {
                              account: item,
                              bankId: item.id
                            })
                          }
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "align-center": "",
                              "justify-space-between": "",
                              row: ""
                            }
                          },
                          [
                            _c("div", [
                              _c(
                                "h3",
                                { staticClass: "primary--text subheading" },
                                [_vm._v(_vm._s(item.alias))]
                              ),
                              _c("div", { staticClass: "body-1" }, [
                                _c("strong", [
                                  _vm._v("ending in " + _vm._s(item.last4))
                                ])
                              ]),
                              _c("div", { staticClass: "body-1 grey--text" }, [
                                _vm._v(_vm._s(item.bankAccountType))
                              ])
                            ]),
                            _c("v-spacer"),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/checkbox-green.svg"),
                                height: "24"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-card",
                    {
                      staticClass: "z-card mb-4 pa-3 pointer",
                      attrs: {
                        ripple: { class: "primary--text text--lighten-5" }
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleSelected("online")
                        }
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "align-center": "",
                            "justify-space-between": "",
                            row: ""
                          }
                        },
                        [
                          _c("div", [
                            _c(
                              "h3",
                              { staticClass: "primary--text subheading" },
                              [_vm._v("Using online banking credentials")]
                            ),
                            _c("div", { staticClass: "body-1" }, [
                              _c("strong", [
                                _vm._v("Immediate bank verification")
                              ])
                            ]),
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(
                                " Make sure you have the online user and password of the online banking of the property bank account. "
                              )
                            ])
                          ]),
                          _c("v-spacer"),
                          _vm.setupSelected == "online"
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/checkbox-green-checked.svg"),
                                  height: "24"
                                }
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/checkbox-green.svg"),
                                  height: "24"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.setupSelected == "online"
                    ? [
                        _c(
                          "div",
                          { staticClass: "mb-3 pt-2 text-xs-center" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-uppercase mb-4 white--text",
                                attrs: {
                                  round: "",
                                  depressed: "",
                                  large: "",
                                  color: "primary"
                                },
                                on: { click: _vm.openPlaid }
                              },
                              [_vm._v("Sign into your bank")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }