var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Password" }, [
    !_vm.strengthMeterOnly
      ? _c("div", { staticClass: "Password__group" }, [
          _c("input", {
            ref: _vm.referanceValue,
            class: [_vm.defaultClass, _vm.disabled ? _vm.disabledClass : ""],
            attrs: {
              type: _vm.inputType,
              name: _vm.name,
              id: _vm.id,
              placeholder: _vm.placeholder,
              required: _vm.required,
              disabled: _vm.disabled,
              autocomplete: _vm.autocomplete
            },
            domProps: { value: _vm.value },
            on: {
              input: function(evt) {
                return _vm.emitValue("input", evt.target.value)
              },
              blur: function(evt) {
                return _vm.emitValue("blur", evt.target.value)
              },
              focus: function(evt) {
                return _vm.emitValue("focus", evt.target.value)
              }
            }
          }),
          _c("div", { staticClass: "Password__icons" }, [
            _vm.toggle
              ? _c("div", { staticClass: "Password__toggle" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-clean",
                      attrs: {
                        type: "button",
                        "aria-label": _vm.showPasswordLabel
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.togglePassword()
                        }
                      }
                    },
                    [
                      _vm.showPassword
                        ? _c(
                            "svg",
                            {
                              attrs: {
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("title", [
                                _vm._v(_vm._s(_vm.showPasswordLabel))
                              ]),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M12 9c1.641 0 3 1.359 3 3s-1.359 3-3 3-3-1.359-3-3 1.359-3 3-3zM12 17.016c2.766 0 5.016-2.25 5.016-5.016s-2.25-5.016-5.016-5.016-5.016 2.25-5.016 5.016 2.25 5.016 5.016 5.016zM12 4.5c5.016 0 9.281 3.094 11.016 7.5-1.734 4.406-6 7.5-11.016 7.5s-9.281-3.094-11.016-7.5c1.734-4.406 6-7.5 11.016-7.5z"
                                }
                              })
                            ]
                          )
                        : _c(
                            "svg",
                            {
                              attrs: {
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("title", [
                                _vm._v(_vm._s(_vm.showPasswordLabel))
                              ]),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M11.859 9h0.141c1.641 0 3 1.359 3 3v0.188zM7.547 9.797c-0.328 0.656-0.563 1.406-0.563 2.203 0 2.766 2.25 5.016 5.016 5.016 0.797 0 1.547-0.234 2.203-0.563l-1.547-1.547c-0.188 0.047-0.422 0.094-0.656 0.094-1.641 0-3-1.359-3-3 0-0.234 0.047-0.469 0.094-0.656zM2.016 4.266l1.266-1.266 17.719 17.719-1.266 1.266c-1.124-1.11-2.256-2.213-3.375-3.328-1.359 0.563-2.813 0.844-4.359 0.844-5.016 0-9.281-3.094-11.016-7.5 0.797-1.969 2.109-3.656 3.75-4.969-0.914-0.914-1.812-1.844-2.719-2.766zM12 6.984c-0.656 0-1.266 0.141-1.828 0.375l-2.156-2.156c1.219-0.469 2.578-0.703 3.984-0.703 5.016 0 9.234 3.094 10.969 7.5-0.75 1.875-1.922 3.469-3.422 4.734l-2.906-2.906c0.234-0.563 0.375-1.172 0.375-1.828 0-2.766-2.25-5.016-5.016-5.016z"
                                }
                              })
                            ]
                          )
                    ]
                  )
                ])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    !_vm.passwordIsStrong && _vm.password !== null && _vm.password.length > 0
      ? _c("div", { staticClass: "not-strong-pass-message" }, [
          _vm._v("Your password is easily guessable. You must do better.")
        ])
      : _vm._e(),
    _vm.passwordIsStrong
      ? _c("div", { staticClass: "strong-pass-message" }, [
          _vm._v("Your password is strong")
        ])
      : _vm._e(),
    _vm.showStrengthMeter
      ? _c(
          "div",
          { staticClass: "meter" },
          _vm._l(5, function(index) {
            return _c("div", {
              key: index,
              class: _vm.barClass(index, _vm.fulfilledCount),
              attrs: { id: "bar" + index }
            })
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "conditions" }, [
      _c("div", { class: _vm.conditionClass("lowercase") }, [
        _vm._v("Lower Case")
      ]),
      _c("div", { class: _vm.conditionClass("uppercase") }, [
        _vm._v("Upper Case")
      ]),
      _c("div", { class: _vm.conditionClass("numbers") }, [_vm._v("Numbers")]),
      _c("div", { class: _vm.conditionClass("symbols") }, [_vm._v("Symbols")]),
      _c("div", { class: _vm.conditionClass("minlength") }, [
        _vm._v("MinLength(" + _vm._s(this.secureLength) + ")")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }