import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

// Vue Persist
const vuexLocalStorage = new VuexPersist({
  key: 'state',
  storage: window.localStorage,
  reducer: state => ({ user: state.user }),
})

// logrocket
const logrocketPlugin = createPlugin(LogRocket)

// store
const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin, logrocketPlugin],
  state,
  actions,
  getters,
  mutations,
})

export default store
