var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              return [
                _c(
                  "div",
                  [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "", color: _vm.toolbarClass } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: _vm.canUndo
                                              ? "grey--text"
                                              : "",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.undo(commands)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("undo")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("undo")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: _vm.canRedo
                                              ? "grey--text"
                                              : "",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.redo(commands)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("redo")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("redo")))])]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.bold()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.bold }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("format_bold")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("bold")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.italic()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.italic }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("format_italic")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("italic")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.strike()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.strike }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("strikethrough_s")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("strike")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.underline()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.underline }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("format_underlined")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("underline")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.heading({
                                              level: 1
                                            })
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return commands.heading({
                                                  level: 1
                                                })
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("H1")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Heading 1")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.heading({
                                              level: 2
                                            })
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return commands.heading({
                                                  level: 2
                                                })
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("H2")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Heading 2")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.heading({
                                              level: 3
                                            })
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return commands.heading({
                                                  level: 3
                                                })
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("H3")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Heading 3")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.bullet_list()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.bullet_list }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("format_list_bulleted")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("bullet_list")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.ordered_list()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.ordered_list }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("format_list_numbered")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("ordered_list")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: isActive.blockquote()
                                              ? _vm.activeClass
                                              : "",
                                            attrs: { icon: "" },
                                            on: { click: commands.blockquote }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [_vm._v("format_quote")])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("blockquote")))])]
                        )
                      ],
                      1
                    ),
                    isActive.table()
                      ? _c(
                          "v-toolbar",
                          { attrs: { flat: "", color: _vm.toolbarClass } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.deleteTable }
                              },
                              [_c("icon", { attrs: { name: "delete_table" } })],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.addColumnBefore }
                              },
                              [
                                _c("icon", {
                                  attrs: { name: "add_col_before" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.addColumnAfter }
                              },
                              [
                                _c("icon", { attrs: { name: "add_col_after" } })
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.deleteColumn }
                              },
                              [_c("icon", { attrs: { name: "delete_col" } })],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.addRowBefore }
                              },
                              [
                                _c("icon", {
                                  attrs: { name: "add_row_before" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.addRowAfter }
                              },
                              [
                                _c("icon", { attrs: { name: "add_row_after" } })
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.deleteRow }
                              },
                              [_c("icon", { attrs: { name: "delete_row" } })],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "menubar__button",
                                attrs: { icon: "" },
                                on: { click: commands.toggleCellMerge }
                              },
                              [
                                _c("icon", { attrs: { name: "combine_cells" } })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("editor-content", { attrs: { editor: _vm.editor } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }