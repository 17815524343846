var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "z-footer", class: { "theme-dark": _vm.dark } },
    [
      _c(
        "svg",
        {
          staticStyle: { "enable-background": "new 0 0 182.9 18.9" },
          attrs: {
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            x: "0px",
            y: "0px",
            viewBox: "0 0 182.9 18.9",
            "xml:space": "preserve"
          }
        },
        [
          _c("g", [
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M8.8,14.5c0-0.5-0.2-0.9-0.7-1.2s-1.3-0.5-2.3-0.8C2.2,11.8,0.5,10.3,0.5,8c0-1.3,0.5-2.4,1.6-3.3\n      c1.1-0.9,2.5-1.3,4.3-1.3c1.9,0,3.4,0.4,4.5,1.3c1.1,0.9,1.7,2,1.7,3.5h-4c0-0.6-0.2-1-0.6-1.4C7.8,6.4,7.2,6.2,6.4,6.2\n      c-0.7,0-1.2,0.2-1.6,0.5S4.3,7.4,4.3,7.8c0,0.4,0.2,0.8,0.6,1.1C5.3,9.2,6.1,9.4,7,9.6c1,0.2,1.8,0.4,2.5,0.7\n      c2.1,0.8,3.2,2.1,3.2,4c0,1.4-0.6,2.5-1.8,3.3c-1.2,0.8-2.7,1.3-4.5,1.3c-1.3,0-2.4-0.2-3.3-0.7c-1-0.4-1.7-1.1-2.3-1.8\n      C0.3,15.6,0,14.7,0,13.8h3.8c0,0.7,0.3,1.3,0.8,1.6s1.1,0.6,2,0.6c0.8,0,1.3-0.1,1.7-0.4C8.6,15.3,8.8,14.9,8.8,14.5z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M19,3.7l0.1,1.7c1.1-1.3,2.5-1.9,4.3-1.9c1.9,0,3.2,0.8,4,2.3c1-1.5,2.5-2.3,4.5-2.3c1.6,0,2.8,0.5,3.6,1.4\n      c0.8,0.9,1.2,2.4,1.2,4.3v9.5h-4V9.1c0-0.8-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.8-0.6c-1.2,0-2,0.6-2.5,1.7l0,10.2h-4V9.1\n      c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.7-0.6c-1.1,0-1.9,0.5-2.5,1.4v10.5h-4V3.7H19z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M48.4,18.6c-0.2-0.4-0.3-0.8-0.4-1.3c-1,1.1-2.2,1.6-3.8,1.6c-1.5,0-2.7-0.4-3.6-1.3s-1.4-1.9-1.4-3.2\n      c0-1.6,0.6-2.8,1.8-3.6c1.2-0.8,2.9-1.3,5.1-1.3h1.8V8.6c0-0.7-0.2-1.2-0.5-1.7c-0.4-0.4-0.9-0.6-1.7-0.6c-0.7,0-1.2,0.2-1.6,0.5\n      c-0.4,0.3-0.6,0.8-0.6,1.3h-4c0-0.9,0.3-1.7,0.8-2.4C40.8,5,41.5,4.4,42.5,4c1-0.4,2.1-0.6,3.3-0.6c1.8,0,3.3,0.5,4.4,1.4\n      c1.1,0.9,1.6,2.2,1.6,3.9v6.5c0,1.4,0.2,2.5,0.6,3.2v0.2H48.4z M45.1,15.8c0.6,0,1.1-0.1,1.6-0.4c0.5-0.3,0.9-0.6,1.1-1.1v-2.6\n      h-1.5c-2,0-3.1,0.7-3.2,2.1l0,0.2c0,0.5,0.2,0.9,0.5,1.2C44,15.7,44.5,15.8,45.1,15.8z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M63.2,7.4c-0.5-0.1-1-0.1-1.4-0.1c-1.5,0-2.5,0.5-3,1.5v9.8h-4V3.7h3.8l0.1,1.8c0.8-1.4,1.9-2.1,3.3-2.1\n      c0.4,0,0.9,0.1,1.2,0.2L63.2,7.4z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M70.6,0v3.7h2.6v2.9h-2.6V14c0,0.6,0.1,0.9,0.3,1.2s0.6,0.4,1.2,0.4c0.4,0,0.8,0,1.2-0.1v3\n      c-0.8,0.2-1.6,0.4-2.4,0.4c-2.8,0-4.2-1.4-4.3-4.2v-8h-2.2V3.7h2.2V0H70.6z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M82.7,8c0-1,0.3-1.7,0.9-2.3C84.3,5.1,85,4.9,86,4.9c1,0,1.8,0.3,2.4,0.9S89.3,7,89.3,8v0.6\n      c0,0.9-0.3,1.7-0.9,2.3S87,11.7,86,11.7c-1,0-1.8-0.3-2.4-0.9c-0.6-0.6-0.9-1.3-0.9-2.3V8z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M107.7,14.5c0-0.5-0.2-0.9-0.7-1.2s-1.3-0.5-2.3-0.8c-3.5-0.7-5.3-2.3-5.3-4.5c0-1.3,0.5-2.4,1.6-3.3\n      c1.1-0.9,2.5-1.3,4.3-1.3c1.9,0,3.4,0.4,4.5,1.3c1.1,0.9,1.7,2,1.7,3.5h-4c0-0.6-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.7-0.6\n      c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.6,0.7-0.6,1.2c0,0.4,0.2,0.8,0.6,1.1c0.4,0.3,1.1,0.5,2.1,0.7c1,0.2,1.8,0.4,2.5,0.7\n      c2.1,0.8,3.2,2.1,3.2,4c0,1.4-0.6,2.5-1.8,3.3c-1.2,0.8-2.7,1.3-4.5,1.3c-1.3,0-2.4-0.2-3.3-0.7s-1.7-1.1-2.3-1.8\n      c-0.6-0.8-0.8-1.6-0.8-2.5h3.8c0,0.7,0.3,1.3,0.8,1.6s1.1,0.6,2,0.6c0.8,0,1.3-0.1,1.7-0.4C107.5,15.3,107.7,14.9,107.7,14.5z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M121,18.9c-2.2,0-4-0.7-5.3-2c-1.4-1.3-2.1-3.1-2.1-5.4v-0.4c0-1.5,0.3-2.8,0.9-4c0.6-1.2,1.4-2.1,2.5-2.7\n      c1.1-0.6,2.3-1,3.6-1c2,0,3.6,0.6,4.8,1.9s1.8,3.1,1.8,5.5v1.6h-9.5c0.1,1,0.5,1.8,1.2,2.3c0.6,0.6,1.5,0.9,2.5,0.9\n      c1.5,0,2.7-0.6,3.6-1.7l2,2.2c-0.6,0.8-1.4,1.5-2.4,2C123.3,18.6,122.2,18.9,121,18.9z M120.5,6.6c-0.8,0-1.4,0.3-1.9,0.8\n      c-0.5,0.5-0.8,1.3-0.9,2.3h5.5V9.4c0-0.9-0.3-1.6-0.7-2.1C122,6.8,121.4,6.6,120.5,6.6z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M135.6,15.6c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.7-0.9,0.7-1.6h3.7c0,1-0.3,1.9-0.8,2.8s-1.3,1.5-2.2,2\n      s-2,0.7-3.1,0.7c-2.1,0-3.8-0.7-5-2c-1.2-1.4-1.8-3.2-1.8-5.6v-0.3c0-2.3,0.6-4.1,1.8-5.5c1.2-1.4,2.9-2.1,5-2.1\n      c1.9,0,3.4,0.5,4.5,1.6c1.1,1.1,1.7,2.5,1.7,4.2h-3.7c0-0.8-0.3-1.4-0.7-1.9c-0.5-0.5-1.1-0.7-1.8-0.7c-0.9,0-1.6,0.3-2.1,1\n      c-0.5,0.7-0.7,1.8-0.7,3.3v0.4c0,1.5,0.2,2.6,0.7,3.3C133.9,15.3,134.6,15.6,135.6,15.6z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M153,17.1c-1,1.2-2.3,1.8-4.1,1.8c-1.6,0-2.8-0.5-3.7-1.4s-1.3-2.3-1.3-4V3.7h4v9.6c0,1.6,0.7,2.3,2.1,2.3\n      c1.4,0,2.3-0.5,2.8-1.4V3.7h4v14.9h-3.7L153,17.1z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M168.3,7.4c-0.5-0.1-1-0.1-1.4-0.1c-1.5,0-2.5,0.5-3,1.5v9.8h-4V3.7h3.8l0.1,1.8c0.8-1.4,1.9-2.1,3.3-2.1\n      c0.4,0,0.9,0.1,1.2,0.2L168.3,7.4z"
              }
            }),
            _c("path", {
              staticClass: "st0",
              attrs: {
                d:
                  "M176.8,18.9c-2.2,0-4-0.7-5.3-2c-1.4-1.3-2.1-3.1-2.1-5.4v-0.4c0-1.5,0.3-2.8,0.9-4c0.6-1.2,1.4-2.1,2.5-2.7\n      c1.1-0.6,2.3-1,3.6-1c2,0,3.6,0.6,4.8,1.9s1.8,3.1,1.8,5.5v1.6h-9.5c0.1,1,0.5,1.8,1.2,2.3c0.6,0.6,1.5,0.9,2.5,0.9\n      c1.5,0,2.7-0.6,3.6-1.7l2,2.2c-0.6,0.8-1.4,1.5-2.4,2C179.2,18.6,178,18.9,176.8,18.9z M176.3,6.6c-0.8,0-1.4,0.3-1.9,0.8\n      c-0.5,0.5-0.8,1.3-0.9,2.3h5.5V9.4c0-0.9-0.3-1.6-0.7-2.1C177.8,6.8,177.2,6.6,176.3,6.6z"
              }
            })
          ])
        ]
      ),
      _c("span", [
        _vm._v(
          " ©" +
            _vm._s(new Date().getFullYear()) +
            " Zinc | All rights reserved. "
        ),
        _c(
          "a",
          {
            staticClass: "pl-2",
            attrs: {
              href: "https://zinc.tech/terms-of-service/",
              target: "_blank"
            }
          },
          [_vm._v("Terms of Service")]
        ),
        _c(
          "a",
          {
            staticClass: "pl-2",
            attrs: {
              href: "https://zinc.tech/privacy-policy/",
              target: "_blank"
            }
          },
          [_vm._v("Privacy Policy")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }