<template>
  <v-card flat color="transparent" max-width="600" width="100%">
    <template v-if="!success">
      <v-flex xs12 sm12>
        <div class="text-xs-center mb-4">
          <div class="display-1 black--text font-weight-bold">{{ totalAmount | currency }}</div>
          <div class="black--text font-weight-bold mb-2">
            <template v-if="contractType == 'deposit'">Total Deposits</template>
            <template v-if="contractType == 'fee'">Total Fees</template>
          </div>
        </div>
        <v-card class="z-card py-3">
          <v-list>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <img src="@/assets/ach-withdraw.svg" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Credit Card Pre-authorization</v-list-tile-title>
                <v-list-tile-sub-title class="body-1">Funds will be hold for no more than 30 days.</v-list-tile-sub-title>
                <!--
                <v-list-tile-sub-title class="body-1">
                  <span class="primary--text">{{
                    preauthProcessingFee | currency
                  }}</span>
                  Convenience Fee
                </v-list-tile-sub-title>
                -->
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
        <div class="px-3 mb-4" style>
          <v-card
            class="z-card indigo darken-4 white--text"
            style="border-top-left-radius:0;border-top-right-radius:0"
          >
            <v-card-text>
              <v-layout align-center align-content-center>
                <img src="@/assets/attention.svg" height="26" class="mr-3" />
                <v-flex>
                  Credit card pre-authorization is only available for short term
                  deposits. Funds will be hold for no more than 30 days.
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </div>

        <v-card class="z-card mb-5">
          <template v-for="(item, index) in application.transactions">
            <template v-if="isCCAvailable(item)">
              <v-layout :key="item.id" row ma-3>
                <v-flex>
                  <div>{{ item.feeName }}</div>
                  <div class="grey--text body-1 mb-2">Description</div>
                </v-flex>
                <v-flex text-xs-right>
                  <div class="indigo--text text--darken-4">
                    {{
                    (item.feeRateType == 'variable'
                    ? variableSetup[item.id].amount
                    : item.amount) | currency
                    }}
                  </div>
                  <div class="grey--text body-1">Amount to hold</div>
                </v-flex>
              </v-layout>
              <v-divider :key="index"></v-divider>
            </template>
          </template>
          <!-- Processing Fee -->
          <!--
          <v-layout row ma-3>
            <v-flex>
              <div>Convenience Fee</div>
              <div class="grey--text body-1 mb-2">Description</div>
            </v-flex>
            <v-flex text-xs-right>
              <div class="indigo--text text--darken-4">{{ preauthProcessingFee | currency }}</div>
              <div class="grey--text body-1">Amount to pay</div>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          -->
          <v-layout row ma-3>
            <v-flex>
              <div>
                <strong>Total</strong>
              </div>
            </v-flex>
            <v-flex text-xs-right>
              <div>
                <strong>{{ totalAmount | currency }}</strong>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-form id="form-card" ref="form" @submit.prevent="makePayment">
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.cardNumber"
            outline
            label="Card Number"
            mask="####  ####  ####  ####"
            box
            :rules="rules.cardNumber"
            :autofocus="$isDesktop()"
            @input="detectCardType"
          >
            <template v-slot:append>
              <img height="22" :src="imgCardType" alt />
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.cardFirstName"
            outline
            label="Card First Name"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.cardLastName"
            outline
            label="Card Last Name"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>

        <v-layout>
          <v-flex xs6 sm6 md4>
            <v-text-field
              v-model="form.cardExpiryDate"
              outline
              placeholder="MM / YY"
              label="Expiry Date"
              mask="## / ##"
              name="expirydate"
              :rules="rules.cardExpiryDate"
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs6 sm6 md3 ml-2>
            <v-text-field
              v-model="form.cardCVV"
              outline
              label="CVV"
              name="cvv"
              type="number"
              box
              :rules="rules.cardCVV"
              maxlength="3"
            >
              <template v-slot:append>
                <v-menu
                  v-model="popoverCVV"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-btn class="v-btn--very-small" color="primary" icon v-on="on">
                      <v-icon size="14">priority_high</v-icon>
                    </v-btn>
                  </template>
                  <v-card max-width="290" color="white body-1 grey--text text--darken-2">
                    <v-card-text class="pb-0">
                      The CVV/CVC code (Card Verification Value/Code) is located
                      on the back of your credit/debit card on the right side of
                      the white signature strip; it is always the last 3 digits
                      in case of VISA and MasterCard.
                      <img
                        width="260"
                        src="@/assets/cvv-location.png"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        flat
                        depressed
                        color="primary"
                        text
                        @click="popoverCVV = false"
                      >{{ $t('ok') }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
        <h3 class="primary--text subheading font-weight-regular mb-3">Billing Address</h3>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.addressLine1"
            outline
            single-line
            label="Street address"
            name="address"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.addressLine2"
            outline
            single-line
            label="Apartment, suite. etc (optional)"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.city"
            outline
            label="City"
            name="city"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.state"
            outline
            label="State"
            name="state"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.postalCode"
            outline
            label="Zip code"
            name="zip"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12>
          <v-text-field
            v-model="form.country"
            outline
            label="Country"
            name="country"
            box
            :rules="rules.required"
          ></v-text-field>
        </v-flex>
      </v-form>
      <v-flex
        body-1
        mb-4
        grey--text
        text--darken-1
      >I hereby authorize Zinc LLC (“Zinc”), its parent, affiliates and their respective agents and their assignees to initiate, depending on the payment method I select, a single or recurring electronic debit entry/entries to my designated checking, savings account or credit card (“Account”) at my designated financial institution or credit issuer (“Financial Institution”) for which I am an authorized user, as well as any Account or Financial Institution I later designate, for the single or recurring transactions I authorize. I acknowledge that the origination of electronic debits to my Account must be permitted by my Financial Institution, which must be located in the United States. I understand that my authorization will remain in full force and effect until Zinc has received written notification from me at least 3 business days prior to my scheduled transfer to terminate this authorization. If I am using a credit card to conduct a transaction, an authorization hold will be secured on the credit card provided, to cover the deposits or charges and any fees that may be incurred. These funds will not be available for your use. Additional authorizations (holds) will be obtained if I authorize subsequent transactions using a credit card. We use Dwolla for payment processing and do not store any credit card information. Dwolla is a trusted, Level 1 PCI Service Provider.</v-flex>
      <v-flex xs12 mb-4>
        <z-sign id="form-sign" ref="sign" @change="signChange"></z-sign>
      </v-flex>

      <v-btn
        color="primary"
        dark
        depressed
        block
        large
        class="text-uppercase mb-5"
        @click="makePayment"
      >{{ $t('pay') }}</v-btn>
    </template>

    <z-success-pay
      :amount="totalAmount"
      :description="payDescription"
      title="Your payment has been successfully processed."
      subtitle="Soon you will get and email with all the transaction details."
      v-if="success"
    ></z-success-pay>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import CreditCardType from 'credit-card-type'
import ZSign from '@/components/Sign'
import ZSuccessPay from '@/components/checkout/SuccessPay'
import Validator from '@/libs/validator'
import Alerts from '@/mixin/Alerts'

export default {
  props: {
    application: Object,
    contractType: String,
    variableSetup: Object,
  },
  data() {
    const validator = new Validator()
    return {
      success: false,
      popoverCVV: false,
      cardType: null,
      validCardTypes: ['visa', 'mastercard'],
      form: {
        cardNumber: null,
        cardFirstName: null,
        cardLastName: null,
        cardExpiryDate: null,
        cardCVV: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
      },
      sign: {
        hasSign: false,
        signerAgree: null,
        signerName: null,
        signature: null,
      },
      rules: {
        required: [validator.rules.required()],
        cardNumber: [validator.rules.cardNumberPotentially()],
        cardExpiryDate: [validator.rules.cardExpiryDatePotentially()],
        cardCVV: [validator.rules.cardCVVPotentially()],
      },
    }
  },
  mixins: [Alerts],
  components: { 'z-sign': ZSign, 'z-success-pay': ZSuccessPay },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    imgCardType() {
      let type = this.cardType
      if (type && type.length == 1) {
        type = type[0].type
      } else {
        type = 'default'
      }
      return '/cc/' + type + '.svg'
    },
    payDescription() {
      const _this = this
      let str = ''
      if (this.application) {
        let arr = []
        let items = this.application.transactions.filter(
          item => item.contractType == _this.contractType
        )
        items.forEach(item => {
          arr.push(item.feeName)
        })
        str = arr.length ? arr.join(', ') : ''
        str += ' and Convenience Fee'
      }
      return str
    },
    totalAmount() {
      let amount = 0
      let tx = this.currentTxs
      for (let i = 0; i < tx.length; i++) {
        amount +=
          tx[i].feeRateType == 'variable'
            ? this.variableSetup[tx[i].id].amount
            : tx[i].amount
      }
      return amount
    },
    currentTxs() {
      let arr = []
      let txs = this.application.transactions
      for (let i = 0; i < txs.length; i++) {
        if (this.isCCAvailable(txs[i])) {
          arr.push(txs[i])
        }
      }
      return arr
    },
    currentTxsIds() {
      let ids = []
      this.currentTxs.forEach(item => {
        ids.push(item.id)
      })
      return ids
    },
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    isCCAvailable(item) {
      if (
        item.contractType == 'deposit' &&
        item.refundStepType == 'days' &&
        item.refundCounter < 30
      ) {
        return true
      }
      return false
    },
    checkCurrentLogin() {
      if (!this.currentUser.isApplicant) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
    detectCardType(text) {
      this.cardType = null
      if (text) {
        this.cardType = CreditCardType(text)
      }
    },
    signChange(data) {
      this.$log.info(data)
      this.sign = data
    },
    makePayment() {
      if (!this.$refs.form.validate()) {
        this.$scrollTo('#form-card', 300, { offset: -100 })
        return this.toastError(this.$t('complete_required'))
      }

      if (!this.$refs.sign.validate()) {
        this.$scrollTo('#form-sign', 300, { offset: -100 })
        return this.toastError(this.$t('complete_required'))
      }

      if (!this.validCardTypes.includes(this.cardType[0].type)) {
        this.$log.error('Credit card type: ', this.cardType[0])
        return this.toastError(
          'Credit card type is not accepted by this merchant account.'
        )
      }

      this.$http
        .post('/applicant/payments', {
          ...this.form,
          ...this.sign,
          ...{
            paymentMethod: 'card',
            applicationId: this.application.id,
            transactions: this.currentTxsIds,
            contractType: this.contractType,
            totalAmount: this.totalAmount,
            variableSetup: this.variableSetup,
          },
        })
        .then(() => {
          this.success = true
          this.$emit('success')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
    },
  },
}
</script>
