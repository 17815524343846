var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-content-space-between": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("label", { staticClass: "v-label theme--light" }, [
              _vm._v("Signature")
            ])
          ]),
          _c("v-spacer"),
          _vm.type === "draw" && _vm.hasSign
            ? _c("v-flex", { attrs: { xs6: "", "text-xs-right": "" } }, [
                _c("a", { on: { click: _vm.clearSignature } }, [
                  _vm._v("clear signature")
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c("input", {
        attrs: { type: "hidden", name: "signature" },
        domProps: { value: _vm.base64Image }
      }),
      _c(
        "div",
        {
          staticClass: "signature-container white",
          style: { "max-width": _vm.containerMaxWisth }
        },
        [
          _c("input", {
            ref: "signatureInput",
            attrs: { type: "hidden", name: "signature" }
          }),
          _c("div", { staticClass: "main-actions" }, [
            _c("div", [
              _c(
                "button",
                {
                  class: { active: _vm.type === "auto", tab: true },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.type = "auto"
                    }
                  }
                },
                [_vm._v("Auto")]
              ),
              _c(
                "button",
                {
                  class: { active: _vm.type === "draw", tab: true },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.type = "draw"
                    }
                  }
                },
                [_vm._v("Draw")]
              ),
              _vm.enableLoad
                ? _c(
                    "button",
                    {
                      class: { active: _vm.type === "load", tab: true },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.type = "load"
                        }
                      }
                    },
                    [_vm._v("Load")]
                  )
                : _vm._e()
            ])
          ]),
          _vm.type === "auto"
            ? _c(
                "div",
                { staticClass: "auto", style: { height: _vm.containerHeight } },
                [
                  _c("VueAutoSignature", {
                    ref: "autoSignature",
                    attrs: { signatureTextProp: _vm.signatureText },
                    on: { onEnd: _vm.onEnd }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.type === "draw"
            ? _c(
                "div",
                { staticClass: "draw", style: { height: _vm.containerHeight } },
                [
                  _c("VueSignaturePad", {
                    ref: "signaturePad",
                    style: "border: 2px dashed #eee!important;margin-left:5px;",
                    attrs: {
                      height: "130px",
                      width: "455px",
                      options: { onBegin: _vm.onBegin, onEnd: _vm.onEnd }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.enableLoad && _vm.type === "load"
            ? _c(
                "div",
                { staticClass: "load", style: { height: _vm.containerHeight } },
                [
                  _c("VueLoadSignature", {
                    ref: "loadSignature",
                    attrs: { height: _vm.height },
                    on: { onEnd: _vm.onEnd }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }