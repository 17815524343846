<template>
  <v-card flat color="transparent" max-width="500" width="100%">
    <div class="text-xs-center pt-4 mb-4">
      <div class="display-1 black--text font-weight-bold">{{ totalAmount | currency }}</div>
      <div class="black--text font-weight-bold mb-2">
        <template v-if="contractType=='deposit'">Total Deposits</template>
        <template v-if="contractType=='fee'">Total Fees</template>
      </div>
      <div class="font-weight-bold grey--text text--darken-1">Choose your payment method</div>
    </div>
    <h3 class="title mb-3 black--text">MY WALLET</h3>
    <div class="wallet">
      <v-card
        class="z-card pointer"
        color="primary white--text"
        :class="{ selected: paymentMethod == 'ach' }"
        v-if="!paymentMethod || paymentMethod == 'ach'"
        v-ripple="true"
        @click="togglePayMethod('ach')"
      >
        <v-layout fill-height py-2>
          <v-flex text-xs-center xs4>
            <img src="../../assets/wallet-ach.svg" />
          </v-flex>
          <v-flex xs8 pt-2>Bank ACH Payment</v-flex>
        </v-layout>
      </v-card>
      <v-card
        class="z-card pointer"
        color="white"
        :class="{ selected: paymentMethod == 'preauth' }"
        v-if="allowCC"
        v-ripple="{'class':'grey--text'}"
        @click="togglePayMethod('preauth')"
      >
        <v-layout fill-height py-2>
          <v-flex text-xs-center xs4>
            <img src="../../assets/wallet-preauth.svg" />
          </v-flex>
          <v-flex xs8 pt-2>Credit Card Pre-authorization</v-flex>
        </v-layout>
      </v-card>
      <v-card
        class="z-card pointer"
        color="green white--text"
        :class="{ selected: paymentMethod == 'loan' }"
        v-if="allowLoan && !paymentMethod || paymentMethod == 'loan'"
        v-ripple="{'class':'white--text'}"
        @click="togglePayMethod('loan')"
      >
        <v-layout fill-height py-2>
          <v-flex text-xs-center xs4>
            <img src="../../assets/wallet-loan.svg" />
          </v-flex>
          <v-flex xs8 pt-0>
            <div>No money, No problem</div>
            <div class="body-1">Personal Loan</div>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card
        class="z-card pointer"
        color="black white--text"
        :class="{ selected: paymentMethod == 'apay' }"
        v-if="allowApay &&!paymentMethod || paymentMethod == 'apay'"
        @click="togglePayMethod('apay')"
      >
        <v-layout fill-height py-2>
          <v-flex text-xs-center xs4 pt-2>
            <img src="../../assets/wallet-apay.svg" />
          </v-flex>
          <v-flex xs8 pt-2>Faster and easier</v-flex>
        </v-layout>
      </v-card>
      <v-card
        class="z-card pointer"
        color="white"
        :class="{ selected: paymentMethod == 'gpay' }"
        v-if="allowGpay &&!paymentMethod || paymentMethod == 'gpay'"
        @click="togglePayMethod('gpay')"
      >
        <v-layout>
          <v-flex text-xs-center xs4 pt-2>
            <img src="../../assets/wallet-gpay.svg" />
          </v-flex>
          <v-flex xs8 pt-2>A better way to pay</v-flex>
        </v-layout>
      </v-card>
      <v-card color="transparent" class="pa-0 elevation-0">
        <img src="../../assets/wallet.svg" style="width:100%;" />
      </v-card>
    </div>
  </v-card>
</template>
<script>
export default {
  props: { application: Object, contractType: String, totalAmount: Number },
  data() {
    return {
      paymentMethod: null,
      allowLoan: false,
      allowGpay: false,
      allowApay: false,
    }
  },
  computed: {
    allowCC() {
      if (this.contractType == 'deposit') {
        let tx = this.application.transactions
        for (let i = 0; i < tx.length; i++) {
          let item = tx[i]
          if (this.isCCAvailable(item)) {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {
    isCCAvailable(item) {
      if (
        item.contractType == 'deposit' &&
        item.refundStepType == 'days' &&
        item.refundCounter < 30
      ) {
        return true
      }
      return false
    },
    togglePayMethod(method) {
      if (this.paymentMethod == method) {
        this.paymentMethod = null
      } else {
        this.paymentMethod = method
      }
      this.$emit('change', this.paymentMethod)
    },
  },
}
</script>