var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-content",
        { staticClass: "login-background" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "pt-5": "",
                "pb-5": ""
              }
            },
            [
              _c("z-logo", {
                staticClass: "logo-zinc",
                attrs: { color: "#ffffff", slogan: "" }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "px-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: { "max-width": "500px", flat: "" }
                    },
                    [
                      _vm.step == "code" || _vm.step == "password"
                        ? _c(
                            "v-card-title",
                            {
                              staticClass:
                                "headline indigo--text text--darken-2 font-weight-bold text-lowercase"
                            },
                            [_vm._v(_vm._s(_vm.$t("create_your_account")))]
                          )
                        : _vm._e(),
                      _vm.step == "cellPhone"
                        ? [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "headline indigo--text text--darken-2 font-weight-bold text-lowercase"
                              },
                              [_vm._v(_vm._s(_vm.$t("enter_your_code")))]
                            )
                          ]
                        : _vm._e(),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                icon: "false",
                                outline: "",
                                value: _vm.errorMessage,
                                type: "error"
                              }
                            },
                            [_vm._v(_vm._s(_vm.errorMessage))]
                          ),
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.step == "success"
                              ? _c(
                                  "div",
                                  { staticClass: "text-xs-center py-4" },
                                  [
                                    _c("img", {
                                      staticClass: "mb-4",
                                      attrs: {
                                        src: require("@/assets/account-created.svg")
                                      }
                                    }),
                                    _c("h3", { staticClass: "heading" }, [
                                      _vm._v("Congratulations!")
                                    ]),
                                    _c("div", { staticClass: "heading mb-3" }, [
                                      _vm._v(
                                        "Your account has been successfully created."
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "grey--text mb-3" },
                                      [
                                        _vm._v(
                                          " You will redirected to Zinc in " +
                                            _vm._s(_vm.successIntervalSeconds) +
                                            " seconds. "
                                        ),
                                        _c("br"),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "primary-text mr-1",
                                            attrs: { to: "/login", tag: "a" }
                                          },
                                          [_vm._v("Click here")]
                                        ),
                                        _vm._v("to get started now. ")
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm.step == "code"
                            ? _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.validateCode($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "heading indigo--text text--darken-2 mb-2"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("enter_access_code"))
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("access_code"),
                                      "background-color": "#F1F5F7",
                                      box: "",
                                      flat: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.verifyCode,
                                      callback: function($$v) {
                                        _vm.verifyCode = $$v
                                      },
                                      expression: "verifyCode"
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        block: "",
                                        large: "",
                                        depressed: "",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("validate")))]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.step == "password"
                            ? _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.createAccount($event)
                                    }
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: _vm.newType,
                                      "append-icon": _vm.newIcon,
                                      label: _vm.$t("new_password"),
                                      autofocus: _vm.$isDesktop(),
                                      box: "",
                                      "background-color": "#F1F5F7",
                                      flat: "",
                                      autocomplete: "off"
                                    },
                                    on: { "click:append": _vm.toggleNew },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  }),
                                  _c("z-password-strength-meter", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      strengthMeterOnly: true,
                                      toggle: true,
                                      secureLength: _vm.minPasswordLength
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      "append-icon": _vm.confirmIcon,
                                      type: _vm.confirmType,
                                      label: _vm.$t("confirm_password"),
                                      rules: _vm.rules.confirmPassword,
                                      required: "",
                                      box: "",
                                      "background-color": "#F1F5F7",
                                      flat: "",
                                      autocomplete: "off"
                                    },
                                    on: { "click:append": _vm.toggleConfirm },
                                    model: {
                                      value: _vm.confirmPassword,
                                      callback: function($$v) {
                                        _vm.confirmPassword = $$v
                                      },
                                      expression: "confirmPassword"
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        block: "",
                                        large: "",
                                        depressed: "",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("create_account")))]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.step == "cellPhone"
                            ? _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.verifyPhone($event)
                                    }
                                  }
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.$t("justsentsms_explain"))
                                    )
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("security_code"),
                                      box: "",
                                      "background-color": "#F1F5F7",
                                      flat: "",
                                      name: "securityCode",
                                      autocomplete: "off"
                                    },
                                    model: {
                                      value: _vm.securityCode,
                                      callback: function($$v) {
                                        _vm.securityCode = $$v
                                      },
                                      expression: "securityCode"
                                    }
                                  }),
                                  _c("p", { staticClass: "grey--text" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("phoneno")) +
                                        ": +" +
                                        _vm._s(_vm.dialCode) +
                                        _vm._s(_vm.cellPhone)
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        block: "",
                                        large: "",
                                        depressed: "",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("verify")))]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c("z-footer", { attrs: { dark: true } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }