var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm10: "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: { "py-2": "", "text-xs-right": "", "grey--text": "" }
                },
                [
                  _c("div", [
                    _vm._v(
                      "Application ID: " + _vm._s(_vm.application.tevId) + " "
                    )
                  ]),
                  _c("z-status-chip", {
                    attrs: {
                      status: _vm.application.status,
                      label: _vm.$t(_vm.application.status)
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "z-card mb-4" },
                [
                  _c("img", {
                    staticClass: "z-seal",
                    attrs: { src: require("@/assets/verified.svg") }
                  }),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "transparent pa-0",
                          attrs: { "two-line": "" }
                        },
                        [
                          _c(
                            "v-list-tile",
                            { staticClass: "pt-3" },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.application.applicantName)
                                      )
                                    ])
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Applicant")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.application.propertyName))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Property")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.application.unitNumber))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Unit Number")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(_vm.application.applicationType)
                                    )
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Type of Application")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("moment")(
                                          _vm.application.moveInDate,
                                          "MM/DD/YYYY"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Move in Date")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(_vm.application.moveInAddress)
                                    )
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Move in Address")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.application.monthlyRent))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Monthly Rent")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.application.cellPhone))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Mobile Phone")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            { staticClass: "pb-3" },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.application.email))
                                  ]),
                                  _c("v-list-tile-sub-title", [_vm._v("Email")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "h3",
                {
                  staticClass: "text-uppercase grey--text text--lighten-1 mb-3"
                },
                [_vm._v("Payment Details")]
              ),
              _vm._l(_vm.application.payments, function(payment) {
                return [
                  _c(
                    "div",
                    { key: payment.id },
                    [
                      _c(
                        "v-card",
                        { staticClass: "z-card mb-4 pa-0" },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "transparent pa-0",
                                  attrs: { "two-line": "" }
                                },
                                [
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _c("strong", [
                                              _vm._v(_vm._s(payment.feeName))
                                            ])
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Description")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c(
                                            "v-list-tile-title",
                                            [
                                              payment.contractType == "deposit"
                                                ? [_vm._v("Refundable")]
                                                : [_vm._v("Non-Refundable")]
                                            ],
                                            2
                                          ),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Type of Fee")
                                          ])
                                        ],
                                        1
                                      ),
                                      payment.contractType == "deposit"
                                        ? _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "grey white--text z-filter-chip"
                                                },
                                                [
                                                  _vm._v(
                                                    " Set for " +
                                                      _vm._s(
                                                        payment.refundCounter
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        payment.refundStepType
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  !_vm.currentUser.isApplicant
                                    ? _c(
                                        "v-list-tile",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push(
                                                "/" +
                                                  _vm.currentUser.accountType +
                                                  "/properties/" +
                                                  _vm.propertyId +
                                                  "/banks/" +
                                                  payment.bankId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c(
                                                "v-list-tile-title",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        payment.bankAlias
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Bank account")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    ripple: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [_vm._v("chevron_right")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.currentUser.isApplicant
                                    ? _c("v-divider")
                                    : _vm._e(),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("moment")(
                                                  payment.submittedAt,
                                                  "MM/DD/YYYY"
                                                )
                                              )
                                            )
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Date Collected")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-tile-action")
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  payment.contractType == "deposit"
                                    ? _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      payment.refundedAt,
                                                      "MM/DD/YYYY"
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Refund Date")
                                              ])
                                            ],
                                            1
                                          ),
                                          payment.refundedAt &&
                                          (payment.status == "completed" ||
                                            payment.status == "pending")
                                            ? _c(
                                                "v-list-tile-action",
                                                [
                                                  _c("countdown", {
                                                    attrs: {
                                                      time:
                                                        new Date(
                                                          payment.refundedAt
                                                        ).getTime() -
                                                        new Date().getTime()
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(props) {
                                                            return [
                                                              _c("v-layout", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          props.days
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "body-1 light-green--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "days"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          props.hours
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "body-1 light-green--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "hours"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          props.minutes
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "body-1 light-green--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "minutes"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: require("@/assets/schedule.svg"),
                                                                    height: "22"
                                                                  }
                                                                })
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-divider"),
                              !_vm.currentUser.isApplicant &&
                              payment.status == "completed" &&
                              payment.contractType == "deposit"
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "white--text pa-3",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { sm6: "", xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "button-extend mb-3 white--text",
                                              attrs: {
                                                block: "",
                                                depressed: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.createExtension(
                                                    payment
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Extend Smart Contract")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { sm6: "", xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "button-incident mb-3 white--text",
                                              attrs: {
                                                block: "",
                                                depressed: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.createIncident(
                                                    payment
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Document and Incident")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.currentUser.isApplicant &&
                              payment.status == "pending"
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "white--text pa-3",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { sm6: "", xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "button-extend mb-3 white--text",
                                              attrs: {
                                                to: "/applicant",
                                                block: "",
                                                depressed: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("Make payments")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              payment.status !== "refunded" &&
                              payment.status !== "refund sent"
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "white--text pa-3",
                                      class: _vm.statusClass(payment.status),
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-uppercase body-1"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(payment.status))
                                          ])
                                        ]
                                      ),
                                      _c("v-spacer"),
                                      payment.amount > 0
                                        ? _c(
                                            "strong",
                                            { staticClass: "title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    payment.amount
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              payment.status === "refunded" ||
                              payment.status === "refund sent"
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "white--text pa-3",
                                      class: _vm.statusClass("completed"),
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-uppercase body-1"
                                        },
                                        [_c("strong", [_vm._v("Funded")])]
                                      ),
                                      _c("v-spacer"),
                                      payment.amount > 0
                                        ? _c(
                                            "strong",
                                            { staticClass: "title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    payment.amount
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.events && _vm.events[payment.id]
                                ? _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "text-uppercase grey--text text--lighten-1 title-events"
                                    },
                                    [_vm._v("Events")]
                                  )
                                : _vm._e(),
                              _vm.events && _vm.events[payment.id]
                                ? _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-data-table", {
                                            staticClass:
                                              "evtInfoTable elevation-2",
                                            attrs: {
                                              headers: _vm.headersEvents,
                                              "disable-initial-sort": "",
                                              "hide-actions": "",
                                              "hide-default-header":
                                                _vm.isMobile,
                                              "hide-headers": _vm.isMobile,
                                              items: _vm.events[payment.id]
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "items",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _vm.isMobile
                                                        ? _c(
                                                            "v-list-tile",
                                                            {
                                                              staticClass:
                                                                "table-mobile",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleClickEvent(
                                                                    item,
                                                                    payment
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-tile-content",
                                                                [
                                                                  _c(
                                                                    "v-list-tile-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.name
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-tile-sub-title",
                                                                    {
                                                                      staticClass:
                                                                        "caption"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.date
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-tile-content",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-right"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-tile-title",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                          class: [
                                                                            item.amount >
                                                                            0
                                                                              ? "success--text"
                                                                              : ""
                                                                          ]
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "currency"
                                                                              )(
                                                                                item.amount
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-tile-sub-title",
                                                                    {
                                                                      staticClass:
                                                                        "caption"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "z-status-chip",
                                                                        {
                                                                          attrs: {
                                                                            status:
                                                                              item.status,
                                                                            label:
                                                                              item.status
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-tile-action",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        icon:
                                                                          "",
                                                                        ripple:
                                                                          "",
                                                                        color:
                                                                          "primary"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "white--text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "chevron_right"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.isMobile
                                                        ? _c("v-divider")
                                                        : _vm._e(),
                                                      !_vm.isMobile
                                                        ? _c(
                                                            "tr",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleClickEvent(
                                                                    item,
                                                                    payment
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.date
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "z-status-chip",
                                                                    {
                                                                      attrs: {
                                                                        status:
                                                                          item.status,
                                                                        label:
                                                                          item.status
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("td", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold body-1",
                                                                    class: [
                                                                      item.amount >
                                                                      0
                                                                        ? "success--text"
                                                                        : ""
                                                                    ]
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "currency"
                                                                        )(
                                                                          item.amount
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        icon:
                                                                          "",
                                                                        ripple:
                                                                          "",
                                                                        color:
                                                                          "primary"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "white--text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "chevron_right"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.events && _vm.events[payment.id]
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "current-balance white--text pa-3",
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-uppercase body-1"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Current Balance")
                                          ])
                                        ]
                                      ),
                                      _c("v-spacer"),
                                      _c("strong", { staticClass: "title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(payment.balance)
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              payment.status === "refunded" ||
                              payment.status === "refund sent"
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass: "white--text pa-3",
                                      class: _vm.statusClass(payment.status),
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-uppercase body-1"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(payment.status))
                                          ])
                                        ]
                                      ),
                                      _c("v-spacer"),
                                      payment.amount > 0
                                        ? _c(
                                            "strong",
                                            { staticClass: "title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    payment.amount
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-5 text-xs-center primary--text" },
                        [_vm._v("Payment ID: " + _vm._s(payment.id))]
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _c(
            "v-flex",
            { attrs: { x12: "", sm10: "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "zinc-box-total white--text pa-3",
                  attrs: { "align-center": "", "justify-center": "" }
                },
                [
                  !_vm.currentUser.isApplicant
                    ? _c("span", { staticClass: "title ody-1 white--text" }, [
                        _vm.application.status !== "completed"
                          ? _c("strong", [_vm._v("Total to be collected")])
                          : _vm._e(),
                        _vm.application.status === "completed"
                          ? _c("strong", [_vm._v("Total collected")])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.currentUser.isApplicant
                    ? _c("span", { staticClass: "title ody-1 white--text" }, [
                        _vm.application.status !== "completed"
                          ? _c("strong", [_vm._v("Total to be payed")])
                          : _vm._e(),
                        _vm.application.status === "completed"
                          ? _c("strong", [_vm._v("Total payed")])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "headline text-xs-right font-weight-bold"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.application.amount))
                          )
                        ]
                      ),
                      _vm.application.status !== "completed"
                        ? _vm._l(_vm.variableFees, function(item) {
                            return _c(
                              "div",
                              {
                                key: item.description,
                                staticClass:
                                  "headline text-xs-right text-lowercase body-1 one-line"
                              },
                              [_vm._v("+ " + _vm._s(item.feeName))]
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.application.step > 0 && _vm.application.step <= 4
            ? _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm12: "",
                    "text-xs-center": "",
                    "pt-5": ""
                  }
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "text-uppercase grey--text text--lighten-1 mb-3"
                    },
                    [_vm._v("Status")]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "zinc-box" },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "zinc-box__body",
                          attrs: { xs12: "", "pb-4": "", "text-xs-center": "" }
                        },
                        [
                          _c("z-progress-tx", {
                            attrs: { step: _vm.application.step }
                          }),
                          _c(
                            "h4",
                            { staticClass: "subheading font-weight-medium" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.application.steps[
                                      _vm.application.step - 1
                                    ].date,
                                    "MM/DD/YYYY"
                                  )
                                )
                              )
                            ]
                          ),
                          _c("div", { staticClass: "grey--text body-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.application.steps[_vm.application.step - 1]
                                  .text
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: {
            "align-center": "",
            "justify-center": "",
            "pb-3": "",
            "text-xs-center": ""
          }
        },
        [
          _c("v-spacer"),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-uppercase rounded mb-2 white--text",
                  attrs: { depressed: "", large: "", color: "primary" },
                  on: { click: _vm.printReport }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/print-report.svg"),
                      height: "32"
                    }
                  })
                ]
              ),
              _c("div", { staticClass: "body-1" }, [_vm._v("Print Report")])
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", "max-width": "580" },
          model: {
            value: _vm.dialogIncident,
            callback: function($$v) {
              _vm.dialogIncident = $$v
            },
            expression: "dialogIncident"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-toolbar",
                { staticClass: "transparent", attrs: { card: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "text-uppercase subheading primary--text" },
                    [_vm._v("Document An Incident")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogIncident = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "grey--text" },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Describe the incident")
                  ]),
                  _c(
                    "div",
                    { staticClass: "pa-2 white mb-3" },
                    [
                      _c("v-textarea", {
                        staticClass: "pt-0",
                        attrs: {
                          "background-color": "white",
                          placeholder: "Add text here",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.incidentText,
                          callback: function($$v) {
                            _vm.incidentText = $$v
                          },
                          expression: "incidentText"
                        }
                      })
                    ],
                    1
                  ),
                  _c("p", { staticClass: "mb-0" }, [_vm._v("Attach photos")]),
                  _c("file-pond", {
                    ref: "incidentImgs",
                    attrs: {
                      name: "incidentImgs",
                      "label-idle": _vm.labelIdleIncident,
                      "allow-multiple": true,
                      maxFiles: "3",
                      "accepted-file-types": "image/jpeg, image/png",
                      files: _vm.incidentImages
                    },
                    on: { init: _vm.handleFilePondInit }
                  }),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Add the amount to be deducted from deposit")
                  ]),
                  _c(
                    "div",
                    { staticClass: "pa-2 white mb-4" },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: {
                          placeholder: "Add amount",
                          "background-color": "white",
                          "single-line": "",
                          "hide-details": "",
                          type: "number"
                        },
                        model: {
                          value: _vm.incidentAmount,
                          callback: function($$v) {
                            _vm.incidentAmount = $$v
                          },
                          expression: "incidentAmount"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-layout",
                    { staticClass: "py-2" },
                    [
                      _c("span", { staticClass: "black--text" }, [
                        _vm._v("Current Balance")
                      ]),
                      _c("v-spacer"),
                      _c("span", [
                        _c("strong", { staticClass: "primary--text" }, [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.payment.balance))
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mb-4" }),
                  _c("p", { staticClass: "mt-3" }, [
                    _vm._v("Client will get an email with all the details.")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "pa-0",
                  staticStyle: { width: "90%", margin: "15px" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        color: "light-green",
                        large: "",
                        block: "",
                        dark: "",
                        depressed: ""
                      },
                      on: { click: _vm.validateIncident }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.dialogIncidentConfirm,
            callback: function($$v) {
              _vm.dialogIncidentConfirm = $$v
            },
            expression: "dialogIncidentConfirm"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "transparent close-toolbar",
                  attrs: { card: "" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogIncidentConfirm = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", { staticClass: "text-xs-center" }, [
                _c("img", {
                  staticClass: "mb-2",
                  attrs: { src: require("@/assets/attention.svg") }
                }),
                _c("h3", { staticClass: "mb-3 title" }, [
                  _vm._v("Confirm Incident")
                ]),
                _c("div", { staticClass: "grey--text text--darken-1" }, [
                  _vm._v(
                    " Are you sure you want to create this incident report? "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "text-xs-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogIncidentConfirm = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-divider", { staticClass: "no-line" }),
                  _c(
                    "v-btn",
                    {
                      class: { "blue--text": _vm.isLoading },
                      attrs: {
                        type: "submit",
                        depressed: "",
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading
                      },
                      on: { click: _vm.submitIncident }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", "max-width": "580" },
          model: {
            value: _vm.dialogExtend,
            callback: function($$v) {
              _vm.dialogExtend = $$v
            },
            expression: "dialogExtend"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-toolbar",
                { staticClass: "transparent", attrs: { card: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "text-uppercase subheading primary--text" },
                    [_vm._v("Extend Smart Contract")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogExtend = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "grey--text" },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Describe the reason to modify the contract date")
                  ]),
                  _c(
                    "div",
                    { staticClass: "pa-2 white mb-3" },
                    [
                      _c("v-textarea", {
                        staticClass: "pt-0",
                        attrs: {
                          "background-color": "white",
                          placeholder: "Add text here",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.extendText,
                          callback: function($$v) {
                            _vm.extendText = $$v
                          },
                          expression: "extendText"
                        }
                      })
                    ],
                    1
                  ),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Set the new refund date")
                  ]),
                  _c(
                    "div",
                    { staticClass: "pa-1" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            lazy: "",
                            transition: "scale-transition",
                            "offset-y": "",
                            "full-width": "",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          label: "New refund date",
                                          readonly: "",
                                          box: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c("img", {
                                                    attrs: {
                                                      width: "16",
                                                      height: "16",
                                                      src: require("../assets/calendar.svg"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.extendDate,
                                          callback: function($$v) {
                                            _vm.extendDate = $$v
                                          },
                                          expression: "extendDate"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.extendDateMenu,
                            callback: function($$v) {
                              _vm.extendDateMenu = $$v
                            },
                            expression: "extendDateMenu"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "no-title": "", color: "light-green" },
                            on: {
                              input: function($event) {
                                _vm.extendDateMenu = false
                              }
                            },
                            model: {
                              value: _vm.extendDate,
                              callback: function($$v) {
                                _vm.extendDate = $$v
                              },
                              expression: "extendDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Attach new contract document")
                  ]),
                  _c("file-pond", {
                    ref: "extendDoc",
                    attrs: {
                      name: "extendDoc",
                      "label-idle": _vm.labelIdleExtend,
                      "allow-multiple": false,
                      "accepted-file-types":
                        "image/jpeg, image/png, application/pdf",
                      files: _vm.extendDocument
                    },
                    on: { init: _vm.handleFilePondInit }
                  }),
                  _c("p", { staticClass: "mt-3" }, [
                    _vm._v(
                      "Client will get an email with approved date change."
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "pa-0",
                  staticStyle: { width: "90%", margin: "15px" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        color: "light-green",
                        large: "",
                        block: "",
                        dark: "",
                        depressed: ""
                      },
                      on: { click: _vm.validateExtend }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.dialogExtendConfirm,
            callback: function($$v) {
              _vm.dialogExtendConfirm = $$v
            },
            expression: "dialogExtendConfirm"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "transparent close-toolbar",
                  attrs: { card: "" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogExtendConfirm = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", { staticClass: "text-xs-center" }, [
                _c("img", {
                  staticClass: "mb-2",
                  attrs: { src: require("@/assets/attention.svg") }
                }),
                _c("h3", { staticClass: "mb-3 title" }, [
                  _vm._v("Extend Smart Contract")
                ]),
                _c("div", { staticClass: "grey--text text--darken-1" }, [
                  _vm._v(" This action cannot be undone. ")
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "text-xs-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogExtendConfirm = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-divider", { staticClass: "no-line" }),
                  _c(
                    "v-btn",
                    {
                      class: { "blue--text": _vm.isLoading },
                      attrs: {
                        type: "submit",
                        depressed: "",
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading
                      },
                      on: { click: _vm.submitExtend }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "520", height: "460" },
          model: {
            value: _vm.dialogThanks,
            callback: function($$v) {
              _vm.dialogThanks = $$v
            },
            expression: "dialogThanks"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogThanks" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogThanks = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _c("img", {
                  attrs: {
                    src: require("@/assets/emailsend.svg"),
                    height: "32"
                  }
                }),
                _c("div", { staticClass: "title" }, [_vm._v("Thank you")]),
                _vm.dialogThanksType === "incident"
                  ? _c("div", { staticClass: "subtitle" }, [
                      _vm._v(
                        "An email has been sent to the tenant with the Incident Report."
                      )
                    ])
                  : _vm._e(),
                _vm.dialogThanksType === "extension"
                  ? _c("div", { staticClass: "subtitle" }, [
                      _vm._v(
                        "An email has been sent to the tenant. Once the tenant approves new contract dates all the changes will take effect."
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "v-card-actions",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        color: "light-green",
                        large: "",
                        block: "",
                        dark: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.dialogThanks = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", "max-width": "580" },
          model: {
            value: _vm.dialogEvent,
            callback: function($$v) {
              _vm.dialogEvent = $$v
            },
            expression: "dialogEvent"
          }
        },
        [
          _vm.event
            ? _c(
                "v-card",
                { staticClass: "grey lighten-5" },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "transparent", attrs: { card: "" } },
                    [
                      _vm.event.type === "incident"
                        ? _c(
                            "v-toolbar-title",
                            {
                              staticClass:
                                "text-uppercase subheading primary--text"
                            },
                            [_vm._v("Incident Report")]
                          )
                        : _vm._e(),
                      _vm.event.type === "extension"
                        ? _c(
                            "v-toolbar-title",
                            {
                              staticClass:
                                "text-uppercase subheading primary--text"
                            },
                            [_vm._v("Extend Smart Contract")]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "grey--text text--darken-2",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialogEvent = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { attrs: { id: "card-event" } },
                    [
                      _vm.event.type === "incident"
                        ? _c("div", { staticClass: "desc-subtitle" }, [
                            _vm._v("Incident description")
                          ])
                        : _vm._e(),
                      _vm.event.type === "extension"
                        ? _c("div", { staticClass: "desc-subtitle" }, [
                            _vm._v("Description")
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "desc-title" }, [
                        _vm._v(_vm._s(_vm.event.description))
                      ]),
                      _vm.event.type === "incident"
                        ? _c(
                            "v-list",
                            {
                              staticClass: "transparent pa-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Incident date")
                                      ]),
                                      _c("v-list-tile-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.event.date,
                                              "MM/DD/YYYY"
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Status")
                                      ]),
                                      _c(
                                        "v-list-tile-title",
                                        {
                                          staticStyle: {
                                            height: "38px!important"
                                          }
                                        },
                                        [
                                          _c("z-status-chip", {
                                            attrs: {
                                              status: _vm.event.status,
                                              label: _vm.event.status
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.currentUser.isApplicant &&
                                  _vm.payment.status == "completed"
                                    ? _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                flat: "",
                                                small: "",
                                                color: "info"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.dialogDelete = true
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("delete")])],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Amount deducted")
                                      ]),
                                      _c("v-list-tile-title", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.event.amount
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.event.images && _vm.event.type === "incident"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "desc-subtitle",
                                staticStyle: { "padding-top": "13px" }
                              },
                              [_vm._v("Support pictures")]
                            ),
                            _vm.isLoading
                              ? _c(
                                  "div",
                                  { staticClass: "text-xs-center pa-5 mb-5" },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        size: 50,
                                        color: "primary",
                                        indeterminate: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isLoading && _vm.event.images.length > 0
                              ? _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _vm._l(_vm.event.images, function(image) {
                                      return [
                                        _c(
                                          "v-flex",
                                          {
                                            key: image.id,
                                            attrs: {
                                              sm4: "",
                                              xs12: "",
                                              "text-xs-center": ""
                                            }
                                          },
                                          [
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "img",
                                                  rawName: "v-img"
                                                }
                                              ],
                                              staticClass: "incident-img",
                                              attrs: { src: image.src }
                                            })
                                          ]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.isLoading && _vm.event.images.length == 0
                              ? _c("div", { staticClass: "desc-title" }, [
                                  _vm._v("No support pictures")
                                ])
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.event.type === "extension"
                        ? _c(
                            "v-list",
                            {
                              staticClass: "transparent pa-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Previous refund date")
                                      ]),
                                      _c("v-list-tile-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.event.oldDate,
                                              "MM/DD/YYYY"
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("New refund date")
                                      ]),
                                      _c("v-list-tile-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.event.newDate,
                                              "MM/DD/YYYY"
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Status")
                                      ]),
                                      _c(
                                        "v-list-tile-title",
                                        {
                                          staticStyle: {
                                            height: "38px!important"
                                          }
                                        },
                                        [
                                          _c("z-status-chip", {
                                            attrs: {
                                              status: _vm.event.status,
                                              label: _vm.event.status
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.currentUser.isApplicant &&
                                  _vm.event.status === "pending"
                                    ? _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                flat: "",
                                                small: "",
                                                color: "info"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.dialogDelete = true
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("delete")])],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.currentUser.isApplicant &&
                                  _vm.event.status === "pending"
                                    ? _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "extension-active-btn",
                                              class: {
                                                "blue--text": _vm.isLoading
                                              },
                                              attrs: {
                                                type: "submit",
                                                depressed: "",
                                                color: "primary",
                                                loading: _vm.isLoading,
                                                disabled: _vm.isLoading
                                              },
                                              on: { click: _vm.activeExtension }
                                            },
                                            [_vm._v("ACCEPT CONTRACT")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-sub-title", [
                                        _vm._v("Support document")
                                      ]),
                                      _vm.event.filename
                                        ? _c("v-list-tile-title", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  download: _vm.event.filename,
                                                  target: "_blank"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.download()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.event.filename) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.event.filename
                                        ? _c("v-list-tile-title", [
                                            _vm._v("No support document")
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "v-card-actions",
                    {
                      staticClass: "pa-0",
                      staticStyle: { width: "90%", margin: "15px" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            color: "light-green",
                            large: "",
                            block: "",
                            dark: "",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.dialogEvent = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.dialogDelete,
            callback: function($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "transparent close-toolbar",
                  attrs: { card: "" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogDelete = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", { staticClass: "text-xs-center" }, [
                _c("img", {
                  staticClass: "mb-2",
                  attrs: { src: require("@/assets/attention.svg") }
                }),
                _c("h3", { staticClass: "mb-3 title" }, [_vm._v("Delete")]),
                _vm.event && _vm.event.type === "incident"
                  ? _c("div", { staticClass: "grey--text text--darken-1" }, [
                      _vm._v(
                        " Are you sure you want to delete this incident report? "
                      )
                    ])
                  : _vm._e(),
                _vm.event && _vm.event.type === "extension"
                  ? _c("div", { staticClass: "grey--text text--darken-1" }, [
                      _vm._v(
                        " Are you sure you want to delete this smart contract extension? "
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "grey--text text--darken-1 pt-3" }, [
                  _vm._v(" This action cannot be undone. ")
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "text-xs-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogDelete = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-divider", { staticClass: "no-line" }),
                  _c(
                    "v-btn",
                    {
                      class: { "blue--text": _vm.isLoading },
                      attrs: {
                        type: "submit",
                        depressed: "",
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading
                      },
                      on: { click: _vm.submitDelete }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "390" },
          model: {
            value: _vm.dialogPending,
            callback: function($$v) {
              _vm.dialogPending = $$v
            },
            expression: "dialogPending"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "transparent close-toolbar",
                  attrs: { card: "" }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text text--darken-2",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogPending = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", { staticClass: "text-xs-center" }, [
                _c("img", {
                  staticClass: "mb-2",
                  attrs: { src: require("@/assets/attention.svg") }
                }),
                _c("h3", { staticClass: "mb-3 title" }, [
                  _vm._v("Pending Approval")
                ]),
                _c("div", { staticClass: "grey--text text--darken-1" }, [
                  _vm._v(
                    " You have new contract extension pending to approve. Please click on each Contract extended with status pending and then click on ACCEPT EXTENSION. "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                {
                  staticClass: "pa-0",
                  staticStyle: { width: "90%", margin: "15px" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        color: "light-green",
                        large: "",
                        block: "",
                        dark: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.dialogPending = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }