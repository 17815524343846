var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-content",
        { staticClass: "login-background" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "pt-5": "",
                "pb-5": ""
              }
            },
            [
              _c("z-logo", {
                staticClass: "logo-zinc",
                attrs: { color: "#ffffff", slogan: "" }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "px-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto", attrs: { "max-width": "500px" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "login-container" },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "justify-center headline indigo--text text--darken-2 font-weight-bold"
                                },
                                [_vm._v("Logout successful")]
                              ),
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "text-xs-center mb-0" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-4 grey--text text--darken-1"
                                      },
                                      [
                                        _vm._v(
                                          "You have successfully logged out."
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          color: "primary",
                                          large: "",
                                          depressed: "",
                                          to: "/login"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("signin")))]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: true } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }