<template>
  <div class="z-progress-tx">
    <v-layout align-center justify-space-around row fill-height py-4>
      <svg
        :class="{'z-progress-tx__icon-completed': step >= 1, 'z-progress-tx__icon-current': step == 1}"
        xmlns="http://www.w3.org/2000/svg"
        width="37.321"
        height="37.32"
        viewBox="0 0 37.321 37.32"
      >
        <g transform="translate(0 0)">
          <path
            class="z-progress-tx__icon z-progress-tx__icon-border"
            d="M466.376,576.864a18.66,18.66,0,1,1,18.66-18.66,18.682,18.682,0,0,1-18.66,18.66Zm0-36.491A17.831,17.831,0,1,0,484.207,558.2a17.851,17.851,0,0,0-17.831-17.831Z"
            transform="translate(-447.716 -539.544)"
          />
        </g>
        <g transform="translate(10.418 9)">
          <path
            class="z-progress-tx__icon"
            d="M473.866,567.577H460.179a1.4,1.4,0,0,1-1.4-1.4V556.2a.377.377,0,0,1,.132-.287l7.9-6.72a.377.377,0,0,1,.49,0l7.834,6.72a.376.376,0,0,1,.132.286v9.976a1.4,1.4,0,0,1-1.4,1.4Zm-14.333-11.2v9.8a.647.647,0,0,0,.646.647h13.687a.647.647,0,0,0,.646-.647v-9.8l-7.457-6.4-7.521,6.4Z"
            transform="translate(-458.78 -549.102)"
          />
          <g transform="translate(0.069 10.105)">
            <path
              class="z-progress-tx__icon"
              d="M473.87,568.2H460.183a1.4,1.4,0,0,1-1.306-.906.376.376,0,0,1,.1-.414l7.8-6.954a.376.376,0,0,1,.5,0l7.8,6.954a.379.379,0,0,1,.1.414,1.4,1.4,0,0,1-1.307.906Zm-14.16-.963a.644.644,0,0,0,.473.209H473.87a.642.642,0,0,0,.473-.209l-7.316-6.524-7.316,6.524Z"
              transform="translate(-458.853 -559.834)"
            />
          </g>
          <g transform="translate(0.069 7.382)">
            <g transform="translate(10.197)">
              <path
                class="z-progress-tx__icon"
                d="M470.06,562.508a.376.376,0,0,1-.25-.657l5.4-4.812a.377.377,0,1,1,.5.562l-5.4,4.813a.378.378,0,0,1-.251.1Z"
                transform="translate(-469.683 -556.942)"
              />
            </g>
            <path
              class="z-progress-tx__icon"
              d="M464.668,562.545a.377.377,0,0,1-.251-.1l-5.438-4.85a.377.377,0,1,1,.5-.562l5.438,4.85a.376.376,0,0,1-.25.657Z"
              transform="translate(-458.853 -556.942)"
            />
          </g>
          <g transform="translate(5.794 3.886)">
            <path
              class="z-progress-tx__icon"
              d="M469.454,556.053a.373.373,0,0,1-.266-.111l-1.806-1.8-1.806,1.8a.377.377,0,0,1-.533-.532l2.072-2.071a.377.377,0,0,1,.533,0l2.072,2.071a.377.377,0,0,1-.266.643Z"
              transform="translate(-464.933 -553.229)"
            />
            <g transform="translate(2.072 0.564)">
              <path
                class="z-progress-tx__icon"
                d="M467.511,558.293a.377.377,0,0,1-.377-.377V554.2a.377.377,0,1,1,.753,0v3.712a.377.377,0,0,1-.377.377Z"
                transform="translate(-467.134 -553.828)"
              />
            </g>
          </g>
        </g>
      </svg>
      <!--
      <svg
        :class="{'z-progress-tx__icon-completed': step >= 2, 'z-progress-tx__icon-current': step == 2}"
        xmlns="http://www.w3.org/2000/svg"
        width="36.449"
        height="36.449"
        viewBox="0 0 36.449 36.449"
      >
        <g transform="translate(0 0)">
          <path
            class="z-progress-tx__icon z-progress-tx__icon-border"
            d="M577.516,575.994a18.225,18.225,0,1,1,18.225-18.224,18.245,18.245,0,0,1-18.225,18.224Zm0-35.639a17.415,17.415,0,1,0,17.415,17.415,17.434,17.434,0,0,0-17.415-17.415Z"
            transform="translate(-559.292 -539.544)"
          />
        </g>
        <g transform="translate(10.153 8.79)">
          <g transform="translate(5.028)">
            <path
              class="z-progress-tx__icon"
              d="M581.472,551.756a.373.373,0,0,1-.219-.072l-2.4-1.761-2.482,1.677a.369.369,0,0,1-.414-.612l2.7-1.823a.366.366,0,0,1,.425.008l2.605,1.915a.369.369,0,0,1-.218.667Z"
              transform="translate(-575.8 -549.102)"
            />
          </g>
          <g transform="translate(0 5.098)">
            <path
              class="z-progress-tx__icon"
              d="M585.1,567.638H571.7a1.373,1.373,0,0,1-1.371-1.371V556.5a.37.37,0,0,1,.137-.287l1.662-1.344a.369.369,0,0,1,.464.574l-1.525,1.233v9.594a.633.633,0,0,0,.633.634h13.4a.633.633,0,0,0,.633-.634v-9.6L584.2,555.29a.369.369,0,0,1,.492-.551l1.662,1.483a.37.37,0,0,1,.123.275v9.769a1.373,1.373,0,0,1-1.371,1.371Z"
              transform="translate(-570.332 -554.646)"
            />
          </g>
          <g transform="translate(0.068 9.895)">
            <path
              class="z-progress-tx__icon"
              d="M585.111,568.057h-13.4a1.375,1.375,0,0,1-1.279-.886.368.368,0,0,1,.1-.405l7.635-6.81a.369.369,0,0,1,.492,0l7.635,6.81a.368.368,0,0,1,.1.405,1.375,1.375,0,0,1-1.279.886Zm-13.864-.942a.631.631,0,0,0,.463.2h13.4a.631.631,0,0,0,.463-.2l-7.164-6.39-7.163,6.39Z"
              transform="translate(-570.406 -559.862)"
            />
          </g>
          <g transform="translate(0.068 7.228)">
            <g transform="translate(9.985)">
              <path
                class="z-progress-tx__icon"
                d="M581.633,562.413a.369.369,0,0,1-.245-.644l5.285-4.713a.369.369,0,0,1,.491.551l-5.285,4.712a.368.368,0,0,1-.246.094Z"
                transform="translate(-581.264 -556.962)"
              />
            </g>
            <path
              class="z-progress-tx__icon"
              d="M576.1,562.449a.37.37,0,0,1-.245-.094l-5.324-4.749a.369.369,0,0,1,.491-.551l5.324,4.75a.369.369,0,0,1-.246.644Z"
              transform="translate(-570.406 -556.962)"
            />
          </g>
          <g transform="translate(1.754 1.945)">
            <path
              class="z-progress-tx__icon"
              d="M584.506,558.72a.369.369,0,0,1-.369-.369v-6.4h-11.16v6.4a.369.369,0,0,1-.738,0v-6.764a.37.37,0,0,1,.37-.37h11.9a.369.369,0,0,1,.369.37v6.764a.369.369,0,0,1-.369.369Z"
              transform="translate(-572.239 -551.218)"
            />
          </g>
          <g transform="translate(3.923 3.762)">
            <path
              class="z-progress-tx__icon"
              d="M582.489,553.931H574.6v-.738h7.892v.738Z"
              transform="translate(-574.598 -553.194)"
            />
          </g>
          <g transform="translate(3.923 5.108)">
            <path
              class="z-progress-tx__icon"
              d="M582.489,555.4H574.6v-.738h7.892v.738Z"
              transform="translate(-574.598 -554.657)"
            />
          </g>
          <g transform="translate(3.923 6.455)">
            <path
              class="z-progress-tx__icon"
              d="M582.489,556.859H574.6v-.738h7.892v.738Z"
              transform="translate(-574.598 -556.122)"
            />
          </g>
          <g transform="translate(3.923 7.801)">
            <path
              class="z-progress-tx__icon"
              d="M582.489,558.323H574.6v-.738h7.892v.738Z"
              transform="translate(-574.598 -557.585)"
            />
          </g>
          <g transform="translate(3.923 9.148)">
            <path
              class="z-progress-tx__icon"
              d="M582.489,559.788H574.6v-.738h7.892v.738Z"
              transform="translate(-574.598 -559.05)"
            />
          </g>
        </g>
      </svg>
      -->
      <svg
        :class="{'z-progress-tx__icon-completed': step >= 2, 'z-progress-tx__icon-current': step == 2}"
        xmlns="http://www.w3.org/2000/svg"
        width="36.159"
        height="36.16"
        viewBox="0 0 36.159 36.16"
      >
        <g transform="translate(9.307 11.399)">
          <path
            class="z-progress-tx__icon"
            d="M691.013,564.582a9.09,9.09,0,0,1-8.753-6.149.408.408,0,0,1,0-.247,9.3,9.3,0,0,1,17.505,0,.409.409,0,0,1,0,.247,9.09,9.09,0,0,1-8.753,6.149Zm-7.953-6.272a8.514,8.514,0,0,0,15.907,0,8.514,8.514,0,0,0-15.907,0Z"
            transform="translate(-682.241 -552.038)"
          />
          <g transform="translate(4.855 2.356)">
            <path
              class="z-progress-tx__icon"
              d="M691.48,562.453a3.921,3.921,0,0,1-3.917-3.917.4.4,0,1,1,.8,0,3.12,3.12,0,1,0,3.12-3.119.4.4,0,0,1,0-.8,3.916,3.916,0,0,1,0,7.832Z"
              transform="translate(-687.563 -554.621)"
            />
          </g>
        </g>
        <g transform="translate(0 0)">
          <path
            class="z-progress-tx__icon z-progress-tx__icon-border"
            d="M690.118,575.7a18.08,18.08,0,1,1,18.08-18.08,18.1,18.1,0,0,1-18.08,18.08Zm0-35.356a17.277,17.277,0,1,0,17.276,17.277,17.3,17.3,0,0,0-17.276-17.277Z"
            transform="translate(-672.039 -539.544)"
          />
        </g>
      </svg>
      <svg
        :class="{'z-progress-tx__icon-completed': step >= 3, 'z-progress-tx__icon-current': step == 3}"
        xmlns="http://www.w3.org/2000/svg"
        width="35.579"
        height="35.58"
        viewBox="0 0 35.579 35.58"
      >
    <g transform="translate(0 0)">
      <path
        class="z-progress-tx__icon"
        d="M914.232,575.7a18.08,18.08,0,1,1,18.08-18.079,18.1,18.1,0,0,1-18.08,18.079Zm0-35.356a17.277,17.277,0,1,0,17.276,17.276,17.3,17.3,0,0,0-17.276-17.276Z"
        transform="translate(-896.153 -539.544)"
      />
    </g>
    <g transform="translate(9.029 10.963)">
      <g transform="translate(7.203 1.496)">
        <path
          class="z-progress-tx__icon"
          d="M916.027,553.489v.38a2.2,2.2,0,0,1,.647.154,1.273,1.273,0,0,1,.468.352,1.541,1.541,0,0,1,.245.37.864.864,0,0,1,.087.349.413.413,0,0,1-.13.3.423.423,0,0,1-.311.129.438.438,0,0,1-.447-.373.8.8,0,0,0-.559-.584v1.463q.437.119.7.218a1.431,1.431,0,0,1,.463.285,1.327,1.327,0,0,1,.336.463,1.455,1.455,0,0,1,.118.589,1.563,1.563,0,0,1-.187.751,1.5,1.5,0,0,1-.555.572,1.98,1.98,0,0,1-.869.264v.876a.844.844,0,0,1-.04.3.172.172,0,0,1-.177.095.194.194,0,0,1-.178-.078.443.443,0,0,1-.052-.239v-.95a2.235,2.235,0,0,1-.719-.193,1.72,1.72,0,0,1-.514-.368,1.559,1.559,0,0,1-.3-.458,1.207,1.207,0,0,1-.1-.465.423.423,0,0,1,.131-.3.437.437,0,0,1,.328-.136.473.473,0,0,1,.269.073.375.375,0,0,1,.149.208,4.355,4.355,0,0,0,.168.442.855.855,0,0,0,.211.28.98.98,0,0,0,.379.193v-1.636a4.423,4.423,0,0,1-.788-.29,1.328,1.328,0,0,1-.512-.456,1.505,1.505,0,0,1,.187-1.752,1.721,1.721,0,0,1,1.113-.45V553.5c0-.2.075-.3.222-.3S916.027,553.3,916.027,553.489Zm-.447,2.414v-1.348a1.232,1.232,0,0,0-.461.233.656.656,0,0,0-.01.857A1.455,1.455,0,0,0,915.58,555.9Zm.447,1.044v1.541a.965.965,0,0,0,.549-.285.726.726,0,0,0,.193-.5.6.6,0,0,0-.186-.473A1.556,1.556,0,0,0,916.027,556.947Z"
          transform="translate(-913.945 -553.201)"
        />
      </g>
      <path
        class="z-progress-tx__icon"
        d="M923.785,561.8H906.416a.366.366,0,0,1-.366-.366v-9.507a.366.366,0,0,1,.366-.366h17.369a.366.366,0,0,1,.366.366v9.507a.366.366,0,0,1-.366.366Zm-17-.732h16.638v-8.776H906.782v8.776Z"
        transform="translate(-906.05 -551.561)"
      />
      <g transform="translate(2.103 1.965)">
        <path
          class="z-progress-tx__icon"
          d="M912.788,554.447h-4.067a.366.366,0,1,1,0-.732h4.067a.366.366,0,1,1,0,.732Z"
          transform="translate(-908.355 -553.715)"
        />
        <g transform="translate(9.095)">
          <path
            class="z-progress-tx__icon"
            d="M922.759,554.447H918.69a.366.366,0,1,1,0-.732h4.069a.366.366,0,0,1,0,.732Z"
            transform="translate(-918.324 -553.715)"
          />
        </g>
        <g transform="translate(0 5.577)">
          <path
            class="z-progress-tx__icon"
            d="M912.788,560.559h-4.067a.365.365,0,1,1,0-.731h4.067a.365.365,0,1,1,0,.731Z"
            transform="translate(-908.355 -559.828)"
          />
          <g transform="translate(9.095)">
            <path
              class="z-progress-tx__icon"
              d="M922.759,560.559H918.69a.365.365,0,1,1,0-.731h4.069a.365.365,0,1,1,0,.731Z"
              transform="translate(-918.324 -559.828)"
            />
          </g>
        </g>
      </g>
      <g transform="translate(0.142 11.485)">
        <path
          class="z-progress-tx__icon"
          d="M923.392,564.881h-16.82a.365.365,0,1,1,0-.731h16.82a.365.365,0,1,1,0,.731Z"
          transform="translate(-906.206 -564.15)"
        />
      </g>
      <g transform="translate(0.142 13.443)">
        <path
          class="z-progress-tx__icon"
          d="M923.392,567.028h-16.82a.366.366,0,0,1,0-.732h16.82a.366.366,0,0,1,0,.732Z"
          transform="translate(-906.206 -566.296)"
        />
      </g>
    </g> 
      </svg>
      <!--
      <svg
        :class="{'z-progress-tx__icon-completed': step >= 5, 'z-progress-tx__icon-current': step == 5}"
        xmlns="http://www.w3.org/2000/svg"
        width="36.159"
        height="36.16"
        viewBox="0 0 36.159 36.16"
      >
        <g transform="translate(0 0)">
          <path
            class="z-progress-tx__icon z-progress-tx__icon-border"
            d="M914.232,575.7a18.08,18.08,0,1,1,18.08-18.079,18.1,18.1,0,0,1-18.08,18.079Zm0-35.356a17.277,17.277,0,1,0,17.276,17.276,17.3,17.3,0,0,0-17.276-17.276Z"
            transform="translate(-896.153 -539.544)"
          />
        </g>
        <g transform="translate(9.029 10.963)">
          <g transform="translate(7.203 1.496)">
            <path
              class="z-progress-tx__icon"
              d="M916.027,553.489v.38a2.2,2.2,0,0,1,.647.154,1.273,1.273,0,0,1,.468.352,1.541,1.541,0,0,1,.245.37.864.864,0,0,1,.087.349.413.413,0,0,1-.13.3.423.423,0,0,1-.311.129.438.438,0,0,1-.447-.373.8.8,0,0,0-.559-.584v1.463q.437.119.7.218a1.431,1.431,0,0,1,.463.285,1.327,1.327,0,0,1,.336.463,1.455,1.455,0,0,1,.118.589,1.563,1.563,0,0,1-.187.751,1.5,1.5,0,0,1-.555.572,1.98,1.98,0,0,1-.869.264v.876a.844.844,0,0,1-.04.3.172.172,0,0,1-.177.095.194.194,0,0,1-.178-.078.443.443,0,0,1-.052-.239v-.95a2.235,2.235,0,0,1-.719-.193,1.72,1.72,0,0,1-.514-.368,1.559,1.559,0,0,1-.3-.458,1.207,1.207,0,0,1-.1-.465.423.423,0,0,1,.131-.3.437.437,0,0,1,.328-.136.473.473,0,0,1,.269.073.375.375,0,0,1,.149.208,4.355,4.355,0,0,0,.168.442.855.855,0,0,0,.211.28.98.98,0,0,0,.379.193v-1.636a4.423,4.423,0,0,1-.788-.29,1.328,1.328,0,0,1-.512-.456,1.505,1.505,0,0,1,.187-1.752,1.721,1.721,0,0,1,1.113-.45V553.5c0-.2.075-.3.222-.3S916.027,553.3,916.027,553.489Zm-.447,2.414v-1.348a1.232,1.232,0,0,0-.461.233.656.656,0,0,0-.01.857A1.455,1.455,0,0,0,915.58,555.9Zm.447,1.044v1.541a.965.965,0,0,0,.549-.285.726.726,0,0,0,.193-.5.6.6,0,0,0-.186-.473A1.556,1.556,0,0,0,916.027,556.947Z"
              transform="translate(-913.945 -553.201)"
            />
          </g>
          <path
            class="z-progress-tx__icon"
            d="M923.785,561.8H906.416a.366.366,0,0,1-.366-.366v-9.507a.366.366,0,0,1,.366-.366h17.369a.366.366,0,0,1,.366.366v9.507a.366.366,0,0,1-.366.366Zm-17-.732h16.638v-8.776H906.782v8.776Z"
            transform="translate(-906.05 -551.561)"
          />
          <g transform="translate(2.103 1.965)">
            <path
              class="z-progress-tx__icon"
              d="M912.788,554.447h-4.067a.366.366,0,1,1,0-.732h4.067a.366.366,0,1,1,0,.732Z"
              transform="translate(-908.355 -553.715)"
            />
            <g transform="translate(9.095)">
              <path
                class="z-progress-tx__icon"
                d="M922.759,554.447H918.69a.366.366,0,1,1,0-.732h4.069a.366.366,0,0,1,0,.732Z"
                transform="translate(-918.324 -553.715)"
              />
            </g>
            <g transform="translate(0 5.577)">
              <path
                class="z-progress-tx__icon"
                d="M912.788,560.559h-4.067a.365.365,0,1,1,0-.731h4.067a.365.365,0,1,1,0,.731Z"
                transform="translate(-908.355 -559.828)"
              />
              <g transform="translate(9.095)">
                <path
                  class="z-progress-tx__icon"
                  d="M922.759,560.559H918.69a.365.365,0,1,1,0-.731h4.069a.365.365,0,1,1,0,.731Z"
                  transform="translate(-918.324 -559.828)"
                />
              </g>
            </g>
          </g>
          <g transform="translate(0.142 11.485)">
            <path
              class="z-progress-tx__icon"
              d="M923.392,564.881h-16.82a.365.365,0,1,1,0-.731h16.82a.365.365,0,1,1,0,.731Z"
              transform="translate(-906.206 -564.15)"
            />
          </g>
          <g transform="translate(0.142 13.443)">
            <path
              class="z-progress-tx__icon"
              d="M923.392,567.028h-16.82a.366.366,0,0,1,0-.732h16.82a.366.366,0,0,1,0,.732Z"
              transform="translate(-906.206 -566.296)"
            />
          </g>
        </g>
      </svg>
      -->
      <svg
        :class="{'z-progress-tx__icon-completed': step >= 4, 'z-progress-tx__icon-current': step == 4}"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="36.16px"
        height="36.16px"
        viewBox="0 0 36.16 36.16"
        enable-background="new 0 0 36.16 36.16"
        xml:space="preserve"
      >
        <g transform="translate(0 0)">
          <path
            class="z-progress-tx__icon z-progress-tx__icon-border"
            d="M18.079,36.156c-9.985,0-18.08-8.095-18.08-18.08s8.095-18.08,18.08-18.08c9.985,0,18.081,8.095,18.081,18.08
    c0,0,0,0,0,0.001C36.148,28.058,28.059,36.145,18.079,36.156z M18.079,0.8c-9.542,0-17.278,7.734-17.278,17.276
    c0,9.541,7.734,17.277,17.276,17.277c9.542,0.001,17.278-7.734,17.278-17.275c0-0.001,0-0.001,0-0.002
    C35.341,8.54,27.615,0.813,18.079,0.8z"
          />
        </g>
        <g transform="translate(1019.584 553.095)">
          <path
            class="z-progress-tx__icon"
            d="M-1005.199-525.664c-0.125,0.001-0.245-0.052-0.331-0.145l-6.053-6.497c-0.173-0.187-0.166-0.482,0.017-0.66
    c0.183-0.179,0.472-0.17,0.646,0.016l0,0l5.698,6.115l13.038-16.247c0.156-0.2,0.445-0.229,0.643-0.065
    c0.195,0.164,0.223,0.458,0.062,0.658l-13.364,16.656c-0.084,0.103-0.207,0.165-0.338,0.17H-1005.199z"
          />
        </g>
      </svg>
    </v-layout>
    <v-layout
      align-center
      justify-space-around
      row
      fill-height
      mb-3
      :class="'z-progress-tx__bar z-progress-tx__bar-'+step"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: {
    step: Number,
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
$success-color: #8bc53f;
$error-color: #f50057;

.z-progress-tx__bar {
  background-color: #edf0f4;
  height: 16px;
  border-radius: 10px;
  position: relative;
}

.z-progress-tx__bar:before {
  background-color: $success-color;
  border-radius: 10px;
  width: calc(25% * 4);
  height: 16px;
  display: block;
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
}

.z-progress-tx__bar-1:before {
  width: calc(12.5% * 1);
}

.z-progress-tx__bar-2:before {
  width: calc(12.5% * 3);
}

.z-progress-tx__bar-3:before {
  width: calc(12.5% * 5);
}

.z-progress-tx__bar-4:before {
  width: calc(12.5% * 8);
}

.z-progress-tx__bar:after {
  background-color: $success-color;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 24px;
  height: 24px;
  display: block;
  content: ' ';
  position: absolute;
  top: -4px;
  z-index: 3;
}

.z-progress-tx__bar-1:after {
  left: calc(12.5% * 1 - 12px);
}

.z-progress-tx__bar-2:after {
  left: calc(12.5% * 3 - 12px);
}

.z-progress-tx__bar-3:after {
  left: calc(12.5% * 5 - 12px);
}

.z-progress-tx__bar-4:after {
  left: calc(12.5% * 7 - 12px);
}

.z-progress-tx__bar > div {
  background-color: #fff;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  z-index: 2;
}

.z-progress-tx__icon {
  fill: #929497;
}

.z-progress-tx__icon-completed .z-progress-tx__icon {
  fill: $success-color;
}

.z-progress-tx__icon-current {
  background-color: $success-color;
  border-radius: 50%;
  transform: scale(1.3);
}

.z-progress-tx__icon-current .z-progress-tx__icon {
  fill: #fff;
}

.z-progress-tx__icon-current .z-progress-tx__icon-border {
  fill: $success-color;
}

.z-progress-error {
  .z-progress-tx__bar:before,
  .z-progress-tx__bar:after,
  .z-progress-tx__icon-current {
    background-color: $error-color;
  }

  .z-progress-tx__bar:before {
    background-color: #929497;
  }

  .z-progress-tx__icon-completed .z-progress-tx__icon,
  .z-progress-tx__icon-current .z-progress-tx__icon-border {
    fill: #929497;
  }

  .z-progress-tx__icon-current .z-progress-tx__icon {
    fill: #fff;
  }
}
</style>