var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("New Application") } }),
      _c(
        "v-content",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "mt-4": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("application_id") + " (tevId)",
                              type: "text",
                              box: "",
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.applicationId,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "applicationId", $$v)
                              },
                              expression: "application.applicationId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("property_id") + " (tevId)",
                              type: "text",
                              box: "",
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.propertyId,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "propertyId", $$v)
                              },
                              expression: "application.propertyId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("first_name"),
                              type: "text",
                              box: "",
                              autofocus: _vm.$isDesktop(),
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "firstName", $$v)
                              },
                              expression: "application.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("last_name"),
                              type: "text",
                              box: "",
                              autofocus: _vm.$isDesktop(),
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "lastName", $$v)
                              },
                              expression: "application.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("email"),
                              type: "text",
                              box: "",
                              autofocus: _vm.$isDesktop(),
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.email,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "email", $$v)
                              },
                              expression: "application.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("cell_phone"),
                              type: "text",
                              box: "",
                              autofocus: _vm.$isDesktop(),
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.cellPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "cellPhone", $$v)
                              },
                              expression: "application.cellPhone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: [
                                { value: true, name: "Yes" },
                                { value: false, name: "No" }
                              ],
                              "item-value": "value",
                              "item-text": "name",
                              "append-icon": "expand_more",
                              label: _vm.$t("Pet"),
                              box: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.pet,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "pet", $$v)
                              },
                              expression: "application.pet"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Unit Number",
                              type: "text",
                              box: "",
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.unitNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "unitNumber", $$v)
                              },
                              expression: "application.unitNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Move In Address",
                              type: "text",
                              box: "",
                              autocomplete: "off"
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.moveInAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.application, "moveInAddress", $$v)
                              },
                              expression: "application.moveInAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: _vm.$t("Move In Date"),
                                              readonly: "",
                                              box: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function() {
                                                    return [
                                                      _c("img", {
                                                        attrs: {
                                                          width: "16",
                                                          height: "16",
                                                          src: require("../../assets/calendar.svg"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.application.moveInDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.application,
                                                  "moveInDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "application.moveInDate"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.moveInDateMenu,
                                callback: function($$v) {
                                  _vm.moveInDateMenu = $$v
                                },
                                expression: "moveInDateMenu"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "", color: "light-green" },
                                on: {
                                  input: function($event) {
                                    _vm.moveInDateMenu = false
                                  }
                                },
                                model: {
                                  value: _vm.application.moveInDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.application, "moveInDate", $$v)
                                  },
                                  expression: "application.moveInDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: _vm.$t("Move Out Date"),
                                              readonly: "",
                                              box: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function() {
                                                    return [
                                                      _c("img", {
                                                        attrs: {
                                                          width: "16",
                                                          height: "16",
                                                          src: require("../../assets/calendar.svg"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.application.moveOutDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.application,
                                                  "moveOutDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "application.moveOutDate"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.moveOutDateMenu,
                                callback: function($$v) {
                                  _vm.moveOutDateMenu = $$v
                                },
                                expression: "moveOutDateMenu"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "", color: "light-green" },
                                on: {
                                  input: function($event) {
                                    _vm.moveOutDateMenu = false
                                  }
                                },
                                model: {
                                  value: _vm.application.moveOutDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.application,
                                      "moveOutDate",
                                      $$v
                                    )
                                  },
                                  expression: "application.moveOutDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm6: _vm.application.applicationType == "lease"
                          }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.applicationTypes,
                              "append-icon": "expand_more",
                              label: "Application Type",
                              box: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.hasChanges = true
                              }
                            },
                            model: {
                              value: _vm.application.applicationType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.application,
                                  "applicationType",
                                  $$v
                                )
                              },
                              expression: "application.applicationType"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.application.applicationType == "lease"
                        ? [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm6: "" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Monthly Rent",
                                    type: "number",
                                    box: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.hasChanges = true
                                    }
                                  },
                                  model: {
                                    value: _vm.application.monthlyRent,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.application,
                                        "monthlyRent",
                                        $$v
                                      )
                                    },
                                    expression: "application.monthlyRent"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        loading: _vm.isSaving,
                        color: "light-green",
                        dark: "",
                        depressed: "",
                        block: "",
                        large: ""
                      },
                      on: { click: _vm.sendApplication }
                    },
                    [_vm._v(_vm._s(_vm.$t("create")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }