<template>
  <v-app>
    <tev-navbar />
    <v-content>
      <v-layout row wrap>
        <v-card class="transparent elevation-0 px-3 mt-5 mb-4" width="600" max-width="100%">
          <h4 class="primary--text font-weight-bold mb-3 text-uppercase">{{ $t('my_payments') }}</h4>
          <!--
          <div>
            <v-text-field
              class="box-border mb-3"
              append-icon="search"
              @click:append="newSearch"
              v-model="queryName"
              :label="$t('search')"
              type="text"
              autocomplete="off"
              hide-details
              box
              :autofocus="$isDesktop()"
            ></v-text-field>

            <div class="mb-3">
              <v-chip class="white font-weight-bold">All</v-chip>
              <v-chip class="white font-weight-bold grey--text z-filter-chip">
                <v-avatar class="primary">
                  <v-icon class="white--text">chevron_right</v-icon>
                </v-avatar>Refundable
              </v-chip>
              <v-chip class="white font-weight-bold grey--text z-filter-chip">
                <v-avatar class="grey">
                  <v-icon class="white--text">chevron_right</v-icon>
                </v-avatar>Non-refundable
              </v-chip>
              <v-chip class="white font-weight-bold grey--text z-filter-chip">
                <v-avatar class="light-green">
                  <v-icon class="white--text">done</v-icon>
                </v-avatar>Paid
              </v-chip>
              <v-chip class="white font-weight-bold grey--text z-filter-chip">
                <v-avatar class="pink accent-3">
                  <v-icon class="white--text">chevron_right</v-icon>
                </v-avatar>Processing
              </v-chip>
              <v-chip class="white font-weight-bold grey--text z-filter-chip">
                <v-avatar class="grey">
                  <v-icon class="white--text">date_range</v-icon>
                </v-avatar>Date
              </v-chip>
            </div>
          </div>
          -->
          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <template v-if="!isLoading && totalResults">
            <z-transaction
              :key="item.id"
              :payment="item"
              v-for="item in payments"
              :to="(item.status=='pending'||item.status=='failed'||item.status=='cancelled')? '/applicant':'/applicant/applications/'+item.applicationId"
            ></z-transaction>
          </template>
          <!-- property-list -->
          <template v-if="!totalResults && !isLoading">
            <h3 class="mt-2 mb-5 title">No payments.</h3>
          </template>
          <template v-if="totalResults && !isLoading">
            <div class="mt-3 text-xs-center">
              <div class="mt-3 grey--text">
                Results {{ currentPage * perPage - perPage + 1 }} -
                {{ Math.min(currentPage * perPage, totalResults) }} of
                {{ totalResults }} payments
              </div>
              <v-pagination
                v-model="currentPage"
                @input="changePage"
                :length="totalPages"
                :total-visible="6"
                circle
                v-if="totalPages > 1"
              ></v-pagination>
            </div>
          </template>
        </v-card>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import ZTransaction from '@/components/ZTransaction.vue'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Payments',
  data() {
    return {
      queryName: null,
      checkbox: false,
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: null,
      totalResults: null,
      payments: [],
    }
  },
  mixins: [Alerts],
  components: { 'z-transaction': ZTransaction },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    totalPending() {
      //TODO: total payments amount
      return 0
    },
  },
  methods: {
    stopPropagation(e) {
      e.stopPropagation()
    },
    changePage(page) {
      this.currentPage = page
      this.loadPayments()
    },
    newSearch() {
      this.currentPage = 1
      this.loadPayments()
    },
    loadPayments() {
      this.isLoading = true
      this.$http
        .get('/applicant/payments', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            status: 'all',
          },
        })
        .then(response => {
          if (response && response.data) {
            this.payments = response.data.payments
            this.totalResults = response.data.metadata.total
            this.totalPages = Math.ceil(this.totalResults / this.perPage)
            this.$scrollTo('#app')
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadPayments()
  },
}
</script>
