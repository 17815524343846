<template>
  <v-app class="page-contract-setup page-with-subnavbar">
    <tev-navbar />
    <tev-subnavbar :back="'/'  + currentUser.accountType + '/properties/' + propertyId + '/contracts'" title="Smart Contract Setup" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm8>
            <v-flex xs12 pt-3 mb-4>
              <h2 class="title" v-if="property">{{ property.name }}</h2>
              <div class="grey--text">{{ $t('property_name') }}</div>
            </v-flex>

            <!-- New Contract Form -->
            <v-form ref="form" v-on:submit.prevent="saveContract" v-if="contract.contractType">
              <v-layout row wrap class="white">
                <v-flex xs12 pa-3>
                  <h3 class="light-green--text text-uppercase mb-2">
                    <img :src="contractTypes[contract.contractType].icon" class="mr-3" />
                    {{ contractTypes[contract.contractType].name }}
                  </h3>
                  <v-divider></v-divider>
                </v-flex>
                <!-- applicationType -->
                <v-flex xs12 px-3 py-1 class="pr-sm-1">
                  <div class="mb-2">Type of application</div>
                  <v-select
                    single-line
                    multiple
                    chips
                    v-model="contract.applicationType"
                    :items="applicationTypes"
                    item-text="name"
                    item-value="id"
                    box
                    background-color="#F1F5F7"
                    flat
                    label="Select"
                    append-icon="keyboard_arrow_down"
                    required
                    :rules="rules.required"
                    @change="hasChanges = true"
                  ></v-select>
                </v-flex>
                <!-- description -->
                <v-flex xs12 px-3 py-0 class="pl-sm-1">
                  <div class="mb-2">Description</div>
                  <v-select
                    single-line
                    v-model="contract.feeItemId"
                    :items="contractFeeItems"
                    item-text="name"
                    item-value="id"
                    box
                    background-color="#F1F5F7"
                    flat
                    label="Select"
                    append-icon="keyboard_arrow_down"
                    required
                    :rules="rules.required"
                    @change="hasChanges = true"
                  ></v-select>
                </v-flex>
                <!-- feeRateType -->
                <v-flex xs12 px-3 py-0 v-if="contract.contractType == 'deposit'">
                  <div class="mb-2">Fee Rate Type</div>
                  <v-select
                    single-line
                    v-model="contract.feeRateType"
                    :items="feeRateTypes"
                    item-text="name"
                    item-value="id"
                    box
                    background-color="#F1F5F7"
                    flat
                    label="Select"
                    append-icon="keyboard_arrow_down"
                    required
                    :rules="rules.required"
                    @change="hasChanges = true"
                  ></v-select>
                </v-flex>
                <!-- setupByType -->
                <template
                  v-if="
                    contract.contractType == 'deposit' &&
                      contract.feeRateType == 'variable'
                  "
                >
                  <v-flex xs12 px-3 py-0>
                    <div class="mb-2">Set Up By</div>
                    <v-select
                      single-line
                      v-model="contract.setupByType"
                      :items="setupByTypes"
                      item-text="name"
                      item-value="id"
                      box
                      background-color="#F1F5F7"
                      flat
                      label="Select"
                      append-icon="keyboard_arrow_down"
                      required
                      :rules="rules.required"
                      @change="hasChanges = true"
                    ></v-select>
                  </v-flex>
                  <!-- unit -->
                  <template v-if="contract.setupByType">
                    <template v-for="(unit, index) in contract.units">
                      <v-flex class="primary--border" xs12 pa-3 my-3 :key="index">
                        <v-flex xs12 pb-1>
                          <v-layout align-space-between justify-space-between row fill-height>
                            <span class="primary--text pt-1 pb-2">Variable No{{ index + 1 }}</span>
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              flat
                              color="primary"
                              class="ma-0"
                              @click="contract.units.splice(index, 1)"
                              v-if="contract.units.length > 1"
                            >
                              <v-icon>close</v-icon>
                            </v-btn>
                          </v-layout>
                        </v-flex>

                        <v-text-field
                          single-line
                          v-model="contract.units[index].alias"
                          label="Variable alias"
                          box
                          background-color="#F1F5F7"
                          flat
                          autocomplete="off"
                          required
                          :rules="rules.required"
                        ></v-text-field>

                        <v-flex xs12 v-if="contract.setupByType == 'name'">
                          <v-text-field
                            single-line
                            v-model="contract.units[index].name"
                            label="Add unit type name"
                            box
                            background-color="#F1F5F7"
                            flat
                            autocomplete="off"
                            required
                            :rules="rules.required"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="contract.setupByType == 'rent'">
                          <v-text-field
                            single-line
                            v-model="contract.units[index].rent"
                            label="Add rent value"
                            box
                            background-color="#F1F5F7"
                            flat
                            type="number"
                            autocomplete="off"
                            required
                            :rules="rules.required"
                          ></v-text-field>
                        </v-flex>
                        <!-- rooms -->
                        <v-flex xs12 pr-2 v-if="contract.setupByType == 'rooms'">
                          <v-select
                            single-line
                            v-model="contract.units[index].rooms"
                            :items="roomsTypes"
                            item-text="name"
                            item-value="id"
                            box
                            background-color="#F1F5F7"
                            flat
                            label="Select No of Rooms"
                            append-icon="keyboard_arrow_down"
                            required
                            :rules="rules.required"
                          ></v-select>
                        </v-flex>
                        <!-- sqft -->
                        <template v-if="contract.setupByType == 'sqft'">
                          <v-layout row wrap>
                            <v-flex xs6 pr-3>
                              <v-text-field
                                type="number"
                                single-line
                                v-model="contract.units[index].sqftFrom"
                                label="Sqft From"
                                box
                                background-color="#F1F5F7"
                                flat
                                autocomplete="off"
                                required
                                hint="Example: 100"
                                :rules="rules.required"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 pl-3>
                              <v-text-field
                                type="number"
                                single-line
                                v-model="contract.units[index].sqftTo"
                                label="Sqft To"
                                box
                                background-color="#F1F5F7"
                                flat
                                autocomplete="off"
                                required
                                hint="Example: 200"
                                :rules="rules.required"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </template>

                        <v-flex xs12>
                          <div class="mb-2">Amount</div>
                          <v-text-field
                            single-line
                            v-model="contract.units[index].amount"
                            label="$ Add deposit amount"
                            box
                            background-color="#F1F5F7"
                            flat
                            autocomplete="off"
                            required
                            :rules="rules.required"
                          ></v-text-field>
                        </v-flex>
                      </v-flex>
                      <v-flex
                        :key="'btn'+index"
                        xs12
                        pb-2
                        text-xs-center
                        v-if="contract.units.length == index + 1"
                      >
                        <v-btn
                          type="submit"
                          round
                          depressed
                          color="primary"
                          @click="addUnitType"
                        >Add more</v-btn>
                      </v-flex>
                    </template>
                  </template>
                </template>

                <v-flex
                  xs12
                  px-3
                  py-0
                  v-if="
                    contract.contractType == 'fee' ||
                      contract.feeRateType == 'flat'
                  "
                >
                  <div class="mb-2">Amount</div>
                  <v-text-field
                    single-line
                    v-model="contract.amount"
                    label="$ Add an amount"
                    box
                    background-color="#F1F5F7"
                    flat
                    autocomplete="off"
                    required
                    :rules="rules.required"
                    @change="hasChanges = true"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 px-3 py-0>
                  <div class="mb-2">Bank Account</div>
                  <v-select
                    single-line
                    v-model="contract.bankId"
                    :items="banks"
                    item-text="alias"
                    item-value="id"
                    box
                    background-color="#F1F5F7"
                    flat
                    label="Select"
                    append-icon="keyboard_arrow_down"
                    required
                    :rules="rules.required"
                    @change="hasChanges = true"
                  ></v-select>
                </v-flex>

                <v-flex xs12 px-3 py-0 v-if="contract.contractType == 'deposit'">
                  <div class="mb-2">Refund the deposit after</div>
                  <v-layout row wrap>
                    <v-flex xs6 pr-2>
                      <v-select
                        single-line
                        v-model="contract.refundCounter"
                        :items="Array.from(Array(32).keys()).slice(1, 31)"
                        box
                        background-color="#F1F5F7"
                        flat
                        label="Select"
                        append-icon="keyboard_arrow_down"
                        required
                        :rules="rules.required"
                        @change="hasChanges = true"
                      ></v-select>
                    </v-flex>
                    <v-flex xs6>
                      <v-select
                        single-line
                        v-model="contract.refundStepType"
                        :items="refundStepTypes"
                        item-text="name"
                        item-value="id"
                        box
                        background-color="#F1F5F7"
                        flat
                        label="Select"
                        append-icon="keyboard_arrow_down"
                        required
                        :rules="rules.required"
                        @change="hasChanges = true"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12 px-3 py-0>
                  <div class="mb-2">Deployment Date</div>
                  <v-dialog
                    ref="dialogDeployDate"
                    v-model="modalDeployDate"
                    :return-value.sync="contract.deployDate"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        single-line
                        v-model="contract.deployDate"
                        label="Select"
                        background-color="#F1F5F7"
                        box
                        flat
                        readonly
                        required
                        :rules="rules.required"
                        @change="hasChanges = true"
                        v-on="on"
                      >
                        <template v-slot:append>
                          <img width="24" height="24" src="@/assets/calendar-grey.svg" />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contract.deployDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        flat
                        color="primary"
                        @click="modalDeployDate = false"
                      >{{ $t('cancel') }}</v-btn>
                      <v-btn
                        flat
                        color="primary"
                        @click="
                          $refs.dialogDeployDate.save(contract.deployDate)
                        "
                      >{{ $t('ok') }}</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-flex>

                <v-flex xs12 pa-3>
                  <v-btn
                    type="submit"
                    block
                    large
                    depressed
                    color="text-uppercase light-green white--text"
                    :loading="isSaving"
                  >{{ $t('save') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
            <!-- / New Contract Form -->
            <!-- Select Contract Type -->
            <template v-if="!contract.contractType">
              <v-flex xs12 mb-4>
                <h3>Type of Smart Contract</h3>
                <p>
                  Select the type of smart contracts you want to assign to this
                  property.
                </p>
              </v-flex>
              <v-flex xs12 mb-4>
                <v-card
                  class="z-card"
                  @click="contract.contractType = 'fee'"
                  v-ripple="{ class: 'light-green--text' }"
                >
                  <v-card-text class="pl-0">
                    <v-list single-line class="transparent">
                      <v-list-tile avatar>
                        <v-list-tile-avatar>
                          <img src="@/assets/one-time-fee.svg" class="ma-4" />
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            <h3 class="title">Move in fees</h3>
                          </v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn small icon ripple color="primary">
                            <v-icon class="white--text">add</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 mb-4>
                <v-card
                  class="z-card"
                  @click="contract.contractType = 'deposit'"
                  v-ripple="{ class: 'light-green--text' }"
                >
                  <v-card-text class="pl-0">
                    <v-list single-line class="transparent">
                      <v-list-tile avatar>
                        <v-list-tile-avatar>
                          <img src="@/assets/recurring-fee.svg" class="ma-4" />
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            <h3 class="title">Deposits</h3>
                          </v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn small icon ripple color="primary">
                            <v-icon class="white--text">add</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex x12 text-xs-center my-5>
                <a href="#">What is a Smart Contract?</a>
              </v-flex>
            </template>
            <!-- / Select Contract Type -->
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import UnsavedChanges from '../../mixin/UnsavedChanges'
import Alerts from '../../mixin/Alerts'
import Validator from '../../libs/validator'

import Icon1 from '@/assets/one-time-fee.svg'
import Icon2 from '@/assets/recurring-fee.svg'

export default {
  data() {
    const validator = new Validator()
    return {
      rules: {
        required: [validator.rules.required()],
        email: [validator.rules.email()],
      },

      isLoading: false,
      isSaving: false,
      modalDeployDate: false,
      propertyId: null,
      property: null,
      banks: [],

      contract: {
        contractType: null,
        applicationType: null,
        feeItemId: null,
        feeRateType: null,
        amount: null,
        setupByType: null,
        units: [],
        bankId: null,
        deployDate: null,
        refundCounter: null,
        refundStepType: null,
      },

      contractTypes: {
        fee: {
          icon: Icon1,
          name: 'Move in fees',
          id: 'fee',
        },
        deposit: {
          icon: Icon2,
          name: 'Deposits',
          id: 'deposit',
        },
      },

      applicationTypes: [
        {
          name: 'Lease',
          id: 'lease',
        },
        {
          name: 'Purchase',
          id: 'purchase',
        },
        {
          name: 'Guest',
          id: 'guest',
        },
      ],
      contractDescriptions: [
        {
          id: 'Moving Fee',
          name: 'Moving Fee',
        },
        {
          id: 'Peet Deposit',
          name: 'Peet Deposit',
        },
        {
          id: 'Security Deposit',
          name: 'Security Deposit',
        },
      ],
      feeRateTypes: [
        {
          id: 'flat',
          name: 'Flat Rate',
        },
        {
          id: 'variable',
          name: 'Variable Rate',
        },
      ],
      setupByTypes: [
        {
          id: 'name',
          name: 'Unit Type Name',
        },
        {
          id: 'sqft',
          name: 'Sqft',
        },
        {
          id: 'rooms',
          name: 'Number of rooms',
        },
        {
          id: 'rent',
          name: 'Montly Rent',
        },
      ],
      roomsTypes: [
        {
          id: 'studio',
          name: 'Studio',
        },
        {
          id: '1',
          name: '1 Rooms',
        },
        {
          id: '2',
          name: '2 Rooms',
        },
        {
          id: '3',
          name: '3 Rooms',
        },
        {
          id: '4',
          name: '4 Rooms',
        },
        {
          id: '5',
          name: '5 Rooms',
        },
        {
          id: '6',
          name: '6 Rooms',
        },
        {
          id: '7',
          name: '7 Rooms',
        },
      ],

      refundStepTypes: [
        {
          id: 'days',
          name: 'Days',
        },
        {
          id: 'months',
          name: 'Months',
        },
      ],
      feeItems: [],
    }
  },
  mixins: [Alerts, UnsavedChanges],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    contractFeeItems() {
      const _this = this
      return this.feeItems.filter(
        item => item.contractType == _this.contract.contractType
      )
    },
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (
        !this.currentUser ||
        !this.currentUser.isManager ||
        !localStorage.token
      ) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
    saveContract() {
      if (!this.$refs.form.validate()) {
        return
      }

      let contract = this.contract

      // applicationType
      if (
        !Array.isArray(contract.applicationType) ||
        (!contract.applicationType.includes('lease') &&
          !contract.applicationType.includes('purchase') &&
          !contract.applicationType.includes('guest'))
      ) {
        return this.toastError('Please set the application type.')
      }

      // description
      if (!contract.feeItemId) {
        return this.toastError('Please set the description.')
      }

      // feeRateType
      if (
        contract.contractType == 'deposit' &&
        contract.feeRateType != 'flat' &&
        contract.feeRateType != 'variable'
      ) {
        return this.toastError('Please set the fee rate type.')
      }

      //  contractType
      if (contract.feeRateType != 'variable') {
        // amount
        if (!contract.amount) {
          return this.toastError('Please set the amount.')
        }
      }

      // refundCounter && refundStepType
      if (this.contractType == 'deposit') {
        // setupByType
        if (['name', 'rooms', 'sqft', 'rent'].includes(contract.setupByType)) {
          return this.toastError('Please set the setup by type.')
        }

        // Units
        if (!contract.units.length) {
          return this.toastError('Please set the units type.')
        }

        // refund
        if (
          !contract.refundCounter ||
          (contract.refundStepType != 'days' &&
            contract.refundStepType != 'months')
        ) {
          return this.toastError('Please set the refund deposit.')
        }
      }

      // Banks
      if (!contract.bankId) {
        return this.toastError('Please set the bank account.')
      }

      // Deploy Date
      if (!contract.deployDate) {
        return this.toastError('Please set the deployment date.')
      }

      this.hasChanges = false
      this.isSaving = true
      this.$http
        .post('/manager/properties/' + this.propertyId + '/contracts', contract)
        .then(() => {
          this.$router.push('/' + this.currentUser.accountType + '/properties/' + this.propertyId + '/contracts')
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }          
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    addUnitType() {
      this.contract.units.push({
        alias: null,
        rooms: null,
        sqftFrom: null,
        sqftTo: null,
        amount: null,
        reference: null,
      })
    },
    loadFeesItems() {
      this.$http
        .get('/manager/fees')
        .then(response => {
          this.feeItems = response.data.fees
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
    },
    loadBanks() {
      this.$http
        .get('/manager/properties/' + this.propertyId + '/banks')
        .then(response => {
          this.banks = response.data.banks
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
    },
    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
          this.loadBanks()
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadFeesItems()
    this.loadProperty()
    this.addUnitType()
  },
}
</script>
<style>
.page-contract-setup .v-select__slot .v-chip {
  background: #fff;
}
</style>
