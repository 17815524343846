var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("my_properties") } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.isInitLoading
                ? _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        sm12: "",
                        "pa-5": "",
                        "text-xs-center": ""
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "loading",
                        attrs: {
                          src: require("../../assets/reload.svg"),
                          height: "100"
                        }
                      }),
                      _c(
                        "p",
                        { staticClass: "title grey--text font-weight-regular" },
                        [_vm._v("Synchronizing properties")]
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.isInitLoading
                ? [
                    !_vm.properties.length
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "my-5 text-xs-center",
                            attrs: { xs12: "", sm6: "" }
                          },
                          [
                            _c("h3", { staticClass: "mt-2 mb-5 title" }, [
                              _vm._v(
                                "There are no property available for your organization."
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.properties.length
                      ? [
                          _c(
                            "v-flex",
                            {
                              staticClass: "pt-3",
                              attrs: { xs12: "", sm12: "", "px-3": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "align-start": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mt-1 mr-3",
                                    attrs: {
                                      src: require("../../assets/temple.svg")
                                    }
                                  }),
                                  _c("v-flex", [
                                    _c(
                                      "div",
                                      { staticClass: "body-1 grey--text" },
                                      [_vm._v(_vm._s(_vm.$t("organization")))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "title font-weight-regular mb-4"
                                      },
                                      [_vm._v(_vm._s(_vm.organization.name))]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "heading grey--text text--darken-3 font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("org_total_importproperties", {
                                          total: _vm.properties.length
                                        })
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "heading grey--text text--darken-1"
                                },
                                [_vm._v(_vm._s(_vm.$t("import_explain")))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "pt-5": "",
                                "text-xs-center": ""
                              }
                            },
                            [
                              _c("v-divider"),
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "pa-3": "",
                                    row: "",
                                    wrap: "",
                                    "align-start": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "py-2 pl-2 white rounded border"
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "pr-0",
                                        attrs: {
                                          label: "Search by property name",
                                          type: "text",
                                          autocomplete: "off",
                                          solo: "",
                                          "hide-details": "",
                                          "background-color": "transparent",
                                          autofocus: _vm.$isDesktop()
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-fade-transition",
                                                    {
                                                      attrs: {
                                                        "leave-absolute": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-uppercase",
                                                          attrs: {
                                                            depressed: "",
                                                            dark: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.searchProperties
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("search")
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2560808167
                                        ),
                                        model: {
                                          value: _vm.name,
                                          callback: function($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-xs-right": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-uppercase caption font-weight-medium mb-3 mr-3",
                                  attrs: {
                                    round: "",
                                    depressed: "",
                                    small: "",
                                    color: "light-green",
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.importAll = !_vm.importAll
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.importAll
                                          ? _vm.$t("unselect_all")
                                          : _vm.$t("select_all")
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "mb-5",
                              attrs: { xs12: "", sm12: "" }
                            },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "white py-0 mb-4",
                                  attrs: { "two-line": "" }
                                },
                                [
                                  _c("v-divider"),
                                  _vm._l(_vm.properties, function(property) {
                                    return [
                                      _c(
                                        "v-list-tile",
                                        {
                                          key: property.id,
                                          attrs: { avatar: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleToImport(
                                                property.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c(
                                                "v-list-tile-title",
                                                {
                                                  staticClass: "text-truncate"
                                                },
                                                [_vm._v(_vm._s(property.name))]
                                              ),
                                              _c(
                                                "v-list-tile-sub-title",
                                                {
                                                  staticClass: "text-truncate"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("address")) +
                                                      " " +
                                                      _vm._s(
                                                        property.addressLine1
                                                      ) +
                                                      ", " +
                                                      _vm._s(property.city) +
                                                      " " +
                                                      _vm._s(property.zip) +
                                                      ", " +
                                                      _vm._s(property.state)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "checkbox-green",
                                                attrs: {
                                                  value:
                                                    _vm.importAll ||
                                                    _vm.toImport.includes(
                                                      property.id
                                                    ),
                                                  color: "light-green"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        key: property.id + "_divider"
                                      })
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm.loadMore
                                ? _c(
                                    "mugen-scroll",
                                    {
                                      attrs: {
                                        handler: _vm.fetchData,
                                        "should-handle": !_vm.isLoading
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-xs-center pa-5 mb-5"
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              size: 50,
                                              color: "primary",
                                              indeterminate: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-uppercase btn-import",
                                  class: {
                                    "shadow-pulse": _vm.hasToImport,
                                    "d-none": !_vm.hasToImport
                                  },
                                  attrs: {
                                    loading: _vm.isSaving,
                                    depressed: "",
                                    round: "",
                                    large: "",
                                    dark: "",
                                    color: "light-green"
                                  },
                                  on: { click: _vm.importProperties }
                                },
                                [_vm._v(_vm._s(_vm.$t("import_properties")))]
                              ),
                              _c(
                                "back-to-top",
                                { attrs: { bottom: "50px", right: "50px" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "elevation-0",
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        fixed: "",
                                        bottom: "",
                                        right: "",
                                        color: "arapawa"
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("keyboard_arrow_up")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }