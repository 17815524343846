var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-invite" },
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md9: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.inviteMember($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "align-start": "",
                                "justify-space-between": "",
                                "fill-height": "",
                                "mb-5": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: {
                                    icon: "",
                                    to:
                                      "/representative/properties/" +
                                      _vm.propertyId
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "text-xs-center pt-4" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/icon-user-green.svg"),
                                      height: "60"
                                    }
                                  }),
                                  !_vm.invitationSent
                                    ? [
                                        _c(
                                          "h3",
                                          { staticClass: "black--text" },
                                          [_vm._v(_vm._s(_vm.property.name))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-weight-regular mb-4"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("property_name"))
                                            )
                                          ]
                                        ),
                                        _vm.memberType == "manager"
                                          ? [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "Invite Property Manager to Zinc"
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm.memberType == "accountant"
                                          ? [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "Invite Accounting Admin to Zinc"
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c("z-spacer", { attrs: { width: 52 } })
                            ],
                            1
                          ),
                          _vm.invitationSent
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        sm8: "",
                                        "text-xs-center": "",
                                        subheading: "",
                                        "mb-5": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "font-weight-regular" },
                                        [
                                          _vm._v(
                                            "The invitation has been sent. New members will get an email that gives them access to Zinc."
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        sm8: "",
                                        "text-xs-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase mb-4",
                                          attrs: {
                                            color: "light-green",
                                            dark: "",
                                            depressed: "",
                                            large: "",
                                            to:
                                              "/representative/properties/" +
                                              _vm.propertyId
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("continue")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.invitationSent
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("first_name"),
                                          type: "text",
                                          box: "",
                                          autofocus: _vm.$isDesktop()
                                        },
                                        model: {
                                          value: _vm.firstName,
                                          callback: function($$v) {
                                            _vm.firstName = $$v
                                          },
                                          expression: "firstName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("last_name"),
                                          type: "text",
                                          box: ""
                                        },
                                        model: {
                                          value: _vm.lastName,
                                          callback: function($$v) {
                                            _vm.lastName = $$v
                                          },
                                          expression: "lastName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "z-callingcode-field",
                                        attrs: {
                                          label: _vm.$t("cell_phone"),
                                          type: "number",
                                          box: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend",
                                              fn: function() {
                                                return [
                                                  _c("z-callingcode-field", {
                                                    attrs: {
                                                      enabledCountryCode: true,
                                                      preferredCountries: [],
                                                      label: _vm.$t("dialcode")
                                                    },
                                                    model: {
                                                      value: _vm.callingCode,
                                                      callback: function($$v) {
                                                        _vm.callingCode = $$v
                                                      },
                                                      expression: "callingCode"
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          3810848873
                                        ),
                                        model: {
                                          value: _vm.cellPhone,
                                          callback: function($$v) {
                                            _vm.cellPhone = $$v
                                          },
                                          expression: "cellPhone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("email"),
                                          type: "email",
                                          box: ""
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  !_vm.$route.query.memberType
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.memberTypes,
                                              "item-value": "value",
                                              "item-text": "name",
                                              label: _vm.$t("member_type"),
                                              solo: ""
                                            },
                                            model: {
                                              value: _vm.memberType,
                                              callback: function($$v) {
                                                _vm.memberType = $$v
                                              },
                                              expression: "memberType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            loading: _vm.isLoading,
                                            color: "light-green",
                                            dark: "",
                                            depressed: "",
                                            block: "",
                                            large: ""
                                          },
                                          on: { click: _vm.inviteMember }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("invite")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }