<template>
  <v-card flat color="transparent" max-width="600" width="100%">
    <div class="text-xs-center mb-4">
      <div class="display-1 black--text font-weight-bold">{{ totalAmount | currency }}</div>
      <div class="black--text font-weight-bold mb-2">
        <template v-if="!property && contractType == 'deposit'">Total Deposits</template>
        <template v-if="!property && contractType == 'fee'">Total Fees</template>
        <template v-if="property">Property Setup Fee</template>
      </div>
    </div>

    <template v-if="isSaving">
      <div class="mt-3 mb-2">
        <v-progress-circular :size="45" :width="1" color="light-green" indeterminate></v-progress-circular>
      </div>
      <h3 class="black--text mb-4 font-weight-regular">One moment please</h3>
    </template>

    <template v-if="!isSaving && plaidAccounts.length">
      <h3 class="black--text mb-4 font-weight-regular">Please select your bank account</h3>

      <!-- Box Online Setup -->
      <v-card
        class="z-card mb-4 pa-3 pointer"
        :ripple="{ class: 'primary--text text--lighten-5' }"
        :key="item.id"
        v-for="item in plaidAccounts"
        @click="selectBankAccount(item)"
      >
        <v-layout align-center justify-space-between row>
          <div>
            <h3 class="primary--text subheading">{{ item.name }}</h3>
            <div class="body-1">
              <strong>ending in {{ item.mask }}</strong>
            </div>
            <div class="body-1 grey--text">{{ item.subtype }}</div>
          </div>
          <v-spacer></v-spacer>
          <img src="@/assets/checkbox-green.svg" height="24" />
        </v-layout>
      </v-card>
      <!-- / Box Online Setup -->

      <div class="mb-3 pt-2 text-xs-center">
        <p>
          You are connected to your {{ institutionName }} account.
          <a @click="openPlaid">Change Bank</a>
        </p>
      </div>
    </template>

    <template v-if="!isSaving && !plaidAccounts.length">
      <v-card class="z-card py-3 mb-4 primary">
        <v-list class="transparent white--text">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <img src="@/assets/wallet-ach.svg" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">Bank ACH Payment</v-list-tile-title>
              <v-list-tile-sub-title
                class="body-1 white--text"
              >Payment could take up to 3 business days</v-list-tile-sub-title>
              <!--
              <v-list-tile-sub-title class="body-1 white--text">
                <span class="white--text">$25.00</span> Convenience Fee
              </v-list-tile-sub-title>
              -->
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>

      <div class="text-xs-center" v-if="isLoading">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </div>

      <template v-if="!isLoading">
        <h3
          class="black--text mb-4 font-weight-regular"
        >How do you want to connect your bank account</h3>

        <v-card
          class="z-card mb-4 pa-3 pointer"
          :ripple="{ class: 'primary--text text--lighten-5' }"
          :key="item.id"
          v-for="item in bankAccounts"
          @click="
            $emit('success', {
              account: item,
              bankId: item.id,
            })
          "
        >
          <v-layout align-center justify-space-between row>
            <div>
              <h3 class="primary--text subheading">{{ item.alias }}</h3>
              <div class="body-1">
                <strong>ending in {{ item.last4 }}</strong>
              </div>
              <div class="body-1 grey--text">{{ item.bankAccountType }}</div>
              <!--div class="body-1">
                <v-btn
                  :loading="isRemoving"
                  :disabled="isRemoving"
                  color="red"
                  dark
                  depressed
                  block
                  large
                  class="text-uppercase"
                  v-on:click.stop="removeFundingSource(item)"
                >Remove</v-btn>
              </div-->
            </div>
            <v-spacer></v-spacer>
            <img src="@/assets/checkbox-green.svg" height="24" />
          </v-layout>
        </v-card>

        <!-- Box Online Setup -->
        <v-card
          class="z-card mb-4 pa-3 pointer"
          :ripple="{ class: 'primary--text text--lighten-5' }"
          @click="toggleSelected('online')"
        >
          <v-layout align-center justify-space-between row>
            <div>
              <h3 class="primary--text subheading">Using online banking credentials</h3>
              <div class="body-1">
                <strong>Immediate bank verification</strong>
              </div>

              <div class="body-1">
                Make sure you have the online user and password of the online
                banking of the property bank account.
              </div>
            </div>
            <v-spacer></v-spacer>
            <img
              src="@/assets/checkbox-green-checked.svg"
              height="24"
              v-if="setupSelected == 'online'"
            />
            <img src="@/assets/checkbox-green.svg" height="24" v-else />
          </v-layout>
        </v-card>
        <!-- / Box Online Setup -->

        <!-- Online Setup -->
        <template v-if="setupSelected == 'online'">
          <div class="mb-3 pt-2 text-xs-center">
            <v-btn
              round
              depressed
              large
              color="primary"
              class="text-uppercase mb-4 white--text"
              @click="openPlaid"
            >Sign into your bank</v-btn>
          </div>
        </template>
        <!-- / Online Setup -->
      </template>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { PLAID_ENV, PLAID_PUBLIC_KEY, PLAID_PRODUCTS } from '@/libs/constants'
import config from '@/libs/config'
import Alerts from '@/mixin/Alerts'

export default {
  props: {
    application: Object,
    totalAmount: Number,
    contractType: String,
    variableSetup: Object,
    bankSetup: Object,
    property: Object,    
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isRemoving: false,      
      allowManualSetup: false,
      setupSelected: null,
      form: {
        country: 'US',
        currency: 'USD',
        alias: null,
        bankAccountName: null,
        bankAccountType: null,
        accountNumber: null,
        routingNumber: null,
      },
      plaid: null,
      publicToken: null,
      plaidAccounts: [],
      institutionName: null,
      bankAccounts: null,
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (
        !this.currentUser || !localStorage.token ||
        !(this.currentUser.isApplicant || this.currentUser.isAccountant)        
      ) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
    toggleSelected(selected) {
      if (this.setupSelected == selected) {
        this.setupSelected = null
      } else {
        this.setupSelected = selected
      }
    },
    verifyManualSetup() {},
    loadBanks() {
      this.isLoading = true
      let url = '/applicant/banks'
      if (this.property) {
        url = '/accountant/properties/' + this.property.id + '/banks'
      }
      this.$http
        .get(url)
        .then(res => {
          this.bankAccounts = res.data.bankAccounts
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    selectBankAccount(account) {
      this.isSaving = true
      let url = '/applicant/banks'
      if (this.property) {
        url = '/accountant/properties/' + this.property.id + '/banks'
      }      
      this.$http
        .post(url, {
          publicToken: this.publicToken,
          accountId: account.id,
        })
        .then(res => {
          this.$emit('success', {
            account: account,
            bankId: res.data.id,
          })
          this.plaidAccounts = []
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    openPlaid(e) {
      e.preventDefault()
      const _this = this
      if (!window.Plaid) {
        window.location.reload(true)
        return
      }
      window.Plaid.create({
        clientName: 'Zinc',
        env: PLAID_ENV,
        key: PLAID_PUBLIC_KEY,
        product: PLAID_PRODUCTS,
        language: config.getLan(),
        userLegalName: _this.currentUser.fullName,
        userEmailAddress: _this.currentUser.email,
        onLoad() {},
        onSuccess(publicToken, metadata) {
          _this.institutionName = metadata.institution.name
          _this.publicToken = publicToken
          if (!metadata.account_id) {
            _this.plaidAccounts = metadata.accounts.filter(
              item => item.subtype == 'checking' || item.subtype == 'savings'
            )
          } else {
            _this.plaidAccounts = []
            _this.selectBankAccount(metadata.account)
          }
        },
        onExit(err, metadata) {
          _this.$log.debug(err, metadata)
        },
        onEvent(eventName, metadata) {
          _this.$log.debug(eventName, metadata)
        },
      }).open()
    },
    async removeFundingSource(account) {
      const answer = await this.$dialog.confirm({
        text: 'Are you sure you want to remove this bank account?',
        title: 'Attention',
        actions: {
          false: 'No',
          true: {
            text: 'Yes, remove it',
            color: 'primary',
          },
        },
      })

      if (!answer) {
        return
      }

      this.isRemoving = true
      let url = '/applicant/banks/'
      if (this.property) {
        url = '/accountant/properties/banks/'
      }

      this.$http
        .post(url+ account.id + '/remove')
        .then(() => {
          this.loadBanks();
          //this.$router.push('/admin/organizations/')
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isRemoving = false
        })
    },    
  },
  mounted() {
    this.loadBanks()
    if (!window.Plaid) {
      let script = document.createElement('script')
      script.async = true
      script.setAttribute(
        'src',
        'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
      )
      document.head.appendChild(script)
      script.onload = () => {
        this.plaid = window.Plaid
      }
    }
  },
}
</script>
