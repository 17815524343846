<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar title="Email Marketing" />
    <tev-subnavbar title="Send a Email" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm12 md9>
          <v-form ref="form" lazy-validation @submit.prevent="inviteMember">
            <v-container pt-5>
              <v-layout row wrap>
                <v-flex md2>
                  <v-subheader>Sender</v-subheader>
                </v-flex>
                <v-flex md10>
                  <v-text-field
                    v-model="sender"
                    :label="$t('email')"
                    type="text"
                    single-line
                    box
                    autocomplete="off"
                    @change="hasChanges=true"
                  ></v-text-field>
                </v-flex>

                <v-flex md2>
                  <v-subheader>Recipients</v-subheader>
                </v-flex>
                <v-flex md10>
                  <v-select
                    v-model="recipients"
                    :items="recipientsOpts"
                    item-text="name"
                    item-value="value"
                    single-line
                    attach
                    chips
                    box
                    label="Recipients"
                    multiple
                  ></v-select>
                </v-flex>
                <v-flex md2>
                  <v-subheader>Template</v-subheader>
                </v-flex>
                <v-flex md10>
                  <v-select
                    :items="emailTemplates"
                    single-line
                    item-text="name"
                    item-value="slug"
                    box
                    label="Standard"
                  >
                    <template v-slot:selection="{ item, selected }">
                      <v-chip>{{item.lang | uppercase }}</v-chip>
                      <span class="ml-3">{{item.name}}</span>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-chip>{{item.lang | uppercase }}</v-chip>
                      <span class="ml-3">{{item.name}}</span>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex md2>
                  <v-subheader>Subject</v-subheader>
                </v-flex>
                <v-flex md10>
                  <v-text-field
                    v-model="subject"
                    single-line
                    box
                    :label="$t('subject')"
                    type="text"
                    autocomplete="off"
                    @change="hasChanges=true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 mb-3>
                  <z-editor @change="editorChange"></z-editor>
                </v-flex>
                <v-flex xs12 sm12 mb-5>
                  <v-btn
                    :loading="isLoading"
                    color="primary"
                    dark
                    depressed
                    large
                    class="text-uppercase"
                    @click="inviteMember"
                  >Preview Email</v-btn>
                  <v-btn
                    :loading="isLoading"
                    color="primary"
                    flat
                    depressed
                    large
                    class="text-uppercase"
                    @click="inviteMember"
                  >Delay Delivery</v-btn>
                  <v-btn
                    :loading="isLoading"
                    color="primary"
                    flat
                    depressed
                    large
                    class="text-uppercase"
                    @click="inviteMember"
                  >Send Test Email</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DisableAutocomplete from '../../mixin/DisableAutocomplete'
import UnsavedChanges from '../../mixin/UnsavedChanges'
import Alerts from '../../mixin/Alerts'

import ZEditor from '../../components/ZEditor'

export default {
  title: 'Email Marketing',
  //TODO: Configurar vue-page-title con i18n
  //title: ({ $t }) => $t("invite_new_members"),
  data() {
    return {
      isLoading: false,
      search: '',
      sender: 'info@zinc.tech',
      recipients: null,
      subject: null,
      emailTemplates: null,
      recipientsOpts: [
        {
          name: 'Representatives',
          value: 'representative',
        },
        {
          name: 'Accountants',
          value: 'accountant',
        },
        {
          name: 'Managers',
          value: 'manager',
        },
        {
          name: 'Assistants',
          value: 'assistant',
        },
        {
          name: 'Properties',
          value: 'property',
        },
        {
          name: 'Applicants',
          value: 'applicant',
        },
      ],
      content: null,
    }
  },
  components: {
    ZEditor,
  },
  mixins: [DisableAutocomplete, UnsavedChanges, Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    editorChange(e) {
      this.$log.info(e)
    },
    loadEmailTemplates() {
      this.isLoading = true
      this.$http
        .get('/admin/email-templates', {
          params: {
            offset: 0,
            limit: 100,
          },
        })
        .then(response => {
          if (
            response &&
            'data' in response &&
            'emailTemplates' in response.data
          ) {
            this.emailTemplates = response.data.emailTemplates
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    inviteMember() {
      if (!this.firstName) {
        return this.toastError(this.$t('complete_required'))
      }

      this.isLoading = true
      this.$http
        .post('/admin/members', {
          firstName: this.firstName,
        })
        .then(() => {
          this.toastSuccess('Email Sent!')
          this.hasChanges = false
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadEmailTemplates()
  },
}
</script>
