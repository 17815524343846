var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "transparent elevation-0 px-3 mt-5 mb-4",
                  attrs: { width: "600", "max-width": "100%" }
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "primary--text font-weight-bold mb-3 text-uppercase"
                    },
                    [_vm._v(_vm._s(_vm.$t("my_properties")))]
                  ),
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5 mb-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading && _vm.totalResults
                    ? _vm._l(_vm.properties, function(item) {
                        return _c("ZProperty", {
                          key: item.id,
                          attrs: {
                            property: item,
                            to: "/applicant/properties/" + item.id
                          }
                        })
                      })
                    : _vm._e(),
                  !_vm.totalResults && !_vm.isLoading
                    ? [
                        _c("h3", { staticClass: "mt-2 mb-5 title" }, [
                          _vm._v("No matching properties found.")
                        ])
                      ]
                    : _vm._e(),
                  _vm.totalResults && !_vm.isLoading
                    ? [
                        _c(
                          "div",
                          { staticClass: "mt-3 grey--text text-xs-center" },
                          [
                            _vm._v(
                              " Results " +
                                _vm._s(
                                  _vm.currentPage * _vm.perPage -
                                    _vm.perPage +
                                    1
                                ) +
                                " - " +
                                _vm._s(
                                  Math.min(
                                    _vm.currentPage * _vm.perPage,
                                    _vm.totalResults
                                  )
                                ) +
                                " of " +
                                _vm._s(_vm.totalResults) +
                                " properties "
                            )
                          ]
                        ),
                        _vm.totalPages > 1
                          ? _c("v-pagination", {
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": 6,
                                circle: ""
                              },
                              on: { input: _vm.changePage },
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }