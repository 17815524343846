var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        color: "transparent",
        "max-width": "500",
        width: "100%"
      }
    },
    [
      _c("div", { staticClass: "text-xs-center pt-4 mb-4" }, [
        _c("div", { staticClass: "display-1 black--text font-weight-bold" }, [
          _vm._v(_vm._s(_vm._f("currency")(_vm.totalAmount)))
        ]),
        _c(
          "div",
          { staticClass: "black--text font-weight-bold mb-2" },
          [
            _vm.contractType == "deposit"
              ? [_vm._v("Total Deposits")]
              : _vm._e(),
            _vm.contractType == "fee" ? [_vm._v("Total Fees")] : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "font-weight-bold grey--text text--darken-1" },
          [_vm._v("Choose your payment method")]
        )
      ]),
      _c("h3", { staticClass: "title mb-3 black--text" }, [
        _vm._v("MY WALLET")
      ]),
      _c(
        "div",
        { staticClass: "wallet" },
        [
          !_vm.paymentMethod || _vm.paymentMethod == "ach"
            ? _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: true,
                      expression: "true"
                    }
                  ],
                  staticClass: "z-card pointer",
                  class: { selected: _vm.paymentMethod == "ach" },
                  attrs: { color: "primary white--text" },
                  on: {
                    click: function($event) {
                      return _vm.togglePayMethod("ach")
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "", "py-2": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "text-xs-center": "", xs4: "" } },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/wallet-ach.svg")
                            }
                          })
                        ]
                      ),
                      _c("v-flex", { attrs: { xs8: "", "pt-2": "" } }, [
                        _vm._v("Bank ACH Payment")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.allowCC
            ? _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: { class: "grey--text" },
                      expression: "{'class':'grey--text'}"
                    }
                  ],
                  staticClass: "z-card pointer",
                  class: { selected: _vm.paymentMethod == "preauth" },
                  attrs: { color: "white" },
                  on: {
                    click: function($event) {
                      return _vm.togglePayMethod("preauth")
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "", "py-2": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "text-xs-center": "", xs4: "" } },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/wallet-preauth.svg")
                            }
                          })
                        ]
                      ),
                      _c("v-flex", { attrs: { xs8: "", "pt-2": "" } }, [
                        _vm._v("Credit Card Pre-authorization")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.allowLoan && !_vm.paymentMethod) || _vm.paymentMethod == "loan"
            ? _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: { class: "white--text" },
                      expression: "{'class':'white--text'}"
                    }
                  ],
                  staticClass: "z-card pointer",
                  class: { selected: _vm.paymentMethod == "loan" },
                  attrs: { color: "green white--text" },
                  on: {
                    click: function($event) {
                      return _vm.togglePayMethod("loan")
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "", "py-2": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "text-xs-center": "", xs4: "" } },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/wallet-loan.svg")
                            }
                          })
                        ]
                      ),
                      _c("v-flex", { attrs: { xs8: "", "pt-0": "" } }, [
                        _c("div", [_vm._v("No money, No problem")]),
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v("Personal Loan")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.allowApay && !_vm.paymentMethod) || _vm.paymentMethod == "apay"
            ? _c(
                "v-card",
                {
                  staticClass: "z-card pointer",
                  class: { selected: _vm.paymentMethod == "apay" },
                  attrs: { color: "black white--text" },
                  on: {
                    click: function($event) {
                      return _vm.togglePayMethod("apay")
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "", "py-2": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: { "text-xs-center": "", xs4: "", "pt-2": "" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/wallet-apay.svg")
                            }
                          })
                        ]
                      ),
                      _c("v-flex", { attrs: { xs8: "", "pt-2": "" } }, [
                        _vm._v("Faster and easier")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.allowGpay && !_vm.paymentMethod) || _vm.paymentMethod == "gpay"
            ? _c(
                "v-card",
                {
                  staticClass: "z-card pointer",
                  class: { selected: _vm.paymentMethod == "gpay" },
                  attrs: { color: "white" },
                  on: {
                    click: function($event) {
                      return _vm.togglePayMethod("gpay")
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: { "text-xs-center": "", xs4: "", "pt-2": "" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/wallet-gpay.svg")
                            }
                          })
                        ]
                      ),
                      _c("v-flex", { attrs: { xs8: "", "pt-2": "" } }, [
                        _vm._v("A better way to pay")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            {
              staticClass: "pa-0 elevation-0",
              attrs: { color: "transparent" }
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: require("../../assets/wallet.svg") }
              })
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }