export default {
  items: {
    admin: [
      {
        title: 'organizations',
        to: '/admin/organizations',
      },
      {
        title: 'members',
        to: '/admin/members',
      },      
      {
        title: 'transactions',
        to: '/admin/transactions',
      },
      {
        title: 'login_history',
        to: '/admin/login-history',
      },
      {
        title: 'Email Templates',
        to: '/admin/email-templates',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    representative: [
      {
        title: 'organizations',
        to: '/representative/organizations',
      },
      {
        title: 'members',
        to: '/representative/members',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    accountant: [
      {
        title: 'properties',
        to: '/accountant/properties',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    manager: [
      {
        title: 'properties',
        to: '/manager/properties',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    assistant: [
      {
        title: 'properties',
        to: '/assistant/properties',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    property: [
      {
        title: 'property',
        to: '/property',
      },
      {
        title: 'applications',
        to: '/property/applications',
      },
      {
        title: 'reports',
        to: '/property/reports',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    applicant: [
      {
        title: 'home',
        to: '/applicant/home',
      },
      {
        title: 'properties',
        to: '/applicant/properties',
      },
      {
        title: 'payments',
        to: '/applicant/payments',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
    tenantev: [
      {
        title: 'Applications',
        to: '/tenantev/applications',
      },
      {
        title: 'Reports',
        to: '/tenantev/reports',
      },
      {
        dividerTop: false,
        title: 'profile',
        to: '/account',
      },
    ],
  },
}
