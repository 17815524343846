<template>
  <v-card class="z-card pa-3 text-xs-left mb-3" :to="to" v-ripple="{class: 'primary--text'}"
   @click="$router.push('/applicant/payments')">
    <v-layout row mb-2>
      <v-flex xs8>
        <div class="font-weight-bold">{{ application.firstName }} {{ application.lastName }}</div>
        <div class="grey--text body-1">Applicant</div>
      </v-flex>
      <v-flex xs4>
        <div
          class="font-weight-bold text-uppercase light-green--text body-1"
        >{{ application.status }}</div>
        <div class="grey--text body-1">Status</div>
      </v-flex>
    </v-layout>
    <v-divider class="mb-2"></v-divider>
    <v-layout row class="mb-2">
      <v-flex xs8>
        <div>{{ application.tevId }}</div>
        <div class="grey--text body-1">Application ID</div>
      </v-flex>
      <v-flex xs4>
        <div>{{ application.moveInDate | moment("MM/DD/YYYY") }}</div>
        <div class="grey--text body-1">Move In Date</div>
      </v-flex>
    </v-layout>

    <v-divider class="mb-2"></v-divider>
    <v-layout row>
      <v-flex xs8>
        <div>{{ application.moveInAddress }}</div>
        <div class="grey--text body-1">Move In Address</div>
      </v-flex>
      <v-flex xs4>
        <v-icon color="grey" size="40" style="margin:2px -4px 0 0;float:right">chevron_right</v-icon>
        <div>{{ application.unitNumber }}</div>
        <div class="grey--text body-1">Unit</div>
      </v-flex>
    </v-layout>    
  </v-card>
</template>

<script>
export default {
  props: { to: String, application: Object },
  data() {
    return {}
  },
}
</script>