<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar title />
    <tev-subnavbar :back="'/accountant/properties/' + propertyId" title="Bank Account Setup" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8 md6>
            <template v-if="selectedAccount">
              <h3
                class="black--text mb-4 font-weight-regular"
              >Make sure new bank information provided is ok before continue</h3>

              <div class="grey--text body-1">Bank Account:</div>
              <div class="mb-3">
                <strong>{{ institutionName }}</strong>
              </div>

              <div class="grey--text body-1">Account Number:</div>
              <div class="mb-3">
                <strong>ending in {{ selectedAccount.mask }}</strong>
              </div>

              <div class="grey--text body-1">Property to be assigned:</div>
              <div class="mb-3">
                <strong v-html="property.name"></strong>
              </div>

              <v-flex xs12 mb-3>
                <div class="grey--text body-1 mb-1">Bank Account Alias:</div>
                <v-text-field
                  v-model="alias"
                  single-line
                  :autofocus="$isDesktop()"
                  label="Bank Account Alias"
                  placeholder="Ex. Maintenance Account"
                  required
                  box
                ></v-text-field>
              </v-flex>

              <p>
                I hereby authorize Zinc LLC, hereinafter called Zinc to initiate
                debit entries or such adjusting entries, either credit or debit
                which are necessary for corrections, to my bank account
                indicated before and the financial institution named below to
                debit (or credit) the same to such account.
              </p>
              <p>
                This authority is to remain in full force and effect until Zinc
                LLC has received written notification from me of its termination
                in such time and in such manner as to afford Zinc LLC a
                reasonable opportunity to act on it.
              </p>

              <z-sign @change="signChange" class="mb-3"></z-sign>
              <v-btn
                :loading="isSaving"
                block
                depressed
                large
                color="light-green"
                class="text-uppercase mb-5 white--text"
                @click="saveBankAccount"
              >{{ $t('save') }}</v-btn>
            </template>
            <template v-if="!selectedAccount">
              <div>
                <strong v-html="property.name"></strong>
              </div>
              <div class="grey--text mb-4 body-1">Property Name</div>

              <template v-if="isSaving">
                <div class="mt-3 mb-2">
                  <v-progress-circular :size="45" :width="1" color="light-green" indeterminate></v-progress-circular>
                </div>
                <h3 class="black--text mb-4 font-weight-regular">One moment please</h3>
              </template>

              <template v-if="!isSaving && plaidAccounts.length">
                <img src="@/assets/bank.svg" class="d-block mt-3 mb-2" />
                <h3 class="black--text mb-4 font-weight-regular">Please select your bank account</h3>

                <!-- Box Online Setup -->
                <v-card
                  class="z-card mb-4 pa-3 pointer"
                  :ripple="{ class: 'primary--text text--lighten-5' }"
                  :key="item.id"
                  v-for="item in plaidAccounts"
                  @click="selectedAccount = item"
                >
                  <v-layout align-center justify-space-between row>
                    <div>
                      <h3 class="primary--text subheading">{{ item.name }}</h3>
                      <div class="body-1">
                        <strong>ending in {{ item.mask }}</strong>
                      </div>
                      <div class="body-1 grey--text">{{ item.subtype }}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <img src="@/assets/checkbox-green.svg" height="24" />
                  </v-layout>
                </v-card>
                <!-- / Box Online Setup -->

                <div class="mb-3 pt-2 text-xs-center">
                  <p>
                    You are connected to your {{ institutionName }} account.
                    <a
                      @click="openPlaid"
                    >Change Bank</a>
                  </p>
                </div>
              </template>
              <template v-if="!isSaving && !plaidAccounts.length">
                <img src="@/assets/bank.svg" class="d-block mt-3 mb-2" />
                <h3
                  class="black--text mb-4 font-weight-regular"
                >How do you want to connect your bank account</h3>

                <!-- Box Online Setup -->
                <v-card
                  class="z-card mb-4 pa-3 pointer"
                  :ripple="{ class: 'primary--text text--lighten-5' }"
                  @click="toggleSelected('online')"
                >
                  <v-layout align-center justify-space-between row>
                    <div>
                      <h3 class="primary--text subheading">Using online banking credentials</h3>
                      <div class="body-1">
                        <strong>Immediate bank verification</strong>
                      </div>

                      <div class="body-1">
                        Make sure you have the online user and password of the
                        online banking of the property bank account.
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <img
                      src="@/assets/checkbox-green-checked.svg"
                      height="24"
                      v-if="setupSelected == 'online' || !allowManualSetup"
                    />
                    <img src="@/assets/checkbox-green.svg" height="24" v-else />
                  </v-layout>
                </v-card>
                <!-- / Box Online Setup -->

                <!-- Online Setup -->
                <template v-if="setupSelected == 'online' || !allowManualSetup">
                  <div class="mb-3 pt-2 text-xs-center">
                    <v-btn
                      round
                      depressed
                      large
                      color="primary"
                      class="text-uppercase mb-4 white--text"
                      @click="openPlaid"
                    >Sign into your bank</v-btn>
                  </div>
                </template>
                <!-- / Online Setup -->

                <!-- Box Manual Setup -->
                <v-card
                  class="z-card mb-4 pa-3 pointer"
                  :ripple="{ class: 'primary--text text--lighten-5' }"
                  @click="toggleSelected('manual')"
                  v-if="allowManualSetup"
                >
                  <v-layout align-center justify-space-between row>
                    <div>
                      <h3 class="primary--text subheading">Bank account manual set up</h3>
                      <div class="body-1">
                        <strong>
                          Bank verification may take up to 3 business
                          days
                        </strong>
                      </div>
                      <div class="body-1">
                        Manually add the routing and account number of the
                        property bank account.
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <img
                      src="@/assets/checkbox-green-checked.svg"
                      height="24"
                      v-if="setupSelected == 'manual'"
                    />
                    <img src="@/assets/checkbox-green.svg" height="24" v-else />
                  </v-layout>
                </v-card>
                <!-- / Box Manual Setup -->

                <!-- Manual Setup -->
                <template v-if="setupSelected == 'manual'">
                  <h3 class="black--text">Connect a checking account</h3>
                  <div class="mb-4">Type the routing and account number</div>

                  <v-card class="mb-5 elevation-0">
                    <v-card-text>
                      <v-flex xs12>
                        <div class="mb-2">Bank Account Country</div>
                        <v-text-field
                          single-line
                          label="Add here"
                          box
                          background-color="#F1F5F7"
                          flat
                          autocomplete="off"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <div class="mb-2">Financial institution</div>
                        <v-text-field
                          single-line
                          label="Add here"
                          box
                          background-color="#F1F5F7"
                          flat
                          autocomplete="off"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <div class="mb-2">Routing Number</div>
                        <v-text-field
                          single-line
                          label="Add here"
                          box
                          background-color="#F1F5F7"
                          flat
                          autocomplete="off"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <div class="mb-2">Account Number</div>
                        <v-text-field
                          single-line
                          label="Add here"
                          box
                          background-color="#F1F5F7"
                          flat
                          autocomplete="off"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <div class="mb-2">Confirm Account Number</div>
                        <v-text-field
                          single-line
                          label="Add here"
                          box
                          background-color="#F1F5F7"
                          flat
                          autocomplete="off"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-card-text>
                  </v-card>
                  <v-btn
                    :loading="isSaving"
                    color="light-green"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                  >{{ $t('verify') }}</v-btn>
                </template>
                <!-- / Manual Setup -->
              </template>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import { PLAID_ENV, PLAID_PUBLIC_KEY, PLAID_PRODUCTS } from '@/libs/constants'
import config from '@/libs/config'
import Alerts from '@/mixin/Alerts'
import ZSign from '@/components/Sign'

export default {
  title: 'Bank Account Setup',
  data() {
    return {
      isSaving: false,
      propertyId: null,
      allowManualSetup: false,
      setupSelected: null,
      property: {
        name: '&nbsp;',
      },
      sign: {
        hasSign: false,
        signerAgree: null,
        signerName: null,
        signature: null,
      },
      form: {
        country: 'US',
        currency: 'USD',
        alias: null,
        bankAccountName: null,
        bankAccountType: null,
        accountNumber: null,
        routingNumber: null,
      },
      plaid: null,
      publicToken: null,
      plaidAccounts: [],
      institutionName: null,
      selectedAccount: null,
      alias: null,
    }
  },
  mixins: [Alerts],
  components: { 'z-sign': ZSign },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    signChange(data) {
      this.sign = data
    },
    toggleSelected(selected) {
      if (this.setupSelected == selected) {
        this.setupSelected = null
      } else {
        this.setupSelected = selected
      }
    },
    verifyManualSetup() {},
    selectBankAccount(account) {
      this.selectedAccount = account
    },
    saveBankAccount() {
      if (!this.sign.hasSign) {
        return
      }

      this.isSaving = true
      this.$http
        .post('/accountant/properties/' + this.propertyId + '/banks', {
          publicToken: this.publicToken,
          accountId: this.selectedAccount.id,
          signerName: this.sign.signerName,
          signature: this.sign.signature,
          alias: this.alias,
        })
        .then(() => {
          this.toastSuccess('Bank Account Added.')
          this.plaidAccounts = []
          this.$router.push('/accountant/properties/' + this.propertyId)
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    openPlaid(e) {
      e.preventDefault()
      const _this = this
      if (!window.Plaid) {
        window.location.reload(true)
        return
      }
      window.Plaid.create({
        clientName: 'Zinc',
        env: PLAID_ENV,
        key: PLAID_PUBLIC_KEY,
        product: PLAID_PRODUCTS,
        language: config.getLan(),
        userLegalName: _this.currentUser.fullName,
        userEmailAddress: _this.currentUser.email,
        onLoad() {},
        onSuccess(publicToken, metadata) {
          _this.institutionName = metadata.institution.name
          _this.publicToken = publicToken
          if (!metadata.account_id) {
            _this.plaidAccounts = metadata.accounts.filter(
              item => item.subtype == 'checking' || item.subtype == 'savings'
            )
          } else {
            _this.plaidAccounts = []
            _this.selectedAccount = metadata.account
          }
        },
        onExit(err, metadata) {
          _this.$log.debug(err, metadata)
        },
        onEvent(eventName, metadata) {
          _this.$log.debug(eventName, metadata)
        },
      }).open()
    },
    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/accountant/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/accountant/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/accountant/properties')
    }
    this.loadProperty()

    if (!window.Plaid) {
      let script = document.createElement('script')
      script.async = true
      script.setAttribute(
        'src',
        'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
      )
      document.head.appendChild(script)
      script.onload = () => {
        this.plaid = window.Plaid
      }
    }
  },
}
</script>
<style></style>
