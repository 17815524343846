<template>
  <v-app>
    <tev-navbar :title="$t('applications')" />
    <v-content>
      <v-container></v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  title: 'Applications',
  data() {
    return {}
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
}
</script>
