<template>
  <v-app>
    <tev-navbar :title="$t('New Application')" />
    <v-content>
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-container>
          <v-layout row wrap mt-4>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.applicationId"
                :label="$t('application_id') + ' (tevId)'"
                type="text"
                box
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.propertyId"
                :label="$t('property_id') + ' (tevId)'"
                type="text"
                box
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.firstName"
                :label="$t('first_name')"
                type="text"
                box
                :autofocus="$isDesktop()"
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.lastName"
                :label="$t('last_name')"
                type="text"
                box
                :autofocus="$isDesktop()"
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.email"
                :label="$t('email')"
                type="text"
                box
                :autofocus="$isDesktop()"
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.cellPhone"
                :label="$t('cell_phone')"
                type="text"
                box
                :autofocus="$isDesktop()"
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6>
              <v-select
                v-model="application.pet"
                :items="[
                  { value: true, name: 'Yes' },
                  { value: false, name: 'No' },
                ]"
                item-value="value"
                item-text="name"
                append-icon="expand_more"
                :label="$t('Pet')"
                box
                @change="hasChanges = true"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6>
              <v-text-field
                v-model="application.unitNumber"
                label="Unit Number"
                type="text"
                box
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="application.moveInAddress"
                label="Move In Address"
                type="text"
                box
                autocomplete="off"
                @change="hasChanges = true"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6>
              <v-menu
                v-model="moveInDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="application.moveInDate"
                    :label="$t('Move In Date')"
                    readonly
                    v-on="on"
                    box
                    @change="hasChanges = true"
                  >
                    <template v-slot:append>
                      <img
                        width="16"
                        height="16"
                        src="../../assets/calendar.svg"
                        alt
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="application.moveInDate"
                  no-title
                  @input="moveInDateMenu = false"
                  color="light-green"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm6>
              <v-menu
                v-model="moveOutDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="application.moveOutDate"
                    :label="$t('Move Out Date')"
                    readonly
                    v-on="on"
                    box
                    @change="hasChanges = true"
                  >
                    <template v-slot:append>
                      <img
                        width="16"
                        height="16"
                        src="../../assets/calendar.svg"
                        alt
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="application.moveOutDate"
                  no-title
                  @input="moveOutDateMenu = false"
                  color="light-green"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 :sm6="application.applicationType == 'lease'">
              <v-select
                v-model="application.applicationType"
                :items="applicationTypes"
                append-icon="expand_more"
                label="Application Type"
                box
                @change="hasChanges = true"
              ></v-select>
            </v-flex>

            <template v-if="application.applicationType == 'lease'">
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="application.monthlyRent"
                  label="Monthly Rent"
                  type="number"
                  box
                  @change="hasChanges = true"
                ></v-text-field>
              </v-flex>
            </template>
          </v-layout>
          <v-btn
            :loading="isSaving"
            color="light-green"
            dark
            depressed
            block
            large
            class="text-uppercase"
            @click="sendApplication"
            >{{ $t('create') }}</v-btn
          >
        </v-container>
      </v-form>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DisableAutocomplete from '../../mixin/DisableAutocomplete'
import UnsavedChanges from '../../mixin/UnsavedChanges'
import Alerts from '../../mixin/Alerts'

export default {
  data() {
    return {
      isSaving: false,
      application: {
        applicationId: null,
        propertyId: null,
        unitNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        cellPhone: null,
        applicationType: null,
        moveInAddress: null,
        moveInDate: null,
        moveOutDate: null,
        monthlyRent: null,
        pet: null,
      },
      birthdateMenu: false,
      moveInDateMenu: false,
      moveOutDateMenu: false,
      applicationTypes: ['lease', 'purchase', 'guest'],
    }
  },
  mixins: [DisableAutocomplete, UnsavedChanges, Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    sendApplication() {
      if (
        !this.application.firstName ||
        !this.application.lastName ||
        !this.application.email ||
        !this.application.cellPhone ||
        !this.application.applicationId ||
        !this.application.propertyId ||
        !this.application.applicationType
      ) {
        return this.toastError(this.$t('complete_required'))
      }

      this.isSaving = true
      this.$http
        .post('/tenantev/applications', this.application)
        .then(() => {
          this.toastSuccess('Application sent!')
          this.hasChanges = false
          this.$refs.form.reset()
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>
