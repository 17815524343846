<template>
  <v-app>
    <v-content class="login-background">
      <v-layout row align-center justify-center pt-5 pb-5>
        <z-logo color="#ffffff" slogan class="logo-zinc" />
      </v-layout>
      <v-layout row wrap px-4>
        <v-flex xs12>
          <v-card max-width="500px" class="mx-auto">
            <v-form ref="form" lazy-validation class="form-sm" @submit.prevent="sendPassword">
              <v-container class="login-container pa-0">
                <v-card flat>
                  <v-card-title
                    class="headline indigo--text text--darken-2 font-weight-bold"
                    v-if="!emailSent"
                  >{{ $t('forgotyourpassword') }}</v-card-title>
                  <v-card-text>
                    <transition name="fade">
                      <template v-if="emailSent">
                        <v-layout row wrap>
                          <v-flex xs12 sm12 text-xs-center>
                            <div class="email-sent-circle"></div>
                            <h4 class="email-sent-label">Email Sent!</h4>
                            <h3 class="mb-4">Check your email for a link to reset your password.</h3>
                            <p
                              class="text-muted mb-5"
                            >If it doesn’t appear within a few minutes, check your spam folder.</p>
                            <v-btn depressed large color="primary" to="/login">Return to Sign In</v-btn>
                          </v-flex>
                        </v-layout>
                      </template>
                    </transition>
                    <template v-if="!emailSent">
                      <p>Enter your email address and we will send you a link to reset your password.</p>
                      <v-layout row wrap>
                        <v-flex xs12 sm12>
                          <v-text-field
                            xs12
                            type="text"
                            v-model="email"
                            :rules="rules.email"
                            :label="$t('email')"
                            background-color="#F1F5F7"
                            required
                            box
                            :autofocus="$isDesktop()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 text-xs-center>
                          <v-btn
                            type="submit"
                            class="primary mb-3"
                            block
                            large
                            depressed
                            @click="sendPassword"
                            :loading="isLoading"
                            v-bind:class="{'blue--text': isLoading}"
                            :disabled="isLoading"
                          >{{ $t('send') }}</v-btn>
                          <v-btn block flat color="primary" to="/login">{{ $t('cancel') }}</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-form>
          </v-card>
          <z-footer :dark="true"></z-footer>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Validator from '../libs/validator'

export default {
  title: 'Forgot Password?',
  data() {
    const validator = new Validator()
    return {
      isLoading: false,
      email: null,
      emailSent: false,

      snackbar: false,
      snackbarText: '',

      rules: {
        email: [
          validator.rules.required('You must complete the required fields.'),
          validator.rules.email('Invalid email.'),
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    sendPassword() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.isLoading = true
      this.$http
        .post('/public/password/send', {
          email: this.email,
        })
        .then(() => {
          this.emailSent = true
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.$toast(err.response.data.detail, {
              color: 'red',
              timeout: 10000,
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
<style>
.email-sent-circle {
  background: #ddf0ff url(../assets/email-sent.svg) center center no-repeat;
  background-size: 56px 56px;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  margin: 20px auto;
}
.email-sent-label {
  color: #2196f3;
  margin-bottom: 60px;
  font-size: 20px;
  font-weight: 400;
}
</style>
