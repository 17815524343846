import LogRocket from 'logrocket'
import { LOGROCKET_TOKEN } from './constants'

export const setup = () => {
  if (LOGROCKET_TOKEN) {
    LogRocket.init(LOGROCKET_TOKEN)
  }
}

export const identify = profile => {
  if (LOGROCKET_TOKEN) {
    LogRocket.identify(profile.userId || 'Unknown', {
      name: profile.fullName || 'Unknown',
      email: profile.email || 'Unknown',
      accountType: profile.accountType || 'Unknown',
    })
  }
}
