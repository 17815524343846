var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        color: "transparent text-xs-center mb-2",
        "max-width": "500",
        width: "100%"
      }
    },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.hideIntro
          ? _c("div", { key: "1" }, [
              _c("h2", { staticClass: "indigo--text text--darken-4 mb-4" }, [
                _vm._v("Welcome to Zinc!")
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/figure-welcome.svg"),
                    height: "220"
                  }
                })
              ]),
              _c("div", { staticClass: "lead mb-4" }, [
                _vm._v(
                  " Zinc is a Smart Contract based platform, that allow the performance of credible transactions without third parties. These transactions are trackable and irreversible. "
                )
              ])
            ])
          : _vm._e(),
        _vm.hideIntro
          ? _c(
              "div",
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "pa-4": "", "text-xs-center": "" } },
                  [
                    _c("img", {
                      attrs: { src: require("../../assets/figure-topay.svg") }
                    }),
                    _c("div", { staticClass: "title font-weight-light mb-2" }, [
                      _vm._v(
                        " There are some fees that have to be paid before you can move into: "
                      )
                    ]),
                    _c("div", { staticClass: "title mb-3" }, [
                      _vm._v(
                        _vm._s(_vm.application.propertyName) +
                          " Unit " +
                          _vm._s(_vm.application.unitNumber)
                      )
                    ]),
                    _c("div", { staticClass: "grey--text body-1" }, [
                      _vm._v(
                        " If you have any questions about these fees please contact the property directly. "
                      )
                    ])
                  ]
                ),
                _c(
                  "v-flex",
                  { staticClass: "pa-4 text-xs-left", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "z-card mb-4" },
                      [
                        _c(
                          "div",
                          { staticClass: "pa-3" },
                          [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "text-uppercase grey--text text--lighten-1"
                              },
                              [_vm._v("Total Moving Fees & Deposits")]
                            ),
                            _c("h3", { staticClass: "primary--text" }, [
                              _vm._v(
                                _vm._s(_vm._f("currency")(_vm.totalAmount))
                              )
                            ]),
                            _vm._l(_vm.variableFees, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.description,
                                  staticClass:
                                    "primary--text text-lowercase body-1"
                                },
                                [_vm._v("+ " + _vm._s(item.feeName))]
                              )
                            })
                          ],
                          2
                        ),
                        _c("v-divider"),
                        _vm._l(_vm.application.payments, function(item) {
                          return [
                            _c(
                              "v-layout",
                              { key: item.id, attrs: { row: "", "ma-3": "" } },
                              [
                                _c("v-flex", [
                                  _c("div", [
                                    _c("strong", [_vm._v(_vm._s(item.feeName))])
                                  ]),
                                  _vm.isCCAvailable(item)
                                    ? _c(
                                        "div",
                                        { staticClass: "primary--text body-1" },
                                        [
                                          _vm._v(
                                            "Credit card pre-authorization payment available"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.isCCAvailable(item)
                                    ? _c(
                                        "div",
                                        { staticClass: "primary--text body-1" },
                                        [_vm._v("Conveniently pay with ACH")]
                                      )
                                    : _vm._e(),
                                  item.contractType == "deposit"
                                    ? _c(
                                        "div",
                                        { staticClass: "grey--text body-1" },
                                        [
                                          _vm._v(
                                            "Refund in " +
                                              _vm._s(item.refundCounter) +
                                              " " +
                                              _vm._s(item.refundStepType)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      "text-xs-right": "",
                                      "font-weight-bold": ""
                                    }
                                  },
                                  [
                                    item.feeRateType == "flat"
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(item.amount)
                                            )
                                          )
                                        ]
                                      : [_vm._v("Calculate")]
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c("v-divider", { key: "d" + item.id })
                          ]
                        }),
                        _c(
                          "v-layout",
                          { attrs: { row: "", "ma-3": "", "pb-4": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { "font-weight-bold": "" } },
                              [_vm._v("Total")]
                            ),
                            _c(
                              "v-flex",
                              { attrs: { "text-xs-right": "" } },
                              [
                                _c("div", { staticClass: "font-weight-bold" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("currency")(_vm.totalAmount))
                                  )
                                ]),
                                _vm._l(_vm.variableFees, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      key: "v" + item.id,
                                      staticClass:
                                        "grey--text text-lowercase body-1"
                                    },
                                    [_vm._v("+ " + _vm._s(item.feeName))]
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      2
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-uppercase mb-4 white--text pulse",
                        attrs: {
                          depressed: "",
                          block: "",
                          round: "",
                          large: "",
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.start()
                          }
                        }
                      },
                      [_vm._v("Let's Start")]
                    )
                  ],
                  1
                ),
                _c("z-footer", { attrs: { dark: true } })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }