<template>
  <v-chip
    class="z-status-chip v-chip--disabled text-lowercase"
    color="grey lighten-3"
    text-color="grey"
  >
    <v-avatar>
      <v-icon :color="statusColor[status]" size="10">lens</v-icon>
    </v-avatar>
    {{ label }}
  </v-chip>
</template>

<script>
export default {
  props: {
    status: String,
    label: String,
  },
  data() {
    return {
      statusColor: {
        waiting: 'orange',
        pending: 'orange',
        'not started': 'orange',     
        initial: 'orange',              
        unverified: 'yellow',
        active: 'light-green',
        completed: 'light-green',
        blocked: 'black',
        inactive: 'grey',
        freeze: 'blue',
      },
    }
  },
}
</script>
<style>
.z-status-chip .v-avatar {
  margin-right: 0;
}
</style>