var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "z-subnavar z-subnavar__fixed",
      style: "padding-left:" + _vm.computedLeft + "px"
    },
    [
      _c(
        "div",
        { staticClass: "z-subnavar__content" },
        [
          _vm.back
            ? _c(
                "v-btn",
                {
                  staticClass: "light-green--text bg-white",
                  attrs: { icon: "", to: _vm.back }
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              )
            : _vm._e(),
          _c("h3", [_vm._v(_vm._s(_vm.title))])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }