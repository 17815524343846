<template>
  <v-form ref="form">
    <p class="mb-4 grey--text">
      Please enter the property company address and contact information.
    </p>
    <SchemaForm
      v-if="formSchema"
      :schema="formSchema"
      :model="formData"
      @error="showError"
    />
    <v-btn round depressed color="primary" @click="submit">Continue</v-btn>
    <v-btn round flat @click="$emit('back')">Back</v-btn>
  </v-form>
</template>

<script>
import SchemaForm from '@/components/schemaForm/Schema.vue'
import StatesHash from '../../states_hash.json'
import Alerts from '@/mixin/Alerts'

export default {
  props: {
    propertyId: String,
    property: Object,
  },
  data() {
    return {
      formData: { country: 'United States' },
      formSchema: {
        title: '',
        type: 'object',
        required: ['address1', 'city', 'state', 'postalCode', 'phone'],
        properties: {
          address1: {
            type: 'string',
            title: 'Address Line 1',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          address2: {
            type: 'string',
            title: 'Address Line 2',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          city: {
            type: 'string',
            title: 'City',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          state: {
            type: 'object',
            title: 'State',
            enum: StatesHash,
            'x-class': 'sm6',
            'x-itemTitle': 'name',
            'x-itemKey': 'abbr',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          postalCode: {
            type: 'string',
            title: 'Zip Code',
            format: 'zip',
            'x-mask': '#####',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          country: {
            type: 'string',
            title: 'Country',
            'x-readonly': true,
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          phone: {
            type: 'string',
            title: 'Phone',
            format: 'phone',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          website: {
            type: 'string',
            title: 'Website',
            hint: 'Business’ website. e.g. https://www.domain.com',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
        },
      },
    }
  },
  components: {
    SchemaForm,
  },
  mixins: [Alerts],
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('next', this.formData)
    },
    showError(err) {
      this.$log.error(err)
    },
  },
}
</script>
