<template>
  <v-app class="page-organization">
    <tev-navbar :title="$t('organization')" />
    <v-content>
      <v-container>
        <v-flex x12 sm8 offset-sm2>
          <v-layout row wrap>
            <v-flex x12 sm12>
              <v-layout row wrap align-start justify-start>
                <v-btn icon class="light-green--text" to="/representative/organizations">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-flex py-3 text-xs-right grey--text>
                  <div>
                    &nbsp;
                    <template
                      v-if="organizationId"
                    >{{ $t('organization_id') }}: {{ organizationId }}</template>
                  </div>
                  <z-status-chip :status="organization.status" :label="$t(organization.status)"></z-status-chip>
                </v-flex>
              </v-layout>

              <!-- organization_info -->
              <v-flex class="zinc-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>{{ $t('organization_info') }}</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body">
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ organization.name }}</div>
                    <div class="zinc-box__label">{{ $t('organization_name') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ organization.address.line1 }}</div>
                    <div class="zinc-box__label">{{ $t('address') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ organization.address.city }}</div>
                    <div class="zinc-box__label">{{ $t('city') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ organization.address.state }}</div>
                    <div class="zinc-box__label">{{ $t('state') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ organization.address.postalCode }}</div>
                    <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                  </div>
                </v-flex>
              </v-flex>
              <!-- / organization_info -->
            </v-flex>

            <v-flex xs12 mb-3>
              <v-btn
                color="primary"
                dark
                depressed
                block
                large
                class="text-uppercase"
                :to="organizationId+'/properties'"
              >Properties</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'

export default {
  data() {
    return {
      isSending: false,
      isCanceling: false,
      organizationId: null,
      organization: {
        name: null,
        tevId: null,
        repId: null,
        representativeName: null,
        repCommission: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
          country: null,
        },
        status: null,
        createdAt: null,
        updatedAt: null,
      },
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loadOrganization() {
      this.isLoading = true
      this.$http
        .get('/representative/organizations/' + this.organizationId)
        .then(response => {
          this.organization = response.data
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
          this.$router.push('/representative/organizations/')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.organizationId = this.$route.params.organizationId
    this.loadOrganization()
  },
}
</script>

