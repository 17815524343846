<template>
  <div></div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ACCOUNT_TYPES } from '@/libs/constants'

export default {
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  mounted() {
    const _this = this
    let goTo = '/login'
    if (this.currentUser.isLoggedIn) {
      ACCOUNT_TYPES.forEach(type => {
        let fn = 'is' + type[0].toUpperCase() + type.slice(1)
        if (_this.currentUser[fn]) {
          goTo = '/' + type
        }
      })
    }
    this.$router.push(goTo)
  },
}
</script>
