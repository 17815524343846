var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-organizations" },
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.newSearch($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "primary--text font-weight-bold mb-3 text-uppercase"
                            },
                            [_vm._v(_vm._s(_vm.$t("organizations")))]
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "box-border",
                                    attrs: {
                                      "append-icon": "search",
                                      label: _vm.$t("search"),
                                      type: "text",
                                      autocomplete: "off",
                                      box: "",
                                      autofocus: _vm.$isDesktop()
                                    },
                                    on: { "click:append": _vm.newSearch },
                                    model: {
                                      value: _vm.name,
                                      callback: function($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isLoading
                            ? _c(
                                "skeleton-loading",
                                { staticClass: "mb-4" },
                                _vm._l(_vm.skeletonRows, function(i) {
                                  return _c(
                                    "div",
                                    { key: i },
                                    [
                                      _c(
                                        "row",
                                        {
                                          attrs: {
                                            gutter: {
                                              top: "14px",
                                              bottom: "12px"
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "column",
                                            { attrs: { span: 2, gutter: 10 } },
                                            [_c("circle-skeleton")],
                                            1
                                          ),
                                          _c(
                                            "column",
                                            { attrs: { span: 20, gutter: 6 } },
                                            [
                                              _c("square-skeleton", {
                                                attrs: {
                                                  count: 1,
                                                  boxProperties: {
                                                    top: "6px",
                                                    bottom: "4px",
                                                    width: "200px",
                                                    height: "14px"
                                                  }
                                                }
                                              }),
                                              _c("square-skeleton", {
                                                attrs: {
                                                  count: 1,
                                                  boxProperties: {
                                                    bottom: "6px",
                                                    width: "120px",
                                                    height: "12px"
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("square-skeleton", {
                                        attrs: {
                                          count: 1,
                                          boxProperties: {
                                            bottom: "1px",
                                            width: "100%",
                                            height: "1px"
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c("v-divider"),
                          !_vm.isLoading
                            ? _c(
                                "v-list",
                                {
                                  staticClass: "transparent mb-4",
                                  attrs: { "two-line": "", subheader: "" }
                                },
                                _vm._l(_vm.organizations, function(item) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      key: item.title,
                                      attrs: {
                                        avatar: "",
                                        to: "/admin/organizations/" + item.id
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-avatar",
                                        [
                                          _c("Lettericon", {
                                            attrs: {
                                              name: item.name,
                                              size: "2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(
                                              _vm._s(item.representativeName)
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-action",
                                        [
                                          _c("z-status-chip", {
                                            attrs: {
                                              status: item.status,
                                              label: _vm.$t(item.status)
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                icon: "",
                                                ripple: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "grey--text" },
                                                [_vm._v("chevron_right")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "text-xs-center mb-4" },
                            [
                              _vm.totalResults
                                ? _c("div", [
                                    _vm._v(
                                      " Results " +
                                        _vm._s(
                                          _vm.currentPage * _vm.perPage -
                                            _vm.perPage +
                                            1
                                        ) +
                                        " - " +
                                        _vm._s(
                                          Math.min(
                                            _vm.totalResults,
                                            _vm.currentPage * _vm.perPage
                                          )
                                        ) +
                                        " of " +
                                        _vm._s(_vm.totalResults) +
                                        " organizations "
                                    )
                                  ])
                                : _vm._e(),
                              _vm.totalPages > 1
                                ? _c("v-pagination", {
                                    attrs: {
                                      length: _vm.totalPages,
                                      "total-visible": 6,
                                      circle: ""
                                    },
                                    on: { input: _vm.changePage },
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                color: "light-green",
                                dark: "",
                                depressed: "",
                                block: "",
                                large: "",
                                to: "/admin/organizations/import"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("import")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-fab-transition",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "light-green",
                    dark: "",
                    fixed: "",
                    bottom: "",
                    right: "",
                    depressed: "",
                    fab: "",
                    to: "/admin/organizations/import"
                  }
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }