<template>
  <v-app>
    <tev-navbar :title="$t('member_details')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8 md6>
            <v-layout row wrap align-start justify-start>
              <v-btn icon class="light-green--text" @click="$router.back()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                &nbsp;
                <template v-if="!isLoading">ID: {{ member.id }}</template>
              </v-flex>
            </v-layout>

            <z-digital-id-card cardName="Digital ID" />

            <v-tabs grow color="transparent" slider-color="primary">
              <v-tab ripple>Personal Info</v-tab>
              <v-tab ripple></v-tab>
              <v-tab-item>
                <v-divider></v-divider>
                <v-flex xs12 sm12 mb-4>
                  <v-list two-line class="transparent py-0">
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-user.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '140px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">{{ member.fullName }}</v-list-tile-title>
                        <v-list-tile-sub-title>Name</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-phone.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '120px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title
                          v-if="!isLoading"
                        >+{{ member.dialCode }} {{ member.cellPhone }}</v-list-tile-title>
                        <v-list-tile-sub-title>Phone number</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="@/assets/ico-email.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="isLoading">
                          <skeleton-loading>
                            <square-skeleton :boxProperties="{width: '160px',height: '14px'}"></square-skeleton>
                          </skeleton-loading>
                        </v-list-tile-title>
                        <v-list-tile-title v-if="!isLoading">{{ member.email }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('email') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                  </v-list>
                </v-flex>
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DigitalIdCard from '@/components/DigitalIdCard'

export default {
  title: 'Member Details',
  data() {
    return {
      isLoading: false,
      memberId: null,
      member: {
        id: null,
        fullName: null,
        dialCode: null,
        cellPhone: null,
        email: null,
        orgStatus: null,
        organization: null,
        status: null,
      },
    }
  },
  components: {
    'z-digital-id-card': DigitalIdCard,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loadMember() {
      this.isLoading = true
      this.$http
        .get('/accountant/members/' + this.memberId)
        .then(res => {
          this.member = res.data
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/accountant/')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.memberId = this.$route.params.memberId
    if (!this.memberId) {
      this.$router.push('/accountant/')
    }
    this.loadMember()
  },
}
</script>
<style>
.page-member .v-list__tile__avatar {
  min-width: 44px;
}
.page-member .v-avatar img {
  border-radius: 0;
}
</style>
