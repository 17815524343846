var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "grey--text text--darken-1" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c("file-pond", {
        ref: _vm.uniqueId,
        staticClass: "v-filepond__selections",
        attrs: {
          name: _vm.uniqueId,
          allowFileSizeValidation: true,
          "label-idle": _vm.labelIdle,
          maxFileSize: _vm.maxFileSize,
          imagePreviewMaxFileSize: _vm.imagePreviewMaxFileSize,
          imagePreviewHeight: _vm.imagePreviewHeight,
          "accepted-file-types": _vm.acceptedFileTypes,
          "allow-multiple": _vm.allowMultiple,
          allowImageTransform: _vm.allowImageTransform,
          allowFileEncode: _vm.allowFileEncode
        },
        on: {
          addfile: _vm.updateFiles,
          removefile: _vm.eventRemove,
          error: _vm.eventError
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }