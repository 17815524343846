<template>
  <v-app>
    <tev-navbar :title="$t('login_history')" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm8 class="pt-5 mb-5 text-xs-center">
          <v-list three-line @scroll="onScroll" class="white rounded py-0 mb-4">
            <template v-for="(item, index) in loginHistory">
              <v-list-tile :key="item.createdAt" avatar>
                <v-list-tile-action>
                  <div
                    :class="String(item.country).toLowerCase()"
                    :title="item.country"
                    class="iti-flag"
                  />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ item.ip }}
                    <em
                      class="ml-4 grey--text body-1"
                    >{{ item.createdAt | moment("from") }}</em>
                  </v-list-tile-title>
                  <v-list-tile-title>{{ item.email }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ $t('platform') }}: {{ item.platform }} - {{ $t('browser') }}: {{ item.browser }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <div
                    class="tx-status"
                    :class="{'tx-status-pending': item.status == 'failed'}"
                  >{{ item.status }}</div>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            class="elevation-0"
            color="primary"
            @click="$scrollTo('#app')"
          >
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
// TODO: Añadir paginacion o scroll
import { mapGetters } from 'vuex'

export default {
  title: window.vm.$t('login_history'),
  data() {
    return {
      isLoading: false,
      offset: 0,
      limit: 25,
      total: 0,
      loginHistory: [],
      fab: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    onScroll() {
      if (this.isLoading || this.offset >= this.total) {
        return
      }

      const height = document.documentElement.scrollHeight
      const top = window.scrollY
      const windowHeight = window.innerHeight
      const total = top + windowHeight + 400

      this.fab = top > 20

      if (parseInt(total) >= parseInt(height)) {
        this.loadHistory()
      }
    },
    async loadHistory() {
      this.isLoading = true
      this.$http
        .get('/admin/login-history', {
          params: {
            limit: this.limit,
            offset: this.offset,
            sort: 'createdAt',
            order: 'desc',
          },
        })
        .then(response => {
          if (this.total === 0) {
            this.total = response.data.metadata.total
          }

          if (
            response &&
            'data' in response &&
            'loginHistory' in response.data &&
            response.data.loginHistory.length > 0
          ) {
            for (let key in response.data.loginHistory) {
              this.loginHistory.push(response.data.loginHistory[key])
            }
            this.offset += response.data.metadata.limit
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.loadHistory()
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>
<style src="../../assets/flags-sprite.css"></style>
<style>
</style>
