<template>
  <v-app>
    <v-content class="login-background">
      <v-layout row align-center justify-center pt-5 pb-5>
        <z-logo color="#ffffff" slogan class="logo-zinc" />
      </v-layout>
      <v-layout row wrap px-4>
        <v-flex xs12>
          <v-card max-width="500px" class="mx-auto">
            <v-form ref="form" lazy-validation class="form-sm" @submit.prevent="logIn">
              <v-container class="login-container pa-0">
                <v-card flat>
                  <v-card-title
                    class="tab-text-color headline indigo--text text--darken-2 font-weight-bold"
                  >{{ $t('signincredentials') }}</v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="email"
                      :rules="rules.email"
                      :label="$t('email')"
                      type="text"
                      autocomplete="off"
                      box
                      background-color="#F1F5F7"
                      flat
                      :autofocus="$isDesktop()&&!email"
                    ></v-text-field>

                    <v-text-field
                      ref="loginPassword"
                      v-model="password"
                      :rules="rules.password"
                      :label="$t('password')"
                      type="password"
                      required
                      box
                      background-color="#F1F5F7"
                      flat
                      :autofocus="$isDesktop()&&email!=null"
                    ></v-text-field>

                    <!--v-layout row>
             <v-flex xs12>
              <v-btn flat color="grey" class="px-2 mx-0 text-none" @click="rememberMe=!rememberMe">
               <toggle-button
                style="pointer-events: none;"
                :value="rememberMe"
                color="#2196F3"
                :font-size="14"
                :width="34"
                :height="18"
                :sync="true"
                class="mr-2"
               />Remember me
              </v-btn>
             </v-flex>
                    </v-layout-->

                    <v-btn
                      type="submit"
                      class="primary mb-3"
                      block
                      large
                      depressed
                      @click="logIn"
                      :loading="isLoading"
                      v-bind:class="{'blue--text': isLoading}"
                      :disabled="isLoading"
                    >{{ $t('signin') }}</v-btn>
                    <div class="text-xs-center mb-0">
                      <v-btn flat color="primary" to="/forgot">{{ $t('forgotyourpassword') }}</v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-form>
          </v-card>
          <z-footer :dark="true"></z-footer>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import Vue from 'vue'
import { RECAPTCHA_KEY } from '@/libs/constants'

// recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_KEY })

import { mapGetters } from 'vuex'
import Validator from '@/libs/validator'
import config from '@/libs/config'

import Alerts from '@/mixin/Alerts'

export default {
  data() {
    const validator = new Validator()
    return {
      isLoading: false,
      email: null,
      password: null,
      rememberMe: false,
      rules: {
        email: [
          validator.rules.required('E-mail is required.'),
          validator.rules.email('Invalid email.'),
        ],
        password: [
          validator.rules.required('Password is required.'),
          //validator.rules.minLength(8, "Password should be at least 8 characters."),
        ],
      },
      newPassword: null,
      confirmPassword: null,
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loginFailed(message) {
      this.$store.dispatch('logout')
      delete localStorage.token
      this.toastError(message)
    },
    async logIn() {
      if (!this.$refs.form.validate()) {
        return
      }

      try {
        this.isLoading = true

        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')

        let response = await this.$http.post('/public/authenticate', {
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
          recaptchaToken: token,
        })
        if (!response.data || !response.data.accessToken) {
          return this.loginFailed('Login failed!')
        }

        // Hide reCAPTCHA badge
        this.$recaptchaInstance.hideBadge()

        // unset
        config.set('loginEmail', false)

        localStorage.token = response.data.accessToken
        localStorage.refresh_token = response.data.refreshToken
        this.$store.dispatch('login')

        let redirect
        if (this.currentUser.isAdmin) {
          redirect = '/admin/'
        } else if (this.currentUser.isRepresentative) {
          redirect = '/representative/'
        } else if (this.currentUser.isAccountant) {
          redirect = '/accountant/'
        } else if (this.currentUser.isManager) {
          redirect = '/manager/'
        } else if (this.currentUser.isAssistant) {
          redirect = '/assistant/'
        } else if (this.currentUser.isProperty) {
          redirect = '/property/'
        } else if (this.currentUser.isSupport) {
          redirect = '/support/'
        } else if (this.currentUser.isTenantev) {
          redirect = '/tenantev/'
        } else {
          redirect = '/applicant/'
        }

        if (
          this.$route.query.redirect &&
          this.$route.query.redirect.indexOf(redirect) !== -1 &&
          this.$route.query.redirect != this.$route.path
        ) {
          redirect = this.$route.query.redirect
        }

        this.$nextTick(() => {
          this.$router.push(redirect)
        })
      } catch (err) {
        this.$log.error(err)
        if (err && err.response && err.response.data) {
          this.loginFailed(err.response.data.detail)
        }
      } finally {
        this.isLoading = false
      }
    },
  },
  created() {
    let loginEmail = config.get('loginEmail', false)
    if (loginEmail) {
      this.email = loginEmail
    }
  },
  mounted() {
    // Show reCAPTCHA badge
    if (this.$recaptchaInstance){
      this.$recaptchaInstance.showBadge()
    }
  },
  beforeDestroy() {
    if (this.$recaptchaInstance){
      this.$recaptchaInstance.hideBadge()
    }
  },
}
</script>
<style lang="scss">
.tab-text-color {
  color: #2b388f !important;
  font-size: 30px !important;
  line-height: 35px !important;
  font-weight: bold !important;
  width: 200px;
}
</style>
