<template>
  <v-form ref="form">
    <v-layout row wrap class="text-xs-left">
      <v-flex xs12>
        <v-checkbox
          color="primary"
          :label="agreeText"
          v-model="signerAgree"
          :rules="rules.required"
          @change="emitChange"
        ></v-checkbox>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          :value="signerNameText"
          label="Printed Name"
          :rules="rules.required"
          :readonly="true"
          required
          box
          :background-color="backgroundColor"
          v-if="signerNameText"
        ></v-text-field>
        <v-text-field
          v-model="signerName"
          label="Printed Name"
          :rules="rules.required"
          required
          box
          :background-color="backgroundColor"
          @change="emitChange"
          v-else
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="signingDate"
          label="Date"
          readonly
          box
          :background-color="backgroundColor"
        >
          <template v-slot:append>
            <img width="16" height="16" src="@/assets/calendar.svg" />
          </template>
        </v-text-field>
      </v-flex>
      <v-flex xs12>
        <div class="pa-3" :style="'background-color:'+backgroundColor">
          <TeSignaturePad
            ref="TeSignaturePad"
            @onSignature="onSignatureChange"
            :signatureText="signerNameText?signerNameText:signerName"
          ></TeSignaturePad>
          <div class="v-text-field__details white" v-if="signError">
            <div class="v-messages theme--light error--text">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">Signature is required.</div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import Validator from '../libs/validator'
import TeSignaturePad from './TeSignaturePad'

export default {
  props: {
    signerNameText: String,
    agreeText: {
      type: String,
      default: 'I agree',
    },
    backgroundColor: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    const validator = new Validator()
    return {
      hasSign: false,
      signError: false,
      signCompleted: false,
      signerAgree: null,
      signingDate: null,
      signerName: null,
      signature: null,
      rules: {
        required: [validator.rules.required()],
      },
    }
  },
  components: {
    TeSignaturePad,
  },
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      if (!this.hasSign) {
        this.signError = true
        isValid = false
      } else {
        this.signError = false
      }
      return isValid
    },
    emitChange() {
      this.$emit('change', {
        hasSign: this.hasSign,
        signerAgree: this.signerAgree,
        signerName: this.signerName,
        signature: this.signature,
      })
    },
    onSignatureChange(sign) {
      if (sign.isEmpty) {
        this.hasSign = false
        this.signature = null
      } else {
        this.hasSign = true
        this.signature = sign.data
        this.signError = false
      }
      this.emitChange()
    },
  },
  mounted() {
    this.signingDate = new Date().toISOString().substr(0, 10)
  },
}
</script>
