var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    { staticClass: "letter-icon", class: { medium: _vm.size == "medium" } },
    [_vm._v(_vm._s(_vm.letters))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }