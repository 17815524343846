var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.htmlDescription
    ? _c(
        "v-tooltip",
        {
          attrs: {
            left: "",
            "open-on-hover": false,
            "open-on-click": false,
            "content-class": "vjsf-tooltip"
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function($event) {
                    _vm.show = !_vm.show
                  }
                }
              },
              [_vm._v(_vm._s(_vm.options.icons.info))]
            )
          ],
          _c("div", {
            style: "max-width: " + _vm.maxWidth + "px",
            domProps: { innerHTML: _vm._s(_vm.htmlDescription) }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }