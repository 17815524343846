var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("preferences") } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "", "mt-5": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 rounded" },
                    [
                      _c(
                        "v-list",
                        { attrs: { subheader: "", "two-line": "" } },
                        [
                          _c("v-subheader", [_vm._v("Notifications")]),
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-action",
                                [
                                  _c("v-checkbox", {
                                    model: {
                                      value: _vm.notifications,
                                      callback: function($$v) {
                                        _vm.notifications = $$v
                                      },
                                      expression: "notifications"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-tile-content",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.notifications = !_vm.notifications
                                    }
                                  }
                                },
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v("Notifications")
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v("Allow notifications")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }