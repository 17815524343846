var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-content",
        { staticClass: "topay-bg" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "pt-5": "",
                "pb-5": ""
              }
            },
            [
              _c("z-logo", {
                staticClass: "logo-zinc",
                attrs: { color: "#ffffff", slogan: "" }
              })
            ],
            1
          ),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "text-xs-center pa-5 mb-5 white--text" },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "white", indeterminate: "" }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "px-4": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto",
                              attrs: { "max-width": "600px" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "pa-4": "",
                                    "text-xs-center": ""
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "figure-topay",
                                    attrs: {
                                      src: require("@/assets/figure-topay.svg")
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "title font-weight-light mb-2"
                                    },
                                    [
                                      _vm._v(
                                        " There are some fees that have to be paid before you can move into: "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "title mb-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.application.propertyName) +
                                        " Unit " +
                                        _vm._s(_vm.application.unitNumber) +
                                        " " +
                                        _vm._s(_vm.application.address.city) +
                                        ", " +
                                        _vm._s(_vm.application.address.state) +
                                        " " +
                                        _vm._s(
                                          _vm.application.address.postalCode
                                        )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "grey--text body-1" },
                                    [
                                      _vm._v(
                                        " If you have any questions about these fees please contact the property directly. "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "grey lighten-4 pa-4",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "z-card mb-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pa-3" },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "text-uppercase grey--text text--lighten-1"
                                            },
                                            [_vm._v("Total Moving Fees")]
                                          ),
                                          _c(
                                            "h3",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.totalAmount
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._l(_vm.variableFees, function(
                                            item
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.description,
                                                staticClass:
                                                  "primary--text text-lowercase"
                                              },
                                              [
                                                _vm._v(
                                                  "+ " +
                                                    _vm._s(item.description)
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      ),
                                      _c("v-divider"),
                                      _vm._l(_vm.application.payments, function(
                                        item
                                      ) {
                                        return [
                                          _c(
                                            "v-layout",
                                            {
                                              key: item.id,
                                              attrs: { row: "", "ma-3": "" }
                                            },
                                            [
                                              _c("v-flex", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  )
                                                ]),
                                                item.contractType == "deposit"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grey--text"
                                                      },
                                                      [_vm._v("Refundable")]
                                                    )
                                                  : _vm._e()
                                              ]),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    "text-xs-right": "",
                                                    "font-weight-bold": ""
                                                  }
                                                },
                                                [
                                                  item.feeRateType == "flat"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              item.amount
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("pending")
                                                          )
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider", {
                                            key: "d" + item.id
                                          })
                                        ]
                                      }),
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            "ma-3": "",
                                            "pb-4": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: { "font-weight-bold": "" }
                                            },
                                            [_vm._v("Total")]
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { "text-xs-right": "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.totalAmount
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._l(_vm.variableFees, function(
                                                item
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "v" + item.id,
                                                    staticClass:
                                                      "grey--text text-lowercase"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "+ " +
                                                        _vm._s(item.description)
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-xs-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-uppercase mb-4 white--text",
                                          attrs: {
                                            block: "",
                                            depressed: "",
                                            large: "",
                                            color: "primary"
                                          },
                                          on: { click: _vm.payApplication }
                                        },
                                        [_vm._v("Pay Now")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("z-footer", { attrs: { dark: true } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }