export default {
  logout: 'Logout',
  search: 'Search',
  settings: 'Settings',
  select: 'Select',
  users: 'Users',
  members: 'Members',
  save: 'Save',
  add: 'Add',
  invite_new_members: 'Invite New Members',
  invite_new_members_property: 'Invite new members to manage this property',
  invite_people_to_zinc: 'Invite people to Zinc',
  invite: 'Invite',
  password: 'Password',
  admin_invitation_sent_explain:
    'The invitation has been sent. New organization members will get an email that gives them access to Zinc.',
  close: 'Close',
  send: 'Send',
  cancel: 'Cancel',
  active: 'Active',
  no: 'No',
  yes: 'Yes',
  ok: 'OK',
  import: 'Import',

  session_expired: 'Session Expired',
  session_expired_explain: 'Your session has expired. Please sign in again.',

  complete_required: 'You must complete the required fields.',

  name: 'Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  my_organizations: 'My Organizations',
  organization_id: 'Organization ID',
  organization_name: 'Organization Name',
  organization_info: 'Organization Info',
  organization: 'Organization',
  representative: 'Representative',
  phone: 'Phone',
  dialcode: 'Dial Code',
  add_commission_value: 'Add commission value',

  transactions: 'Transactions',
  current_transactions_list: 'Current Transactions List',
  transactions_details: 'Transaction Details',

  status: 'Status',
  pending: 'Pending',
  completed: 'Completed',
  waiting: 'Waiting',

  application_id: 'Application ID',
  property_id: 'Property ID',
  id_number: 'ID Number',
  applications: 'Applications',
  applicant_info: 'Applicant Info',
  applicant_name: 'Applicant Name',
  mobile_phone: 'Mobile Phone',
  email: 'Email',
  date_of_birth: 'Date of Birth',
  us_social_security: 'US Social Security',
  property_info: 'Property Info',
  property_name: 'Property Name',
  address: 'Address',
  city: 'city',
  state: 'State',
  zip_code: 'Zip Code',
  move_in_date: 'Move in date',
  monthly_rent: 'Monthly rent',
  type_of_application: 'Type of Application',
  lease: 'Lease',
  transaction_status: 'Transaction Status',
  documents_signed: 'Documents signed',
  detail_of_collected_fees: 'Detail of collected fees',
  move_in_fee: 'Move in fee',
  fee: 'Fee',
  non_refundable: 'Non-refundable',
  type_of_fee: 'Type of fee',
  due_date: 'Due date',
  rate: 'Rate',
  elevator_deposit_fee: 'Elevator deposit fee',
  total_to_be_collected: 'Total to be collected',

  import_properties: 'Import Properties',
  properties: 'Properties',
  property: 'Property',
  my_property: 'My Property',
  property_management_company: 'Property Management Company',
  active_members: 'Active Members',

  transfer_property: 'Transfer Property',
  new_property_management_company: 'New Property Management Company',

  manager_first_name: 'Manager First Name',
  manager_last_name: 'Manager Last Name',
  cell_phone: 'Cell Phone',
  role: 'Role',

  profile: 'Profile',
  my_profile: 'My Profile',
  my_digital_id: 'My Digital ID',
  share_id: 'Share ID',

  reports: 'Reports',
  filter_your_search: 'Filter Your Search',
  from_date: 'From Date',
  to_date: 'To Date',
  transaction_type: 'Transaction Type',
  generate_reports: 'Generate Reports',

  my_properties: 'My Properties',
  properties_list: 'Properties List',
  import_explain:
    'Please select the properties you want Zinc to collect payments.',

  payment_setup: 'Payment Setup',
  payment_setup_explain:
    'How do you want to make your payments? You can add multiple accounts and edit them at any time.',

  checking_account: 'Checking Account',
  manual_setup: 'Manual Setup',
  debit_or_credit_card: 'Debit or credit card',

  payments_takes_days: 'Payments takes about {days} business days to complete.',

  amount_transaction_fee: '{amount} transaction fee',

  my_smart_services: 'My Smart Services',
  select_smartservices_explain:
    'Select the smart contracts you want to assign to this property',

  one_time_fee: 'One time fee',
  recurring_fee: 'Recurring fee',
  special_assessments_fee: 'Special assessments fee',
  miscellaneous: 'Miscellaneous',

  change_password: 'Change Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  continue: 'Continue',

  signincredentials: 'Sign in using your credentials',
  signin: 'Sign In',
  forgotyourpassword: 'Forgot Your Password?',

  create_your_account: 'Create your account',
  enter_your_code: 'Enter your code',
  enter_access_code: 'Enter Access Code',
  access_code: 'Access Code',
  security_code: 'Security Code',
  validate: 'validate',
  create_account: 'Create Account',
  justsentsms_explain:
    'We just sent you a text message with a code for you to enter here.',
  phoneno: 'Phone No',
  sendmenewcode: 'Send me a new code',
  verify: 'Verify',

  your_account: 'Your Account',
  invitations: 'Invitations',
  organizations: 'Organizations',

  your_notifications: 'Your Notifications',
  view_more: 'View more',
  view_all_notifications: 'View all notifications',

  login_history: 'Login History',
  platform: 'Platform',
  browser: 'Browser',
  notifications: 'Notifications',

  preferences: 'Preferences',

  select_all: 'Select All',
  unselect_all: 'Unselect All',

  org_total_importproperties:
    'Your organization has {total} properties available to import!',

  property_activity: 'Property Activity',
  no_activity_explain: 'There are no activity available for this property.',
  member_type: 'Member Type',
  member_details: 'Member Details',

  select_image_area: 'Select image area',

  home: 'Home',
  payments: 'Payments',
  get_loan: 'Get Loan',

  two_types: '2 types',
  the_are: 'The are',
  of_fees: 'of fees',

  my_payments: 'My Payments',
}
