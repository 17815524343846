<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar title />
    <tev-subnavbar back="/applicant/payments" title="Payment Detail" />
    <v-content>
      <v-container>


        <img src="@/assets/verified.svg" class="z-seal" />
        <v-layout row wrap>
          <v-flex xs12 sm8  v-if="isLoading" class="mb-5 text-xs-center">
            <div class="text-xs-center pa-5 mb-5">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-flex>          
          <v-card v-if="payment && !isLoading" class="elevation-0 transparent" max-width="100%" width="600">
            <!-- property_info -->
            <h3 class="text-uppercase grey--text text--lighten-1 mb-3">{{ $t('property_info') }}</h3>
            <v-card class="z-card mb-4">
              <v-card-text class="pa-0">
                <v-list two-line class="transparent pa-0">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ payment.propertyName }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('property_name')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        payment.address.line1
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('address')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        payment.address.city
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('city')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        payment.address.state
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('state')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action></v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        payment.address.postalCode
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('zip_code')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        payment.organizationName
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Property Management Company</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card-text>
            </v-card>
            <h3 class="text-uppercase grey--text text--lighten-1 mb-3">Payment Details</h3>
            <v-card class="z-card mb-4 pa-0">
              <v-card-text class="pa-0">
                <v-list two-line class="transparent pa-0">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{description}}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Fee Description</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <template v-if="payment.contractType == 'deposit'">Refundable</template>
                        <template v-else>Non-Refundable</template>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Type of Fee</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ payment.submittedAt | moment('MM/DD/YYYY') }}</v-list-tile-title>
                      <v-list-tile-sub-title>Date Collected</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action></v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile v-if="payment.contractType == 'deposit'">
                    <v-list-tile-content>
                      <v-list-tile-title>{{ payment.refundedAt | moment('MM/DD/YYYY') }}</v-list-tile-title>
                      <v-list-tile-sub-title>Refund Date</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="payment.status=='completed' || payment.status=='pending'">
                      <countdown :time="new Date(payment.refundedAt).getTime()  - new Date().getTime()">
                        <template slot-scope="props">
                          <v-layout>
                            <div class="mr-2">
                              {{ props.days }}
                              <div class="body-1 light-green--text">days</div>
                            </div>
                            <div class="mr-2">
                              {{ props.hours }}
                              <div class="body-1 light-green--text">hours</div>
                            </div>
                            <div class="mr-2">
                              {{ props.minutes }}
                              <div class="body-1 light-green--text">minutes</div>
                            </div>
                            <img src="@/assets/schedule.svg" height="22" />
                          </v-layout>
                        </template>
                      </countdown>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>

                <v-layout align-center justify-center class="light-green white--text pa-3">
                  <span class="text-uppercase body-1">
                    <strong>{{ payment.status }}</strong>
                  </span>
                  <v-spacer></v-spacer>
                  <strong class="title">{{ payment.amount | currency }}</strong>
                </v-layout>
              </v-card-text>
            </v-card>
            <div class="mb-5 text-xs-center primary--text">Payment ID: {{ payment.id }}</div>

            <!--
            <v-layout align-center justify-center text-xs-center>
              <div>
                <v-btn
                  @click="printReport"
                  depressed
                  large
                  color="primary"
                  class="text-uppercase rounded mb-2 white--text"
                >
                  <img src="@/assets/print-report.svg" />
                </v-btn>
                <div class="body-1">Print Report</div>
              </div>
              <div>
                <v-btn
                  @click="emailReceipt"
                  depressed
                  large
                  color="primary"
                  class="text-uppercase rounded mb-2 white--text"
                >
                  <img src="@/assets/email-receipt.svg" />
                </v-btn>
                <div class="body-1">Email Receipt</div>
              </div>
            </v-layout>
            -->
          </v-card>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Payment Detail',
  data() {
    return {
      isLoading: false,
      payment: { amount: null, address: { line1: null } },
    }
  },
  mixins: [Alerts],
  components: { countdown: VueCountdown },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    description() {
      if (this.payment){
        if (this.payment.transactionType=='property_zinc_fee'){
          return 'Property Setup Fee'
        }
        else if (this.payment.transactionType=='applicant_zinc_fee'){
          return 'Processing Fee'
        }
        else {
          return this.payment.feeName
        }
      }
      return ''
    },    
  },
  methods: {
    load() {
      this.isLoading = true
      this.$http
        .get('/applicant/payments/' + this.paymentId)
        .then(res => {     
          let tx = res.data
          if (tx.status == 'pending') {
            this.$router.push('/applicant')
          }
          this.payment = tx
          this.$log.info(this.payment)
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    printReport() {
      this.isLoading = true
      this.$http
        .get('/applicant/payments/' + this.paymentId + '/print')
        .then(() => {
          this.toastSuccess('OK!')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    emailReceipt() {
      this.isLoading = true
      this.$http
        .get('/applicant/payments/' + this.paymentId + '/receipt')
        .then(() => {
          this.toastSuccess('OK!')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.paymentId = this.$route.params.paymentId
    if (!this.paymentId) {
      this.$router.push('/applicant/payments')
    }
    this.load()
  },
}
</script>
<style>
.z-seal {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 1000;
}
</style>
