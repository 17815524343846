var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "form-onboarding",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.sendOnboarding($event)
        }
      },
      model: {
        value: _vm.formValid,
        callback: function($$v) {
          _vm.formValid = $$v
        },
        expression: "formValid"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c(
            "v-stepper",
            {
              staticClass: "elevation-0 transparent",
              attrs: { vertical: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.step > 1,
                    step: "1",
                    color: _vm.step > 1 ? "light-green" : "primary"
                  }
                },
                [
                  _c("StepperTitle", {
                    attrs: {
                      title: "Personal Information",
                      titleStep: 1,
                      step: _vm.step
                    },
                    on: {
                      edit: function($event) {
                        _vm.step = 1
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("AddPersonalInfo", {
                    attrs: { data: _vm.formData },
                    on: { next: _vm.next, back: _vm.back }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.step > 2,
                    step: "2",
                    color: _vm.step > 2 ? "light-green" : "primary"
                  }
                },
                [
                  _c("StepperTitle", {
                    attrs: {
                      title: "Company Information",
                      titleStep: 2,
                      step: _vm.step
                    },
                    on: {
                      edit: function($event) {
                        _vm.step = 2
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c("AddCompanyInfo", {
                    attrs: { property: _vm.property },
                    on: { next: _vm.next, back: _vm.back }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.step > 3,
                    step: "3",
                    color: _vm.step > 3 ? "light-green" : "primary"
                  }
                },
                [
                  _c("StepperTitle", {
                    attrs: {
                      title: "Company Details",
                      titleStep: 3,
                      step: _vm.step
                    },
                    on: {
                      edit: function($event) {
                        _vm.step = 3
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c("AddCompanyDetails", {
                    on: { next: _vm.next, back: _vm.back }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.step > 4,
                    step: "4",
                    color: _vm.step > 4 ? "light-green" : "primary"
                  }
                },
                [
                  _c("StepperTitle", {
                    attrs: {
                      title: "Controller Information",
                      titleStep: 4,
                      step: _vm.step
                    },
                    on: {
                      edit: function($event) {
                        _vm.step = 4
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c("AddControllerInfo", {
                    on: { next: _vm.next, back: _vm.back }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    step: "5",
                    color: _vm.step > 5 ? "light-green" : "primary"
                  }
                },
                [
                  _c("StepperTitle", {
                    attrs: {
                      title: "Confirm & Upload",
                      titleStep: 5,
                      step: _vm.step
                    },
                    on: {
                      edit: function($event) {
                        _vm.step = 5
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c("z-sign", {
                    ref: "sign",
                    staticClass: "mb-3",
                    attrs: {
                      backgroundColor: "#F1F5F7",
                      signerNameText:
                        _vm.formData.firstName + " " + _vm.formData.lastName,
                      agreeText:
                        "I, " +
                        _vm.formData.firstName +
                        " " +
                        _vm.formData.lastName +
                        ", hereby certify, to the best of my knowledge, that the information provided above is complete and correct."
                    },
                    on: { change: _vm.signChange }
                  }),
                  !_vm.agreeCorrect && _vm.sign.hasSign && _vm.sign.signerAgree
                    ? [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              round: "",
                              depressed: "",
                              color: "primary"
                            },
                            on: {
                              click: function($event) {
                                _vm.agreeCorrect = true
                              }
                            }
                          },
                          [_vm._v("Agree & Continue")]
                        )
                      ]
                    : _vm._e(),
                  _vm.agreeCorrect
                    ? [
                        _c("v-checkbox", {
                          attrs: { color: "primary" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("div", [
                                      _vm._v(" I agree Zinc's "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "grey--text",
                                          attrs: {
                                            href:
                                              "https://zinc.tech/terms-of-service/",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Terms of Service")]
                                      ),
                                      _vm._v(" and "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "grey--text",
                                          attrs: {
                                            href:
                                              "https://zinc.tech/privacy-policy/",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Privacy Policy")]
                                      ),
                                      _vm._v(", and Dwolla's "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "grey--text",
                                          attrs: {
                                            href:
                                              "https://www.dwolla.com/legal/tos/",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Terms of service")]
                                      ),
                                      _vm._v(" and "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "grey--text",
                                          attrs: {
                                            href:
                                              "https://www.dwolla.com/legal/privacy/",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Privacy Policy")]
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            652888550
                          ),
                          model: {
                            value: _vm.agreeTerms,
                            callback: function($$v) {
                              _vm.agreeTerms = $$v
                            },
                            expression: "agreeTerms"
                          }
                        }),
                        _vm.agreeTerms
                          ? _c(
                              "v-btn",
                              {
                                class: { "blue--text": _vm.isSaving },
                                attrs: {
                                  loading: _vm.isSaving,
                                  disabled: _vm.isSaving,
                                  round: "",
                                  depressed: "",
                                  color: "primary"
                                },
                                on: { click: _vm.submit }
                              },
                              [_vm._v("Agree & Submit")]
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { round: "", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.agreeCorrect = false
                          _vm.$emit("back")
                        }
                      }
                    },
                    [_vm._v("Back")]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }