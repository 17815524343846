var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: { class: "primary--text" },
          expression: "{class: 'primary--text'}"
        }
      ],
      staticClass: "z-card pa-3 text-xs-left mb-3",
      attrs: { to: _vm.to },
      on: {
        click: function($event) {
          return _vm.$router.push("/applicant/payments")
        }
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "mb-2": "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(
                _vm._s(_vm.application.firstName) +
                  " " +
                  _vm._s(_vm.application.lastName)
              )
            ]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Applicant")
            ])
          ]),
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c(
              "div",
              {
                staticClass:
                  "font-weight-bold text-uppercase light-green--text body-1"
              },
              [_vm._v(_vm._s(_vm.application.status))]
            ),
            _c("div", { staticClass: "grey--text body-1" }, [_vm._v("Status")])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "v-layout",
        { staticClass: "mb-2", attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("div", [_vm._v(_vm._s(_vm.application.tevId))]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Application ID")
            ])
          ]),
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm._f("moment")(_vm.application.moveInDate, "MM/DD/YYYY")
                )
              )
            ]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Move In Date")
            ])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("div", [_vm._v(_vm._s(_vm.application.moveInAddress))]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Move In Address")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c(
                "v-icon",
                {
                  staticStyle: { margin: "2px -4px 0 0", float: "right" },
                  attrs: { color: "grey", size: "40" }
                },
                [_vm._v("chevron_right")]
              ),
              _c("div", [_vm._v(_vm._s(_vm.application.unitNumber))]),
              _c("div", { staticClass: "grey--text body-1" }, [_vm._v("Unit")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }