var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-member" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("members") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "align-start": "",
                            "justify-space-between": "",
                            row: "",
                            "fill-height": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "light-green--text",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.isInvite = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                xs12: "",
                                "align-center": "",
                                "justify-center": "",
                                row: "",
                                wrap: "",
                                "fill-height": "",
                                "pt-4": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outline: "",
                                    icon: "",
                                    large: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c("v-avatar", { attrs: { size: 16 } }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/edit.svg")
                                      }
                                    })
                                  ])
                                ],
                                1
                              ),
                              _c("Lettericon", {
                                staticClass: "ma-3",
                                attrs: {
                                  name: "Michelle Hendley",
                                  size: "medium"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outline: "",
                                    icon: "",
                                    large: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c("v-avatar", { attrs: { size: 16 } }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/delete.svg")
                                      }
                                    })
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("z-spacer", { attrs: { width: 52 } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        xs12: "",
                        "align-center": "",
                        "justify-center": "",
                        "text-xs-center": "",
                        row: "",
                        wrap: "",
                        "mb-4": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            title: "",
                            "font-weight-regular": ""
                          }
                        },
                        [_vm._v(_vm._s(_vm.fullName))]
                      ),
                      _c("v-flex", { attrs: { xs12: "", "body-1": "" } }, [
                        _vm._v("Manager Assistant")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "", "mx-4": "" } },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "transparent",
                          attrs: { "two-line": "" }
                        },
                        [
                          _c(
                            "v-list-tile",
                            { attrs: { avatar: "" } },
                            [
                              _c(
                                "v-list-tile-avatar",
                                { attrs: { size: "16" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/ico-user.svg")
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.fullName))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(_vm._s(_vm.$t("name")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            { attrs: { avatar: "" } },
                            [
                              _c(
                                "v-list-tile-avatar",
                                { attrs: { size: "16" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/ico-phone.svg")
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.phone))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(_vm._s(_vm.$t("phone")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            { attrs: { avatar: "" } },
                            [
                              _c(
                                "v-list-tile-avatar",
                                { attrs: { size: "16" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/ico-email.svg")
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.email))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(_vm._s(_vm.$t("email")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            { attrs: { avatar: "" } },
                            [
                              _c(
                                "v-list-tile-avatar",
                                { attrs: { size: "16" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/ico-org.svg")
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.organization))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(_vm._s(_vm.$t("organization")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-tile",
                            { attrs: { avatar: "" } },
                            [
                              _c(
                                "v-list-tile-avatar",
                                { attrs: { size: "16" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/ico-lock.svg")
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v("***********")
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(_vm._s(_vm.$t("password")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-tile-action",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "border-0",
                                      attrs: {
                                        icon: "",
                                        ripple: "",
                                        color: "primary",
                                        outline: ""
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/edit.svg")
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }