<template>
  <v-app>
    <tev-navbar v-if="property" :title="(property.name)?'Banks: ' + property.name:''" />

    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8>
            <div class="text-xs-center" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-flex> 
        </v-layout>
        <v-layout row wrap>               
          <v-flex x12 sm8>
            <v-layout v-if="property && !isLoading" row wrap align-start justify-start>
              <v-btn
                icon
                class="light-green--text"
                @click="goBack()"
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                <div>
                  &nbsp;
                  <template v-if="propertyId"
                    >{{ $t('property_id') }}: {{ property.tevPropertyId }}</template
                  >
                </div>
                <z-status-chip
                  :status="property.status"
                  :label="$t(property.status)"
                ></z-status-chip>
              </v-flex>
            </v-layout>
          </v-flex>    
        </v-layout>  
        <v-layout row wrap>
          <v-flex x12 sm8>
            <v-flex xs12 sm12 mb-4 v-if="!isLoading && banks.length==0">
              <div class="text-xs-center pt-5 mb-5 grey--text">
                <h2 class="headline black--text">
                  No banks defined. Please ask accountant to setup bank accounts for this property.
                </h2>
              </div>
            </v-flex>
            <v-flex xs12 sm12 mb-4 v-if="!isLoading && banks.length>0">
              <v-layout row>
                <v-flex py-3>
                  <h3 class="text-uppercase grey--text text--lighten-1 mb-3">
                    Bank Accounts 
                  </h3>
                </v-flex>
                <v-flex py-3 text-xs-right grey--text>

                </v-flex>
              </v-layout>
              <v-card class="z-card">
                <v-card-text class="px-2 py-1">
                  <v-list two-line class="transparent">
                    <template v-for="(item, index) in banks">
                      <v-divider
                        :key="'d' + item.id"
                        v-if="index > 0"
                      ></v-divider>
                      <v-list-tile :key="item.id" @click="$router.push('/' + currentUser.accountType + '/properties/' + propertyId + '/banks/'+item.id)">
                        <v-list-tile-content>
                          <v-list-tile-title class="font-weight-bold">
                            {{ item.alias }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title
                            >Bank Account Alias</v-list-tile-sub-title
                          >
                        </v-list-tile-content>
                        <v-list-tile-content>
                          <v-list-tile-title class="font-weight-bold">
                            {{ item.last4 }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title
                            >4 last digits</v-list-tile-sub-title
                          >
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn no-ripple small icon :ripple="false" disabled>
                            <v-icon size="32" class="grey--text"
                              >chevron_right</v-icon
                            >
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>

  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Transactions',
  data() {
    return {
      isLoading: false,
      propertyId: null,
      property: null,
      banks:[],

    }
  },
  mixins: [Alerts],
  components: {

  },

  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),

  },

  methods: {
    goBack() {
      this.$router.push('/' + this.currentUser.accountType + '/properties/' + this.propertyId)
    },
    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
          this.loadBanks()
        })
        .catch(err => {
          this.isLoading = false          
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
    },    
    loadBanks() {
      this.$http
        .get('/manager/properties/' + this.propertyId + '/banks')
        .then(response => {
          this.banks = response.data.banks
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadProperty()
  },
}
</script>

<style lang="scss">

</style>
