var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-contract-setup page-with-subnavbar" },
    [
      _c("tev-navbar"),
      _c("tev-subnavbar", {
        attrs: {
          back:
            "/" +
            _vm.currentUser.accountType +
            "/properties/" +
            _vm.propertyId +
            "/contracts",
          title: "My Smart Contract"
        }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "transparent elevation-0",
                      attrs: { width: "600", "max-width": "100%" }
                    },
                    [
                      _vm.schedule
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                x12: "",
                                sm8: "",
                                "offset-sm2": "",
                                "text-xs-center": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "title font-weight-light mb-4" },
                                [
                                  _vm._v(
                                    "Select the date and time when you want your smart contract to be disable"
                                  )
                                ]
                              ),
                              _c(
                                "v-date-picker",
                                {
                                  staticClass: "v-card--flat mb-3",
                                  attrs: { reactive: true },
                                  model: {
                                    value: _vm.stoppedAt,
                                    callback: function($$v) {
                                      _vm.stoppedAt = $$v
                                    },
                                    expression: "stoppedAt"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase white--text",
                                      attrs: {
                                        disabled: !_vm.stoppedAt,
                                        depressed: "",
                                        large: "",
                                        color: "light-green"
                                      },
                                      on: { click: _vm.disableContract }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("save")))]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase white--text",
                                      attrs: {
                                        depressed: "",
                                        large: "",
                                        outline: "",
                                        color: "indigo darken-4"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.schedule = false
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  )
                                ],
                                1
                              ),
                              _c("v-layout", {
                                attrs: {
                                  "align-center": "",
                                  "justify-center": "",
                                  row: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.schedule
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "white pa-3 pt-5",
                              attrs: {
                                x12: "",
                                sm8: "",
                                "offset-sm2": "",
                                "text-xs-center": ""
                              }
                            },
                            [
                              _vm.next
                                ? [
                                    _c("img", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        src: require("@/assets/schedule.svg")
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "title font-weight-light mb-4"
                                      },
                                      [
                                        _vm._v(
                                          "When do you want this smart contract to be disable?"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-uppercase mb-3 white--text",
                                        attrs: {
                                          block: "",
                                          depressed: "",
                                          large: "",
                                          color: "light-green",
                                          disabled: _vm.isDisabling
                                        },
                                        on: { click: _vm.disableContract }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("now")))]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-uppercase mb-3 white--text",
                                        attrs: {
                                          block: "",
                                          depressed: "",
                                          large: "",
                                          color: "primary",
                                          disabled: _vm.isDisabling
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.schedule = true
                                          }
                                        }
                                      },
                                      [_vm._v("Schedule For Later")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-uppercase mb-0 white--text",
                                        attrs: {
                                          to:
                                            "/" +
                                            _vm.currentUser.accountType +
                                            "/properties/" +
                                            _vm.propertyId +
                                            "/contracts",
                                          block: "",
                                          large: "",
                                          flat: "",
                                          color: "primary"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                                    )
                                  ]
                                : _vm._e(),
                              !_vm.next
                                ? [
                                    _c("img", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        src: require("@/assets/attention.svg")
                                      }
                                    }),
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "headline black--text mb-3"
                                      },
                                      [_vm._v("Attention")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "title font-weight-light mb-4"
                                      },
                                      [
                                        _vm._v(
                                          "By suspending this Smart Contract all the charges related to the existing fees will not be collected anymore."
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-uppercase mb-3 white--text",
                                        attrs: {
                                          block: "",
                                          depressed: "",
                                          large: "",
                                          color: "pink accent-3"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.next = true
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("continue")))]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-uppercase mb-0 white--text",
                                        attrs: {
                                          to:
                                            "/" +
                                            _vm.currentUser.accountType +
                                            "/properties/" +
                                            _vm.propertyId +
                                            "/contracts",
                                          block: "",
                                          depressed: "",
                                          large: "",
                                          color: "primary"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }