<template>
  <div class="Password">
    <div v-if="!strengthMeterOnly" class="Password__group">
      <input
        :type="inputType"
        :ref="referanceValue"
        :class="[defaultClass, disabled ? disabledClass : '']"
        :name="name"
        :id="id"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :autocomplete="autocomplete"
        v-bind:value="value"
        @input="evt => emitValue('input', evt.target.value)"
        @blur="evt => emitValue('blur', evt.target.value)"
        @focus="evt => emitValue('focus', evt.target.value)"
      />
      <div class="Password__icons">
        <div v-if="toggle" class="Password__toggle">
          <button
            type="button"
            class="btn-clean"
            :aria-label="showPasswordLabel"
            @click.prevent="togglePassword()"
          >
            <svg
              v-if="showPassword"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <title>{{ showPasswordLabel }}</title>
              <path
                d="M12 9c1.641 0 3 1.359 3 3s-1.359 3-3 3-3-1.359-3-3 1.359-3 3-3zM12 17.016c2.766 0 5.016-2.25 5.016-5.016s-2.25-5.016-5.016-5.016-5.016 2.25-5.016 5.016 2.25 5.016 5.016 5.016zM12 4.5c5.016 0 9.281 3.094 11.016 7.5-1.734 4.406-6 7.5-11.016 7.5s-9.281-3.094-11.016-7.5c1.734-4.406 6-7.5 11.016-7.5z"
              />
            </svg>
            <svg
              v-else
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <title>{{ showPasswordLabel }}</title>
              <path
                d="M11.859 9h0.141c1.641 0 3 1.359 3 3v0.188zM7.547 9.797c-0.328 0.656-0.563 1.406-0.563 2.203 0 2.766 2.25 5.016 5.016 5.016 0.797 0 1.547-0.234 2.203-0.563l-1.547-1.547c-0.188 0.047-0.422 0.094-0.656 0.094-1.641 0-3-1.359-3-3 0-0.234 0.047-0.469 0.094-0.656zM2.016 4.266l1.266-1.266 17.719 17.719-1.266 1.266c-1.124-1.11-2.256-2.213-3.375-3.328-1.359 0.563-2.813 0.844-4.359 0.844-5.016 0-9.281-3.094-11.016-7.5 0.797-1.969 2.109-3.656 3.75-4.969-0.914-0.914-1.812-1.844-2.719-2.766zM12 6.984c-0.656 0-1.266 0.141-1.828 0.375l-2.156-2.156c1.219-0.469 2.578-0.703 3.984-0.703 5.016 0 9.234 3.094 10.969 7.5-0.75 1.875-1.922 3.469-3.422 4.734l-2.906-2.906c0.234-0.563 0.375-1.172 0.375-1.828 0-2.766-2.25-5.016-5.016-5.016z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div
      class="not-strong-pass-message"
      v-if="!passwordIsStrong && password !== null && password.length > 0"
    >Your password is easily guessable. You must do better.</div>
    <div class="strong-pass-message" v-if="passwordIsStrong">Your password is strong</div>

    <div v-if="showStrengthMeter" class="meter">
      <div
        v-for="index in 5"
        :class="barClass(index, fulfilledCount)"
        :id="'bar' + index"
        :key="index"
      ></div>
    </div>

    <div class="conditions">
      <div :class="conditionClass('lowercase')">Lower Case</div>
      <div :class="conditionClass('uppercase')">Upper Case</div>
      <div :class="conditionClass('numbers')">Numbers</div>
      <div :class="conditionClass('symbols')">Symbols</div>
      <div :class="conditionClass('minlength')">MinLength({{ this.secureLength }})</div>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'

export default {
  props: {
    /**
     * Input field id
     * @type {String}
     */
    id: {
      type: String,
      default: 'password',
    },
    /**
     * Input field placeholder text
     * @type {String}
     */
    placeholder: {
      type: String,
      default: 'create password',
    },
    /**
     * Input field autocomplete
     * @type {String}
     */
    autocomplete: {
      type: String,
      default: 'none',
    },
    /**
     * Binded value
     * @type {Object}
     */
    value: {
      type: String,
    },
    /**
     * Input field name
     * @type {String}
     */
    name: {
      type: String,
      default: 'password',
    },
    /**
     * Input field required attribute
     * @type {Boolean}
     */
    required: {
      type: Boolean,
      default: true,
    },
    /**
     * Input field disabled attribute
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Password min length.
     * Right now only visual for the badge
     * @type {Number}
     */
    secureLength: {
      type: Number,
      default: 8,
    },
    /**
     * Display badge:
     * The badge shows your
     * password character count
     * up to the defined secureLength
     * @type {Boolean}
     */
    badge: {
      type: Boolean,
      default: true,
    },
    /**
     * Show password toggle:
     * Show icon to toggle
     * the password visibility
     */
    toggle: {
      type: Boolean,
      default: false,
    },
    /**
     * Prop to toggle the
     * cleartext password if
     * toggle is disabled
     */
    showPassword: {
      type: Boolean,
      default: false,
    },
    /**
     * Prop to change the
     * ref of the input
     */
    referanceValue: {
      type: String,
      default: 'input',
    },
    /**
     * Prop to toggle the
     * strength Meter if
     * User wants to implement
     * their own
     */
    showStrengthMeter: {
      type: Boolean,
      default: true,
    },
    /**
     * Prop to toggle the
     * input element if
     * User wants to implement
     * their own input element
     */
    strengthMeterOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * CSS Class for the Input field
     * @type {String}
     */
    defaultClass: {
      type: String,
      default: 'Password__field',
    },
    /**
     * CSS Class for the disabled Input field
     * @type {String}
     */
    disabledClass: {
      type: String,
      default: 'Password__field--disabled',
    },

    /**
     * CSS class for styling the
     * strength meter bars.
     * @type {String}
     */
    strengthMeterClass: {
      type: String,
      default: 'Password__strength-meter',
    },
    /**
     * strengthMeterFillClass sets the
     * individual strength width and fill
     * color of the strength meter bars.
     * @type {String}
     */
    strengthMeterFillClass: {
      type: String,
      default: 'Password__strength-meter--fill',
    },
    /**
     * Label for the show password icon
     */
    labelShow: {
      type: String,
      default: 'Show Password',
    },
    /**
     * Label for the hide password icon
     */
    labelHide: {
      type: String,
      default: 'Hide Password',
    },
  },
  data() {
    return {
      password: null,
    }
  },

  methods: {
    togglePassword() {
      if (this.showPassword) {
        this.$emit('hide')
        this.showPassword = false
      } else {
        this.$emit('show')
        this.showPassword = true
      }
    },
    emitValue(type, value) {
      this.$emit(type, value)
      this.password = value
    },

    barClass(index, fulfilledCount) {
      if (index <= fulfilledCount) {
        return 'bar active'
      }
      return 'bar'
    },

    conditionClass(type) {
      let finalClass = 'condition '

      if (type === 'lowercase') {
        if (this.hasLowerCase()) {
          finalClass += 'fulfilled'
        } else {
          finalClass += 'notfulfilled'
        }
      }

      if (type === 'uppercase') {
        if (this.hasUpperCase()) {
          finalClass += 'fulfilled'
        } else {
          finalClass += 'notfulfilled'
        }
      }

      if (type === 'numbers') {
        if (this.hasNumbers()) {
          finalClass += 'fulfilled'
        } else {
          finalClass += 'notfulfilled'
        }
      }

      if (type === 'symbols') {
        if (this.hasSymbols()) {
          finalClass += 'fulfilled'
        } else {
          finalClass += 'notfulfilled'
        }
      }

      if (type === 'minlength') {
        if (this.satisfyMinLength()) {
          finalClass += 'fulfilled'
        } else {
          finalClass += 'notfulfilled'
        }
      }

      return finalClass
    },

    hasLowerCase() {
      if (this.password !== null && this.password !== '') {
        return /[a-z]/.test(this.password)
      }
      return false
    },
    hasUpperCase() {
      if (this.password !== null && this.password !== '') {
        return /[A-Z]/.test(this.password)
      }
      return false
    },
    hasNumbers() {
      if (this.password !== null && this.password !== '') {
        return /[0-9]/.test(this.password)
      }
      return false
    },
    hasSymbols() {
      if (this.password !== null && this.password !== '') {
        return !/^[A-Za-z0-9]+$/.test(this.password)
      }
      return false
    },
    satisfyMinLength() {
      if (this.password !== null && this.password !== '') {
        return this.password.toString().length >= this.secureLength
      }
      return false
    },
  },

  computed: {
    /**
     * passwordStrength is the score calculated by zxcvbn
     * @return {Number} Password Strength Score
     */
    passwordStrength() {
      //this.$log.log("pass checker score")
      //this.$log.log(this.password ? zxcvbn(this.password).score : null)
      return this.password ? zxcvbn(this.password).score : 0
    },

    /**
     * isSecure checks if the length of the password is longer then
     * the defined `secureLength`
     * @return {Boolean} Password length longer then minLength
     */
    isSecure() {
      return this.password ? this.password.length >= this.secureLength : null
    },

    /**
     * isActive checks if a password is entered.
     * It's required for the password count badge.
     * @return {Boolean} Password entered
     */
    isActive() {
      return this.password && this.password.length > 0
    },

    /**
     * passwordCount holds the character count of the
     * current password. It shows the count up to the `secureLength`.
     * @return {Number} Password Character Count
     */
    passwordCount() {
      return (
        this.password &&
        (this.password.length > this.secureLength
          ? `${this.secureLength}+`
          : this.password.length)
      )
    },
    /**
     * Changing the input type from password to text
     * based on the local _showPassword data or the showPassword prop
     */
    inputType() {
      return this.showPassword ? 'text' : 'password'
    },

    showPasswordLabel() {
      return this.showPassword ? this.labelHide : this.labelShow
    },

    // number of fulfilled conditions between following conditions :
    // numbers, uppercase, lowercase, symbols, minLength
    fulfilledCount() {
      let count = 0
      if (this.hasLowerCase()) count++
      if (this.hasUpperCase()) count++
      if (this.hasNumbers()) count++
      if (this.hasSymbols()) count++
      if (this.satisfyMinLength()) count++
      return count
    },

    passwordIsStrong() {
      return (
        this.hasLowerCase() &&
        this.hasUpperCase() &&
        this.hasNumbers() &&
        this.hasSymbols() &&
        this.satisfyMinLength()
      )
    },
  },

  watch: {
    value(newValue) {
      if (this.strengthMeterOnly) {
        this.emitValue('input', newValue)
      }
    },
    passwordStrength(score) {
      this.$emit('score', score)
      this.$emit('feedback', zxcvbn(this.password).feedback)
    },
  },
}
</script>

<style lang="scss">
.meter {
  position: relative;
  /*height: 3px;*/
  /*background: #DDD;*/
  margin: 4px auto 15px;
  border-radius: 3px;
}

.meter {
  .bar {
    position: absolute;
    background-color: #ddd;
    height: 4px;
    top: 0;
    width: 18%;
    transition: all 250ms;
  }

  .bar[id='bar1'] {
    left: 0;

    &.active {
      background-color: #d84556;
    }
  }

  .bar[id='bar2'] {
    left: 20%;

    &.active {
      background-color: #e48948;
    }
  }

  .bar[id='bar3'] {
    left: 40%;

    &.active {
      background-color: #c4c24d;
    }
  }

  .bar[id='bar4'] {
    left: 61%;

    &.active {
      background-color: #96c654;
    }
  }

  .bar[id='bar5'] {
    left: 82%;

    &.active {
      background-color: #008100;
    }
  }
}

.conditions {
  text-align: center;

  .condition {
    font-weight: bold;
    font-size: 12px;
    margin-right: 15px;
    display: inline-block;

    &.notfulfilled {
      color: #aeb4bc;
      transition: all 250ms;

      &:before {
        transition: all 250ms;
        content: '\2715';
        margin-right: 1px;
      }
    }

    &.fulfilled {
      color: #b4c44c;
      transition: all 250ms;

      &:before {
        transition: all 250ms;
        content: '\2713';
        margin-right: 1px;
      }
    }
  }
}

@media (max-width: 768px) {
  .conditions {
    .condition {
      font-weight: normal;
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.not-strong-pass-message {
  margin-bottom: 0;
  font-size: 14px;
  color: grey;
  text-align: left;
}

.strong-pass-message {
  margin-bottom: 0;
  font-size: 14px;
  color: #008100;
  text-align: left;
}

[v-cloak] {
  display: none;
}

.Password {
  margin: 0 auto;
}

.Password__group {
  position: relative;
  margin-bottom: 10px;
}

.Password__strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 10px auto 20px;
  border-radius: 3px;
}

.Password__field {
  background-color: #f1f5f7;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 13px;
  width: 100%;
}

.Password__field--disabled {
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
}

.Password__icons {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.Password__toggle {
  line-height: 1.1;
  margin-right: 13px;
}

.btn-clean {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #777777;
  padding: 0;

  svg {
    fill: currentColor;
  }

  &:hover,
  &:focus {
    color: #404b69;
  }
}
</style>
