// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/email-sent.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.email-sent-circle {\n  background: #ddf0ff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: 56px 56px;\n  border-radius: 50%;\n  height: 90px;\n  width: 90px;\n  margin: 20px auto;\n}\n.email-sent-label {\n  color: #2196f3;\n  margin-bottom: 60px;\n  font-size: 20px;\n  font-weight: 400;\n}\n", ""]);
// Exports
module.exports = exports;
