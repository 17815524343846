var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: "Transactions" } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "" } },
                    [
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "text-xs-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.balance
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "z-card mb-4" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "transparent pa-0",
                                          attrs: { "two-line": "" }
                                        },
                                        [
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(_vm.balance.name)
                                                      )
                                                    ])
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Account Name")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            _vm.balance.balance
                                                              .value
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Total")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.balance.dateFmt
                                                      )
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v(
                                                      "Balance Last Update"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(_vm.balance.id)
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Balance ID")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(_vm.balance.type)
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Balance Type")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.items
                        ? _c("z-table-transactions", {
                            attrs: { items: _vm.items }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }