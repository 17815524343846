import JwtDecode from 'jwt-decode'

export default class User {
  static from(token) {
    try {
      let obj = JwtDecode(token)
      return new User(obj)
    } catch (_) {
      return {
        loggedIn: false,
      }
    }
  }

  constructor({ id, type, email, fullName, firstName, lastName, exp }) {
    this.loggedIn = true
    this.userId = id
    this.accountType = type
    this.email = email
    this.fullName = fullName
    this.firstName = firstName
    this.lastName = lastName
    this.expireIn = exp
  }

  get name() {
    return this.fullName ? this.fullName : this.email
  }

  get isLoggedIn() {
    //const now = Math.floor(Date.now() / 1000)
    //if (this.loggedIn && localStorage.token && now < this.expireIn) {
    if (this.loggedIn && localStorage.token) {
      return true
    }
    return false
  }

  get isAdmin() {
    return this.isLoggedIn && this.accountType == 'admin'
  }

  get isRepresentative() {
    return this.isLoggedIn && this.accountType == 'representative'
  }

  get isSupport() {
    return this.isLoggedIn && this.accountType == 'support'
  }

  get isAccountant() {
    return this.isLoggedIn && this.accountType == 'accountant'
  }

  get isManager() {
    return this.isLoggedIn && this.accountType == 'manager'
  }

  get isAssistant() {
    return this.isLoggedIn && this.accountType == 'assistant'
  }

  get isProperty() {
    return this.isLoggedIn && this.accountType == 'property'
  }

  get isApplicant() {
    return this.isLoggedIn && this.accountType == 'applicant'
  }

  get isTenantev() {
    return this.isLoggedIn && this.accountType == 'tenantev'
  }
}
