var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        {
          class: {
            "bg-bottom": _vm.showIntroStep && !_vm.paymentMethod,
            "grey lighten-3": _vm.started
          }
        },
        [
          _vm.started && !_vm.success
            ? _c(
                "v-layout",
                {
                  staticClass: "grey lighten-5",
                  attrs: {
                    "pa-2": "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "light-green--text bg-white ma-3",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("arrow_back")])],
                    1
                  ),
                  _vm.currentPayStep == "all"
                    ? _c("v-flex", { staticClass: "my-3 text-xs-center" }, [
                        _c("div", { staticClass: "grey--text" }, [
                          _vm._v("Please pay fees and deposits")
                        ]),
                        _c(
                          "h3",
                          {
                            staticClass:
                              "headline font-weight-bold indigo--text text--darken-4"
                          },
                          [_vm._v(" Fees & Deposits ")]
                        ),
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v(_vm._s(_vm.applicationAddress))
                        ])
                      ])
                    : _vm._e(),
                  _vm.currentPayStep == "deposit"
                    ? _c("v-flex", { staticClass: "my-3 text-xs-center" }, [
                        _c("div", { staticClass: "grey--text" }, [
                          _vm._v("Let’s start by paying first the")
                        ]),
                        _c(
                          "h3",
                          {
                            staticClass:
                              "headline font-weight-bold indigo--text text--darken-4"
                          },
                          [_vm._v(" Deposits ")]
                        ),
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v(_vm._s(_vm.applicationAddress))
                        ])
                      ])
                    : _vm._e(),
                  _vm.currentPayStep == "fee"
                    ? _c("v-flex", { staticClass: "my-3 text-xs-center" }, [
                        _c("div", { staticClass: "grey--text" }, [
                          _vm._v("Let’s start by paying first the")
                        ]),
                        _c(
                          "h3",
                          {
                            staticClass:
                              "headline font-weight-bold indigo--text text--darken-4"
                          },
                          [_vm._v(" Fees ")]
                        ),
                        _c("div", { staticClass: "body-1" }, [
                          _vm._v(_vm._s(_vm.applicationAddress))
                        ])
                      ])
                    : _vm._e(),
                  _c("div", {
                    staticClass: "ma-3",
                    staticStyle: { width: "36px" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.started ? _c("StepperCheckout") : _vm._e(),
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                {
                  attrs: { row: "", "align-center": "", "justify-center": "" }
                },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5 mb-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        !_vm.application && _vm.applications.length > 1
                          ? [
                              _vm.applications.length > 1 ? void 0 : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { "max-width": "600px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-xs-center pt-5 mb-5 grey--text"
                                    },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "headline black--text" },
                                        [
                                          _vm._v(
                                            " You have several pending payments. "
                                          )
                                        ]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          "Please select the payment you wish to complete."
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._l(_vm.applications, function(item) {
                                    return _c(
                                      "v-card",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple",
                                            value: {
                                              class: "light-green--text"
                                            },
                                            expression:
                                              "{ class: 'light-green--text' }"
                                          }
                                        ],
                                        key: item.id,
                                        staticClass: "z-card mb-4 pointer",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectApplication(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pl-0" },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  "align-center": "",
                                                  "justify-space-between": "",
                                                  row: "",
                                                  "fill-height": ""
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "ma-4",
                                                  attrs: {
                                                    src: require("../../assets/icon-property-green.svg")
                                                  }
                                                }),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: { row: "", wrap: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          sm12: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass: "title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.propertyName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          sm12: ""
                                                        }
                                                      },
                                                      [
                                                        _c("h4", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.moveInAddress
                                                            ) +
                                                              " - Unit " +
                                                              _vm._s(
                                                                item.unitNumber
                                                              )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      icon: "",
                                                      ripple: "",
                                                      color: "primary"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "white--text"
                                                      },
                                                      [_vm._v("navigate_next")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ]
                          : _vm._e(),
                        !_vm.application && !_vm.applications.length
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-xs-center pt-5 mb-5 grey--text"
                                },
                                [
                                  _c(
                                    "h2",
                                    { staticClass: "headline black--text" },
                                    [_vm._v(" You have no pending payments. ")]
                                  )
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm.application
                          ? [
                              !_vm.started
                                ? _c("IntroCheckout", {
                                    attrs: {
                                      application: _vm.application,
                                      contractType: _vm.currentPayStep,
                                      totalFees: _vm.totalFees,
                                      totalDeposits: _vm.totalDeposits,
                                      depositsLength: _vm.depositsLength,
                                      feesLength: _vm.feesLength,
                                      variableDeposits: _vm.variableDeposits
                                    },
                                    on: {
                                      step: function($event) {
                                        _vm.showIntroStep++
                                      },
                                      start: function($event) {
                                        _vm.started = true
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.started && !_vm.paying
                                ? _c("CheckoutReview", {
                                    attrs: {
                                      application: _vm.application,
                                      contractType: _vm.currentPayStep
                                    },
                                    on: { change: _vm.checkoutChanged }
                                  })
                                : _vm._e(),
                              _vm.paying && !_vm.paymentMethod
                                ? _c("MyWallet", {
                                    attrs: {
                                      variableSetup: _vm.variableSetup,
                                      application: _vm.application,
                                      totalAmount: _vm.totalAmount,
                                      contractType: _vm.currentPayStep
                                    },
                                    on: { change: _vm.paymentMethodSelected }
                                  })
                                : _vm._e(),
                              _vm.paymentMethod == "preauth"
                                ? _c("PayCreditCard", {
                                    attrs: {
                                      variableSetup: _vm.variableSetup,
                                      application: _vm.application,
                                      totalAmount: _vm.totalAmount,
                                      contractType: _vm.currentPayStep
                                    },
                                    on: { success: _vm.paySuccess }
                                  })
                                : _vm._e(),
                              _vm.paymentMethod == "ach" && !_vm.bankSetup
                                ? _c("BankSetup", {
                                    attrs: {
                                      variableSetup: _vm.variableSetup,
                                      application: _vm.application,
                                      totalAmount: _vm.totalAmount,
                                      contractType: _vm.currentPayStep
                                    },
                                    on: { success: _vm.bankSetupSuccess }
                                  })
                                : _vm._e(),
                              _vm.paymentMethod == "ach" && _vm.bankSetup
                                ? _c("PayAch", {
                                    attrs: {
                                      variableSetup: _vm.variableSetup,
                                      application: _vm.application,
                                      totalAmount: _vm.totalAmount,
                                      contractType: _vm.currentPayStep,
                                      bankSetup: _vm.bankSetup
                                    },
                                    on: { success: _vm.paySuccess }
                                  })
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }