<template>
  <v-app class="page-contract-setup page-with-subnavbar">
    <tev-navbar />
    <tev-subnavbar :back="'/'  + currentUser.accountType + '/properties/' + propertyId + '/contracts'" title="My Smart Contract" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-card class="transparent elevation-0" width="600" max-width="100%">
            <v-flex x12 sm8 offset-sm2 text-xs-center v-if="schedule">
              <p
                class="title font-weight-light mb-4"
              >Select the date and time when you want your smart contract to be disable</p>
              <v-date-picker class="v-card--flat mb-3" v-model="stoppedAt" :reactive="true">
                <v-btn
                  @click="disableContract"
                  :disabled="!stoppedAt"
                  depressed
                  large
                  color="light-green"
                  class="text-uppercase white--text"
                >{{ $t('save') }}</v-btn>
                <v-btn
                  @click="schedule=false"
                  depressed
                  large
                  outline
                  color="indigo darken-4"
                  class="text-uppercase white--text"
                >{{ $t('cancel') }}</v-btn>
              </v-date-picker>
              <v-layout align-center justify-center row></v-layout>
            </v-flex>
            <v-flex x12 sm8 offset-sm2 text-xs-center class="white pa-3 pt-5" v-if="!schedule">
              <template v-if="next">
                <img src="@/assets/schedule.svg" class="mb-2" />

                <p
                  class="title font-weight-light mb-4"
                >When do you want this smart contract to be disable?</p>

                <v-btn
                  @click="disableContract"
                  block
                  depressed
                  large
                  color="light-green"
                  class="text-uppercase mb-3 white--text"
                  :disabled="isDisabling"
                >{{ $t('now') }}</v-btn>
                <v-btn
                  @click="schedule=true"
                  block
                  depressed
                  large
                  color="primary"
                  class="text-uppercase mb-3 white--text"
                  :disabled="isDisabling"
                >Schedule For Later</v-btn>
                <v-btn
                  :to="'/'  + currentUser.accountType + '/properties/'+propertyId  + '/contracts'"
                  block
                  large
                  flat
                  color="primary"
                  class="text-uppercase mb-0 white--text"
                >{{ $t('cancel') }}</v-btn>
              </template>
              <template v-if="!next">
                <img src="@/assets/attention.svg" class="mb-2" />

                <h2 class="headline black--text mb-3">Attention</h2>
                <p
                  class="title font-weight-light mb-4"
                >By suspending this Smart Contract all the charges related to the existing fees will not be collected anymore.</p>

                <v-btn
                  @click="next=true"
                  block
                  depressed
                  large
                  color="pink accent-3"
                  class="text-uppercase mb-3 white--text"
                >{{ $t('continue') }}</v-btn>
                <v-btn
                  :to="'/'  + currentUser.accountType + '/properties/' + propertyId + '/contracts'"
                  block
                  depressed
                  large
                  color="primary"
                  class="text-uppercase mb-0 white--text"
                >{{ $t('cancel') }}</v-btn>
              </template>
            </v-flex>
          </v-card>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isLoading: false,
      isDisabling: false,
      next: false,
      schedule: false,
      propertyId: null,
      contractId: null,
      property: null,
      stoppedAt: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    disableContract() {
      this.isDisabling = true
      this.$http
        .post(
          '/manager/properties/' +
            this.propertyId +
            '/contracts/' +
            this.contractId +
            '/disable',
          {
            stoppedAt: this.stoppedAt,
          }
        )
        .then(() => {
          this.$router.push('/' + this.currentUser.accountType + '/properties/' + this.propertyId +  '/contracts')
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isDisabling = false
        })
    },
    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    this.contractId = this.$route.params.contractId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadProperty()
  },
}
</script>
<style>
.page-contract-setup .v-select__slot .v-chip {
  background: #fff;
}
</style>