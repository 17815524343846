<template>
  <v-app>
    <v-content class="login-background">
      <v-layout row align-center justify-center pt-5 pb-5>
        <z-logo color="#ffffff" slogan class="logo-zinc" />
      </v-layout>
      <v-layout row wrap px-4>
        <v-flex xs12>
          <v-card max-width="500px" class="mx-auto" flat>
            <v-card-title
              class="headline indigo--text text--darken-2 font-weight-bold text-lowercase"
              v-if="step=='code'||step=='password'"
            >{{ $t('create_your_account') }}</v-card-title>
            <!-- /.sign-up-wrapper -->
            <template v-if="step=='cellPhone'">
              <v-card-title
                class="headline indigo--text text--darken-2 font-weight-bold text-lowercase"
              >{{ $t('enter_your_code') }}</v-card-title>
            </template>
            <v-card-text>
              <v-alert
                icon="false"
                outline
                :value="errorMessage"
                type="error"
                class="mb-4"
              >{{ errorMessage }}</v-alert>

              <transition name="fade">
                <div v-if="step=='success'" class="text-xs-center py-4">
                  <img src="@/assets/account-created.svg" class="mb-4" />
                  <h3 class="heading">Congratulations!</h3>
                  <div class="heading mb-3">Your account has been successfully created.</div>
                  <div class="grey--text mb-3">
                    You will redirected to Zinc in {{ successIntervalSeconds }} seconds.
                    <br />
                    <router-link to="/login" tag="a" class="primary-text mr-1">Click here</router-link>to get started now.
                  </div>
                </div>
              </transition>

              <!-- Enter Code -->
              <form v-on:submit.prevent="validateCode" v-if="step=='code'">
                <!--p
                  class="grey--text"
                >Zinc is by invitation only. Please contact your Property Management Company to request and access code to join Zinc.</p-->
                <h3 class="heading indigo--text text--darken-2 mb-2">{{ $t('enter_access_code') }}</h3>
                <v-text-field
                  v-model="verifyCode"
                  :label="$t('access_code')"
                  background-color="#F1F5F7"
                  box
                  flat
                  @input="hasChanges=true"
                  autocomplete="off"
                ></v-text-field>
                <v-btn type="submit" block large depressed color="primary">{{ $t('validate') }}</v-btn>
              </form>

              <!-- Enter Password -->
              <form v-on:submit.prevent="createAccount" v-if="step=='password'">
                <v-text-field
                  v-model="password"
                  :type="newType"
                  @click:append="toggleNew"
                  :append-icon="newIcon"
                  :label="$t('new_password')"
                  :autofocus="$isDesktop()"
                  box
                  background-color="#F1F5F7"
                  flat
                  autocomplete="off"
                ></v-text-field>

                <z-password-strength-meter
                  :strengthMeterOnly="true"
                  v-model="password"
                  :toggle="true"
                  class="mb-2"
                  :secureLength="minPasswordLength"
                ></z-password-strength-meter>

                <v-text-field
                  v-model="confirmPassword"
                  @click:append="toggleConfirm"
                  :append-icon="confirmIcon"
                  :type="confirmType"
                  :label="$t('confirm_password')"
                  :rules="rules.confirmPassword"
                  required
                  box
                  background-color="#F1F5F7"
                  flat
                  class="mt-4"
                  autocomplete="off"
                ></v-text-field>

                <v-btn
                  type="submit"
                  block
                  large
                  depressed
                  color="primary"
                >{{ $t('create_account') }}</v-btn>
              </form>

              <!-- Verify -->
              <form v-on:submit.prevent="verifyPhone" v-if="step=='cellPhone'">
                <p>{{ $t('justsentsms_explain')}}</p>
                <v-text-field
                  v-model="securityCode"
                  :label="$t('security_code')"
                  box
                  background-color="#F1F5F7"
                  flat
                  name="securityCode"
                  autocomplete="off"
                ></v-text-field>
                <p class="grey--text">{{ $t('phoneno') }}: +{{ dialCode }}{{ cellPhone }}</p>
                <!--p class="green--text">
                  <v-icon class="green--text mr-2">check</v-icon>Se envió un nuevo código a tu número móvil.
                </p-->
                <!--p class="py-0 mt-10 mb-0 info--text">Send me a new code</p-->
                <!--div>You can resend code within 55 seconds</div-->
                <v-btn type="submit" block large depressed color="primary">{{ $t('verify') }}</v-btn>
              </form>
            </v-card-text>
          </v-card>
          <z-footer :dark="true"></z-footer>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import ZPasswordStrengthMeter from '@/components/ZPasswordStrengthMeter.vue'
import Validator from '@/libs/validator'
import config from '@/libs/config'
import DisableAutocomplete from '@/mixin/DisableAutocomplete'
import UnsavedChanges from '@/mixin/UnsavedChanges'
import Alerts from '@/mixin/Alerts'
import { MIN_PASSWORD_LENGTH } from '@/libs/constants'

export default {
  data() {
    const _this = this
    const validator = new Validator()
    return {
      decoded: null,
      hasChanges: false,
      step: 'code',
      newType: 'password',
      newIcon: 'remove_red_eye',
      confirmType: 'password',
      confirmIcon: 'remove_red_eye',
      password: null,
      confirmPassword: null,
      minPasswordLength: MIN_PASSWORD_LENGTH,
      email: null,
      verifyCode: null,
      securityCode: null,
      errorMessage: null,
      dialCode: null,
      cellPhone: null,
      requestId: null,
      successIntervalSeconds: 7,
      successInterval: null,

      rules: {
        password: [
          validator.rules.required('You must complete the required fields.'),
          validator.rules.minLength(
            MIN_PASSWORD_LENGTH,
            'Your password must contain no less than ' +
              MIN_PASSWORD_LENGTH +
              ' characters.'
          ),
          validator.rules.maxLength(
            100,
            'Your password should not contain more than 100 characters.'
          ),
        ],
        confirmPassword: [
          validator.rules.matches(
            _this,
            'password',
            'The passwords you entered do not match.'
          ),
        ],
      },
    }
  },
  mixins: [DisableAutocomplete, UnsavedChanges, Alerts],
  components: {
    ZPasswordStrengthMeter,
  },
  methods: {
    toggleNew() {
      this.newType = this.getType(this.newType)
      this.newIcon = this.getIcon(this.newType)
    },
    toggleConfirm() {
      this.confirmType = this.getType(this.confirmType)
      this.confirmIcon = this.getIcon(this.confirmType)
    },
    getType(el) {
      return el == 'text' ? 'password' : 'text'
    },
    getIcon(el) {
      return el == 'text' ? 'more_horiz' : 'remove_red_eye'
    },
    validateCode() {
      if (!this.decoded[0] || !this.decoded[1]) {
        return this.toastError('You must complete the required fields.')
      }
      this.$http
        .post('/public/validate-code', {
          userId: this.decoded[0],
          code: this.decoded[1],
        })
        .then(() => {
          this.step = 'password'
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
    },
    createAccount() {
      if (!this.password || !this.confirmPassword) {
        return this.toastError('You must complete the required fields.')
      }

      if (this.confirmPassword != this.password) {
        return this.toastError('The passwords you entered do not match.')
      }

      if (this.password.length > 100) {
        return this.toastError(
          'Your password should not contain more than 100 characters.'
        )
      }

      if (this.password.length < MIN_PASSWORD_LENGTH) {
        return this.toastError(
          'Your password must contain no less than ' +
            MIN_PASSWORD_LENGTH +
            ' characters.'
        )
      }

      if (!this.password.match(/[a-zA-Z]+/) || !this.password.match(/[0-9]+/)) {
        return this.toastError(
          'Your password must contain numbers and letters.'
        )
      }

      this.$http
        .post('/public/register', {
          userId: this.decoded[0],
          code: this.decoded[1],
          password: this.password,
        })
        .then(response => {
          if ('data' in response && response.data.requestId) {
            this.requestId = response.data.requestId
            this.dialCode = response.data.dialCode
            this.cellPhone = response.data.cellPhone
            this.step = 'cellPhone'
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
    },
    verifyPhone() {
      const _this = this

      if (!this.decoded[0] || !this.securityCode) {
        return this.toastError('You must complete the required fields.')
      }

      this.$http
        .post('/public/verify-account', {
          code: this.securityCode,
          userId: this.decoded[0],
          requestId: this.requestId,
        })
        .then(response => {
          this.step = 'success'
          this.hasChanges = false
          config.set('loginEmail', response.data.email)
          this.successInterval = setInterval(function() {
            _this.successIntervalSeconds -= 1
            if (_this.successIntervalSeconds < 1) {
              _this.$router.push('/login')
            }
          }, 1000)
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.successInterval) {
      clearInterval(this.successInterval)
    }
    next()
  },
  mounted() {
    let code = this.$route.query.code
    if (!code) {
      this.$router.push('/login')
    }
    this.decoded = atob(code).split('.')
    this.$log.debug(this.decoded)
    if (this.decoded[0] || this.decoded[1]) {
      return this.validateCode()
    }
  },
}
</script>

