<template>
  <v-app>
    <tev-navbar v-if="property" :title="(property.name)?'Contracts: ' + property.name:''" />

    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm11>
            <div class="text-xs-center" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex x12 sm11>
            <v-layout v-if="property && !isLoading" row wrap align-start justify-start>
              <v-btn icon class="light-green--text" @click="goBack()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                <div>
                  &nbsp;
                  <template
                    v-if="property"
                  >{{ $t('property_id') }}: {{ property.tevPropertyId }}</template>
                </div>
                <z-status-chip :status="property.status" :label="$t(property.status)"></z-status-chip>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex x12 sm11
            class="pink--text accent-3"
            v-if="!isLoading&&!contracts.length"
          >The property has no contract established. Add the first one.</v-flex>

          <v-flex xs12 sm8 text-xs-center>
            <v-btn
              :to="'/'  + currentUser.accountType + '/properties/'+propertyId+'/contracts/new'"
              block
              depressed
              large
              color="primary"
              class="text-uppercase mb-3 white--text"
            >Add Contract</v-btn>
          </v-flex>  
          
          <v-flex x12 sm11>
            <z-table-contracts v-if="!isLoading && contracts" :items="contracts" :propertyId="propertyId"></z-table-contracts>
          </v-flex>

                
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import Icon1 from '@/assets/one-time-fee.svg'
import Icon2 from '@/assets/recurring-fee.svg'
import ZTableContracts from '../../components/ZTableContracts.vue'

export default {
  title: 'Contracts',
  data() {
    return {
      isLoading: false,
      propertyId: null,
      property: null,
      contracts: [],
      displayContracts: [],
      expand: {},
      contractTypes: {
        fee: {
          icon: Icon1,
          name: 'Move in fee',
          id: 'fee',
        },
        deposit: {
          icon: Icon2,
          name: 'Deposit',
          id: 'deposit',
        },
      },      
    }
  },
  mixins: [Alerts],
  components: {
    'z-table-contracts': ZTableContracts    
  },

  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },

  methods: {
    goBack() {
      this.$router.push(
        '/' + this.currentUser.accountType + '/properties/' + this.propertyId
      )
    },
    expandContract(id, bool) {
      let expand = this.expand
      expand[id] = bool
      this.expand = null
      this.expand = expand
    },

    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
          this.loadContracts()
        })
        .catch(err => {
          this.isLoading = false
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
    },

    loadContracts() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId + '/contracts')
        .then(response => {
          this.contracts = response.data.contracts
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadProperty()
  },
}
</script>

<style lang="scss">
</style>
