<template>
  <v-app>
    <tev-navbar title="Change phone" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex x12 sm8 md6>
          <v-layout row wrap align-start justify-start>
            <v-flex text-xs-left grey--text>
              <v-btn icon class="light-green--text" @click="$router.back()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-list two-line class="transparent py-0">
            <v-list-tile avatar>
              <v-list-tile-avatar size="16">
                <img src="@/assets/ico-phone.svg" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-if="isLoading">
                  <skeleton-loading>
                    <square-skeleton :boxProperties="{width: '120px',height: '14px'}"></square-skeleton>
                  </skeleton-loading>
                </v-list-tile-title>
                <v-list-tile-title
                  v-if="profile && !isLoading"
                >+{{ profile.dialCode }} {{ profile.cellPhone }}</v-list-tile-title>
                <v-list-tile-sub-title>Current phone number</v-list-tile-sub-title>
              </v-list-tile-content>                      
            </v-list-tile>
          </v-list>

          <v-form ref="form" class="form-sm" @submit.prevent="resetPhone">
            <v-container>
              <v-layout row wrap>

                <v-flex xs12 sm12>
                  <v-text-field
                    v-model="cellPhone"
                    label="New phone number"
                    type="number"
                    class="z-callingcode-field"
                    box
                  >
                    <template v-slot:prepend>
                      <z-callingcode-field
                        :enabledCountryCode="true"
                        :preferredCountries="[]"
                        v-model="callingCode"
                        :label="$t('dialcode')"
                      ></z-callingcode-field>
                    </template>
                  </v-text-field>
                </v-flex>
                                

                <v-flex xs12 sm12 mb-5>
                  <v-btn
                    type="submit"
                    color="light-green"
                    dark
                    large
                    block
                    depressed
                    @click="resetPhone"
                  >Submit</v-btn>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import ZCallingCodeField from '@/components/ZCallingCodeField'
import Alerts from '@/mixin/Alerts'

export default {
  data() {
    return {
      isLoading: false,
      profile: null,      
      currentPhone: null,
      callingCode: null,
      cellPhone: null,

    }
  },
  mixins: [Alerts],
  components: {
    'z-callingcode-field': ZCallingCodeField,
  },  
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loadAccount() {
      this.isLoading = true
      this.$http
        .get('/account/profile')
        .then(res => {
          this.profile = res.data
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/')
        })
        .finally(() => {
          this.isLoading = false
        })
    }, 

    resetPhone() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
 
      if (
        !this.callingCode.dialCode ||
        !this.cellPhone
      ) {
        return this.toastError('Empty phone or country code.')
      }
      if (this.cellPhone && this.cellPhone.length<=8){
        return this.toastError('Incomplete phone number.')
      }
      if (this.currentPhone == this.cellPhone) {
        return this.toastError('Your new phone is the same as the current phone.')
      }

      this.$http
        .post('/account/phone/change', {
          dialCode: this.callingCode.dialCode,
          cellPhone: this.cellPhone,
        })
        .then(() => {
          this.toastSuccess('Your phone has been saved successfully.')
          this.currentPhone = ''
          this.newPhone = ''
          this.$router.push('/account')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadAccount()
  }  
}
</script>
