<template>
  <div>
    <v-layout row wrap align-content-space-between>
      <v-flex xs6>
        <label class="v-label theme--light">Signature</label>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 text-xs-right v-if="type === 'draw' && hasSign">
        <a @click="clearSignature">clear signature</a>
      </v-flex>
    </v-layout>
    <input type="hidden" name="signature" :value="base64Image" />
    <div class="signature-container white" :style="{ 'max-width': containerMaxWisth }">
      <input type="hidden" name="signature" ref="signatureInput" />
      <div class="main-actions">
        <div>
          <button
            :class="{ active: type === 'auto', tab: true }"
            @click.prevent="type = 'auto'"
          >Auto</button>
          <button
            :class="{ active: type === 'draw', tab: true }"
            @click.prevent="type = 'draw'"
          >Draw</button>
          <button
            :class="{ active: type === 'load', tab: true }"
            @click.prevent="type = 'load'"
            v-if="enableLoad"
          >Load</button>
        </div>
      </div>
      <div v-if="type === 'auto'" class="auto" :style="{ height: containerHeight }">
        <VueAutoSignature ref="autoSignature" :signatureTextProp="signatureText" @onEnd="onEnd"></VueAutoSignature>
      </div>
      <div v-if="type === 'draw'" class="draw" :style="{ height: containerHeight }">
        <VueSignaturePad
          ref="signaturePad"
          :height="'130px'"
          :width="'455px'"
          :style="'border: 2px dashed #eee!important;margin-left:5px;'"
          :options="{ onBegin, onEnd }"
        />
      </div>
      <div v-if="enableLoad && type === 'load'" class="load" :style="{ height: containerHeight }">
        <VueLoadSignature ref="loadSignature" @onEnd="onEnd" :height="height"></VueLoadSignature>
      </div>
    </div>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad'
import VueAutoSignature from './VueAutoSignature'
import VueLoadSignature from './VueLoadSignature'

export default {
  name: 'TeSignaturePad',
  data() {
    return {
      type: 'auto', // options: draw, auto, load
      base64Image: null,
      hasSign: false,
    }
  },
  computed: {
    canvasHeight() {
      let h = this.height - 45
      return h.toString() + 'px'
    },
    containerMaxWisth() {
      return this.maxWidth + 'px'
    },
    containerHeight() {
      return this.height + 'px'
    },
  },
  props: {
    enableLoad: {
      type: Boolean,
    },
    signatureText: {
      type: String,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 220,
    },
  },
  components: {
    VueAutoSignature,
    VueSignaturePad,
    VueLoadSignature,
  },
  methods: {
    clearSignature() {
      this.$refs.signaturePad.clearSignature()
      this.hasSign = false
    },

    saveSignature() {
      let result

      if (this.type === 'draw') {
        result = this.$refs.signaturePad.saveSignature()
      } else if (this.type === 'auto') {
        result = this.$refs.autoSignature.saveSignature()
      } else if (this.type === 'load') {
        result = this.$refs.loadSignature.saveSignature()
      }

      let data = result.data
      let isEmpty = result.isEmpty

      if (isEmpty) {
        this.hasSign = false
      }

      this.base64Image = data

      return {
        data,
        isEmpty,
      }
    },
    onBegin() {
      this.hasSign = true
    },
    onEnd() {
      this.$refs.signatureInput.value = this.saveSignature().data
      this.$emit('onSignature', this.saveSignature())
    },
  },
}
</script>
<style scoped lang="scss">
.signature-container {
  border: 2px solid #f1f1f1;
  padding: 0;
  position: relative;
  .draw {
    position: relative;
    .actions {
      padding-top: 20px;
      position: relative;
    }
  }
}

.main-actions {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #eae9ee;
  padding: 15px;
  .title {
    vertical-align: center;
    padding-right: 50px;
    margin-top: 8px;
    font-size: 22px;
    color: #b3b2b7;
    font-family: Arial, sans-serif;
  }
  button.tab {
    color: #b3b2b7;
    background-color: transparent;
    font-weight: bold;
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    outline: none;
    &:hover {
      cursor: pointer;
    }
    &.active {
      border-bottom: 3px solid #2e2e2e;
      color: #2e2e2e;
    }
  }
}

@font-face {
  font-family: Calligraphy;
  src: url(~@/assets/fonts/Calligraphy.ttf);
}

@font-face {
  font-family: CollectionFree;
  src: url(~@/assets/fonts/CollectionFree.otf);
}

@font-face {
  font-family: DerickChetty;
  src: url(~@/assets/fonts/DerickChetty.ttf);
}

@font-face {
  font-family: Everything;
  src: url(~@/assets/fonts/Everything.ttf);
}

@font-face {
  font-family: Lemon;
  src: url(~@/assets/fonts/Lemon.otf);
}

@font-face {
  font-family: Moonbright;
  src: url(~@/assets/fonts/Moonbright.ttf);
}

@font-face {
  font-family: Welcome;
  src: url(~@/assets/fonts/Welcome.ttf);
}
</style>
