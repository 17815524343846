var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "px-3 mt-5 mb-4", attrs: { xs12: "", sm8: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pt-3": "", "mb-4": "" } },
                    [
                      _vm.property
                        ? _c("h2", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.property.name))
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "grey--text" }, [
                        _vm._v(_vm._s(_vm.$t("property_name")))
                      ])
                    ]
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "box-border",
                            attrs: {
                              "append-icon": "search",
                              label: _vm.$t("search"),
                              type: "text",
                              autocomplete: "off",
                              box: "",
                              autofocus: _vm.$isDesktop()
                            },
                            on: { "click:append": _vm.newSearch },
                            model: {
                              value: _vm.queryName,
                              callback: function($$v) {
                                _vm.queryName = $$v
                              },
                              expression: "queryName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-chip", { staticClass: "white font-weight-bold" }, [
                    _vm._v("All")
                  ]),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "white font-weight-bold grey--text z-filter-chip"
                    },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "light-green" },
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v("expand_less")
                          ])
                        ],
                        1
                      ),
                      _vm._v("Funded ")
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "white font-weight-bold grey--text z-filter-chip"
                    },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "primary" },
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v("chevron_right")
                          ])
                        ],
                        1
                      ),
                      _vm._v("Pending ")
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "white font-weight-bold grey--text z-filter-chip"
                    },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "red" },
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v("remove")
                          ])
                        ],
                        1
                      ),
                      _vm._v("Fail ")
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "white font-weight-bold grey--text z-filter-chip"
                    },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "pink accent-3" },
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v("priority_high")
                          ])
                        ],
                        1
                      ),
                      _vm._v("Need Attention ")
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "white font-weight-bold grey--text z-filter-chip"
                    },
                    [
                      _c(
                        "v-avatar",
                        { staticClass: "grey" },
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v("date_range")
                          ])
                        ],
                        1
                      ),
                      _vm._v("Date ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "mb-5 text-xs-center",
                  attrs: { xs12: "", sm8: "" }
                },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5 mb-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("z-application"),
                  _c("z-transaction")
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }