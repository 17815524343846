<template>
  <svg
    class="checkmark"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
    :style="'width:' + size + 'px;height:' + size + 'px;'"
    v-bind:class="{ animation: animate }"
  >
    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
    <path
      class="checkmark__check"
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: Number,
    delay: Number,
    bgColor: {
      type: String,
      default: '#8bc34a',
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    return {
      animate: false,
    }
  },
  mounted() {
    const _this = this
    if (_this.delay) {
      setTimeout(function() {
        _this.animate = true
      }, _this.delay)
      return
    }
    _this.animate = true
  },
}
</script>

<style>
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #8bc34a;
  display: inline-block;
}

.checkmark.animation {
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #8bc34a;
  fill: none;
}

.checkmark.animation .checkmark__circle {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.checkmark.animation .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #8bc34a;
  }
}
</style>
