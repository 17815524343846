var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-content",
        { staticClass: "login-background" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "pt-5": "",
                "pb-5": ""
              }
            },
            [
              _c("z-logo", {
                staticClass: "logo-zinc",
                attrs: { color: "#ffffff", slogan: "" }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "px-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto", attrs: { "max-width": "500px" } },
                    [
                      _c(
                        "v-form",
                        { ref: "form", staticClass: "form-sm" },
                        [
                          _c(
                            "v-container",
                            { staticClass: "login-container pa-0" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  !_vm.emailSent
                                    ? _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "headline indigo--text text--darken-2 font-weight-bold"
                                        },
                                        [_vm._v("Forgot Your Password?")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-card-text",
                                    [
                                      _vm.passwordChanged
                                        ? [
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "", wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      sm12: "",
                                                      "text-xs-center": "",
                                                      "align-center": "",
                                                      "justify-content-center":
                                                        ""
                                                    }
                                                  },
                                                  [
                                                    _c("CheckMark", {
                                                      staticClass: "mb-5 mt-4"
                                                    }),
                                                    _vm.newAccount
                                                      ? [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-4"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Your account has been created"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-muted mb-5"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "You can sign into your new account with your email and password."
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-4"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Your password has been changed"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-muted mb-5"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "You can sign into your account with your new password."
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          depressed: "",
                                                          large: "",
                                                          color: "primary",
                                                          to: "/login"
                                                        }
                                                      },
                                                      [_vm._v("LogIn")]
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _vm.newAccount
                                              ? _c("tev-pagetitle", {
                                                  attrs: {
                                                    title:
                                                      "Activate your account",
                                                    subtitle:
                                                      "Enter a password for your new account and then confirm your password by entering it a second time."
                                                  }
                                                })
                                              : _vm._e(),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm12: "" } },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mb-4" },
                                                  [
                                                    _vm._v(
                                                      "Enter your new password for your account and then confirm your password by entering it a second time."
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "", wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      sm12: ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        xs12: "",
                                                        rules:
                                                          _vm.rules.password,
                                                        label: "New Password",
                                                        "append-icon":
                                                          _vm.newIcon,
                                                        type: _vm.newType,
                                                        "background-color":
                                                          "#F1F5F7",
                                                        required: "",
                                                        box: "",
                                                        autofocus: _vm.$isDesktop()
                                                      },
                                                      on: {
                                                        "click:append":
                                                          _vm.toggleNew
                                                      },
                                                      model: {
                                                        value: _vm.newPassword,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.newPassword = $$v
                                                        },
                                                        expression:
                                                          "newPassword"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      sm12: ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        xs12: "",
                                                        rules:
                                                          _vm.rules
                                                            .confirmPassword,
                                                        label:
                                                          "Confirm Password",
                                                        "append-icon":
                                                          _vm.confirmIcon,
                                                        type: _vm.confirmType,
                                                        "background-color":
                                                          "#F1F5F7",
                                                        required: "",
                                                        box: "",
                                                        autofocus: _vm.$isDesktop()
                                                      },
                                                      on: {
                                                        "click:append":
                                                          _vm.toggleConfirm
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.confirmPassword,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.confirmPassword = $$v
                                                        },
                                                        expression:
                                                          "confirmPassword"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      sm12: "",
                                                      "text-xs-center": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        class: {
                                                          "blue--text":
                                                            _vm.isLoading
                                                        },
                                                        attrs: {
                                                          color: "primary",
                                                          block: "",
                                                          large: "",
                                                          depressed: "",
                                                          loading:
                                                            _vm.isLoading,
                                                          disabled:
                                                            _vm.isLoading
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.resetPassword
                                                        }
                                                      },
                                                      [
                                                        _vm.newAccount
                                                          ? [_vm._v("Activate")]
                                                          : [_vm._v("Reset")]
                                                      ],
                                                      2
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          block: "",
                                                          flat: "",
                                                          large: "",
                                                          color: "primary",
                                                          to: "/login"
                                                        }
                                                      },
                                                      [_vm._v("Cancel")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("z-footer", { attrs: { dark: true } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }