var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-members" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("my_property") } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.newSearch($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "align-start": "",
                                "justify-space-between": "",
                                row: "",
                                "fill-height": "",
                                "mb-5": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.isInvite = false
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c("div", { staticClass: "text-xs-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/icon-property-green.svg"),
                                    height: "60"
                                  }
                                }),
                                _c(
                                  "h3",
                                  { staticClass: "font-weight-regular" },
                                  [_vm._v(_vm._s(_vm.$t("transfer_property")))]
                                )
                              ]),
                              _c("z-spacer", { attrs: { width: 52 } })
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "new_property_management_company"
                                      ),
                                      type: "text",
                                      box: "",
                                      autofocus: _vm.$isDesktop()
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("manager_first_name"),
                                      type: "text",
                                      box: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("manager_last_name"),
                                      type: "text",
                                      box: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("cell_phone"),
                                      type: "text",
                                      box: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("email"),
                                      type: "email",
                                      box: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.roleTypes,
                                      "append-icon": "expand_more",
                                      "item-text": "name",
                                      "item-value": "type",
                                      label: "Role",
                                      box: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        color: "light-green",
                                        dark: "",
                                        depressed: "",
                                        block: "",
                                        large: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isInvite = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("transfer_property"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }