<template>
  <v-app>
    <tev-navbar :title="$t('my_property')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8 md6>
            <v-flex x12 sm12>
              <v-layout row wrap align-start justify-start>
                <v-btn icon class="light-green--text" @click="$router.back()">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-flex py-3 text-xs-right grey--text>
                  <div>
                    &nbsp;
                    <template v-if="propertyId">{{ $t('property_id') }}: {{ propertyId }}</template>
                  </div>
                  <z-status-chip :status="property.status" :label="$t(property.status)"></z-status-chip>
                </v-flex>
              </v-layout>

              <!-- property_info -->
              <v-flex class="zinc-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>{{ $t('property_info') }}</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body">
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.name }}</div>
                    <div class="zinc-box__label">{{ $t('property_name') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div
                      class="zinc-box__value"
                    >{{ property.address.line1 }} {{ property.address.line1 ? ', '+property.address.line1 : '' }}</div>
                    <div class="zinc-box__label">{{ $t('address') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.city }}</div>
                    <div class="zinc-box__label">{{ $t('city') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.state }}</div>
                    <div class="zinc-box__label">{{ $t('state') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.postalCode }}</div>
                    <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                  </div>
                </v-flex>
              </v-flex>
              <!-- / property_info -->
            </v-flex>

            <!-- property_management_company -->
            <!--
            <v-flex xs12 sm12>
              <v-flex class="zinc-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>{{ $t('property_management_company') }}</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body grey--text py-4">{{ property.organization }}</v-flex>
              </v-flex>

            </v-flex>
            -->

            <!-- smart_contracts -->
            <!--
            <v-flex xs12 sm12>
              <v-flex class="zinc-box zinc-smartservices-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>{{ $t('My Smart Contracts') }}</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body text-xs-center">
                  <v-layout row wrap>
                    <v-flex xs12 sm12 pa-4>
                      <div>
                        <img src="../../assets/ico-smartcontract.svg" />
                      </div>
                      <h4 class="title font-weight-regular mb-2">Smart Contracts</h4>
                      <v-btn
                        depressed
                        large
                        color="primary"
                        class="text-uppercase mb-3"
                      >Add New Smart Contract</v-btn>
                      <div class="body-1">
                        <a>What is a Smart Contract?</a>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-flex>
            </v-flex>
            -->

            <v-flex class="zinc-box pa-3">
              <div class="mb-2">Assign Property Setup Fee</div>
              <v-select
                readonly
                single-line
                v-model="property.setupFee"
                :items="['50.00']"
                box
                background-color="#F1F5F7"
                flat
                label="Select a amount"
                append-icon="keyboard_arrow_down"
                required
                hide-details
              ></v-select>
            </v-flex>

            <!-- Accounting Admin -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>Accounting Admin</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body px-2">
                <v-list single-line class="transparent mb-4" v-if="!accountants.length">
                  <v-list-tile @click="assignAccountant">
                    <v-list-tile-content>
                      <v-list-tile-title>Accounting Admin not assingned</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>

                <v-list two-line class="transparent mb-4" v-if="accountants.length">
                  <v-list-tile
                    v-for="item in accountants"
                    :key="item.title"
                    avatar
                    :to="'/admin/members/'+item.id"
                  >
                    <v-list-tile-avatar>
                      <Lettericon :name="item.fullName" size="2"></Lettericon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.fullName }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn small icon ripple color="primary">
                        <v-icon class="white--text">chevron_right</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </v-flex>

            <!-- Property Manager -->
            <v-flex class="zinc-box">
              <v-flex xs12 class="zinc-box__title">
                <h3>Property Manager</h3>
              </v-flex>
              <v-flex xs12 class="zinc-box__body px-2">
                <v-list single-line class="transparent mb-4" v-if="!managers.length">
                  <v-list-tile @click="assignManager">
                    <v-list-tile-content>
                      <v-list-tile-title>Property Manager not assigned</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>

                <v-list two-line class="transparent mb-4" v-if="managers.length">
                  <v-list-tile
                    v-for="item in managers"
                    :key="item.title"
                    avatar
                    :to="'/admin/members/'+item.id"
                  >
                    <v-list-tile-avatar>
                      <Lettericon :name="item.fullName" size="2"></Lettericon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.fullName }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn small icon ripple color="primary">
                        <v-icon class="white--text">chevron_right</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </v-flex>
            <!-- / active_members -->

            <v-flex xs12 sm12 pb-3 text-xs-center>
              <v-btn
                :to="'/admin/properties/'+propertyId+'/activity'"
                block
                depressed
                large
                class="text-uppercase mb-3 white--text grey darken-3"
              >{{ $t('property_activity') }}</v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '@/components/Lettericon'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Property Details',
  data() {
    return {
      isLoading: false,
      isMembersLoading: false,
      propertyId: null,
      members: [],
      property: {
        name: null,
        setupFee: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
        },
        status: null,
        organizationId: null,
        organizationName: null,
        organizationStatus: null,
      },
    }
  },
  mixins: [Alerts],
  components: {
    Lettericon,
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
    managers() {
      return this.members.filter(item => item.accountType == 'manager')
    },
    accountants() {
      return this.members.filter(item => item.accountType == 'accountant')
    },
  },
  methods: {
    assignManager() {
      this.$router.push(
        '/admin/properties/' +
          this.propertyId +
          '/invite?memberType=manager'
      )
    },
    assignAccountant() {
      this.$router.push(
        '/admin/properties/' +
          this.propertyId +
          '/invite?memberType=accountant'
      )
    },
    loadProperty() {
      this.$http
        .get('/admin/properties/' + this.propertyId)
        .then(res => {
          this.property = res.data[0]
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/admin/properties')
        })
    },
    loadMembers() {
      this.isMembersLoading = true
      this.$http
        .get('/admin/properties/' + this.propertyId + '/members')
        .then(response => {
          this.members = response.data.members
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.$toast(err.response.data.detail, {
              color: 'red',
              timeout: 10000,
            })
          }
        })
        .finally(() => {
          this.isMembersLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/admin/properties')
    }
    this.loadProperty()
    this.loadMembers()
  },
}
</script>