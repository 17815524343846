<template>
  <v-app>
    <tev-navbar v-if="property" :title="(property.name)?'Applicants: ' + property.name:''" />

    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm11>
            <div class="text-xs-center" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex x12 sm11>
            <v-layout v-if="property && !isLoading" row wrap align-start justify-start>
              <v-btn icon class="light-green--text" @click="goBack()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                <div>
                  &nbsp;
                  <template
                    v-if="property"
                  >{{ $t('property_id') }}: {{ property.tevPropertyId }}</template>
                </div>
                <z-status-chip :status="property.status" :label="$t(property.status)"></z-status-chip>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex x12 sm11
            class="pink--text accent-3"
            v-if="!isLoading&&!applications.length"
          >The property has no applications in Zinc yet.</v-flex>


          
          <v-flex x12 sm11>
            <z-table-applications v-if="!isLoading && applications" :items="applications" :propertyId="propertyId"></z-table-applications>
          </v-flex>

                
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import ZTableApplications from '../../components/ZTableApplications.vue'

export default {
  title: 'Applications',
  data() {
    return {
      isLoading: false,
      propertyId: null,
      property: null,
      applications: [],
      displayApplications: [],
      expand: {},    
    }
  },
  mixins: [Alerts],
  components: {
    'z-table-applications': ZTableApplications    
  },

  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },

  methods: {
    goBack() {
      this.$router.push(
        '/' + this.currentUser.accountType + '/properties/' + this.propertyId
      )
    },
    expandContract(id, bool) {
      let expand = this.expand
      expand[id] = bool
      this.expand = null
      this.expand = expand
    },

    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId)
        .then(response => {
          this.property = response.data[0]
          this.loadApplications()
        })
        .catch(err => {
          this.isLoading = false
          this.$log.error(err)
          this.$router.push('/' + this.currentUser.accountType + '/properties')
        })
    },

    loadApplications() {
      this.isLoading = true
      this.$http
        .get('/manager/properties/' + this.propertyId + '/applications')
        .then(response => {
          this.applications = response.data.applications

          this.$log.info(this.applications)
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/' + this.currentUser.accountType + '/properties')
    }
    this.loadProperty()
  },
}
</script>

<style lang="scss">
</style>
