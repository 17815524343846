var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("transactions") } }),
      _c("tev-subnavbar", { attrs: { title: _vm.$t("transactions_details") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm12: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            "py-2": "",
                            "text-xs-right": "",
                            "grey--text": ""
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("id_number")) +
                              ": " +
                              _vm._s(_vm.applicationId)
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("h3", [
                                _vm._v(_vm._s(_vm.$t("applicant_info")))
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.applicantName))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("applicant_name")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.mobilePhone))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("mobile_phone")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.email))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("email")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.dateBirth))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("date_of_birth")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.ssNumber))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("us_social_security")))
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("h3", [
                                _vm._v(_vm._s(_vm.$t("property_info")))
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.propertyName))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("property_name")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.address))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("address")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.city))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("city")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.state))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("state")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.zipCode))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("zip_code")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.moveInDate))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("move_in_date")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.monthlyRent))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("monthly_rent")))
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__footer",
                              attrs: { x12: "" }
                            },
                            [_vm._v(_vm._s(_vm.$t("type_of_application")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("h3", [
                                _vm._v(_vm._s(_vm.$t("transaction_status")))
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body",
                              attrs: {
                                xs12: "",
                                "pb-4": "",
                                "text-xs-center": ""
                              }
                            },
                            [
                              _c("z-progress-tx", { attrs: { step: 3 } }),
                              _c(
                                "h4",
                                {
                                  staticClass: "subheading font-weight-medium"
                                },
                                [_vm._v("03.26.2019")]
                              ),
                              _c("div", { staticClass: "grey--text body-2" }, [
                                _vm._v("Documents signed")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(_vm.$t("detail_of_collected_fees"))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body",
                              attrs: { xs12: "" }
                            },
                            [
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.$t("move_in_fee")))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("fee")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t(_vm.status.toLowerCase()))
                                  )
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("status")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.typeFee))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("type_of_fee")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.dueDate))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("due_date")))
                                ])
                              ]),
                              _c("div", { staticClass: "zinc-box__item" }, [
                                _c("div", { staticClass: "zinc-box__value" }, [
                                  _vm._v(_vm._s(_vm.rate))
                                ]),
                                _c("div", { staticClass: "zinc-box__label" }, [
                                  _vm._v(_vm._s(_vm.$t("rate")))
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "zinc-box-total primary white--text",
                          attrs: {
                            "pa-4": "",
                            "mb-5": "",
                            "text-xs-center": ""
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("total_to_be_collected")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "headline font-weight-bold" },
                            [_vm._v("$150.00")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }