<template>
  <v-app>
    <v-content class="login-background">
      <v-layout row align-center justify-center pt-5 pb-5>
        <z-logo color="#ffffff" slogan class="logo-zinc" />
      </v-layout>
      <v-layout row wrap px-4>
        <v-flex xs12>
          <v-card max-width="500px" class="mx-auto">
            <v-form ref="form" class="form-sm">
              <v-container class="login-container pa-0">
                <v-card flat>
                  <v-card-title
                    class="headline indigo--text text--darken-2 font-weight-bold"
                    v-if="!emailSent"
                  >Forgot Your Password?</v-card-title>
                  <v-card-text>
                    <template v-if="passwordChanged">
                      <v-layout row wrap>
                        <v-flex xs12 sm12 text-xs-center align-center justify-content-center>
                          <CheckMark class="mb-5 mt-4" />
                          <template v-if="newAccount">
                            <h3 class="mb-4">Your account has been created</h3>
                            <p
                              class="text-muted mb-5"
                            >You can sign into your new account with your email and password.</p>
                          </template>
                          <template v-else>
                            <h3 class="mb-4">Your password has been changed</h3>
                            <p
                              class="text-muted mb-5"
                            >You can sign into your account with your new password.</p>
                          </template>
                          <v-btn depressed large color="primary" to="/login">LogIn</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                    <template v-else>
                      <tev-pagetitle
                        title="Activate your account"
                        subtitle="Enter a password for your new account and then confirm your password by entering it a second time."
                        v-if="newAccount"
                      />
                      <v-flex xs12 sm12>
                        <p
                          class="mb-4"
                        >Enter your new password for your account and then confirm your password by entering it a second time.</p>
                      </v-flex>
                      <v-layout row wrap>
                        <v-flex xs12 sm12>
                          <v-text-field
                            xs12
                            v-model="newPassword"
                            :rules="rules.password"
                            label="New Password"
                            @click:append="toggleNew"
                            :append-icon="newIcon"
                            :type="newType"
                            background-color="#F1F5F7"
                            required
                            box
                            :autofocus="$isDesktop()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12>
                          <v-text-field
                            xs12
                            v-model="confirmPassword"
                            :rules="rules.confirmPassword"
                            label="Confirm Password"
                            @click:append="toggleConfirm"
                            :append-icon="confirmIcon"
                            :type="confirmType"
                            background-color="#F1F5F7"
                            required
                            box
                            :autofocus="$isDesktop()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 text-xs-center>
                          <v-btn
                            color="primary"
                            block
                            large
                            depressed
                            @click="resetPassword"
                            :loading="isLoading"
                            v-bind:class="{'blue--text': isLoading}"
                            :disabled="isLoading"
                          >
                            <template v-if="newAccount">Activate</template>
                            <template v-else>Reset</template>
                          </v-btn>
                          <v-btn block flat large color="primary" to="/login">Cancel</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-form>
          </v-card>
          <z-footer :dark="true"></z-footer>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Validator from '../libs/validator'
import CheckMark from '../components/CheckMark'

export default {
  data() {
    const _this = this
    const validator = new Validator()
    return {
      isLoading: false,

      newAccount: false,

      email: null,
      verifyCode: null,

      newPassword: null,
      confirmPassword: null,

      passwordChanged: false,

      newType: 'password',
      newIcon: 'remove_red_eye',
      confirmType: 'password',
      confirmIcon: 'remove_red_eye',

      snackbar: false,
      snackbarText: '',

      rules: {
        password: [
          validator.rules.required('You must complete the required fields.'),
          validator.rules.minLength(
            8,
            'Your password must contain no less than 8 characters.'
          ),
          validator.rules.maxLength(
            100,
            'Your password should not contain more than 100 characters.'
          ),
        ],
        confirmPassword: [
          validator.rules.matches(
            _this,
            'newPassword',
            'The passwords you entered do not match.'
          ),
        ],
      },
    }
  },
  components: {
    CheckMark,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    getType(el) {
      return el == 'text' ? 'password' : 'text'
    },
    getIcon(el) {
      return el == 'text' ? 'more_horiz' : 'remove_red_eye'
    },
    toggleNew() {
      this.newType = this.getType(this.newType)
      this.newIcon = this.getIcon(this.newType)
    },
    toggleConfirm() {
      this.confirmType = this.getType(this.confirmType)
      this.confirmIcon = this.getIcon(this.confirmType)
    },
    resetPassword() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.isLoading = true
      this.$http
        .post('/public/password/reset', {
          email: this.email,
          code: this.verifyCode,
          password: this.newPassword,
        })
        .then(() => {
          this.passwordChanged = true
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.$toast(err.response.data.detail, {
              color: 'red',
              timeout: 6000,
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.email = this.$route.query.email
    this.verifyCode = this.$route.query.code
    if (!this.email || !this.verifyCode) {
      this.$router.push('/login')
    }
  },
}
</script>