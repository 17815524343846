var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      key: _vm.notification.id,
      staticClass: "mb-3 elevation-1",
      class: { "grey lighten-3": _vm.notification.status == "read" },
      attrs: { color: "white" }
    },
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c("div", [
          _vm.notification.title
            ? _c("h4", [_vm._v(_vm._s(_vm.notification.title))])
            : _vm._e(),
          _vm.notification.message
            ? _c("span", [_vm._v(_vm._s(_vm.notification.message))])
            : _vm._e()
        ])
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm.notification.status == "unread"
            ? _c("v-btn", { attrs: { color: "primary", flat: "" } }, [
                _vm._v("Mark as read")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }