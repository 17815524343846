<template>
  <v-layout align-center justify-center column v-if="!isOnline">
    <v-icon :size="iconSize" class="grey--text text--lighten-2 mb-4">wifi_off</v-icon>
    <h4 class="title mb-4">You are offline. Connect to the internet.</h4>
    <v-btn
      color="primary"
      flat
      depressed
      class="text-uppercase"
      @click="$emit('reload', true)"
      v-if="showButton"
    >{{ $t('Reload') }}</v-btn>
  </v-layout>
</template>

<script>
export default {
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '100px',
    },
  },
  data() {
    return {}
  },
}
</script>
<style>
</style>