var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", {
        attrs: {
          title: _vm.property.name ? "Property: " + _vm.property.name : ""
        }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { x12: "", sm12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "align-start": "",
                                "justify-start": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goBack()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "py-3": "",
                                    "text-xs-right": "",
                                    "grey--text": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" "),
                                      _vm.propertyId
                                        ? [
                                            _vm._v(
                                              _vm._s(_vm.$t("property_id")) +
                                                ": " +
                                                _vm._s(
                                                  _vm.property.tevPropertyId
                                                )
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("z-status-chip", {
                                    attrs: {
                                      status: _vm.property.status,
                                      label: _vm.$t(_vm.property.status)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.paymentMethod == "ach"
                        ? [
                            _vm.paymentMethod == "ach" && !_vm.bankSetup
                              ? _c("BankSetup", {
                                  attrs: {
                                    variableSetup: _vm.variableSetup,
                                    application: _vm.application,
                                    totalAmount: _vm.totalAmount,
                                    contractType: _vm.currentPayStep,
                                    property: _vm.property
                                  },
                                  on: { success: _vm.bankSetupSuccess }
                                })
                              : _vm._e(),
                            _vm.paymentMethod == "ach" && _vm.bankSetup
                              ? _c("PayAch", {
                                  attrs: {
                                    variableSetup: _vm.variableSetup,
                                    application: _vm.application,
                                    totalAmount: _vm.totalAmount,
                                    contractType: _vm.currentPayStep,
                                    bankSetup: _vm.bankSetup,
                                    property: _vm.property
                                  },
                                  on: { success: _vm.paySuccess }
                                })
                              : _vm._e()
                          ]
                        : [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "z-card" },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "px-2 py-1 pl-3 pr-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "zinc-box__item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "zinc-box__value property-name"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.property.name)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__label"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("property_name")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "zinc-box__item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__value"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.property.address.line1
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__label"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("address"))
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "zinc-box__item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__value"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.property.address.city
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__label"
                                              },
                                              [_vm._v(_vm._s(_vm.$t("city")))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "zinc-box__item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__value"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.property.address.state
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__label"
                                              },
                                              [_vm._v(_vm._s(_vm.$t("state")))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "zinc-box__item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__value"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.property.address
                                                        .postalCode
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "zinc-box__label"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("zip_code"))
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.managers.length
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-uppercase grey--text text--lighten-1 mb-3"
                                      },
                                      [_vm._v(" Members ")]
                                    ),
                                    _c(
                                      "v-card",
                                      { staticClass: "z-card" },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "px-2 py-1" },
                                          [
                                            _c(
                                              "v-list",
                                              {
                                                staticClass: "transparent",
                                                attrs: { "two-line": "" }
                                              },
                                              [
                                                _vm._l(_vm.managers, function(
                                                  item,
                                                  index
                                                ) {
                                                  return [
                                                    index > 0
                                                      ? _c("v-divider", {
                                                          key: "d" + index
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "v-list-tile",
                                                      {
                                                        key: "t" + index,
                                                        attrs: {
                                                          avatar: "",
                                                          to:
                                                            "/accountant/members/" +
                                                            item.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-avatar",
                                                          [
                                                            _c("Lettericon", {
                                                              attrs: {
                                                                name:
                                                                  item.fullName,
                                                                size: "2"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-tile-content",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.fullName
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-tile-sub-title",
                                                              [
                                                                _vm._v(
                                                                  "Property Manager"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-tile-action",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  icon: "",
                                                                  ripple: "",
                                                                  color:
                                                                    "primary"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "white--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "chevron_right"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                })
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isLoading
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                                  [
                                    _vm.isLoading
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-xs-center pa-5 mb-5"
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                size: 50,
                                                color: "primary",
                                                indeterminate: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isLoading
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                                  [
                                    _vm.dwStatus == "unverified"
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "z-card primary--border mb-4"
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-3" },
                                              [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      "align-center": "",
                                                      "justify-start": "",
                                                      row: ""
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        src: require("@/assets/icon-onboarding.svg"),
                                                        width: "50",
                                                        height: "50"
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "primary--text font-weight-bold mb-1 text-uppercase text-xs-left"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Property Onboarding "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-spacer")
                                                  ],
                                                  1
                                                ),
                                                !_vm.startOnboarding
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text body-1 mt-4 mb-1"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " In order to start collecting fees and deposits, we need to verify some information related with your property. Before you continue, here is a check list of the information required in order to onboard your property: "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "light-green"
                                                                  }
                                                                },
                                                                [_vm._v("done")]
                                                              ),
                                                              _vm._v(
                                                                "Registration and personal Information "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "light-green"
                                                                  }
                                                                },
                                                                [_vm._v("done")]
                                                              ),
                                                              _vm._v(
                                                                "Controller Information "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "light-green"
                                                                  }
                                                                },
                                                                [_vm._v("done")]
                                                              ),
                                                              _vm._v(
                                                                "Company Information "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "light-green"
                                                                  }
                                                                },
                                                                [_vm._v("done")]
                                                              ),
                                                              _vm._v(
                                                                "Company Details "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "light-green"
                                                                  }
                                                                },
                                                                [_vm._v("done")]
                                                              ),
                                                              _vm._v(
                                                                "Add bank accounts and setup fee "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-xs-right"
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "text-uppercase",
                                                              attrs: {
                                                                large: "",
                                                                round: "",
                                                                color:
                                                                  "primary",
                                                                depressed: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.startOnboarding = true
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Start Property Onboarding"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _c(
                                              "transition",
                                              { attrs: { name: "fade" } },
                                              [
                                                _vm.startOnboarding
                                                  ? _c("PropertyOnboarding", {
                                                      staticClass: "ml-1",
                                                      attrs: {
                                                        propertyId:
                                                          _vm.propertyId,
                                                        property: _vm.property
                                                      },
                                                      on: {
                                                        success: _vm.loadStatus
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dwStatus && _vm.dwStatus != "unverified"
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "z-card primary--border mb-4"
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-3" },
                                              [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    staticClass: "mb-4",
                                                    attrs: {
                                                      "align-center": "",
                                                      "justify-start": "",
                                                      row: ""
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        src: require("@/assets/icon-onboarding.svg"),
                                                        width: "50",
                                                        height: "50"
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "primary--text font-weight-bold mb-1 text-uppercase text-xs-left"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Property Onboarding "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-spacer")
                                                  ],
                                                  1
                                                ),
                                                _vm.dwStatus == "verified"
                                                  ? _c("div", [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "green--text font-weight-bold"
                                                        },
                                                        [_vm._v("Verified")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " The identifying information submitted was sufficient in verifying the account. "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _vm.dwStatus == "certify"
                                                  ? _c("div", [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "grey--text font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Certifying Beneficial Ownership"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pt-1 grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " In order to complete property onboarding must certify beneficial ownership as imposed by the United States Federal customer due diligence rule and Zinc. "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pt-1 grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(" I, "),
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.currentUser
                                                                  .fullName
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " (as Accountant Administrator of property "
                                                          ),
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.property
                                                                  .name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            "), hereby certify, to the best of my knowledge, that the information provided for this property is complete and correct. "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-xs-right"
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                round: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.submitCertify()
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Submit")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ])
                                                  : _vm.dwStatus == "suspended"
                                                  ? _c("div", [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "red--text font-weight-bold"
                                                        },
                                                        [_vm._v("Suspended")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " This account has been suspended, please contact us. "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _vm.dwStatus == "retry" ||
                                                    _vm.dwStatus == "document"
                                                  ? _c("div", [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "red--text font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Verfication failed"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " The initial identity verification attempt failed because the information provided did not satisfy verification check. "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please review it and submit valid identity documentation "
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                round: "",
                                                                color:
                                                                  "primary",
                                                                outline: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.dwStatus =
                                                                    "unverified"
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Submit")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ])
                                                  : _c("div", [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "orange--text font-weight-bold"
                                                        },
                                                        [_vm._v("Pending")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text body-1 mb-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " The documents you submitted are waiting to be approved. "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dwStatus == "verified" &&
                                    !_vm.property.setupPayed
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "mb-4": "" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "z-card primary--border"
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "pa-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "primary--text mb-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              _vm.totalAmount
                                                            )
                                                          ) +
                                                            " Property Setup Fee"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grey--text body-1 mb-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Accountant admin need to make payment in order to give property access to bank accounts setup. "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-xs-right"
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              round: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.paymentMethod =
                                                                  "ach"
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Pay Setup Fee Now"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dwStatus == "verified" &&
                                    _vm.banks.length
                                      ? _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              sm12: "",
                                              "mb-4": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { "py-3": "" } },
                                                  [
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "text-uppercase grey--text text--lighten-1 mb-3"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Bank Accounts "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      "py-3": "",
                                                      "text-xs-right": "",
                                                      "grey--text": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.banks.length
                                                        ) +
                                                        " / " +
                                                        _vm._s(
                                                          _vm.property.maxBanks
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card",
                                              { staticClass: "z-card" },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "px-2 py-1" },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      {
                                                        staticClass:
                                                          "transparent",
                                                        attrs: {
                                                          "two-line": ""
                                                        }
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.banks,
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return [
                                                              index > 0
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      key:
                                                                        "d" +
                                                                        item.id
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-list-tile",
                                                                {
                                                                  key: item.id,
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$router.push(
                                                                        "/accountant/properties/" +
                                                                          _vm.propertyId +
                                                                          "/banks/" +
                                                                          item.id
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-tile-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-tile-title",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.alias
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-tile-sub-title",
                                                                        [
                                                                          _vm._v(
                                                                            "Bank Account Alias"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-tile-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-tile-title",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.last4
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-tile-sub-title",
                                                                        [
                                                                          _vm._v(
                                                                            "4 last digits"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-tile-action",
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            "no-ripple":
                                                                              "",
                                                                            small:
                                                                              "",
                                                                            icon:
                                                                              "",
                                                                            ripple: false,
                                                                            disabled:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "grey--text",
                                                                              attrs: {
                                                                                size:
                                                                                  "32"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "chevron_right"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.dwStatus == "verified" &&
                            _vm.property.setupPayed &&
                            _vm.banks.length < _vm.property.maxBanks &&
                            _vm.property.status != "suspended"
                              ? _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": "",
                                      "pb-3": "",
                                      "text-xs-center": ""
                                    }
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "text-uppercase rounded mb-2 white--text",
                                            attrs: {
                                              to:
                                                "/accountant/properties/" +
                                                _vm.propertyId +
                                                "/bank",
                                              depressed: "",
                                              large: "",
                                              color: "primary"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/add-bank.svg"),
                                                height: "64"
                                              }
                                            })
                                          ]
                                        ),
                                        _c("div", { staticClass: "body-1" }, [
                                          _vm._v("Add Bank Account")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }