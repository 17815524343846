<template>
  <v-select
    ref="codePicker"
    background-color="#e0e0e0"
    :value="activeCountry.dialCode"
    @input="updateValue"
    item-value="dialCode"
    :items="sortedCountries"
    class="z-callingcode-select"
    :label="label"
    return-object
    hide-no-data
    box
  >
    <template v-slot:no-data></template>
    <template v-slot:selection="{ item }">
      <div v-if="enabledFlags" :class="String(item.iso2).toLowerCase()" class="iti-flag" />
      <span v-if="enabledCountryCode" class="country-code">+{{ item.dialCode }}</span>
    </template>
    <template v-slot:item="{ item }">
      <div v-if="enabledFlags" :class="String(item.iso2).toLowerCase()" class="iti-flag" />
      <span class="text-no-wrap text-truncate ml-2">
        {{ item.name }}
        <em class="grey--text pr-2">+{{ item.dialCode }}</em>
      </span>
    </template>
  </v-select>
</template>
<script>
import allCountries from '../libs/all-countries'

export default {
  name: 'z-callingcode-select',
  props: {
    value: [String, Number, Object],
    label: {
      type: String,
      default: 'Dial Code',
    },
    disabledFetch: {
      type: Boolean,
      default: false,
    },
    allCountries: {
      type: Array,
      default: () => allCountries,
    },
    preferredCountries: {
      type: Array,
      default: () => [],
    },
    onlyCountries: {
      type: Array,
      default: () => [],
    },
    ignoredCountries: {
      type: Array,
      default: () => [],
    },
    enabledCountryCode: {
      type: Boolean,
      default: true,
    },
    enabledFlags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeCountry: {
        iso2: '',
      },
    }
  },
  computed: {
    sortedCountries() {
      return this.allCountries
    },
  },
  methods: {
    getCountries(list = []) {
      return list
        .map(countryCode => this.findCountryByIso(countryCode))
        .filter(Boolean)
    },
    findCountryByIso(iso = '') {
      return this.allCountries.find(
        country => country.iso2 === iso.toUpperCase()
      )
    },
    findCountryByDial(dial = 0) {
      return this.allCountries.find(country => country.dialCode === dial)
    },
    updateValue(obj) {
      this.activeCountry = obj
      this.$emit('input', this.activeCountry)
    },
  },
  mounted() {
    if (!this.value && !this.disabledFetch) {
      // Default US
      this.updateValue(this.findCountryByIso('us') || this.activeCountry)
    }

    if (this.value) {
      if (Number.isInteger(this.value) || /^\d+$/.test(this.value)) {
        this.updateValue(
          this.findCountryByDial(this.value) || this.activeCountry
        )
      } else if (
        Object.prototype.toString.call(this.value) === '[object String]'
      ) {
        this.updateValue(
          this.findCountryByIso(this.value) || this.activeCountry
        )
      } else {
        if (this.value.iso2) {
          this.updateValue(
            this.findCountryByIso(this.value.iso2) || this.activeCountry
          )
        } else if (this.value.dialCode) {
          this.updateValue(
            this.findCountryByDial(this.value.dialCode) || this.activeCountry
          )
        }
      }
    }
  },
}
</script>
<style src="../assets/flags-sprite.css"></style>
<style>
.z-callingcode-field input[type='number']::-webkit-inner-spin-button,
.z-callingcode-field input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.z-callingcode-field.v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 0;
  margin-right: 0;
}
.z-callingcode-field
  .v-select.v-text-field--enclosed:not(.v-text-field--single-line)
  .v-select__selections {
  width: 70px;
}
.z-callingcode-field
  .v-select.z-callingcode-select__dialcode.v-text-field--enclosed:not(.v-text-field--single-line)
  .v-select__selections {
  width: 90px;
}
.z-callingcode-field .v-select.v-text-field input {
  width: 4px;
}
.z-callingcode-select.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #e0e0e0;
}
.z-callingcode-select.theme--light.v-text-field--box
  > .v-input__control
  > .v-input__slot {
  border-color: #e0e0e0;
  background-color: #e0e0e0;
}
</style>
<style scoped>
.iti-flag {
  margin-right: 5px;
  margin-left: 5px;
}
</style>
