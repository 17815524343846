<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm10>
        <v-flex py-2 text-xs-right grey--text>
          <div>Application ID: {{ application.tevId }}
          </div>
          <z-status-chip :status="application.status" :label="$t(application.status)"></z-status-chip>
        </v-flex>

        <v-card class="z-card mb-4">
          <img src="@/assets/verified.svg" class="z-seal" />
          <v-card-text class="pa-0">
            <v-list two-line class="transparent pa-0">
              <v-list-tile class="pt-3">
                <v-list-tile-content>
                  <v-list-tile-title><strong>{{ application.applicantName }}</strong></v-list-tile-title>
                  <v-list-tile-sub-title>Applicant</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.propertyName }}</v-list-tile-title>
                  <v-list-tile-sub-title>Property</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.unitNumber }}</v-list-tile-title>
                  <v-list-tile-sub-title>Unit Number</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.applicationType }}</v-list-tile-title>
                  <v-list-tile-sub-title>Type of Application</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.moveInDate | moment("MM/DD/YYYY") }}</v-list-tile-title>
                  <v-list-tile-sub-title>Move in Date</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.moveInAddress }}</v-list-tile-title>
                  <v-list-tile-sub-title>Move in Address</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>                  
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.monthlyRent }}</v-list-tile-title>
                  <v-list-tile-sub-title>Monthly Rent</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.cellPhone }}</v-list-tile-title>
                  <v-list-tile-sub-title>Mobile Phone</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile class="pb-3">
                <v-list-tile-content>
                  <v-list-tile-title>{{ application.email }}</v-list-tile-title>
                  <v-list-tile-sub-title>Email</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>                  
              <v-divider></v-divider>
            </v-list>
          </v-card-text>
        </v-card>

        <h3 class="text-uppercase grey--text text--lighten-1 mb-3">Payment Details</h3>
        <template v-for= "payment in application.payments">
          <div :key="payment.id">
            <v-card class="z-card mb-4 pa-0">
              <v-card-text class="pa-0">
                <v-list two-line class="transparent pa-0">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{payment.feeName}}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Description</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <template v-if="payment.contractType == 'deposit'">Refundable</template>
                        <template v-else>Non-Refundable</template>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Type of Fee</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="payment.contractType == 'deposit'">
                      <v-chip class="grey white--text z-filter-chip">
                        Set for {{ payment.refundCounter }} {{ payment.refundStepType }}
                      </v-chip>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>

                  <v-list-tile v-if="!currentUser.isApplicant"
                    @click="$router.push('/' + currentUser.accountType + '/properties/' + propertyId + '/banks/'+payment.bankId)">
                    <v-list-tile-content>
                      <v-list-tile-title class="font-weight-bold">
                        {{ payment.bankAlias }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>Bank account</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn small icon ripple color="primary">
                        <v-icon class="white--text">chevron_right</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider v-if="!currentUser.isApplicant"></v-divider>        

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ payment.submittedAt | moment('MM/DD/YYYY') }}</v-list-tile-title>
                      <v-list-tile-sub-title>Date Collected</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action></v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile v-if="payment.contractType == 'deposit'">
                    <v-list-tile-content>
                      <v-list-tile-title>{{ payment.refundedAt | moment('MM/DD/YYYY') }}</v-list-tile-title>
                      <v-list-tile-sub-title>Refund Date</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="payment.refundedAt && (payment.status=='completed' || payment.status=='pending')">
                      <countdown :time="new Date(payment.refundedAt).getTime()  - new Date().getTime()">
                        <template slot-scope="props">
                          <v-layout>
                            <div class="mr-2">
                              {{ props.days }}
                              <div class="body-1 light-green--text">days</div>
                            </div>
                            <div class="mr-2">
                              {{ props.hours }}
                              <div class="body-1 light-green--text">hours</div>
                            </div>
                            <div class="mr-2">
                              {{ props.minutes }}
                              <div class="body-1 light-green--text">minutes</div>
                            </div>
                            <img src="@/assets/schedule.svg" height="22" />
                          </v-layout>
                        </template>
                      </countdown>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
                <v-divider></v-divider>

                <v-layout row wrap v-if="!currentUser.isApplicant && payment.status=='completed' && payment.contractType == 'deposit'"
                  class="white--text pa-3">

                  <v-flex sm6 xs12>
                    <v-btn
                      @click="createExtension(payment)"
                      block
                      depressed
                      color="primary"
                      class="button-extend mb-3 white--text"
                    >Extend Smart Contract</v-btn>
                  </v-flex>

                  <v-flex sm6 xs12>
                    <v-btn
                      @click="createIncident(payment)"
                      block
                      depressed
                      color="primary"
                      class="button-incident mb-3 white--text"
                    >Document and Incident</v-btn>      
                  </v-flex>                  
                </v-layout>

                <v-layout row wrap v-if="currentUser.isApplicant && payment.status=='pending'"
                  class="white--text pa-3">
                  <v-flex sm6 xs12>
                    <v-btn
                      :to="'/applicant'"
                      block
                      depressed
                      color="primary"
                      class="button-extend mb-3 white--text"
                    >Make payments</v-btn>
                  </v-flex>
                </v-layout>

                <v-layout v-if="payment.status!=='refunded' && payment.status!=='refund sent'" align-center justify-center :class="statusClass(payment.status)" class="white--text pa-3">
                  <span class="text-uppercase body-1">
                    <strong>{{ payment.status }}</strong>
                  </span>
                  <v-spacer></v-spacer>
                  <strong class="title" v-if="payment.amount>0">{{ payment.amount | currency }}</strong>
                </v-layout>
                <v-layout v-if="payment.status==='refunded' || payment.status==='refund sent'" align-center justify-center :class="statusClass('completed')" class="white--text pa-3">
                  <span class="text-uppercase body-1">
                    <strong>Funded</strong>
                  </span>
                  <v-spacer></v-spacer>
                  <strong class="title" v-if="payment.amount>0">{{ payment.amount | currency }}</strong>
                </v-layout>

                <h3 v-if="events && events[payment.id]" class="text-uppercase grey--text text--lighten-1 title-events">Events</h3>                        
                <v-layout v-if="events && events[payment.id]">
                  <v-flex xs12>
                    <v-data-table
                      class="evtInfoTable elevation-2"
                      :headers="headersEvents"
                      disable-initial-sort
                      hide-actions
                      :hide-default-header="isMobile"
                      :hide-headers="isMobile"
                      :items="events[payment.id]"
                    >
                      <template v-slot:items="{ item }">
                        <v-list-tile class="table-mobile" v-if="isMobile" @click="handleClickEvent(item, payment)">
                          <v-list-tile-content>
                            <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                            <v-list-tile-sub-title class="caption">
                              {{ item.date }}
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-content class="text-xs-right">
                            <v-list-tile-title class="text-xs-right">
                              <span
                                :class="[item.amount > 0 ? 'success--text' : '']"
                                class="font-weight-bold"
                              >{{ item.amount | currency }}</span>
                            </v-list-tile-title>
                            <v-list-tile-sub-title class="caption">
                              <z-status-chip :status="item.status" :label="item.status"></z-status-chip>
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-btn small icon ripple color="primary">
                              <v-icon class="white--text">chevron_right</v-icon>
                            </v-btn>
                          </v-list-tile-action>                               
                        </v-list-tile>
                        <v-divider v-if="isMobile" />
                        <tr v-if="!isMobile" @click="handleClickEvent(item, payment)">
                          <td>{{ item.name }}</td>
                          <td>{{ item.date }}</td>
                          <td>
                            <z-status-chip :status="item.status" :label="item.status"></z-status-chip>
                          </td>
                          <td>
                            <span
                              :class="[item.amount > 0 ? 'success--text' : '']"
                              class="font-weight-bold body-1"
                            >{{ item.amount | currency }}</span>
                          </td>
                          <td>
                            <v-btn small icon ripple color="primary">
                              <v-icon class="white--text">chevron_right</v-icon>
                            </v-btn>                                  
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
                <v-layout v-if="events && events[payment.id]" align-center justify-center class="current-balance white--text pa-3">
                  <span class="text-uppercase body-1">
                    <strong>Current Balance</strong>
                  </span>
                  <v-spacer></v-spacer>
                  <strong class="title">{{ payment.balance | currency }}</strong>
                </v-layout>

                <v-layout v-if="payment.status==='refunded' || payment.status==='refund sent'" align-center justify-center :class="statusClass(payment.status)" class="white--text pa-3">
                  <span class="text-uppercase body-1">
                    <strong>{{ payment.status }}</strong>
                  </span>
                  <v-spacer></v-spacer>
                  <strong class="title" v-if="payment.amount>0">{{ payment.amount | currency }}</strong>
                </v-layout>

              </v-card-text>
            </v-card>
            <div class="mb-5 text-xs-center primary--text">Payment ID: {{ payment.id }}</div>
          </div>
        </template>
      </v-flex>

      <v-flex x12 sm10>                
        <v-layout align-center justify-center class="zinc-box-total white--text pa-3">
          <span v-if="!currentUser.isApplicant" class="title ody-1 white--text">
            <strong v-if="application.status!=='completed'">Total to be collected</strong>
            <strong v-if="application.status==='completed'">Total collected</strong>
          </span>
          <span v-if="currentUser.isApplicant" class="title ody-1 white--text">
            <strong v-if="application.status!=='completed'">Total to be payed</strong>
            <strong v-if="application.status==='completed'">Total payed</strong>
          </span>

          <v-spacer></v-spacer>
          <div >
            <div class="headline text-xs-right font-weight-bold">{{ application.amount | currency }}</div>
            <template v-if="application.status!=='completed'">
              <div
                class="headline text-xs-right text-lowercase body-1 one-line"
                :key="item.description"
                v-for="(item) in variableFees"
              >+ {{ item.feeName }}</div>
            </template>
          </div>       
        </v-layout>
      </v-flex>


      
      <v-flex v-if="application.step>0 && application.step<=4" xs12 sm12 text-xs-center pt-5>
        <!-- tx-progress -->
        <h3 class="text-uppercase grey--text text--lighten-1 mb-3">Status</h3>

        <v-flex class="zinc-box">

          <v-flex xs12 class="zinc-box__body" pb-4 text-xs-center>
            <z-progress-tx :step="application.step"></z-progress-tx>
            <h4 class="subheading font-weight-medium">{{ application.steps[application.step-1].date | moment("MM/DD/YYYY") }}</h4>
            <div class="grey--text body-2">{{ application.steps[application.step-1].text }}</div>
          </v-flex>
        </v-flex>
        <!-- / tx-progress -->
      </v-flex>

    </v-layout>

    <v-layout
      align-center
      justify-center
      pb-3
      text-xs-center
    >
      <v-spacer></v-spacer>

      <div>
        <v-btn
          @click="printReport"
          depressed
          large
          color="primary"
          class="text-uppercase rounded mb-2 white--text"
        >
          <img src="@/assets/print-report.svg" height="32"/>
        </v-btn>
        <div class="body-1">Print Report</div>
      </div>
      <v-spacer></v-spacer>

    </v-layout>

    <!-- Incident -->
    <v-dialog v-model="dialogIncident" persistent scrollable max-width="580">
      <v-card class="grey lighten-5">
        <v-toolbar class="transparent" card>
          <v-toolbar-title class="text-uppercase subheading primary--text">Document An Incident</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogIncident = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="grey--text">
          <p class="mb-0">Describe the incident</p>
          <div class="pa-2 white mb-3">
            <v-textarea
              class="pt-0"
              background-color="white"
              placeholder="Add text here"
              hide-details
              v-model="incidentText"
            ></v-textarea>
          </div>

          <p class="mb-0">Attach photos</p>
          <file-pond
              name="incidentImgs"
              ref="incidentImgs"
              :label-idle="labelIdleIncident"
              v-bind:allow-multiple="true"
              maxFiles="3"
              accepted-file-types="image/jpeg, image/png"
              v-bind:files="incidentImages"
              v-on:init="handleFilePondInit"/>

          <p class="mb-0">Add the amount to be deducted from deposit</p>
          <div class="pa-2 white mb-4">
            <v-text-field
              class="pt-0"
              placeholder="Add amount"
              background-color="white"
              single-line
              hide-details
              v-model="incidentAmount"
              type="number"
            ></v-text-field>
          </div>

          <v-divider></v-divider>
          <v-layout class="py-2">
            <span class="black--text">Current Balance</span>
            <v-spacer></v-spacer>
            <span>
              <strong class="primary--text">{{ payment.balance | currency }}</strong>
            </span>
          </v-layout>
          <v-divider class="mb-4"></v-divider>

          <p class="mt-3">Client will get an email with all the details.</p>
        </v-card-text>
        <v-card-actions class="pa-0" style="width: 90%; margin: 15px;">
          <v-btn
            color="light-green"
            class="text-uppercase"
            large
            block
            dark
            depressed
            @click="validateIncident"
          >Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogIncidentConfirm" persistent max-width="290">
      <v-card class="pa-3">
        <v-toolbar class="transparent close-toolbar" card>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogIncidentConfirm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>          
        <v-card-text class="text-xs-center">
          <img src="@/assets/attention.svg" class="mb-2" />
          <h3 class="mb-3 title">Confirm Incident</h3>
          <div class="grey--text text--darken-1">
            Are you sure you want to create this incident report?
          </div>
        </v-card-text>
        <v-card-actions class="text-xs-center">
          <v-btn depressed @click="dialogIncidentConfirm = false">Cancel</v-btn>
          <v-divider class="no-line"></v-divider>
          <v-btn 
            type="submit" 
            depressed 
            color="primary" 
            @click="submitIncident"
            :loading="isLoading"
            v-bind:class="{'blue--text': isLoading}"
            :disabled="isLoading"            
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Incident -->    

    <!-- Extend -->
    <v-dialog v-model="dialogExtend" persistent scrollable max-width="580">
      <v-card class="grey lighten-5">
        <v-toolbar class="transparent" card>
          <v-toolbar-title class="text-uppercase subheading primary--text">Extend Smart Contract</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogExtend = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="grey--text">
          <p class="mb-0">Describe the reason to modify the contract date</p>
          <div class="pa-2 white mb-3">
            <v-textarea
              class="pt-0"
              background-color="white"
              placeholder="Add text here"
              hide-details
              v-model="extendText"
            ></v-textarea>
          </div>

          <p class="mb-0">Set the new refund date</p>
          <div class="pa-1">
            <v-menu
              v-model="extendDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >

              <template v-slot:activator="{ on }">
                <v-text-field v-model="extendDate" label="New refund date" readonly v-on="on" box>
                  <template v-slot:append>
                    <img width="16" height="16" src="../assets/calendar.svg" alt />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="extendDate"
                no-title
                @input="extendDateMenu = false"
                color="light-green"
              ></v-date-picker>
            </v-menu>
          </div>

          <p class="mb-0">Attach new contract document</p>
          <file-pond
              name="extendDoc"
              ref="extendDoc"
              :label-idle="labelIdleExtend"
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png, application/pdf"
              v-bind:files="extendDocument"
              v-on:init="handleFilePondInit"/>

          <p class="mt-3">Client will get an email with approved date change.</p>
        </v-card-text>
        <v-card-actions class="pa-0" style="width: 90%; margin: 15px;">
          <v-btn
            color="light-green"
            class="text-uppercase"
            large
            block
            dark
            depressed
            @click="validateExtend"
          >Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExtendConfirm" persistent max-width="290">
      <v-card class="pa-3">
        <v-toolbar class="transparent close-toolbar" card>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogExtendConfirm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>        
        <v-card-text class="text-xs-center">
          <img src="@/assets/attention.svg" class="mb-2" />
          <h3 class="mb-3 title">Extend Smart Contract</h3>
          <div class="grey--text text--darken-1">
            This action cannot be undone.
          </div>
        </v-card-text>
        <v-card-actions class="text-xs-center">
          <v-btn depressed @click="dialogExtendConfirm = false">Cancel</v-btn>
          <v-divider class="no-line"></v-divider>
          <v-btn 
            type="submit" 
            depressed 
            color="primary" 
            @click="submitExtend"
            :loading="isLoading"
            v-bind:class="{'blue--text': isLoading}"
            :disabled="isLoading"            
          >Confirm</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Extend -->   

    <v-dialog v-model="dialogThanks" max-width="520" height="460">
      <v-card class="dialogThanks">
        <v-card-title class="headline lighten-2" primary-title>
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="dialogThanks = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <img src="@/assets/emailsend.svg" height="32" />          
          <div class="title">Thank you</div>
          <div v-if="dialogThanksType==='incident'" class="subtitle">An email has been sent to the tenant with the Incident Report.</div>
          <div v-if="dialogThanksType==='extension'" class="subtitle">An email has been sent to the tenant. Once the tenant approves new contract dates all the changes will take effect.</div>
        </v-card-text>
        <v-card-actions class="pt-4">
          <v-btn 
            color="light-green"
            class="text-uppercase"
            large
            block
            dark
            depressed
            @click="dialogThanks = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEvent" persistent scrollable max-width="580">
      <v-card v-if="event" class="grey lighten-5">
        <v-toolbar class="transparent" card>
          <v-toolbar-title v-if="event.type==='incident'" class="text-uppercase subheading primary--text">Incident Report</v-toolbar-title>
          <v-toolbar-title v-if="event.type==='extension'" class="text-uppercase subheading primary--text">Extend Smart Contract</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogEvent = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text id="card-event">

          <div v-if="event.type==='incident'" class="desc-subtitle">Incident description</div>
          <div v-if="event.type==='extension'" class="desc-subtitle">Description</div>
          <div class="desc-title">{{ event.description }}</div>

          <v-list v-if="event.type==='incident'" two-line class="transparent pa-0">
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>Incident date</v-list-tile-sub-title>
                <v-list-tile-title>{{ event.date | moment('MM/DD/YYYY')}}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>Status</v-list-tile-sub-title>
                <v-list-tile-title style="height: 38px!important">
                  <z-status-chip :status="event.status" :label="event.status"></z-status-chip>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="!currentUser.isApplicant && payment.status=='completed'">
                <v-btn @click="dialogDelete = true" icon flat small color="info">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-tile-action>              
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>Amount deducted</v-list-tile-sub-title>
                <v-list-tile-title><strong>{{ event.amount | currency }}</strong></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
          </v-list>

          <template v-if="event.images && event.type==='incident'">
            <div class="desc-subtitle" style="padding-top: 13px;">Support pictures</div>
            <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>

            <v-layout row wrap v-if="!isLoading && event.images.length>0">
              <template v-for= "image in event.images">
                <v-flex sm4 xs12 :key="image.id" text-xs-center>
                  <img class="incident-img" v-img :src="image.src">
                </v-flex>
              </template>
            </v-layout>

            <div v-if="!isLoading && event.images.length==0" class="desc-title">No support pictures</div>            
          </template>

          <v-list v-if="event.type==='extension'" two-line class="transparent pa-0">
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>Previous refund date</v-list-tile-sub-title>
                <v-list-tile-title>{{ event.oldDate | moment('MM/DD/YYYY')}}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>New refund date</v-list-tile-sub-title>
                <v-list-tile-title>{{ event.newDate | moment('MM/DD/YYYY')}}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>Status</v-list-tile-sub-title>
                <v-list-tile-title style="height: 38px!important">
                  <z-status-chip :status="event.status" :label="event.status"></z-status-chip>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="!currentUser.isApplicant && event.status==='pending'">
                <v-btn @click="dialogDelete = true" icon flat small color="info">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action v-if="currentUser.isApplicant && event.status==='pending'">
                <v-btn 
                  type="submit" 
                  class="extension-active-btn"
                  depressed 
                  color="primary" 
                  @click="activeExtension"
                  :loading="isLoading"
                  v-bind:class="{'blue--text': isLoading}"
                  :disabled="isLoading"            
                >ACCEPT CONTRACT</v-btn>
              </v-list-tile-action>              
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-sub-title>Support document</v-list-tile-sub-title>
                <v-list-tile-title v-if="event.filename">
                  <a 
                    @click="download()"
                    :download="event.filename" 
                    target="_blank">
                    {{ event.filename }}
                  </a>
                </v-list-tile-title>
                <v-list-tile-title v-if="!event.filename">No support document</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

        </v-card-text>
        <v-card-actions class="pa-0" style="width: 90%; margin: 15px;">
          <v-btn
            color="light-green"
            class="text-uppercase"
            large
            block
            dark
            depressed
            @click="dialogEvent = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card class="pa-3">
        <v-toolbar class="transparent close-toolbar" card>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogDelete = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="text-xs-center">
          <img src="@/assets/attention.svg" class="mb-2" />
          <h3 class="mb-3 title">Delete</h3>

          <div v-if="event && event.type==='incident'" class="grey--text text--darken-1">
            Are you sure you want to delete this incident report?
          </div>

          <div v-if="event && event.type==='extension'" class="grey--text text--darken-1">
            Are you sure you want to delete this smart contract extension?
          </div>

          <div class="grey--text text--darken-1 pt-3">
            This action cannot be undone.
          </div>
        </v-card-text>
        <v-card-actions class="text-xs-center">
          <v-btn depressed @click="dialogDelete = false">Cancel</v-btn>
          <v-divider class="no-line"></v-divider>
          <v-btn 
            type="submit" 
            depressed 
            color="primary" 
            @click="submitDelete"
            :loading="isLoading"
            v-bind:class="{'blue--text': isLoading}"
            :disabled="isLoading"            
          >Confirm</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>   

    <v-dialog v-model="dialogPending" persistent max-width="390">
      <v-card class="pa-3">
        <v-toolbar class="transparent close-toolbar" card>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="dialogPending = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>        
        <v-card-text class="text-xs-center">
          <img src="@/assets/attention.svg" class="mb-2" />
          <h3 class="mb-3 title">Pending Approval</h3>
          <div class="grey--text text--darken-1">
            You have new contract extension pending to approve. Please click on each Contract extended with status pending and then click on ACCEPT EXTENSION.
          </div>
        </v-card-text>
        <v-card-actions class="pa-0" style="width: 90%; margin: 15px;">
          <v-btn
            color="light-green"
            class="text-uppercase"
            large
            block
            dark
            depressed
            @click="dialogPending = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Alerts from '../mixin/Alerts'
import ZProgressTx from './ProgressTx'
import VueCountdown from '@chenfengyuan/vue-countdown'

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType, 
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize  
);

export default {
  props: {
    application: Object,
    propertyId: String,
  },
  data() {
    return {
      isLoading: false,

      datePickerModal: false,

      dialogIncident: false,
      dialogIncidentConfirm: false,
      
      dialogExtend: false,
      dialogExtendConfirm: false,
      
      dialogThanks: false,
      dialogThanksType: 'incident',
      dialogEvent: false,
      dialogDelete: false,

      dialogPending: false,

      payment: {},      
      search: '',
      range: [],
      status: '',
      pagination: {
        rowsPerPage: 10,
      },

      // Events: Extensions and Incidents
      incidentAmount: 0,
      incidentText: '',
      incidentImages: [],
      labelIdleIncident: 'Drag & Drop up to 3 photos or <span class="filepond--label-action"> Browse </span>',

      extendDate: null,
      extendDateMenu: false,
      extendText: '',
      extendDocument: null,
      labelIdleExtend: 'Drag & Drop your document or <span class="filepond--label-action"> Browse </span>',

      events: null,
      headersEvents: [
        { text: 'Description', value: 'name' },
        { text: 'Event Date', value: 'date' },
        { text: 'Status', value: 'status' },
        { text: 'Deductions', value: 'amount' },
        { text: '', value: '' },
      ],    
      
      event: null,
    }
  },
  components: {
    'z-progress-tx': ZProgressTx,
    countdown: VueCountdown,
    FilePond
  },
  watch: {
    range(val) {
      if (val.length > 2) {
        val.pop()
      }
    },
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
    variableFees() {
      if (this.application && this.application.payments){
        return this.application.payments.filter(
          item => item.feeRateType == 'variable'
        )
      }
      return []
    },    
    isMobile: function() {
      return this.$vuetify.breakpoint.smAndDown
    },

  },

  methods: {      
    statusClass(status) {
      let str = 'orange'
      if (status == 'submitted') {
        str = 'primary'
      } 
      else if (status == 'processed' || status == 'completed') {
        str = 'light-green'
      }
      else if (status == 'refund sent' || status == 'refunded'){
        str = 'grey'
      }
      else if (status == 'failed' || status == 'cancelled') {
        str = 'red'
      }
      return str
    },

    handleFilePondInit: function() {
      // FilePond instance methods are available on `this.$refs.pond`
      //this.$log.info('handleFilePondInit')
      this.extendDocument = null
      this.incidentImages = []
    },

    formatDate: function(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    sortDate: function() {
      const [first, second] = this.range
      if (first && second) {
        const d1 = new Date(first)
        const d2 = new Date(second)
        if (d1.getTime() < d2.getTime()) {
          return {
            str: { from: first, to: second },
            obj: { from: d1, to: d2 },
          }
        }
        return {
          str: { from: second, to: first },
          obj: { from: d2, to: d1 },
        }
      }
      return false
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'date') {
          const [m1, d1, y1] = a[index].split('/')
          const [m2, d2, y2] = b[index].split('/')
          const t1 = new Date(y1, m1 - 1, d1)
          const t2 = new Date(y2, m2 - 1, d2)
          if (!isDesc) {
            return t2 - t1
          } else {
            return t1 - t2
          }
        } else if (index === 'amount') {
          const t1 = Number(a[index])
          const t2 = Number(b[index])
          if (!isDesc) {
            return t2 - t1
          } else {
            return t1 - t2
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase())
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },


    printReport: function() {
      this.$htmlToPaper('card-details');
    },

    createIncident(payment) {
      if (this.currentUser.isApplicant){
        return
      }
      this.payment = payment
      this.dialogIncident = true
    },   
    createExtension(payment) {
      if (this.currentUser.isApplicant){
        return
      }
      this.payment = payment
      this.dialogExtend = true
    },  
    
    validateIncident (){
      if (!this.incidentText || this.incidentText.length==0){
        return this.toastError('Please enter a description of the incident.')
      }

      if (!this.incidentAmount || this.incidentAmount<=0) {
        return this.toastError('Please enter a valid amount to be deducted.')
      }

      if (this.incidentAmount > this.payment.balance) {
        return this.toastError('Amount to be deducted cannot be bigger than current payment balance.')
      }

      this.dialogIncidentConfirm = true
    },
    validateExtend (){
      if (!this.extendText || this.extendText.length==0){
        return this.toastError('Please enter the reason to mofity the extend date.')
      }

      if (!this.extendDate || this.extendDate<=this.payment.refundedAt) {
        return this.toastError('Please enter a valid new refund date.')
      }

      this.dialogExtendConfirm = true
    },    

    submitIncident() {
      if (this.currentUser.isApplicant){
        return
      }      
      this.isLoading = true;

      let params = {
        files: [],
        text: this.incidentText,
        amount: this.incidentAmount,
        applicationId: this.application.id
      }

      let files = this.$refs['incidentImgs'].getFiles()
      if (files && files.length>0){
        for (let i = 0; i < files.length; i++) {
          params.files.push({
            data: files[i].getFileEncodeBase64String(),
            fileType: files[i].fileType,
            fileName: files[i].filename,
          })
        }
      }

      this.$http
        .post('/' + this.currentUser.accountType + '/payments/' + this.payment.id + '/incident', params)
        .then(() => {
          this.loadEvents()

          this.isLoading = false
          this.dialogIncident = false
          this.dialogIncidentConfirm = false

          this.dialogThanksType = 'incident'
          this.dialogThanks = true          
        })
        .catch(err => {
          this.$log.error(err)
        })
    },  

    submitExtend() {
      if (this.currentUser.isApplicant){
        return
      }      
      this.isLoading = true;

      let params = {
        files: [],
        text: this.extendText,
        date: this.extendDate,
        applicationId: this.application.id
      }

      let files = this.$refs['extendDoc'].getFiles()
      if (files && files.length>0){
        params.files.push({
          data: files[0].getFileEncodeBase64String(),
          fileType: files[0].fileType,
          fileName: files[0].filename,
        })
      }

      this.$http
        .post('/' + this.currentUser.accountType + '/payments/' + this.payment.id + '/extend', params)
        .then(() => {
          this.loadEvents()

          this.isLoading = false
          this.dialogExtend = false
          this.dialogExtendConfirm = false

          this.dialogThanksType = 'extension'
          this.dialogThanks = true          
        })
        .catch(err => {
          this.$log.error(err)
        })
    }, 
    submitDelete() {
      if (this.currentUser.isApplicant){
        return
      }      
      this.isLoading = true;
      let action = '/removeExtension'
      if (this.event.type==='incident'){
        action = '/removeIncident'
      }
      this.$http
        .post('/' + this.currentUser.accountType + '/events/' + this.event.id + action)
        .then(() => {
          this.loadEvents()
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
          this.dialogDelete = false
          this.dialogEvent = false
        })
    },

    activeExtension() {
      if (!this.currentUser.isApplicant){
        return
      }      
      this.isLoading = true
      this.$http
        .post('/' + this.currentUser.accountType + '/events/' + this.event.id + '/activeExtension')
        .then(() => {
          this.loadApplication()
        })
        .catch(err => {
          this.$log.error(err)
        })
        .finally(() => {
          this.isLoading = false
          this.dialogDelete = false
          this.dialogEvent = false
        })
    },

    loadApplication() {
      this.isLoading = true
      this.$http
        .get('/' + this.currentUser.accountType + '/applications/' + this.application.id)
        .then(res => {
          this.application = res.data
          setTimeout(() => {
            this.loadEvents()
          }, 300)
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/applicant/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    loadEvents(){
      // Load application extensions and incidents
      this.$http
        .get('/' + this.currentUser.accountType + '/applications/' + this.application.id + '/events')
        .then(response => {
          this.events = response.data.events

          let pendingApproval = false
          // Update balance
          for (let i = 0; i < this.application.payments.length; i++) {
            let amountDeducted = 0            
            if (this.events[this.application.payments[i].id]){
              for (let j = 0; j < this.events[this.application.payments[i].id].length; j++) {
                let event = this.events[this.application.payments[i].id][j]
                if (event.type==='incident' && event.status==='active'){
                  amountDeducted += event.amount
                }

                if (event.type==='extension' && event.status==='pending'){
                  pendingApproval = true
                }
              }
            }

            this.application.payments[i].balance = this.application.payments[i].amount + amountDeducted
            if (this.application.payments[i].balance<0){
              this.application.payments[i].balance = 0
            }
          }    
          
          if (this.currentUser.isApplicant && pendingApproval){
            this.dialogPending = true
          }
        })
        .catch(err => {       
          this.$log.error(err)
        })
        .finally(() => {
          //this.loadIncidents()
        })
    },

    handleClickEvent: function(item, payment) {
      this.event = item
      this.payment = payment
      this.dialogEvent = true
      setTimeout(() => {
        document.getElementById('card-event').scrollTop = 0
      }, 100)

      this.isLoading = true
      if (this.event.type==='incident') {

        this.event.images = []
        this.event.nimages = 0
        if (this.event.image1Id) {
          this.event.nimages = 1
        }
        else if (this.event.image2Id) {
          this.event.nimages = 2
        }
        else if (this.event.image3Id) {
          this.event.nimages = 3
        }

        if (this.event.nimages==0){
          this.isLoading = false
          return
        }
        for (let i=0;i<3;i++){
          let uploadId = null
          if (i==0){
            uploadId = this.event.image1Id
          }
          else if (i==1){
            uploadId = this.event.image2Id
          }
          else if (i==2){
            uploadId = this.event.image3Id
          }

          if (uploadId) {
            this.$http
              .get('/' + this.currentUser.accountType + '/applications/' + this.application.id + '/downloads/' + uploadId)
              .then(response => {
                this.event.images.push({
                  src: 'data:' + response.data.filetype + ';base64,' + response.data.base64,
                  id: i
                })

                if (i==this.event.nimages-1){
                  this.isLoading = false
                }
              })
              .catch(err => {       
                this.$log.error(err)
              })
              .finally(() => {
                //this.loadIncidents()
              })
          }
        }
      }
      else {
        this.isLoading = false
      }
    },

    download(){

      // Load application extensions and incidents
      this.$http
        .get('/' + this.currentUser.accountType + '/applications/' + this.application.id + '/downloads/' + this.event.uploadId)
        .then(response => {

          if (response && response.data){
            const blob = this.b64toBlob(response.data.base64, response.data.filetype)

            const e = document.createEvent('MouseEvents'),
              a = document.createElement('a')
            a.download = response.data.filename
            a.href = window.URL.createObjectURL(blob)
            a.dataset.downloadurl = [response.data.filetype, a.download, a.href].join(':')
            e.initEvent(
              'click',
              true,
              false,
              window,
              0,
              0,
              0,
              0,
              0,
              false,
              false,
              false,
              false,
              0,
              null
            )
            a.dispatchEvent(e)
          }

        })
        .catch(err => {       
          this.$log.error(err)
        })
        .finally(() => {
          //this.loadIncidents()
        })
    },
    b64toBlob (b64Data, contentType='', sliceSize=512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },    
  },
}
</script>


<style lang="scss">

@media (max-width: 600px) {
  .tableForm {
    padding-top: 60px!important;

    .v-input__control {
      border: 2px solid #ededed !important;
    }
    .v-input {
      margin: 8px auto;
    }
  }
}

.table-mobile .v-list__tile {
  height: 90px !important;
}
.v-list__tile {
  height: 70px !important;
}
.trxTitle {
  .v-list__tile {
    padding: 0;  
    margin-top: -20px!important;
  }
}

.tableForm {

  border-left: 0px !important;
  .v-input__control {
    border-radius: 0px !important;
    &:last-child {
      border-left: 2px solid #ededed;
    }
    label {
      font-size: 14px;
    }
  }
}
.removeRadius {
  border-radius: 0px !important;
}
.accInfoTable {
  border-radius: 0px;
  tr {
    cursor: pointer;
  }  
  thead {
    tr {
      height: 70px;   
      th {
        margin: 20px auto;
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }
    }
  }
}

@media (max-width: 600px) {
  .accInfoTable {
    padding-top: 60px;
  }
}

.zinc-box {
  background-color: transparent !important;
  margin-bottom: 20px;
}

.zinc-box__title {
  background-color: #c1c1c1;
  color: #fff;
  padding: 6px 20px;
}

.zinc-box__title h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.zinc-box__body {
  padding: 0 20px;
}

.zinc-box__footer {
  background-color: #efefef;
  padding: 20px;
  font-weight: 400;
  font-size: 18px;
}

.zinc-box__item {
  border-bottom: 1px solid #e4e4e4;
  padding: 18px 0;
}

.zinc-box__item:last-child {
  border-bottom: 0;
}

.zinc-box__label {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.zinc-box__value {
  color: #373041;
  font-size: 18px;
  font-weight: 400;
}

.zinc-box-total {
  background-color: #2B388F;
  min-height: 112px;
  .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 959px) {
  .zinc-box__body {
    padding: 0 18px;
  }
  .zinc-box__item {
    padding: 14px 0;
  }
  .zinc-box__value {
    font-size: 16px;
  }
  .zinc-box__footer {
    padding: 18px;
    font-size: 16px;
  }
}


.z-seal {
  position: absolute;
  top: 20px;
  right: 0px;
}

.button-extend{
  border-radius: 23px;
  font-weight: normal;
  height: 46px;
  width: 200px;
  float:right;
  right: 5%;
}
.button-incident{
  border-radius: 23px;
  font-weight: normal;
  height: 46px;
  width: 200px;
  float:left;
  left: 5%;  
}

@media only screen and (max-width: 600px) {
  .button-extend, 
  .button-incident {
    margin-left: 15%;
    left: 0px;
    right: 0px;
    float: none;
  }
}

.no-line {
  border: none !important;
}

.one-line {
  overflow: hidden;
  white-space: nowrap;
}

.filepond--wrapper {
  border: 1px solid #2196F3;
  border-radius: 10px;
}

.filepond--drop-label{
  color: #2196F3;
}

.filepond--root {
  margin-bottom: 0em!important;
}

.title-events {
  font-size: 14px;
  padding: 10px;
}

.evtInfoTable {
  border-radius: 0px;
  tr {
    cursor: pointer;
  }  
  thead {
    tr {
      th {
        margin: 12px auto;
        font-weight: bold;
        font-size: 14px;
        color: #212121;
        padding: 0 5px!important;
      }

      th:nth-child(1){
        padding-left:15px!important;
      }
    }
  }

  tbody {
    td {
      padding: 0 5px!important;
    }
    td:nth-child(1){
      padding-left:15px!important;
    }    
  }
}

.app-detail-dialog {
  height: 80%!important;
}

.current-balance {
  background-color: #DD536E;
}

.delete-message{
  font-size: 12px;
  color: #000;
}

.close-toolbar .v-toolbar__content{
  padding: 0px!important
}

.desc-subtitle {
  padding: 0 16px;
  color: rgba(0,0,0,0.54);
  font-size: 14px;
}

.desc-title {
  padding: 0px 16px 13px 16px;
  font-size: 14px;
  font-weight: 400;
  color: inherit;
}

.incident-img {
  height: 120px;
}

.extension-active-btn {
  padding: 0px 20px!important;
}
</style>
