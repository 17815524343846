const mixin = {
  data() {
    return {}
  },
  methods: {
    confirm(message, title) {
      this.$dialog.confirm({
        text: message,
        title: title || 'Alert',
        actions: {
          false: 'No',
          true: {
            color: 'primary',
            text: 'Ok',
            handle: () => {
              return new Promise(() => {})
            },
          },
        },
      })
    },
    toast(message) {
      this.$toast(message, {
        color: 'black',
        timeout: 6000,
      })
    },
    toastSuccess(message) {
      this.$toast(message, {
        color: 'green',
        timeout: 6000,
        position: 'top',
      })
    },
    toastWarning(message) {
      this.$toast(message, {
        color: 'orange',
        timeout: 6000,
        position: 'top',
      })
    },
    toastError(message) {
      this.$toast(message, {
        color: 'red',
        showClose: true,
        timeout: 0,
        position: 'top',
      })
    },
  },
}

export default mixin
