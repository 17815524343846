var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: { class: "primary--text" },
          expression: "{class: 'primary--text'}"
        }
      ],
      staticClass: "z-card pa-3 text-xs-left mb-3",
      attrs: { to: _vm.to }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "mb-2": "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.payment.feeName))
            ]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Fee Description")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c(
                "v-icon",
                {
                  staticStyle: { margin: "10px 2px", float: "right" },
                  attrs: { color: "primary" }
                },
                [_vm._v("chevron_right")]
              ),
              _c(
                "div",
                { staticClass: "font-weight-bold" },
                [
                  _vm.payment.contractType == "deposit" &&
                  _vm.payment.amount == 0
                    ? [_vm._v("Need to Calculate")]
                    : [_vm._v(_vm._s(_vm._f("currency")(_vm.payment.amount)))]
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "font-weight-bold text-uppercase body-1",
                  class: _vm.statusClass(_vm.payment.status)
                },
                [_vm._v(_vm._s(_vm.payment.status))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _vm.payment.contractType == "deposit" &&
            _vm.payment.status != "pending"
              ? _c("img", {
                  staticStyle: { margin: "10px", float: "right" },
                  attrs: { src: require("@/assets/schedule.svg"), height: "20" }
                })
              : _vm._e(),
            _c("div", [
              _vm._v(
                _vm._s(_vm.payment.propertyName) +
                  " - Unit " +
                  _vm._s(_vm.payment.unitNumber)
              )
            ]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Property")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _vm.payment.contractType == "deposit" &&
              _vm.payment.status != "pending"
                ? [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(_vm.payment.refundedAt, "MM/DD/YYYY")
                        )
                      )
                    ]),
                    _c("div", { staticClass: "grey--text body-1" }, [
                      _vm._v("Refund Date")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }