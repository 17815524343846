var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar", { attrs: { title: "Email Marketing" } }),
      _c("tev-subnavbar", { attrs: { title: "Send a Email" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md9: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.inviteMember($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { "pt-5": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md2: "" } },
                                [_c("v-subheader", [_vm._v("Sender")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md10: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("email"),
                                      type: "text",
                                      "single-line": "",
                                      box: "",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.sender,
                                      callback: function($$v) {
                                        _vm.sender = $$v
                                      },
                                      expression: "sender"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md2: "" } },
                                [_c("v-subheader", [_vm._v("Recipients")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md10: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.recipientsOpts,
                                      "item-text": "name",
                                      "item-value": "value",
                                      "single-line": "",
                                      attach: "",
                                      chips: "",
                                      box: "",
                                      label: "Recipients",
                                      multiple: ""
                                    },
                                    model: {
                                      value: _vm.recipients,
                                      callback: function($$v) {
                                        _vm.recipients = $$v
                                      },
                                      expression: "recipients"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md2: "" } },
                                [_c("v-subheader", [_vm._v("Template")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md10: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.emailTemplates,
                                      "single-line": "",
                                      "item-text": "name",
                                      "item-value": "slug",
                                      box: "",
                                      label: "Standard"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          var selected = ref.selected
                                          return [
                                            _c("v-chip", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("uppercase")(item.lang)
                                                )
                                              )
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("v-chip", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("uppercase")(item.lang)
                                                )
                                              )
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md2: "" } },
                                [_c("v-subheader", [_vm._v("Subject")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { md10: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "single-line": "",
                                      box: "",
                                      label: _vm.$t("subject"),
                                      type: "text",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.hasChanges = true
                                      }
                                    },
                                    model: {
                                      value: _vm.subject,
                                      callback: function($$v) {
                                        _vm.subject = $$v
                                      },
                                      expression: "subject"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", "mb-3": "" } },
                                [
                                  _c("z-editor", {
                                    on: { change: _vm.editorChange }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", "mb-5": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        loading: _vm.isLoading,
                                        color: "primary",
                                        dark: "",
                                        depressed: "",
                                        large: ""
                                      },
                                      on: { click: _vm.inviteMember }
                                    },
                                    [_vm._v("Preview Email")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        loading: _vm.isLoading,
                                        color: "primary",
                                        flat: "",
                                        depressed: "",
                                        large: ""
                                      },
                                      on: { click: _vm.inviteMember }
                                    },
                                    [_vm._v("Delay Delivery")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        loading: _vm.isLoading,
                                        color: "primary",
                                        flat: "",
                                        depressed: "",
                                        large: ""
                                      },
                                      on: { click: _vm.inviteMember }
                                    },
                                    [_vm._v("Send Test Email")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }