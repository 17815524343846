import { render, staticRenderFns } from "./OrganizationsView.vue?vue&type=template&id=21df4757&"
import script from "./OrganizationsView.vue?vue&type=script&lang=js&"
export * from "./OrganizationsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VAvatar,VChip,VContainer,VContent,VDivider,VFlex,VForm,VIcon,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileSubTitle,VListTileTitle,VPagination,VProgressCircular,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src266546640/src/zinc-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21df4757')) {
      api.createRecord('21df4757', component.options)
    } else {
      api.reload('21df4757', component.options)
    }
    module.hot.accept("./OrganizationsView.vue?vue&type=template&id=21df4757&", function () {
      api.rerender('21df4757', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/representative/OrganizationsView.vue"
export default component.exports