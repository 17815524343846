<template>
  <v-app>
    <tev-navbar :title="$t('my_property')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-card class="transparent elevation-0" width="600" max-width="100%">
            <v-layout row wrap align-start justify-start>
              <v-btn icon active-class="none" class="light-green--text" to="/applicant/properties">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-flex py-3 text-xs-right grey--text>
                <div>
                  &nbsp;
                  <template v-if="propertyId">{{ $t('property_id') }}: {{ property.tevPropertyId }}</template>
                </div>
                <z-status-chip :status="property.status" :label="$t(property.status)"></z-status-chip>
              </v-flex>
            </v-layout>

            <!-- property_info -->
            <h3 class="text-uppercase grey--text text--lighten-1 mb-3">{{ $t('property_info') }}</h3>
            <v-card class="z-card mb-4">
              <v-card-text class="pa-0">
                <v-list two-line class="transparent pa-0">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ property.name }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('property_name')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        property.address.line1
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('address')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        property.address.city
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('city')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        property.address.state
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('state')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action></v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{
                        property.address.postalCode
                        }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                        $t('zip_code')
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card-text>
            </v-card>
            <!-- / property_info -->

            <h3 class="text-uppercase grey--text text--lighten-1 mb-3">Move Ins</h3>
            <ZApplication v-for="item in property.applications"
              :application="item" 
              :key="item.id" 
              :to="'/applicant/applications/' + item.id"
            ></ZApplication>
          </v-card>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import ZApplication from '@/components/ZApplication'

export default {
  title: 'Property Details',
  data() {
    return {
      error: false,
      isLoading: false,
      propertyId: null,
      property: {
        name: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
        },
        status: null,
        applications: [],
      },
    }
  },
  mixins: [Alerts],
  components: { ZApplication },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  methods: {
    loadProperty() {
      this.isLoading = true
      this.$http
        .get('/applicant/properties/' + this.propertyId)
        .then(res => {
          this.property = res.data
          this.$log.info(this.property)
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/applicant/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/applicant/properties')
    }
    this.loadProperty()
  },
}
</script>