<template>
  <v-form ref="form" lazy-validation>
    <p class="mb-4 grey--text">
      Please start the onboarding process by confirming your personal
      information.
    </p>
    <SchemaForm
      v-if="formSchema"
      :schema="formSchema"
      :model="formData"
      :options="null"
      @error="showError"
    />
    <v-btn round depressed color="primary" @click="submit">OK, Continue</v-btn>
    <v-btn round flat @click="$emit('back')">Back</v-btn>
  </v-form>
</template>
<script>
import SchemaForm from '@/components/schemaForm/Schema.vue'
import Alerts from '@/mixin/Alerts'

export default {
  props: {
    propertyId: String,
    property: Object,

    data: Object,
  },
  data() {
    return {
      formValid: false,
      formData: { firstName: 'sss' },
      formSchema: {
        title: '',
        type: 'object',
        required: ['firstName', 'lastName', 'email'],
        properties: {
          firstName: {
            type: 'string',
            title: 'First Name',
            'x-autofocus': true,
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          lastName: {
            type: 'string',
            title: 'Last Name',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          email: {
            type: 'string',
            title: 'Email Address',
            format: 'email',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
        },
      },
    }
  },
  components: {
    SchemaForm,
  },
  mixins: [Alerts],
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return this.toastError(this.$t('complete_required'))
      }
      this.$emit('next', this.formData)
    },
    showError(err) {
      this.$log.error(err)
    },
  },
  created() {
    this.$log.debug(this.data)
    this.formData = this.data
    this.$log.debug(this.data)
  },
  mounted() {},
}
</script>
