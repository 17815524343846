<template>
  <v-form ref="form">
    <p class="mb-4 grey--text">
      Please enter the company controller information. The controller is any
      natural individual who holds significant responsibilities to control,
      manage, or direct a company or other corporate entity (i.e. CEO, CFO,
      General Partner, President, etc). A company may have more than one
      controller, but only one controller’s information is required.
    </p>
    <SchemaForm
      v-if="formSchema"
      :schema="formSchema"
      :model="formData"
      @error="showError"
    />
    <v-btn round depressed color="primary" @click="submit">Continue</v-btn>
    <v-btn round flat @click="$emit('back')">Back</v-btn>
  </v-form>
</template>
<script>
import SchemaForm from '@/components/schemaForm/Schema.vue'
import Alerts from '@/mixin/Alerts'
import StatesHash from '../../states_hash.json'

export default {
  props: {
    propertyId: String,
    property: Object,
  },
  data() {
    return {
      formData: { country: 'United States' },
      formSchema: {
        type: 'object',
        required: [
          'firstName',
          'lastName',
          'dateOfBirth',
          'ssn',
          'address1',
          'city',
          'stateProvinceRegion',
          'postalCode',
          'idFile',
        ],
        properties: {
          firstName: {
            type: 'string',
            title: 'First Name',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          lastName: {
            type: 'string',
            title: 'Last Name',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          title: {
            type: 'string',
            title: 'Job Title',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          dateOfBirth: {
            type: 'string',
            format: 'date',
            title: 'Date Of Birth',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          ssn: {
            type: 'string',
            title: 'Last 4 digits of SSN',
            format: 'ssn',
            maxLenght: 4,
            'x-mask': '####',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          address1: {
            type: 'string',
            title: 'Address Line 1',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          address2: {
            type: 'string',
            title: 'Address Line 2',
            'x-class': 'sm12',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          city: {
            type: 'string',
            title: 'City',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          stateProvinceRegion: {
            type: 'object',
            title: 'State / Province or Region',
            enum: StatesHash,
            'x-class': 'sm6',
            'x-itemTitle': 'name',
            'x-itemKey': 'abbr',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          postalCode: {
            type: 'string',
            title: 'Postal Code',
            'x-mask': '#####',
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          country: {
            type: 'string',
            title: 'Country',
            'x-readonly': true,
            'x-class': 'sm6',
            'x-box': true,
            'x-background-color': '#F1F5F7',
          },
          idFile: {
            type: 'string',
            title:
              'Upload Driver License or U.S. government-issued photo id card',
            format: 'base64',
            'x-hint':
              'The uploaded file must be a color scan or photo of government issued identification.',
            'x-accept': 'image/jpeg, image/png',
            'x-display': 'fileinput',
            'x-class': 'sm12',
          },
        },
      },
    }
  },
  components: {
    SchemaForm,
  },
  mixins: [Alerts],
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      const age = this.$moment().diff(this.formData['dateOfBirth'], 'years')
      const isLegal = age >= 18

      if (!isLegal) {
        return this.toastError('The controller must be at least 18 years old.')
      }

      this.$emit('next', { controller: this.formData })
    },
    showError(err) {
      this.$log.error(err)
    },
  },
}
</script>
