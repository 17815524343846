<template>
  <v-app class="page-property-activity">
    <tev-navbar :title="$t('property_activity')" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm12 px-4 class="pt-4 mb-4">
          <v-layout row wrap align-start justify-start>
            <v-btn icon @click="$router.go(-1)" class="light-green--text mr-3">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-flex>
              <div class="body-1 grey--text">{{ $t('property') }}</div>
              <div class="title font-weight-regular">{{ propertyName }}</div>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 sm6 class="my-5 text-xs-center" v-if="!totalResults&&!isLoading">
          <h3 class="mt-2 mb-5 title">{{ $t('no_activity_explain') }}</h3>
        </v-flex>

        <v-flex xs12 sm12 class="mb-5">
          <template v-if="totalResults">
            <v-divider></v-divider>
            <v-list three-line class="activity-list py-0 transparent mx-3">
              <template v-for="(activity, index) in propertyActivity">
                <v-list-tile :key="activity.id" avatar>
                  <v-list-tile-avatar>
                    <Lettericon :name="activity.event" size="2"></Lettericon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-sub-title
                      class="text-truncate"
                    >{{ $t('browser') }} {{ activity.browser }}</v-list-tile-sub-title>
                    <v-list-tile-title>{{ activity.event }}</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action
                    class="grey--text body-1 text-xs-right"
                  >{{ activity.createdAt | moment("from") }}</v-list-tile-action>
                </v-list-tile>
                <v-divider
                  :key="activity.id+'_divider'"
                  :inset="true"
                  v-if="(index<propertyActivity.length-1)"
                ></v-divider>
              </template>
            </v-list>
            <v-divider></v-divider>

            <mugen-scroll :handler="fetchData" :should-handle="!isLoading" v-if="loadMore"></mugen-scroll>
          </template>

          <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>

          <back-to-top bottom="50px" right="50px">
            <v-btn fab dark fixed bottom right class="elevation-0" color="arapawa">
              <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>
          </back-to-top>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import MugenScroll from 'vue-mugen-scroll'
import BackToTop from 'vue-backtotop'
import Lettericon from '@/components/Lettericon'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Property Activity',
  data() {
    return {
      loadMore: true,
      isLoading: false,
      currentPage: 1,
      perPage: 25,
      totalResults: 0,
      totalPages: null,
      propertyActivity: [],
      propertyId: null,
      propertyName: null,
    }
  },
  mixins: [Alerts],
  components: { MugenScroll, BackToTop, Lettericon },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    fetchData() {
      if (this.propertyActivity.length < this.totalResults) {
        this.currentPage += 1
        this.loadActivity()
      } else {
        this.loadMore = false
      }
    },
    loadActivity() {
      this.isLoading = true
      this.$http
        .get('/accountant/properties/' + this.propertyId + '/activity', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
          },
        })
        .then(response => {
          this.propertyActivity = this.propertyActivity.concat(
            response.data.propertyActivity
          )
          this.totalResults = response.data.metadata.total
          this.totalPages = Math.ceil(this.totalResults / this.perPage)
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    if (!this.propertyId) {
      this.$router.push('/accountant/properties')
    }
    //this.$moment.locale('es')
    this.loadActivity()
  },
}
</script>
<style>
.page-property-activity .v-content .v-list__tile__avatar .v-icon {
  font-family: roboto;
  font-size: 14px;
  font-weight: 700;
}

.page-property-activity .letter-icon {
  width: 40px !important;
  height: 40px !important;
  background-color: white !important;
  color: #8bc34a;
  border: 2px solid #8bc34a;
}

.activity-list {
  position: relative;
}

.activity-list:before {
  display: block;
  content: '';
  width: 1px;
  top: 0;
  bottom: 0;
  left: 36px;
  border-left: 2px dashed #8bc34a;
  position: absolute;
}
</style>
