<template>
  <v-app class="page-members">
    <tev-navbar :title="$t('members')" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <v-form ref="form" lazy-validation @submit.prevent="newSearch">
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field
                    append-icon="search"
                    @click:append="newSearch"
                    v-model="name"
                    :label="$t('search')"
                    type="text"
                    autocomplete="off"
                    box
                    :autofocus="$isDesktop()"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <skeleton-loading class="mb-4" v-if="isLoading">
                <div :key="i" v-for="i in skeletonRows">
                  <row :gutter="{top: '14px', bottom: '12px'}">
                    <column :span="2" :gutter="10">
                      <circle-skeleton></circle-skeleton>
                    </column>
                    <column :span="20" :gutter="6">
                      <square-skeleton
                        :count="1"
                        :boxProperties="{top: '6px', bottom: '4px',width: '200px',height: '14px'}"
                      ></square-skeleton>
                      <square-skeleton
                        :count="1"
                        :boxProperties="{bottom: '6px',width: '120px',height: '12px'}"
                      ></square-skeleton>
                    </column>
                  </row>
                  <square-skeleton
                    :count="1"
                    :boxProperties="{bottom: '1px',width: '100%',height: '1px'}"
                  ></square-skeleton>
                </div>
              </skeleton-loading>

              <v-list two-line subheader class="transparent list-alpha mb-4" v-if="!isLoading">
                <v-divider></v-divider>

                <template v-for="(item, index) in members">
                  <v-subheader
                    :key="index"
                    v-if="!index||String(members[index-1].fullName).substring(0,1).toUpperCase()!=String(item.fullName).substring(0,1).toUpperCase()"
                  >{{ String(item.fullName).substring(0,1) }}</v-subheader>
                  <v-list-tile :key="item.title" avatar :to="'/representative/members/'+item.id">
                    <v-list-tile-avatar>
                      <Lettericon :name="item.fullName" size="2"></Lettericon>
                      <!--v-icon class="grey zinc white--text text-uppercase">{{ item.fullName.substring(0,1) }}</v-icon-->
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.fullName }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{ item.totalProperties }} properties</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <z-status-chip :status="item.status" :label="$t(item.status)"></z-status-chip>
                    </v-list-tile-action>
                    <v-list-tile-action>
                      <v-btn small icon ripple color="primary">
                        <v-icon class="white--text">chevron_right</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </template>
              </v-list>
              <div class="text-xs-center mb-5">
                <div
                  v-if="totalResults"
                >Results {{ currentPage * perPage - perPage + 1 }} - {{ Math.min(currentPage * perPage, totalResults) }} of {{ totalResults }} members</div>
                <v-pagination
                  v-model="currentPage"
                  @input="changePage"
                  :length="totalPages"
                  :total-visible="6"
                  circle
                  v-if="totalPages>1"
                ></v-pagination>
              </div>
              <!--v-btn
                color="light-green"
                dark
                depressed
                block
                large
                class="text-uppercase"
                to="/representative/invite"
              >{{ $t('invite_new_members') }}</v-btn-->
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>

      <!--v-fab-transition>
        <v-btn color="light-green" dark fixed bottom right depressed fab to="/representative/invite">
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition-->
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '@/components/Lettericon'
import Alerts from '@/mixin/Alerts'

export default {
  title: 'Members',
  data() {
    return {
      isLoading: false,
      name: null,
      currentPage: 1,
      perPage: 10,
      totalPages: null,
      totalResults: null,
      members: [],
    }
  },
  mixins: [Alerts],
  components: {
    Lettericon,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    skeletonRows() {
      return !this.members.length ? this.perPage : this.members.length
    },
  },
  watch: {
    currentPage() {
      //this.loadMembers()
    },
  },
  methods: {
    goTo() {},
    changePage(page) {
      this.currentPage = page
      this.loadMembers()
    },
    newSearch() {
      this.currentPage = 1
      this.loadMembers()
    },
    loadMembers() {
      this.isLoading = true
      this.$http
        .get('/representative/members', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            name: this.name,
          },
        })
        .then(response => {
          if (response && 'data' in response && 'members' in response.data) {
            this.members = response.data.members
            this.totalResults = response.data.metadata.total
            this.totalPages = Math.ceil(this.totalResults / this.perPage)
            this.$scrollTo('#app')
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadMembers()
  },
}
</script>
<style>
.page-members .v-content .theme--light.v-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: #cfd8dc;
  color: #fff;
  text-transform: uppercase;
}

.grey.zinc {
  background-color: #cfd8dc !important;
}

.page-members .v-content .v-list--two-line .v-list__tile {
  border-bottom: 1px solid #cfd8dc;
}

.page-members .v-content .v-list__tile__avatar .v-icon {
  font-family: roboto;
  font-size: 14px;
  font-weight: 700;
}
</style>
