import { render, staticRenderFns } from "./TransactionsView.vue?vue&type=template&id=7385d536&"
import script from "./TransactionsView.vue?vue&type=script&lang=js&"
export * from "./TransactionsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VApp,VContent,VDivider,VFlex,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileSubTitle,VListTileTitle})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src266546640/src/zinc-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7385d536')) {
      api.createRecord('7385d536', component.options)
    } else {
      api.reload('7385d536', component.options)
    }
    module.hot.accept("./TransactionsView.vue?vue&type=template&id=7385d536&", function () {
      api.rerender('7385d536', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/tmp/TransactionsView.vue"
export default component.exports