<template>
  <v-layout xs12 sm12 column class="z-digital-id">
    <div class="z-digital-id__head">
      <span>{{ cardName }}</span>
    </div>
    <div class="z-digital-id__body"></div>
  </v-layout>
</template>

<script>
export default {
  props: {
    cardName: {
      type: String,
      default: 'Digital ID',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  mounted() {},
}
</script>

<style>
.z-digital-id {
  background-color: #fff;
  min-height: 160px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.z-digital-id:before {
  content: '';
  position: absolute;
  background: url(../assets/user.svg) center center no-repeat;
  width: 102px;
  height: 102px;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.z-digital-id__head {
  background-color: rgba(229, 229, 229, 1);
  width: 100%;
  display: block;
  padding: 10px 20px;
  min-height: 80px;
  position: relative;
}

.z-digital-id__head > span {
  color: #fff;
  font-size: 28px;
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-style: italic;
}

.z-digital-id__body {
  width: 100%;
  display: block;
  padding: 10px 20px;
}
</style>
