<template>
  <v-app class="page-invite">
    <tev-navbar :title="$t('members')" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm12 md9>
          <v-form ref="form" lazy-validation @submit.prevent="inviteMember">
            <v-container>
              <v-layout align-start justify-space-between fill-height mb-5>
                <v-btn icon class="light-green--text" to="/admin">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <div class="text-xs-center pt-4">
                  <img src="../../assets/icon-user-green.svg" height="60" />
                  <h3 class="font-weight-regular">{{ $t('invite_people_to_zinc') }}</h3>
                </div>
                <z-spacer :width="52" />
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="firstName"
                    :label="$t('first_name')"
                    type="text"
                    box
                    autocomplete="off"
                    @change="hasChanges=true"
                    :autofocus="$isDesktop()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="lastName"
                    :label="$t('last_name')"
                    type="text"
                    box
                    autocomplete="off"
                    @change="hasChanges=true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="cellPhone"
                    :label="$t('cell_phone')"
                    type="number"
                    class="z-callingcode-field"
                    box
                    autocomplete="off"
                    @change="hasChanges=true"
                  >
                    <template v-slot:prepend>
                      <z-callingcode-field
                        :enabledCountryCode="true"
                        :preferredCountries="[]"
                        v-model="callingCode"
                        :label="$t('dialcode')"
                      ></z-callingcode-field>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="email"
                    :label="$t('email')"
                    type="email"
                    box
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-autocomplete
                    v-model="organizationId"
                    @change="organizationChanged"
                    :loading="isLoading"
                    item-text="name"
                    item-value="id"
                    :items="dropdownOrganization"
                    :search-input.sync="search"
                    flat
                    clearable
                    :label="$t('organization_name')"
                    autocomplete="off"
                    box
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-btn
                    :loading="isLoading"
                    color="light-green"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                    @click="inviteMember"
                  >{{ $t('invite') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
//TODO: remove page
import { mapGetters } from 'vuex'
import ZCallingCodeField from '../../components/ZCallingCodeField'
import DisableAutocomplete from '../../mixin/DisableAutocomplete'
import UnsavedChanges from '../../mixin/UnsavedChanges'
import Alerts from '../../mixin/Alerts'

export default {
  title: window.vm.$t('invite_new_members'),
  data() {
    return {
      isLoading: false,
      search: '',
      firstName: null,
      lastName: null,
      email: null,
      callingCode: null,
      cellPhone: null,
      organizationId: null,
      organizationName: null,
      dropdownOrganization: [],
    }
  },
  mixins: [DisableAutocomplete, UnsavedChanges, Alerts],
  components: {
    'z-callingcode-field': ZCallingCodeField,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  watch: {
    search(val) {
      if (this.isLoading || !val || val.length < 3) {
        return
      }
      this.onSearch(val)
    },
  },
  methods: {
    onSearch(query) {
      const _this = this
      this.isLoading = true

      this.$http
        .get('/admin/organizations/tev', {
          params: {
            name: query,
          },
        })
        .then(response => {
          if ('data' in response && 'organizations' in response.data) {
            _this.dropdownOrganization = response.data.organizations
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => (this.isLoading = false))
    },
    organizationChanged(val) {
      this.hasChanges = true
      let item = this.dropdownOrganization.find(o => o.id === val)
      if (item) {
        this.organizationName = item.name
      }
    },
    inviteMember() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.callingCode.dialCode ||
        !this.cellPhone ||
        !this.organizationId
      ) {
        return this.toastError(this.$t('complete_required'))
      }

      this.isLoading = true
      this.$http
        .post('/admin/members', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          dialCode: this.callingCode.dialCode,
          cellPhone: this.cellPhone,
          organizationId: this.organizationId,
          organizationName: this.organizationName,
        })
        .then(() => {
          this.toastSuccess('Invitation sent!')
          this.hasChanges = false
          this.$router.push('/admin/members')
        })
        .catch(err => {
          this.$log.error(err)

          //TODO: Mejorar recepcion de mensajes de error para todas las llamadas a la api
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
