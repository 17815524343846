<template>
  <v-app>
    <tev-navbar :title="$t('my_properties')" />
    <v-content>
      <v-layout row wrap>
        <v-flex xs12 sm12 pa-5 text-xs-center v-if="isInitLoading">
          <img src="../../assets/reload.svg" class="loading" height="100" />
          <p class="title grey--text font-weight-regular">Synchronizing properties</p>
        </v-flex>
        <template v-if="!isInitLoading">
          <v-flex xs12 sm6 class="my-5 text-xs-center" v-if="!properties.length">
            <h3 class="mt-2 mb-5 title">There are no property available for your organization.</h3>
          </v-flex>

          <template v-if="properties.length">
            <v-flex xs12 sm12 px-3 class="pt-3">
              <v-layout row wrap align-start justify-start>
                <img src="../../assets/temple.svg" class="mt-1 mr-3" />
                <v-flex>
                  <div class="body-1 grey--text">{{ $t('organization') }}</div>
                  <div class="title font-weight-regular mb-4">{{ organization.name }}</div>
                </v-flex>
              </v-layout>
              <div class="heading grey--text text--darken-3 font-weight-bold">
                {{ $t('org_total_importproperties', {
                total: properties.length
                })}}
              </div>
              <div class="heading grey--text text--darken-1">{{ $t('import_explain') }}</div>
            </v-flex>

            <v-flex xs12 pt-5 text-xs-center>
              <v-divider></v-divider>

              <v-layout pa-3 row wrap align-start justify-start>
                <v-flex class="py-2 pl-2 white rounded border">
                  <v-text-field
                    v-model="name"
                    label="Search by property name"
                    type="text"
                    autocomplete="off"
                    solo
                    hide-details
                    class="pr-0"
                    background-color="transparent"
                    :autofocus="$isDesktop()"
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <v-btn
                          depressed
                          dark
                          class="text-uppercase"
                          color="primary"
                          @click="searchProperties"
                        >{{ $t('search') }}</v-btn>
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 text-xs-right>
              <v-btn
                round
                depressed
                small
                class="text-uppercase caption font-weight-medium mb-3 mr-3"
                color="light-green"
                dark
                @click="importAll=!importAll"
              >
                <span v-text="importAll?$t('unselect_all'):$t('select_all')"></span>
              </v-btn>
            </v-flex>

            <v-flex xs12 sm12 class="mb-5">
              <v-list two-line class="white py-0 mb-4">
                <v-divider></v-divider>
                <template v-for="property in properties">
                  <v-list-tile :key="property.id" avatar @click="toggleToImport(property.id)">
                    <v-list-tile-content>
                      <v-list-tile-title class="text-truncate">{{ property.name }}</v-list-tile-title>
                      <v-list-tile-sub-title
                        class="text-truncate"
                      >{{ $t('address') }} {{ property.addressLine1 }}, {{ property.city }} {{ property.zip }}, {{ property.state }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-checkbox
                        :value="importAll||toImport.includes(property.id)"
                        color="light-green"
                        class="checkbox-green"
                      ></v-checkbox>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="property.id+'_divider'"></v-divider>
                </template>
              </v-list>

              <mugen-scroll :handler="fetchData" :should-handle="!isLoading" v-if="loadMore">
                <div class="text-xs-center pa-5 mb-5">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>
              </mugen-scroll>

              <v-btn
                :loading="isSaving"
                depressed
                round
                large
                dark
                class="text-uppercase btn-import"
                :class="{'shadow-pulse': hasToImport, 'd-none': !hasToImport}"
                color="light-green"
                @click="importProperties"
              >{{ $t('import_properties') }}</v-btn>

              <back-to-top bottom="50px" right="50px">
                <v-btn fab dark fixed bottom right class="elevation-0" color="arapawa">
                  <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
              </back-to-top>
            </v-flex>
          </template>
        </template>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import MugenScroll from 'vue-mugen-scroll'
import BackToTop from 'vue-backtotop'
import Alerts from '@/mixin/Alerts'

export default {
  data() {
    return {
      isInitLoading: true,
      loadMore: true,
      isSaving: false,
      isLoading: false,
      name: null,
      importAll: false,
      start: false,
      toImport: [],
      filterInput: '',
      currentPage: 1,
      perPage: 10,
      totalResults: 0,
      properties: [],
      organization: null,
      organizationId: null,
    }
  },
  mixins: [Alerts],
  components: { MugenScroll, BackToTop },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    paginationVisible() {
      return this.$vuetify.breakpoint.name == 'xs' ? 1 : 6
    },
    hasToImport() {
      return this.importAll || this.toImport.length
    },
  },
  methods: {
    filterProperties() {
      this.currentPage = 1
      this.loadProperties()
    },
    toggleToImport(id) {
      this.$log.debug(id)
      if (!this.isToImport(id)) {
        this.addToImport(id)
      } else {
        this.removeToImport(id)
      }
    },
    isToImport(id) {
      return this.toImport.indexOf(id) > -1
    },
    addToImport(id) {
      this.toImport.push(id)
    },
    removeToImport(id) {
      this.toImport.splice(this.toImport.indexOf(id), 1)
    },
    importProperties() {
      if (!this.toImport.length && !this.importAll) {
        return this.toastError('Please add some property to import first.')
      }

      this.isSaving = true
      this.$http
        .post('/representative/properties/tev', {
          organizationId: this.organizationId,
          all: this.importAll,
          ids: this.toImport,
          total: this.totalResults,
        })
        .then(() => {
          this.$router.push(
            '/representative/organizations/' +
              this.organizationId +
              '/properties'
          )
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    fetchData() {
      this.showScrollTop = true
      this.currentPage += 1
      this.loadProperties()
    },
    searchProperties() {
      this.currentPage = 1
      this.loadProperties()
    },
    loadProperties() {
      this.isLoading = true
      this.$http
        .get('/representative/properties/tev', {
          params: {
            organizationId: this.organizationId,
            page: this.currentPage,
            per_page: this.perPage,
            name: this.filterInput,
          },
        })
        .then(response => {
          this.properties = this.properties.concat(response.data.properties)
          this.currentPage = response.data.page
          this.perPage = response.data.per_page
          this.totalResults = response.data.total
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
          this.isInitLoading = false
        })
    },
    loadOrganization() {
      this.isLoading = true
      this.$http
        .get('/representative/organizations/' + this.organizationId)
        .then(response => {
          this.organization = response.data
          this.loadProperties()
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
          this.$router.push('/representative/organizations/')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.organizationId = this.$route.params.organizationId
    this.loadOrganization()
  },
}
</script>
<style scoped>
.v-list__tile__action {
  min-width: auto;
}

.loading {
  animation: ckw 0.7s linear infinite;
  margin-bottom: 20px;
}

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-import {
  width: 300px;
  left: 50%;
  margin-left: -150px;
  position: fixed;
  bottom: 20px;
}
</style>
