import Vue from 'vue'
const isProduction = process.env.NODE_ENV === 'production'

// Sentry and LogRocket
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

//import LogRocket from 'logrocket'
//import { setup as setupLogRocket } from './libs/logrocket'

// LogRocket
//setupLogRocket()

if (process.env.NODE_ENV != 'test') {
  // Sentry
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://829c3292bcf54b65899b21da9fff3a79@sentry.io/1771282',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: !isProduction,
      }),
    ],
  })
}

/*
LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setExtra('sessionURL', sessionURL)
  })
})
*/

// Vuetify
import './plugins/vuetify'

// Axios
import './backend/vue-axios'

Vue.config.productionTip = false

// Router and Store
import { sync } from 'vuex-router-sync'
import store from './store'
import router from './router'

router.beforeEach(function(to, from, next) {
  if (!to.hash) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  next()
})

// sync the router with the vuex store.
// this registers `store.state.route`
sync(store, router)

// scrollto
Vue.use(require('vue-scrollto'))

// body-class
import vbclass from 'vue-body-class'
Vue.use(vbclass, router)

// Logger
import VueLogger from 'vuejs-logger'
Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
})

// moment.js
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment,
})

// Scroll To
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

// PageTitle
import VuePageTitle from 'vue-page-title'
Vue.use(VuePageTitle)

// Events
// https://github.com/cklmercer/vue-events
import VueEvents from 'vue-events'
Vue.use(VueEvents)

// Online / Offline
import VueOffline from 'vue-offline'
Vue.use(VueOffline)

// Device Detector
import VueDeviceDetector from 'vue-device-detector'
Vue.use(VueDeviceDetector)

// Toggle Button
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

// Skeleton Loading
import VueSkeletonLoading from 'vue-skeleton-loading'
Vue.use(VueSkeletonLoading)

// signature-pad
import VueSignature from 'vue-signature-pad'
Vue.use(VueSignature)

// Toast
import VuetifyToast from 'vuetify-toast-snackbar'
Vue.use(VuetifyToast, {
  x: 'left',
  y: 'bottom',
  color: 'info',
  icon: false,
  timeout: 6000,
  dismissable: true,
  autoHeight: false,
  multiLine: false,
  vertical: false,
  shorts: {
    custom: {
      color: 'purple',
    },
  },
  classes: 'application',
  property: '$toast',
})

// VueTheMask
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// Dialogs
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
Vue.use(VuetifyDialog, {
  router,
  confirm: {
    actions: {
      false: 'No',
      true: {
        text: 'Yes',
        color: 'primary',
      },
    },
    icon: false,
    width: 500,
  },
  warning: {},
  error: {},
  prompt: {},
})

import Popover from 'vue-js-popover'
Vue.use(Popover)

// Navbar
import TevNavbar from './components/Navbar'
Vue.component('tev-navbar', TevNavbar)

// Subnavbar
import TevSubnavbar from './components/Subnavbar'
Vue.component('tev-subnavbar', TevSubnavbar)

// Footer
import ZFooter from './components/Footer'
Vue.component('z-footer', ZFooter)

// Logo
import Zlogo from './components/ZLogo'
Vue.component('z-logo', Zlogo)

// Spacer
import ZSpacer from './components/Spacer'
Vue.component('z-spacer', ZSpacer)

// Offline
import ZOffline from './components/ZOffline'
Vue.component('z-offline', ZOffline)

// Status Chip
import ZStatusChip from './components/ZStatusChip'
Vue.component('z-status-chip', ZStatusChip)

// PageTitle
import TevPageTitle from './components/PageTitle'
Vue.component('tev-pagetitle', TevPageTitle)

// SocketIO
import { API_URL } from './libs/constants'
import VueSocketIO from 'vue-socket.io'

// Image cropper
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
Vue.component('Cropper', Cropper)
Vue.component('CircleStencil', CircleStencil)

// Print html
import VueHtmlToPaper from 'vue-html-to-paper';
Vue.use(VueHtmlToPaper);

// Image gallery
import VueImg from 'v-img';
Vue.use(VueImg);

Vue.use(
  new VueSocketIO({
    debug: !isProduction,
    connection: API_URL.replace('/v1', ''),
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
    options: {},
  })
)

// i18n
import i18n from './libs/i18n'

// Filters
import Filters from './filters'
Vue.use(Filters)

// App.vue
import App from './App.vue'

// CSS
import 'vue2-animate/dist/vue2-animate.min.css'
import './scss/style.scss'

// Vue
window.vm = new Vue({
  i18n,
  store,
  router,
  sockets: {
    forceReload() {
      this.$log.info(
        'Zinc is pushing a client update, which will cause your browser to restart. We apologize for any inconvenience.'
      )
      location.reload()
    },
  },
  render: h => h(App),
}).$mount('#app')
