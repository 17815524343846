var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.property
        ? _c("tev-navbar", {
            attrs: {
              title: _vm.property.name ? "Banks: " + _vm.property.name : ""
            }
          })
        : _vm._e(),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { x12: "", sm8: "" } }, [
                    _vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "text-xs-center" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 50,
                                color: "primary",
                                indeterminate: ""
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "" } },
                    [
                      _vm.property && !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "align-start": "",
                                "justify-start": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goBack()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "py-3": "",
                                    "text-xs-right": "",
                                    "grey--text": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" "),
                                      _vm.propertyId
                                        ? [
                                            _vm._v(
                                              _vm._s(_vm.$t("property_id")) +
                                                ": " +
                                                _vm._s(
                                                  _vm.property.tevPropertyId
                                                )
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("z-status-chip", {
                                    attrs: {
                                      status: _vm.property.status,
                                      label: _vm.$t(_vm.property.status)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "" } },
                    [
                      !_vm.isLoading && _vm.banks.length == 0
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-xs-center pt-5 mb-5 grey--text"
                                },
                                [
                                  _c(
                                    "h2",
                                    { staticClass: "headline black--text" },
                                    [
                                      _vm._v(
                                        " No banks defined. Please ask accountant to setup bank accounts for this property. "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.banks.length > 0
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c("v-flex", { attrs: { "py-3": "" } }, [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-uppercase grey--text text--lighten-1 mb-3"
                                      },
                                      [_vm._v(" Bank Accounts ")]
                                    )
                                  ]),
                                  _c("v-flex", {
                                    attrs: {
                                      "py-3": "",
                                      "text-xs-right": "",
                                      "grey--text": ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { staticClass: "z-card" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-2 py-1" },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "transparent",
                                          attrs: { "two-line": "" }
                                        },
                                        [
                                          _vm._l(_vm.banks, function(
                                            item,
                                            index
                                          ) {
                                            return [
                                              index > 0
                                                ? _c("v-divider", {
                                                    key: "d" + item.id
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-list-tile",
                                                {
                                                  key: item.id,
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$router.push(
                                                        "/" +
                                                          _vm.currentUser
                                                            .accountType +
                                                          "/properties/" +
                                                          _vm.propertyId +
                                                          "/banks/" +
                                                          item.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c(
                                                        "v-list-tile-title",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.alias
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-list-tile-sub-title",
                                                        [
                                                          _vm._v(
                                                            "Bank Account Alias"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c(
                                                        "v-list-tile-title",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.last4
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-list-tile-sub-title",
                                                        [
                                                          _vm._v(
                                                            "4 last digits"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-action",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "no-ripple": "",
                                                            small: "",
                                                            icon: "",
                                                            ripple: false,
                                                            disabled: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "grey--text",
                                                              attrs: {
                                                                size: "32"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "chevron_right"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }