<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar title />
    <tev-subnavbar back="/accountant/properties" title="Bank Account Setup" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 mb-4>
            <div class="font-weight-bold heading">Icon Brickell Condo No1</div>
            <div class="grey--text">{{ $t('property_name') }}</div>
          </v-flex>
          <v-flex xs12 mb-4>How do you want to connect your property bank account</v-flex>
          <v-flex xs12 mb-4>
            <v-layout align-space-between justify-space-between row fill-height white>
              <v-flex pa-3>
                <h3 class="primary--text font-weight-regular">Using online banking credentials</h3>
                <div class="font-weight-bold">Immediate bank verification</div>
                <div>Make sure you have the online user and password of the online banking of the property bank account.</div>
              </v-flex>
              <v-layout
                align-center
                fill-height
                column
                white
                pa-3
                grey
                lighten-3
                light-green--text
                style="max-width:150px"
              >
                <v-checkbox :value="false" color="light-green" class="checkbox-green"></v-checkbox>
                <div>{{ $t('select') }}</div>
              </v-layout>
            </v-layout>
          </v-flex>
          <v-flex xs12 mb-4>
            <v-layout align-space-between justify-space-between row fill-height white>
              <v-flex pa-3>
                <h3 class="primary--text font-weight-regular">Bank account manual set up</h3>
                <div>Manually add the routing and account number of the property bank account.</div>
                <div class="font-weight-bold">Bank verification may take up to 3 business days</div>
              </v-flex>
              <v-layout
                align-center
                fill-height
                column
                white
                pa-3
                grey
                lighten-3
                light-green--text
                style="max-width:150px"
              >
                <v-checkbox :value="false" color="light-green" class="checkbox-green"></v-checkbox>
                <div>{{ $t('select') }}</div>
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
}
</script>