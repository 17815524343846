<template>
  <v-app class="page-organizations">
    <tev-navbar :title="$t('organizations')" />
    <v-content>
      <v-layout row wrap pt-4 v-if="organization">
        <v-flex xs12 sm12 md9>
          <v-form ref="form" lazy-validation @submit.prevent="inviteRepresentative">
            <v-container>
              <v-layout align-start justify-space-between fill-height mb-5>
                <v-btn icon class="light-green--text" @click="organization = null">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <div class="text-xs-center pt-4">
                  <img src="../../assets/icon-user-green.svg" height="60" />
                  <h3 class="black--text">{{ organization.name }}</h3>
                </div>
                <z-spacer :width="52" />
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 text-xs-center mb-4>
                  <h2 class="font-weight-light">Invite Property Account Manager</h2>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="repId"
                    :items="representatives"
                    item-value="id"
                    item-text="fullName"
                    box
                    label="Select Account Manager"
                    @change="hasChanges = true"
                  ></v-select>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-if="repId"
                    v-model="repCommission"
                    :label="$t('add_commission_value')"
                    type="number"
                    box
                    autocomplete="off"
                    @change="hasChanges = true"
                    :autofocus="true"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 v-if="repId">
                  <v-btn
                    :loading="isLoading"
                    color="light-green"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                    @click="inviteRepresentative"
                  >{{ $t('invite') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>

      <v-layout row wrap pt-4 v-if="!organization">
        <v-flex xs12>
          <v-form ref="form" lazy-validation @submit.prevent="newSearch">
            <v-container>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    append-icon="search"
                    @click:append="newSearch"
                    v-model="name"
                    :label="$t('search')"
                    type="text"
                    autocomplete="off"
                    box
                    class="box-border"
                    :autofocus="$isDesktop()"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <skeleton-loading class="mb-4" v-if="isLoading">
                <div :key="i" v-for="i in skeletonRows">
                  <row :gutter="{ top: '14px', bottom: '12px' }">
                    <column :span="20" :gutter="6">
                      <square-skeleton
                        :count="1"
                        :boxProperties="{
                          top: '6px',
                          bottom: '4px',
                          width: '200px',
                          height: '14px',
                        }"
                      ></square-skeleton>
                      <square-skeleton
                        :count="1"
                        :boxProperties="{
                          bottom: '6px',
                          width: '120px',
                          height: '12px',
                        }"
                      ></square-skeleton>
                    </column>
                  </row>
                  <square-skeleton
                    :count="1"
                    :boxProperties="{
                      bottom: '1px',
                      width: '100%',
                      height: '1px',
                    }"
                  ></square-skeleton>
                </div>
              </skeleton-loading>

              <v-divider></v-divider>
              <v-list two-line subheader class="transparent mb-4" v-if="!isLoading">
                <v-list-tile
                  v-for="item in organizations"
                  :key="item.title"
                  avatar
                  @click="toImport(item)"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ item.addressLine1 }}
                      {{ item.addressLine2 }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn small icon ripple color="primary">
                      <v-icon class="white--text">add</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
              <div class="text-xs-center mb-5">
                <div v-if="totalResults">
                  Results {{ currentPage * perPage - perPage + 1 }} -
                  {{ Math.min(totalResults, currentPage * perPage) }} of
                  {{ totalResults }} organizations
                </div>
                <v-pagination
                  v-model="currentPage"
                  @input="changePage"
                  :length="totalPages"
                  :total-visible="6"
                  circle
                  v-if="totalPages > 1"
                ></v-pagination>
              </div>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'

export default {
  title: window.vm.$t('organizations'),
  data() {
    return {
      isLoading: false,
      organization: null,
      repId: null,
      repCommission: null,
      name: null,
      currentPage: 1,
      perPage: 10,
      totalPages: null,
      totalResults: null,
      organizations: [],
      representatives: [],
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    skeletonRows() {
      return !this.organizations.length
        ? this.perPage
        : this.organizations.length
    },
  },
  watch: {
    currentPage() {
      //this.loadMembers()
    },
  },
  methods: {
    toImport(item) {
      this.organization = item
    },
    inviteRepresentative() {
      if (!this.repId || !this.organization) {
        return this.toastError(this.$t('complete_required'))
      }
      this.isLoading = true
      this.$http
        .post('/admin/organizations', {
          tevId: this.organization.id,
          repId: this.repId,
          repCommission: this.repCommission,
          name: this.organization.name,
          addressLine1: this.organization.addressLine1,
          addressLine2: this.organization.addressLine2,
          city: this.organization.city,
          postalCode: this.organization.zip,
          state: this.organization.state,
        })
        .then(() => {
          this.toastSuccess('Invitation sent!')
          this.hasChanges = false
          this.$router.push('/admin/organizations')
        })
        .catch(err => {
          this.$log.error(err)
          if (
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadRepresentatives() {
      this.$http
        .get('/admin/representatives', {
          params: {},
        })
        .then(response => {
          if (
            response &&
            'data' in response &&
            'representatives' in response.data
          ) {
            this.representatives = response.data.representatives
          }
        })
        .catch(err => {
          this.$log.error(err)
        })
    },
    changePage(page) {
      this.currentPage = page
      this.loadOrganizations()
    },
    newSearch() {
      this.currentPage = 1
      this.loadOrganizations()
    },
    loadOrganizations() {
      // offline
      if (!this.isOnline) {
        return this.toastError('You are offline!')
      }

      this.isLoading = true
      this.$http
        .get('/admin/organizations/tev', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            name: this.name,
          },
        })
        .then(response => {
          if (
            response &&
            'data' in response &&
            'organizations' in response.data
          ) {
            this.organizations = response.data.organizations
            this.totalResults = response.data.total
            this.totalPages = Math.ceil(this.totalResults / this.perPage)
            this.$scrollTo('#app')
          }
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.loadOrganizations()
    this.loadRepresentatives()
  },
}
</script>
<style>
.page-organizations .v-content .theme--light.v-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: #cfd8dc;
  color: #fff;
  text-transform: uppercase;
}

.grey.zinc {
  background-color: #cfd8dc !important;
}

.page-organizations .v-content .v-list--two-line .v-list__tile {
  border-bottom: 1px solid #cfd8dc;
}

.page-organizations .v-content .v-list__tile__avatar .v-icon {
  font-family: roboto;
  font-size: 14px;
  font-weight: 700;
}

.page-organizations .letter-icon {
  width: 40px !important;
  height: 40px !important;
}
</style>
