<template>
  <v-app class="page-members">
    <tev-navbar :title="$t('my_property')" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <v-form ref="form" lazy-validation @submit.prevent="newSearch">
            <v-container>
              <v-layout align-start justify-space-between row fill-height mb-5>
                <v-btn icon @click="isInvite=false" class="light-green--text">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
                <div class="text-xs-center">
                  <img src="../../assets/icon-property-green.svg" height="60" />
                  <h3 class="font-weight-regular">{{ $t('transfer_property') }}</h3>
                </div>
                <z-spacer :width="52"></z-spacer>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field
                    :label="$t('new_property_management_company')"
                    type="text"
                    box
                    :autofocus="$isDesktop()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field :label="$t('manager_first_name')" type="text" box></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field :label="$t('manager_last_name')" type="text" box></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field :label="$t('cell_phone')" type="text" box></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field :label="$t('email')" type="email" box></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-select
                    :items="roleTypes"
                    append-icon="expand_more"
                    item-text="name"
                    item-value="type"
                    label="Role"
                    box
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-btn
                    color="light-green"
                    dark
                    depressed
                    block
                    large
                    class="text-uppercase"
                    @click="isInvite=true"
                  >{{ $t('transfer_property') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      roleTypes: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
}
</script>
