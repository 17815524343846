var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c("p", { staticClass: "mb-4 grey--text" }, [
        _vm._v("Please enter the property company information.")
      ]),
      _vm.formSchema
        ? _c("SchemaForm", {
            attrs: { schema: _vm.formSchema, model: _vm.formData },
            on: { error: _vm.showError },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend-einFile",
                  fn: function(ref) {
                    return [
                      _c("v-flex", { attrs: { sm12: "" } }, [
                        _c("div", { staticClass: "mb-3 grey--text" }, [
                          _vm._v(
                            "The document(s) will then be reviewed; this review may take up to 1-2 business days to approve or reject."
                          )
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              3406583626
            )
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { round: "", depressed: "", color: "primary" },
          on: { click: _vm.submit }
        },
        [_vm._v("Continue")]
      ),
      _c(
        "v-btn",
        {
          attrs: { round: "", flat: "" },
          on: {
            click: function($event) {
              return _vm.$emit("back")
            }
          }
        },
        [_vm._v("Back")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }