<template>
  <v-app class="page-with-subnavbar">
    <tev-navbar :title="$t('transactions')" />
    <tev-subnavbar :title="$t('current_transactions_list')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm12 class="mb-5 text-xs-center">
            <template v-for="item in transactions">
              <v-card
                class="card-tx"
                :key="item.id"
                v-ripple="{ class: 'light-green--text' }"
              >
                <v-card-title>{{ item.fullName }}</v-card-title>
                <v-divider></v-divider>
                <v-card-actions>{{
                  $t(item.status.toLowerCase())
                }}</v-card-actions>
              </v-card>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      transactions: [
        {
          id: 1,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Completed',
        },
        {
          id: 2,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
        {
          id: 3,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Completed',
        },
        {
          id: 4,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
        {
          id: 5,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
        {
          id: 6,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Completed',
        },
        {
          id: 7,
          fullname: 'Thomas Smith',
          property_name: 'Icon Brickell Condo No1',
          status: 'Pending',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (!this.currentUser || !localStorage.token) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
  },
  mounted() {
    //this.loadTransactions()
  },
}
</script>
<style>
.card-tx {
  -webkit-box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 80px;
}

.card-tx .v-divider {
  border-color: rgba(0, 0, 0, 0.07);
}
</style>
