var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        color: "transparent",
        "max-width": "600",
        width: "100%"
      }
    },
    [
      !_vm.success
        ? [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "", "mt-4": "" } },
              [
                _c(
                  "v-flex",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title black--text font-weight-regular mb-4"
                      },
                      [
                        _vm._v(
                          "Make sure the bank information provided is ok before continue."
                        )
                      ]
                    ),
                    _c("div", { staticClass: "grey--text body-1" }, [
                      _vm._v("Name:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "mb-2 font-weight-bold black--text" },
                      [_vm._v(_vm._s(_vm.bankSetup.account.name))]
                    ),
                    _c("div", { staticClass: "grey--text body-1" }, [
                      _vm._v("Account Number:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "mb-5 font-weight-bold black--text" },
                      [
                        _vm._v(
                          " ending in " +
                            _vm._s(
                              _vm.bankSetup.account.mask ||
                                _vm.bankSetup.account.last4
                            ) +
                            " (" +
                            _vm._s(
                              _vm.bankSetup.account.subtype ||
                                _vm.bankSetup.account.bankAccountType
                            ) +
                            ") "
                        )
                      ]
                    ),
                    _vm.property
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mb-3": "" } },
                          [
                            _c(
                              "div",
                              { staticClass: "grey--text body-1 mb-1" },
                              [_vm._v("Bank Account Alias:")]
                            ),
                            _c("v-text-field", {
                              attrs: {
                                "single-line": "",
                                autofocus: _vm.$isDesktop(),
                                label: "Bank Account Alias",
                                placeholder: "",
                                required: "",
                                box: ""
                              },
                              model: {
                                value: _vm.bankSetup.account.alias,
                                callback: function($$v) {
                                  _vm.$set(_vm.bankSetup.account, "alias", $$v)
                                },
                                expression: "bankSetup.account.alias"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.application
                      ? _c(
                          "v-card",
                          { staticClass: "z-card mb-4" },
                          [
                            _vm._l(_vm.application.payments, function(
                              item,
                              index
                            ) {
                              return [
                                _vm.contractType == item.contractType ||
                                _vm.contractType == "all"
                                  ? _c(
                                      "v-layout",
                                      {
                                        key: item.id,
                                        attrs: { row: "", "ma-3": "" }
                                      },
                                      [
                                        _c("v-flex", [
                                          _c("div", [
                                            _vm._v(_vm._s(item.feeName))
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "grey--text body-1 mb-2"
                                            },
                                            [_vm._v("Description")]
                                          )
                                        ]),
                                        _c(
                                          "v-flex",
                                          { attrs: { "text-xs-right": "" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "indigo--text text--darken-4"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        item.feeRateType ==
                                                          "variable"
                                                          ? _vm.variableSetup[
                                                              item.id
                                                            ].amount
                                                          : item.amount
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "grey--text body-1"
                                              },
                                              [
                                                _vm.contractType == "deposit"
                                                  ? [
                                                      _vm._v(
                                                        "Amount to deposit"
                                                      )
                                                    ]
                                                  : [_vm._v("Amount to pay")]
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.contractType == item.contractType ||
                                _vm.contractType == "all"
                                  ? _c("v-divider", { key: index })
                                  : _vm._e()
                              ]
                            }),
                            _c(
                              "v-layout",
                              { attrs: { row: "", "ma-3": "" } },
                              [
                                _c("v-flex", [
                                  _c("div", [_c("strong", [_vm._v("Total")])])
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { "text-xs-right": "" } },
                                  [
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(_vm.totalAmount)
                                          )
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.property
                      ? _c(
                          "v-card",
                          { staticClass: "z-card mb-4" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", "ma-3": "" } },
                              [
                                _c("v-flex", [
                                  _c("div", [_vm._v("Property Setup Fee")]),
                                  _c(
                                    "div",
                                    { staticClass: "grey--text body-1 mb-2" },
                                    [_vm._v("Description")]
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { "text-xs-right": "" } },
                                  [
                                    _c("div"),
                                    _c(
                                      "div",
                                      { staticClass: "grey--text body-1" },
                                      [_vm._v("Amount to pay")]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-layout",
                              { attrs: { row: "", "ma-3": "" } },
                              [
                                _c("v-flex", [
                                  _c("div", [_c("strong", [_vm._v("Total")])])
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { "text-xs-right": "" } },
                                  [
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(_vm.totalAmount)
                                          )
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  {
                    attrs: {
                      "body-1": "",
                      "mb-4": "",
                      "grey--text": "",
                      "text--darken-1": ""
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        " I hereby authorize Zinc LLC (“Zinc”), its parent, affiliates and their respective agents and their assignees to initiate, depending on the payment method I select, a single or recurring electronic debit entry/entries to my designated checking, savings account or credit card (“Account”) at my designated financial institution or credit issuer (“Financial Institution”) for which I am an authorized user, as well as any Account or Financial Institution I later designate, for the single or recurring transactions I authorize. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " I acknowledge that the origination of electronic debits to my Account must be permitted by my Financial Institution, which must be located in the United States. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " I understand that my authorization will remain in full force and effect until Zinc has received written notification from me at least 3 business days prior to my scheduled transfer to terminate this authorization. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " If I am using a credit card to conduct a transaction, an authorization hold will be secured on the credit card provided, to cover the deposits or charges and any fees that may be incurred. These funds will not be available for your use. Additional authorizations (holds) will be obtained if I authorize subsequent transactions using a credit card. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " We use Dwolla for payment processing and we do not store bank account numbers or credit card information. By checking this box you agree to our "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "grey--text",
                          attrs: {
                            href: "https://zinc.tech/terms-of-service/",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Terms of Service")]
                      ),
                      _vm._v(" and "),
                      _c(
                        "a",
                        {
                          staticClass: "grey--text",
                          attrs: {
                            href: "https://zinc.tech/privacy-policy/",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(" , as well as our partner "),
                      _c(
                        "a",
                        {
                          staticClass: "grey--text",
                          attrs: {
                            href: "https://www.dwolla.com/legal/tos/",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Dwolla'sTerms of service")]
                      ),
                      _vm._v(" and "),
                      _c(
                        "a",
                        {
                          staticClass: "grey--text",
                          attrs: {
                            href: "https://www.dwolla.com/legal/privacy/",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(". ")
                    ])
                  ]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mb-4": "" } },
                  [
                    _c("z-sign", {
                      ref: "sign",
                      on: { change: _vm.signChange }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "text-uppercase mb-5",
                class: { "blue--text": _vm.isLoading },
                attrs: {
                  color: "primary",
                  dark: "",
                  depressed: "",
                  block: "",
                  large: "",
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading
                },
                on: {
                  click: function($event) {
                    return _vm.makePayment()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("pay")))]
            )
          ]
        : _vm._e(),
      _vm.success
        ? _c("z-success-pay", {
            attrs: {
              amount: _vm.totalAmount,
              description: _vm.payDescription,
              title: "Your payment has been successfully processed.",
              subtitle:
                "Soon you will get and email with all the transaction details.",
              message: _vm.payMessage
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }