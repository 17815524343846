import axios from 'axios'
import { API_URL } from '../../libs/constants'
import config from '../../libs/config'

const client = axios.create({
  baseURL: API_URL,
  transformRequest: [
    function(data, headers) {
      headers['Accept-Language'] = navigator.language || navigator.userLanguage
      headers['Content-Language'] = config.getLan()

      if (localStorage.token) {
        headers['Authorization'] = 'Bearer ' + localStorage.token
      }
      return Object.prototype.toString.call(data) === '[object String]'
        ? data
        : JSON.stringify(data)
    },
  ],
  headers: {
    'Content-Type': 'application/json',
  },
})

export default client
