<template>
  <v-app>
    <tev-navbar title="Change password" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex x12 sm8 md6>
          <v-layout row wrap align-start justify-start>
            <v-flex text-xs-left grey--text>
              <v-btn icon class="light-green--text" @click="$router.back()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-form ref="form" class="form-sm" @submit.prevent="resetPassword">
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field
                    xs12
                    v-model="currentPassword"
                    @click:append="toggleCurrent"
                    :append-icon="currentIcon"
                    :type="currentType"
                    label="Current Password"
                    :autofocus="$isDesktop()"
                    :rules="rules.password"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field
                    xs12
                    v-model="newPassword"
                    @click:append="toggleNew"
                    :append-icon="newIcon"
                    :type="newType"
                    :label="$t('new_password')"
                    :rules="rules.password"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field
                    xs12
                    v-model="confirmPassword"
                    @click:append="toggleConfirm"
                    :append-icon="confirmIcon"
                    :type="confirmType"
                    :label="$t('confirm_password')"
                    :rules="rules.confirmPassword"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 mb-5>
                  <v-btn
                    type="submit"
                    color="light-green"
                    dark
                    large
                    block
                    depressed
                    @click="resetPassword"
                  >{{ $t('continue') }}</v-btn>
                </v-flex>
                <v-flex xs12 sm12 mb-5>
                  <p class="black--text">We strongly recommend the use of complex passwords.</p>
                  <p>
                    A good password should have the following minimum
                    characteristics:
                  </p>
                  <ul>
                    <li>At least {{ minPasswordLength }} characters Contain upper case letters</li>
                    <li>Contain lower case letters</li>
                    <li>Contain numeric characters</li>
                    <li>Contain special characters such as @ and $</li>
                    <li>
                      Do not contain personal information such as names or
                      birthdays
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Validator from '@/libs/validator'
import Alerts from '@/mixin/Alerts'
import { MIN_PASSWORD_LENGTH } from '@/libs/constants'

export default {
  data() {
    const _this = this
    const validator = new Validator()
    return {
      loading: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      minPasswordLength: MIN_PASSWORD_LENGTH,

      currentType: 'password',
      currentIcon: 'remove_red_eye',
      newType: 'password',
      newIcon: 'remove_red_eye',
      confirmType: 'password',
      confirmIcon: 'remove_red_eye',

      snackbar: false,
      snackbarText: '',

      rules: {
        password: [
          validator.rules.required('You must complete the required fields.'),
          validator.rules.minLength(
            8,
            'Your password must contain no less than 8 characters.'
          ),
          validator.rules.maxLength(
            100,
            'Your password should not contain more than 100 characters.'
          ),
        ],
        confirmPassword: [
          validator.rules.matches(
            _this,
            'newPassword',
            'The passwords you entered do not match.'
          ),
        ],
      },
    }
  },
  mixins: [Alerts],
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    getType(el) {
      return el == 'text' ? 'password' : 'text'
    },
    getIcon(el) {
      return el == 'text' ? 'more_horiz' : 'remove_red_eye'
    },
    toggleCurrent() {
      this.currentType = this.getType(this.currentType)
      this.currentIcon = this.getIcon(this.currentType)
    },
    toggleNew() {
      this.newType = this.getType(this.newType)
      this.newIcon = this.getIcon(this.newType)
    },
    toggleConfirm() {
      this.confirmType = this.getType(this.confirmType)
      this.confirmIcon = this.getIcon(this.confirmType)
    },
    resetPassword() {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.currentPassword == this.newPassword) {
        return this.toastError(
          'Your new password is the same as the current password.'
        )
      }

      if (
        !this.newPassword.match(/[a-z]+/) ||
        !this.newPassword.match(/[A-Z]+/) ||
        !this.newPassword.match(/[0-9]+/)
      ) {
        return this.toastError(
          'Your password must contain numbers and letters.'
        )
      }

      this.$http
        .post('/account/password/change ', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.emailsent = true
          this.toastSuccess('Your password has been saved successfully.')
          this.currentPassword = ''
          this.newPassword = ''
          this.confirmPassword = ''
          this.$router.push('/account')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
    },
  },
}
</script>
