var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-contract-setup page-with-subnavbar" },
    [
      _c("tev-navbar"),
      _c("tev-subnavbar", {
        attrs: {
          back:
            "/" +
            _vm.currentUser.accountType +
            "/properties/" +
            _vm.propertyId +
            "/contracts",
          title: "Smart Contract Setup"
        }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pt-3": "", "mb-4": "" } },
                        [
                          _vm.property
                            ? _c("h2", { staticClass: "title" }, [
                                _vm._v(_vm._s(_vm.property.name))
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v(_vm._s(_vm.$t("property_name")))
                          ])
                        ]
                      ),
                      _vm.contract.contractType
                        ? _c(
                            "v-form",
                            {
                              ref: "form",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.saveContract($event)
                                }
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "white",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pa-3": "" } },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "light-green--text text-uppercase mb-2"
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-3",
                                            attrs: {
                                              src:
                                                _vm.contractTypes[
                                                  _vm.contract.contractType
                                                ].icon
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.contractTypes[
                                                  _vm.contract.contractType
                                                ].name
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "pr-sm-1",
                                      attrs: {
                                        xs12: "",
                                        "px-3": "",
                                        "py-1": ""
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _vm._v("Type of application")
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          "single-line": "",
                                          multiple: "",
                                          chips: "",
                                          items: _vm.applicationTypes,
                                          "item-text": "name",
                                          "item-value": "id",
                                          box: "",
                                          "background-color": "#F1F5F7",
                                          flat: "",
                                          label: "Select",
                                          "append-icon": "keyboard_arrow_down",
                                          required: "",
                                          rules: _vm.rules.required
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.hasChanges = true
                                          }
                                        },
                                        model: {
                                          value: _vm.contract.applicationType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "applicationType",
                                              $$v
                                            )
                                          },
                                          expression: "contract.applicationType"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "pl-sm-1",
                                      attrs: {
                                        xs12: "",
                                        "px-3": "",
                                        "py-0": ""
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _vm._v("Description")
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          "single-line": "",
                                          items: _vm.contractFeeItems,
                                          "item-text": "name",
                                          "item-value": "id",
                                          box: "",
                                          "background-color": "#F1F5F7",
                                          flat: "",
                                          label: "Select",
                                          "append-icon": "keyboard_arrow_down",
                                          required: "",
                                          rules: _vm.rules.required
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.hasChanges = true
                                          }
                                        },
                                        model: {
                                          value: _vm.contract.feeItemId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "feeItemId",
                                              $$v
                                            )
                                          },
                                          expression: "contract.feeItemId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.contract.contractType == "deposit"
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "px-3": "",
                                            "py-0": ""
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "mb-2" }, [
                                            _vm._v("Fee Rate Type")
                                          ]),
                                          _c("v-select", {
                                            attrs: {
                                              "single-line": "",
                                              items: _vm.feeRateTypes,
                                              "item-text": "name",
                                              "item-value": "id",
                                              box: "",
                                              "background-color": "#F1F5F7",
                                              flat: "",
                                              label: "Select",
                                              "append-icon":
                                                "keyboard_arrow_down",
                                              required: "",
                                              rules: _vm.rules.required
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            model: {
                                              value: _vm.contract.feeRateType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "feeRateType",
                                                  $$v
                                                )
                                              },
                                              expression: "contract.feeRateType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.contract.contractType == "deposit" &&
                                  _vm.contract.feeRateType == "variable"
                                    ? [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              "px-3": "",
                                              "py-0": ""
                                            }
                                          },
                                          [
                                            _c("div", { staticClass: "mb-2" }, [
                                              _vm._v("Set Up By")
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                "single-line": "",
                                                items: _vm.setupByTypes,
                                                "item-text": "name",
                                                "item-value": "id",
                                                box: "",
                                                "background-color": "#F1F5F7",
                                                flat: "",
                                                label: "Select",
                                                "append-icon":
                                                  "keyboard_arrow_down",
                                                required: "",
                                                rules: _vm.rules.required
                                              },
                                              on: {
                                                change: function($event) {
                                                  _vm.hasChanges = true
                                                }
                                              },
                                              model: {
                                                value: _vm.contract.setupByType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "setupByType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contract.setupByType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.contract.setupByType
                                          ? [
                                              _vm._l(
                                                _vm.contract.units,
                                                function(unit, index) {
                                                  return [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "primary--border",
                                                        attrs: {
                                                          xs12: "",
                                                          "pa-3": "",
                                                          "my-3": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs12: "",
                                                              "pb-1": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  "align-space-between":
                                                                    "",
                                                                  "justify-space-between":
                                                                    "",
                                                                  row: "",
                                                                  "fill-height":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text pt-1 pb-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Variable No" +
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c("v-spacer"),
                                                                _vm.contract
                                                                  .units
                                                                  .length > 1
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "ma-0",
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          flat:
                                                                            "",
                                                                          color:
                                                                            "primary"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.contract.units.splice(
                                                                              index,
                                                                              1
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "close"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            "single-line": "",
                                                            label:
                                                              "Variable alias",
                                                            box: "",
                                                            "background-color":
                                                              "#F1F5F7",
                                                            flat: "",
                                                            autocomplete: "off",
                                                            required: "",
                                                            rules:
                                                              _vm.rules.required
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .units[index]
                                                                .alias,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract
                                                                  .units[index],
                                                                "alias",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.units[index].alias"
                                                          }
                                                        }),
                                                        _vm.contract
                                                          .setupByType == "name"
                                                          ? _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      "single-line":
                                                                        "",
                                                                      label:
                                                                        "Add unit type name",
                                                                      box: "",
                                                                      "background-color":
                                                                        "#F1F5F7",
                                                                      flat: "",
                                                                      autocomplete:
                                                                        "off",
                                                                      required:
                                                                        "",
                                                                      rules:
                                                                        _vm
                                                                          .rules
                                                                          .required
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .contract
                                                                          .units[
                                                                          index
                                                                        ].name,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .contract
                                                                            .units[
                                                                            index
                                                                          ],
                                                                          "name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "contract.units[index].name"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.contract
                                                          .setupByType == "rent"
                                                          ? _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      "single-line":
                                                                        "",
                                                                      label:
                                                                        "Add rent value",
                                                                      box: "",
                                                                      "background-color":
                                                                        "#F1F5F7",
                                                                      flat: "",
                                                                      type:
                                                                        "number",
                                                                      autocomplete:
                                                                        "off",
                                                                      required:
                                                                        "",
                                                                      rules:
                                                                        _vm
                                                                          .rules
                                                                          .required
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .contract
                                                                          .units[
                                                                          index
                                                                        ].rent,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .contract
                                                                            .units[
                                                                            index
                                                                          ],
                                                                          "rent",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "contract.units[index].rent"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.contract
                                                          .setupByType ==
                                                        "rooms"
                                                          ? _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: "",
                                                                  "pr-2": ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    "single-line":
                                                                      "",
                                                                    items:
                                                                      _vm.roomsTypes,
                                                                    "item-text":
                                                                      "name",
                                                                    "item-value":
                                                                      "id",
                                                                    box: "",
                                                                    "background-color":
                                                                      "#F1F5F7",
                                                                    flat: "",
                                                                    label:
                                                                      "Select No of Rooms",
                                                                    "append-icon":
                                                                      "keyboard_arrow_down",
                                                                    required:
                                                                      "",
                                                                    rules:
                                                                      _vm.rules
                                                                        .required
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .contract
                                                                        .units[
                                                                        index
                                                                      ].rooms,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .contract
                                                                          .units[
                                                                          index
                                                                        ],
                                                                        "rooms",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "contract.units[index].rooms"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.contract
                                                          .setupByType == "sqft"
                                                          ? [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: "",
                                                                        "pr-3":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            type:
                                                                              "number",
                                                                            "single-line":
                                                                              "",
                                                                            label:
                                                                              "Sqft From",
                                                                            box:
                                                                              "",
                                                                            "background-color":
                                                                              "#F1F5F7",
                                                                            flat:
                                                                              "",
                                                                            autocomplete:
                                                                              "off",
                                                                            required:
                                                                              "",
                                                                            hint:
                                                                              "Example: 100",
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .contract
                                                                                .units[
                                                                                index
                                                                              ]
                                                                                .sqftFrom,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .contract
                                                                                  .units[
                                                                                  index
                                                                                ],
                                                                                "sqftFrom",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "contract.units[index].sqftFrom"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: "",
                                                                        "pl-3":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            type:
                                                                              "number",
                                                                            "single-line":
                                                                              "",
                                                                            label:
                                                                              "Sqft To",
                                                                            box:
                                                                              "",
                                                                            "background-color":
                                                                              "#F1F5F7",
                                                                            flat:
                                                                              "",
                                                                            autocomplete:
                                                                              "off",
                                                                            required:
                                                                              "",
                                                                            hint:
                                                                              "Example: 200",
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .contract
                                                                                .units[
                                                                                index
                                                                              ]
                                                                                .sqftTo,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .contract
                                                                                  .units[
                                                                                  index
                                                                                ],
                                                                                "sqftTo",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "contract.units[index].sqftTo"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          : _vm._e(),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mb-2"
                                                              },
                                                              [_vm._v("Amount")]
                                                            ),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                "single-line":
                                                                  "",
                                                                label:
                                                                  "$ Add deposit amount",
                                                                box: "",
                                                                "background-color":
                                                                  "#F1F5F7",
                                                                flat: "",
                                                                autocomplete:
                                                                  "off",
                                                                required: "",
                                                                rules:
                                                                  _vm.rules
                                                                    .required
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.contract
                                                                    .units[
                                                                    index
                                                                  ].amount,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.contract
                                                                      .units[
                                                                      index
                                                                    ],
                                                                    "amount",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "contract.units[index].amount"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      2
                                                    ),
                                                    _vm.contract.units.length ==
                                                    index + 1
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            key: "btn" + index,
                                                            attrs: {
                                                              xs12: "",
                                                              "pb-2": "",
                                                              "text-xs-center":
                                                                ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "submit",
                                                                  round: "",
                                                                  depressed: "",
                                                                  color:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.addUnitType
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Add more"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _vm.contract.contractType == "fee" ||
                                  _vm.contract.feeRateType == "flat"
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "px-3": "",
                                            "py-0": ""
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "mb-2" }, [
                                            _vm._v("Amount")
                                          ]),
                                          _c("v-text-field", {
                                            attrs: {
                                              "single-line": "",
                                              label: "$ Add an amount",
                                              box: "",
                                              "background-color": "#F1F5F7",
                                              flat: "",
                                              autocomplete: "off",
                                              required: "",
                                              rules: _vm.rules.required
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.hasChanges = true
                                              }
                                            },
                                            model: {
                                              value: _vm.contract.amount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "amount",
                                                  $$v
                                                )
                                              },
                                              expression: "contract.amount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "px-3": "",
                                        "py-0": ""
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _vm._v("Bank Account")
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          "single-line": "",
                                          items: _vm.banks,
                                          "item-text": "alias",
                                          "item-value": "id",
                                          box: "",
                                          "background-color": "#F1F5F7",
                                          flat: "",
                                          label: "Select",
                                          "append-icon": "keyboard_arrow_down",
                                          required: "",
                                          rules: _vm.rules.required
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.hasChanges = true
                                          }
                                        },
                                        model: {
                                          value: _vm.contract.bankId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "bankId",
                                              $$v
                                            )
                                          },
                                          expression: "contract.bankId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.contract.contractType == "deposit"
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "px-3": "",
                                            "py-0": ""
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "mb-2" }, [
                                            _vm._v("Refund the deposit after")
                                          ]),
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs6: "", "pr-2": "" }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "single-line": "",
                                                      items: Array.from(
                                                        Array(32).keys()
                                                      ).slice(1, 31),
                                                      box: "",
                                                      "background-color":
                                                        "#F1F5F7",
                                                      flat: "",
                                                      label: "Select",
                                                      "append-icon":
                                                        "keyboard_arrow_down",
                                                      required: "",
                                                      rules: _vm.rules.required
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        _vm.hasChanges = true
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .refundCounter,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "refundCounter",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.refundCounter"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "single-line": "",
                                                      items:
                                                        _vm.refundStepTypes,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      box: "",
                                                      "background-color":
                                                        "#F1F5F7",
                                                      flat: "",
                                                      label: "Select",
                                                      "append-icon":
                                                        "keyboard_arrow_down",
                                                      required: "",
                                                      rules: _vm.rules.required
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        _vm.hasChanges = true
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .refundStepType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "refundStepType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.refundStepType"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "px-3": "",
                                        "py-0": ""
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _vm._v("Deployment Date")
                                      ]),
                                      _c(
                                        "v-dialog",
                                        {
                                          ref: "dialogDeployDate",
                                          attrs: {
                                            "return-value":
                                              _vm.contract.deployDate,
                                            persistent: "",
                                            lazy: "",
                                            "full-width": "",
                                            width: "290px"
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.contract,
                                                "deployDate",
                                                $event
                                              )
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.contract,
                                                "deployDate",
                                                $event
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            "single-line": "",
                                                            label: "Select",
                                                            "background-color":
                                                              "#F1F5F7",
                                                            box: "",
                                                            flat: "",
                                                            readonly: "",
                                                            required: "",
                                                            rules:
                                                              _vm.rules.required
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              _vm.hasChanges = true
                                                            }
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "append",
                                                                fn: function() {
                                                                  return [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        width:
                                                                          "24",
                                                                        height:
                                                                          "24",
                                                                        src: require("@/assets/calendar-grey.svg")
                                                                      }
                                                                    })
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .deployDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "deployDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.deployDate"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2068192650
                                          ),
                                          model: {
                                            value: _vm.modalDeployDate,
                                            callback: function($$v) {
                                              _vm.modalDeployDate = $$v
                                            },
                                            expression: "modalDeployDate"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: { scrollable: "" },
                                              model: {
                                                value: _vm.contract.deployDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "deployDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contract.deployDate"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    flat: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.modalDeployDate = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("cancel"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    flat: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.dialogDeployDate.save(
                                                        _vm.contract.deployDate
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(_vm.$t("ok")))]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pa-3": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            type: "submit",
                                            block: "",
                                            large: "",
                                            depressed: "",
                                            color:
                                              "text-uppercase light-green white--text",
                                            loading: _vm.isSaving
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("save")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.contract.contractType
                        ? [
                            _c("v-flex", { attrs: { xs12: "", "mb-4": "" } }, [
                              _c("h3", [_vm._v("Type of Smart Contract")]),
                              _c("p", [
                                _vm._v(
                                  " Select the type of smart contracts you want to assign to this property. "
                                )
                              ])
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", "mb-4": "" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple",
                                        value: { class: "light-green--text" },
                                        expression:
                                          "{ class: 'light-green--text' }"
                                      }
                                    ],
                                    staticClass: "z-card",
                                    on: {
                                      click: function($event) {
                                        _vm.contract.contractType = "fee"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pl-0" },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass: "transparent",
                                            attrs: { "single-line": "" }
                                          },
                                          [
                                            _c(
                                              "v-list-tile",
                                              { attrs: { avatar: "" } },
                                              [
                                                _c("v-list-tile-avatar", [
                                                  _c("img", {
                                                    staticClass: "ma-4",
                                                    attrs: {
                                                      src: require("@/assets/one-time-fee.svg")
                                                    }
                                                  })
                                                ]),
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass: "title"
                                                        },
                                                        [_vm._v("Move in fees")]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          icon: "",
                                                          ripple: "",
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "white--text"
                                                          },
                                                          [_vm._v("add")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", "mb-4": "" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple",
                                        value: { class: "light-green--text" },
                                        expression:
                                          "{ class: 'light-green--text' }"
                                      }
                                    ],
                                    staticClass: "z-card",
                                    on: {
                                      click: function($event) {
                                        _vm.contract.contractType = "deposit"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pl-0" },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass: "transparent",
                                            attrs: { "single-line": "" }
                                          },
                                          [
                                            _c(
                                              "v-list-tile",
                                              { attrs: { avatar: "" } },
                                              [
                                                _c("v-list-tile-avatar", [
                                                  _c("img", {
                                                    staticClass: "ma-4",
                                                    attrs: {
                                                      src: require("@/assets/recurring-fee.svg")
                                                    }
                                                  })
                                                ]),
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass: "title"
                                                        },
                                                        [_vm._v("Deposits")]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          icon: "",
                                                          ripple: "",
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "white--text"
                                                          },
                                                          [_vm._v("add")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  x12: "",
                                  "text-xs-center": "",
                                  "my-5": ""
                                }
                              },
                              [
                                _c("a", { attrs: { href: "#" } }, [
                                  _vm._v("What is a Smart Contract?")
                                ])
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }