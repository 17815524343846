<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <v-list-tile class="trxTitle px-0 mt-5">
          <v-list-tile-content class="pl-0">
            <v-list-tile-title style="color:rgb(193,193,193);" class="text-uppercase">transactions</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn
              @click="generateCsv"
              :small="isMobile"
              flat
              class="text-capitalize"
              color="info"
            >create report</v-btn>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile class="px-0 tableForm">
          <v-layout class="mx-0" row wrap>
            <v-flex xs12 sm5 class="pa-0">
              <v-text-field
                flat
                solo
                hide-details
                v-model="search"
                label="Description or transaction ID"
              >
                <template v-slot:append>
                  <v-icon size="20">search</v-icon>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm5 class="pa-0">
              <v-text-field
                :value="dateRange"
                flat
                @click="datePickerModal = !datePickerModal"
                readonly
                solo
                hide-details
                label="Date Range"
              >
                <template v-slot:append>
                  <v-icon size="20">today</v-icon>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm2 class="pa-0">
              <v-select
                :items="statusFilter"
                flat
                solo
                v-model="status"
                hide-details
                label="Filter"
              >
                <template v-slot:append>
                  <v-icon size="20">filter_list</v-icon>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
        </v-list-tile>
      </v-flex>
    </v-layout>
    <v-dialog v-model="datePickerModal" max-width="290" content-class="removeRadius">
      <v-card tile>
        <v-date-picker class="elevation-0" multiple v-model="range"></v-date-picker>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="range = []" flat>clear</v-btn>
          <v-btn color="info" @click="datePickerModal = false" flat>done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout pt-5>
      <v-flex xs12>
        <v-data-table
          class="accInfoTable elevation-2"
          :headers="headers"
          disable-initial-sort
          :hide-default-header="isMobile"
          :hide-headers="isMobile"
          :items="filteredItems"
          :custom-sort="customSort"
          :pagination.sync="pagination"
        >
          <template v-slot:items="{ item }">
            <v-list-tile v-if="isMobile" @click="handleClick(item)">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.note }}</v-list-tile-title>
                <v-list-tile-sub-title class="caption">
                  {{
                  item.date
                  }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-content class="text-xs-right">
                <v-list-tile-title class="text-xs-right">
                  <span
                    :class="[item.amount > 0 ? 'success--text' : '']"
                    class="font-weight-bold"
                  ><span v-if="item.amount>0">+</span>{{ item.amount | currency }}</span>
                </v-list-tile-title>
                <v-list-tile-sub-title class="caption">{{ item.status }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider v-if="isMobile" />
            <tr v-if="!isMobile" @click="handleClick(item)">
              <td>{{ item.note }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.fromto }}</td>
              <td>{{ item.status }}</td>
              <td>
                <span
                  :class="[item.amount > 0 ? 'success--text' : '']"
                  class="font-weight-bold body-1"
                ><span v-if="item.amount>0">+</span>{{ item.amount | currency }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

   <v-dialog id="app-details" max-width="400px" content-class="removeRadius" v-model="details">
      <v-card class="grey lighten-5">
        <v-toolbar class="transparent" card>
          <v-toolbar-title class="text-uppercase subheading primary--text">Transaction Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon class="grey--text text--darken-2" @click="details = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
      </v-card>
      <v-list class="removeRadius pb-0">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title class="font-weight-bold">
              {{
              onDialog.note
              }}
            </v-list-tile-title>
            <v-list-tile-sub-title>Description</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{ onDialog.date }}</v-list-tile-title>
            <v-list-tile-sub-title>Date</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{ onDialog.sourceName }}</v-list-tile-title>
            <v-list-tile-sub-title>From</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{ onDialog.destinationName }}</v-list-tile-title>
            <v-list-tile-sub-title>To</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{ onDialog.id }}</v-list-tile-title>
            <v-list-tile-sub-title>Transaction ID</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile @click="details = false" style="background: #8BC53F;">
          <v-list-tile-content>
            <v-list-tile-title
              class="white--text text-uppercase font-weight-bold"
            >{{ onDialog.status }}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-title class="white--text font-weight-bold"><span v-if="onDialog.amount>0">+</span>{{ onDialog.amount | currency }}</v-list-tile-title>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-dialog>    
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      datePickerModal: false,
      details: false,
      onDialog: {},
      search: '',
      range: [],
      status: '',
      pagination: {
        rowsPerPage: 10,
      },
      headers: [
        { text: 'Description', value: 'note' },
        { text: 'Date', value: 'date' },
        { text: 'From/To', value: 'fromto' },
        { text: 'Status', value: 'status' },
        { text: 'Amount', value: 'amount' },
      ],

    }
  },
  watch: {
    range(val) {
      if (val.length > 2) {
        val.pop()
      }
    },
  },
  computed: {
    isMobile: function() {
      return this.$vuetify.breakpoint.smAndDown
    },
    filteredItems() {
      let searched = this.items.filter(item => {
        const note = item.note.toLowerCase()
        const id = item.id.toLowerCase()
        const txt = this.search.toLowerCase()
        return note.includes(txt) || id.includes(txt)
      })

      const range = this.sortDate().obj

      if (range) {
        searched = searched.filter(item => {
          const [m, d, y] = item.date.split('/')
          const date = new Date(y, m - 1, d)
          if (
            date.getTime() > range.from.getTime() &&
            date < range.to.getTime()
          ) {
            this.$log.info({ item: item.date, date, range })
          }

          return (
            date.getTime() > range.from.getTime() && date < range.to.getTime()
          )
        })
      }
      if (!!this.status && this.status !== 'All')
        searched = searched.filter(item => item.status === this.status)
      return searched
    },
    dateRange: function() {
      const range = this.sortDate().str
      if (range) {
        return `${this.formatDate(range.from)} - ${this.formatDate(range.to)}`
      }
      return ''
    },
    statusFilter: function() {
      const set = new Set()
      set.add('All')
      this.items.forEach(item => set.add(item.status))
      return Array.from(set)
    },
  },

  methods: {
    formatDate: function(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    sortDate: function() {
      const [first, second] = this.range
      if (first && second) {
        const d1 = new Date(first)
        const d2 = new Date(second)
        if (d1.getTime() < d2.getTime()) {
          return {
            str: { from: first, to: second },
            obj: { from: d1, to: d2 },
          }
        }
        return {
          str: { from: second, to: first },
          obj: { from: d2, to: d1 },
        }
      }
      return false
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'date') {
          const [m1, d1, y1] = a[index].split('/')
          const [m2, d2, y2] = b[index].split('/')
          const t1 = new Date(y1, m1 - 1, d1)
          const t2 = new Date(y2, m2 - 1, d2)
          if (!isDesc) {
            return t2 - t1
          } else {
            return t1 - t2
          }
        } 
        else if (index==='amount'){
          const t1 = Number(a[index])
          const t2 = Number(b[index])
          if (!isDesc) {
            return t2 - t1
          } else {
            return t1 - t2
          }
        }
        
        else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase())
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },
    handleClick: function(item) {
      this.onDialog = item
      this.details = true
    },

    generateCsv: function() {
      let csvContent = 'data:text/csv;charset=utf-8,'
      const keys = []
      const columns = []
      const model = [
        {
          value: 'id',
          text: 'Transaction ID',
        },
        {
          value: 'note',
          text: 'Description',
        },        
        {
          value: 'date',
          text: 'Date',
        },        
        {
          value: 'sourceName',
          text: 'From',
        },
        {
          value: 'destinationName',
          text: 'To',
        },    
        {
          value: 'amount',
          text: 'Amount',
        },    
        {
          value: 'status',
          text: 'Status',
        },     
        {
          value: 'type',
          text: 'Type',
        },                             
      ]
      model.forEach(item => {
        keys.push(item.value)
        columns.push(item.text)
      })
      csvContent += columns.join('|') + '\r\n'

      this.filteredItems.forEach(item => {
        const row = []
        keys.forEach(key => {
          row.push(item[key])
        })
        csvContent += row.join('|') + '\r\n'
      })
      const uri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('display', 'none')
      link.setAttribute('href', uri)
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
    },
  },
}
</script>


<style lang="scss">
@media (min-width: 601px) {
  .tableForm {
    border: 2px solid #ededed !important;
  }
}
@media (max-width: 600px) {
  .tableForm {
    .v-input__control {
      border: 2px solid #ededed !important;
    }
    .v-input {
      margin: 8px auto;
    }
  }
}
.trxTitle {
  .v-list__tile {
    padding: 0;
  }
}
.tableForm {
  .v-list__tile {
    padding: 0;
  }
  border-left: 0px !important;
  .v-input__control {
    border-radius: 0px !important;
    &:last-child {
      border-left: 2px solid #ededed;
    }
    label {
      font-size: 14px;
    }
  }
}
.removeRadius {
  border-radius: 0px !important;
}
.accInfoTable {
  border-radius: 0px;
  tr {
    cursor: pointer;
  }  
  thead {
    tr {
      height: 70px;
      th {
        margin: 20px auto;
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }
    }
  }
}

@media (max-width: 600px) {
  .accInfoTable {
    padding-top: 60px;
  }
}
</style>
