var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkmark-container" },
    [
      _c("CheckMark", { staticClass: "checkmark" }),
      _c("v-card", { staticClass: "z-card" }, [
        _c("h3", { staticClass: "title indigo--text darken-4 mb-2" }, [
          _vm._v(_vm._s(_vm._f("currency")(_vm.amount)))
        ]),
        _vm.description
          ? _c("div", { staticClass: "subheading mb-5" }, [
              _vm._v(_vm._s(_vm.description))
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "title font-weight-regular grey--text mb-3" },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _c("div", { staticClass: "grey--text" }, [
          _vm._v(_vm._s(_vm.subtitle))
        ]),
        _vm.message
          ? _c("div", { staticClass: "bottom-msg" }, [
              _vm._v(_vm._s(_vm.message))
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }