var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.options.vuetifyVersion === 1
        ? _c(
            "v-list-tile-content",
            [_c("v-list-tile-title", [_vm._v(_vm._s(_vm.title))])],
            1
          )
        : _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v(_vm._s(_vm.title))])],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }