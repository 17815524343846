// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/user.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.z-digital-id {\n  background-color: #fff;\n  min-height: 160px;\n  border-radius: 20px;\n  position: relative;\n  overflow: hidden;\n  margin-bottom: 20px;\n}\n.z-digital-id:before {\n  content: '';\n  position: absolute;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  width: 102px;\n  height: 102px;\n  top: 20px;\n  left: 20px;\n  z-index: 1;\n}\n.z-digital-id__head {\n  background-color: rgba(229, 229, 229, 1);\n  width: 100%;\n  display: block;\n  padding: 10px 20px;\n  min-height: 80px;\n  position: relative;\n}\n.z-digital-id__head > span {\n  color: #fff;\n  font-size: 28px;\n  position: absolute;\n  bottom: 10px;\n  right: 30px;\n  font-style: italic;\n}\n.z-digital-id__body {\n  width: 100%;\n  display: block;\n  padding: 10px 20px;\n}\n", ""]);
// Exports
module.exports = exports;
