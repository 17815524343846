<template>
  <v-app>
    <tev-navbar :title="$t('my_property')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm8>
            <v-flex x12 sm12>
              <v-layout row wrap>
                <v-flex py-3 text-xs-right grey--text>
                  <div>
                    &nbsp;
                    <template v-if="propertyId">{{ $t('property_id') }}: {{ propertyId }}</template>
                  </div>
                  <z-status-chip :status="property.status" :label="$t(property.status)"></z-status-chip>
                </v-flex>
              </v-layout>
              <!-- property_info -->
              <v-flex class="zinc-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>{{ $t('property_info') }}</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body">
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.name }}</div>
                    <div class="zinc-box__label">{{ $t('property_name') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.line1 }}</div>
                    <div class="zinc-box__label">{{ $t('address') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.city }}</div>
                    <div class="zinc-box__label">{{ $t('city') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.state }}</div>
                    <div class="zinc-box__label">{{ $t('state') }}</div>
                  </div>
                  <div class="zinc-box__item">
                    <div class="zinc-box__value">{{ property.address.postalCode }}</div>
                    <div class="zinc-box__label">{{ $t('zip_code') }}</div>
                  </div>
                </v-flex>
              </v-flex>
              <!-- / property_info -->
            </v-flex>

            <v-flex xs12 sm12>
              <!-- active_members -->
              <v-flex class="zinc-box">
                <v-flex xs12 class="zinc-box__title">
                  <h3>Property Manager</h3>
                </v-flex>
                <v-flex xs12 class="zinc-box__body px-2">
                  <v-list single-line class="transparent mb-4">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>Assign Property Manager</v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn small icon ripple color="primary">
                          <v-icon class="white--text">add</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>

                  <!--
                  <v-list two-line class="transparent mb-4">

                    <v-list-tile v-for="item in items" :key="item.title" avatar>
                      <v-list-tile-avatar>
                        <v-icon :class="[item.iconClass]">{{ item.title.substring(0,1) }}</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn small icon ripple color="primary">
                          <v-icon class="white--text">add</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                  -->
                </v-flex>
              </v-flex>
              <!-- / active_members -->
            </v-flex>

            <v-flex xs12 mb-3>
              <v-btn
                :loading="isSending"
                :disabled="isSending"
                color="primary"
                dark
                depressed
                block
                large
                class="text-uppercase"
                @click="inviteMember"
              >Invite New Member</v-btn>
            </v-flex>

            <v-flex xs12 mb-3>
              <v-btn
                :loading="isSending"
                :disabled="isSending"
                color="primary"
                dark
                depressed
                block
                large
                class="text-uppercase"
                @click="transferProperty"
              >Transfer Property</v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  title: 'Property Details',
  data() {
    return {
      error: false,
      propertyId: null,
      managers: [],
      property: {
        name: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
        },
        status: null,
        organizationId: null,
        organizationName: null,
        organizationStatus: null,
      },
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  methods: {
    inviteMember() {},
    transferProperty() {},
    loadProperty() {
      this.$http
        .get('/property/info')
        .then(response => {
          this.property = response.data
        })
        .catch(err => {
          this.$log.error(err)
          //this.$router.push('/manager/properties')
        })
    },
    loadManagers() {
      this.$http
        .get('/manager/properties/' + this.propertyId + '/members')
        .then(response => {
          this.managers = response.data.results
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.$toast(err.response.data.detail, {
              color: 'red',
              timeout: 10000,
            })
          }
        })
    },
  },
  mounted() {
    this.loadProperty()
    //this.loadManagers()
  },
}
</script>
