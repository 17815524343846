var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-organization" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("organization") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-flex",
                { attrs: { x12: "", sm8: "", "offset-sm2": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { x12: "", sm12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "align-start": "",
                                "justify-start": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: {
                                    icon: "",
                                    to: "/representative/organizations"
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "py-3": "",
                                    "text-xs-right": "",
                                    "grey--text": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" "),
                                      _vm.organizationId
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("organization_id")
                                              ) +
                                                ": " +
                                                _vm._s(_vm.organizationId)
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("z-status-chip", {
                                    attrs: {
                                      status: _vm.organization.status,
                                      label: _vm.$t(_vm.organization.status)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "zinc-box" },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "zinc-box__title",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("h3", [
                                    _vm._v(_vm._s(_vm.$t("organization_info")))
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "zinc-box__body",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [_vm._v(_vm._s(_vm.organization.name))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("organization_name"))
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.organization.address.line1)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("address")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.organization.address.city)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("city")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.organization.address.state)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("state")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.organization.address.postalCode
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("zip_code")))]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-3": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                color: "primary",
                                dark: "",
                                depressed: "",
                                block: "",
                                large: "",
                                to: _vm.organizationId + "/properties"
                              }
                            },
                            [_vm._v("Properties")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }