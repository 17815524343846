var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-content",
        { staticClass: "login-background" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "pt-5": "",
                "pb-5": ""
              }
            },
            [
              _c("z-logo", {
                staticClass: "logo-zinc",
                attrs: { color: "#ffffff", slogan: "" }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "px-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto", attrs: { "max-width": "500px" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "form-sm",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.logIn($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "login-container pa-0" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "tab-text-color headline indigo--text text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("signincredentials"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.rules.email,
                                          label: _vm.$t("email"),
                                          type: "text",
                                          autocomplete: "off",
                                          box: "",
                                          "background-color": "#F1F5F7",
                                          flat: "",
                                          autofocus:
                                            _vm.$isDesktop() && !_vm.email
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        ref: "loginPassword",
                                        attrs: {
                                          rules: _vm.rules.password,
                                          label: _vm.$t("password"),
                                          type: "password",
                                          required: "",
                                          box: "",
                                          "background-color": "#F1F5F7",
                                          flat: "",
                                          autofocus:
                                            _vm.$isDesktop() &&
                                            _vm.email != null
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "primary mb-3",
                                          class: {
                                            "blue--text": _vm.isLoading
                                          },
                                          attrs: {
                                            type: "submit",
                                            block: "",
                                            large: "",
                                            depressed: "",
                                            loading: _vm.isLoading,
                                            disabled: _vm.isLoading
                                          },
                                          on: { click: _vm.logIn }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("signin")))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-xs-center mb-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                flat: "",
                                                color: "primary",
                                                to: "/forgot"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("forgotyourpassword")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("z-footer", { attrs: { dark: true } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }