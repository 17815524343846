var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c("p", { staticClass: "mb-4 grey--text" }, [
        _vm._v(
          " Please enter the company controller information. The controller is any natural individual who holds significant responsibilities to control, manage, or direct a company or other corporate entity (i.e. CEO, CFO, General Partner, President, etc). A company may have more than one controller, but only one controller’s information is required. "
        )
      ]),
      _vm.formSchema
        ? _c("SchemaForm", {
            attrs: { schema: _vm.formSchema, model: _vm.formData },
            on: { error: _vm.showError }
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { round: "", depressed: "", color: "primary" },
          on: { click: _vm.submit }
        },
        [_vm._v("Continue")]
      ),
      _c(
        "v-btn",
        {
          attrs: { round: "", flat: "" },
          on: {
            click: function($event) {
              return _vm.$emit("back")
            }
          }
        },
        [_vm._v("Back")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }