<template>
  <v-card flat color="transparent" max-width="600" width="100%">
    <template v-if="!success">
      <v-layout row wrap mt-4>
        <v-flex>
          <div
            class="title black--text font-weight-regular mb-4"
          >Make sure the bank information provided is ok before continue.</div>

          <div class="grey--text body-1">Name:</div>
          <div
            class="mb-2 font-weight-bold black--text"
          >{{ bankSetup.account.name }}</div>

          <div class="grey--text body-1">Account Number:</div>
          <div class="mb-5 font-weight-bold black--text">
            ending in
            {{ bankSetup.account.mask || bankSetup.account.last4 }} ({{
            bankSetup.account.subtype || bankSetup.account.bankAccountType
            }})
          </div>

          <v-flex v-if="property" xs12 mb-3>
            <div class="grey--text body-1 mb-1">Bank Account Alias:</div>
            <v-text-field
              v-model="bankSetup.account.alias"
              single-line
              :autofocus="$isDesktop()"
              label="Bank Account Alias"
              placeholder=""
              required
              box
            ></v-text-field>
          </v-flex>

          <v-card v-if="application" class="z-card mb-4">
            <template v-for="(item, index) in application.payments">
              <v-layout :key="item.id" row ma-3 v-if="contractType == item.contractType || contractType=='all'">
                <v-flex>
                  <div>{{ item.feeName }}</div>
                  <div class="grey--text body-1 mb-2">Description</div>
                </v-flex>
                <v-flex text-xs-right>
                  <div class="indigo--text text--darken-4">
                    {{
                    (item.feeRateType == 'variable'
                    ? variableSetup[item.id].amount
                    : item.amount) | currency
                    }}
                  </div>
                  <div class="grey--text body-1">
                    <template v-if="contractType == 'deposit'">Amount to deposit</template>
                    <template v-else>Amount to pay</template>
                  </div>
                </v-flex>
              </v-layout>
              <v-divider :key="index" v-if="contractType == item.contractType || contractType=='all'"></v-divider>
            </template>
            <!-- Total -->
            <v-layout row ma-3>
              <v-flex>
                <div>
                  <strong>Total</strong>
                </div>
              </v-flex>
              <v-flex text-xs-right>
                <div>
                  <strong>{{ totalAmount | currency }}</strong>
                </div>
              </v-flex>
            </v-layout>
          </v-card>

          <v-card v-if="property" class="z-card mb-4">

            <v-layout row ma-3>
              <v-flex>
                <div>Property Setup Fee</div>
                <div class="grey--text body-1 mb-2">Description</div>
              </v-flex>
              <v-flex text-xs-right>
                <div> </div>
                <div class="grey--text body-1">Amount to pay</div>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <!-- Total -->
            <v-layout row ma-3>
              <v-flex>
                <div>
                  <strong>Total</strong>
                </div>
              </v-flex>
              <v-flex text-xs-right>
                <div>
                  <strong>{{ totalAmount | currency }}</strong>
                </div>
              </v-flex>
            </v-layout>
          </v-card>

          <!--
          <v-card class="z-card mb-4">
            <v-dialog
              ref="dialog"
              v-model="modalWithdrawDate"
              :return-value.sync="form.withdrawDate"
              persistent
              lazy
              full-width
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-list two-line subheader class="pb-0">
                  <v-list-tile avatar v-on="on">
                    <v-list-tile-avatar>
                      <img src="@/assets/ach-withdraw.svg" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ form.withdrawDate | moment('dddd, MM/DD/YYYY') }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title
                        >Withdraw Date</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn icon>
                        <v-icon color="primary">insert_invitation</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </template>
              <v-date-picker
                :min="currentDate"
                v-model="form.withdrawDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn flat color="primary" @click="modalWithdrawDate = false"
                  >Cancel</v-btn
                >
                <v-btn
                  flat
                  color="primary"
                  @click="$refs.dialog.save(form.withdrawDate)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-card>
          -->
        </v-flex>

        <v-flex body-1 mb-4 grey--text text--darken-1>
          <p>
          I hereby authorize Zinc LLC (“Zinc”), its parent, affiliates and
          their respective agents and their assignees to initiate, depending on
          the payment method I select, a single or recurring electronic debit
          entry/entries to my designated checking, savings account or credit
          card (“Account”) at my designated financial institution or credit
          issuer (“Financial Institution”) for which I am an authorized user, as
          well as any Account or Financial Institution I later designate, for
          the single or recurring transactions I authorize. 
          </p>
          <p>
          I acknowledge that
          the origination of electronic debits to my Account must be permitted
          by my Financial Institution, which must be located in the United
          States. 
          </p>
          <p>          
          I understand that my authorization will remain in full force
          and effect until Zinc has received written notification from me at
          least 3 business days prior to my scheduled transfer to terminate this
          authorization. 
          </p>
          <p>
          If I am using a credit card to conduct a transaction,
          an authorization hold will be secured on the credit card provided, to
          cover the deposits or charges and any fees that may be incurred. These
          funds will not be available for your use. Additional authorizations
          (holds) will be obtained if I authorize subsequent transactions using
          a credit card. 
          </p>
          <p>
          We use Dwolla for payment processing and we do not store bank account numbers or credit card information. 
          By checking this box you agree to our
          <a
              href="https://zinc.tech/terms-of-service/"
              target="_blank"
              class="grey--text"
            >Terms of Service</a> 
          and 
          <a
            href="https://zinc.tech/privacy-policy/"
            target="_blank"
            class="grey--text"
          >Privacy Policy</a>
          , as well as our
          partner 
          <a
            href="https://www.dwolla.com/legal/tos/"
            target="_blank"
            class="grey--text"
          >Dwolla'sTerms of service</a>
          and 
          <a
            href="https://www.dwolla.com/legal/privacy/"
            target="_blank"
            class="grey--text"
          >Privacy Policy</a>.
          </p>

        </v-flex>
        <v-flex xs12 mb-4>
          <z-sign ref="sign" @change="signChange"></z-sign>
        </v-flex>
      </v-layout>
      <v-btn
        color="primary"
        dark
        depressed
        block
        large
        class="text-uppercase mb-5"
        @click="makePayment()"
        :loading="isLoading"
        v-bind:class="{'blue--text': isLoading}"
        :disabled="isLoading"        
      >{{ $t('pay') }}</v-btn>
    </template>

    <z-success-pay
      :amount="totalAmount"
      :description="payDescription"
      title="Your payment has been successfully processed."
      subtitle="Soon you will get and email with all the transaction details."
      :message="payMessage"
      v-if="success"
    ></z-success-pay>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import ZSign from '@/components/Sign'
import ZSuccessPay from '@/components/checkout/SuccessPay'
import Alerts from '@/mixin/Alerts'

export default {
  props: {
    achProcessingFee: {
      type: Number,
      default: 0,
    },
    application: Object,
    totalAmount: Number,
    contractType: String,
    variableSetup: Object,
    bankSetup: Object,
    property: Object,    
  },
  data() {
    return {
      success: false,
      isLoading: false,      
      currentDate: null,
      modalWithdrawDate: null,
      form: { withdrawDate: null },
      sign: {
        hasSign: false,
        signerAgree: null,
        signerName: null,
        signature: null,
      },
    }
  },
  mixins: [Alerts],
  components: { 'z-sign': ZSign, 'z-success-pay': ZSuccessPay },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
    payMessage() {
      if (this.property){
        return 'You can now add bank accounts to your property.'
      }
      return ''
    },
    payDescription() {
      const _this = this
      let str = ''
      if (this.application) {
        let arr = []
        let items = this.application.payments.filter(
          item => item.contractType == _this.contractType || _this.contractType == 'all'
        )
        items.forEach(item => {
          arr.push(item.feeName)
        })
        str = arr.length ? arr.join(', ') : ''
      }
      else if (this.property){
        str = 'Property Setup Fee'
      }
      return str
    },
    currentTxsIds() {
      let ids = []
      this.currentTxs.forEach(item => {
        ids.push(item.id)
      })
      return ids
    },
    currentTxs() {
      let items = this.application.payments.filter(
        item => item.contractType == this.contractType || this.contractType == 'all'
      )
      return items
    },
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (!(this.currentUser.isApplicant || this.currentUser.isAccountant)) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
    signChange(data) {
      //this.$log.info(data)
      this.sign = data
    },
    makePayment() {
      if (!this.$refs.sign.validate()) {
        return
      }
      this.isLoading = true

      let url = '/applicant/payments'
      if (this.property){
        url = '/accountant/properties/' + this.property.id + '/payments'
      }


      this.$http
        .post(url, {
          ...this.sign,
          ...{
            paymentMethod: 'ach',
            propertyId: (this.property)?this.property.id:null,
            applicationId: (this.application)?this.application.id:null,
            payments: (this.application)?this.currentTxsIds:null,
            contractType: this.contractType,
            totalAmount: this.totalAmount,
            variableSetup: this.variableSetup,
            bankId: this.bankSetup.bankId,
            bankAlias: this.bankSetup.account.alias
          },
        })
        .then(() => {
          this.success = true
          this.$emit('success')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isLoading = false
        })        
    },
  },
  mounted() {
    this.currentDate = this.form.withdrawDate = new Date()
      .toISOString()
      .substring(0, 10)
  },
}
</script>
