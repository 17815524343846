var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar"),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "px-3 mt-5 mb-4", attrs: { xs12: "", sm8: "" } },
                [
                  _c("div", [_vm._v("Organization")]),
                  _c("h3", { staticClass: "title mb-3 black--text" }, [
                    _vm._v(_vm._s(_vm.organization.name))
                  ])
                ]
              ),
              _c(
                "v-flex",
                { staticClass: "px-3 mt-2 mb-2", attrs: { xs12: "", sm8: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.newSearch($event)
                        }
                      }
                    },
                    [
                      _c("v-container", [
                        _c(
                          "h4",
                          {
                            staticClass: "primary--text font-weight-bold mb-3"
                          },
                          [_vm._v("PROPERTIES")]
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c("v-text-field", {
                              staticClass: "box-border",
                              attrs: {
                                "append-icon": "search",
                                label: _vm.$t("search"),
                                type: "text",
                                autocomplete: "off",
                                box: "",
                                autofocus: _vm.$isDesktop()
                              },
                              on: { "click:append": _vm.newSearch },
                              model: {
                                value: _vm.queryName,
                                callback: function($$v) {
                                  _vm.queryName = $$v
                                },
                                expression: "queryName"
                              }
                            }),
                            _c(
                              "v-chip",
                              {
                                staticClass: "white font-weight-bold",
                                on: {
                                  click: function($event) {
                                    return _vm.filterStatus("all")
                                  }
                                }
                              },
                              [_vm._v("All")]
                            ),
                            _c(
                              "v-chip",
                              {
                                staticClass:
                                  "white font-weight-bold grey--text z-filter-chip",
                                on: {
                                  click: function($event) {
                                    return _vm.filterStatus("pending")
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-avatar",
                                  { staticClass: "orange accent-3" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "white--text" },
                                      [_vm._v("chevron_right")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v("Pending ")
                              ],
                              1
                            ),
                            _c(
                              "v-chip",
                              {
                                staticClass:
                                  "white font-weight-bold grey--text z-filter-chip",
                                on: {
                                  click: function($event) {
                                    return _vm.filterStatus("waiting")
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-avatar",
                                  { staticClass: "primary" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "white--text" },
                                      [_vm._v("autorenew")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v("Waiting ")
                              ],
                              1
                            ),
                            _c(
                              "v-chip",
                              {
                                staticClass:
                                  "white font-weight-bold grey--text z-filter-chip",
                                on: {
                                  click: function($event) {
                                    return _vm.filterStatus("active")
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-avatar",
                                  { staticClass: "light-green" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "white--text" },
                                      [_vm._v("expand_less")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v("Active ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "mb-5 text-xs-center",
                  attrs: { xs12: "", sm8: "" }
                },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5 mb-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading && _vm.totalResults
                    ? _c(
                        "v-list",
                        {
                          staticClass: "transparent mb-4",
                          attrs: { "two-line": "" }
                        },
                        [
                          _c("v-divider"),
                          _vm._l(_vm.properties, function(item) {
                            return [
                              _c(
                                "v-list-tile",
                                {
                                  key: item.id,
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/representative/properties/" + item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticClass: "heading" },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v(
                                          _vm._s(_vm.$t("address")) +
                                            ": " +
                                            _vm._s(
                                              item.address.line1 || "null"
                                            ) +
                                            ", " +
                                            _vm._s(item.address.city) +
                                            " " +
                                            _vm._s(item.address.postalCode) +
                                            " " +
                                            _vm._s(item.address.state)
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-tile-action", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tx-status",
                                        class: _vm.statusClass(item.status)
                                      },
                                      [_vm._v(_vm._s(item.status))]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("v-divider", { key: item.id + "_divider" })
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.totalResults && !_vm.isLoading
                    ? [
                        _c("h3", { staticClass: "mt-2 mb-5 title" }, [
                          _vm._v("No matching properties found.")
                        ])
                      ]
                    : _vm._e(),
                  _vm.totalResults && !_vm.isLoading
                    ? [
                        _c("div", { staticClass: "mt-3 mb-2" }, [
                          _vm._v(
                            "Results " +
                              _vm._s(
                                _vm.currentPage * _vm.perPage - _vm.perPage + 1
                              ) +
                              " - " +
                              _vm._s(
                                Math.min(
                                  _vm.currentPage * _vm.perPage,
                                  _vm.totalResults
                                )
                              ) +
                              " of " +
                              _vm._s(_vm.totalResults) +
                              " properties"
                          )
                        ]),
                        _vm.totalPages > 1
                          ? _c("v-pagination", {
                              staticClass: "mb-4",
                              attrs: {
                                length: _vm.totalPages,
                                "total-visible": 6,
                                circle: ""
                              },
                              on: { input: _vm.changePage },
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            color: "light-green",
                            dark: "",
                            depressed: "",
                            block: "",
                            large: "",
                            to:
                              "/representative/organizations/" +
                              this.organizationId +
                              "/properties/import"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("import_properties")))]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }