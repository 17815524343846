<template>
  <div class="checkmark-container">
    <CheckMark class="checkmark"></CheckMark>
    <v-card class="z-card">
      <h3 class="title indigo--text darken-4 mb-2">{{ amount | currency }}</h3>
      <div class="subheading mb-5" v-if="description">{{ description }}</div>
      <div class="title font-weight-regular grey--text mb-3">{{ title }}</div>
      <div class="grey--text">{{ subtitle }}</div>

      <div class="bottom-msg" v-if="message">{{ message }}</div>      
    </v-card>
  </div>
</template>
<script>
import CheckMark from '@/components/CheckMark'
export default {
  props: {
    amount: Number,
    description: String,
    title: String,
    subtitle: String,
    message: String,
  },
  data() {
    return {}
  },
  components: {
    CheckMark,
  },
}
</script>
<style scoped>
.checkmark-container {
  padding: 0;
  padding-top: 30px;
  position: relative;
  text-align: center;
}
.checkmark {
  left: 50%;
  position: absolute;
  top: 0;
  z-index: 100;
  margin-left: -28px;
}
.z-card {
  padding: 60px 30px;
}

.subheading {
  font-size: 20px!important;
  font-weight: 600;
}

.bottom-msg {
  font-size: 20px!important;
  font-weight: 600;
  padding-top: 60px;
  color: #2B388F;
}
</style>
