var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("my_property") } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "", md6: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { x12: "", sm12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "align-start": "",
                                "justify-start": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.back()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "py-3": "",
                                    "text-xs-right": "",
                                    "grey--text": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(" "),
                                      _vm.propertyId
                                        ? [
                                            _vm._v(
                                              _vm._s(_vm.$t("property_id")) +
                                                ": " +
                                                _vm._s(
                                                  _vm.property.tevPropertyId
                                                )
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c("z-status-chip", {
                                    attrs: {
                                      status: _vm.property.status,
                                      label: _vm.$t(_vm.property.status)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "zinc-box" },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "zinc-box__title",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("h3", [
                                    _vm._v(_vm._s(_vm.$t("property_info")))
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "zinc-box__body",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [_vm._v(_vm._s(_vm.property.name))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("property_name")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.property.address.line1) +
                                            " " +
                                            _vm._s(
                                              _vm.property.address.line1
                                                ? ", " +
                                                    _vm.property.address.line1
                                                : ""
                                            )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("address")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.property.address.city)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("city")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.property.address.state)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("state")))]
                                    )
                                  ]),
                                  _c("div", { staticClass: "zinc-box__item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__value" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.property.address.postalCode
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "zinc-box__label" },
                                      [_vm._v(_vm._s(_vm.$t("zip_code")))]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [_c("h3", [_vm._v("Accounting Admin")])]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body px-2",
                              attrs: { xs12: "" }
                            },
                            [
                              !_vm.accountants.length
                                ? _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent mb-4",
                                      attrs: { "single-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-tile",
                                        { on: { click: _vm.assignAccountant } },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  "Assign Accounting Admin"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    ripple: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [_vm._v("add")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.accountants.length
                                ? _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent mb-4",
                                      attrs: { "two-line": "" }
                                    },
                                    _vm._l(_vm.accountants, function(item) {
                                      return _c(
                                        "v-list-tile",
                                        {
                                          key: item.title,
                                          attrs: {
                                            avatar: "",
                                            to:
                                              "/representative/members/" +
                                              item.id
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-avatar",
                                            [
                                              _c("Lettericon", {
                                                attrs: {
                                                  name: item.fullName,
                                                  size: "2"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(_vm._s(item.fullName))
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    ripple: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [_vm._v("chevron_right")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "zinc-box" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__title",
                              attrs: { xs12: "" }
                            },
                            [_c("h3", [_vm._v("Property Manager")])]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "zinc-box__body px-2",
                              attrs: { xs12: "" }
                            },
                            [
                              !_vm.managers.length
                                ? _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent mb-4",
                                      attrs: { "single-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-tile",
                                        { on: { click: _vm.assignManager } },
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  "Assign Property Manager"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    ripple: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [_vm._v("add")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.managers.length
                                ? _c(
                                    "v-list",
                                    {
                                      staticClass: "transparent mb-4",
                                      attrs: { "two-line": "" }
                                    },
                                    _vm._l(_vm.managers, function(item) {
                                      return _c(
                                        "v-list-tile",
                                        {
                                          key: item.title,
                                          attrs: {
                                            avatar: "",
                                            to:
                                              "/representative/members/" +
                                              item.id
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-tile-avatar",
                                            [
                                              _c("Lettericon", {
                                                attrs: {
                                                  name: item.fullName,
                                                  size: "2"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(_vm._s(item.fullName))
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-tile-action",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: "",
                                                    ripple: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [_vm._v("chevron_right")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm12: "",
                            "pb-3": "",
                            "text-xs-center": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-uppercase mb-3 white--text grey darken-3",
                              attrs: {
                                to:
                                  "/representative/properties/" +
                                  _vm.propertyId +
                                  "/activity",
                                block: "",
                                depressed: "",
                                large: ""
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("property_activity")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }