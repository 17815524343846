var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: { class: "primary--text" },
          expression: "{class: 'primary--text'}"
        }
      ],
      staticClass: "z-card pa-3 text-xs-left mb-3",
      attrs: { to: _vm.to }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "mb-2": "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.property.name))
            ]),
            _c("div", { staticClass: "grey--text body-1" }, [
              _vm._v("Property")
            ])
          ]),
          _c("v-flex")
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c("div", [
              _vm._v(
                _vm._s(_vm.property.moveInAddress) +
                  " - Unit " +
                  _vm._s(_vm.property.unitNumber)
              )
            ]),
            _c("div", { staticClass: "grey--text body-1" }, [_vm._v("Address")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c(
                "v-icon",
                {
                  staticStyle: { margin: "10px 2px", float: "right" },
                  attrs: { color: "grey" }
                },
                [_vm._v("chevron_right")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }