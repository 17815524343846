<template>
  <div :style="'width:'+width+'px'"></div>
</template>

<script>
export default {
  props: {
    width: Number
  },
  data () {
    return {

    }
  }
}
</script>
<style>
</style>