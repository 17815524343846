var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 158.9 76.2" },
      attrs: {
        version: "1.1",
        x: "0px",
        y: "0px",
        viewBox: "0 0 158.9 76.2",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _vm.slogan
          ? _c("g", [
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M5.3,74.3c0-0.4-0.2-0.7-0.5-0.9S4,72.9,3.4,72.8c-0.6-0.1-1.1-0.3-1.5-0.5c-0.3-0.2-0.6-0.4-0.8-0.6\n\t\t\ts-0.3-0.6-0.3-0.9c0-0.6,0.2-1,0.7-1.4c0.5-0.4,1.1-0.5,1.8-0.5c0.8,0,1.4,0.2,1.9,0.6C5.8,69.8,6,70.3,6,70.9H5.3\n\t\t\tc0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.8-0.4-1.3-0.4c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.4,0.1,0.6,0.4,0.8\n\t\t\tc0.3,0.2,0.7,0.4,1.4,0.5c0.7,0.2,1.2,0.3,1.6,0.5c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.3,0.9c0,0.6-0.2,1.1-0.7,1.4\n\t\t\tc-0.5,0.4-1.1,0.5-1.9,0.5c-0.8,0-1.5-0.2-2-0.6s-0.8-0.9-0.8-1.5h0.8c0,0.5,0.2,0.8,0.6,1.1c0.4,0.3,0.8,0.4,1.4,0.4\n\t\t\tc0.5,0,1-0.1,1.3-0.4S5.3,74.6,5.3,74.3z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M14,68.9l0,1.2c0.3-0.4,0.6-0.8,1-1c0.4-0.2,0.8-0.3,1.3-0.3c1.1,0,1.8,0.5,2.1,1.4c0.3-0.4,0.6-0.8,1-1\n\t\t\ts0.9-0.4,1.4-0.4c1.5,0,2.3,0.8,2.3,2.5v4.8h-0.8v-4.7c0-0.6-0.1-1.1-0.4-1.4c-0.3-0.3-0.7-0.5-1.3-0.5c-0.6,0-1,0.2-1.4,0.5\n\t\t\tc-0.4,0.4-0.6,0.8-0.7,1.3v4.7h-0.8v-4.8c0-0.6-0.1-1.1-0.4-1.4c-0.3-0.3-0.7-0.5-1.3-0.5c-0.5,0-0.9,0.1-1.3,0.4\n\t\t\tc-0.4,0.3-0.6,0.7-0.8,1.3v4.9h-0.8v-7.1H14z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M35.1,76.1c-0.1-0.2-0.1-0.6-0.2-1c-0.3,0.4-0.6,0.6-1.1,0.8s-0.9,0.3-1.4,0.3c-0.7,0-1.2-0.2-1.7-0.6\n\t\t\tc-0.4-0.4-0.6-0.9-0.6-1.4c0-0.7,0.3-1.2,0.9-1.6c0.6-0.4,1.4-0.6,2.4-0.6h1.4v-0.8c0-0.5-0.2-0.9-0.5-1.2\n\t\t\tc-0.3-0.3-0.8-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.3-0.5,0.6-0.5,1l-0.8,0c0-0.6,0.3-1.1,0.8-1.5c0.5-0.4,1.2-0.6,1.9-0.6\n\t\t\tc0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,1,0.7,1.7v3.4c0,0.7,0.1,1.2,0.2,1.5v0.1H35.1z M32.6,75.5c0.5,0,1-0.1,1.4-0.4\n\t\t\tc0.4-0.3,0.7-0.6,0.9-1v-1.6h-1.4c-0.8,0-1.4,0.2-1.8,0.4c-0.4,0.3-0.7,0.7-0.7,1.1c0,0.4,0.1,0.7,0.4,1\n\t\t\tC31.8,75.4,32.1,75.5,32.6,75.5z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M46.5,69.6c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.6,0.7-0.7,1.2v4.9h-0.8v-7.1H44l0,1.1\n\t\t\tc0.4-0.8,1.1-1.3,2-1.3c0.2,0,0.4,0,0.5,0.1L46.5,69.6z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M54.7,67.1v1.8h1.5v0.6h-1.5v4.8c0,0.4,0.1,0.7,0.2,0.9s0.4,0.3,0.7,0.3c0.1,0,0.3,0,0.6-0.1l0,0.6\n\t\t\tc-0.2,0.1-0.5,0.1-0.8,0.1c-0.5,0-0.9-0.2-1.2-0.5c-0.2-0.3-0.4-0.8-0.4-1.4v-4.8h-1.3v-0.6h1.3v-1.8H54.7z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M71.8,71.1c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3c0.4,0,0.7,0.1,0.9,0.3s0.3,0.5,0.3,0.8v0.3\n\t\t\tc0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.9,0.3c-0.4,0-0.7-0.1-0.9-0.3s-0.3-0.5-0.3-0.9V71.1z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M94.5,74.3c0-0.4-0.2-0.7-0.5-0.9s-0.8-0.4-1.4-0.6c-0.6-0.1-1.1-0.3-1.5-0.5c-0.3-0.2-0.6-0.4-0.8-0.6\n\t\t\ts-0.3-0.6-0.3-0.9c0-0.6,0.2-1,0.7-1.4c0.5-0.4,1.1-0.5,1.8-0.5c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.5h-0.8\n\t\t\tc0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.8-0.4-1.3-0.4c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.4,0.1,0.6,0.4,0.8\n\t\t\tc0.3,0.2,0.7,0.4,1.4,0.5c0.7,0.2,1.2,0.3,1.6,0.5c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.3,0.9c0,0.6-0.2,1.1-0.7,1.4\n\t\t\tc-0.5,0.4-1.1,0.5-1.9,0.5c-0.8,0-1.5-0.2-2-0.6s-0.8-0.9-0.8-1.5h0.8c0,0.5,0.2,0.8,0.6,1.1c0.4,0.3,0.8,0.4,1.4,0.4\n\t\t\tc0.5,0,1-0.1,1.3-0.4S94.5,74.6,94.5,74.3z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M105.3,76.2c-0.6,0-1.2-0.1-1.6-0.4c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1.1-0.4-1.8v-0.3\n\t\t\tc0-0.7,0.1-1.3,0.4-1.9c0.3-0.5,0.6-1,1.1-1.3c0.5-0.3,1-0.5,1.5-0.5c0.9,0,1.6,0.3,2.1,0.9c0.5,0.6,0.8,1.4,0.8,2.4v0.4h-5.1v0.2\n\t\t\tc0,0.8,0.2,1.5,0.7,2c0.5,0.5,1,0.8,1.7,0.8c0.4,0,0.8-0.1,1.1-0.2c0.3-0.2,0.6-0.4,0.9-0.7l0.5,0.4\n\t\t\tC107.2,75.8,106.4,76.2,105.3,76.2z M105.1,69.5c-0.6,0-1.1,0.2-1.5,0.7c-0.4,0.4-0.7,1-0.7,1.8h4.3v-0.1c0-0.7-0.2-1.2-0.6-1.7\n\t\t\tC106.2,69.7,105.7,69.5,105.1,69.5z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M117.5,75.5c0.5,0,1-0.2,1.4-0.5c0.4-0.3,0.6-0.7,0.6-1.2h0.8c0,0.4-0.2,0.8-0.4,1.2s-0.6,0.6-1,0.8\n\t\t\tc-0.4,0.2-0.9,0.3-1.3,0.3c-0.9,0-1.7-0.3-2.2-1s-0.8-1.5-0.8-2.6v-0.2c0-0.7,0.1-1.3,0.4-1.9s0.6-1,1.1-1.3\n\t\t\tc0.5-0.3,1-0.4,1.6-0.4c0.8,0,1.4,0.2,1.9,0.7c0.5,0.5,0.8,1.1,0.8,1.8h-0.8c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-0.8-0.5-1.4-0.5\n\t\t\tc-0.7,0-1.3,0.3-1.7,0.8s-0.6,1.2-0.6,2.2v0.2c0,0.9,0.2,1.6,0.6,2.1C116.2,75.3,116.8,75.5,117.5,75.5z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M131.8,75.2c-0.5,0.7-1.2,1-2.3,1c-0.8,0-1.3-0.2-1.7-0.7c-0.4-0.4-0.6-1.1-0.6-2v-4.6h0.8v4.5\n\t\t\tc0,1.4,0.5,2,1.6,2c1.1,0,1.9-0.5,2.2-1.4v-5.2h0.8v7.1h-0.8L131.8,75.2z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M143.3,69.6c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.6,0.7-0.7,1.2v4.9H140v-7.1h0.8l0,1.1\n\t\t\tc0.4-0.8,1.1-1.3,2-1.3c0.2,0,0.4,0,0.5,0.1L143.3,69.6z"
                }
              }),
              _c("path", {
                attrs: {
                  fill: _vm.sloganColor ? _vm.sloganColor : _vm.color,
                  d:
                    "M152.6,76.2c-0.6,0-1.2-0.1-1.6-0.4c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1.1-0.4-1.8v-0.3\n\t\t\tc0-0.7,0.1-1.3,0.4-1.9c0.3-0.5,0.6-1,1.1-1.3c0.5-0.3,1-0.5,1.5-0.5c0.9,0,1.6,0.3,2.1,0.9c0.5,0.6,0.8,1.4,0.8,2.4v0.4h-5.1v0.2\n\t\t\tc0,0.8,0.2,1.5,0.7,2c0.5,0.5,1,0.8,1.7,0.8c0.4,0,0.8-0.1,1.1-0.2c0.3-0.2,0.6-0.4,0.9-0.7l0.5,0.4\n\t\t\tC154.5,75.8,153.7,76.2,152.6,76.2z M152.4,69.5c-0.6,0-1.1,0.2-1.5,0.7c-0.4,0.4-0.7,1-0.7,1.8h4.3v-0.1c0-0.7-0.2-1.2-0.6-1.7\n\t\t\tC153.5,69.7,153,69.5,152.4,69.5z"
                }
              })
            ])
          : _vm._e(),
        _vm.logo
          ? _c("g", [
              _c("g", [
                _c("rect", {
                  staticClass: "st0",
                  attrs: {
                    fill: _vm.color,
                    x: "49.5",
                    y: "11.8",
                    width: "12.3",
                    height: "45.9"
                  }
                })
              ]),
              _c("g", [
                _c("rect", {
                  staticClass: "st0",
                  attrs: {
                    fill: _vm.color,
                    x: "49.5",
                    width: "12.3",
                    height: "8.2"
                  }
                })
              ]),
              _c("g", [
                _c("path", {
                  attrs: {
                    fill: _vm.color,
                    d:
                      "M83.2,11.8l0.4,5.3c3.3-4.1,7.7-6.2,13.2-6.2c4.9,0,8.5,1.4,10.9,4.3c2.4,2.9,3.6,7.1,3.6,12.8v29.6H99V28.3\n\t\t\tc0-2.6-0.6-4.5-1.7-5.7c-1.1-1.2-3-1.8-5.6-1.8c-3.5,0-6,1.5-7.8,4.4v32.4H71.7V11.8H83.2z"
                  }
                })
              ]),
              _c("g", [
                _c("path", {
                  attrs: {
                    fill: _vm.color,
                    d:
                      "M139.7,48.7c2.3,0,4.1-0.6,5.5-1.9c1.4-1.2,2.1-2.9,2.2-5h11.5c0,3.1-0.9,6-2.5,8.5c-1.7,2.6-4,4.6-6.8,6\n\t\t\tc-2.9,1.4-6.1,2.1-9.6,2.1c-6.6,0-11.7-2.1-15.5-6.3c-3.8-4.2-5.7-9.9-5.7-17.3v-0.8c0-7.1,1.9-12.7,5.6-16.9\n\t\t\tc3.8-4.2,8.9-6.3,15.5-6.3c5.7,0,10.3,1.6,13.8,4.9c3.5,3.3,5.2,7.6,5.3,13h-11.5c-0.1-2.4-0.8-4.3-2.2-5.8\n\t\t\tc-1.4-1.5-3.3-2.2-5.6-2.2c-2.9,0-5,1-6.5,3.1c-1.5,2.1-2.2,5.5-2.2,10.1v1.3c0,4.7,0.7,8.1,2.2,10.2\n\t\t\tC134.5,47.6,136.7,48.7,139.7,48.7z"
                  }
                })
              ])
            ])
          : _vm._e(),
        _vm.icon || _vm.logo
          ? _c("g", [
              _c("g", [
                _c("polygon", {
                  attrs: {
                    fill: _vm.iconColor ? _vm.iconColor : _vm.color,
                    points: "27.3,11.7 2.2,11.7 2.2,22.5 18.7,22.5 \t\t\t"
                  }
                })
              ]),
              _c("g", [
                _c("polygon", {
                  attrs: {
                    fill: _vm.iconColor ? _vm.iconColor : _vm.color,
                    points: "13.9,57.7 40,57.7 40,46.9 22.5,46.9 \t\t\t"
                  }
                })
              ]),
              _c("g", [
                _c("polygon", {
                  attrs: {
                    fill: _vm.iconColor ? _vm.iconColor : _vm.color,
                    points:
                      "0,50 0,57.7 10.3,57.7 40.4,20.4 40.4,11.7 30.9,11.7 \t\t\t"
                  }
                })
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }