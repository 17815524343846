var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("reports") } }),
      _c(
        "v-content",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "mt-4": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label:
                                _vm.$t("property_name") +
                                " / " +
                                _vm.$t("applicant_name"),
                              type: "text",
                              box: "",
                              autofocus: _vm.$isDesktop()
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: _vm.$t("from_date"),
                                              readonly: "",
                                              box: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function() {
                                                    return [
                                                      _c("img", {
                                                        attrs: {
                                                          width: "16",
                                                          height: "16",
                                                          src: require("../../assets/calendar.svg"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.startDate,
                                              callback: function($$v) {
                                                _vm.startDate = $$v
                                              },
                                              expression: "startDate"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.startDateMenu,
                                callback: function($$v) {
                                  _vm.startDateMenu = $$v
                                },
                                expression: "startDateMenu"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "", color: "light-green" },
                                on: {
                                  input: function($event) {
                                    _vm.startDateMenu = false
                                  }
                                },
                                model: {
                                  value: _vm.startDate,
                                  callback: function($$v) {
                                    _vm.startDate = $$v
                                  },
                                  expression: "startDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: _vm.$t("to_date"),
                                              readonly: "",
                                              box: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function() {
                                                    return [
                                                      _c("img", {
                                                        attrs: {
                                                          width: "16",
                                                          height: "16",
                                                          src: require("../../assets/calendar.svg"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.endDate,
                                              callback: function($$v) {
                                                _vm.endDate = $$v
                                              },
                                              expression: "endDate"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.endDateMenu,
                                callback: function($$v) {
                                  _vm.endDateMenu = $$v
                                },
                                expression: "endDateMenu"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "", color: "light-green" },
                                on: {
                                  input: function($event) {
                                    _vm.endDateMenu = false
                                  }
                                },
                                model: {
                                  value: _vm.endDate,
                                  callback: function($$v) {
                                    _vm.endDate = $$v
                                  },
                                  expression: "endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("property_id"),
                              type: "text",
                              box: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.statusTypes,
                              "append-icon": "expand_more",
                              label: _vm.$t("status"),
                              box: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.transactionTypes,
                              "append-icon": "expand_more",
                              label: _vm.$t("transaction_type"),
                              box: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        color: "light-green",
                        dark: "",
                        depressed: "",
                        block: "",
                        large: ""
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("generate_reports")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }