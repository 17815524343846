var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { title: "Application Detail" } }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "transparent elevation-0",
                      attrs: { width: "800", "max-width": "100%" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "arrow-left",
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-start": "",
                            "justify-start": ""
                          }
                        },
                        [
                          _vm.application
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "light-green--text",
                                  attrs: {
                                    icon: "",
                                    "active-class": "none",
                                    to:
                                      "/applicant/properties/" +
                                      _vm.application.propertyId
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "text-xs-center pa-5 mb-5" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.application
                        ? _c(
                            "v-card-text",
                            { attrs: { id: "card-details" } },
                            [
                              _c("z-application-detail", {
                                ref: "appDetail",
                                attrs: {
                                  application: _vm.application,
                                  propertyId: _vm.application.propertyId
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }