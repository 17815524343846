<template>
  <v-app class="page-myprofile page-with-subnavbar">
    <tev-navbar :title="$t('my_profile')" />
    <tev-subnavbar :title="$t('my_digital_id')" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-flex x12 sm6>
            <z-digital-id-card />
            <!--v-layout xs12 align-start justify-space-between row fill-height>
            <h4>Personal Information</h4>
            <v-btn outline icon large color="primary">
              <v-avatar :size="16">
                <img src="../../assets/edit.svg" />
              </v-avatar>
            </v-btn>
            </v-layout-->
            <v-flex xs12 sm12 mb-4>
              <v-list two-line class="transparent">
                <v-list-tile avatar>
                  <v-list-tile-avatar size="16">
                    <img src="../../assets/ico-user.svg" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Michelle Hendley</v-list-tile-title>
                    <v-list-tile-sub-title>Name</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar size="16">
                    <img src="../../assets/ico-phone.svg" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>305.000.0000</v-list-tile-title>
                    <v-list-tile-sub-title>Phone number</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar size="16">
                    <img src="../../assets/ico-email.svg" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>michelle@mail.com</v-list-tile-title>
                    <v-list-tile-sub-title>{{ $t('email') }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
                <v-list-tile avatar>
                  <v-list-tile-avatar size="16">
                    <img src="../../assets/ico-org.svg" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Tenant Evaluation</v-list-tile-title>
                    <v-list-tile-sub-title>{{ $t('organization') }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
              </v-list>
            </v-flex>
            <v-flex xs12 sm12>
              <v-btn
                color="light-green"
                dark
                depressed
                block
                large
                class="text-uppercase"
              >{{ $t('share_id') }}</v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import DigitalIdCard from '../../components/DigitalIdCard'

export default {
  data() {
    return {}
  },
  components: {
    'z-digital-id-card': DigitalIdCard,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  created() {
    this.checkCurrentLogin()
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      if (!this.currentUser || !localStorage.token) {
        this.$router.push('/login?redirect=' + this.$route.path)
      }
    },
  },
  mounted() {},
}
</script>
<style>
.page-myprofile .v-list__tile__avatar {
  min-width: 44px;
}

.page-myprofile .v-avatar img {
  border-radius: 0;
}
</style>