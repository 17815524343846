var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-members" },
    [
      _c("tev-navbar", { attrs: { title: "Email Templates" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-3": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                color: "light-green",
                                dark: "",
                                depressed: "",
                                large: "",
                                to: "/admin/email-templates/new"
                              }
                            },
                            [_vm._v("New Email Template")]
                          )
                        ],
                        1
                      ),
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "text-xs-center pa-5 mb-5" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? _c(
                            "v-list",
                            {
                              staticClass: "transparent mb-4",
                              attrs: { "two-line": "", subheader: "" }
                            },
                            [
                              _c("v-divider"),
                              _vm._l(_vm.emailTemplates, function(item) {
                                return _c(
                                  "v-list-tile",
                                  {
                                    key: item.title,
                                    attrs: {
                                      to: "/admin/email-templates/" + item.id
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-tile-action",
                                      [
                                        _c("v-chip", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("uppercase")(item.lang)
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(_vm._s(item.name))
                                        ]),
                                        _c("v-list-tile-sub-title")
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-action",
                                      [
                                        _c("v-chip", [
                                          _vm._v(_vm._s(item.slug))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-action",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              icon: "",
                                              ripple: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "grey--text" },
                                              [_vm._v("chevron_right")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "text-xs-center mb-5" },
                        [
                          _vm.totalResults
                            ? _c("div", [
                                _vm._v(
                                  "Results " +
                                    _vm._s(
                                      _vm.currentPage * _vm.perPage -
                                        _vm.perPage +
                                        1
                                    ) +
                                    " - " +
                                    _vm._s(
                                      Math.min(
                                        _vm.currentPage * _vm.perPage,
                                        _vm.totalResults
                                      )
                                    ) +
                                    " of " +
                                    _vm._s(_vm.totalResults)
                                )
                              ])
                            : _vm._e(),
                          _vm.totalPages > 1
                            ? _c("v-pagination", {
                                attrs: {
                                  length: _vm.totalPages,
                                  "total-visible": 6,
                                  circle: ""
                                },
                                on: { input: _vm.changePage },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-fab-transition",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "light-green",
                    dark: "",
                    fixed: "",
                    bottom: "",
                    right: "",
                    depressed: "",
                    fab: "",
                    to: "/admin/email-templates/new"
                  }
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }