<template>
  <v-app class="page-member">
    <tev-navbar :title="$t('members')" />
    <v-content>
      <v-container>
        <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>
        <template v-if="!isLoading">
          <v-layout row wrap>
            <v-flex xs12 sm6 mb-4>
              <v-layout row wrap>
                <v-flex xs12 sm12 mb-4>
                  <v-layout align-start justify-space-between row fill-height>
                    <v-btn icon @click="$router.go(-1)" class="light-green--text">
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-layout xs12 align-center justify-center row wrap fill-height pt-4>
                      <v-btn outline icon large color="primary">
                        <v-avatar :size="16">
                          <img src="../../assets/edit.svg" />
                        </v-avatar>
                      </v-btn>
                      <Lettericon :name="member.fullName" size="medium" class="ma-3"></Lettericon>
                      <v-btn outline icon large color="primary">
                        <v-avatar :size="16">
                          <img src="../../assets/delete.svg" />
                        </v-avatar>
                      </v-btn>
                    </v-layout>
                    <z-spacer :width="52" />
                  </v-layout>
                </v-flex>
                <v-layout xs12 align-center justify-center text-xs-center row wrap mb-4>
                  <v-flex xs12 title font-weight-regular>{{ member.fullName }}</v-flex>
                  <v-flex xs12 body-1></v-flex>
                </v-layout>
                <v-flex xs12 sm12 mx-4>
                  <v-list two-line class="transparent">
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-user.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ member.fullName }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('name') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-phone.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ member.cellPhone }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('phone') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-email.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ member.email }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('email') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile avatar>
                      <v-list-tile-avatar size="16">
                        <img src="../../assets/ico-org.svg" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ member.organization }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ $t('organization') }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </template>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Lettericon from '../../components/Lettericon'

export default {
  title: 'Member Details',
  data() {
    return {
      isLoading: false,
      memberId: null,
      member: {
        fullName: null,
        cellPhone: null,
        email: null,
        organization: null,
      },
    }
  },
  components: {
    Lettericon,
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
    }),
  },
  methods: {
    loadMember() {
      this.isLoading = true
      this.$http
        .get('/property/members/' + this.memberId)
        .then(response => {
          this.member = response.data
        })
        .catch(err => {
          this.$log.error(err)
          if (
            err &&
            'response' in err &&
            'data' in err.response &&
            'detail' in err.response.data
          ) {
            this.$toast(err.response.data.detail, {
              color: 'red',
              showClose: true,
              timeout: 10000,
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.memberId = this.$route.params.memberId
    this.loadMember()
  },
}
</script>
<style>
.page-member .v-list__tile__avatar {
  min-width: 44px;
}
.page-member .v-avatar img {
  border-radius: 0;
}
</style>
