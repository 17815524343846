var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-property-activity" },
    [
      _c("tev-navbar", { attrs: { title: _vm.$t("property_activity") } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "pt-4 mb-4",
                  attrs: { xs12: "", sm12: "", "px-4": "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-start": "",
                        "justify-start": ""
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "light-green--text mr-3",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("arrow_back")])],
                        1
                      ),
                      _c("v-flex", [
                        _c("div", { staticClass: "body-1 grey--text" }, [
                          _vm._v(_vm._s(_vm.$t("property")))
                        ]),
                        _c(
                          "div",
                          { staticClass: "title font-weight-regular" },
                          [_vm._v(_vm._s(_vm.propertyName))]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.totalResults && !_vm.isLoading
                ? _c(
                    "v-flex",
                    {
                      staticClass: "my-5 text-xs-center",
                      attrs: { xs12: "", sm6: "" }
                    },
                    [
                      _c("h3", { staticClass: "mt-2 mb-5 title" }, [
                        _vm._v(_vm._s(_vm.$t("no_activity_explain")))
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _vm.totalResults
                    ? [
                        _c("v-divider"),
                        _c(
                          "v-list",
                          {
                            staticClass: "activity-list py-0 transparent mx-3",
                            attrs: { "three-line": "" }
                          },
                          [
                            _vm._l(_vm.propertyActivity, function(
                              activity,
                              index
                            ) {
                              return [
                                _c(
                                  "v-list-tile",
                                  { key: activity.id, attrs: { avatar: "" } },
                                  [
                                    _c(
                                      "v-list-tile-avatar",
                                      [
                                        _c("Lettericon", {
                                          attrs: {
                                            name: activity.event,
                                            size: "2"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c(
                                          "v-list-tile-sub-title",
                                          { staticClass: "text-truncate" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("browser")) +
                                                " " +
                                                _vm._s(activity.browser)
                                            )
                                          ]
                                        ),
                                        _c("v-list-tile-title", [
                                          _vm._v(_vm._s(activity.event))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-action",
                                      {
                                        staticClass:
                                          "grey--text body-1 text-xs-right"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(
                                              activity.createdAt,
                                              "from"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                index < _vm.propertyActivity.length - 1
                                  ? _c("v-divider", {
                                      key: activity.id + "_divider",
                                      attrs: { inset: true }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        ),
                        _c("v-divider"),
                        _vm.loadMore
                          ? _c("mugen-scroll", {
                              attrs: {
                                handler: _vm.fetchData,
                                "should-handle": !_vm.isLoading
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center pa-5 mb-5" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 50,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "back-to-top",
                    { attrs: { bottom: "50px", right: "50px" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-0",
                          attrs: {
                            fab: "",
                            dark: "",
                            fixed: "",
                            bottom: "",
                            right: "",
                            color: "arapawa"
                          }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }