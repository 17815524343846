<template>
  <v-form
    ref="form"
    class="form-onboarding"
    v-model="formValid"
    lazy-validation
    @submit.prevent="sendOnboarding"
  >
    <v-card class="elevation-0">
      <v-stepper class="elevation-0 transparent" v-model="step" vertical>
        <!-- Personal Information -->
        <v-stepper-step :complete="step > 1" step="1" :color="step > 1 ? 'light-green' : 'primary'">
          <StepperTitle
            title="Personal Information"
            :titleStep="1"
            v-on:edit="step = 1"
            :step="step"
          ></StepperTitle>
        </v-stepper-step>
        <v-stepper-content step="1">
          <AddPersonalInfo :data="formData" v-on:next="next" v-on:back="back"></AddPersonalInfo>
        </v-stepper-content>

        <!-- Company Information -->
        <v-stepper-step :complete="step > 2" step="2" :color="step > 2 ? 'light-green' : 'primary'">
          <StepperTitle
            title="Company Information"
            :titleStep="2"
            v-on:edit="step = 2"
            :step="step"
          ></StepperTitle>
        </v-stepper-step>
        <v-stepper-content step="2">
          <AddCompanyInfo :property="property" v-on:next="next" v-on:back="back"></AddCompanyInfo>
        </v-stepper-content>

        <!-- Company Details -->
        <v-stepper-step :complete="step > 3" step="3" :color="step > 3 ? 'light-green' : 'primary'">
          <StepperTitle title="Company Details" :titleStep="3" v-on:edit="step = 3" :step="step"></StepperTitle>
        </v-stepper-step>
        <v-stepper-content step="3">
          <AddCompanyDetails v-on:next="next" v-on:back="back"></AddCompanyDetails>
        </v-stepper-content>

        <!-- Controller Information -->
        <v-stepper-step :complete="step > 4" step="4" :color="step > 4 ? 'light-green' : 'primary'">
          <StepperTitle
            title="Controller Information"
            :titleStep="4"
            v-on:edit="step = 4"
            :step="step"
          ></StepperTitle>
        </v-stepper-step>
        <v-stepper-content step="4">
          <AddControllerInfo v-on:next="next" v-on:back="back"></AddControllerInfo>
        </v-stepper-content>

        <!-- Onwership Information -->
        <!--
            <v-stepper-step
              :complete="step > 5"
              step="5"
              :color="step > 5 ? 'light-green' : 'primary'"
            >
            <StepperTitle title="Onwership Information" :titleStep="5" v-on:edit="step=5" :step="step"></StepperTitle>
            </v-stepper-step>
            <v-stepper-content step="5">
              <AddOwnerInfo v-on:next="next" v-on:back="back"></AddOwnerInfo>
            </v-stepper-content>
        -->

        <!-- Onwership Information -->
        <v-stepper-step step="5" :color="step > 5 ? 'light-green' : 'primary'">
          <StepperTitle
            title="Confirm &amp; Upload"
            :titleStep="5"
            v-on:edit="step = 5"
            :step="step"
          ></StepperTitle>
        </v-stepper-step>
        <v-stepper-content step="5">
          <z-sign
            class="mb-3"
            ref="sign"
            @change="signChange"
            backgroundColor="#F1F5F7"
            :signerNameText="`${formData.firstName} ${formData.lastName}`"
            :agreeText="
              `I, ${formData.firstName} ${formData.lastName}, hereby certify, to the best of my knowledge, that the information provided above is complete and correct.`
            "
          ></z-sign>

          <template v-if="!agreeCorrect && sign.hasSign && sign.signerAgree">
            <v-btn round depressed color="primary" @click="agreeCorrect = true">Agree &amp; Continue</v-btn>
          </template>

          <template v-if="agreeCorrect">
            <v-checkbox color="primary" v-model="agreeTerms">
              <template v-slot:label>
                <div>
                  I agree Zinc's
                  <a
                    href="https://zinc.tech/terms-of-service/"
                    target="_blank"
                    class="grey--text"
                  >Terms of Service</a>
                  and
                  <a
                    href="https://zinc.tech/privacy-policy/"
                    target="_blank"
                    class="grey--text"
                  >Privacy Policy</a>, and Dwolla's
                  <a
                    href="https://www.dwolla.com/legal/tos/"
                    target="_blank"
                    class="grey--text"
                  >Terms of service</a>
                  and
                  <a
                    href="https://www.dwolla.com/legal/privacy/"
                    target="_blank"
                    class="grey--text"
                  >Privacy Policy</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              :loading="isSaving"
              v-bind:class="{ 'blue--text': isSaving }"
              :disabled="isSaving"
              round
              depressed
              color="primary"
              @click="submit"
              v-if="agreeTerms"
            >Agree &amp; Submit</v-btn>
          </template>
          <v-btn
            round
            flat
            @click="
              agreeCorrect = false
              $emit('back')
            "
          >Back</v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import DisableAutocomplete from '@/mixin/DisableAutocomplete'
import UnsavedChanges from '@/mixin/UnsavedChanges'
import ZSign from '@/components/Sign'

import StepperTitle from '@/components/onboarding/StepperTitle'
import AddPersonalInfo from '@/components/onboarding/AddPersonalInfo'
import AddCompanyInfo from '@/components/onboarding/AddCompanyInfo'
import AddCompanyDetails from '@/components/onboarding/AddCompanyDetails'
import AddControllerInfo from '@/components/onboarding/AddControllerInfo'
//import AddOwnerInfo from '@/components/onboarding/AddOwnerInfo'

export default {
  props: {
    propertyId: String,
    property: Object,
  },
  data() {
    return {
      isSaving: false,
      isLoading: false,
      agreeTerms: false,
      agreeCorrect: false,
      formData: { controller: {}, onwers: [] },
      formValid: false,
      step: 1,
      sign: {
        hasSign: false,
        signerAgree: null,
        signerName: null,
        signature: null,
      },
    }
  },
  components: {
    StepperTitle,
    AddPersonalInfo,
    AddCompanyInfo,
    AddCompanyDetails,
    AddControllerInfo,
    ZSign,
  },
  mixins: [DisableAutocomplete, UnsavedChanges, Alerts],
  computed: {
    ...mapGetters({ user: 'currentUser' }),
  },
  methods: {
    next(data) {
      this.formData = Object.assign(this.formData, data)
      this.step++
    },
    back() {
      this.step--
    },
    signChange(data) {
      this.sign = data
    },
    submit() {
      if (!this.agreeTerms) {
        return
      }

      if (this.formData['einFile'] == this.formData['articlesFile']) {
        return this.toastError('Please upload different documents.')
      }

      this.isSaving = true
      this.$http
        .post(
          '/accountant/properties/' + this.propertyId + '/onboarding',
          this.formData
        )
        .then(() => {
          this.toastSuccess('Success!')
          this.$emit('success')
        })
        .catch(err => {
          this.$log.error(err)
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail)
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
  mounted() {
    this.formData.firstName = this.user.firstName
    this.formData.lastName = this.user.lastName
    this.formData.email = this.user.email
  },
}
</script>
<style>
.form-onboarding .v-stepper__step--complete {
  padding: 4px 24px 4px;
}
.form-onboarding .filepond--drip {
  border: 1px dashed #3396f3;
  opacity: 1;
}
.form-onboarding [data-hopper-state='drag-over'] .filepond--drip {
  border: 1px dashed #3396f3;
  opacity: 0.1;
  box-shadow: 0px 0px 39px -8px rgba(0, 0, 0, 0.4);
}
.form-onboarding .filepond--label-action {
  color: #3396f3;
  -webkit-text-decoration-color: #3396f3;
  text-decoration-color: #3396f3;
}
</style>
