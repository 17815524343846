var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "page-with-subnavbar" },
    [
      _c("tev-navbar"),
      _c("tev-subnavbar", {
        attrs: {
          back:
            "/" +
            _vm.currentUser.accountType +
            "/transactions/" +
            _vm.propertyId,
          title: "BANK ACCOUNT INFORMATION"
        }
      }),
      _c(
        "v-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "", sm8: "" } },
                    [
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "text-xs-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.bank
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", "mb-4": "" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "z-card mb-4" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "transparent pa-0",
                                          attrs: { "two-line": "" }
                                        },
                                        [
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(_vm.bank.alias)
                                                      )
                                                    ])
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Bank Account Alias")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-tile-action",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        flat: "",
                                                        small: "",
                                                        color: "info"
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("delete")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _vm.property
                                                    ? _c("v-list-tile-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.property.name
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Property")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-tile-action",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "info",
                                                      attrs: {
                                                        icon: "",
                                                        flat: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$router.push(
                                                            "/" +
                                                              _vm.currentUser
                                                                .accountType +
                                                              "/properties/" +
                                                              _vm.propertyId
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "white"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "chevron_right"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.bank.routingNumber
                                                      )
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("Routing Number")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(_vm.bank.last4)
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v("4 last digits")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.items
                        ? _c("z-table-transactions", {
                            attrs: { items: _vm.items }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("z-footer", { attrs: { dark: false } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }