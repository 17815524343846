import { render, staticRenderFns } from "./Property.vue?vue&type=template&id=3f08bd42&"
import script from "./Property.vue?vue&type=script&lang=js&"
export * from "./Property.vue?vue&type=script&lang=js&"
import style0 from "./Property.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardText,VCardTitle,VCheckbox,VChip,VCombobox,VContainer,VDatePicker,VExpansionPanel,VExpansionPanelContent,VFlex,VIcon,VInput,VLayout,VMenu,VRating,VSelect,VSlideYTransition,VSlider,VSpacer,VSubheader,VTab,VTabItem,VTabs,VTabsSlider,VTextField,VTextarea,VTimePicker})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src266546640/src/zinc-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f08bd42')) {
      api.createRecord('3f08bd42', component.options)
    } else {
      api.reload('3f08bd42', component.options)
    }
    module.hot.accept("./Property.vue?vue&type=template&id=3f08bd42&", function () {
      api.rerender('3f08bd42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/schemaForm/Property.vue"
export default component.exports