import { render, staticRenderFns } from "./AddCompanyDetails.vue?vue&type=template&id=38ac2e02&"
import script from "./AddCompanyDetails.vue?vue&type=script&lang=js&"
export * from "./AddCompanyDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VForm})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src266546640/src/zinc-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38ac2e02')) {
      api.createRecord('38ac2e02', component.options)
    } else {
      api.reload('38ac2e02', component.options)
    }
    module.hot.accept("./AddCompanyDetails.vue?vue&type=template&id=38ac2e02&", function () {
      api.rerender('38ac2e02', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/onboarding/AddCompanyDetails.vue"
export default component.exports