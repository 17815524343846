var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    ref: "codePicker",
    staticClass: "z-callingcode-select",
    attrs: {
      "background-color": "#e0e0e0",
      value: _vm.activeCountry.dialCode,
      "item-value": "dialCode",
      items: _vm.sortedCountries,
      label: _vm.label,
      "return-object": "",
      "hide-no-data": "",
      box: ""
    },
    on: { input: _vm.updateValue },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function() {
          return undefined
        },
        proxy: true
      },
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          return [
            _vm.enabledFlags
              ? _c("div", {
                  staticClass: "iti-flag",
                  class: String(item.iso2).toLowerCase()
                })
              : _vm._e(),
            _vm.enabledCountryCode
              ? _c("span", { staticClass: "country-code" }, [
                  _vm._v("+" + _vm._s(item.dialCode))
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _vm.enabledFlags
              ? _c("div", {
                  staticClass: "iti-flag",
                  class: String(item.iso2).toLowerCase()
                })
              : _vm._e(),
            _c("span", { staticClass: "text-no-wrap text-truncate ml-2" }, [
              _vm._v(" " + _vm._s(item.name) + " "),
              _c("em", { staticClass: "grey--text pr-2" }, [
                _vm._v("+" + _vm._s(item.dialCode))
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }