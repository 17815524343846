<template>
  <v-app>
    <tev-navbar :title="'Application Detail'" />
    <v-content>
      <v-container>
        <v-layout row wrap>
          <v-card class="transparent elevation-0" width="800" max-width="100%">
            <v-layout row wrap align-start justify-start class="arrow-left">
              <v-btn v-if="application" icon active-class="none" class="light-green--text" :to="'/applicant/properties/' + application.propertyId">
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </v-layout>

            <div class="text-xs-center pa-5 mb-5" v-if="isLoading">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>

            <v-card-text id="card-details" v-if="!isLoading && application">
              <z-application-detail ref="appDetail" :application="application" :propertyId="application.propertyId"></z-application-detail>
            </v-card-text>
          </v-card>
        </v-layout>
      </v-container>
      <z-footer :dark="false"></z-footer>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Alerts from '@/mixin/Alerts'
import ZApplicationDetail from '@/components/ZApplicationDetail'

export default {
  title: 'Application Details',
  data() {
    return {
      error: false,
      isLoading: false,
      applicationId: null,
      application: null,
    }
  },
  mixins: [Alerts],
  components: { 
    'z-application-detail': ZApplicationDetail
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUser' }),
  },
  methods: {
    loadApplication() {
      this.isLoading = true
      this.$http
        .get('/applicant/applications/' + this.applicationId)
        .then(res => {
          this.application = res.data
          this.$log.info(this.application)

          setTimeout(() => {
            this.$refs.appDetail.loadEvents()
          }, 300)
      
        })
        .catch(err => {
          this.$log.error(err)
          this.$router.push('/applicant/properties')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.applicationId = this.$route.params.applicationId
    /*if (!this.applicationId) {
      this.$router.push('/applicant/properties')
    }*/
    this.loadApplication()
  },
}
</script>


<style>
.arrow-left {
  float: left;
}


</style>