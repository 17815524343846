<template>
  <v-card
    class="mb-3 elevation-1"
    color="white"
    :class="{'grey lighten-3': (notification.status=='read')}"
    :key="notification.id"
  >
    <v-card-title primary-title>
      <div>
        <h4 v-if="notification.title">{{ notification.title }}</h4>
        <span v-if="notification.message">{{ notification.message }}</span>
      </div>
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" flat v-if="notification.status=='unread'">Mark as read</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    notification: Object,
  },
  methods: {},
}
</script>
