<template>
  <div class="z-subnavar z-subnavar__fixed" :style="'padding-left:'+ computedLeft+'px'">
    <div class="z-subnavar__content">
      <v-btn icon class="light-green--text bg-white" :to="back" v-if="back">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <h3>{{title}}</h3>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    after: String,
    back: String,
  },
  computed: {
    computedLeft() {
      return this.$vuetify.application.left
    },
  },
  data() {
    return {}
  },
}
</script>
<style>
.page-with-subnavbar .v-content {
  padding-top: 0 !important;
  padding-top: 148px !important;
}

.z-subnavar .v-btn--active:before,
.z-subnavar .v-btn:hover:before,
.z-subnavar .v-btn:focus:before {
  background-color: transparent;
}

.z-subnavar {
  background-color: #f8f8f8;
  margin-top: 64px;
  height: 84px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.z-subnavar__fixed {
  position: fixed;
  z-index: 2;
}

.z-subnavar__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 24px;
}

.z-subnavar__content > :first-child.v-btn--icon {
  margin-left: -6px !important;
}

.z-subnavar h3 {
  color: #2196f3;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 10px;
  display: inline;
  line-height: 84px;
  font-size: 16px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/*
.z-subnavar:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(222, 222, 222, 0.65))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(222, 222, 222, 0.65) 100%
  );
  width: 100%;
  left: 0;
  content: ' ';
  position: absolute;
  height: 64px;
  margin-top: 20px;
}
*/

@media only screen and (max-width: 959px) {
  .page-with-subnavbar .v-content {
    padding-top: 130px !important;
  }

  .z-subnavar {
    margin-top: 56px;
    height: 74px;
  }

  .z-subnavar h3 {
    line-height: 74px;
  }

  .z-subnavar:before {
    height: 54px;
  }
}
</style>