const mixin = {
  mounted() {
    if (this.$route && this.$route.params) {
      let l = this.$route.params.lang
      if (l) {
        this.$i18n.locale = l
      }
    }
  },
}

export default mixin
