import { render, staticRenderFns } from "./OrganizationView.vue?vue&type=template&id=5dbcf214&"
import script from "./OrganizationView.vue?vue&type=script&lang=js&"
export * from "./OrganizationView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VContainer,VContent,VFlex,VIcon,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src266546640/src/zinc-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5dbcf214')) {
      api.createRecord('5dbcf214', component.options)
    } else {
      api.reload('5dbcf214', component.options)
    }
    module.hot.accept("./OrganizationView.vue?vue&type=template&id=5dbcf214&", function () {
      api.rerender('5dbcf214', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/representative/OrganizationView.vue"
export default component.exports