import Vue from 'vue'
import VueAxios from 'vue-axios'

import axios from './axios'

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    const reqConfig = error.config

    // Logout user if token refresh didn't work
    if (reqConfig.url.replace(reqConfig.baseURL, '') == '/public/refresh') {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')

      const _this = window.vm
      _this.$dialog
        .confirm({
          title: _this.$t('session_expired'),
          text: _this.$t('session_expired_explain'),
          actions: {
            true: {
              text: _this.$t('ok'),
              color: 'primary',
            },
          },
        })
        .then(answer => {
          if (answer) {
            if (_this.$route.path !== '/login') {
              //_this.$store.dispatch('logout')
              _this.$router.replace('/login?redirect=' + _this.$route.path)
            }
          }
        })

      return Promise.reject(error)
    }

    return axios
      .post('/public/refresh', {
        refreshToken: localStorage.getItem('refresh_token'),
      })
      .then(res => {
        localStorage.token = res.data.accessToken
        localStorage.refresh_token = res.data.refreshToken

        return new Promise((resolve, reject) => {
          axios
            .request(reqConfig)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
)

Vue.use(VueAxios, axios)
